import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const acctyperef=useRef();
const bankref=useRef();
const branchref=useRef();
const accnoref=useRef();
const ifsccoderef=useRef();
const baleolref=useRef();
const balref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       acctyperef.current.value=global1.acctype;
bankref.current.value=global1.bank;
branchref.current.value=global1.branch;
accnoref.current.value=global1.accno;
ifsccoderef.current.value=global1.ifsccode;
baleolref.current.value=global1.baleol;
balref.current.value=global1.bal;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const acctype=acctyperef.current.value;
const bank=bankref.current.value;
const branch=branchref.current.value;
const accno=accnoref.current.value;
const ifsccode=ifsccoderef.current.value;
const baleol=baleolref.current.value;
const bal=balref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatepaubankacntsbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                acctype:acctype,
bank:bank,
branch:branch,
accno:accno,
ifsccode:ifsccode,
baleol:baleol,
bal:bal,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpaubankacnts');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Bank Account Type<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank Account Type" id="acctype" ref={acctyperef} />
<br /><br />

<label htmlFor="bank">Bank Name</label>

<select id="bank" className="form-control"  ref={bankref}>
<option value="ALGEMENE BANK NEDERLAND">ALGEMENE BANK NEDERLAND</option>
<option value="ALLAHABAD BANK">ALLAHABAD BANK</option>
<option value="AMERICAN EXPRESS BANK LTD.">AMERICAN EXPRESS BANK LTD.</option>
<option value="ANDHRA BANK">ANDHRA BANK</option>
<option value="AXIS  BANK">AXIS  BANK</option>
<option value="BANK OF AMERICA">BANK OF AMERICA</option>
<option value="BANK OF BARODA ">BANK OF BARODA </option>
<option value="BANK OF INDIA">BANK OF INDIA</option>
<option value="BANK OF MAHARASHTRA">BANK OF MAHARASHTRA</option>
<option value="BANK OF TOKYO LTD.">BANK OF TOKYO LTD.</option>
<option value="BANQUE NATIONALE DE PARIS">BANQUE NATIONALE DE PARIS</option>
<option value="CANARA BANK">CANARA BANK</option>
<option value="Catholic Siriyan Bank">Catholic Siriyan Bank</option>
<option value="CENTAL COOPERATIVE BANK">CENTAL COOPERATIVE BANK</option>
<option value="CENTRAL BANK OF INDIA">CENTRAL BANK OF INDIA</option>
<option value="CITY UNION BANK">CITY UNION BANK</option>
<option value="CORPORATION BANK">CORPORATION BANK</option>
<option value="DENA BANK">DENA BANK</option>
<option value="DHANALAKSHMI  BANK">DHANALAKSHMI  BANK</option>
<option value="FEDERAL BANK">FEDERAL BANK</option>
<option value="HDFC BANK">HDFC BANK</option>
<option value="ICICI BANK">ICICI BANK</option>
<option value="IDBI Bank">IDBI Bank</option>
<option value="INDIAN BANK">INDIAN BANK</option>
<option value="INDIAN OVERSEAS BANK">INDIAN OVERSEAS BANK</option>
<option value="INDUSIND BANK">INDUSIND BANK</option>
<option value="KARNATAKA BANK LTD.">KARNATAKA BANK LTD.</option>
<option value="KARUR VYSYA BANK">KARUR VYSYA BANK</option>
<option value="LAKSHMI VILAS BANK">LAKSHMI VILAS BANK</option>
<option value="ORIENTAL BANK OF COMMERCE">ORIENTAL BANK OF COMMERCE</option>
<option value="PUNJAB AND SIND BANK">PUNJAB AND SIND BANK</option>
<option value="PUNJAB NATIONAL BANK">PUNJAB NATIONAL BANK</option>
<option value="RESERVE BANK OF INDIA">RESERVE BANK OF INDIA</option>
<option value="SOUTH INDIAN BANK">SOUTH INDIAN BANK</option>
<option value="STATE BANK OF BIKANER & JAIPUR">STATE BANK OF BIKANER & JAIPUR</option>
<option value="STATE BANK OF HYDERABAD">STATE BANK OF HYDERABAD</option>
<option value="STATE BANK OF INDIA ">STATE BANK OF INDIA </option>
<option value="STATE BANK OF INDORE ">STATE BANK OF INDORE </option>
<option value="STATE BANK OF MYSORE ">STATE BANK OF MYSORE </option>
<option value="STATE BANK OF PATIALA ">STATE BANK OF PATIALA </option>
<option value="STATE BANK OF SAURASHTRA">STATE BANK OF SAURASHTRA</option>
<option value="STATE BANK OF TRAVANCORE">STATE BANK OF TRAVANCORE</option>
<option value="SYNDICATE BANK">SYNDICATE BANK</option>
<option value="TAMIL NADU MERCANTILE BANK">TAMIL NADU MERCANTILE BANK</option>
<option value="TAMILNADU POWER FINANCE AND INFRASTRUCTURE DEVELOP">TAMILNADU POWER FINANCE AND INFRASTRUCTURE DEVELOP</option>
<option value="UCO BANK">UCO BANK</option>
<option value="UNION BANK OF INDIA">UNION BANK OF INDIA</option>
<option value="UNITED BANK OF INDIA">UNITED BANK OF INDIA</option>
<option value="VIJAYA BANK">VIJAYA BANK</option>
<option value="Others">Others</option>
</select>
<br /><br />

Enter Branch<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Branch" id="branch" ref={branchref} />
<br /><br />

Enter Account No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Account No." id="accno" ref={accnoref} />
<br /><br />

Enter IFSC Code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter IFSC Code" id="ifsccode" ref={ifsccoderef} />
<br /><br />

Enter Balance amount at the end of last financial year(in Rs.)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Balance amount at the end of last financial year(in Rs.)" id="baleol" ref={baleolref} />
<br /><br />

Enter Balance amount at the end of last day of academic year(in Rs.)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Balance amount at the end of last day of academic year(in Rs.)" id="bal" ref={balref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;