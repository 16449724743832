import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidemetricNavigation from '../components/layout/SidemetricNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    
    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    
    const addperiod = () => {
        history.replace('/addperiod');
    }  

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/gethigheredubyadmin', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_highereduadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletehigheredubyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_highereduadmin");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }


    useEffect(() => {
        checklogin();
        formattable();
        //search1();
        //getexamlist();
        //getlocal();
        //searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const width=window.innerWidth;
    const mdivwidth=width - 130;

        //alert('loaded');
  

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidemetricNavigation />
                    </td> */}
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>University Metrics</p>
            <hr />
            
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    <th>Metric</th>
 
</tr>
</thead>
<tbody>


<tr><td><Link to='/viewndoc121acadmin1'> 1.2.1 Subsequent Academic Council Meeting</Link></td></tr>
<tr><td><Link to='/viewndoc121bosadmin1'> 1.2.1 BoS meetings</Link></td></tr>
<tr><td><Link to='/viewndoc132attadmin1'> 1.3.2 Value added course attendance list</Link></td></tr>
<tr><td><Link to='/viewndoc132brochureadmin1'> Value added courses circular and brochure 1.3.2</Link></td></tr>
<tr><td><Link to='/viewndoc132certadmin1'> Value added course certificates 1.3.2</Link></td></tr>
<tr><td><Link to='/viewndoc133internadmin1'> Internship certificates 1.3.3</Link></td></tr>
<tr><td><Link to='/viewndoc133bosadmin1'> BoS minutes 1.3.3</Link></td></tr>
<tr><td><Link to='/viewndoc141fsubmitadmin1'> Proof of feedback report submission to appropriate bodies 1.4.1</Link></td></tr>
<tr><td><Link to='/viewndoc141sampleadmin1'> Feedback form 1.4.1</Link></td></tr>
<tr><td><Link to='/viewndoc141actionadmin1'> Feedback analysis report and action taken report 1.4.1</Link></td></tr>
<tr><td><Link to='/viewndod211intakeadmin1'> Admission sanction letter 2.1.1</Link></td></tr>
<tr><td><Link to='/viewndoc211extractadmin1'> Admission extract 2.1.1</Link></td></tr>
<tr><td><Link to='/viewndoc212finaladmin1'> Final admission list 2.1.2</Link></td></tr>
<tr><td><Link to='/viewndoc212letteradmin1'> Govt letter for reserved category 2.1.2</Link></td></tr>
<tr><td><Link to='/viewndod222studadmin1'> Programwise studnt list 2.2.2</Link></td></tr>
<tr><td><Link to='/viewndoc241sanctionadmin1'> Sanction letter for faculties 2.4.1</Link></td></tr>
<tr><td><Link to='/viewndoc242faclistadmin1'> List of PhD faculties 2.4.2</Link></td></tr>
<tr><td><Link to='/viewndoc252listadmin1'> List of students applkying for reeval or retotal 2.5.2</Link></td></tr>
<tr><td><Link to='/viewndoc222teachersadmin1'> List of teachers with department for latest completed academic year 2.2.2</Link></td></tr>
<tr><td><Link to='/viewndoc253screenshotadmin1'> Screenshot of exam automation software 2.5.3</Link></td></tr>
<tr><td><Link to='/viewndoc253reportadmin1'> Exam automation report 2.5.3</Link></td></tr>
<tr><td><Link to='/viewndoc261polistadmin1'> Program outcome list 2.6.1</Link></td></tr>
<tr><td><Link to='/viewndoc261colistadmin1'> Course outcome list 2.6.1</Link></td></tr>
<tr><td><Link to='/viewndoc262certadmin1'> CoE report 2.6.2</Link></td></tr>
<tr><td><Link to='/viewndoc262annualadmin1'> Examination annual report 2.6.2</Link></td></tr>
<tr><td><Link to='/viewndoc311coadmin1'> CO List 3.1.1</Link></td></tr>
<tr><td><Link to='/viewndoc312sanctionadmin1'> Seed money sanction letter 3.1.2</Link></td></tr>
<tr><td><Link to='/viewndoc312financeadmin1'> Financial mapping 3.1.2</Link></td></tr>
<tr><td><Link to='/viewndoc341syllabusadmin1'> Syllabus copy 3.4.1</Link></td></tr>
<tr><td><Link to='/viewndoc341ethicsadmin1'> Ethics committee 3.4.1</Link></td></tr>
<tr><td><Link to='/viewndoc341ethicsminadmin1'> Ethics committee meeting minutes</Link></td></tr>
<tr><td><Link to='/viewndoc341researchadmin1'> Research committee constitution 3.4.1</Link></td></tr>
<tr><td><Link to='/viewndoc341researchminadmin1'> Research committee meeting minutes</Link></td></tr>
<tr><td><Link to='/viewndoc341billsadmin1'> Software bills 3.4.1</Link></td></tr>
<tr><td><Link to='/viewndoc342phdawardadmin1'> PhD award letter to students 3.4.3</Link></td></tr>
<tr><td><Link to='/viewndoc342letteradmin1'> Guideship allotment letter 3.4.3</Link></td></tr>

<tr><td><Link to='/viewndoc346agencyadmin1'> 3.4.6 E course agency details</Link></td></tr>
<tr><td><Link to='/viewndoc346lmsadmin1'> 3.4.6 LMS details</Link></td></tr>
<tr><td><Link to='/viewndoc351corporateadmin1'> 3.5.1 Corporate training</Link></td></tr>
<tr><td><Link to='/viewndoc346ecadmin1'> 3.4.6 Course details</Link></td></tr>
              
            </tbody>
            </Table>

            <br /><br />
            

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;