import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Salumni';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const alumniref=useRef();
const addressref=useRef();
const cityref=useRef();
const countryref=useRef();
const emailref=useRef();
const phoneref=useRef();
const programref=useRef();
const regnoref=useRef();
const batchref=useRef();
const usernameref=useRef();
const passwordref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       alumniref.current.value=global1.alumni;
addressref.current.value=global1.address;
cityref.current.value=global1.city;
countryref.current.value=global1.country;
emailref.current.value=global1.email;
phoneref.current.value=global1.phone;
programref.current.value=global1.program;
regnoref.current.value=global1.regno;
batchref.current.value=global1.batch;
usernameref.current.value=global1.username;
passwordref.current.value=global1.password;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const alumni=alumniref.current.value;
const address=addressref.current.value;
const city=cityref.current.value;
const country=countryref.current.value;
const email=emailref.current.value;
const phone=phoneref.current.value;
const program=programref.current.value;
const regno=regnoref.current.value;
const batch=batchref.current.value;
const username=usernameref.current.value;
const password=passwordref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatealumniusersbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                alumni:alumni,
address:address,
city:city,
country:country,
email:email,
phone:phone,
program:program,
regno:regno,
batch:batch,
username:username,
password:password,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnalumniusers');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Alumni name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Alumni name" id="alumni" ref={alumniref} />
<br /><br />

Enter Address<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address" id="address" ref={addressref} />
<br /><br />

Enter City<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter City" id="city" ref={cityref} />
<br /><br />

Enter Country<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Country" id="country" ref={countryref} />
<br /><br />

Enter Email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email" id="email" ref={emailref} />
<br /><br />

Enter Phone<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Phone" id="phone" ref={phoneref} />
<br /><br />

Enter Program studied<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program studied" id="program" ref={programref} />
<br /><br />

Enter Institution reg no<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Institution reg no" id="regno" ref={regnoref} />
<br /><br />

Enter Batch (Year of passing)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Batch (Year of passing)" id="batch" ref={batchref} />
<br /><br />

Enter Username<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Username" id="username" ref={usernameref} />
<br /><br />

Enter Password<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Password" id="password" ref={passwordref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;