import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faCube, faVideo, faCubes, faFile, faUsers, faDatabase, faTachometerAlt, faComment, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigationstud';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [photo, setPhoto] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [dep, setDep] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    //const name=global1.name;
    const coursename=global1.coursename;
    const coursecode1=global1.coursecode;
    const courseid=global1.courseid; 
    const section=global1.section;
    const semester=global1.semester;
    const programcode=global1.programcode;
    const lmsyear=global1.lmsyear;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const viewstudents = () => {
        history.replace('/classstudents');
    }
    const viewstudentsm = () => {
        history.replace('/classstudentsm');
    }
    const viewstudentsmsec = () => {
        history.replace('/classstudentsmsec');
    }

    const addassignments = () => {
        history.replace('/addassignments');
    }

    const viewassignments = () => {
        history.replace('/classassignments');
    }
    const viewstudymaterial = () => {
        history.replace('/classfiles');
    }

    const addstudymaterial = () => {
        history.replace('/addcoursefiles');
    }

    const gotoattendance = () => {
        // history.replace('/classattendance');
        history.replace('/selectattendance');
    }

    const addmodules = () => {
        history.replace('/addlessonplan');
    }

    const viewmodules = () => {
        history.replace('/lessonplan');
    }
    const gotodynamicmodules = () => {
        history.replace('/viewdynamicmodules');
    }

    const addlessonplan = () => {
        history.replace('/viewlessonnew');
    }

    const addstudents = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudents');
    }

    const addstudentsext = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/viewclassprogramstud');
    }

    

   
var uphoto='';
var uname='';

    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getuserdetails', {
            params: {
                user: user,
                token: token,
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        //setResults(response.data.data.classes);
    uname=response.data.data.classes[0].name;
    uphoto=response.data.data.classes[0].photo;
    setEmail(response.data.data.classes[0].email);
    setPhone(response.data.data.classes[0].phone);
    setDep(response.data.data.classes[0].department);
    if(!uphoto) {
        uphoto='https://jadavpuruniversity.s3-ap-south-1.amazonaws.com/820222034-user.png';
    }
    setPhoto(uphoto);
        //alert(uname + ' ' + photo);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        searchApi('');
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    function checkdata(link, ccode) {
        //alert (examcode + '-' + examname);
        if (link=='NA') {
            link="https://meet.jit.si/" + ccode;
        }
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDragStart = (ev, ccode,  course, section, semester, program) => {
        //ev.preventDefault();
        ev.dataTransfer.setData("ccode", ccode);
        ev.dataTransfer.setData("course", course);
        ev.dataTransfer.setData("section", section);
        ev.dataTransfer.setData("semester", semester);
        ev.dataTransfer.setData("program", program);
        //setCoursecode(ccode);
    }
    const onDrop = async (ev, starttime, endtime) => {
        let ccode = ev.dataTransfer.getData("ccode");
        let course = ev.dataTransfer.getData("course");
        let section = ev.dataTransfer.getData("section");
        let semester = ev.dataTransfer.getData("semester");
        let program = ev.dataTransfer.getData("program");
        const module=moduleref.current.value;
        const link=linkref.current.value;
        const topic=topicref.current.value;
        if(!module) {
            alert("Please enter module");
            return;
        }
        if(!topic) {
            alert("Please enter topic");
            return;
        }
        if(!link) {
            alert("Please enter link");
            return;
        }
        
        setCoursecode(ccode);
        const response = await ep1.get('/api/v1/createclass', {
            params: {
                user: user,
                name: name,
                colid: colid,
                classdate: datechange,
                weeks: 0,
                coursecode: ccode,
                course: course,
                link: link,
                program: program,
                semester: semester,
                section: section,
                module: module,
                topic: topic,
                classtime: starttime



            }

        });
        if (response.data.status == "Success") {
            
            //history.replace('/workload');
            //setTerm2('Thank you');  
            getclass();
            alert('Data updated successfully');
        }
        //setResults(response.data.data.classes);

        //alert(ccode + '-' + starttime + '-' + endtime + '-' + datechange + '-' + module + '-' + topic + '-' + link);

    }

    async function refreshclass(newdate) {
        
        var dt1=new Date(newdate);
        setDate2(dt1);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
    }

    const width=window.innerWidth;
    const mdivwidth=width - 100;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }

    


    return (
        <div className="container">
           

                    <TopNavigation />
        
        

            <br /><br />

            <div style={{textAlign: 'center'}}>
            {/* <h4>{name}</h4>
                <br /> */}

            <img src={photo} style={{width: 130, height: 130, borderRadius: 65}} />
                <br /><br />
                {/* <Link to='/selectawsconfigphotostud' className="btn btn-dark"> Update Photo</Link>
                <br /><br /> */}
                
               


                </div>

                

           


            {/* <p style={{ fontSize: 18 }}>Select an option to proceed</p> */}
            <hr />
           
    
        

        

        {/* <div className="container">
                <div className="row"> */}



<div className="container">
        <div className="row">


            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer' }}>       
                    <Link to='/viewnbookmob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Books
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/viewnseminarmob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Seminar
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/viewnprojectsmob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Projects
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/viewnpublicationsmob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Publications
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/viewnpatentsmob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Patents
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/viewnteacherfellowmob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Fellow ship
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/viewnconsultancymob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Consultancy
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/viewnphdguidemob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       PhD guide
                        </Link>

                        </div>
            </div>

            <div className="col-6">
                <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}>       
                    <Link to='/new'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Logout
                        </Link>

                        </div>
            </div>

        </div>
        </div>


{/* 

        <table style={{width: '100%'}}>
        <tr>
            <td>
            <div className={classes1.cardmob} style={{ textAlign: 'center', width: '150px'}}>
                <div style={{ textAlign: 'center', width: '150px'}}>
                         
                          
                        
                            
                             
                    <Link to='/viewnbookmob'>
                    <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                       Books
                        </Link>

                        </div>
                </div>
                </td>
                <td>
                <div className={classes1.cardmob} style={{ textAlign: 'center', width: '150px', marginLeft:0}}>
                 <div style={{ textAlign: 'center', width: '150px', marginLeft: 0}}>
                           
                             
                    <Link to='/viewnseminarmob'>
                    <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                        
                        Seminar
                        </Link>

                        </div>
                </div>
                </td>
        </tr>
        <tr>
            <td>
            <div className={classes1.cardmob} style={{ textAlign: 'center', width: '150px'}}>
                
                          
                        
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewcircularstud'>All circulars</Link>

                </div>
            </td>
            <td>
            
                 <div className={classes1.cardmob} style={{ textAlign: 'center', width: '150px', marginLeft: 0}}>
                 <div style={{ textAlign: 'center', width: '150px', marginLeft: 0}}>
                          
                             
                    <Link to='/new'>
                    <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                        App Logout
                        </Link>

                        </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
            <div className={classes1.cardmob} style={{ textAlign: 'center', width: '150px'}}>
                 
                        
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewvactodaystud'>Today's VAC</Link>

                </div>
            </td>
            <td>
            <div className={classes1.cardmob} style={{ textAlign: 'center', width: '150px'}}>
                     
                        
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnmodulesstud'>Open courses</Link>

                        
                </div>
            </td>
            <td>
        
            </td>
        </tr>
        </table> */}
        <br /><br />
        
       

                {/* <div className="col-2"> */}
               
              
                 {/* <div className="col-2"> */}
                

                {/* </div></div> */}

                {/* <div className="container">
                <div className="row"> */}
                
                {/* <div className="col-2"> */}
                
              
                
                
            {/* </div></div> */}

            

       
        
     
        <FooterNav1 />
     
               
        </div>
        
    );

}

export default Allclasses;