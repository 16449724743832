import { Route, Switch } from 'react-router-dom';

import Allclasses from './pages/Allclasses';
import Newclasses from './pages/Newclasses';
import Newclasses1 from './pages/Newclasses1';
import Newclassesf from './pages/Newclassesf';
import Newclassesf1 from './pages/Newclassesf1';
import Newclassesf2 from './pages/Newclassesf2';
import Openclasses from './pages/Openclasses';
import Workload from './pages/Workload';
import Workload1 from './pages/Workload1';
import Workload12 from './pages/Workload12';
import Addworkload from './pages/Addworkload';
import Addworkload1 from './pages/Addworkload1';
import Addworkloadf from './pages/Addworkloadf';
import Addperiod from './pages/Addperiod';
import Dashcourse from './pages/Dashcourse';
import Dashcourse1 from './pages/Dashcourse1';
import Dashcoursef from './pages/Dashcoursef';
import Enrollstudents from './pages/Enrollstudents';
import Enrollstudents1 from './pages/Enrollstudents1';
import CourseActions from './pages/CourseActions';
import Classstudents from './pages/Classstudents';
import Classattendance from './pages/Classattendance';
import ClassAttendance1 from './pages/ClassAttendance1';
import ClassAttendance2 from './pages/ClassAttendance2';
import Getmyfeedback from './pages/Getmyfeedback';
import Addassignments from './pages/Addassignments';
import Classassignments from './pages/Classassignments';
import Addcoursefiles from './pages/Addcoursefiles';
import Classfiles from './pages/Classfiles';
import Classstudentsm from './pages/Classstudentsm';
import Proctorlist from './pages/Proctorlist';
import Examenrlist from './pages/Examenrlist';
import Examenrlist1 from './pages/Examenrlist1';
import Lessonplan from './pages/Lessonplan';
import Addlessonplan from './pages/Addlessonplan';
import Addassignments1 from './pages/Addassignments1';
import Assetlist from './pages/Assetlist';
import Addassets from './pages/Addassets';
import Assetassignment from './pages/Assetassignment';
import Addassetassignment from './pages/Addassetassignment';
import Submittedassignments from './pages/Submittedassignments';
import Submissioncomments from './pages/Submissioncomments';
import Menteelist from './pages/Menteelist';
import Addmentee from './pages/Addmentee';
import Mentorlogbook from './pages/Mentorlogbook';
import Addmentorlogbook from './pages/Addmentorlogbook';
import Fees from './pages/Fees';
import Workdiary from './pages/Workdiary';
import Addlibbooks from './pages/Addlibbooks';
import LibraryActions from './pages/LibraryActions';
import Viewlibbooks from './pages/Viewlibbooks';
import Viewlibbooks1 from './pages/Viewlibbooks1';
import Viewlibbooksfaculty from './pages/Viewlibbooksfaculty';
import Searchlibbooks from './pages/Searchlibbooks';
import Searchlibbooks1 from './pages/Searchlibbooks1';
import Libissuebooks from './pages/Libissuebooks';
import Libreturnbooks from './pages/Libreturnbooks';
import Libissuelist from './pages/Libissuelist';
import Libissuelistall from './pages/Libissuelistall';
import Viewprojects from './pages/ViewProjects';
import Viewprojectsadmindoc from './pages/Viewprojectsadmindoc';
import Viewallprojects from './pages/Viewallprojects';
import Addprojects from './pages/Addprojects';
import Editprojects from './pages/Editprojects';
import Viewseminar from './pages/Viewseminar';
import Addseminar from './pages/Addseminar';
import Editseminar from './pages/Editseminar';
import Viewpatent from './pages/Viewpatent';
import Addpatent from './pages/Addpatent';
import Editpatent from './pages/Editpatent';
import Viewpub from './pages/Viewpub';
import Addpub from './pages/Addpub';
import Editpub from './pages/Editpub';
import Viewbooks from './pages/Viewbooks';
import Viewallbooks from './pages/Viewallbooks';
import Addbooks from './pages/Addbooks';
import Editbooks from './pages/Editbooks';
import Dashmydetails from './pages/Dashmydetails';
import Dashmydetails1 from './pages/Dashmydetails1';
import Dashmydetails2 from './pages/Dashmydetails2';
import Updatepassword from './pages/Updatepassword';
import Updatepassword1 from './pages/Updatepassword1';
import Dashcriteria1 from './pages/Dashcriteria1';
import Dashcriteria2 from './pages/Dashcriteria2';
import Dashcriteria3 from './pages/Dashcriteria3';
import Dashcriteria4 from './pages/Dashcriteria4';
import Dashcriteria5 from './pages/Dashcriteria5';
import Dashcriteria6 from './pages/Dashcriteria6';
import Webinarregister from './pages/Webinarregister';
import Parentfeedbackanalysis from './pages/Parentfeedbackanalysis';
import Parentfeedbackanalysis1 from './pages/Parentfeedbackanalysis1';
import Curriculumfeedbackopt1 from './pages/Curriculumfeedbackopt1';
import Employerfeedbackanalysis from './pages/Employerfeedbackanalysis';
import Viewalerts from './pages/Viewalerts';
import Viewkpi from './pages/Viewkpi';
import Viewdynamicworkdiary from './pages/Viewdynamicworkdiary';
import Viewdynamicmodules from './pages/Viewdynamicmodules';
import Selectcourse from './pages/Selectcourse';
import Viewvacstudlist from './pages/Viewvacstudlist';
import Dashfacultydetails from './pages/Dashfacultydetails';
import Viewallpubs from './pages/Viewallpubs';
import Viewallpatents from './pages/Viewallpatents';
import Viewallseminars from './pages/Viewallseminars';
import Viewaddoncourseadmin from './pages/Viewaddoncourseadmin';
import Viewadmissionadmin from './pages/Viewadmissionadmin';
import Viewbosadmin from './pages/Viewbosadmin';
import Viewcollaborationadmin from './pages/Viewcollaborationadmin';
import Vieweventadmin from './pages/Vieweventadmin';
import Viewexpenditureadmin from './pages/Viewexpenditureadmin';
import Viewextensionactadmin from './pages/Viewextensionactadmin';
import Viewictadmin from './pages/Viewictadmin';
import Viewinstawardsadmin from './pages/Viewinstawardsadmin';
import Viewlibraryexpadmin from './pages/Viewlibraryexpadmin';
import Viewmouadmin from './pages/Viewmouadmin';
import Viewpassexamadmin from './pages/Viewpassexamadmin';
import Viewreservecatadmin from './pages/Viewreservecatadmin';
import Deleteroleuser from './pages/Deleteroleuser';
import Vieweventipr from './pages/Vieweventipr';
import Vieweventextension from './pages/Vieweventextension';
import MainNavigation from './components/layout/MainNavigtion';
import FooterNavigation from './components/layout/FooterNav';

import Viewawardsadmin from './pages/Viewawardsadmin';
import Viewcareercounseladmin from './pages/Viewcareercounseladmin';
import Viewcbcsadmin from './pages/Viewcbcsadmin';
import Viewegovernadmin from './pages/Viewegovernadmin';
import Viewhighereduadmin from './pages/Viewhighereduadmin';
import Viewhigherexamadmin from './pages/Viewhigherexamadmin';
import Viewplacementadmin from './pages/Viewplacementadmin';
import Viewqualityadmin from './pages/Viewqualityadmin';
import Viewskilldevadmin from './pages/Viewskilldevadmin';
import Viewteacherdataadmin from './pages/Viewteacherdataadmin';
import Viewteacherfsadmin from './pages/Viewteacherfsadmin';


import Addaddoncourse from './pages/Addaddoncourse';
import Addadmission from './pages/Addadmission';
import Addbos from './pages/Addbos';
import Addcbcs from './pages/Addcbcs';
import Addcollaboration from './pages/Addcollaboration';
import Addevent from './pages/Addevent';
import Addexpenditure from './pages/Addexpenditure';
import Addextensionact from './pages/Addextensionact';
import Addict from './pages/Addict';
import Addinstawards from './pages/Addinstawards';
import Addlibraryexp from './pages/Addlibraryexp';
import Addmou from './pages/Addmou';
import Addpassexam from './pages/Addpassexam';
import Addreservecat from './pages/Addreservecat';
import Addteacherdata from './pages/Addteacherdata';

import Addawards from './pages/Addawards';
import Addcareercounsel from './pages/Addcareercounsel';
import Addegovern from './pages/Addegovern';
import Addhigheredu from './pages/Addhigheredu';
import Addhigherexam from './pages/Addhigherexam';
import Addplacement from './pages/Addplacement';
import Addquality from './pages/Addquality';
import Addskilldev from './pages/Addskilldev';
import Addteacherfs from './pages/Addteacherfs';


import Viewaddoncourse from './pages/Viewaddoncourse';
import Viewadmission from './pages/Viewadmission';
import Viewbos from './pages/Viewbos';
import Viewcbcs from './pages/Viewcbcs';
import Viewcollaboration from './pages/Viewcollaboration';
import Viewevent from './pages/Viewevent';
import Viewexpenditure from './pages/Viewexpenditure';
import Viewextensionact from './pages/Viewextensionact';
import Viewict from './pages/Viewict';
import Viewinstawards from './pages/Viewinstawards';
import Viewlibraryexp from './pages/Viewlibraryexp';
import Viewmou from './pages/Viewmou';
import Viewpassexam from './pages/Viewpassexam';
import Viewreservecat from './pages/Viewreservecat';
import Viewteacherdata from './pages/Viewteacherdata';

import Viewawards from './pages/Viewawards';
import Viewcareercounsel from './pages/Viewcareercounsel';
import Viewegovern from './pages/Viewegovern';
import Viewhigheredu from './pages/Viewhigheredu';
import Viewhigherexam from './pages/Viewhigherexam';
import Viewplacement from './pages/Viewplacement';
import Viewquality from './pages/Viewquality';
import Viewskilldev from './pages/Viewskilldev';
import Viewteacherfs from './pages/Viewteacherfs';

import Editaddoncourse from './pages/Editaddoncourse';
import Editadmission from './pages/Editadmission';
import Editbos from './pages/Editbos';
import Editcbcs from './pages/Editcbcs';
import Editcollaboration from './pages/Editcollaboration';
import Editevent from './pages/Editevent';
import Editexpenditure from './pages/Editexpenditure';
import Editextensionact from './pages/Editextensionact';
import Editict from './pages/Editict';
import Editinstawards from './pages/Editinstawards';
import Editlibraryexp from './pages/Editlibraryexp';
import Editmou from './pages/Editmou';
import Editpassexam from './pages/Editpassexam';
import Editreservecat from './pages/Editreservecat';
import Editteacherdata from './pages/Editteacherdata';

import Editawards from './pages/Editawards';
import Editcareercounsel from './pages/Editcareercounsel';
import Editegovern from './pages/Editegovern';
import Edithigheredu from './pages/Edithigheredu';
import Edithigherexam from './pages/Edithigherexam';
import Editplacement from './pages/Editplacement';
import Editquality from './pages/Editquality';
import Editskilldev from './pages/Editskilldev';
import Editteacherfs from './pages/Editteacherfs';

import Addsdg3 from './pages/Addsdg3';
import Viewsdg3 from './pages/Viewsdg3';
import Editsdg3 from './pages/Editsdg3';
import Addsdg4 from './pages/Addsdg4';
import Viewsdg4 from './pages/Viewsdg4';
import Editsdg4 from './pages/Editsdg4';
import Addsdg5 from './pages/Addsdg5';
import Viewsdg5 from './pages/Viewsdg5';
import Editsdg5 from './pages/Editsdg5';
import Addsdg8 from './pages/Addsdg8';
import Viewsdg8 from './pages/Viewsdg8';
import Editsdg8 from './pages/Editsdg8';
import Addsdg9 from './pages/Addsdg9';
import Viewsdg9 from './pages/Viewsdg9';
import Editsdg9 from './pages/Editsdg9';
import Addsdg10 from './pages/Addsdg10';
import Viewsdg10 from './pages/Viewsdg10';
import Editsdg10 from './pages/Editsdg10';
import Addsdg11 from './pages/Addsdg11';
import Viewsdg11 from './pages/Viewsdg11';
import Editsdg11 from './pages/Editsdg11';
import Addsdg12 from './pages/Addsdg12';
import Viewsdg12 from './pages/Viewsdg12';
import Editsdg12 from './pages/Editsdg12';
import Addsdg13 from './pages/Addsdg13';
import Viewsdg13 from './pages/Viewsdg13';
import Editsdg13 from './pages/Editsdg13';
import Addsdg16 from './pages/Addsdg16';
import Viewsdg16 from './pages/Viewsdg16';
import Editsdg16 from './pages/Editsdg16';
import Addsdg17 from './pages/Addsdg17';
import Viewsdg17 from './pages/Viewsdg17';
import Editsdg17 from './pages/Editsdg17';

import Addsdg1 from './pages/Addsdg1';
import Viewsdg1 from './pages/Viewsdg1';
import Editsdg1 from './pages/Editsdg1';
import Addsdg2 from './pages/Addsdg2';
import Viewsdg2 from './pages/Viewsdg2';
import Editsdg2 from './pages/Editsdg2';
import Addsdg6 from './pages/Addsdg6';
import Viewsdg6 from './pages/Viewsdg6';
import Editsdg6 from './pages/Editsdg6';
import Addsdg7 from './pages/Addsdg7';
import Viewsdg7 from './pages/Viewsdg7';
import Editsdg7 from './pages/Editsdg7';
import Addsdg14 from './pages/Addsdg14';
import Viewsdg14 from './pages/Viewsdg14';
import Editsdg14 from './pages/Editsdg14';
import Addsdg15 from './pages/Addsdg15';
import Viewsdg15 from './pages/Viewsdg15';
import Editsdg15 from './pages/Editsdg15';

import Viewsdg from './pages/Viewsdg';
import Viewallfaculties from './pages/Viewallfaculties';

import Viewmlink from './pages/Viewmlink';
import Addmlink from './pages/Addmlink';
import Editmlink from './pages/Editmlink';



import Vieweventfdp from './pages/Vieweventfdp';
import Viewinnovation from './pages/Viewinnovation';
import Viewinnovationadmin from './pages/Viewinnovationadmin';
import Addinnovation from './pages/Addinnovation';
import Editinnovation from './pages/Editinovation';

import Viewfacultyreport from './pages/Viewfacultyreport';

import Updatequestions from './pages/Updatequestions';

import Alumnifeedbackanalysis from './pages/Alumnifeedbackanalysis';

import Addprojectstatus from './pages/Addprojectstatus';
import Addprojectstatus1 from './pages/Addprojectstatus1';
import Viewprojectstatus from './pages/Viewprojectstatus';
import Viewprojectstatusv from './pages/Viewprojectstatusv';
import Viewprojectstatusbytaskdep from './pages/Viewprojectstatusbytaskdep';
import Editprojectstatus from './pages/Editprojectstatus';
import Viewprojectstatusbytask from './pages/Viewprojectstatusbytask';

import Viewweeklystatus from './pages/Viewweeklystatus';


import Addkpi from './pages/Addkpi';
import Viewkpiuser from './pages/Viewkpiuser';
import Editkpi from './pages/Editkpi';

import Addtasks from './pages/Addtasks';
import Viewtasks from './pages/Viewtasks';
import Edittasks from './pages/Edittasks';



import Viewscopus from './pages/Viewscopus';

import Viewkpigroup from './pages/Viewkpigroup';
import Viewkpicriteria from './pages/Viewkpicriteria';

import Addallkpi from './pages/Addallkpi';


import Addresult from './pages/Addresult';
import Addecontent from './pages/Addecontent';
import Addscholarship from './pages/Addscholarship';
import Addfunds from './pages/Addfunds';
import Addseedm from './pages/Addseedm';
import Addconsultancy from './pages/Addconsultancy';
import Addsyllabusrev from './pages/Addsyllabusrev';

import Editresult from './pages/Editresult';
import Editecontent from './pages/Editecontent';
import Editscholarship from './pages/Editscholarship';
import Editfunds from './pages/Editfunds';
import Editseedm from './pages/Editseedm';
import Editconsultancy from './pages/Editconsultancy';
import Editsyllabusrev from './pages/Editsyllabusrev';

import Viewresult from './pages/Viewresult';
import Viewecontent from './pages/Viewecontent';
import Viewscholarship from './pages/Viewscholarship';
import Viewfunds from './pages/Viewfunds';
import Viewseedm from './pages/Viewseedm';
import Viewconsultancy from './pages/Viewconsultancy';
import Viewsyllabusrev from './pages/Viewsyllabusrev';


import Addexplearning from './pages/Addexplearning';
import Viewexplearning from './pages/Viewexplearning';
import Editexplearning from './pages/Editexplearning';

import Viewsuperweekly from './pages/Viewsuperweekly';

import Addtaskassign from './pages/Addtaskassign';
import Viewtaskassign from './pages/Viewtaskassign';
import Edittaskassign from './pages/Edittaskassign';

import Addpubaqar from './pages/Addpubaqar';
import Editpubaqar from './pages/Editpubaqar';
import Viewpubaqar from './pages/Viewpubaqar';

import Adddepartment from './pages/Adddepartment';
import Editdepartment from './pages/Editdepartment';
import Viewdepartment from './pages/Viewdepartment';

import Addbosfac from './pages/Addbosfac';
import Editbosfac from './pages/Editbosfac';
import Viewbosfac from './pages/Viewbosfac';

import Addtaskticket from './pages/Addtasksticket';
import Viewtaskticket from './pages/Viewtaskticket';
import Viewtaskticketall from './pages/Viewtasksticketall';
import Edittaskticket from './pages/Edittaskticket';

import Thankyou from './pages/Thankyou';

import Viewtaskassignuser from './pages/Viewtaskassignuser';
import Edittaskassignuser from './pages/Edittaskassignuser';
import Viewtaskbyidticket from './pages/Viewtaskbyidticket';

import Viewawsconfig from './pages/Viewawsconfig';
import Editawsconfig from './pages/Editawsconfig';
import Addawsconfig from './pages/Addawsconfig';

import Addsupportingdoc from './pages/Addsupportingdoc1';
import Addsupportingdoc2 from './pages/Addsupportingdoc2';
import Selectawsconfig from './pages/Selectawsconfig';
import Viewsupportingdoc from './pages/Viewsupportingdoc';
import Viewsupportingdocall from './pages/Viewsupportingdocall';

import Addmetricrules from './pages/Addmetricrules';
import Editmetricrules from './pages/Editmetricrules';
import Viewmetricrules from './pages/Viewmetricrules';
import Selectmetricrule from './pages/Selectmetricrule';
import Selectmetricrulefiles from './pages/Selectmetricrulefiles';



import Viewcircularfac from './pages/Viewcircularfac';
import Editcircularfac from './pages/Editcircularfac';
import Addcircularfac from './pages/Addcircularfac';

import Viewinstitutions from './pages/Viewinstitutions';
import Editinstitutions from './pages/Editinstitutions';
import Addinstitution from './pages/Addinstitution';
import Selectinstitution from './pages/Selectinstitution';

import Selectdepartment from './pages/Selectdepartment';
import Viewdeppub from './pages/Viewdeppub';
import Viewdepprojects from './pages/Viewdepprojects';
import Viewdepseminar from './pages/Viewdepseminar';
import Viewdepbooks from './pages/Viewdepbooks';

import Viewdeppubmy from './pages/Viewdeppubmy';
import Viewdepseminarmy from './pages/Viewdepseminarmy';
import Viewdepprojectsmy from './pages/Viewdepprojectsmy';
import Viewdepbooksmy from './pages/Viewdepbooksmy';

import Getstudentcount from './pages/Getstudentcount';
import Getfacultycount from './pages/Getfacultycount';
import Getstudentbycategory from './pages/Getstudentbycategory';
import Getstudentbycatgender from './pages/Getstudentbycatgender';
import Selectattendance from './pages/Selectattendance';

import Addbulkaddonc from './pages/Addbulkaddonc';
import Editadminbooks from './pages/Editadminbooks';
import Viewsupportingdocadmin from './pages/Viewsupportingdocadmin';


import Workload13 from './pages/Workload13';
import Takeattendance from './pages/Takeattendance';
import Viewclassattendance from './pages/Viewclassattendance';

import Classattendance3 from './pages/ClassAttendance3';

import Addexplearnproj from './pages/Addexplearnproj';
import Viewexplearnproj from './pages/Viewexplearnproj';
import Editexplearnproj from './pages/Editexplearnproj';
import Addfieldproj from './pages/Addfieldproj';
import Viewfieldproj from './pages/Viewfieldproj';
import Editfieldproj from './pages/Editfieldproj';
import Addteacherguide from './pages/Addteacherguide';
import Viewteacherguide from './pages/Viewteacherguide';
import Editteacherguide from './pages/Editteacherguide';
import Addextawards from './pages/Addextawards';
import Viewextawards from './pages/Viewextawards';
import Editextawards from './pages/Editextawards';
import Addmouact from './pages/Addmouact';
import Viewmouact from './pages/Viewmouact';
import Editmouact from './pages/Editmouact';
import Addsportsact from './pages/Addsportsact';
import Viewsportsact from './pages/Viewsportsact';
import Editsportsact from './pages/Editsportsact';
import Addegov from './pages/Addegov';
import Viewegov from './pages/Viewegov';
import Editegov from './pages/Editegov'; 
import Addfdp from './pages/Addfdp';
import Viewfdp from './pages/Viewfdp';
import Editfdp from './pages/Editfdp'; 
import Addqualityinit from './pages/Addqualityinit';
import Viewqualityinit from './pages/Viewqualityinit';
import Editqualityinit from './pages/Editqualityinit';

import Addbulkpublications from './pages/Addbulkpublications';
import Addbulkpatents from './pages/Addbulkpatents';
import Addbulkbooks from './pages/Addbulkbooks';
import Addbulkseminar from './pages/Addbulkseminar';
import Addbulkprojects from './pages/Addbulkprojects';

import Viewallstudents from './pages/Viewallstudents';

import Addbosbulk from './pages/Addbosbulk';
import Addcbcsbulk from './pages/Addcbcsbulk';
import Addsyllabusrevbulk from './pages/Addsyllabusrevbulk';
import Addemployabilitybulk from './pages/Addemployabilitybulk';
import Addexplearningbulk from './pages/Addexplearningbulk';

import Addadmissionbulk from './pages/Addadmissionbulk';
import Addreservecatbulk from './pages/Addreservecatbulk';
import Addteacherdatabulk from './pages/Addteacherdatabulk';
import Addpassexambulk from './pages/Addpassexambulk';
import Addresultbulk from './pages/Addresultbulk';
import Addteacherawardbulk from './pages/Addteacherawardbulk';
import Addexamautomationbulk from './pages/Addexamautomationbulk';
import Addmenteesbulk from './pages/Addmenteesbulk';

import Addinstawardsbulk from './pages/Addinstawardsbulk';
import Addextactbulk from './pages/Addextactbulk';
import Addcollaborationbulk from './pages/Addcollaborationbulk';
import Addmoubulk from './pages/Addmoubulk';
import Addecontentbulk from './pages/Addecontentbulk';
import Addseedmbulk from './pages/Addseedmbulk';
import Addteacherfellowbulk from './pages/Addteacherfellowbulk';
import Addresearchfellowbulk from './pages/Addresearchfellowbulk';

import Addictbulk from './pages/Addictbulk';
import Addexpenditurebulk from './pages/Addexpenditurebulk';
import Addlibrarybulk from './pages/Addlibrarybulk';

import Addscholarshipbulk from './pages/Addscholarshipbulk';
import Addskilldevbulk from './pages/Addskilldevbulk';
import Addcareercounselbulk from './pages/Addcareercounselbulk';
import Addplacementbulk from './pages/Addplacementbulk';
import Addhigheredubulk from './pages/Addhigheredubulk';
import Addhigherexambulk from './pages/Addhigherexambulk';
import Addawardsbulk from './pages/Addawardsbulk';
import Addalumniconbulk from './pages/Addalumniconbulk';

import Addegovernbulk from './pages/Addegovernbulk';
import Addteacherfsbulk from './pages/Addteacherfsbulk';
import Addfundsbulk from './pages/Addfundsbulk';
import Addqualitybulk from './pages/Addqualitybulk';



import Addemployability from './pages/Addemployability';
import Viewemployability from './pages/Viewemployability';
import Editemployability from './pages/Editemployability';
import Addphdguide from './pages/Addphdguide';
import Viewphdguide from './pages/Viewphdguide';
import Editphdguide from './pages/Editphdguide';
import Addteacheraward from './pages/Addteacheraward';
import Viewteacheraward from './pages/Viewteacheraward';
import Editteacheraward from './pages/Editteacheraward';
import Addexamautomation from './pages/Addexamautomation';
import Viewexamautomation from './pages/Viewexamautomation';
import Editexamautomation from './pages/Editexamautomation';
import Addteacherfellow from './pages/Addteacherfellow';
import Viewteacherfellow from './pages/Viewteacherfellow';
import Editteacherfellow from './pages/Editteacherfellow';
import Addresearchfellow from './pages/Addresearchfellow';
import Viewresearchfellow from './pages/Viewresearchfellow';
import Editresearchfellow from './pages/Editresearchfellow';
import Addmentees from './pages/Addmentees';
import Viewmentees from './pages/Viewmentees';
import Editmentees from './pages/Editmentees';
import Addalumnicon from './pages/Addalumnicon';
import Viewalumnicon from './pages/Viewalumnicon';
import Editalumnicon from './pages/Editalumnicon';

import Viewlibbookstudents from './pages/Viewlibbookstudents';
import Enrollstudentsext from './pages/Enrollstudentsext';

import Addexplearnprojbulk from './pages/Addexplearnprojbulk';
import Addfieldprojbulk from './pages/Addfieldprojbulk';
import Addteacherguidebulk from './pages/Addteacherguidebulk';
import Addsportsactbulk from './pages/Addsportsactbulk';
import Addegovbulk from './pages/Addegovbulk';
import Addeventbulk from './pages/Addeventbulk';
import Addqualityinitbulk from './pages/Addqualityinitbulk';
import Addfdpbulk from './pages/Addfdpbulk';
import Addmouactbulk from './pages/Addmouactbulk';

import Viewallclasses from './pages/Viewallclasses';
import Viewallcoursefiles from './pages/Viewallcoursefiles';
import Viewallassignments from './pages/Viewallassignments';

import Viewincubation from './pages/Viewincubation';
import Addincubation from './pages/Addincubation';
import Editincubation from './pages/Editincubation';

import Viewstartup from './pages/Viewstartup';
import Addstartup from './pages/Addstartup';
import Editstartup from './pages/Editstartup';
import Viewcombined from './pages/Viewcombined';

import Addphdguidebulk from './pages/Addphdguidebulk';

import Addcbcsuao from './pages/Addcbcsuao';
import Editcbcsuao from './pages/Editcbcsuao';
import Viewcbcsuao from './pages/Viewcbcsuao';
import Addsyllabusrevaqao from './pages/Addsyllabusrevaqao';
import Editsyllabusrevaqao from './pages/Editsyllabusrevaqao';
import Viewsyllabusrevaqao from './pages/Viewsyllabusrevaqao';

import Viewemployabilityadmin from './pages/Viewemployabilityadmin';
import Viewsyllabusrevadmin from './pages/Viewsyllabusrevadmin';
import Viewexplearningadmin from './pages/Viewexplearningadmin';
import Viewphdguideadmin from './pages/Viewphdguideadmin';
import Viewresearchfellowadmin from './pages/Viewresearchfellowadmin';
import Viewteacherfellowadmin from './pages/Viewteacherfellowadmin';
import Classstudentsmsec from './pages/Classstudentsmsec';
import Viewextawardsadmin from './pages/Viewextawardsadmin';

import Viewecontentadmin from './pages/Viewecontentadmin';
import Viewalumniconadmin from './pages/Viewalumniconadmin';
import Viewresultadmin from './pages/Viewresultadmin';
import Viewexamautomationadmin from './pages/Viewexamautomationadmin';
import Viewmenteesadmin from './pages/Viewmenteesadmin';
import Viewteacherawardadmin from './pages/Viewteacherawardadmin';

import Viewseedmadmin from './pages/Viewseedmadmin';
import Viewconsultancyadmin from './pages/Viewconsultancyadmin';
import Viewscholarshipadmin from './pages/Viewscholarshipadmin';

import Qspeeradd from './pages/Qspeeradd';
import Qspeer1 from './pages/Qspeer1';
import Viewqspeer from './pages/Viewqspeer';
import Viewqsemployers from './pages/Viewqsemployers';
import Viewdeptreport from './pages/Viewdeptreport';

import Selectattendanceweek from './pages/Selectattendanceweek';
import ClassAttendanceweek from './pages/ClassAttendanceweek';
import Viewweeklyattendance from './pages/Viewweeklyattendance';
import Viewplacementall from './pages/Viewplacementall';
import Addkpibulk from './pages/Addkpibulk';
import Viewsupportingdocquery from './pages/Viewsupportingdocquery';
import Viewsupportingdocqueryuser from './pages/Viewsupportingdocqueryuser';

import Viewallhod from './pages/Viewallhod';
import Noaccess from './pages/Noaccess';


import Viewincubationadmin from './pages/Viewincubationadmin';
import Viewsportsactadmin from './pages/Viewsportsactadmin';
import Viewfdpadmin from './pages/Viewfdpadmin';
import Viewfieldprojadmin from './pages/Viewfieldprojadmin';
import Viewfundsadmin from './pages/Viewfundsadmin';
import Viewqualityinitadmin from './pages/Viewqualityinitadmin';
import Viewexplearnprojadmin from './pages/Viewexplearnprojadmin';

import Viewprojectyrdocs from './pages/Viewprojectyrdocs';
import Viewallseminardocs from './pages/Viewallseminardocs';
import Viewallsemreimbdocs from './pages/Viewallsemreimbdocs';
import Viewallpubdocs from './pages/Viewallpubdocs';
import Viewallpubdoccomments from './pages/Viewallpubdoccomments';
import Viewallpubdoccomcy from './pages/Viewallpubdoccomcy';
import Selectperiod from './pages/Selectperiod';
import Viewexplearningdocs from './pages/Viewexplearningdocs';
import Viewemployabilitydocs from './pages/Viewemployabilitydocs';
import Viewsyllabusrevdocs from './pages/Viewsyllabusrevdocs';
import Viewallpatentdocs from './pages/Viewallpatentdocs';
import Viewallbookdoccomments from './pages/Viewallbookdoccomments';
import Viewinnovationdoccomments from './pages/Viewinnovationdoccomments';
import Viewinnovationdoccommentsd from './pages/Viewinnovationdoccommentsd';
import Viewteacherfellowdoccomments from './pages/Viewteacherfellowdoccomments';
import Viewteacherawarddoccomments from './pages/Viewteacherawarddoccomments';
import Viewadmissiondoccomments from './pages/Viewadmissiondoccomments';
import Viewscholarshipdoccomments from './pages/Viewscholarshipdoccomments';
import Viewskilldevdoccomments from './pages/Viewskilldevdoccomments';
import Viewplacementdoccomments from './pages/Viewplacementdoccomments';
import Viewcareerdoccomments from './pages/Viewcareerdoccomments';
import Vieweventdoccomments from './pages/Vieweventdoccomments';
import Viewseedmdoscomments from './pages/Viewseedmdoscomments';
import Viewseedmdoccommentsd from './pages/Viewseedmdoccommentsd';
import Viewaddonccomments from './pages/Viewaddoncdoccomments';
import Viewictdoccomments from './pages/Viewictdoccomments';
import Viewbosdoccomments from './pages/Viewbosdoccomments';
import Viewcollabdoccomments from './pages/Viewcollabdoccomments';
import Viewmoudoccomments from './pages/Viewmoudoccomments';
import Viewscholarshipdocs from './pages/Viewscholarshipdoccommentsd';

import Viewcurgap from './pages/Viewcurgap';
import Viewcurgapadmin from './pages/Viewcurgapadmin';
import Addcurgap from './pages/Addcurgap';
import Editcurgap from './pages/Editcurgap';

import Viewprograms from './pages/Viewprograms';
import Addprograms from './pages/Addprograms';
import Editprograms from './pages/Editprograms';
import Addprogrambulk from './pages/Addprogrambulk';

import Viewdeppublications from './pages/Viewdeppublications';
import Adddeppub from './pages/Adddeppub';
import Editdeppub from './pages/Editdeppub';
import Viewdeppublicationsadmin from './pages/Viewdeppublicationsadmin';

import Viewstudpub from './pages/Viewstudpub';
import Addstudpub from './pages/Addstudpub';
import Editstudpub from './pages/Editstudpub';
import Viewstudpubadmin from './pages/Viewstudpubadmin';
import Viewuniversitymetric from './pages/Viewuniversitymetric';
import Viewuniversitymetric1 from './pages/Viewuniversitymetric1';
import Viewaffiliatedmetric from './pages/Viewaffiliatedmetric';
import Adminaffiliatedmetric from './pages/Adminaffiliatedmetric';
import Viewaffiliatedmetricnew from './pages/Viewaffiliatedmetricnew';
import Adminaffiliatedmetricnew from './pages/Adminaffiliatedmetricnew';
import Adminuniversitymetric from './pages/Adminuniversitymetric';
import Viewremedial from './pages/Viewremedial';
import Addremedial from './pages/Addremedial';
import Editremedial from './pages/Editremedial';
import Viewremedialadmin from './pages/Viewremedialadmin';
import Viewcurstructure from './pages/Viewcurstructure';
import Addcurstructure from './pages/Addcurstructure';
import Editcurstructure from './pages/Editcurstructure';
import Viewcurstructureadmin from './pages/Viewcurstructureadmin';
import Viewnbaug from './pages/Viewnbaug';

import Viewnbaug11 from './pages/Viewnbaug11';
import Addnbaug11 from './pages/Addnbaug11';
import Viewnbaug11admin from './pages/Viewnbaug11admin';

import Viewgoals from './pages/Viewgoals';
import Addgoals from './pages/Addgoals';

import Viewlessonnew from './pages/Viewlessonnew';
import Addlessonplannew from './pages/Addlessonplannew';
import Editlessonplannew from './pages/Editlessonplannew';
import Editworkloadnew from './pages/Editworkloadnew';
import Addclass from './pages/Addclass';

import Viewleavebalance from './pages/Viewleavebalance';
import Addleavebalance from './pages/Addleavebalance';
import Editleavebalance from './pages/Editleavebalance';

import Viewdeptfeedbackcount from './pages/Viewdeptfeedbackcount';
import Viewsssqscore from './pages/Viewsssqscore';
import Viewssscount from './pages/Viewssscount';
import Viewssstext from './pages/Viewssstext';

import Viewleaveapply from './pages/Viewleaveapply';
import Addleaveapply from './pages/Addleaveapply';
import Viewleavepending from './pages/Viewleavepending';
import Viewleavependinghod from './pages/Viewleavependinghod';
import Viewleavependingprincipal from './pages/Viewleavependingprincipal';
import Viewleavehistory from './pages/Viewleavehistory';

import Viewnbadiploma from './pages/Viewnbadiploma';
import Viewnbamca from './pages/Viewnbamca';

import Viewreservecatdoccomments from './pages/Viewreservecatdoccomments';
import Viewcbcsdoc from './pages/Viewcbcsdoc';
import Viewexplearningprojdoc from './pages/Viewexplearningprojdoc';
// import Viewexplearningprojdoc from './pages/Viewexplearningprojdoc';
import Viewteacherdatadoc from './pages/Viewteacherdatadoc';
import Viewphdguidedoccomments from './pages/Viewphdguidedoccomments';
import Viewpassexamdocs from './pages/Viewpassexamdocs';
import Viewresearchfellodocs from './pages/Viewresearchfellodocs';
import Viewresearchfellodocsd from './pages/Viewresearchfellodocsd';
import Selectproject from './pages/Selectproject';
import Selectmyproject from './pages/Selectmyproject';
import Viewresearchfellowadmfac from './pages/Viewresearchfellowadmfac';
import Selectmyprojectadm from './pages/Selectmyprojectadm';
import Viewprojectbalance from './pages/Viewprojectbalance';

import Addinvoice from './pages/Addinvoice';
import Viewinvoice from './pages/Viewinvoice';
import Editinvoice from './pages/Editinvoice';

import Addprojectbalance from './pages/Addprojectbalance';
import Editprojectbalance from './pages/Editprojectbalance';

import Viewkpiuser1 from './pages/Viewkpiuser1';
import Addallkpi1 from './pages/Addallkpi1';
import Viewsyllabuserror from './pages/Viewsyllabuserror';

import Viewadmissiondoccommentsd from './pages/Viewadmissiondoccommentsd';
import Viewallbookdoccommentsd from './pages/Viewallbookdoccommentsd';
import Viewallpatentdocsd from './pages/Viewallpatentdocsd';
import Viewallpubdoccomcyd from './pages/Viewallpubdoccomcyd';
import Viewallpubdoccommentsd from './pages/Viewallpubdoccommentsd';
import Viewallpubdocsd from './pages/Viewallpubdocsd';
import Viewallseminardocsd from './pages/Viewallseminardocsd';
import Viewallsemreimbdocsd from './pages/Viewallsemreimbdocsd';
import Viewbosdoccommentsd from './pages/Viewbosdoccommentsd';
import Viewcareerdoccommentsd from './pages/Viewcareerdoccommentsd';
import Viewcbcsdocd from './pages/Viewcbcsdocd';
import Viewcollabdoccommentsd from './pages/Viewcollabdoccommentsd';
import Viewemployabilitydocsd from './pages/Viewemployabilitydocsd';
import Vieweventdoccommentsd from './pages/Vieweventdoccommentsd';
import Viewexplearningdocsd from './pages/Viewexplearningdocsd';
import Viewexplearningprojdocd from './pages/Viewexplearningprojdocd';
import Viewictdoccommentsd from './pages/Viewictdoccommentsd';
import Viewmoudoccommentsd from './pages/Viewmoudoccommentsd';
import Viewpassexamdocsd from './pages/Viewpassexamdocsd';
import Viewphdguidedoccommentsd from './pages/Viewphdguidedoccommentsd';
import Viewplacementdoccommentsd from './pages/Viewplacementdoccommentsd';
import Viewprojectyrdocsd from './pages/Viewprojectyrdocsd';
import Viewreservecatdoccommentsd from './pages/Viewreservecatdoccommentsd';
import Viewscholarshipdoccommentsd from './pages/Viewskilldevdoccommentsd';
import Viewsyllabusrevdocsd from './pages/Viewsyllabusrevdocsd';
import Viewteacherawarddoccommentsd from './pages/Viewteacherawarddoccommentsd';
import Viewteacherdatadocd from './pages/Viewteacherdatadocd';
import Viewteacherfellowdoccommentsd from './pages/Viewteacherfellowdoccommentsd';
import Viewaddoncdoccommentsd from './pages/Viewaddoncdoccommentsd';

import Viewnprogcourseadminlinks from './pages/Viewnprogcourseadminlinks';


import Checkocr from './pages/Checkocr';

import Ocrtest1 from './pages/Ocrtest1';
import Ocrtest2 from './pages/Ocrtest2';
import Ocrtest3 from './pages/Ocrtest3';
import Ocrtest31 from './pages/Ocrtest31';
import Ocrtest32 from './pages/Ocrtest32';
import Ocrtest33 from './pages/Ocrtest33';
import Ocrtest34 from './pages/Ocrtest34';
import Selectawsconfignew from './pages/Selectawsconfignew';
import Viewleaveapplyadmin from './pages/Viewleaveapplyadmin';
import Offlinewelcome from './pages/Offlinewelcome';
import Geotagtest1 from './pages/Geotagtest1';
import Selectawsphoto from './pages/Selectawsphoto';

import Addpdfbulk from './pages/Addpdfbulk';
import Pdftest2 from './pages/Pdftest2';
import Pdftest3 from './pages/Pdftest3';
import Selectawspdf from './pages/Selectawspdf';

import Thankyouafter from './pages/Thankyouafter';

import Viewfieldprojdd from './pages/Viewfieldprojdd';
import Viewfdpcold from './pages/Viewfdpcold';



import Viewexamautomationd from './pages/Viewexamautomationd';
import Viewincubationd from './pages/Viewincubationd';
import Viewalumnicond from './pages/Viewalumnicond';
import Viewegovernd from './pages/Viewegovernd';
import Viewhigheredud from './pages/Viewhigheredud';
import Viewhigherexamd from './pages/Viewhigherexamd';
import Viewlibraryexpd from './pages/Viewlibraryexpd';
import Viewexpenditured from './pages/Viewexpenditured';
import Viewextensionactd from './pages/Viewextensionactd';
import Viewconsultancyd from './pages/Viewconsultancyd';
import Viewecontentd from './pages/Viewecontentd';
import Viewresultd from './pages/Viewresultd';
import Viewqualityd from './pages/Viewqualityd';
import Viewqualityinitd from './pages/Viewqualityinitd';
import Viewfundsd from './pages/Viewfundsd';
import Viewextawardsd from './pages/Viewextawardsd';
import Viewteacherfsd from './pages/Viewteacherfsd';


import Viewdeletebyuser from './pages/Viewdeletebyuser';

import Viewbosexcelyear from './pages/Viewbosexcelyear';
import Viewboslinksyear from './pages/Viewboslinksyear';
import Viewcbcsexcelyear from './pages/Viewcbcsexcelyear';
import Viewaddonexcelyear from './pages/Viewaddonexcelyear';
import Viewaddonlinksyear from './pages/Viewaddonlinksyear';
import Viewexplearnprojexcel from './pages/Viewexplearnprojexcel';
import Viewexplearnaffexcel from './pages/Viewexplearnaffexcel';
import Viewaddondeclaration from './pages/Viewaddondeclaration';
import Viewadmissionexcel from './pages/Viewadmissionexcel';
import Viewadmissionlinks from './pages/Viewadmissionlinks';
import Viewreservecatlinks from './pages/Viewreservecatlinks';
import Viewphdguideexcel from './pages/Viewphdguideexcel';
import Viewphdguidelinks from './pages/Viewphdguidelinks';
import Viewpassexcel from './pages/Viewpassexcel';
import Viewpasslinks from './pages/Viewpasslinks';
import Viewplacementexcel from './pages/Viewplacementexcel';
import Viewplacementlinks from './pages/Viewplacementlinks';
import Viewhighereduexcel from './pages/Viewhighereduexcel';
import Viewhigheredulinks from './pages/Viewhigheredulinks';
import Viewhigherexamexcel from './pages/Viewhigherexamexcel';
import Viewhigherexamlinks from './pages/Viewhigherexamlinks';
import Viewprojectexcel from './pages/Viewprojectexcel';
import Viewprojectlinks from './pages/Viewprojectlinks';
import Viewpubexcel from './pages/Viewpubexcel';
import Viewpublinks from './pages/Viewpublinks';
import Viewmouexcel from './pages/Viewmouexcel';
import Viewmoulinks from './pages/Viewmoulinks';
import Viewbooklinks from './pages/Viewbooklinks';
import Viewbooksexcel from './pages/Viewbooksexcel';
import Viewcollabexcel from './pages/Viewcollabexcel';
import Viewextensionexcel from './pages/Viewextensionexcel';
import Viewextensionlinks from './pages/Viewextensionlinks';
import Viewawardsdocsd from './pages/Viewawardsdocsd';
import Viewawardsexcel from './pages/Viewawardsexcel';
import Viewawardslink from './pages/Viewawardslink';
import Viewscholarshipexcel from './pages/Viewscholarshipexcel';
import Viewskilldevexcel from './pages/Viewskilldevexcel';
import Viewskilldevlinks from './pages/Viewskilldevlinks';
import Vieweventexcel from './pages/Vieweventexcel';
import Vieweventlinks from './pages/Vieweventlink';
import Viewfdpexcel from './pages/Viewfdpexcel';
import Viewfdplinks from './pages/Viewfdplinks';
import Viewhnewprog from './pages/Viewhnewprog';
import Addhnewprog from './pages/Addhnewprog';
import Edithnewprog from './pages/Edithnewprog';
import Viewhnewprogadmin from './pages/Viewhnewprogadmin';
import Viewntimesheetadmin from './pages/Viewntimesheetadmin';
import Viewntimesheet from './pages/Viewntimesheet';
import Addntimesheet from './pages/Addntimesheet';
import Editntimesheet from './pages/Editntimesheet';

import Editnsop from './pages/Editnsop';
import Viewnsop from './pages/Viewnsop';
import Addnsop from './pages/Addnsop';
import Viewnsopadmin from './pages/Viewnsopadmin';

import Addkpibulkin from './pages/Addkpibulkin';

import Editnacademic from './pages/Editnacademic';
import Viewnacademic from './pages/Viewnacademic';
import Addnacademic from './pages/Addnacademic';
import Addnacademicbulk from './pages/Addnacademicbulk';
import Viewnacademicadmin from './pages/Viewnacademicadmin';

import Viewsportsactdocs from './pages/Viewsportsactdocs';

import Editnworkbook from './pages/Editnworkbook';
import Viewnworkbook from './pages/Viewnworkbook';
import Addnworkbook from './pages/Addnworkbook';
import Addnworkbookbulk from './pages/Addnworkbookbulk';
import Viewnworkbookadmin from './pages/Viewnworkbookadmin';

import Editnenrollment from './pages/Editnenrollment';
import Viewnenrollment from './pages/Viewnenrollment';
import Addnenrollment from './pages/Addnenrollment';
import Addnenrollmentbulk from './pages/Addnenrollmentbulk';
import Viewnenrollmentadmin from './pages/Viewnenrollmentadmin';

import Editncourseplan from './pages/Editncourseplan';
import Viewncourseplan from './pages/Viewncourseplan';
import Addncourseplan from './pages/Addncourseplan';
import Addncourseplanbulk from './pages/Addncourseplanbulk';
import Viewncourseplanadmin from './pages/Viewncourseplanadmin';

import Editninter from './pages/Editninter';
import Viewninter from './pages/Viewninter';
import Addninter from './pages/Addninter';
import Addninterbulk from './pages/Addninterbulk';
import Viewninteradmin from './pages/Viewninteradmin';

import Editnclinicskills from './pages/Editnclinicskills';
import Viewnclinicskills from './pages/Viewnclinicskills';
import Addnclinicskills from './pages/Addnclinicskills';
import Addnclinicskillsbulk from './pages/Addnclinicskillsbulk';
import Viewnclinicskillsadmin from './pages/Viewnclinicskillsadmin';

import Editnhlearner from './pages/Editnhlearner';
import Viewnhlearner from './pages/Viewnhlearner';
import Addnhlearner from './pages/Addnhlearner';
import Addnhlearnerbulk from './pages/Addnhlearnerbulk';
import Viewnhlearneradmin from './pages/Viewnhlearneradmin';

import Editnotherstud from './pages/Editnotherstud';
import Viewnotherstud from './pages/Viewnotherstud';
import Addnotherstud from './pages/Addnotherstud';
import Addnotherstudbulk from './pages/Addnotherstudbulk';
import Viewnotherstudadmin from './pages/Viewnotherstudadmin';

import Editnreval from './pages/Editnreval';
import Viewnreval from './pages/Viewnreval';
import Addnreval from './pages/Addnreval';
import Addnrevalbulk from './pages/Addnrevalbulk';
import Viewnrevaladmin from './pages/Viewnrevaladmin';

import Editnfacilities from './pages/Editnfacilities';
import Viewnfacilities from './pages/Viewnfacilities';
import Addnfacilities from './pages/Addnfacilities';
import Addnfacilitiesbulk from './pages/Addnfacilitiesbulk';
import Viewnfacilitiesadmin from './pages/Viewnfacilitiesadmin';

import Editndepfunding from './pages/Editndepfunding';
import Viewndepfunding from './pages/Viewndepfunding';
import Addndepfunding from './pages/Addndepfunding';
import Addndepfundingbulk from './pages/Addndepfundingbulk';
import Viewndepfundingadmin from './pages/Viewndepfundingadmin';

import Editnprogcourse from './pages/Editnprogcourse';
import Viewnprogcourse from './pages/Viewnprogcourse';
import Addnprogcourse from './pages/Addnprogcourse';
import Addnprogcoursebulk from './pages/Addnprogcoursebulk';
import Viewnprogcourseadmin from './pages/Viewnprogcourseadmin';

import Editnnvacstud from './pages/Editnnvacstud';
import Viewnnvacstud from './pages/Viewnnvacstud';
import Addnnvacstud from './pages/Addnnvacstud';
import Addnnvacstudbulk from './pages/Addnnvacstudbulk';
import Viewnnvacstudadmin from './pages/Viewnnvacstudadmin';

import Editnnursinginter from './pages/Editnnursinginter';
import Viewnnursinginter from './pages/Viewnnursinginter';
import Addnnursinginter from './pages/Addnnursinginter';
import Addnnursinginterbulk from './pages/Addnnursinginterbulk';
import Viewnnursinginteradmin from './pages/Viewnnursinginteradmin';


import Editnnexp from './pages/Editnnexp';
import Viewnnexp from './pages/Viewnnexp';
import Addnnexp from './pages/Addnnexp';
import Addnnexpbulk from './pages/Addnnexpbulk';
import Viewnnexpadmin from './pages/Viewnnexpadmin';

import Editnnratio from './pages/Editnnratio';
import Viewnnratio from './pages/Viewnnratio';
import Addnnratio from './pages/Addnnratio';
import Addnnratiobulk from './pages/Addnnratiobulk';
import Viewnnratioadmin from './pages/Viewnnratioadmin';

import Editnnmentor from './pages/Editnnmentor';
import Viewnnmentor from './pages/Viewnnmentor';
import Addnnmentor from './pages/Addnnmentor';
import Addnnmentorbulk from './pages/Addnnmentorbulk';
import Viewnnmentoradmin from './pages/Viewnnmentoradmin';

import Editnnexam from './pages/Editnnexam';
import Viewnnexam from './pages/Viewnnexam';
import Addnnexam from './pages/Addnnexam';
import Addnnexambulk from './pages/Addnnexambulk';
import Viewnnexamadmin from './pages/Viewnnexamadmin';

import Editnecourse from './pages/Editnecourse';
import Viewnecourse from './pages/Viewnecourse';
import Addnecourse from './pages/Addnecourse';
import Addnecoursebulk from './pages/Addnecoursebulk';
import Viewnecourseadmin from './pages/Viewnecourseadmin';

import Editnnextension from './pages/Editnnextension';
import Viewnnextension from './pages/Viewnnextension';
import Addnnextension from './pages/Addnnextension';
import Addnnextensionbulk from './pages/Addnnextensionbulk';
import Viewnnextensionadmin from './pages/Viewnnextensionadmin';

import Editnncollab from './pages/Editnncollab';
import Viewnncollab from './pages/Viewnncollab';
import Addnncollab from './pages/Addnncollab';
import Addnncollabbulk from './pages/Addnncollabbulk';
import Viewnncollabadmin from './pages/Viewnncollabadmin';

import Editnnmou from './pages/Editnnmou';
import Viewnnmou from './pages/Viewnnmou';
import Addnnmou from './pages/Addnnmou';
import Addnnmoubulk from './pages/Addnnmoubulk';
import Viewnnmouadmin from './pages/Viewnnmouadmin';

import Editnnpatients from './pages/Editnnpatients';
import Viewnnpatients from './pages/Viewnnpatients';
import Addnnpatients from './pages/Addnnpatients';
import Addnnpatientsbulk from './pages/Addnnpatientsbulk';
import Viewnnpatientsadmin from './pages/Viewnnpatientsadmin';

import Editnnexposure from './pages/Editnnexposure';
import Viewnnexposure from './pages/Viewnnexposure';
import Addnnexposure from './pages/Addnnexposure';
import Addnnexposurebulk from './pages/Addnnexposurebulk';
import Viewnnexposureadmin from './pages/Viewnnexposureadmin';

import Dasherp1 from './pages/Dasherp1';
import Viewkpiuser2 from './pages/Viewkpiuser2';
import Viewkpipending from './pages/Viewkpipending';


import Editnclient from './pages/Editnclient';
import Viewnclient from './pages/Viewnclient';
import Addnclient from './pages/Addnclient';
import Addnclientbulk from './pages/Addnclientbulk';
import Viewnclientadmin from './pages/Viewnclientadmin';

import Editnmilestone from './pages/Editnmilestone';
import Viewnmilestone from './pages/Viewnmilestone';
import Addnmilestone from './pages/Addnmilestone';
import Addnmilestonebulk from './pages/Addnmilestonebulk';
import Viewnmilestoneadmin from './pages/Viewnmilestoneadmin';

import Editninvoice from './pages/Editninvoice';
import Viewninvoice from './pages/Viewninvoice';
import Addninvoice from './pages/Addninvoice';
import Addninvoicebulk from './pages/Addninvoicebulk';
import Viewninvoiceadmin from './pages/Viewninvoiceadmin';

import Editngstgain from './pages/Editngstgain';
import Viewngstgain from './pages/Viewngstgain';
import Addngstgain from './pages/Addngstgain';
import Addngstgainbulk from './pages/Addngstgainbulk';
import Viewngstgainadmin from './pages/Viewngstgainadmin';

import Editnvendor from './pages/Editnvendor';
import Viewnvendor from './pages/Viewnvendor';
import Addnvendor from './pages/Addnvendor';
import Addnvendorbulk from './pages/Addnvendorbulk';
import Viewnvendoradmin from './pages/Viewnvendoradmin';

import Editnvworkorder from './pages/Editnvworkorder';
import Viewnvworkorder from './pages/Viewnvworkorder';
import Addnvworkorder from './pages/Addnvworkorder';
import Addnvworkorderbulk from './pages/Addnvworkorderbulk';
import Viewnvworkorderadmin from './pages/Viewnvworkorderadmin';

import Editnvinvoice from './pages/Editnvinvoice';
import Viewnvinvoice from './pages/Viewnvinvoice';
import Addnvinvoice from './pages/Addnvinvoice';
import Addnvinvoicebulk from './pages/Addnvinvoicebulk';
import Viewnvinvoiceadmin from './pages/Viewnvinvoiceadmin';

import Editntrialbalance from './pages/Editntrialbalance';
import Viewntrialbalance from './pages/Viewntrialbalance';
import Addntrialbalance from './pages/Addntrialbalance';
import Addntrialbalancebulk from './pages/Addntrialbalancebulk';
import Viewntrialbalanceadmin from './pages/Viewntrialbalanceadmin';

import Viewemployabilitydocsdep from './pages/Viewemployabilitydocsdep';

import Viewsyllabusrevdocsdep from './pages/Viewsyllabusrevdocsdep';
import Viewexplearningdocsdep from './pages/Viewexplearningdocsdep';
import Viewecontentdep from './pages/Viewecontentdep';
import Viewhigheredudep from './pages/Viewhigheredudep';
import Viewhigherexamdep from './pages/Viewhigherexamdep';
import Viewplacementdep from './pages/Viewplacementdep';

import Dasherpadmin1 from './pages/Dasherpadmin1';
import Dashfeedbackreports from './pages/Dashfeedbackreports';


import Editnbudget from './pages/Editnbudget';
import Viewnbudget from './pages/Viewnbudget';
import Addnbudget from './pages/Addnbudget';
import Addnbudgetbulk from './pages/Addnbudgetbulk';
import Viewnbudgetadmin from './pages/Viewnbudgetadmin';

import Editnledger from './pages/Editnledger';
import Viewnledger from './pages/Viewnledger';
import Addnledger from './pages/Addnledger';
import Addnledgerbulk from './pages/Addnledgerbulk';
import Viewnledgeradmin from './pages/Viewnledgeradmin';

import Editnaccounts from './pages/Editnaccounts';
import Viewnaccounts from './pages/Viewnaccounts';
import Addnaccounts from './pages/Addnaccounts';
import Addnaccountsbulk from './pages/Addnaccountsbulk';
import Viewnaccountsadmin from './pages/Viewnaccountsadmin';


import Editnseedmoney from './pages/Editnseedmoney';
import Viewnseedmoney from './pages/Viewnseedmoney';
import Addnseedmoney from './pages/Addnseedmoney';
import Addnseedmoneybulk from './pages/Addnseedmoneybulk';
import Viewnseedmoneyadmin from './pages/Viewnseedmoneyadmin';

import Editnbudgetreq from './pages/Editnbudgetreq';
import Viewnbudgetreq from './pages/Viewnbudgetreq';
import Addnbudgetreq from './pages/Addnbudgetreq';
import Addnbudgetreqbulk from './pages/Addnbudgetreqbulk';
import Viewnbudgetreqadmin from './pages/Viewnbudgetreqadmin';

import Editnoodreq from './pages/Editnoodreq';
import Viewnoodreq from './pages/Viewnoodreq';
import Addnoodreq from './pages/Addnoodreq';
import Addnoodreqbulk from './pages/Addnoodreqbulk';
import Viewnoodreqadmin from './pages/Viewnoodreqadmin';

import Editneventreq from './pages/Editneventreq';
import Viewneventreq from './pages/Viewneventreq';
import Addneventreq from './pages/Addneventreq';
import Addneventreqbulk from './pages/Addneventreqbulk';
import Viewneventreqadmin from './pages/Viewneventreqadmin';

import Editnassistantreq from './pages/Editnassistantreq';
import Viewnassistantreq from './pages/Viewnassistantreq';
import Addnassistantreq from './pages/Addnassistantreq';
import Addnassistantreqbulk from './pages/Addnassistantreqbulk';
import Viewnassistantreqadmin from './pages/Viewnassistantreqadmin';

import Editnhousingreq from './pages/Editnhousingreq';
import Viewnhousingreq from './pages/Viewnhousingreq';
import Addnhousingreq from './pages/Addnhousingreq';
import Addnhousingreqbulk from './pages/Addnhousingreqbulk';
import Viewnhousingreqadmin from './pages/Viewnhousingreqadmin';

import Editntravelsupport from './pages/Editntravelsupport';
import Viewntravelsupport from './pages/Viewntravelsupport';
import Addntravelsupport from './pages/Addntravelsupport';
import Addntravelsupportbulk from './pages/Addntravelsupportbulk';
import Viewntravelsupportadmin from './pages/Viewntravelsupportadmin';


import Editncompanies from './pages/Editncompanies';
import Viewncompanies from './pages/Viewncompanies';
import Addncompanies from './pages/Addncompanies';
import Addncompaniesbulk from './pages/Addncompaniesbulk';
import Viewncompaniesadmin from './pages/Viewncompaniesadmin';

import Editnjobs from './pages/Editnjobs';
import Viewnjobs from './pages/Viewnjobs';
import Addnjobs from './pages/Addnjobs';
import Addnjobsbulk from './pages/Addnjobsbulk';
import Viewnjobsadmin from './pages/Viewnjobsadmin';

import Editnjobstudents from './pages/Editnjobstudents';
import Viewnjobstudents from './pages/Viewnjobstudents';
import Addnjobstudents from './pages/Addnjobstudents';
import Addnjobstudentsbulk from './pages/Addnjobstudentsbulk';
import Viewnjobstudentsadmin from './pages/Viewnjobstudentsadmin';

import Editnhighereducation from './pages/Editnhighereducation';
import Viewnhighereducation from './pages/Viewnhighereducation';
import Addnhighereducation from './pages/Addnhighereducation';
import Addnhighereducationbulk from './pages/Addnhighereducationbulk';
import Viewnhighereducationadmin from './pages/Viewnhighereducationadmin';



import Viewnoffcampusadmin from './pages/Viewnoffcampusadmin';
import Viewnjobsapplyadmin from './pages/Viewnjobsapplyadmin';
import Viewnhigheredurepadmin from './pages/Viewnhigheredurepadmin';
import Viewnoffawardsadmin from './pages/Viewnoffawardsadmin';
import Viewnoffscholarshipadmin from './pages/Viewnoffscholarshipadmin';
import Viewnoffextensionadmin from './pages/Viewnoffextensionadmin';
import Viewnoffcoursesadmin from './pages/Viewnoffcoursesadmin';

import Viewdepfacdocs from './pages/Viewdepfacdocs';

import Editncommittees from './pages/Editncommittees';
import Viewncommittees from './pages/Viewncommittees';
import Addncommittees from './pages/Addncommittees';
import Addncommitteesbulk from './pages/Addncommitteesbulk';
import Viewncommitteesadmin from './pages/Viewncommitteesadmin';

import Editncommembers from './pages/Editncommembers';
import Viewncommembers from './pages/Viewncommembers';
import Addncommembers from './pages/Addncommembers';
import Addncommembersbulk from './pages/Addncommembersbulk';
import Viewncommembersadmin from './pages/Viewncommembersadmin';

import Editncomminutes from './pages/Editncomminutes';
import Viewncomminutes from './pages/Viewncomminutes';
import Addncomminutes from './pages/Addncomminutes';
import Addncomminutesbulk from './pages/Addncomminutesbulk';
import Viewncomminutesadmin from './pages/Viewncomminutesadmin';

import Editncomtasks from './pages/Editncomtasks';
import Viewncomtasks from './pages/Viewncomtasks';
import Addncomtasks from './pages/Addncomtasks';
import Addncomtasksbulk from './pages/Addncomtasksbulk';
import Viewncomtasksadmin from './pages/Viewncomtasksadmin';


import Addworkloadbulk from './pages/Addworkloadbulk';
import Addlessonplanbulk from './pages/Addlessonplanbulk';
import Dashcourse2 from './pages/Dashcourse2';
import Enrollstudentsext1 from './pages/Enrollstudentsext1';

import Viewlessonplanadmin from './pages/Viewlessonplanadmin';
import Viewworkloadadmin from './pages/Viewworkloadadmin';

import Editcoursefiles from './pages/Editcoursefiles';
import Viewcoursefiles from './pages/Viewcoursefiles';
import Addcoursefilesnew from './pages/Addcoursefilesnew';
import Addcoursefilesbulk from './pages/Addcoursefilesbulk';
import Viewcoursefilesadmin from './pages/Viewcoursefilesadmin';

import Editclass from './pages/Editclass';
import Viewclass from './pages/Viewclass';
import Addclassnew from './pages/Addclassnew';
import Addclassbulk from './pages/Addclassbulk';
import Viewclassadmin from './pages/Viewclassadmin';
import Addclassnewweeks from './pages/Addclassnewweeks';

import Selectprogramstt from './pages/Selectprogramstt';
import Selectsemsecyr from './pages/Selectsemsecyr';
import Viewclassprogram from './pages/Viewclassprogram';
import Viewclassseven from './pages/Viewclassseven';
import Viewallfacultieslms from './pages/Viewallfacultieslms';
import Viewworkloadadminfac from './pages/Viewworkloadadminfac';


import Editco from './pages/Editco';
import Viewco from './pages/Viewco';
import Addco from './pages/Addco';
import Addcobulk from './pages/Addcobulk';
import Viewcoadmin from './pages/Viewcoadmin';

import Editlannouncement from './pages/Editlannouncement';
import Viewlannouncement from './pages/Viewlannouncement';
import Addlannouncement from './pages/Addlannouncement';
import Addlannouncementbulk from './pages/Addlannouncementbulk';
import Viewlannouncementadmin from './pages/Viewlannouncementadmin';

import Editlsyllabus from './pages/Editlsyllabus';
import Viewlsyllabus from './pages/Viewlsyllabus';
import Addlsyllabus from './pages/Addlsyllabus';
import Addlsyllabusbulk from './pages/Addlsyllabusbulk';
import Viewlsyllabusadmin from './pages/Viewlsyllabusadmin';

import Editlcalendar from './pages/Editlcalendar';
import Viewlcalendar from './pages/Viewlcalendar';
import Addlcalendar from './pages/Addlcalendar';
import Addlcalendarbulk from './pages/Addlcalendarbulk';
import Viewlcalendaradmin from './pages/Viewlcalendaradmin';

import Edituser from './pages/Edituser';
import Viewuserfaculty from './pages/Viewuserfaculty';
import Viewuserstudent from './pages/Viewuserstudent';
import Viewuserhod from './pages/Viewuserhod';
import Adduser from './pages/Adduser';
import Adduserstudent from './pages/Adduserstudent';
import Adduserfaculty from './pages/Adduserfaculty';
import Adduserhod from './pages/Adduserhod';
import Adduserbulk from './pages/Adduserbulk';
import Adduserbulkhod from './pages/Adduserbulkhod';
import Adduserbulkstudent from './pages/Adduserbulkstudent';
import Viewuseradmin from './pages/Viewuseradmin';

import Viewlsyllabusemp from './pages/Viewlsyllabusemp';
import Addphoto from './pages/Addphoto';
import Selectawsconfigphoto from './pages/Selectawsconfigphoto';

import Selectperiodlms from './pages/Selectperiodlms';
import Viewallfacultieslmsdep from './pages/Viewallfacultieslmsdep';
import Deleteworkload from './pages/Deleteworkload';

import Newclassesf3 from './pages/Newclassesf3';
import Dashstud1 from './pages/Dashstud1';
import Selectawsconfigphotostud from './pages/Selectawsconfigphotostud';
import Addphotostud from './pages/Addphotostud';
import Viewclassprogramstud from './pages/Viewclassprogramstud';
import Viewenrolcourseyr from './pages/Viewenrolcourseyr';
import Viewcoursefilesstud from './pages/Viewcoursefilesstud';


import Editcurrenyear from './pages/Editcurrenyear';
import Viewcurrenyear from './pages/Viewcurrenyear';
import Addcurrenyear from './pages/Addcurrenyear';
import Addcurrenyearbulk from './pages/Addcurrenyearbulk';
import Viewcurrenyearadmin from './pages/Viewcurrenyearadmin';
import Viewassignmentstud from './pages/Viewassignmentstud';

import Selectawsconfigstudassignment from './pages/Selectawsconfigstudassignment';
import Addphotostudassignment from './pages/Addphotostudassignment';
import Viewcircularstud from './pages/Viewcircularstud';


import Editstudparents from './pages/Editstudparents';
import Viewstudparents from './pages/Viewstudparents';
import Addstudparents from './pages/Addstudparents';
import Addstudparentsbulk from './pages/Addstudparentsbulk';
import Viewstudparentsadmin from './pages/Viewstudparentsadmin';

import Classattendance4 from './pages/Classattendance4';

import Addlessonplanbulknew from './pages/Addlessonplanbulknew';

import Dashstud2 from './pages/Dashstud2';

import Classattendancefac from './pages/Classattendancefac';
import Classattendancefaccl from './pages/Classattendancefaccl';
import Classattendancestud from './pages/Classattendancestud';


import Editprojectledger from './pages/Editprojectledger';
import Viewprojectledger from './pages/Viewprojectledger';
import Addprojectledger from './pages/Addprojectledger';
import Addprojectledgerbulk from './pages/Addprojectledgerbulk';
import Viewprojectledgeradmin from './pages/Viewprojectledgeradmin';

import Editprojectcentral from './pages/Editprojectcentral';
import Viewprojectcentral from './pages/Viewprojectcentral';
import Addprojectcentral from './pages/Addprojectcentral';
import Addprojectcentralbulk from './pages/Addprojectcentralbulk';
import Viewprojectcentraladmin from './pages/Viewprojectcentraladmin';

import Editmaintenance from './pages/Editmaintenance';
import Viewmaintenance from './pages/Viewmaintenance';
import Addmaintenance from './pages/Addmaintenance';
import Addmaintenancebulk from './pages/Addmaintenancebulk';
import Viewmaintenanceadmin from './pages/Viewmaintenanceadmin';

import Editotherincome from './pages/Editotherincome';
import Viewotherincome from './pages/Viewotherincome';
import Addotherincome from './pages/Addotherincome';
import Addotherincomebulk from './pages/Addotherincomebulk';
import Viewotherincomeadmin from './pages/Viewotherincomeadmin';

import Viewfeedbackdata from './pages/Viewfeedbackdata';
import Viewvactodaystud from './pages/Viewvactodaystud';
import Viewdepdata from './pages/Viewdepdata';

import Editsyllabusrevadmin from './pages/Editsyllabusrevadmin';
import Editaddoncourseadmin from './pages/Editaddoncourseadmin';


import Editnaccountheads from './pages/Editnaccountheads';
import Viewnaccountheads from './pages/Viewnaccountheads';
import Addnaccountheads from './pages/Addnaccountheads';
import Addnaccountheadsbulk from './pages/Addnaccountheadsbulk';
import Viewnaccountheadsadmin from './pages/Viewnaccountheadsadmin';

import Editntransactions from './pages/Editntransactions';
import Viewntransactions from './pages/Viewntransactions';
import Addntransactions from './pages/Addntransactions';
import Addntransactionsbulk from './pages/Addntransactionsbulk';
import Viewntransactionsadmin from './pages/Viewntransactionsadmin';

import Editntledger from './pages/Editntledger';
import Viewntledger from './pages/Viewntledger';
import Addntledger from './pages/Addntledger';
import Addntledgerbulk from './pages/Addntledgerbulk';
import Viewntledgeradmin from './pages/Viewntledgeradmin';
import Selectfin from './pages/Selectfin';
import Datewisesummaryledger from './pages/Datewisesummaryledger';

import Viewsuperfaculty from './pages/Viewsuperfaculty';

import Reportcriteria1 from './pages/Reportcriteria1';


import Editnnursing812 from './pages/Editnnursing812';
import Viewnnursing812 from './pages/Viewnnursing812';
import Addnnursing812 from './pages/Addnnursing812';
import Addnnursing812bulk from './pages/Addnnursing812bulk';
import Viewnnursing812admin from './pages/Viewnnursing812admin';

import Editnnursing814 from './pages/Editnnursing814';
import Viewnnursing814 from './pages/Viewnnursing814';
import Addnnursing814 from './pages/Addnnursing814';
import Addnnursing814bulk from './pages/Addnnursing814bulk';
import Viewnnursing814admin from './pages/Viewnnursing814admin';

import Editnnursing815 from './pages/Editnnursing815';
import Viewnnursing815 from './pages/Viewnnursing815';
import Addnnursing815 from './pages/Addnnursing815';
import Addnnursing815bulk from './pages/Addnnursing815bulk';
import Viewnnursing815admin from './pages/Viewnnursing815admin';

import Editnnursing818 from './pages/Editnnursing818';
import Viewnnursing818 from './pages/Viewnnursing818';
import Addnnursing818 from './pages/Addnnursing818';
import Addnnursing818bulk from './pages/Addnnursing818bulk';
import Viewnnursing818admin from './pages/Viewnnursing818admin';

import Editnnursing424 from './pages/Editnnursing424';
import Viewnnursing424 from './pages/Viewnnursing424';
import Addnnursing424 from './pages/Addnnursing424';
import Addnnursing424bulk from './pages/Addnnursing424bulk';
import Viewnnursing424admin from './pages/Viewnnursing424admin';

import Editncompetitive from './pages/Editncompetitive';
import Viewncompetitive from './pages/Viewncompetitive';
import Addncompetitive from './pages/Addncompetitive';
import Addncompetitivebulk from './pages/Addncompetitivebulk';
import Viewncompetitiveadmin from './pages/Viewncompetitiveadmin';

import Editnnursing515 from './pages/Editnnursing515';
import Viewnnursing515 from './pages/Viewnnursing515';
import Addnnursing515 from './pages/Addnnursing515';
import Addnnursing515bulk from './pages/Addnnursing515bulk';
import Viewnnursing515admin from './pages/Viewnnursing515admin';


import Viewnnursinginteradminlinks from './pages/Viewnnursinginteradminlinks';
import Viewnfacilitiesadminlinks from './pages/Viewnfacilitiesadminlinks';
import Viewaddonlinks from './pages/Viewaddonlinks';
import Viewboslinks from './pages/Viewboslinks';
import Viewadmissionlinksssr from './pages/Viewadmissionlinksssr';
import Viewcollablinks from './pages/Viewcollablinks';
import Viewallpublinksssr from './pages/Viewallpublinksssr';
import Viewictlinks from './pages/Viewictlinks';
import Vieweventlinksssr from './pages/Vieweventlinks';
import Viewskilldevlinksssr from './pages/Viewskilldevlinksssr';
import Viewscholarshiplinks from './pages/Viewscholarshiplinks';
import Viewcareerlinks from './pages/Viewcareerlinks';
import Viewnnursing515links from './pages/Viewnnursing515links';
import Viewplacementlinksssr from './pages/Viewplacementlinksssr';
import Viewhigherexamlinksssr from './pages/Viewhigherexamlinksssr';
import Viewteacherawardlinks from './pages/Viewteacherawardlinks';
import Viewphdguidelinksssr from './pages/Viewphdguidelinksssr';
import Viewallsemreimlinks from './pages/Viewallsemreimlinks';
import Viewteacherfslinks from './pages/Viewteacherfslinks';



import Editndepmeetings from './pages/Editndepmeetings';
import Viewndepmeetings from './pages/Viewndepmeetings';
import Addndepmeetings from './pages/Addndepmeetings';
import Addndepmeetingsbulk from './pages/Addndepmeetingsbulk';
import Viewndepmeetingsadmin from './pages/Viewndepmeetingsadmin';

import Editnmeetingtasks from './pages/Editnmeetingtasks';
import Viewnmeetingtasks from './pages/Viewnmeetingtasks';
import Addnmeetingtasks from './pages/Addnmeetingtasks';
import Addnmeetingtasksbulk from './pages/Addnmeetingtasksbulk';
import Viewnmeetingtasksadmin from './pages/Viewnmeetingtasksadmin';

import Viewmoulinksssr from './pages/Viewmoulinksssr';
import Viewhigheredulinksssr from './pages/Viewhigheredulinksssr';
import Viewsyllabusrevlinks from './pages/Viewsyllabusrevlinks';
import Addsyllabusrevupdate from './pages/Addsyllabusrevupdate';
import Modifysyllabusrev from './pages/Modifysyllabusrev';
import Viewkpiuserprint from './pages/Viewkpiuserprint';

import Viewnirfadmission from './pages/Viewnirfadmission';
import Viewnirfpass from './pages/Viewnirfpass';
import Viewnirfplacement from './pages/Viewnirfplacement';
import Viewnirflibraryexp from './pages/Viewnirflibraryexp';
import Viewnirfreimbursement from './pages/Viewnirfreimbursement';
import Viewnirfexpenditure from './pages/Viewnirfexpenditure';


import Editndepmeetingsall from './pages/Editndepmeetingsall';
import Viewndepmeetingsall from './pages/Viewndepmeetingsall';
import Addndepmeetingsall from './pages/Addndepmeetingsall';
import Addndepmeetingsallbulk from './pages/Addndepmeetingsallbulk';
import Viewndepmeetingsalladmin from './pages/Viewndepmeetingsalladmin';

import Editnmeetingtasksall from './pages/Editnmeetingtasksall';
import Viewnmeetingtasksall from './pages/Viewnmeetingtasksall';
import Addnmeetingtasksall from './pages/Addnmeetingtasksall';
import Addnmeetingtasksallbulk from './pages/Addnmeetingtasksallbulk';
import Viewnmeetingtasksalladmin from './pages/Viewnmeetingtasksalladmin';

import Editnallcourses from './pages/Editnallcourses';
import Viewnallcourses from './pages/Viewnallcourses';
import Addnallcourses from './pages/Addnallcourses';
import Addnallcoursesbulk from './pages/Addnallcoursesbulk';
import Viewnallcoursesadmin from './pages/Viewnallcoursesadmin';
import Viewnallcoursesprog from './pages/Viewnallcoursesprog';

import Viewnbaaug11adminnew from './pages/Viewnbaaug11adminnew';
import Viewnbaaug11adminnewlinks from './pages/Viewnbaaug11adminnewlinks';
import Viewaddonlinks1 from './pages/Viewaddonlinks1';

import Viewegovlinks from './pages/Viewegovlinks';
import Viewnnursing812links from './pages/Viewnnursing812links';
import Viewnnursing814links from './pages/Viewnnursing814links';
import Viewnnursing818adminlinks from './pages/Viewnursing818adminlinks';

import Viewnnratiolinks from './pages/Viewnnratiolinks';
import Viewnhlearneradminlinks from './pages/Viewnhlearneradminlinks';
import Viewnclinicskillsadminlinks from './pages/Viewnclinicskillsadminlinks';

import Viewexplearnuaqar from './pages/Viewexplearnuaqar';
import Viewexplearnuaqarlinks from './pages/Viewexplearnuaqarlinks';

import Vieweventlinksbyuser from './pages/Vieweventlinksbyuser';

import Viewhumetric from './pages/Viewhumetric';

import Viewnmeetingtaskspending from './pages/Viewnmeetingtaskspending';
import Viewnmeetingtasksallclients from './pages/Viewnmeetingtasksallclients';



import Editverifystatus from './pages/Editverifystatus';
import Viewverifystatus from './pages/Viewverifystatus';
import Addverifystatus from './pages/Addverifystatus';
import Addverifystatusbulk from './pages/Addverifystatusbulk';
import Viewverifystatusadmin from './pages/Viewverifystatusadmin';

import Viewverifystatusprog from './pages/Viewverifystatusprog';

import Editnissuesall from './pages/Editnissuesall';
import Viewnissuesall from './pages/Viewnissuesall';
import Addnissuesall from './pages/Addnissuesall';
import Addnissuesallbulk from './pages/Addnissuesallbulk';
import Viewnissuesalladmin from './pages/Viewnissuesalladmin';

import Viewnissuesallprog from './pages/Viewnissuesallprog';

import Editntickets from './pages/Editntickets';
import Viewntickets from './pages/Viewntickets';
import Addntickets from './pages/Addntickets';
import Addnticketsbulk from './pages/Addnticketsbulk';
import Viewnticketsadmin from './pages/Viewnticketsadmin';

import Viewnticketsprog from './pages/Viewnticketsprog';

import Adminhumetric from './pages/Adminhumetric';

import Viewsyllabusrevlinksssr from './pages/Viewsyllabusrevlinksssr';
import Viewemployabilitylinksssr from './pages/Viewemployabilitylinksssr';
import Viewexplearninglinksssr from './pages/Viewexplearninglinksssr';
import Viewinnovationlinks from './pages/Viewinnovationlinks';
import Viewawardsstudlinks from './pages/Viewawardsstudlinks';
import Viewallseminarlinksssr from './pages/Viewallseminarlinksssr';
import Viewresearchfellowlinks from './pages/Viewresearchfellowlinks';
import Viewteacherfellowlinks from './pages/Viewteacherfellowlinks';
import Viewseedmlinks from './pages/Viewseedmlinks';
import Viewpassexamlinks from './pages/Viewpassexamlinks';
import Viewteacherdatalinks from './pages/Viewteacherdatalinks';
import Viewexamautolinks from './pages/Viewexamautolinks';
import Viewecontentlinks from './pages/Viewecontentlinks';
import Viewresultlinks from './pages/Viewresultlinks';
import Viewsportsactlinks from './pages/Viewsportsactlinks';
import Viewallbooklinksssr from './pages/Viewallbooklinksssr';
import Viewallpatentlinksssr from './pages/Viewallpatentlinksssr';
import Viewextensionactlinksssr from './pages/Viewextensionactlinksssr';
import Viewexpenditurelinksssr from './pages/Viewexpenditurelinksssr';
import Viewlibraryexpenlinksssr from './pages/Viewlibraryexpenlinksssr';
import Viewfundslinkssr from './pages/Viewfundslinkssr';



import Editnbaug33result from './pages/Editnbaug33result';
import Viewnbaug33result from './pages/Viewnbaug33result';
import Addnbaug33result from './pages/Addnbaug33result';
import Addnbaug33resultbulk from './pages/Addnbaug33resultbulk';
import Viewnbaug33resultadmin from './pages/Viewnbaug33resultadmin';

import Viewnbaug33resultprog from './pages/Viewnbaug33resultprog';

import Editwriterdb from './pages/Editwriterdb';
import Viewwriterdb from './pages/Viewwriterdb';
import Addwriterdb from './pages/Addwriterdb';
import Addwriterdbbulk from './pages/Addwriterdbbulk';
import Viewwriterdbadmin from './pages/Viewwriterdbadmin';

import Viewwriterdbprog from './pages/Viewwriterdbprog';

import Viewadmissionprog from './pages/Viewadmissionprog';
import Viewpassexamprog from './pages/Viewpassexamprog';
import Viewplacementprog from './pages/Viewplacementprog';

import Selectreporttype from './pages/Selectreporttype';
import Generatereport from './pages/Generatereport';

import Editnadmission from './pages/Editnadmission';
import Viewnadmission from './pages/Viewnadmission';
import Addnadmission from './pages/Addnadmission';
import Addadmissionbulkn from './pages/Addadmissionbulkn';
import Viewnadmissionadmin from './pages/Viewnadmissionadmin';


import Editnplacement from './pages/Editnplacement';
import Viewnplacement from './pages/Viewnplacement';
import Addnplacement from './pages/Addnplacement';
import Addplacementbulkn from './pages/Addplacementbulkn';
import Viewnplacementadmin from './pages/Viewnplacementadmin';


import Editnpassexam from './pages/Editnpassexam';
import Viewnpassexam from './pages/Viewnpassexam';
import Addnpassexam from './pages/Addnpassexam';
import Addpassexambulkn from './pages/Addpassexambulkn';
import Viewnpassexamadmin from './pages/Viewnpassexamadmin';

import Editnmeetingtaskssuper from './pages/Editnmeetingtaskssuper';
import Bulkupdatecomments from './pages/Bulkupdatecomments';

import Editnhigheredu from './pages/Editnhigheredu';
import Viewnhigheredu from './pages/Viewnhigheredu';
import Addnhigheredu from './pages/Addnhigheredu';
import Addhigheredubulkn from './pages/Addhigheredubulkn';
import Viewnhighereduadmin from './pages/Viewnhighereduadmin';

import Viewhighereduprog from './pages/Viewhighereduprog';

import Editnhigherexam from './pages/Editnhigherexam';
import Viewnhigherexam from './pages/Viewnhigherexam';
import Addnhigherexam from './pages/Addnhigherexam';
import Addhigherexambulkn from './pages/Addhigherexambulkn';
import Viewnhigherexamadmin from './pages/Viewnhigherexamadmin';

import Viewhigherexamprog from './pages/Viewhigherexamprog';

import Editnreservecat from './pages/Editnreservecat';
import Viewnreservecat from './pages/Viewnreservecat';
import Addnreservecat from './pages/Addnreservecat';
import Addreservecatbulkn from './pages/Addreservecatbulkn';
import Viewnreservecatadmin from './pages/Viewnreservecatadmin';

import Viewreservecatprog from './pages/Viewreservecatprog';

import Editnphdfac from './pages/Editnphdfac';
import Viewnphdfac from './pages/Viewnphdfac';
import Addnphdfac from './pages/Addnphdfac';
import Addphdfacbulkn from './pages/Addphdfacbulkn';
import Viewnphdfacadmin from './pages/Viewnphdfacadmin';

import Viewphdfacprog from './pages/Viewphdfacprog';

import Viewemployabilitys from './pages/Viewemployabilitys';
import Editemployabilitys from './pages/Editemployabilitys';


import Editnteacherdata from './pages/Editnteacherdata';
import Viewnteacherdata from './pages/Viewnteacherdata';
import Addnteacherdata from './pages/Addnteacherdata';
import Addteacherdatabulkn from './pages/Addteacherdatabulkn';
import Viewnteacherdataadmin from './pages/Viewnteacherdataadmin';

import Viewteacherdataprog from './pages/Viewteacherdataprog';

import Editnresult from './pages/Editnresult';
import Viewnresult from './pages/Viewnresult';
import Addnresult from './pages/Addnresult';
import Addresultbulkn from './pages/Addresultbulkn';
import Viewnresultadmin from './pages/Viewnresultadmin';

import Viewresultprog from './pages/Viewresultprog';

import Editnphdface from './pages/Editnphdface';
import Viewnphdface from './pages/Viewnphdface';
import Addnphdface from './pages/Addnphdface';
import Addphdfacebulkn from './pages/Addphdfacebulkn';
import Viewnphdfaceadmin from './pages/Viewnphdfaceadmin';

import Viewphdfaceprog from './pages/Viewphdfaceprog';

import Editnnmetricissues from './pages/Editnnmetricissues';
import Viewnnmetricissues from './pages/Viewnnmetricissues';
import Addnnmetricissues from './pages/Addnnmetricissues';
import Addnmetricissuesbulkn from './pages/Addnmetricissuesbulkn';
import Viewnnmetricissuesadmin from './pages/Viewnnmetricissuesadmin';

import Viewnmetricissuesprog from './pages/Viewnmetricissuesprog';


import Editnempexpenses from './pages/Editnempexpenses';
import Viewnempexpenses from './pages/Viewnempexpenses';
import Addnempexpenses from './pages/Addnempexpenses';
import Addempexpensesbulkn from './pages/Addempexpensesbulkn';
import Viewnempexpensesadmin from './pages/Viewnempexpensesadmin';

import Viewempexpensesprog from './pages/Viewempexpensesprog';

import Editneventexpenses from './pages/Editneventexpenses';
import Viewneventexpenses from './pages/Viewneventexpenses';
import Addneventexpenses from './pages/Addneventexpenses';
import Addeventexpensesbulkn from './pages/Addeventexpensesbulkn';
import Viewneventexpensesadmin from './pages/Viewneventexpensesadmin';

import Vieweventexpensesprog from './pages/Vieweventexpensesprog';



import Editnmentees from './pages/Editnmentees';
import Viewnmentees from './pages/Viewnmentees';
import Addnmentees from './pages/Addnmentees';
import Addmenteesbulkn from './pages/Addmenteesbulkn';
import Viewnmenteesadmin from './pages/Viewnmenteesadmin';

import Viewmenteesprog from './pages/Viewmenteesprog';



import Editnalumnicon from './pages/Editnalumnicon';
import Viewnalumnicon from './pages/Viewnalumnicon';
import Addnalumnicon from './pages/Addnalumnicon';
import Addalumniconbulkn from './pages/Addalumniconbulkn';
import Viewnalumniconadmin from './pages/Viewnalumniconadmin';

import Viewalumniconprog from './pages/Viewalumniconprog';

import Editnevent from './pages/Editnevent';
import Viewnevent from './pages/Viewnevent';
import Addnevent from './pages/Addnevent';
import Addeventbulkn from './pages/Addeventbulkn';
import Viewneventadmin from './pages/Viewneventadmin';

import Vieweventprog from './pages/Vieweventprog';

import Editnteacherfs from './pages/Editnteacherfs';
import Viewnteacherfs from './pages/Viewnteacherfs';
import Addnteacherfs from './pages/Addnteacherfs';
import Addteacherfsbulkn from './pages/Addteacherfsbulkn';
import Viewnteacherfsadmin from './pages/Viewnteacherfsadmin';

import Viewteacherfsprog from './pages/Viewteacherfsprog';

import Viewneventlinks from './pages/Viewneventlinks';


import Editnlabsafety from './pages/Editnlabsafety';
import Viewnlabsafety from './pages/Viewnlabsafety';
import Addnlabsafety from './pages/Addnlabsafety';
import Addlabsafetybulkn from './pages/Addlabsafetybulkn';
import Viewnlabsafetyadmin from './pages/Viewnlabsafetyadmin';

import Viewlabsafetyprog from './pages/Viewlabsafetyprog';

import Editnlabequipments from './pages/Editnlabequipments';
import Viewnlabequipments from './pages/Viewnlabequipments';
import Addnlabequipments from './pages/Addnlabequipments';
import Addlabequipmentsbulkn from './pages/Addlabequipmentsbulkn';
import Viewnlabequipmentsadmin from './pages/Viewnlabequipmentsadmin';

import Viewlabequipmentsprog from './pages/Viewlabequipmentsprog';

import Editnprogrambudget from './pages/Editnprogrambudget';
import Viewnprogrambudget from './pages/Viewnprogrambudget';
import Addnprogrambudget from './pages/Addnprogrambudget';
import Addprogrambudgetbulkn from './pages/Addprogrambudgetbulkn';
import Viewnprogrambudgetadmin from './pages/Viewnprogrambudgetadmin';

import Viewprogrambudgetprog from './pages/Viewprogrambudgetprog';

import Editninternet from './pages/Editninternet';
import Viewninternet from './pages/Viewninternet';
import Addninternet from './pages/Addninternet';
import Addinternetbulkn from './pages/Addinternetbulkn';
import Viewninternetadmin from './pages/Viewninternetadmin';

import Viewinternetprog from './pages/Viewinternetprog';

import Editnemfaculty from './pages/Editnemfaculty';
import Viewnemfaculty from './pages/Viewnemfaculty';
import Addnemfaculty from './pages/Addnemfaculty';
import Addemfacultybulkn from './pages/Addemfacultybulkn';
import Viewnemfacultyadmin from './pages/Viewnemfacultyadmin';

import Viewemfacultyprog from './pages/Viewemfacultyprog';

import Editnstudentpubs from './pages/Editnstudentpubs';
import Viewnstudentpubs from './pages/Viewnstudentpubs';
import Addnstudentpubs from './pages/Addnstudentpubs';
import Addstudentpubsbulkn from './pages/Addstudentpubsbulkn';
import Viewnstudentpubsadmin from './pages/Viewnstudentpubsadmin';

import Viewstudentpubsprog from './pages/Viewstudentpubsprog';

import Editndeppublications from './pages/Editndeppublications';
import Viewndeppublications from './pages/Viewndeppublications';
import Addndeppublications from './pages/Addndeppublications';
import Adddeppublicationsbulkn from './pages/Adddeppublicationsbulkn';
import Viewndeppublicationsadmin from './pages/Viewndeppublicationsadmin';

import Viewdeppublicationsprog from './pages/Viewdeppublicationsprog';

import Editnstudmaster from './pages/Editnstudmaster';
import Viewnstudmaster from './pages/Viewnstudmaster';
import Addnstudmaster from './pages/Addnstudmaster';
import Addstudmasterbulkn from './pages/Addstudmasterbulkn';
import Viewnstudmasteradmin from './pages/Viewnstudmasteradmin';

import Viewstudmasterprog from './pages/Viewstudmasterprog';

import Editnseedm from './pages/Editnseedm';
import Viewnseedm from './pages/Viewnseedm';
import Addnseedm from './pages/Addnseedm';
import Addseedmbulkn from './pages/Addseedmbulkn';
import Viewnseedmadmin from './pages/Viewnseedmadmin';

import Viewseedmprog from './pages/Viewseedmprog';

import Editnfacyear from './pages/Editnfacyear';
import Viewnfacyear from './pages/Viewnfacyear';
import Addnfacyear from './pages/Addnfacyear';
import Addfacyearbulkn from './pages/Addfacyearbulkn';
import Viewnfacyearadmin from './pages/Viewnfacyearadmin';

import Viewfacyearprog from './pages/Viewfacyearprog';

// import Editnplacement from './pages/Editnplacement';
// import Viewnplacement from './pages/Viewnplacement';
// import Addnplacement from './pages/Addnplacement';
// import Addplacementbulkn from './pages/Addplacementbulkn';
// import Viewnplacementadmin from './pages/Viewnplacementadmin';

// import Viewplacementprog from './pages/Viewplacementprog';

import Viewnteacherfsadminlinks from './pages/Viewnteacherfsadminlinks';
import Viewnalumniconlinks from './pages/Viewnalumniconlinks';


import Editnstudmasternew from './pages/Editnstudmasternew';
import Viewnstudmasternew from './pages/Viewnstudmasternew';
import Addnstudmasternew from './pages/Addnstudmasternew';
import Addstudmasternewbulkn from './pages/Addstudmasternewbulkn';
import Viewnstudmasternewadmin from './pages/Viewnstudmasternewadmin';

import Viewstudmasternewprog from './pages/Viewstudmasternewprog';
import Viewnnursing424adminlinks from './pages/Viewnnursing424adminlinks';

import Viewsyllabusrevdocsdt from './pages/Viewsyllabusrevdocsdt';

import Viewnfacilitiesadminlinksnew from './pages/Viewnfacilitiesadminlinksnew';

import Editnstartup from './pages/Editnstartup';
import Viewnstartup from './pages/Viewnstartup';
import Addnstartup from './pages/Addnstartup';
import Addstartupbulkn from './pages/Addstartupbulkn';
import Viewnstartupadmin from './pages/Viewnstartupadmin';

import Viewstartupprog from './pages/Viewstartupprog';
import Viewplacementindex from './pages/Viewplacementindex';


import Editnacadperf from './pages/Editnacadperf';
import Viewnacadperf from './pages/Viewnacadperf';
import Addnacadperf from './pages/Addnacadperf';
import Addacadperfbulkn from './pages/Addacadperfbulkn';
import Viewnacadperfadmin from './pages/Viewnacadperfadmin';

import Viewacadperfprog from './pages/Viewacadperfprog';
import Viewacadapi from './pages/Viewacadapi';
import Viewplacementindex1 from './pages/Viewplacementindex1';
import Viewadmissionperfapi from './pages/Viewadmissionperfapi';
import Viewsfrapi from './pages/Viewsfrapi';
import Viewfacultycadre from './pages/Viewfacultycadre';

import Viewnrevaladminlinks from './pages/Viewnrevaladminlinks';
import Viewextawardsdlinks from './pages/Viewextawardsdlinks';
import Viewnnpatientsadminlinks from './pages/Viewnnpatientsadminlinks';
import Viewninteradminlinks from './pages/Viewninteradminlinks';
import Viewnotherstudadminlinks from './pages/Viewnotherstudadminlinks';
import Viewnmenteesadminlinks from './pages/Viewnmenteesadminlinks';
import Selecttype from './pages/Selecttype';

import Viewneventadmintype from './pages/Viewneventadmintype';
import Viewneventadmintypelinks from './pages/Viewneventadmintypelinks';

import Viewprojectyrdocsdlinks from './pages/Viewprojectyrdocsdlinks';
import Viewqualitydlinks from './pages/Viewqualitydlinks';
import Viewqualityinitdlinks from './pages/Viewqualityinitdlinks';
import Viewnseedmadminlinks from './pages/Viewnseedmadminlinks';
import Viewcollabdoccommentsdlinks from './pages/Viewcollabdoccommentsdlinks';
import Viewconsultancydlinks from './pages/Viewconsultancydlinks';

import Viewnphdfacadminlinks from './pages/Viewnphdfacadminlinks';
import Viewphdguidedoclinks from './pages/Viewphdguidedoclinks';

import Viewplacementdoclinks from './pages/Viewplacementdoclinks';

import Viewexplearningprojlinks from './pages/Viewexplearningprojlinks';


import Editnpayslips from './pages/Editnpayslips';
import Viewnpayslips from './pages/Viewnpayslips';
import Addnpayslips from './pages/Addnpayslips';
import Addpayslipsbulkn from './pages/Addpayslipsbulkn';
import Viewnpayslipsadmin from './pages/Viewnpayslipsadmin';

import Viewpayslipsprog from './pages/Viewpayslipsprog';

import Editnpayslipdetails from './pages/Editnpayslipdetails';
import Viewnpayslipdetails from './pages/Viewnpayslipdetails';
import Addnpayslipdetails from './pages/Addnpayslipdetails';
import Addpayslipdetailsbulkn from './pages/Addpayslipdetailsbulkn';
import Viewnpayslipdetailsadmin from './pages/Viewnpayslipdetailsadmin';

import Viewpayslipdetailsprog from './pages/Viewpayslipdetailsprog';

import Viewnpayslipdetailsadmiid from './pages/Viewnpayslipdetailsadmiid';
import Viewpayslipdetailsbyid from './pages/Viewpayslipdetailsbyid';

import Editnopencourse from './pages/Editnopencourse';
import Viewnopencourse from './pages/Viewnopencourse';
import Addnopencourse from './pages/Addnopencourse';
import Addopencoursebulkn from './pages/Addopencoursebulkn';
import Viewnopencourseadmin from './pages/Viewnopencourseadmin';

import Viewopencourseprog from './pages/Viewopencourseprog';

import Editnopenmodules from './pages/Editnopenmodules';
import Viewnopenmodules from './pages/Viewnopenmodules';
import Addnopenmodules from './pages/Addnopenmodules';
import Addopenmodulesbulkn from './pages/Addopenmodulesbulkn';
import Viewnopenmodulesadmin from './pages/Viewnopenmodulesadmin';

import Viewopenmodulesprog from './pages/Viewopenmodulesprog';


import Editnrapplication from './pages/Editnrapplication';
import Viewnrapplication from './pages/Viewnrapplication';
import Addnrapplication from './pages/Addnrapplication';
import Addrapplicationbulkn from './pages/Addrapplicationbulkn';
import Viewnrapplicationadmin from './pages/Viewnrapplicationadmin';

import Viewrapplicationprog from './pages/Viewrapplicationprog';

import Editnrtrust from './pages/Editnrtrust';
import Viewnrtrust from './pages/Viewnrtrust';
import Addnrtrust from './pages/Addnrtrust';
import Addrtrustbulkn from './pages/Addrtrustbulkn';
import Viewnrtrustadmin from './pages/Viewnrtrustadmin';

import Viewrtrustprog from './pages/Viewrtrustprog';


import Editnrinstitute from './pages/Editnrinstitute';
import Viewnrinstitute from './pages/Viewnrinstitute';
import Addnrinstitute from './pages/Addnrinstitute';
import Addrinstitutebulkn from './pages/Addrinstitutebulkn';
import Viewnrinstituteadmin from './pages/Viewnrinstituteadmin';

import Viewrinstituteprog from './pages/Viewrinstituteprog';


import Editnrnotherc from './pages/Editnrnotherc';
import Viewnrnotherc from './pages/Viewnrnotherc';
import Addnrnotherc from './pages/Addnrnotherc';
import Addrnothercbulkn from './pages/Addrnothercbulkn';
import Viewnrnothercadmin from './pages/Viewnrnothercadmin';

import Viewrnothercprog from './pages/Viewrnothercprog';

import Editnrnclassroom from './pages/Editnrnclassroom';
import Viewnrnclassroom from './pages/Viewnrnclassroom';
import Addnrnclassroom from './pages/Addnrnclassroom';
import Addrnclassroombulkn from './pages/Addrnclassroombulkn';
import Viewnrnclassroomadmin from './pages/Viewnrnclassroomadmin';

import Viewrnclassroomprog from './pages/Viewrnclassroomprog';

import Editnrnlabs from './pages/Editnrnlabs';
import Viewnrnlabs from './pages/Viewnrnlabs';
import Addnrnlabs from './pages/Addnrnlabs';
import Addrnlabsbulkn from './pages/Addrnlabsbulkn';
import Viewnrnlabsadmin from './pages/Viewnrnlabsadmin';

import Viewrnlabsprog from './pages/Viewrnlabsprog';

import Editnrnlibrarian from './pages/Editnrnlibrarian';
import Viewnrnlibrarian from './pages/Viewnrnlibrarian';
import Addnrnlibrarian from './pages/Addnrnlibrarian';
import Addrnlibrarianbulkn from './pages/Addrnlibrarianbulkn';
import Viewnrnlibrarianadmin from './pages/Viewnrnlibrarianadmin';

import Viewrnlibrarianprog from './pages/Viewrnlibrarianprog';

import Editnrnvehicle from './pages/Editnrnvehicle';
import Viewnrnvehicle from './pages/Viewnrnvehicle';
import Addnrnvehicle from './pages/Addnrnvehicle';
import Addrnvehiclebulkn from './pages/Addrnvehiclebulkn';
import Viewnrnvehicleadmin from './pages/Viewnrnvehicleadmin';

import Viewrnvehicleprog from './pages/Viewrnvehicleprog';

import Editnrnadministrative from './pages/Editnrnadministrative';
import Viewnrnadministrative from './pages/Viewnrnadministrative';
import Addnrnadministrative from './pages/Addnrnadministrative';
import Addrnadministrativebulkn from './pages/Addrnadministrativebulkn';
import Viewnrnadministrativeadmin from './pages/Viewnrnadministrativeadmin';

import Viewrnadministrativeprog from './pages/Viewrnadministrativeprog';

import Editnrnaffhospital from './pages/Editnrnaffhospital';
import Viewnrnaffhospital from './pages/Viewnrnaffhospital';
import Addnrnaffhospital from './pages/Addnrnaffhospital';
import Addrnaffhospitalbulkn from './pages/Addrnaffhospitalbulkn';
import Viewnrnaffhospitaladmin from './pages/Viewnrnaffhospitaladmin';

import Viewrnaffhospitalprog from './pages/Viewrnaffhospitalprog';

import Editnrndocuments from './pages/Editnrndocuments';
import Viewnrndocuments from './pages/Viewnrndocuments';
import Addnrndocuments from './pages/Addnrndocuments';
import Addrndocumentsbulkn from './pages/Addrndocumentsbulkn';
import Viewnrndocumentsadmin from './pages/Viewnrndocumentsadmin';

import Viewrndocumentsprog from './pages/Viewrndocumentsprog';

import Editnrnonteaching from './pages/Editnrnonteaching';
import Viewnrnonteaching from './pages/Viewnrnonteaching';
import Addnrnonteaching from './pages/Addnrnonteaching';
import Addrnonteachingbulkn from './pages/Addrnonteachingbulkn';
import Viewnrnonteachingadmin from './pages/Viewnrnonteachingadmin';

import Viewrnonteachingprog from './pages/Viewrnonteachingprog';



import Editnrnphospitals from './pages/Editnrnphospitals';
import Viewnrnphospitals from './pages/Viewnrnphospitals';
import Addnrnphospitals from './pages/Addnrnphospitals';
import Addrnphospitalsbulkn from './pages/Addrnphospitalsbulkn';
import Viewnrnphospitalsadmin from './pages/Viewnrnphospitalsadmin';

import Viewrnphospitalsprog from './pages/Viewrnphospitalsprog';

import Editnrnbuilding from './pages/Editnrnbuilding';
import Viewnrnbuilding from './pages/Viewnrnbuilding';
import Addnrnbuilding from './pages/Addnrnbuilding';
import Addrnbuildingbulkn from './pages/Addrnbuildingbulkn';
import Viewnrnbuildingadmin from './pages/Viewnrnbuildingadmin';

import Viewrnbuildingprog from './pages/Viewrnbuildingprog';



import Editnnqualification from './pages/Editnnqualification';
import Viewnnqualification from './pages/Viewnnqualification';
import Addnnqualification from './pages/Addnnqualification';
import Addnqualificationbulkn from './pages/Addnqualificationbulkn';
import Viewnnqualificationadmin from './pages/Viewnnqualificationadmin';

import Viewnqualificationprog from './pages/Viewnqualificationprog';

import Editnnexperience from './pages/Editnnexperience';
import Viewnnexperience from './pages/Viewnnexperience';
import Addnnexperience from './pages/Addnnexperience';
import Addnexperiencebulkn from './pages/Addnexperiencebulkn';
import Viewnnexperienceadmin from './pages/Viewnnexperienceadmin';

import Viewnexperienceprog from './pages/Viewnexperienceprog';

import Editnnmytasks from './pages/Editnnmytasks';
import Viewnnmytasks from './pages/Viewnnmytasks';
import Addnnmytasks from './pages/Addnnmytasks';
import Addnmytasksbulkn from './pages/Addnmytasksbulkn';
import Viewnnmytasksadmin from './pages/Viewnnmytasksadmin';

import Viewnmytasksprog from './pages/Viewnmytasksprog';

import Editnempapprec from './pages/Editnempapprec';
import Viewnempapprec from './pages/Viewnempapprec';
import Addnempapprec from './pages/Addnempapprec';
import Addempapprecbulkn from './pages/Addempapprecbulkn';
import Viewnempapprecadmin from './pages/Viewnempapprecadmin';

import Viewempapprecprog from './pages/Viewempapprecprog';

import Editnsalarydetails from './pages/Editnsalarydetails';
import Viewnsalarydetails from './pages/Viewnsalarydetails';
import Addnsalarydetails from './pages/Addnsalarydetails';
import Addsalarydetailsbulkn from './pages/Addsalarydetailsbulkn';
import Viewnsalarydetailsadmin from './pages/Viewnsalarydetailsadmin';

import Viewsalarydetailsprog from './pages/Viewsalarydetailsprog';
import Editnnmytasksuser from './pages/Editnnmytasksuser';
import Viewnnmytasksuser from './pages/Viewnnmytasksuser';

// import Editnnlibusage from './pages/Editnnlibusage';
// import Viewnnlibusage from './pages/Viewnnlibusage';
// import Addnnlibusage from './pages/Addnnlibusage';
// import Addnlibusagebulkn from './pages/Addnlibusagebulkn';
// import Viewnnlibusageadmin from './pages/Viewnnlibusageadmin';

// import Viewnlibusageprog from './pages/Viewnlibusageprog';

import Editnexplearning from './pages/Editnexplearning';
import Viewnexplearning from './pages/Viewnexplearning';
import Addnexplearning from './pages/Addnexplearning';
import Addexplearningbulkn from './pages/Addexplearningbulkn';
import Viewnexplearningadmin from './pages/Viewnexplearningadmin';

import Viewexplearningprog from './pages/Viewexplearningprog';

import Editnnufacultydata from './pages/Editnnufacultydata';
import Viewnnufacultydata from './pages/Viewnnufacultydata';
import Addnnufacultydata from './pages/Addnnufacultydata';
import Addnufacultydatabulkn from './pages/Addnufacultydatabulkn';
import Viewnnufacultydataadmin from './pages/Viewnnufacultydataadmin';

import Viewnufacultydataprog from './pages/Viewnufacultydataprog';

// import Editnphdface from './pages/Editnphdface';
// import Viewnphdface from './pages/Viewnphdface';
// import Addnphdface from './pages/Addnphdface';
// import Addphdfacebulkn from './pages/Addphdfacebulkn';
// import Viewnphdfaceadmin from './pages/Viewnphdfaceadmin';

// import Viewphdfaceprog from './pages/Viewphdfaceprog';

// import Editnteacherdata from './pages/Editnteacherdata';
// import Viewnteacherdata from './pages/Viewnteacherdata';
// import Addnteacherdata from './pages/Addnteacherdata';
// import Addteacherdatabulkn from './pages/Addteacherdatabulkn';
// import Viewnteacherdataadmin from './pages/Viewnteacherdataadmin';

// import Viewteacherdataprog from './pages/Viewteacherdataprog';

// import Editnresult from './pages/Editnresult';
// import Viewnresult from './pages/Viewnresult';
// import Addnresult from './pages/Addnresult';
// import Addresultbulkn from './pages/Addresultbulkn';
// import Viewnresultadmin from './pages/Viewnresultadmin';

// import Viewresultprog from './pages/Viewresultprog';

import Editnnugrievance from './pages/Editnnugrievance';
import Viewnnugrievance from './pages/Viewnnugrievance';
import Addnnugrievance from './pages/Addnnugrievance';
import Addnugrievancebulkn from './pages/Addnugrievancebulkn';
import Viewnnugrievanceadmin from './pages/Viewnnugrievanceadmin';

import Viewnugrievanceprog from './pages/Viewnugrievanceprog';

import Editnpasspercent from './pages/Editnpasspercent';
import Viewnpasspercent from './pages/Viewnpasspercent';
import Addnpasspercent from './pages/Addnpasspercent';
import Addpasspercentbulkn from './pages/Addpasspercentbulkn';
import Viewnpasspercentadmin from './pages/Viewnpasspercentadmin';

import Viewpasspercentprog from './pages/Viewpasspercentprog';

import Editnteacherfellow from './pages/Editnteacherfellow';
import Viewnteacherfellow from './pages/Viewnteacherfellow';
import Addnteacherfellow from './pages/Addnteacherfellow';
import Addteacherfellowbulkn from './pages/Addteacherfellowbulkn';
import Viewnteacherfellowadmin from './pages/Viewnteacherfellowadmin';

import Viewteacherfellowprog from './pages/Viewteacherfellowprog';

import Editnpatents from './pages/Editnpatents';
import Viewnpatents from './pages/Viewnpatents';
import Addnpatents from './pages/Addnpatents';
import Addpatentsbulkn from './pages/Addpatentsbulkn';
import Viewnpatentsadmin from './pages/Viewnpatentsadmin';

import Viewpatentsprog from './pages/Viewpatentsprog';

import Editnconsultancy from './pages/Editnconsultancy';
import Viewnconsultancy from './pages/Viewnconsultancy';
import Addnconsultancy from './pages/Addnconsultancy';
import Addconsultancybulkn from './pages/Addconsultancybulkn';
import Viewnconsultancyadmin from './pages/Viewnconsultancyadmin';

import Viewconsultancyprog from './pages/Viewconsultancyprog';

import Editnmou from './pages/Editnmou';
import Viewnmou from './pages/Viewnmou';
import Addnmou from './pages/Addnmou';
import Addmoubulkn from './pages/Addmoubulkn';
import Viewnmouadmin from './pages/Viewnmouadmin';

import Viewmouprog from './pages/Viewmouprog';

import Editnnuefacilities from './pages/Editnnuefacilities';
import Viewnnuefacilities from './pages/Viewnnuefacilities';
import Addnnuefacilities from './pages/Addnnuefacilities';
import Addnuefacilitiesbulkn from './pages/Addnuefacilitiesbulkn';
import Viewnnuefacilitiesadmin from './pages/Viewnnuefacilitiesadmin';

import Viewnuefacilitiesprog from './pages/Viewnuefacilitiesprog';

import Editnnustudcompratio from './pages/Editnnustudcompratio';
import Viewnnustudcompratio from './pages/Viewnnustudcompratio';
import Addnnustudcompratio from './pages/Addnnustudcompratio';
import Addnustudcompratiobulkn from './pages/Addnustudcompratiobulkn';
import Viewnnustudcompratioadmin from './pages/Viewnnustudcompratioadmin';

import Viewnustudcompratioprog from './pages/Viewnustudcompratioprog';

import Editnawards from './pages/Editnawards';
import Viewnawards from './pages/Viewnawards';
import Addnawards from './pages/Addnawards';
import Addawardsbulkn from './pages/Addawardsbulkn';
import Viewnawardsadmin from './pages/Viewnawardsadmin';

import Viewawardsprog from './pages/Viewawardsprog';

// import Editnhigherexam from './pages/Editnhigherexam';
// import Viewnhigherexam from './pages/Viewnhigherexam';
// import Addnhigherexam from './pages/Addnhigherexam';
// import Addhigherexambulkn from './pages/Addhigherexambulkn';
// import Viewnhigherexamadmin from './pages/Viewnhigherexamadmin';

// import Viewhigherexamprog from './pages/Viewhigherexamprog';

import Editnnlibusage from './pages/Editnnlibusage';
import Viewnnlibusage from './pages/Viewnnlibusage';
import Addnnlibusage from './pages/Addnnlibusage';
import Addnlibusagebulkn from './pages/Addnlibusagebulkn';
import Viewnnlibusageadmin from './pages/Viewnnlibusageadmin';

import Viewnlibusageprog from './pages/Viewnlibusageprog';

import Viewuniversitymetricnew from './pages/Viewuniversitymetricnew';

import Editnnluteacherdata from './pages/Editnnluteacherdata';
import Viewnnluteacherdata from './pages/Viewnnluteacherdata';
import Addnnluteacherdata from './pages/Addnnluteacherdata';
import Addnluteacherdatabulkn from './pages/Addnluteacherdatabulkn';
import Viewnnluteacherdataadmin from './pages/Viewnnluteacherdataadmin';

import Viewnluteacherdataprog from './pages/Viewnluteacherdataprog';

import Editnnlufacilities from './pages/Editnnlufacilities';
import Viewnnlufacilities from './pages/Viewnnlufacilities';
import Addnnlufacilities from './pages/Addnnlufacilities';
import Addnlufacilitiesbulkn from './pages/Addnlufacilitiesbulkn';
import Viewnnlufacilitiesadmin from './pages/Viewnnlufacilitiesadmin';

import Viewnlufacilitiesprog from './pages/Viewnlufacilitiesprog';

import Editnnlurecognitions from './pages/Editnnlurecognitions';
import Viewnnlurecognitions from './pages/Viewnnlurecognitions';
import Addnnlurecognitions from './pages/Addnnlurecognitions';
import Addnlurecognitionsbulkn from './pages/Addnlurecognitionsbulkn';
import Viewnnlurecognitionsadmin from './pages/Viewnnlurecognitionsadmin';

import Viewnlurecognitionsprog from './pages/Viewnlurecognitionsprog';

import Editnnlubills from './pages/Editnnlubills';
import Viewnnlubills from './pages/Viewnnlubills';
import Addnnlubills from './pages/Addnnlubills';
import Addnlubillsbulkn from './pages/Addnlubillsbulkn';
import Viewnnlubillsadmin from './pages/Viewnnlubillsadmin';

import Viewnlubillsprog from './pages/Viewnlubillsprog';

import Editnnluincentives from './pages/Editnnluincentives';
import Viewnnluincentives from './pages/Viewnnluincentives';
import Addnnluincentives from './pages/Addnnluincentives';
import Addnluincentivesbulkn from './pages/Addnluincentivesbulkn';
import Viewnnluincentivesadmin from './pages/Viewnnluincentivesadmin';

import Viewnluincentivesprog from './pages/Viewnluincentivesprog';

import Editnnluphdtheisis from './pages/Editnnluphdtheisis';
import Viewnnluphdtheisis from './pages/Viewnnluphdtheisis';
import Addnnluphdtheisis from './pages/Addnnluphdtheisis';
import Addnluphdtheisisbulkn from './pages/Addnluphdtheisisbulkn';
import Viewnnluphdtheisisadmin from './pages/Viewnnluphdtheisisadmin';

import Viewnluphdtheisisprog from './pages/Viewnluphdtheisisprog';

import Editnnlulegaldatabases from './pages/Editnnlulegaldatabases';
import Viewnnlulegaldatabases from './pages/Viewnnlulegaldatabases';
import Addnnlulegaldatabases from './pages/Addnnlulegaldatabases';
import Addnlulegaldatabasesbulkn from './pages/Addnlulegaldatabasesbulkn';
import Viewnnlulegaldatabasesadmin from './pages/Viewnnlulegaldatabasesadmin';

import Viewnlulegaldatabasesprog from './pages/Viewnlulegaldatabasesprog';

import Editnnlbar from './pages/Editnnlbar';
import Viewnnlbar from './pages/Viewnnlbar';
import Addnnlbar from './pages/Addnnlbar';
import Addnlbarbulkn from './pages/Addnlbarbulkn';
import Viewnnlbaradmin from './pages/Viewnnlbaradmin';

import Viewnlbarprog from './pages/Viewnlbarprog';

import Editnnlucitation from './pages/Editnnlucitation';
import Viewnnlucitation from './pages/Viewnnlucitation';
import Addnnlucitation from './pages/Addnnlucitation';
import Addnlucitationbulkn from './pages/Addnlucitationbulkn';
import Viewnnlucitationadmin from './pages/Viewnnlucitationadmin';

import Viewnlucitationprog from './pages/Viewnlucitationprog';


import Viewlegaluniversitymenu from './pages/Viewlegaluniversitymenu';

import Viewaffiliatedmenu from './pages/Viewaffiliatedmenu';

import Viewautonomousmenu from './pages/Viewautonomousmenu';

import Editnainst from './pages/Editnainst';
import Viewnainst from './pages/Viewnainst';
import Addnainst from './pages/Addnainst';
import Addainstbulkn from './pages/Addainstbulkn';
import Viewnainstadmin from './pages/Viewnainstadmin';

import Viewainstprog from './pages/Viewainstprog';


import Editnpaunewidetails from './pages/Editnpaunewidetails';
import Viewnpaunewidetails from './pages/Viewnpaunewidetails';
import Addnpaunewidetails from './pages/Addnpaunewidetails';
import Addpaunewidetailsbulkn from './pages/Addpaunewidetailsbulkn';
import Viewnpaunewidetailsadmin from './pages/Viewnpaunewidetailsadmin';

import Viewpaunewidetailsprog from './pages/Viewpaunewidetailsprog';

import Editnpauidetailsc from './pages/Editnpauidetailsc';
import Viewnpauidetailsc from './pages/Viewnpauidetailsc';
import Addnpauidetailsc from './pages/Addnpauidetailsc';
import Addpauidetailscbulkn from './pages/Addpauidetailscbulkn';
import Viewnpauidetailscadmin from './pages/Viewnpauidetailscadmin';

import Viewpauidetailscprog from './pages/Viewpauidetailscprog';

import Editnpautrust from './pages/Editnpautrust';
import Viewnpautrust from './pages/Viewnpautrust';
import Addnpautrust from './pages/Addnpautrust';
import Addpautrustbulkn from './pages/Addpautrustbulkn';
import Viewnpautrustadmin from './pages/Viewnpautrustadmin';

import Viewpautrustprog from './pages/Viewpautrustprog';

import Editnpaunewprincipal from './pages/Editnpaunewprincipal';
import Viewnpaunewprincipal from './pages/Viewnpaunewprincipal';
import Addnpaunewprincipal from './pages/Addnpaunewprincipal';
import Addpaunewprincipalbulkn from './pages/Addpaunewprincipalbulkn';
import Viewnpaunewprincipaladmin from './pages/Viewnpaunewprincipaladmin';

import Viewpaunewprincipalprog from './pages/Viewpaunewprincipalprog';

import Editnpaungovcouncil from './pages/Editnpaungovcouncil';
import Viewnpaungovcouncil from './pages/Viewnpaungovcouncil';
import Addnpaungovcouncil from './pages/Addnpaungovcouncil';
import Addpaungovcouncilbulkn from './pages/Addpaungovcouncilbulkn';
import Viewnpaungovcounciladmin from './pages/Viewnpaungovcounciladmin';

import Viewpaungovcouncilprog from './pages/Viewpaungovcouncilprog';

import Editnpaunboard from './pages/Editnpaunboard';
import Viewnpaunboard from './pages/Viewnpaunboard';
import Addnpaunboard from './pages/Addnpaunboard';
import Addpaunboardbulkn from './pages/Addpaunboardbulkn';
import Viewnpaunboardadmin from './pages/Viewnpaunboardadmin';

import Viewpaunboardprog from './pages/Viewpaunboardprog';

import Editnpauragcomt from './pages/Editnpauragcomt';
import Viewnpauragcomt from './pages/Viewnpauragcomt';
import Addnpauragcomt from './pages/Addnpauragcomt';
import Addpauragcomtbulkn from './pages/Addpauragcomtbulkn';
import Viewnpauragcomtadmin from './pages/Viewnpauragcomtadmin';

import Viewpauragcomtprog from './pages/Viewpauragcomtprog';

import Editnpauragsquad from './pages/Editnpauragsquad';
import Viewnpauragsquad from './pages/Viewnpauragsquad';
import Addnpauragsquad from './pages/Addnpauragsquad';
import Addpauragsquadbulkn from './pages/Addpauragsquadbulkn';
import Viewnpauragsquadadmin from './pages/Viewnpauragsquadadmin';

import Viewpauragsquadprog from './pages/Viewpauragsquadprog';

import Editnpauwelfarecomt from './pages/Editnpauwelfarecomt';
import Viewnpauwelfarecomt from './pages/Viewnpauwelfarecomt';
import Addnpauwelfarecomt from './pages/Addnpauwelfarecomt';
import Addpauwelfarecomtbulkn from './pages/Addpauwelfarecomtbulkn';
import Viewnpauwelfarecomtadmin from './pages/Viewnpauwelfarecomtadmin';

import Viewpauwelfarecomtprog from './pages/Viewpauwelfarecomtprog';

import Editnpauredressal from './pages/Editnpauredressal';
import Viewnpauredressal from './pages/Viewnpauredressal';
import Addnpauredressal from './pages/Addnpauredressal';
import Addpauredressalbulkn from './pages/Addpauredressalbulkn';
import Viewnpauredressaladmin from './pages/Viewnpauredressaladmin';

import Viewpauredressalprog from './pages/Viewpauredressalprog';

import Editnpaubankacnts from './pages/Editnpaubankacnts';
import Viewnpaubankacnts from './pages/Viewnpaubankacnts';
import Addnpaubankacnts from './pages/Addnpaubankacnts';
import Addpaubankacntsbulkn from './pages/Addpaubankacntsbulkn';
import Viewnpaubankacntsadmin from './pages/Viewnpaubankacntsadmin';

import Viewpaubankacntsprog from './pages/Viewpaubankacntsprog';

import Editnpaudeposit from './pages/Editnpaudeposit';
import Viewnpaudeposit from './pages/Viewnpaudeposit';
import Addnpaudeposit from './pages/Addnpaudeposit';
import Addpaudepositbulkn from './pages/Addpaudepositbulkn';
import Viewnpaudepositadmin from './pages/Viewnpaudepositadmin';

import Viewpaudepositprog from './pages/Viewpaudepositprog';

import Editnpauendowment from './pages/Editnpauendowment';
import Viewnpauendowment from './pages/Viewnpauendowment';
import Addnpauendowment from './pages/Addnpauendowment';
import Addpauendowmentbulkn from './pages/Addpauendowmentbulkn';
import Viewnpauendowmentadmin from './pages/Viewnpauendowmentadmin';

import Viewpauendowmentprog from './pages/Viewpauendowmentprog';

import Editnpaulanddetailsa from './pages/Editnpaulanddetailsa';
import Viewnpaulanddetailsa from './pages/Viewnpaulanddetailsa';
import Addnpaulanddetailsa from './pages/Addnpaulanddetailsa';
import Addpaulanddetailsabulkn from './pages/Addpaulanddetailsabulkn';
import Viewnpaulanddetailsaadmin from './pages/Viewnpaulanddetailsaadmin';

import Viewpaulanddetailsaprog from './pages/Viewpaulanddetailsaprog';

import Editnpaulanddetailsb from './pages/Editnpaulanddetailsb';
import Viewnpaulanddetailsb from './pages/Viewnpaulanddetailsb';
import Addnpaulanddetailsb from './pages/Addnpaulanddetailsb';
import Addpaulanddetailsbbulkn from './pages/Addpaulanddetailsbbulkn';
import Viewnpaulanddetailsbadmin from './pages/Viewnpaulanddetailsbadmin';

import Viewpaulanddetailsbprog from './pages/Viewpaulanddetailsbprog';

import Editnpaucourse from './pages/Editnpaucourse';
import Viewnpaucourse from './pages/Viewnpaucourse';
import Addnpaucourse from './pages/Addnpaucourse';
import Addpaucoursebulkn from './pages/Addpaucoursebulkn';
import Viewnpaucourseadmin from './pages/Viewnpaucourseadmin';

import Viewpaucourseprog from './pages/Viewpaucourseprog';

import Editnpaucrsedetails from './pages/Editnpaucrsedetails';
import Viewnpaucrsedetails from './pages/Viewnpaucrsedetails';
import Addnpaucrsedetails from './pages/Addnpaucrsedetails';
import Addpaucrsedetailsbulkn from './pages/Addpaucrsedetailsbulkn';
import Viewnpaucrsedetailsadmin from './pages/Viewnpaucrsedetailsadmin';

import Viewpaucrsedetailsprog from './pages/Viewpaucrsedetailsprog';

import Editnpauaddcrse from './pages/Editnpauaddcrse';
import Viewnpauaddcrse from './pages/Viewnpauaddcrse';
import Addnpauaddcrse from './pages/Addnpauaddcrse';
import Addpauaddcrsebulkn from './pages/Addpauaddcrsebulkn';
import Viewnpauaddcrseadmin from './pages/Viewnpauaddcrseadmin';

import Viewpauaddcrseprog from './pages/Viewpauaddcrseprog';

import Editnpaunstuddetails from './pages/Editnpaunstuddetails';
import Viewnpaunstuddetails from './pages/Viewnpaunstuddetails';
import Addnpaunstuddetails from './pages/Addnpaunstuddetails';
import Addpaunstuddetailsbulkn from './pages/Addpaunstuddetailsbulkn';
import Viewnpaunstuddetailsadmin from './pages/Viewnpaunstuddetailsadmin';

import Viewpaunstuddetailsprog from './pages/Viewpaunstuddetailsprog';

import Editnpaufaculty from './pages/Editnpaufaculty';
import Viewnpaufaculty from './pages/Viewnpaufaculty';
import Addnpaufaculty from './pages/Addnpaufaculty';
import Addpaufacultybulkn from './pages/Addpaufacultybulkn';
import Viewnpaufacultyadmin from './pages/Viewnpaufacultyadmin';

import Viewpaufacultyprog from './pages/Viewpaufacultyprog';

import Editnpaunfacultydetails from './pages/Editnpaunfacultydetails';
import Viewnpaunfacultydetails from './pages/Viewnpaunfacultydetails';
import Addnpaunfacultydetails from './pages/Addnpaunfacultydetails';
import Addpaunfacultydetailsbulkn from './pages/Addpaunfacultydetailsbulkn';
import Viewnpaunfacultydetailsadmin from './pages/Viewnpaunfacultydetailsadmin';

import Viewpaunfacultydetailsprog from './pages/Viewpaunfacultydetailsprog';

import Editnpaunfaddqualif from './pages/Editnpaunfaddqualif';
import Viewnpaunfaddqualif from './pages/Viewnpaunfaddqualif';
import Addnpaunfaddqualif from './pages/Addnpaunfaddqualif';
import Addpaunfaddqualifbulkn from './pages/Addpaunfaddqualifbulkn';
import Viewnpaunfaddqualifadmin from './pages/Viewnpaunfaddqualifadmin';

import Viewpaunfaddqualifprog from './pages/Viewpaunfaddqualifprog';

import Editnpaunfacdexp from './pages/Editnpaunfacdexp';
import Viewnpaunfacdexp from './pages/Viewnpaunfacdexp';
import Addnpaunfacdexp from './pages/Addnpaunfacdexp';
import Addpaunfacdexpbulkn from './pages/Addpaunfacdexpbulkn';
import Viewnpaunfacdexpadmin from './pages/Viewnpaunfacdexpadmin';

import Viewpaunfacdexpprog from './pages/Viewpaunfacdexpprog';

import Editnpaunfindexp from './pages/Editnpaunfindexp';
import Viewnpaunfindexp from './pages/Viewnpaunfindexp';
import Addnpaunfindexp from './pages/Addnpaunfindexp';
import Addpaunfindexpbulkn from './pages/Addpaunfindexpbulkn';
import Viewnpaunfindexpadmin from './pages/Viewnpaunfindexpadmin';

import Viewpaunfindexpprog from './pages/Viewpaunfindexpprog';

import Editnpaunnfeduqualif from './pages/Editnpaunnfeduqualif';
import Viewnpaunnfeduqualif from './pages/Viewnpaunnfeduqualif';
import Addnpaunnfeduqualif from './pages/Addnpaunnfeduqualif';
import Addpaunnfeduqualifbulkn from './pages/Addpaunnfeduqualifbulkn';
import Viewnpaunnfeduqualifadmin from './pages/Viewnpaunnfeduqualifadmin';

import Viewpaunnfeduqualifprog from './pages/Viewpaunnfeduqualifprog';

import Editnpaunnfcoeexp from './pages/Editnpaunnfcoeexp';
import Viewnpaunnfcoeexp from './pages/Viewnpaunnfcoeexp';
import Addnpaunnfcoeexp from './pages/Addnpaunnfcoeexp';
import Addpaunnfcoeexpbulkn from './pages/Addpaunnfcoeexpbulkn';
import Viewnpaunnfcoeexpadmin from './pages/Viewnpaunnfcoeexpadmin';

import Viewpaunnfcoeexpprog from './pages/Viewpaunnfcoeexpprog';

import Editnpauconfacshe from './pages/Editnpauconfacshe';
import Viewnpauconfacshe from './pages/Viewnpauconfacshe';
import Addnpauconfacshe from './pages/Addnpauconfacshe';
import Addpauconfacshebulkn from './pages/Addpauconfacshebulkn';
import Viewnpauconfacsheadmin from './pages/Viewnpauconfacsheadmin';

import Viewpauconfacsheprog from './pages/Viewpauconfacsheprog';

import Editnpauconfaccourse from './pages/Editnpauconfaccourse';
import Viewnpauconfaccourse from './pages/Viewnpauconfaccourse';
import Addnpauconfaccourse from './pages/Addnpauconfaccourse';
import Addpauconfaccoursebulkn from './pages/Addpauconfaccoursebulkn';
import Viewnpauconfaccourseadmin from './pages/Viewnpauconfaccourseadmin';

import Viewpauconfaccourseprog from './pages/Viewpauconfaccourseprog';

import Editnpauconfacmemtech from './pages/Editnpauconfacmemtech';
import Viewnpauconfacmemtech from './pages/Viewnpauconfacmemtech';
import Addnpauconfacmemtech from './pages/Addnpauconfacmemtech';
import Addpauconfacmemtechbulkn from './pages/Addpauconfacmemtechbulkn';
import Viewnpauconfacmemtechadmin from './pages/Viewnpauconfacmemtechadmin';

import Viewpauconfacmemtechprog from './pages/Viewpauconfacmemtechprog';

import Editnpaunontstaff from './pages/Editnpaunontstaff';
import Viewnpaunontstaff from './pages/Viewnpaunontstaff';
import Addnpaunontstaff from './pages/Addnpaunontstaff';
import Addpaunontstaffbulkn from './pages/Addpaunontstaffbulkn';
import Viewnpaunontstaffadmin from './pages/Viewnpaunontstaffadmin';

import Viewpaunontstaffprog from './pages/Viewpaunontstaffprog';

import Editnpauspacereq from './pages/Editnpauspacereq';
import Viewnpauspacereq from './pages/Viewnpauspacereq';
import Addnpauspacereq from './pages/Addnpauspacereq';
import Addpauspacereqbulkn from './pages/Addpauspacereqbulkn';
import Viewnpauspacereqadmin from './pages/Viewnpauspacereqadmin';

import Viewpauspacereqprog from './pages/Viewpauspacereqprog';

import Editnpaunequip from './pages/Editnpaunequip';
import Viewnpaunequip from './pages/Viewnpaunequip';
import Addnpaunequip from './pages/Addnpaunequip';
import Addpaunequipbulkn from './pages/Addpaunequipbulkn';
import Viewnpaunequipadmin from './pages/Viewnpaunequipadmin';

import Viewpaunequipprog from './pages/Viewpaunequipprog';

import Editnpauccomfarea from './pages/Editnpauccomfarea';
import Viewnpauccomfarea from './pages/Viewnpauccomfarea';
import Addnpauccomfarea from './pages/Addnpauccomfarea';
import Addpauccomfareabulkn from './pages/Addpauccomfareabulkn';
import Viewnpauccomfareaadmin from './pages/Viewnpauccomfareaadmin';

import Viewpauccomfareaprog from './pages/Viewpauccomfareaprog';

import Editnpauntermlanwan from './pages/Editnpauntermlanwan';
import Viewnpauntermlanwan from './pages/Viewnpauntermlanwan';
import Addnpauntermlanwan from './pages/Addnpauntermlanwan';
import Addpauntermlanwanbulkn from './pages/Addpauntermlanwanbulkn';
import Viewnpauntermlanwanadmin from './pages/Viewnpauntermlanwanadmin';

import Viewpauntermlanwanprog from './pages/Viewpauntermlanwanprog';

import Editnpausoftware from './pages/Editnpausoftware';
import Viewnpausoftware from './pages/Viewnpausoftware';
import Addnpausoftware from './pages/Addnpausoftware';
import Addpausoftwarebulkn from './pages/Addpausoftwarebulkn';
import Viewnpausoftwareadmin from './pages/Viewnpausoftwareadmin';

import Viewpausoftwareprog from './pages/Viewpausoftwareprog';

import Editnpaunetwork from './pages/Editnpaunetwork';
import Viewnpaunetwork from './pages/Viewnpaunetwork';
import Addnpaunetwork from './pages/Addnpaunetwork';
import Addpaunetworkbulkn from './pages/Addpaunetworkbulkn';
import Viewnpaunetworkadmin from './pages/Viewnpaunetworkadmin';

import Viewpaunetworkprog from './pages/Viewpaunetworkprog';

import Editnpaulibarea from './pages/Editnpaulibarea';
import Viewnpaulibarea from './pages/Viewnpaulibarea';
import Addnpaulibarea from './pages/Addnpaulibarea';
import Addpaulibareabulkn from './pages/Addpaulibareabulkn';
import Viewnpaulibareaadmin from './pages/Viewnpaulibareaadmin';

import Viewpaulibareaprog from './pages/Viewpaulibareaprog';

import Editnpaunlibbooks from './pages/Editnpaunlibbooks';
import Viewnpaunlibbooks from './pages/Viewnpaunlibbooks';
import Addnpaunlibbooks from './pages/Addnpaunlibbooks';
import Addpaunlibbooksbulkn from './pages/Addpaunlibbooksbulkn';
import Viewnpaunlibbooksadmin from './pages/Viewnpaunlibbooksadmin';

import Viewpaunlibbooksprog from './pages/Viewpaunlibbooksprog';

import Editnpaunlibjournal from './pages/Editnpaunlibjournal';
import Viewnpaunlibjournal from './pages/Viewnpaunlibjournal';
import Addnpaunlibjournal from './pages/Addnpaunlibjournal';
import Addpaunlibjournalbulkn from './pages/Addpaunlibjournalbulkn';
import Viewnpaunlibjournaladmin from './pages/Viewnpaunlibjournaladmin';

import Viewpaunlibjournalprog from './pages/Viewpaunlibjournalprog';

import Editnpauclassblock from './pages/Editnpauclassblock';
import Viewnpauclassblock from './pages/Viewnpauclassblock';
import Addnpauclassblock from './pages/Addnpauclassblock';
import Addpauclassblockbulkn from './pages/Addpauclassblockbulkn';
import Viewnpauclassblockadmin from './pages/Viewnpauclassblockadmin';

import Viewpauclassblockprog from './pages/Viewpauclassblockprog';

import Editnpauclassnew from './pages/Editnpauclassnew';
import Viewnpauclassnew from './pages/Viewnpauclassnew';
import Addnpauclassnew from './pages/Addnpauclassnew';
import Addpauclassnewbulkn from './pages/Addpauclassnewbulkn';
import Viewnpauclassnewadmin from './pages/Viewnpauclassnewadmin';

import Viewpauclassnewprog from './pages/Viewpauclassnewprog';

import Editnpaunnoofdrawhall from './pages/Editnpaunnoofdrawhall';
import Viewnpaunnoofdrawhall from './pages/Viewnpaunnoofdrawhall';
import Addnpaunnoofdrawhall from './pages/Addnpaunnoofdrawhall';
import Addpaunnoofdrawhallbulkn from './pages/Addpaunnoofdrawhallbulkn';
import Viewnpaunnoofdrawhalladmin from './pages/Viewnpaunnoofdrawhalladmin';

import Viewpaunnoofdrawhallprog from './pages/Viewpaunnoofdrawhallprog';

import Editnpauadminarea from './pages/Editnpauadminarea';
import Viewnpauadminarea from './pages/Viewnpauadminarea';
import Addnpauadminarea from './pages/Addnpauadminarea';
import Addpauadminareabulkn from './pages/Addpauadminareabulkn';
import Viewnpauadminareaadmin from './pages/Viewnpauadminareaadmin';

import Viewpauadminareaprog from './pages/Viewpauadminareaprog';

import Editnpaunamen from './pages/Editnpaunamen';
import Viewnpaunamen from './pages/Viewnpaunamen';
import Addnpaunamen from './pages/Addnpaunamen';
import Addpaunamenbulkn from './pages/Addpaunamenbulkn';
import Viewnpaunamenadmin from './pages/Viewnpaunamenadmin';

import Viewpaunamenprog from './pages/Viewpaunamenprog';

import Editnpaubhostel from './pages/Editnpaubhostel';
import Viewnpaubhostel from './pages/Viewnpaubhostel';
import Addnpaubhostel from './pages/Addnpaubhostel';
import Addpaubhostelbulkn from './pages/Addpaubhostelbulkn';
import Viewnpaubhosteladmin from './pages/Viewnpaubhosteladmin';

import Viewpaubhostelprog from './pages/Viewpaubhostelprog';

import Editnpaughostel from './pages/Editnpaughostel';
import Viewnpaughostel from './pages/Viewnpaughostel';
import Addnpaughostel from './pages/Addnpaughostel';
import Addpaughostelbulkn from './pages/Addpaughostelbulkn';
import Viewnpaughosteladmin from './pages/Viewnpaughosteladmin';

import Viewpaughostelprog from './pages/Viewpaughostelprog';

import Editnpaunotherbuildarea from './pages/Editnpaunotherbuildarea';
import Viewnpaunotherbuildarea from './pages/Viewnpaunotherbuildarea';
import Addnpaunotherbuildarea from './pages/Addnpaunotherbuildarea';
import Addpaunotherbuildareabulkn from './pages/Addpaunotherbuildareabulkn';
import Viewnpaunotherbuildareaadmin from './pages/Viewnpaunotherbuildareaadmin';

import Viewpaunotherbuildareaprog from './pages/Viewpaunotherbuildareaprog';

import Editnpauphyedu from './pages/Editnpauphyedu';
import Viewnpauphyedu from './pages/Viewnpauphyedu';
import Addnpauphyedu from './pages/Addnpauphyedu';
import Addpauphyedubulkn from './pages/Addpauphyedubulkn';
import Viewnpauphyeduadmin from './pages/Viewnpauphyeduadmin';

import Viewpauphyeduprog from './pages/Viewpauphyeduprog';

import Editnpaufacilityavail from './pages/Editnpaufacilityavail';
import Viewnpaufacilityavail from './pages/Viewnpaufacilityavail';
import Addnpaufacilityavail from './pages/Addnpaufacilityavail';
import Addpaufacilityavailbulkn from './pages/Addpaufacilityavailbulkn';
import Viewnpaufacilityavailadmin from './pages/Viewnpaufacilityavailadmin';

import Viewpaufacilityavailprog from './pages/Viewpaufacilityavailprog';

import Editnpaualumni from './pages/Editnpaualumni';
import Viewnpaualumni from './pages/Viewnpaualumni';
import Addnpaualumni from './pages/Addnpaualumni';
import Addpaualumnibulkn from './pages/Addpaualumnibulkn';
import Viewnpaualumniadmin from './pages/Viewnpaualumniadmin';

import Viewpaualumniprog from './pages/Viewpaualumniprog';

import Editnpautpc from './pages/Editnpautpc';
import Viewnpautpc from './pages/Viewnpautpc';
import Addnpautpc from './pages/Addnpautpc';
import Addpautpcbulkn from './pages/Addpautpcbulkn';
import Viewnpautpcadmin from './pages/Viewnpautpcadmin';

import Viewpautpcprog from './pages/Viewpautpcprog';

import Editnpaunhealth from './pages/Editnpaunhealth';
import Viewnpaunhealth from './pages/Viewnpaunhealth';
import Addnpaunhealth from './pages/Addnpaunhealth';
import Addpaunhealthbulkn from './pages/Addpaunhealthbulkn';
import Viewnpaunhealthadmin from './pages/Viewnpaunhealthadmin';

import Viewpaunhealthprog from './pages/Viewpaunhealthprog';

import Editnpauothamen from './pages/Editnpauothamen';
import Viewnpauothamen from './pages/Viewnpauothamen';
import Addnpauothamen from './pages/Addnpauothamen';
import Addpauothamenbulkn from './pages/Addpauothamenbulkn';
import Viewnpauothamenadmin from './pages/Viewnpauothamenadmin';

import Viewpauothamenprog from './pages/Viewpauothamenprog';

import Editnpauregrec from './pages/Editnpauregrec';
import Viewnpauregrec from './pages/Viewnpauregrec';
import Addnpauregrec from './pages/Addnpauregrec';
import Addpauregrecbulkn from './pages/Addpauregrecbulkn';
import Viewnpauregrecadmin from './pages/Viewnpauregrecadmin';

import Viewpauregrecprog from './pages/Viewpauregrecprog';

import Editnpaucertificates from './pages/Editnpaucertificates';
import Viewnpaucertificates from './pages/Viewnpaucertificates';
import Addnpaucertificates from './pages/Addnpaucertificates';
import Addpaucertificatesbulkn from './pages/Addpaucertificatesbulkn';
import Viewnpaucertificatesadmin from './pages/Viewnpaucertificatesadmin';

import Viewpaucertificatesprog from './pages/Viewpaucertificatesprog';

import Editnpaunprocfee from './pages/Editnpaunprocfee';
import Viewnpaunprocfee from './pages/Viewnpaunprocfee';
import Addnpaunprocfee from './pages/Addnpaunprocfee';
import Addpaunprocfeebulkn from './pages/Addpaunprocfeebulkn';
import Viewnpaunprocfeeadmin from './pages/Viewnpaunprocfeeadmin';

import Viewpaunprocfeeprog from './pages/Viewpaunprocfeeprog';

import Editnpauprcplendrsmnt from './pages/Editnpauprcplendrsmnt';
import Viewnpauprcplendrsmnt from './pages/Viewnpauprcplendrsmnt';
import Addnpauprcplendrsmnt from './pages/Addnpauprcplendrsmnt';
import Addpauprcplendrsmntbulkn from './pages/Addpauprcplendrsmntbulkn';
import Viewnpauprcplendrsmntadmin from './pages/Viewnpauprcplendrsmntadmin';

import Viewpauprcplendrsmntprog from './pages/Viewpauprcplendrsmntprog';

import Editnpaumngdeclaration from './pages/Editnpaumngdeclaration';
import Viewnpaumngdeclaration from './pages/Viewnpaumngdeclaration';
import Addnpaumngdeclaration from './pages/Addnpaumngdeclaration';
import Addpaumngdeclarationbulkn from './pages/Addpaumngdeclarationbulkn';
import Viewnpaumngdeclarationadmin from './pages/Viewnpaumngdeclarationadmin';

import Viewpaumngdeclarationprog from './pages/Viewpaumngdeclarationprog';



import Editnpaunewfacultydetails from './pages/Editnpaunewfacultydetails';
import Viewnpaunewfacultydetails from './pages/Viewnpaunewfacultydetails';
import Addnpaunewfacultydetails from './pages/Addnpaunewfacultydetails';
import Addpaunewfacultydetailsbulkn from './pages/Addpaunewfacultydetailsbulkn';
import Viewnpaunewfacultydetailsadmin from './pages/Viewnpaunewfacultydetailsadmin';

import Viewpaunewfacultydetailsprog from './pages/Viewpaunewfacultydetailsprog';

import Editnpaunewfeduqualif from './pages/Editnpaunewfeduqualif';
import Viewnpaunewfeduqualif from './pages/Viewnpaunewfeduqualif';
import Addnpaunewfeduqualif from './pages/Addnpaunewfeduqualif';
import Addpaunewfeduqualifbulkn from './pages/Addpaunewfeduqualifbulkn';
import Viewnpaunewfeduqualifadmin from './pages/Viewnpaunewfeduqualifadmin';

import Viewpaunewfeduqualifprog from './pages/Viewpaunewfeduqualifprog';

// import Editnpauconfaccourse from './pages/Editnpauconfaccourse';
// import Viewnpauconfaccourse from './pages/Viewnpauconfaccourse';
// import Addnpauconfaccourse from './pages/Addnpauconfaccourse';
// import Addpauconfaccoursebulkn from './pages/Addpauconfaccoursebulkn';
// import Viewnpauconfaccourseadmin from './pages/Viewnpauconfaccourseadmin';

// import Viewpauconfaccourseprog from './pages/Viewpauconfaccourseprog';

// import Editnpauconfacmemtech from './pages/Editnpauconfacmemtech';
// import Viewnpauconfacmemtech from './pages/Viewnpauconfacmemtech';
// import Addnpauconfacmemtech from './pages/Addnpauconfacmemtech';
// import Addpauconfacmemtechbulkn from './pages/Addpauconfacmemtechbulkn';
// import Viewnpauconfacmemtechadmin from './pages/Viewnpauconfacmemtechadmin';

// import Viewpauconfacmemtechprog from './pages/Viewpauconfacmemtechprog';

// import Editnpauspacereq from './pages/Editnpauspacereq';
// import Viewnpauspacereq from './pages/Viewnpauspacereq';
// import Addnpauspacereq from './pages/Addnpauspacereq';
// import Addpauspacereqbulkn from './pages/Addpauspacereqbulkn';
// import Viewnpauspacereqadmin from './pages/Viewnpauspacereqadmin';

// import Viewpauspacereqprog from './pages/Viewpauspacereqprog';

// import Editnpaunequip from './pages/Editnpaunequip';
// import Viewnpaunequip from './pages/Viewnpaunequip';
// import Addnpaunequip from './pages/Addnpaunequip';
// import Addpaunequipbulkn from './pages/Addpaunequipbulkn';
// import Viewnpaunequipadmin from './pages/Viewnpaunequipadmin';

// import Viewpaunequipprog from './pages/Viewpaunequipprog';

// import Editnpaunlibjournal from './pages/Editnpaunlibjournal';
// import Viewnpaunlibjournal from './pages/Viewnpaunlibjournal';
// import Addnpaunlibjournal from './pages/Addnpaunlibjournal';
// import Addpaunlibjournalbulkn from './pages/Addpaunlibjournalbulkn';
// import Viewnpaunlibjournaladmin from './pages/Viewnpaunlibjournaladmin';

// import Viewpaunlibjournalprog from './pages/Viewpaunlibjournalprog';

// import Editnpaunnoofdrawhall from './pages/Editnpaunnoofdrawhall';
// import Viewnpaunnoofdrawhall from './pages/Viewnpaunnoofdrawhall';
// import Addnpaunnoofdrawhall from './pages/Addnpaunnoofdrawhall';
// import Addpaunnoofdrawhallbulkn from './pages/Addpaunnoofdrawhallbulkn';
// import Viewnpaunnoofdrawhalladmin from './pages/Viewnpaunnoofdrawhalladmin';

// import Viewpaunnoofdrawhallprog from './pages/Viewpaunnoofdrawhallprog';

import Editnpaunewlanddetails from './pages/Editnpaunewlanddetails';
import Viewnpaunewlanddetails from './pages/Viewnpaunewlanddetails';
import Addnpaunewlanddetails from './pages/Addnpaunewlanddetails';
import Addpaunewlanddetailsbulkn from './pages/Addpaunewlanddetailsbulkn';
import Viewnpaunewlanddetailsadmin from './pages/Viewnpaunewlanddetailsadmin';

import Viewpaunewlanddetailsprog from './pages/Viewpaunewlanddetailsprog';

import Editnpaunewfaddqualif from './pages/Editnpaunewfaddqualif';
import Viewnpaunewfaddqualif from './pages/Viewnpaunewfaddqualif';
import Addnpaunewfaddqualif from './pages/Addnpaunewfaddqualif';
import Addpaunewfaddqualifbulkn from './pages/Addpaunewfaddqualifbulkn';
import Viewnpaunewfaddqualifadmin from './pages/Viewnpaunewfaddqualifadmin';

import Viewpaunewfaddqualifprog from './pages/Viewpaunewfaddqualifprog';

import Editnpaunewfacdexp from './pages/Editnpaunewfacdexp';
import Viewnpaunewfacdexp from './pages/Viewnpaunewfacdexp';
import Addnpaunewfacdexp from './pages/Addnpaunewfacdexp';
import Addpaunewfacdexpbulkn from './pages/Addpaunewfacdexpbulkn';
import Viewnpaunewfacdexpadmin from './pages/Viewnpaunewfacdexpadmin';

import Viewpaunewfacdexpprog from './pages/Viewpaunewfacdexpprog';

import Editnpaunewfindexp from './pages/Editnpaunewfindexp';
import Viewnpaunewfindexp from './pages/Viewnpaunewfindexp';
import Addnpaunewfindexp from './pages/Addnpaunewfindexp';
import Addpaunewfindexpbulkn from './pages/Addpaunewfindexpbulkn';
import Viewnpaunewfindexpadmin from './pages/Viewnpaunewfindexpadmin';

import Viewpaunewfindexpprog from './pages/Viewpaunewfindexpprog';

import Dashanna1 from './pages/Dashanna1';
import Dashannaadmin1 from './pages/Dashannaadmin1';


import Editnpnirf from './pages/Editnpnirf';
import Viewnpnirf from './pages/Viewnpnirf';
import Addnpnirf from './pages/Addnpnirf';
import Addpnirfbulkn from './pages/Addpnirfbulkn';
import Viewnpnirfadmin from './pages/Viewnpnirfadmin';

import Viewpnirfprog from './pages/Viewpnirfprog';

import Editnpadmnew from './pages/Editnpadmnew';
import Viewnpadmnew from './pages/Viewnpadmnew';
import Addnpadmnew from './pages/Addnpadmnew';
import Addpadmnewbulkn from './pages/Addpadmnewbulkn';
import Viewnpadmnewadmin from './pages/Viewnpadmnewadmin';

import Viewpadmnewprog from './pages/Viewpadmnewprog';

import Editnpnbanew from './pages/Editnpnbanew';
import Viewnpnbanew from './pages/Viewnpnbanew';
import Addnpnbanew from './pages/Addnpnbanew';
import Addpnbanewbulkn from './pages/Addpnbanewbulkn';
import Viewnpnbanewadmin from './pages/Viewnpnbanewadmin';

import Editnpaunewfcoeexp from './pages/Editnpaunewfcoeexp';
import Viewnpaunewfcoeexp from './pages/Viewnpaunewfcoeexp';
import Addnpaunewfcoeexp from './pages/Addnpaunewfcoeexp';
import Addpaunewfcoeexpbulkn from './pages/Addpaunewfcoeexpbulkn';
import Viewnpaunewfcoeexpadmin from './pages/Viewnpaunewfcoeexpadmin';

import Viewpaunewfcoeexpprog from './pages/Viewpaunewfcoeexpprog';


import Editnnuexplearning from './pages/Editnnuexplearning';
import Viewnnuexplearning from './pages/Viewnnuexplearning';
import Addnnuexplearning from './pages/Addnnuexplearning';
import Addnuexplearningbulkn from './pages/Addnuexplearningbulkn';
import Viewnnuexplearningadmin from './pages/Viewnnuexplearningadmin';

import Viewnuexplearningprog from './pages/Viewnuexplearningprog';

import Editnnucodeofethics from './pages/Editnnucodeofethics';
import Viewnnucodeofethics from './pages/Viewnnucodeofethics';
import Addnnucodeofethics from './pages/Addnnucodeofethics';
import Addnucodeofethicsbulkn from './pages/Addnucodeofethicsbulkn';
import Viewnnucodeofethicsadmin from './pages/Viewnnucodeofethicsadmin';

import Viewnucodeofethicsprog from './pages/Viewnucodeofethicsprog';

import Editnnlufocus from './pages/Editnnlufocus';
import Viewnnlufocus from './pages/Viewnnlufocus';
import Addnnlufocus from './pages/Addnnlufocus';
import Addnlufocusbulkn from './pages/Addnlufocusbulkn';
import Viewnnlufocusadmin from './pages/Viewnnlufocusadmin';

import Viewnlufocusprog from './pages/Viewnlufocusprog';

import Editnnluadmission from './pages/Editnnluadmission';
import Viewnnluadmission from './pages/Viewnnluadmission';
import Addnnluadmission from './pages/Addnnluadmission';
import Addnluadmissionbulkn from './pages/Addnluadmissionbulkn';
import Viewnnluadmissionadmin from './pages/Viewnnluadmissionadmin';

import Viewnluadmissionprog from './pages/Viewnluadmissionprog';

import Editnnauscholarship from './pages/Editnnauscholarship';
import Viewnnauscholarship from './pages/Viewnnauscholarship';
import Addnnauscholarship from './pages/Addnnauscholarship';
import Addnauscholarshipbulkn from './pages/Addnauscholarshipbulkn';
import Viewnnauscholarshipadmin from './pages/Viewnnauscholarshipadmin';

import Viewnauscholarshipprog from './pages/Viewnauscholarshipprog';



import Editnppuclgdetails from './pages/Editnppuclgdetails';
import Viewnppuclgdetails from './pages/Viewnppuclgdetails';
import Addnppuclgdetails from './pages/Addnppuclgdetails';
import Addppuclgdetailsbulkn from './pages/Addppuclgdetailsbulkn';
import Viewnppuclgdetailsadmin from './pages/Viewnppuclgdetailsadmin';

import Viewppuclgdetailsprog from './pages/Viewppuclgdetailsprog';

import Editnppuheaddetails from './pages/Editnppuheaddetails';
import Viewnppuheaddetails from './pages/Viewnppuheaddetails';
import Addnppuheaddetails from './pages/Addnppuheaddetails';
import Addppuheaddetailsbulkn from './pages/Addppuheaddetailsbulkn';
import Viewnppuheaddetailsadmin from './pages/Viewnppuheaddetailsadmin';

import Viewppuheaddetailsprog from './pages/Viewppuheaddetailsprog';

import Editnppulegalrep from './pages/Editnppulegalrep';
import Viewnppulegalrep from './pages/Viewnppulegalrep';
import Addnppulegalrep from './pages/Addnppulegalrep';
import Addppulegalrepbulkn from './pages/Addppulegalrepbulkn';
import Viewnppulegalrepadmin from './pages/Viewnppulegalrepadmin';

import Viewppulegalrepprog from './pages/Viewppulegalrepprog';

import Editnpputrustdetails from './pages/Editnpputrustdetails';
import Viewnpputrustdetails from './pages/Viewnpputrustdetails';
import Addnpputrustdetails from './pages/Addnpputrustdetails';
import Addpputrustdetailsbulkn from './pages/Addpputrustdetailsbulkn';
import Viewnpputrustdetailsadmin from './pages/Viewnpputrustdetailsadmin';

import Viewpputrustdetailsprog from './pages/Viewpputrustdetailsprog';

import Editnppupermission from './pages/Editnppupermission';
import Viewnppupermission from './pages/Viewnppupermission';
import Addnppupermission from './pages/Addnppupermission';
import Addppupermissionbulkn from './pages/Addppupermissionbulkn';
import Viewnppupermissionadmin from './pages/Viewnppupermissionadmin';

import Viewppupermissionprog from './pages/Viewppupermissionprog';

import Editnppumanagingbody from './pages/Editnppumanagingbody';
import Viewnppumanagingbody from './pages/Viewnppumanagingbody';
import Addnppumanagingbody from './pages/Addnppumanagingbody';
import Addppumanagingbodybulkn from './pages/Addppumanagingbodybulkn';
import Viewnppumanagingbodyadmin from './pages/Viewnppumanagingbodyadmin';

import Viewppumanagingbodyprog from './pages/Viewppumanagingbodyprog';

import Editnppuwlfcomt from './pages/Editnppuwlfcomt';
import Viewnppuwlfcomt from './pages/Viewnppuwlfcomt';
import Addnppuwlfcomt from './pages/Addnppuwlfcomt';
import Addppuwlfcomtbulkn from './pages/Addppuwlfcomtbulkn';
import Viewnppuwlfcomtadmin from './pages/Viewnppuwlfcomtadmin';

import Viewppuwlfcomtprog from './pages/Viewppuwlfcomtprog';

import Editnpputrustfin from './pages/Editnpputrustfin';
import Viewnpputrustfin from './pages/Viewnpputrustfin';
import Addnpputrustfin from './pages/Addnpputrustfin';
import Addpputrustfinbulkn from './pages/Addpputrustfinbulkn';
import Viewnpputrustfinadmin from './pages/Viewnpputrustfinadmin';

import Viewpputrustfinprog from './pages/Viewpputrustfinprog';

import Editnppufdrdetails from './pages/Editnppufdrdetails';
import Viewnppufdrdetails from './pages/Viewnppufdrdetails';
import Addnppufdrdetails from './pages/Addnppufdrdetails';
import Addppufdrdetailsbulkn from './pages/Addppufdrdetailsbulkn';
import Viewnppufdrdetailsadmin from './pages/Viewnppufdrdetailsadmin';

import Viewppufdrdetailsprog from './pages/Viewppufdrdetailsprog';

import Editnppuimmvprop from './pages/Editnppuimmvprop';
import Viewnppuimmvprop from './pages/Viewnppuimmvprop';
import Addnppuimmvprop from './pages/Addnppuimmvprop';
import Addppuimmvpropbulkn from './pages/Addppuimmvpropbulkn';
import Viewnppuimmvpropadmin from './pages/Viewnppuimmvpropadmin';

import Viewppuimmvpropprog from './pages/Viewppuimmvpropprog';

import Editnppufinance from './pages/Editnppufinance';
import Viewnppufinance from './pages/Viewnppufinance';
import Addnppufinance from './pages/Addnppufinance';
import Addppufinancebulkn from './pages/Addppufinancebulkn';
import Viewnppufinanceadmin from './pages/Viewnppufinanceadmin';

import Viewppufinanceprog from './pages/Viewppufinanceprog';

import Editnppuland from './pages/Editnppuland';
import Viewnppuland from './pages/Viewnppuland';
import Addnppuland from './pages/Addnppuland';
import Addppulandbulkn from './pages/Addppulandbulkn';
import Viewnppulandadmin from './pages/Viewnppulandadmin';

import Viewppulandprog from './pages/Viewppulandprog';

import Editnppubuilding from './pages/Editnppubuilding';
import Viewnppubuilding from './pages/Viewnppubuilding';
import Addnppubuilding from './pages/Addnppubuilding';
import Addppubuildingbulkn from './pages/Addppubuildingbulkn';
import Viewnppubuildingadmin from './pages/Viewnppubuildingadmin';

import Viewppubuildingprog from './pages/Viewppubuildingprog';

import Editnppuhostel from './pages/Editnppuhostel';
import Viewnppuhostel from './pages/Viewnppuhostel';
import Addnppuhostel from './pages/Addnppuhostel';
import Addppuhostelbulkn from './pages/Addppuhostelbulkn';
import Viewnppuhosteladmin from './pages/Viewnppuhosteladmin';

import Viewppuhostelprog from './pages/Viewppuhostelprog';

import Editnppurooms from './pages/Editnppurooms';
import Viewnppurooms from './pages/Viewnppurooms';
import Addnppurooms from './pages/Addnppurooms';
import Addppuroomsbulkn from './pages/Addppuroomsbulkn';
import Viewnppuroomsadmin from './pages/Viewnppuroomsadmin';

import Viewppuroomsprog from './pages/Viewppuroomsprog';

import Editnppuphyedu from './pages/Editnppuphyedu';
import Viewnppuphyedu from './pages/Viewnppuphyedu';
import Addnppuphyedu from './pages/Addnppuphyedu';
import Addppuphyedubulkn from './pages/Addppuphyedubulkn';
import Viewnppuphyeduadmin from './pages/Viewnppuphyeduadmin';

import Viewppuphyeduprog from './pages/Viewppuphyeduprog';

import Editnppustaff from './pages/Editnppustaff';
import Viewnppustaff from './pages/Viewnppustaff';
import Addnppustaff from './pages/Addnppustaff';
import Addppustaffbulkn from './pages/Addppustaffbulkn';
import Viewnppustaffadmin from './pages/Viewnppustaffadmin';

import Viewppustaffprog from './pages/Viewppustaffprog';

import Editnppucollege from './pages/Editnppucollege';
import Viewnppucollege from './pages/Viewnppucollege';
import Addnppucollege from './pages/Addnppucollege';
import Addppucollegebulkn from './pages/Addppucollegebulkn';
import Viewnppucollegeadmin from './pages/Viewnppucollegeadmin';

import Viewppucollegeprog from './pages/Viewppucollegeprog';

import Editnpputrust from './pages/Editnpputrust';
import Viewnpputrust from './pages/Viewnpputrust';
import Addnpputrust from './pages/Addnpputrust';
import Addpputrustbulkn from './pages/Addpputrustbulkn';
import Viewnpputrustadmin from './pages/Viewnpputrustadmin';

import Viewpputrustprog from './pages/Viewpputrustprog';

import Editnppuaddprog from './pages/Editnppuaddprog';
import Viewnppuaddprog from './pages/Viewnppuaddprog';
import Addnppuaddprog from './pages/Addnppuaddprog';
import Addppuaddprogbulkn from './pages/Addppuaddprogbulkn';
import Viewnppuaddprogadmin from './pages/Viewnppuaddprogadmin';

import Viewppuaddprogprog from './pages/Viewppuaddprogprog';

import Editnppuvarintake from './pages/Editnppuvarintake';
import Viewnppuvarintake from './pages/Viewnppuvarintake';
import Addnppuvarintake from './pages/Addnppuvarintake';
import Addppuvarintakebulkn from './pages/Addppuvarintakebulkn';
import Viewnppuvarintakeadmin from './pages/Viewnppuvarintakeadmin';

import Viewppuvarintakeprog from './pages/Viewppuvarintakeprog';

import Editnppuexistprog from './pages/Editnppuexistprog';
import Viewnppuexistprog from './pages/Viewnppuexistprog';
import Addnppuexistprog from './pages/Addnppuexistprog';
import Addppuexistprogbulkn from './pages/Addppuexistprogbulkn';
import Viewnppuexistprogadmin from './pages/Viewnppuexistprogadmin';

import Viewppuexistprogprog from './pages/Viewppuexistprogprog';

import Editnppucourses from './pages/Editnppucourses';
import Viewnppucourses from './pages/Viewnppucourses';
import Addnppucourses from './pages/Addnppucourses';
import Addppucoursesbulkn from './pages/Addppucoursesbulkn';
import Viewnppucoursesadmin from './pages/Viewnppucoursesadmin';

import Viewppucoursesprog from './pages/Viewppucoursesprog';

import Editnppucoursrep from './pages/Editnppucoursrep';
import Viewnppucoursrep from './pages/Viewnppucoursrep';
import Addnppucoursrep from './pages/Addnppucoursrep';
import Addppucoursrepbulkn from './pages/Addppucoursrepbulkn';
import Viewnppucoursrepadmin from './pages/Viewnppucoursrepadmin';

import Viewppucoursrepprog from './pages/Viewppucoursrepprog';

import Editnppuprincipal from './pages/Editnppuprincipal';
import Viewnppuprincipal from './pages/Viewnppuprincipal';
import Addnppuprincipal from './pages/Addnppuprincipal';
import Addppuprincipalbulkn from './pages/Addppuprincipalbulkn';
import Viewnppuprincipaladmin from './pages/Viewnppuprincipaladmin';

import Viewppuprincipalprog from './pages/Viewppuprincipalprog';

import Editnppufaculty from './pages/Editnppufaculty';
import Viewnppufaculty from './pages/Viewnppufaculty';
import Addnppufaculty from './pages/Addnppufaculty';
import Addppufacultybulkn from './pages/Addppufacultybulkn';
import Viewnppufacultyadmin from './pages/Viewnppufacultyadmin';

import Viewppufacultyprog from './pages/Viewppufacultyprog';

import Editnppustafstudratio from './pages/Editnppustafstudratio';
import Viewnppustafstudratio from './pages/Viewnppustafstudratio';
import Addnppustafstudratio from './pages/Addnppustafstudratio';
import Addppustafstudratiobulkn from './pages/Addppustafstudratiobulkn';
import Viewnppustafstudratioadmin from './pages/Viewnppustafstudratioadmin';

import Viewppustafstudratioprog from './pages/Viewppustafstudratioprog';

import Editnppulabequip from './pages/Editnppulabequip';
import Viewnppulabequip from './pages/Viewnppulabequip';
import Addnppulabequip from './pages/Addnppulabequip';
import Addppulabequipbulkn from './pages/Addppulabequipbulkn';
import Viewnppulabequipadmin from './pages/Viewnppulabequipadmin';

import Viewppulabequipprog from './pages/Viewppulabequipprog';

import Editnppulabequipprocure from './pages/Editnppulabequipprocure';
import Viewnppulabequipprocure from './pages/Viewnppulabequipprocure';
import Addnppulabequipprocure from './pages/Addnppulabequipprocure';
import Addppulabequipprocurebulkn from './pages/Addppulabequipprocurebulkn';
import Viewnppulabequipprocureadmin from './pages/Viewnppulabequipprocureadmin';

import Viewppulabequipprocureprog from './pages/Viewppulabequipprocureprog';

import Editnppulecturerooms from './pages/Editnppulecturerooms';
import Viewnppulecturerooms from './pages/Viewnppulecturerooms';
import Addnppulecturerooms from './pages/Addnppulecturerooms';
import Addppulectureroomsbulkn from './pages/Addppulectureroomsbulkn';
import Viewnppulectureroomsadmin from './pages/Viewnppulectureroomsadmin';

import Viewppulectureroomsprog from './pages/Viewppulectureroomsprog';

import Editnppuavailclsroom from './pages/Editnppuavailclsroom';
import Viewnppuavailclsroom from './pages/Viewnppuavailclsroom';
import Addnppuavailclsroom from './pages/Addnppuavailclsroom';
import Addppuavailclsroombulkn from './pages/Addppuavailclsroombulkn';
import Viewnppuavailclsroomadmin from './pages/Viewnppuavailclsroomadmin';

import Viewppuavailclsroomprog from './pages/Viewppuavailclsroomprog';

import Editnppunetwork from './pages/Editnppunetwork';
import Viewnppunetwork from './pages/Viewnppunetwork';
import Addnppunetwork from './pages/Addnppunetwork';
import Addppunetworkbulkn from './pages/Addppunetworkbulkn';
import Viewnppunetworkadmin from './pages/Viewnppunetworkadmin';

import Viewppunetworkprog from './pages/Viewppunetworkprog';

import Editnppucompfaci from './pages/Editnppucompfaci';
import Viewnppucompfaci from './pages/Viewnppucompfaci';
import Addnppucompfaci from './pages/Addnppucompfaci';
import Addppucompfacibulkn from './pages/Addppucompfacibulkn';
import Viewnppucompfaciadmin from './pages/Viewnppucompfaciadmin';

import Viewppucompfaciprog from './pages/Viewppucompfaciprog';

import Editnppucompsoftware from './pages/Editnppucompsoftware';
import Viewnppucompsoftware from './pages/Viewnppucompsoftware';
import Addnppucompsoftware from './pages/Addnppucompsoftware';
import Addppucompsoftwarebulkn from './pages/Addppucompsoftwarebulkn';
import Viewnppucompsoftwareadmin from './pages/Viewnppucompsoftwareadmin';

import Viewppucompsoftwareprog from './pages/Viewppucompsoftwareprog';

import Editnppucompaddinfo from './pages/Editnppucompaddinfo';
import Viewnppucompaddinfo from './pages/Viewnppucompaddinfo';
import Addnppucompaddinfo from './pages/Addnppucompaddinfo';
import Addppucompaddinfobulkn from './pages/Addppucompaddinfobulkn';
import Viewnppucompaddinfoadmin from './pages/Viewnppucompaddinfoadmin';

import Viewppucompaddinfoprog from './pages/Viewppucompaddinfoprog';

import Editnppucompaddsoft from './pages/Editnppucompaddsoft';
import Viewnppucompaddsoft from './pages/Viewnppucompaddsoft';
import Addnppucompaddsoft from './pages/Addnppucompaddsoft';
import Addppucompaddsoftbulkn from './pages/Addppucompaddsoftbulkn';
import Viewnppucompaddsoftadmin from './pages/Viewnppucompaddsoftadmin';

import Viewppucompaddsoftprog from './pages/Viewppucompaddsoftprog';

import Editnppulibrary from './pages/Editnppulibrary';
import Viewnppulibrary from './pages/Viewnppulibrary';
import Addnppulibrary from './pages/Addnppulibrary';
import Addppulibrarybulkn from './pages/Addppulibrarybulkn';
import Viewnppulibraryadmin from './pages/Viewnppulibraryadmin';

import Viewppulibraryprog from './pages/Viewppulibraryprog';

import Editnppulibstaff from './pages/Editnppulibstaff';
import Viewnppulibstaff from './pages/Viewnppulibstaff';
import Addnppulibstaff from './pages/Addnppulibstaff';
import Addppulibstaffbulkn from './pages/Addppulibstaffbulkn';
import Viewnppulibstaffadmin from './pages/Viewnppulibstaffadmin';

import Viewppulibstaffprog from './pages/Viewppulibstaffprog';

import Editnppulibbooks from './pages/Editnppulibbooks';
import Viewnppulibbooks from './pages/Viewnppulibbooks';
import Addnppulibbooks from './pages/Addnppulibbooks';
import Addppulibbooksbulkn from './pages/Addppulibbooksbulkn';
import Viewnppulibbooksadmin from './pages/Viewnppulibbooksadmin';

import Viewppulibbooksprog from './pages/Viewppulibbooksprog';

import Editnppulibindjrnl from './pages/Editnppulibindjrnl';
import Viewnppulibindjrnl from './pages/Viewnppulibindjrnl';
import Addnppulibindjrnl from './pages/Addnppulibindjrnl';
import Addppulibindjrnlbulkn from './pages/Addppulibindjrnlbulkn';
import Viewnppulibindjrnladmin from './pages/Viewnppulibindjrnladmin';

import Viewppulibindjrnlprog from './pages/Viewppulibindjrnlprog';

import Editnppulibforgjrnl from './pages/Editnppulibforgjrnl';
import Viewnppulibforgjrnl from './pages/Viewnppulibforgjrnl';
import Addnppulibforgjrnl from './pages/Addnppulibforgjrnl';
import Addppulibforgjrnlbulkn from './pages/Addppulibforgjrnlbulkn';
import Viewnppulibforgjrnladmin from './pages/Viewnppulibforgjrnladmin';

import Viewppulibforgjrnlprog from './pages/Viewppulibforgjrnlprog';

import Editnppulibautomation from './pages/Editnppulibautomation';
import Viewnppulibautomation from './pages/Viewnppulibautomation';
import Addnppulibautomation from './pages/Addnppulibautomation';
import Addppulibautomationbulkn from './pages/Addppulibautomationbulkn';
import Viewnppulibautomationadmin from './pages/Viewnppulibautomationadmin';

import Viewppulibautomationprog from './pages/Viewppulibautomationprog';

import Editnppulibprocure from './pages/Editnppulibprocure';
import Viewnppulibprocure from './pages/Viewnppulibprocure';
import Addnppulibprocure from './pages/Addnppulibprocure';
import Addppulibprocurebulkn from './pages/Addppulibprocurebulkn';
import Viewnppulibprocureadmin from './pages/Viewnppulibprocureadmin';

import Viewppulibprocureprog from './pages/Viewppulibprocureprog';

import Editnppulibexp from './pages/Editnppulibexp';
import Viewnppulibexp from './pages/Viewnppulibexp';
import Addnppulibexp from './pages/Addnppulibexp';
import Addppulibexpbulkn from './pages/Addppulibexpbulkn';
import Viewnppulibexpadmin from './pages/Viewnppulibexpadmin';

import Viewppulibexpprog from './pages/Viewppulibexpprog';

import Editnppustudfaci from './pages/Editnppustudfaci';
import Viewnppustudfaci from './pages/Viewnppustudfaci';
import Addnppustudfaci from './pages/Addnppustudfaci';
import Addppustudfacibulkn from './pages/Addppustudfacibulkn';
import Viewnppustudfaciadmin from './pages/Viewnppustudfaciadmin';

import Viewppustudfaciprog from './pages/Viewppustudfaciprog';

import Editnppuotherfaci from './pages/Editnppuotherfaci';
import Viewnppuotherfaci from './pages/Viewnppuotherfaci';
import Addnppuotherfaci from './pages/Addnppuotherfaci';
import Addppuotherfacibulkn from './pages/Addppuotherfacibulkn';
import Viewnppuotherfaciadmin from './pages/Viewnppuotherfaciadmin';

import Viewppuotherfaciprog from './pages/Viewppuotherfaciprog';

import Editnppumedservice from './pages/Editnppumedservice';
import Viewnppumedservice from './pages/Viewnppumedservice';
import Addnppumedservice from './pages/Addnppumedservice';
import Addppumedservicebulkn from './pages/Addppumedservicebulkn';
import Viewnppumedserviceadmin from './pages/Viewnppumedserviceadmin';

import Viewppumedserviceprog from './pages/Viewppumedserviceprog';

import Editnppuaudvidequip from './pages/Editnppuaudvidequip';
import Viewnppuaudvidequip from './pages/Viewnppuaudvidequip';
import Addnppuaudvidequip from './pages/Addnppuaudvidequip';
import Addppuaudvidequipbulkn from './pages/Addppuaudvidequipbulkn';
import Viewnppuaudvidequipadmin from './pages/Viewnppuaudvidequipadmin';

import Viewppuaudvidequipprog from './pages/Viewppuaudvidequipprog';

import Editnppuclubs from './pages/Editnppuclubs';
import Viewnppuclubs from './pages/Viewnppuclubs';
import Addnppuclubs from './pages/Addnppuclubs';
import Addppuclubsbulkn from './pages/Addppuclubsbulkn';
import Viewnppuclubsadmin from './pages/Viewnppuclubsadmin';

import Viewppuclubsprog from './pages/Viewppuclubsprog';

import Editnpputechstaff from './pages/Editnpputechstaff';
import Viewnpputechstaff from './pages/Viewnpputechstaff';
import Addnpputechstaff from './pages/Addnpputechstaff';
import Addpputechstaffbulkn from './pages/Addpputechstaffbulkn';
import Viewnpputechstaffadmin from './pages/Viewnpputechstaffadmin';

import Viewpputechstaffprog from './pages/Viewpputechstaffprog';

import Editnppuminststaff from './pages/Editnppuminststaff';
import Viewnppuminststaff from './pages/Viewnppuminststaff';
import Addnppuminststaff from './pages/Addnppuminststaff';
import Addppuminststaffbulkn from './pages/Addppuminststaffbulkn';
import Viewnppuminststaffadmin from './pages/Viewnppuminststaffadmin';

import Viewppuminststaffprog from './pages/Viewppuminststaffprog';

import Editnppuaddinfo from './pages/Editnppuaddinfo';
import Viewnppuaddinfo from './pages/Viewnppuaddinfo';
import Addnppuaddinfo from './pages/Addnppuaddinfo';
import Addppuaddinfobulkn from './pages/Addppuaddinfobulkn';
import Viewnppuaddinfoadmin from './pages/Viewnppuaddinfoadmin';

import Viewppuaddinfoprog from './pages/Viewppuaddinfoprog';

import Editnppudeclaration from './pages/Editnppudeclaration';
import Viewnppudeclaration from './pages/Viewnppudeclaration';
import Addnppudeclaration from './pages/Addnppudeclaration';
import Addppudeclarationbulkn from './pages/Addppudeclarationbulkn';
import Viewnppudeclarationadmin from './pages/Viewnppudeclarationadmin';

import Viewppudeclarationprog from './pages/Viewppudeclarationprog';

import Editnppucertificates from './pages/Editnppucertificates';
import Viewnppucertificates from './pages/Viewnppucertificates';
import Addnppucertificates from './pages/Addnppucertificates';
import Addppucertificatesbulkn from './pages/Addppucertificatesbulkn';
import Viewnppucertificatesadmin from './pages/Viewnppucertificatesadmin';

import Viewppucertificatesprog from './pages/Viewppucertificatesprog';

import Editnppuaddinfoprog from './pages/Editnppuaddinfoprog';
import Viewnppuaddinfoprog from './pages/Viewnppuaddinfoprog';
import Addnppuaddinfoprog from './pages/Addnppuaddinfoprog';
import Addppuaddinfoprogbulkn from './pages/Addppuaddinfoprogbulkn';
import Viewnppuaddinfoprogadmin from './pages/Viewnppuaddinfoprogadmin';

import Viewppuaddinfoprogprog from './pages/Viewppuaddinfoprogprog';

import Editnppuaddinfonoc from './pages/Editnppuaddinfonoc';
import Viewnppuaddinfonoc from './pages/Viewnppuaddinfonoc';
import Addnppuaddinfonoc from './pages/Addnppuaddinfonoc';
import Addppuaddinfonocbulkn from './pages/Addppuaddinfonocbulkn';
import Viewnppuaddinfonocadmin from './pages/Viewnppuaddinfonocadmin';

import Viewppuaddinfonocprog from './pages/Viewppuaddinfonocprog';

import Editnppuaddinfopermission from './pages/Editnppuaddinfopermission';
import Viewnppuaddinfopermission from './pages/Viewnppuaddinfopermission';
import Addnppuaddinfopermission from './pages/Addnppuaddinfopermission';
import Addppuaddinfopermissionbulkn from './pages/Addppuaddinfopermissionbulkn';
import Viewnppuaddinfopermissionadmin from './pages/Viewnppuaddinfopermissionadmin';

import Viewppuaddinfopermissionprog from './pages/Viewppuaddinfopermissionprog';

import Editnppuaddinfobos from './pages/Editnppuaddinfobos';
import Viewnppuaddinfobos from './pages/Viewnppuaddinfobos';
import Addnppuaddinfobos from './pages/Addnppuaddinfobos';
import Addppuaddinfobosbulkn from './pages/Addppuaddinfobosbulkn';
import Viewnppuaddinfobosadmin from './pages/Viewnppuaddinfobosadmin';

import Viewppuaddinfobosprog from './pages/Viewppuaddinfobosprog';

import Editnppuaddinfosyllabi from './pages/Editnppuaddinfosyllabi';
import Viewnppuaddinfosyllabi from './pages/Viewnppuaddinfosyllabi';
import Addnppuaddinfosyllabi from './pages/Addnppuaddinfosyllabi';
import Addppuaddinfosyllabibulkn from './pages/Addppuaddinfosyllabibulkn';
import Viewnppuaddinfosyllabiadmin from './pages/Viewnppuaddinfosyllabiadmin';

import Viewppuaddinfosyllabiprog from './pages/Viewppuaddinfosyllabiprog';

import Editnppuaddinfofac from './pages/Editnppuaddinfofac';
import Viewnppuaddinfofac from './pages/Viewnppuaddinfofac';
import Addnppuaddinfofac from './pages/Addnppuaddinfofac';
import Addppuaddinfofacbulkn from './pages/Addppuaddinfofacbulkn';
import Viewnppuaddinfofacadmin from './pages/Viewnppuaddinfofacadmin';

import Viewppuaddinfofacprog from './pages/Viewppuaddinfofacprog';

import Editnppuaddinfostaffdetails from './pages/Editnppuaddinfostaffdetails';
import Viewnppuaddinfostaffdetails from './pages/Viewnppuaddinfostaffdetails';
import Addnppuaddinfostaffdetails from './pages/Addnppuaddinfostaffdetails';
import Addppuaddinfostaffdetailsbulkn from './pages/Addppuaddinfostaffdetailsbulkn';
import Viewnppuaddinfostaffdetailsadmin from './pages/Viewnppuaddinfostaffdetailsadmin';

import Viewppuaddinfostaffdetailsprog from './pages/Viewppuaddinfostaffdetailsprog';

import Editnppuaddfacdetails from './pages/Editnppuaddfacdetails';
import Viewnppuaddfacdetails from './pages/Viewnppuaddfacdetails';
import Addnppuaddfacdetails from './pages/Addnppuaddfacdetails';
import Addppuaddfacdetailsbulkn from './pages/Addppuaddfacdetailsbulkn';
import Viewnppuaddfacdetailsadmin from './pages/Viewnppuaddfacdetailsadmin';

import Viewppuaddfacdetailsprog from './pages/Viewppuaddfacdetailsprog';

import Editnppufacworkload from './pages/Editnppufacworkload';
import Viewnppufacworkload from './pages/Viewnppufacworkload';
import Addnppufacworkload from './pages/Addnppufacworkload';
import Addppufacworkloadbulkn from './pages/Addppufacworkloadbulkn';
import Viewnppufacworkloadadmin from './pages/Viewnppufacworkloadadmin';

import Viewppufacworkloadprog from './pages/Viewppufacworkloadprog';



import Editnhostel from './pages/Editnhostel';
import Viewnhostel from './pages/Viewnhostel';
import Addnhostel from './pages/Addnhostel';
import Addhostelbulkn from './pages/Addhostelbulkn';
import Viewnhosteladmin from './pages/Viewnhosteladmin';

import Viewhostelprog from './pages/Viewhostelprog';

import Viewnreservecatadminlinks from './pages/Viewnreservecatadminlinks';


import Editnawardsnew from './pages/Editnawardsnew';
import Viewnawardsnew from './pages/Viewnawardsnew';
import Addnawardsnew from './pages/Addnawardsnew';
import Addawardsnewbulkn from './pages/Addawardsnewbulkn';
import Viewnawardsnewadmin from './pages/Viewnawardsnewadmin';

import Viewawardsnewprog from './pages/Viewawardsnewprog';

import Editnvacstudents from './pages/Editnvacstudents';
import Viewnvacstudents from './pages/Viewnvacstudents';
import Addnvacstudents from './pages/Addnvacstudents';
import Addvacstudentsbulkn from './pages/Addvacstudentsbulkn';
import Viewnvacstudentsadmin from './pages/Viewnvacstudentsadmin';

import Viewvacstudentsprog from './pages/Viewvacstudentsprog';
import Viewnticketsall from './pages/Viewnticketsall';
import Editnticketsassign from './pages/Editnticketsassign';

import Viewnticketsforme from './pages/Viewnticketsforme';
import Editnticketsforme from './pages/Editnticketsforme';

import Viewsupportingdocquery1 from './pages/Viewsupportingdocquery1';


import Editnninvoicefinal from './pages/Editnninvoicefinal';
import Viewnninvoicefinal from './pages/Viewnninvoicefinal';
import Addnninvoicefinal from './pages/Addnninvoicefinal';
import Addninvoicefinalbulkn from './pages/Addninvoicefinalbulkn';
import Viewnninvoicefinaladmin from './pages/Viewnninvoicefinaladmin';

import Viewninvoicefinalprog from './pages/Viewninvoicefinalprog';

import Editnninvoiceitems from './pages/Editnninvoiceitems';
import Viewnninvoiceitems from './pages/Viewnninvoiceitems';
import Addnninvoiceitems from './pages/Addnninvoiceitems';
import Addninvoiceitemsbulkn from './pages/Addninvoiceitemsbulkn';
import Viewnninvoiceitemsadmin from './pages/Viewnninvoiceitemsadmin';

import Viewninvoiceitemsprog from './pages/Viewninvoiceitemsprog';
import Viewnninvoiceprint from './pages/Viewnninvoiceprint';


import Adminaffiliatedfeb23 from './pages/Adminaffiliatedfeb23';
import Adminunivapr23 from './pages/Adminunivapr23';
import Adminlegalnew from './pages/Adminlegalnew';
import Adminautonomousnew from './pages/Adminautonomousnew';

import Editnninvoicefnew from './pages/Editnninvoicefnew';
import Viewnninvoicefnew from './pages/Viewnninvoicefnew';
import Addnninvoicefnew from './pages/Addnninvoicefnew';
import Addninvoicefnewbulkn from './pages/Addninvoicefnewbulkn';
import Viewnninvoicefnewadmin from './pages/Viewnninvoicefnewadmin';

import Viewninvoicefnewprog from './pages/Viewninvoicefnewprog';

import Editnnorgdetails from './pages/Editnnorgdetails';
import Viewnnorgdetails from './pages/Viewnnorgdetails';
import Addnnorgdetails from './pages/Addnnorgdetails';
import Addnorgdetailsbulkn from './pages/Addnorgdetailsbulkn';
import Viewnnorgdetailsadmin from './pages/Viewnnorgdetailsadmin';

import Viewnorgdetailsprog from './pages/Viewnorgdetailsprog';

import Editnnstatus from './pages/Editnnstatus';
import Viewnnstatus from './pages/Viewnnstatus';
import Addnnstatus from './pages/Addnnstatus';
import Addnstatusbulkn from './pages/Addnstatusbulkn';
import Viewnnstatusadmin from './pages/Viewnnstatusadmin';

import Viewnstatusprog from './pages/Viewnstatusprog';

import Editnnprojects from './pages/Editnnprojects';
import Viewnnprojects from './pages/Viewnnprojects';
import Addnnprojects from './pages/Addnnprojects';
import Addnprojectsbulkn from './pages/Addnprojectsbulkn';
import Viewnnprojectsadmin from './pages/Viewnnprojectsadmin';

import Viewnprojectsprog from './pages/Viewnprojectsprog';
import Editnnprojectsnew from './pages/Editnnprojectsnew';
import Viewnnprojectsnew from './pages/Viewnnprojectsnew';
import Addnnprojectsnew from './pages/Addnnprojectsnew';
import Addnprojectsnewbulkn from './pages/Addnprojectsnewbulkn';
import Viewnnprojectsnewadmin from './pages/Viewnnprojectsnewadmin';

import Viewnprojectsnewprog from './pages/Viewnprojectsnewprog';


import Editnstudmasterall from './pages/Editnstudmasterall';
import Viewnstudmasterall from './pages/Viewnstudmasterall';
import Addnstudmasterall from './pages/Addnstudmasterall';
import Addstudmasterallbulkn from './pages/Addstudmasterallbulkn';
import Viewnstudmasteralladmin from './pages/Viewnstudmasteralladmin';

import Viewstudmasterallprog from './pages/Viewstudmasterallprog';

import Editninnovationnew from './pages/Editninnovationnew';
import Viewninnovationnew from './pages/Viewninnovationnew';
import Addninnovationnew from './pages/Addninnovationnew';
import Addinnovationnewbulkn from './pages/Addinnovationnewbulkn';
import Viewninnovationnewadmin from './pages/Viewninnovationnewadmin';

import Viewinnovationnewprog from './pages/Viewinnovationnewprog';

import Editnexpenditurenew from './pages/Editnexpenditurenew';
import Viewnexpenditurenew from './pages/Viewnexpenditurenew';
import Addnexpenditurenew from './pages/Addnexpenditurenew';
import Addexpenditurenewbulkn from './pages/Addexpenditurenewbulkn';
import Viewnexpenditurenewadmin from './pages/Viewnexpenditurenewadmin';

import Viewexpenditurenewprog from './pages/Viewexpenditurenewprog';

import Editnqualitynew from './pages/Editnqualitynew';
import Viewnqualitynew from './pages/Viewnqualitynew';
import Addnqualitynew from './pages/Addnqualitynew';
import Addqualitynewbulkn from './pages/Addqualitynewbulkn';
import Viewnqualitynewadmin from './pages/Viewnqualitynewadmin';

import Viewqualitynewprog from './pages/Viewqualitynewprog';

import Editnlquality from './pages/Editnlquality';
import Viewnlquality from './pages/Viewnlquality';
import Addnlquality from './pages/Addnlquality';
import Addlqualitybulkn from './pages/Addlqualitybulkn';
import Viewnlqualityadmin from './pages/Viewnlqualityadmin';

import Viewlqualityprog from './pages/Viewlqualityprog';

import Editnuquality from './pages/Editnuquality';
import Viewnuquality from './pages/Viewnuquality';
import Addnuquality from './pages/Addnuquality';
import Adduqualitybulkn from './pages/Adduqualitybulkn';
import Viewnuqualityadmin from './pages/Viewnuqualityadmin';

import Viewuqualityprog from './pages/Viewuqualityprog';

import Editnstudschspnew from './pages/Editnstudschspnew';
import Viewnstudschspnew from './pages/Viewnstudschspnew';
import Addnstudschspnew from './pages/Addnstudschspnew';
import Addstudschspnewbulkn from './pages/Addstudschspnewbulkn';
import Viewnstudschspnewadmin from './pages/Viewnstudschspnewadmin';

import Viewstudschspnewprog from './pages/Viewstudschspnewprog';

import Editnustudschsp from './pages/Editnustudschsp';
import Viewnustudschsp from './pages/Viewnustudschsp';
import Addnustudschsp from './pages/Addnustudschsp';
import Addustudschspbulkn from './pages/Addustudschspbulkn';
import Viewnustudschspadmin from './pages/Viewnustudschspadmin';

import Viewustudschspprog from './pages/Viewustudschspprog';

import Anamolystudmaster from './pages/Anamolystudmaster';


import Viewlegalunimenuadmin from './pages/Viewlegalunimenuadmin';
import Viewnewuniversitymenuadmin from './pages/Viewnewuniversitymenuadmin';
import Viewaffiliatedmenuadmin from './pages/Viewaffiliatedmenuadmin';
import Viewautonomousmenuadmin from './pages/Viewautonomousmenuadmin';


import Editnstudmentor from './pages/Editnstudmentor';
import Viewnstudmentor from './pages/Viewnstudmentor';
import Addnstudmentor from './pages/Addnstudmentor';
import Addstudmentorbulkn from './pages/Addstudmentorbulkn';
import Viewnstudmentoradmin from './pages/Viewnstudmentoradmin';

import Viewstudmentorprog from './pages/Viewstudmentorprog';

import Editnlphdfac from './pages/Editnlphdfac';
import Viewnlphdfac from './pages/Viewnlphdfac';
import Addnlphdfac from './pages/Addnlphdfac';
import Addlphdfacbulkn from './pages/Addlphdfacbulkn';
import Viewnlphdfacadmin from './pages/Viewnlphdfacadmin';

import Viewlphdfacprog from './pages/Viewlphdfacprog';

import Editnalumniusers from './pages/Editnalumniusers';
import Viewnalumniusers from './pages/Viewnalumniusers';
import Addnalumniusers from './pages/Addnalumniusers';
import Addalumniusersbulkn from './pages/Addalumniusersbulkn';
import Viewnalumniusersadmin from './pages/Viewnalumniusersadmin';

import Viewalumniusersprog from './pages/Viewalumniusersprog';

import Editnalumniwork from './pages/Editnalumniwork';
import Viewnalumniwork from './pages/Viewnalumniwork';
import Addnalumniwork from './pages/Addnalumniwork';
import Addalumniworkbulkn from './pages/Addalumniworkbulkn';
import Viewnalumniworkadmin from './pages/Viewnalumniworkadmin';

import Viewalumniworkprog from './pages/Viewalumniworkprog';

import Editnalumnihighered from './pages/Editnalumnihighered';
import Viewnalumnihighered from './pages/Viewnalumnihighered';
import Addnalumnihighered from './pages/Addnalumnihighered';
import Addalumnihigheredbulkn from './pages/Addalumnihigheredbulkn';
import Viewnalumnihigheredadmin from './pages/Viewnalumnihigheredadmin';

import Viewalumnihigheredprog from './pages/Viewalumnihigheredprog';

import Editnalumnicontribute from './pages/Editnalumnicontribute';
import Viewnalumnicontribute from './pages/Viewnalumnicontribute';
import Addnalumnicontribute from './pages/Addnalumnicontribute';
import Addalumnicontributebulkn from './pages/Addalumnicontributebulkn';
import Viewnalumnicontributeadmin from './pages/Viewnalumnicontributeadmin';

import Viewalumnicontributeprog from './pages/Viewalumnicontributeprog';

// import Editnalumnijobs from './pages/Editnalumnijobs';
// import Viewnalumnijobs from './pages/Viewnalumnijobs';
// import Addnalumnijobs from './pages/Addnalumnijobs';
// import Addalumnijobsbulkn from './pages/Addalumnijobsbulkn';
// import Viewnalumnijobsadmin from './pages/Viewnalumnijobsadmin';

// import Viewalumnijobsprog from './pages/Viewalumnijobsprog';

import Editnalumnievents from './pages/Editnalumnievents';
import Viewnalumnievents from './pages/Viewnalumnievents';
import Addnalumnievents from './pages/Addnalumnievents';
import Addalumnieventsbulkn from './pages/Addalumnieventsbulkn';
import Viewnalumnieventsadmin from './pages/Viewnalumnieventsadmin';

import Viewalumnieventsprog from './pages/Viewalumnieventsprog';

import Newclassesf41 from './pages/Newclassesf41';
import Dashalumni1 from './pages/Dashalumni1';

import Selectawsconfigalumni from './pages/Selectawsconfigalumni';
import Addphotoalumni from './pages/Addphotoalumni';
import Alumniregister from './pages/Alumniregister';

import Editnpfiles from './pages/Editnpfiles';
import Viewnpfiles from './pages/Viewnpfiles';
import Addnpfiles from './pages/Addnpfiles';
import Addpfilesbulkn from './pages/Addpfilesbulkn';
import Viewnpfilesadmin from './pages/Viewnpfilesadmin';

import Viewpfilesprog from './pages/Viewpfilesprog';

import Editnpdeliverables from './pages/Editnpdeliverables';
import Viewnpdeliverables from './pages/Viewnpdeliverables';
import Addnpdeliverables from './pages/Addnpdeliverables';
import Addpdeliverablesbulkn from './pages/Addpdeliverablesbulkn';
import Viewnpdeliverablesadmin from './pages/Viewnpdeliverablesadmin';

import Viewpdeliverablesprog from './pages/Viewpdeliverablesprog';


import Editncnewfeedback from './pages/Editncnewfeedback';
import Viewncnewfeedback from './pages/Viewncnewfeedback';
import Viewcnewfeedackusers from './pages/Viewcnewfeedackusers';
import Addncnewfeedback from './pages/Addncnewfeedback';
import Addcnewfeedbackbulkn from './pages/Addcnewfeedbackbulkn';
import Viewncnewfeedbackadmin from './pages/Viewncnewfeedbackadmin';

import Viewcnewfeedbackprog from './pages/Viewcnewfeedbackprog';

import Ocrtest35 from './pages/Ocrtest35';
import Ocrtest35remote from './pages/Ocrtest35remote';

import Viewnticketsallclosed from './pages/Viewnticketsallclosed';
import Viewnticketsallstatus from './pages/Viewnticketsallstatus';

import Editnsssstud from './pages/Editnsssstud';
import Viewnsssstud from './pages/Viewnsssstud';
import Addnsssstud from './pages/Addnsssstud';
import Addsssstudbulkn from './pages/Addsssstudbulkn';
import Viewnsssstudadmin from './pages/Viewnsssstudadmin';

import Viewsssstudprog from './pages/Viewsssstudprog';
import Viewnmodulesstud from './pages/Viewnmodulesstud';

import Viewnninvoicebill from './pages/Viewnninvoicebill';
import Viewnninvoicereceipt from './pages/Viewnninvoicereceipt';

import Editnassignment from './pages/Editnassignment';
import Viewnassignment from './pages/Viewnassignment';
import Addnassignment from './pages/Addnassignment';
import Addassignmentbulkn from './pages/Addassignmentbulkn';
import Viewnassignmentadmin from './pages/Viewnassignmentadmin';

import Viewassignmentprog from './pages/Viewassignmentprog';

import Editnaffassign from './pages/Editnaffassign';
import Viewnaffassign from './pages/Viewnaffassign';
import Addnaffassign from './pages/Addnaffassign';
import Addaffassignbulkn from './pages/Addaffassignbulkn';
import Viewnaffassignadmin from './pages/Viewnaffassignadmin';

import Viewaffassignprog from './pages/Viewaffassignprog';

import Editnmkustud from './pages/Editnmkustud';
import Viewnmkustud from './pages/Viewnmkustud';
import Addnmkustud from './pages/Addnmkustud';
import Addmkustudbulkn from './pages/Addmkustudbulkn';
import Viewnmkustudadmin from './pages/Viewnmkustudadmin';

import Viewmkustudprog from './pages/Viewmkustudprog';

import Editnmkumarks from './pages/Editnmkumarks';
import Viewnmkumarks from './pages/Viewnmkumarks';
import Addnmkumarks from './pages/Addnmkumarks';
import Addmkumarksbulkn from './pages/Addmkumarksbulkn';
import Viewnmkumarksadmin from './pages/Viewnmkumarksadmin';

import Viewmkumarksprog from './pages/Viewmkumarksprog';

import Viewnmkumarksheet from './pages/Viewnmkumarksheet';

import Editnamastudnew from './pages/Editnamastudnew';
import Viewnamastudnew from './pages/Viewnamastudnew';
import Addnamastudnew from './pages/Addnamastudnew';
import Addamastudnewbulkn from './pages/Addamastudnewbulkn';
import Viewnamastudnewadmin from './pages/Viewnamastudnewadmin';

import Viewamastudnewprog from './pages/Viewamastudnewprog';

import Editnaffcolprog from './pages/Editnaffcolprog';
import Viewnaffcolprog from './pages/Viewnaffcolprog';
import Addnaffcolprog from './pages/Addnaffcolprog';
import Addaffcolprogbulkn from './pages/Addaffcolprogbulkn';
import Viewnaffcolprogadmin from './pages/Viewnaffcolprogadmin';

import Viewaffcolprogprog from './pages/Viewaffcolprogprog';

import Editnaffproglist from './pages/Editnaffproglist';
import Viewnaffproglist from './pages/Viewnaffproglist';
import Addnaffproglist from './pages/Addnaffproglist';
import Addaffproglistbulkn from './pages/Addaffproglistbulkn';
import Viewnaffproglistadmin from './pages/Viewnaffproglistadmin';

import Viewaffproglistprog from './pages/Viewaffproglistprog';

import EditnInstitutions from './pages/EditnInstitutions';
import ViewnInstitutions from './pages/ViewnInstitutions';
import AddnInstitutions from './pages/AddnInstitutions';
import AddInstitutionsbulkn from './pages/AddInstitutionsbulkn';
import ViewnInstitutionsadmin from './pages/ViewnInstitutionsadmin';

import ViewInstitutionsprog from './pages/ViewInstitutionsprog';


import Editnrmsucoldeta from './pages/Editnrmsucoldeta';
import Viewnrmsucoldeta from './pages/Viewnrmsucoldeta';
import Addnrmsucoldeta from './pages/Addnrmsucoldeta';
import Addrmsucoldetabulkn from './pages/Addrmsucoldetabulkn';
import Viewnrmsucoldetaadmin from './pages/Viewnrmsucoldetaadmin';

import Viewrmsucoldetaprog from './pages/Viewrmsucoldetaprog';

import Editnrmsunirf from './pages/Editnrmsunirf';
import Viewnrmsunirf from './pages/Viewnrmsunirf';
import Addnrmsunirf from './pages/Addnrmsunirf';
import Addrmsunirfbulkn from './pages/Addrmsunirfbulkn';
import Viewnrmsunirfadmin from './pages/Viewnrmsunirfadmin';

import Viewrmsunirfprog from './pages/Viewrmsunirfprog';

import Editnrmsutrustdata from './pages/Editnrmsutrustdata';
import Viewnrmsutrustdata from './pages/Viewnrmsutrustdata';
import Addnrmsutrustdata from './pages/Addnrmsutrustdata';
import Addrmsutrustdatabulkn from './pages/Addrmsutrustdatabulkn';
import Viewnrmsutrustdataadmin from './pages/Viewnrmsutrustdataadmin';

import Viewrmsutrustdataprog from './pages/Viewrmsutrustdataprog';

import Editnuser from './pages/Editnuser';
import Viewnuser from './pages/Viewnuser';
import Addnuser from './pages/Addnuser';
import Adduserbulkn from './pages/Adduserbulkn';
import Viewnuseradmin from './pages/Viewnuseradmin';

import Viewuserprog from './pages/Viewuserprog';

import Viewnamastudc from './pages/Viewnamastudc';

import Viewnamastudshort from './pages/Viewnamastudshort';

import Selectaccreditation from './pages/Selectaccreditation';
import Viewastudcont from './pages/Viewastudcont';

import Addinstitutionnew from './pages/Addinstitutionnew';
import Addinstitutionself from './pages/Addinstitutionself';
import Updatephone from './pages/Updatephone';

import Editnmsusancseatn from './pages/Editnmsusancseatn';
import Viewnmsusancseatn from './pages/Viewnmsusancseatn';
import Addnmsusancseatn from './pages/Addnmsusancseatn';
import Addmsusancseatnbulkn from './pages/Addmsusancseatnbulkn';
import Viewnmsusancseatnadmin from './pages/Viewnmsusancseatnadmin';

import Viewmsusancseatnprog from './pages/Viewmsusancseatnprog';


import Editnawsconfig from './pages/Editnawsconfig';
import Viewnawsconfig from './pages/Viewnawsconfig';
import Addnawsconfig from './pages/Addnawsconfig';
import Addawsconfigbulkn from './pages/Addawsconfigbulkn';
import Viewnawsconfigadmin from './pages/Viewnawsconfigadmin';

import Viewawsconfigprog from './pages/Viewawsconfigprog';

import Dashadm1 from './pages/Dashadm1';
import Selectawsconfigadm1 from './pages/Selectawsconfigadm1';
import Addphotoadm from './pages/Addphotoadm';

import Newclassesf42 from './pages/Newclassesf42';

import Editnadmusers from './pages/Editnadmusers';
import Viewnadmusers from './pages/Viewnadmusers';
import Addnadmusers from './pages/Addnadmusers';
import Addadmusersbulkn from './pages/Addadmusersbulkn';
import Viewnadmusersadmin from './pages/Viewnadmusersadmin';

import Viewadmusersprog from './pages/Viewadmusersprog';

import Editnadmedu from './pages/Editnadmedu';
import Viewnadmedu from './pages/Viewnadmedu';
import Addnadmedu from './pages/Addnadmedu';
import Addadmedubulkn from './pages/Addadmedubulkn';
import Viewnadmeduadmin from './pages/Viewnadmeduadmin';

import Viewadmeduprog from './pages/Viewadmeduprog';

import Editnadmwork from './pages/Editnadmwork';
import Viewnadmwork from './pages/Viewnadmwork';
import Addnadmwork from './pages/Addnadmwork';
import Addadmworkbulkn from './pages/Addadmworkbulkn';
import Viewnadmworkadmin from './pages/Viewnadmworkadmin';

import Viewadmworkprog from './pages/Viewadmworkprog';

import Editnadmprograms from './pages/Editnadmprograms';
import Viewnadmprograms from './pages/Viewnadmprograms';
import Addnadmprograms from './pages/Addnadmprograms';
import Addadmprogramsbulkn from './pages/Addadmprogramsbulkn';
import Viewnadmprogramsadmin from './pages/Viewnadmprogramsadmin';

import Viewadmprogramsprog from './pages/Viewadmprogramsprog';

import Editnadmintern from './pages/Editnadmintern';
import Viewnadmintern from './pages/Viewnadmintern';
import Addnadmintern from './pages/Addnadmintern';
import Addadminternbulkn from './pages/Addadminternbulkn';
import Viewnadminternadmin from './pages/Viewnadminternadmin';

import Viewadminternprog from './pages/Viewadminternprog';

import Editnadmextra from './pages/Editnadmextra';
import Viewnadmextra from './pages/Viewnadmextra';
import Addnadmextra from './pages/Addnadmextra';
import Addadmextrabulkn from './pages/Addadmextrabulkn';
import Viewnadmextraadmin from './pages/Viewnadmextraadmin';

import Viewadmextraprog from './pages/Viewadmextraprog';

import Editnadmlanguages from './pages/Editnadmlanguages';
import Viewnadmlanguages from './pages/Viewnadmlanguages';
import Addnadmlanguages from './pages/Addnadmlanguages';
import Addadmlanguagesbulkn from './pages/Addadmlanguagesbulkn';
import Viewnadmlanguagesadmin from './pages/Viewnadmlanguagesadmin';

import Viewadmlanguagesprog from './pages/Viewadmlanguagesprog';

import Editnadmmarks from './pages/Editnadmmarks';
import Viewnadmmarks from './pages/Viewnadmmarks';
import Addnadmmarks from './pages/Addnadmmarks';
import Addadmmarksbulkn from './pages/Addadmmarksbulkn';
import Viewnadmmarksadmin from './pages/Viewnadmmarksadmin';

import Viewadmmarksprog from './pages/Viewadmmarksprog';

import Editnadmfacilities from './pages/Editnadmfacilities';
import Viewnadmfacilities from './pages/Viewnadmfacilities';
import Addnadmfacilities from './pages/Addnadmfacilities';
import Addadmfacilitiesbulkn from './pages/Addadmfacilitiesbulkn';
import Viewnadmfacilitiesadmin from './pages/Viewnadmfacilitiesadmin';

import Viewadmfacilitiesprog from './pages/Viewadmfacilitiesprog';


import Editnbook from './pages/Editnbook';
import Viewnbook from './pages/Viewnbook';
import Addnbook from './pages/Addnbook';
import Addbookbulkn from './pages/Addbookbulkn';
import Viewnbookadmin from './pages/Viewnbookadmin';

import Viewbookprog from './pages/Viewbookprog';
import Viewnbooksadminlinks from './pages/Viewnbooksadminlinks';

import Selectawsconfigmob from './pages/Selectawsconfigmob';
import Addsupportingdoc2mob from './pages/Addsupportingdoc2mob';

import Selectbrowser from './pages/Selectbrowser';
import Newclassesf43 from './pages/Newclassesf43';
import Dashfacmob from './pages/Dashfacmob';

import Editnbookmob from './pages/Editnbookmob';
import Viewnbookmob from './pages/Viewnbookmob';
import Addnbookmob from './pages/Addnbookmob';
import Viewnbookadminmob from './pages/Viewnbookadminmob';

import Viewnnuefacilitieslinks from './pages/Viewnnuefacilitieslinks';
import Viewnustudschsplinks from './pages/Viewnustudschsplinks';
import Viewnhigheredulinks from './pages/Viewnhigheredulinks';

import Editnfeedback from './pages/Editnfeedback';
import Viewnfeedback from './pages/Viewnfeedback';
import Addnfeedback from './pages/Addnfeedback';
import Addfeedbackbulkn from './pages/Addfeedbackbulkn';
import Viewnfeedbackadmin from './pages/Viewnfeedbackadmin';

import Viewfeedbackprog from './pages/Viewfeedbackprog';

import Viewnhigherexamlinks from './pages/Viewnhigherexamlinks';


import Editnseminarmob from './pages/Editnseminarmob';
import Viewnseminarmob from './pages/Viewnseminarmob';
import Addnseminarmob from './pages/Addnseminarmob';
import Viewnseminaradminmob from './pages/Viewnseminaradminmob';

import Dashadminmob from './pages/Dashadminmob';
import Selectbrowseradmin from './pages/Selectbrowseradmin';

import Viewsupportingdocquerymob from './pages/Viewsupportingdocquerymob';

import Ocrtest35mob from './pages/Ocrtest35mob';
import Selectawsconfignewmob from './pages/Selectawsconfignewmob';

import Editncocal from './pages/Editncocal';
import Viewncocal from './pages/Viewncocal';
import Addncocal from './pages/Addncocal';
import Addcocalbulkn from './pages/Addcocalbulkn';
import Viewncocaladmin from './pages/Viewncocaladmin';

import Viewcocalprog from './pages/Viewcocalprog';

import Editnprojectsmob from './pages/Editnprojectsmob';
import Viewnprojectsmob from './pages/Viewnprojectsmob';
import Addnprojectsmob from './pages/Addnprojectsmob';
import Viewnprojectsadminmob from './pages/Viewnprojectsadminmob';

import Editnpublicationsmob from './pages/Editnpublicationsmob';
import Viewnpublicationsmob from './pages/Viewnpublicationsmob';
import Addnpublicationsmob from './pages/Addnpublicationsmob';
import Viewnpublicationsadminmob from './pages/Viewnpublicationsadminmob';

import Editnpatentsmob from './pages/Editnpatentsmob';
import Viewnpatentsmob from './pages/Viewnpatentsmob';
import Addnpatentsmob from './pages/Addnpatentsmob';
import Viewnpatentsadminmob from './pages/Viewnpatentsadminmob';

import Editnconsultancymob from './pages/Editnconsultancymob';
import Viewnconsultancymob from './pages/Viewnconsultancymob';
import Addnconsultancymob from './pages/Addnconsultancymob';
import Viewnconsultancyadminmob from './pages/Viewnconsultancyadminmob';

import Editnphdguidemob from './pages/Editnphdguidemob';
import Viewnphdguidemob from './pages/Viewnphdguidemob';
import Addnphdguidemob from './pages/Addnphdguidemob';
import Viewnphdguideadminmob from './pages/Viewnphdguideadminmob';

import Editnteacherfellowmob from './pages/Editnteacherfellowmob';
import Viewnteacherfellowmob from './pages/Viewnteacherfellowmob';
import Addnteacherfellowmob from './pages/Addnteacherfellowmob';
import Viewnteacherfellowadminmob from './pages/Viewnteacherfellowadminmob';

import Thankyouaftermob from './pages/Thankyouaftermob';

import Editnpayimages from './pages/Editnpayimages';
import Viewnpayimages from './pages/Viewnpayimages';
import Addnpayimages from './pages/Addnpayimages';
import Addpayimagesbulkn from './pages/Addpayimagesbulkn';
import Viewnpayimagesadmin from './pages/Viewnpayimagesadmin';

import Viewpayimagesprog from './pages/Viewpayimagesprog';

import Editnalumnipay from './pages/Editnalumnipay';
import Viewnalumnipay from './pages/Viewnalumnipay';
import Addnalumnipay from './pages/Addnalumnipay';
import Addalumnipaybulkn from './pages/Addalumnipaybulkn';
import Viewnalumnipayadmin from './pages/Viewnalumnipayadmin';

import Viewalumnipayprog from './pages/Viewalumnipayprog';

import Editnevguests from './pages/Editnevguests';
import Viewnevguests from './pages/Viewnevguests';
import Addnevguests from './pages/Addnevguests';
import Addevguestsbulkn from './pages/Addevguestsbulkn';
import Viewnevguestsadmin from './pages/Viewnevguestsadmin';

import Viewevguestsprog from './pages/Viewevguestsprog';

import Viewnamastudnewbyuniv from './pages/Viewnamastudnewbyuniv';

import Viewnamaduplicate from './pages/Viewnamaduplicate';

import Viewnamastuddiff from './pages/Viewnamastuddiff';

import Assamannualreport from './pages/Assamannualreport';

import Editngenderaudit from './pages/Editngenderaudit';
import Viewngenderaudit from './pages/Viewngenderaudit';
import Addngenderaudit from './pages/Addngenderaudit';
import Addgenderauditbulkn from './pages/Addgenderauditbulkn';
import Viewngenderauditadmin from './pages/Viewngenderauditadmin';

import Viewgenderauditprog from './pages/Viewgenderauditprog';

import Assamannualreport1 from './pages/Assamannualreport1';

import Assetlistadmin from './pages/Assetlistadmin';
import Selectmetricdoctype from './pages/Selectmetricdoctype';
import Selectawsconfigf from './pages/Selectawsconfigf';
import Addsupportingdoc2f from './pages/Addsupportingdoc2f';
import Alllinksf from './pages/Alllinksf';
import Alllinksfbymetric from './pages/Alllinksfbymetric';
import Selectmetricdoctypea from './pages/Selectmetricdoctypea';
import Addassetbulk from './pages/Addassetbulk';
import Assetedit from './pages/Assetedit';
import Addsupportingdoc2f1 from './pages/Addsupportingdoc2f1';

import Createfile from './pages/Createfile';

import Addsupportingdoc21 from './pages/Addsupportingdoc21';

import Bdadm from './pages/Bdadm';
import Beadm from './pages/Beadm';

import Editnsalarypaid from './pages/Editnsalarypaid';
import Viewnsalarypaid from './pages/Viewnsalarypaid';
import Addnsalarypaid from './pages/Addnsalarypaid';
import Addsalarypaidbulkn from './pages/Addsalarypaidbulkn';
import Viewnsalarypaidadmin from './pages/Viewnsalarypaidadmin';

import Viewsalarypaidprog from './pages/Viewsalarypaidprog';

import Bensalarypaid from './pages/Bensalarypaid';

import Bdelsalarypaid from './pages/Bdelsalarypaid';

import Editnemptravel from './pages/Editnemptravel';
import Viewnemptravel from './pages/Viewnemptravel';
import Addnemptravel from './pages/Addnemptravel';
import Addemptravelbulkn from './pages/Addemptravelbulkn';
import Viewnemptraveladmin from './pages/Viewnemptraveladmin';

import Viewemptravelprog from './pages/Viewemptravelprog';

import Benemptravel from './pages/Benemptravel';

import Bdelemptravel from './pages/Bdelemptravel';

import Viewnphdfacelinks from './pages/Viewnphdfacelinks';

import Baddsalarypaid from './pages/Baddsalarypaid';
import Baddemptravel from './pages/Baddemptravel';

import Editnprojects from './pages/Editnprojects';
import Viewnprojects from './pages/Viewnprojects';
import Addnprojects from './pages/Addnprojects';
import Addprojectsbulkn from './pages/Addprojectsbulkn';
import Viewnprojectsadmin from './pages/Viewnprojectsadmin';

import Viewprojectsprog from './pages/Viewprojectsprog';

import Benprojects from './pages/Benprojects';

import Bdelprojects from './pages/Bdelprojects';

import Baddprojects from './pages/Baddprojects';


import Editnpublications from './pages/Editnpublications';
import Viewnpublications from './pages/Viewnpublications';
import Addnpublications from './pages/Addnpublications';
import Addpublicationsbulkn from './pages/Addpublicationsbulkn';
import Viewnpublicationsadmin from './pages/Viewnpublicationsadmin';

import Viewpublicationsprog from './pages/Viewpublicationsprog';

import Benpublications from './pages/Benpublications';

import Bdelpublications from './pages/Bdelpublications';

import Baddpublications from './pages/Baddpublications';

import Editnseminar from './pages/Editnseminar';
import Viewnseminar from './pages/Viewnseminar';
import Addnseminar from './pages/Addnseminar';
import Addseminarbulkn from './pages/Addseminarbulkn';
import Viewnseminaradmin from './pages/Viewnseminaradmin';

import Viewseminarprog from './pages/Viewseminarprog';

import Benseminar from './pages/Benseminar';

import Bdelseminar from './pages/Bdelseminar';

import Baddseminar from './pages/Baddseminar';


import Benbook from './pages/Benbook';

import Bdelbook from './pages/Bdelbook';

import Baddbook from './pages/Baddbook';
import Benpatents from './pages/Benpatents';

import Bdelpatents from './pages/Bdelpatents';

import Baddpatents from './pages/Baddpatents';
import Benconsultancy from './pages/Benconsultancy';

import Bdelconsultancy from './pages/Bdelconsultancy';

import Baddconsultancy from './pages/Baddconsultancy';
import Editncertificates from './pages/Editncertificates';
import Viewncertificates from './pages/Viewncertificates';
import Addncertificates from './pages/Addncertificates';
import Addcertificatesbulkn from './pages/Addcertificatesbulkn';
import Viewncertificatesadmin from './pages/Viewncertificatesadmin';

import Viewcertificatesprog from './pages/Viewcertificatesprog';

import Bencertificates from './pages/Bencertificates';

import Bdelcertificates from './pages/Bdelcertificates';

import Baddcertificates from './pages/Baddcertificates';

import Editnemploymentall from './pages/Editnemploymentall';
import Viewnemploymentall from './pages/Viewnemploymentall';
import Addnemploymentall from './pages/Addnemploymentall';
import Addemploymentallbulkn from './pages/Addemploymentallbulkn';
import Viewnemploymentalladmin from './pages/Viewnemploymentalladmin';

import Viewemploymentallprog from './pages/Viewemploymentallprog';

import Benemploymentall from './pages/Benemploymentall';

import Bdelemploymentall from './pages/Bdelemploymentall';

import Baddemploymentall from './pages/Baddemploymentall';

import Viewnprojectsadmin1 from './pages/Viewnprojectsadmin1';
import Viewnprojectsadminlinks from './pages/Viewnprojectsadminlinks';

import Viewnpublicationsadmin1 from './pages/Viewnpublicationsadmin1';
import Viewnpublicationsadminlinks from './pages/Viewnpublicationsadminlinks';
import Viewnseminaradmin1 from './pages/Viewnseminaradmin1';
import Viewnseminaradminlinks from './pages/Viewnseminaradminlinks';
import Viewnbookadmin1 from './pages/Viewnbookadmin1';
import Viewnbookadminlinks from './pages/Viewnbookadminlinks';
import Viewnpatentsadmin1 from './pages/Viewnpatentsadmin1';
import Viewnpatentsadminlinks from './pages/Viewnpatentsadminlinks';
import Viewnconsultancyadmin1 from './pages/Viewnconsultancyadmin1';
import Viewnconsultancyadminlinks from './pages/Viewnconsultancyadminlinks';
import Viewncertificatesadmin1 from './pages/Viewncertificatesadmin1';
import Viewncertificatesadminlinks from './pages/Viewncertificatesadminlinks';
import Viewnemploymentalladmin1 from './pages/Viewnemploymentalladmin1';
import Viewnemploymentalladminlinks from './pages/Viewnemploymentalladminlinks';

import Viewnnallcoursesadmin1 from './pages/Viewnnallcoursesadmin1';
import Viewnnallcoursesadminlinks from './pages/Viewnnallcoursesadminlinks';
import Bennallcourses from './pages/Bennallcourses';
import Bdelnallcourses from './pages/Bdelnallcourses';
import Baddnallcourses from './pages/Baddnallcourses';
import Viewnaddoncadmin1 from './pages/Viewnaddoncadmin1';
import Viewnaddoncadminlinks from './pages/Viewnaddoncadminlinks';
import Benaddonc from './pages/Benaddonc';
import Bdeladdonc from './pages/Bdeladdonc';
import Baddaddonc from './pages/Baddaddonc';
import Editnaddoncstud from './pages/Editnaddoncstud';
import Viewnaddoncstud from './pages/Viewnaddoncstud';
import Addnaddoncstud from './pages/Addnaddoncstud';
import Viewnaddoncstudadmin1 from './pages/Viewnaddoncstudadmin1';
import Viewnaddoncstudadminlinks from './pages/Viewnaddoncstudadminlinks';
import Benaddoncstud from './pages/Benaddoncstud';
import Bdeladdoncstud from './pages/Bdeladdoncstud';
import Baddaddoncstud from './pages/Baddaddoncstud';
import Viewnnuexplearningadmin1 from './pages/Viewnnuexplearningadmin1';
import Viewnnuexplearningadminlinks from './pages/Viewnnuexplearningadminlinks';
import Bennuexplearning from './pages/Bennuexplearning';
import Bdelnuexplearning from './pages/Bdelnuexplearning';
import Baddnuexplearning from './pages/Baddnuexplearning';

import Viewnewunivmenu from './pages/Viewnewunivmenu';
import Viewnewunivmenufac from './pages/Viewnewunivmenufac';

import Editnnallcourses from './pages/Editnnallcourses';
import Viewnnallcourses from './pages/Viewnnallcourses';
import Addnnallcourses from './pages/Addnnallcourses';
import Viewai from './pages/Viewai';

import Editnaddonc from './pages/Editnaddonc';
import Viewnaddonc from './pages/Viewnaddonc';
import Addnaddonc from './pages/Addnaddonc';



import Editndoc121ac from './pages/Editndoc121ac';
import Viewndoc121ac from './pages/Viewndoc121ac';
import Addndoc121ac from './pages/Addndoc121ac';
import Viewndoc121acadmin1 from './pages/Viewndoc121acadmin1';

import Viewndoc121acadminlinks from './pages/Viewndoc121acadminlinks';

import Bendoc121ac from './pages/Bendoc121ac';

import Bdeldoc121ac from './pages/Bdeldoc121ac';

import Badddoc121ac from './pages/Badddoc121ac';

import Editndoc121bos from './pages/Editndoc121bos';
import Viewndoc121bos from './pages/Viewndoc121bos';
import Addndoc121bos from './pages/Addndoc121bos';
import Viewndoc121bosadmin1 from './pages/Viewndoc121bosadmin1';

import Viewndoc121bosadminlinks from './pages/Viewndoc121bosadminlinks';

import Bendoc121bos from './pages/Bendoc121bos';

import Bdeldoc121bos from './pages/Bdeldoc121bos';

import Badddoc121bos from './pages/Badddoc121bos';

import Editndoc132att from './pages/Editndoc132att';
import Viewndoc132att from './pages/Viewndoc132att';
import Addndoc132att from './pages/Addndoc132att';
import Viewndoc132attadmin1 from './pages/Viewndoc132attadmin1';

import Viewndoc132attadminlinks from './pages/Viewndoc132attadminlinks';

import Bendoc132att from './pages/Bendoc132att';

import Bdeldoc132att from './pages/Bdeldoc132att';

import Badddoc132att from './pages/Badddoc132att';

import Editndoc132brochure from './pages/Editndoc132brochure';
import Viewndoc132brochure from './pages/Viewndoc132brochure';
import Addndoc132brochure from './pages/Addndoc132brochure';
import Viewndoc132brochureadmin1 from './pages/Viewndoc132brochureadmin1';

import Viewndoc132brochureadminlinks from './pages/Viewndoc132brochureadminlinks';

import Bendoc132brochure from './pages/Bendoc132brochure';

import Bdeldoc132brochure from './pages/Bdeldoc132brochure';

import Badddoc132brochure from './pages/Badddoc132brochure';

import Editndoc133intern from './pages/Editndoc133intern';
import Viewndoc133intern from './pages/Viewndoc133intern';
import Addndoc133intern from './pages/Addndoc133intern';
import Viewndoc133internadmin1 from './pages/Viewndoc133internadmin1';

import Viewndoc133internadminlinks from './pages/Viewndoc133internadminlinks';

import Bendoc133intern from './pages/Bendoc133intern';

import Bdeldoc133intern from './pages/Bdeldoc133intern';

import Badddoc133intern from './pages/Badddoc133intern';

import Editndoc133bos from './pages/Editndoc133bos';
import Viewndoc133bos from './pages/Viewndoc133bos';
import Addndoc133bos from './pages/Addndoc133bos';
import Viewndoc133bosadmin1 from './pages/Viewndoc133bosadmin1';

import Viewndoc133bosadminlinks from './pages/Viewndoc133bosadminlinks';

import Bendoc133bos from './pages/Bendoc133bos';

import Bdeldoc133bos from './pages/Bdeldoc133bos';

import Badddoc133bos from './pages/Badddoc133bos';

import Editndoc141fsubmit from './pages/Editndoc141fsubmit';
import Viewndoc141fsubmit from './pages/Viewndoc141fsubmit';
import Addndoc141fsubmit from './pages/Addndoc141fsubmit';
import Viewndoc141fsubmitadmin1 from './pages/Viewndoc141fsubmitadmin1';

import Viewndoc141fsubmitadminlinks from './pages/Viewndoc141fsubmitadminlinks';

import Bendoc141fsubmit from './pages/Bendoc141fsubmit';

import Bdeldoc141fsubmit from './pages/Bdeldoc141fsubmit';

import Badddoc141fsubmit from './pages/Badddoc141fsubmit';

import Editndoc141sample from './pages/Editndoc141sample';
import Viewndoc141sample from './pages/Viewndoc141sample';
import Addndoc141sample from './pages/Addndoc141sample';
import Viewndoc141sampleadmin1 from './pages/Viewndoc141sampleadmin1';

import Viewndoc141sampleadminlinks from './pages/Viewndoc141sampleadminlinks';

import Bendoc141sample from './pages/Bendoc141sample';

import Bdeldoc141sample from './pages/Bdeldoc141sample';

import Badddoc141sample from './pages/Badddoc141sample';

import Editndoc141action from './pages/Editndoc141action';
import Viewndoc141action from './pages/Viewndoc141action';
import Addndoc141action from './pages/Addndoc141action';
import Viewndoc141actionadmin1 from './pages/Viewndoc141actionadmin1';

import Viewndoc141actionadminlinks from './pages/Viewndoc141actionadminlinks';

import Bendoc141action from './pages/Bendoc141action';

import Bdeldoc141action from './pages/Bdeldoc141action';

import Badddoc141action from './pages/Badddoc141action';

import Editndod211intake from './pages/Editndod211intake';
import Viewndod211intake from './pages/Viewndod211intake';
import Addndod211intake from './pages/Addndod211intake';
import Viewndod211intakeadmin1 from './pages/Viewndod211intakeadmin1';

import Viewndod211intakeadminlinks from './pages/Viewndod211intakeadminlinks';

import Bendod211intake from './pages/Bendod211intake';

import Bdeldod211intake from './pages/Bdeldod211intake';

import Badddod211intake from './pages/Badddod211intake';

import Editndoc211extract from './pages/Editndoc211extract';
import Viewndoc211extract from './pages/Viewndoc211extract';
import Addndoc211extract from './pages/Addndoc211extract';
import Viewndoc211extractadmin1 from './pages/Viewndoc211extractadmin1';

import Viewndoc211extractadminlinks from './pages/Viewndoc211extractadminlinks';

import Bendoc211extract from './pages/Bendoc211extract';

import Bdeldoc211extract from './pages/Bdeldoc211extract';

import Badddoc211extract from './pages/Badddoc211extract';

import Editndoc212final from './pages/Editndoc212final';
import Viewndoc212final from './pages/Viewndoc212final';
import Addndoc212final from './pages/Addndoc212final';
import Viewndoc212finaladmin1 from './pages/Viewndoc212finaladmin1';

import Viewndoc212finaladminlinks from './pages/Viewndoc212finaladminlinks';

import Bendoc212final from './pages/Bendoc212final';

import Bdeldoc212final from './pages/Bdeldoc212final';

import Badddoc212final from './pages/Badddoc212final';

import Editndoc132cert from './pages/Editndoc132cert';
import Viewndoc132cert from './pages/Viewndoc132cert';
import Addndoc132cert from './pages/Addndoc132cert';
import Viewndoc132certadmin1 from './pages/Viewndoc132certadmin1';

import Viewndoc132certadminlinks from './pages/Viewndoc132certadminlinks';

import Bendoc132cert from './pages/Bendoc132cert';

import Bdeldoc132cert from './pages/Bdeldoc132cert';

import Badddoc132cert from './pages/Badddoc132cert';

import Editndod222stud from './pages/Editndod222stud';
import Viewndod222stud from './pages/Viewndod222stud';
import Addndod222stud from './pages/Addndod222stud';
import Viewndod222studadmin1 from './pages/Viewndod222studadmin1';

import Viewndod222studadminlinks from './pages/Viewndod222studadminlinks';

import Bendod222stud from './pages/Bendod222stud';

import Bdeldod222stud from './pages/Bdeldod222stud';

import Badddod222stud from './pages/Badddod222stud';

import Editndoc222teachers from './pages/Editndoc222teachers';
import Viewndoc222teachers from './pages/Viewndoc222teachers';
import Addndoc222teachers from './pages/Addndoc222teachers';
import Viewndoc222teachersadmin1 from './pages/Viewndoc222teachersadmin1';

import Viewndoc222teachersadminlinks from './pages/Viewndoc222teachersadminlinks';

import Bendoc222teachers from './pages/Bendoc222teachers';

import Bdeldoc222teachers from './pages/Bdeldoc222teachers';

import Badddoc222teachers from './pages/Badddoc222teachers';

import Editndoc241sanction from './pages/Editndoc241sanction';
import Viewndoc241sanction from './pages/Viewndoc241sanction';
import Addndoc241sanction from './pages/Addndoc241sanction';
import Viewndoc241sanctionadmin1 from './pages/Viewndoc241sanctionadmin1';

import Viewndoc241sanctionadminlinks from './pages/Viewndoc241sanctionadminlinks';

import Bendoc241sanction from './pages/Bendoc241sanction';

import Bdeldoc241sanction from './pages/Bdeldoc241sanction';

import Badddoc241sanction from './pages/Badddoc241sanction';

import Editndoc242faclist from './pages/Editndoc242faclist';
import Viewndoc242faclist from './pages/Viewndoc242faclist';
import Addndoc242faclist from './pages/Addndoc242faclist';
import Viewndoc242faclistadmin1 from './pages/Viewndoc242faclistadmin1';

import Viewndoc242faclistadminlinks from './pages/Viewndoc242faclistadminlinks';

import Bendoc242faclist from './pages/Bendoc242faclist';

import Bdeldoc242faclist from './pages/Bdeldoc242faclist';

import Badddoc242faclist from './pages/Badddoc242faclist';

import Editndoc252list from './pages/Editndoc252list';
import Viewndoc252list from './pages/Viewndoc252list';
import Addndoc252list from './pages/Addndoc252list';
import Viewndoc252listadmin1 from './pages/Viewndoc252listadmin1';

import Viewndoc252listadminlinks from './pages/Viewndoc252listadminlinks';

import Bendoc252list from './pages/Bendoc252list';

import Bdeldoc252list from './pages/Bdeldoc252list';

import Badddoc252list from './pages/Badddoc252list';

import Editndoc253screenshot from './pages/Editndoc253screenshot';
import Viewndoc253screenshot from './pages/Viewndoc253screenshot';
import Addndoc253screenshot from './pages/Addndoc253screenshot';
import Viewndoc253screenshotadmin1 from './pages/Viewndoc253screenshotadmin1';

import Viewndoc253screenshotadminlinks from './pages/Viewndoc253screenshotadminlinks';

import Bendoc253screenshot from './pages/Bendoc253screenshot';

import Bdeldoc253screenshot from './pages/Bdeldoc253screenshot';

import Badddoc253screenshot from './pages/Badddoc253screenshot';

import Editndoc253report from './pages/Editndoc253report';
import Viewndoc253report from './pages/Viewndoc253report';
import Addndoc253report from './pages/Addndoc253report';
import Viewndoc253reportadmin1 from './pages/Viewndoc253reportadmin1';

import Viewndoc253reportadminlinks from './pages/Viewndoc253reportadminlinks';

import Bendoc253report from './pages/Bendoc253report';

import Bdeldoc253report from './pages/Bdeldoc253report';

import Badddoc253report from './pages/Badddoc253report';

// import Editndoc261polist from './pages/Editndoc261polist';
// import Viewndoc261polist from './pages/Viewndoc261polist';
// import Addndoc261polist from './pages/Addndoc261polist';
// import Viewndoc261polistadmin1 from './pages/Viewndoc261polistadmin1';

// import Viewndoc261polistadminlinks from './pages/Viewndoc261polistadminlinks';

// import Bendoc261polist from './pages/Bendoc261polist';

// import Bdeldoc261polist from './pages/Bdeldoc261polist';

// import Badddoc261polist from './pages/Badddoc261polist';

import Editndoc212letter from './pages/Editndoc212letter';
import Viewndoc212letter from './pages/Viewndoc212letter';
import Addndoc212letter from './pages/Addndoc212letter';
import Viewndoc212letteradmin1 from './pages/Viewndoc212letteradmin1';

import Viewndoc212letteradminlinks from './pages/Viewndoc212letteradminlinks';

import Bendoc212letter from './pages/Bendoc212letter';

import Bdeldoc212letter from './pages/Bdeldoc212letter';

import Badddoc212letter from './pages/Badddoc212letter';

import Editndoc261colist from './pages/Editndoc261colist';
import Viewndoc261colist from './pages/Viewndoc261colist';
import Addndoc261colist from './pages/Addndoc261colist';
import Viewndoc261colistadmin1 from './pages/Viewndoc261colistadmin1';

import Viewndoc261colistadminlinks from './pages/Viewndoc261colistadminlinks';

import Bendoc261colist from './pages/Bendoc261colist';

import Bdeldoc261colist from './pages/Bdeldoc261colist';

import Badddoc261colist from './pages/Badddoc261colist';

import Editndoc262cert from './pages/Editndoc262cert';
import Viewndoc262cert from './pages/Viewndoc262cert';
import Addndoc262cert from './pages/Addndoc262cert';
import Viewndoc262certadmin1 from './pages/Viewndoc262certadmin1';

import Viewndoc262certadminlinks from './pages/Viewndoc262certadminlinks';

import Bendoc262cert from './pages/Bendoc262cert';

import Bdeldoc262cert from './pages/Bdeldoc262cert';

import Badddoc262cert from './pages/Badddoc262cert';

import Editndoc262annual from './pages/Editndoc262annual';
import Viewndoc262annual from './pages/Viewndoc262annual';
import Addndoc262annual from './pages/Addndoc262annual';
import Viewndoc262annualadmin1 from './pages/Viewndoc262annualadmin1';

import Viewndoc262annualadminlinks from './pages/Viewndoc262annualadminlinks';

import Bendoc262annual from './pages/Bendoc262annual';

import Bdeldoc262annual from './pages/Bdeldoc262annual';

import Badddoc262annual from './pages/Badddoc262annual';

import Editndoc311co from './pages/Editndoc311co';
import Viewndoc311co from './pages/Viewndoc311co';
import Addndoc311co from './pages/Addndoc311co';
import Viewndoc311coadmin1 from './pages/Viewndoc311coadmin1';

import Viewndoc311coadminlinks from './pages/Viewndoc311coadminlinks';

import Bendoc311co from './pages/Bendoc311co';

import Bdeldoc311co from './pages/Bdeldoc311co';

import Badddoc311co from './pages/Badddoc311co';

import Editndoc312sanction from './pages/Editndoc312sanction';
import Viewndoc312sanction from './pages/Viewndoc312sanction';
import Addndoc312sanction from './pages/Addndoc312sanction';
import Viewndoc312sanctionadmin1 from './pages/Viewndoc312sanctionadmin1';

import Viewndoc312sanctionadminlinks from './pages/Viewndoc312sanctionadminlinks';

import Bendoc312sanction from './pages/Bendoc312sanction';

import Bdeldoc312sanction from './pages/Bdeldoc312sanction';

import Badddoc312sanction from './pages/Badddoc312sanction';

import Editndoc312finance from './pages/Editndoc312finance';
import Viewndoc312finance from './pages/Viewndoc312finance';
import Addndoc312finance from './pages/Addndoc312finance';
import Viewndoc312financeadmin1 from './pages/Viewndoc312financeadmin1';

import Viewndoc312financeadminlinks from './pages/Viewndoc312financeadminlinks';

import Bendoc312finance from './pages/Bendoc312finance';

import Bdeldoc312finance from './pages/Bdeldoc312finance';

import Badddoc312finance from './pages/Badddoc312finance';

import Editndoc341syllabus from './pages/Editndoc341syllabus';
import Viewndoc341syllabus from './pages/Viewndoc341syllabus';
import Addndoc341syllabus from './pages/Addndoc341syllabus';
import Viewndoc341syllabusadmin1 from './pages/Viewndoc341syllabusadmin1';

import Viewndoc341syllabusadminlinks from './pages/Viewndoc341syllabusadminlinks';

import Bendoc341syllabus from './pages/Bendoc341syllabus';

import Bdeldoc341syllabus from './pages/Bdeldoc341syllabus';

import Badddoc341syllabus from './pages/Badddoc341syllabus';

import Editndoc341ethics from './pages/Editndoc341ethics';
import Viewndoc341ethics from './pages/Viewndoc341ethics';
import Addndoc341ethics from './pages/Addndoc341ethics';
import Viewndoc341ethicsadmin1 from './pages/Viewndoc341ethicsadmin1';

import Viewndoc341ethicsadminlinks from './pages/Viewndoc341ethicsadminlinks';

import Bendoc341ethics from './pages/Bendoc341ethics';

import Bdeldoc341ethics from './pages/Bdeldoc341ethics';

import Badddoc341ethics from './pages/Badddoc341ethics';

import Editndoc341ethicsmin from './pages/Editndoc341ethicsmin';
import Viewndoc341ethicsmin from './pages/Viewndoc341ethicsmin';
import Addndoc341ethicsmin from './pages/Addndoc341ethicsmin';
import Viewndoc341ethicsminadmin1 from './pages/Viewndoc341ethicsminadmin1';

import Viewndoc341ethicsminadminlinks from './pages/Viewndoc341ethicsminadminlinks';

import Bendoc341ethicsmin from './pages/Bendoc341ethicsmin';

import Bdeldoc341ethicsmin from './pages/Bdeldoc341ethicsmin';

import Badddoc341ethicsmin from './pages/Badddoc341ethicsmin';

import Editndoc341research from './pages/Editndoc341research';
import Viewndoc341research from './pages/Viewndoc341research';
import Addndoc341research from './pages/Addndoc341research';
import Viewndoc341researchadmin1 from './pages/Viewndoc341researchadmin1';

import Viewndoc341researchadminlinks from './pages/Viewndoc341researchadminlinks';

import Bendoc341research from './pages/Bendoc341research';

import Bdeldoc341research from './pages/Bdeldoc341research';

import Badddoc341research from './pages/Badddoc341research';

import Editndoc341researchmin from './pages/Editndoc341researchmin';
import Viewndoc341researchmin from './pages/Viewndoc341researchmin';
import Addndoc341researchmin from './pages/Addndoc341researchmin';
import Viewndoc341researchminadmin1 from './pages/Viewndoc341researchminadmin1';

import Viewndoc341researchminadminlinks from './pages/Viewndoc341researchminadminlinks';

import Bendoc341researchmin from './pages/Bendoc341researchmin';

import Bdeldoc341researchmin from './pages/Bdeldoc341researchmin';

import Badddoc341researchmin from './pages/Badddoc341researchmin';

import Editndoc342phdaward from './pages/Editndoc342phdaward';
import Viewndoc342phdaward from './pages/Viewndoc342phdaward';
import Addndoc342phdaward from './pages/Addndoc342phdaward';
import Viewndoc342phdawardadmin1 from './pages/Viewndoc342phdawardadmin1';

import Viewndoc342phdawardadminlinks from './pages/Viewndoc342phdawardadminlinks';

import Bendoc342phdaward from './pages/Bendoc342phdaward';

import Bdeldoc342phdaward from './pages/Bdeldoc342phdaward';

import Badddoc342phdaward from './pages/Badddoc342phdaward';

import Editndoc342letter from './pages/Editndoc342letter';
import Viewndoc342letter from './pages/Viewndoc342letter';
import Addndoc342letter from './pages/Addndoc342letter';
import Viewndoc342letteradmin1 from './pages/Viewndoc342letteradmin1';

import Viewndoc342letteradminlinks from './pages/Viewndoc342letteradminlinks';

import Bendoc342letter from './pages/Bendoc342letter';

import Bdeldoc342letter from './pages/Bdeldoc342letter';

import Badddoc342letter from './pages/Badddoc342letter';

import Editndoc341bills from './pages/Editndoc341bills';
import Viewndoc341bills from './pages/Viewndoc341bills';
import Addndoc341bills from './pages/Addndoc341bills';
import Viewndoc341billsadmin1 from './pages/Viewndoc341billsadmin1';

import Viewndoc341billsadminlinks from './pages/Viewndoc341billsadminlinks';

import Bendoc341bills from './pages/Bendoc341bills';

import Bdeldoc341bills from './pages/Bdeldoc341bills';

import Badddoc341bills from './pages/Badddoc341bills';

import Viewdoclistuniv from './pages/Viewdoclistuniv';
import Viewdoclistunivadmin from './pages/Viewdoclistunivadmin';

import Dashscore1 from './pages/Dashscore1';

import Viewai1 from './pages/Viewai1';

import Editndoc346agency from './pages/Editndoc346agency';
import Viewndoc346agency from './pages/Viewndoc346agency';
import Addndoc346agency from './pages/Addndoc346agency';
import Viewndoc346agencyadmin1 from './pages/Viewndoc346agencyadmin1';

import Viewndoc346agencyadminlinks from './pages/Viewndoc346agencyadminlinks';

import Bendoc346agency from './pages/Bendoc346agency';

import Bdeldoc346agency from './pages/Bdeldoc346agency';

import Badddoc346agency from './pages/Badddoc346agency';

import Editndoc346ec from './pages/Editndoc346ec';
import Viewndoc346ec from './pages/Viewndoc346ec';
import Addndoc346ec from './pages/Addndoc346ec';
import Viewndoc346ecadmin1 from './pages/Viewndoc346ecadmin1';

import Viewndoc346ecadminlinks from './pages/Viewndoc346ecadminlinks';

import Bendoc346ec from './pages/Bendoc346ec';

import Bdeldoc346ec from './pages/Bdeldoc346ec';

import Badddoc346ec from './pages/Badddoc346ec';

import Editndoc346lms from './pages/Editndoc346lms';
import Viewndoc346lms from './pages/Viewndoc346lms';
import Addndoc346lms from './pages/Addndoc346lms';
import Viewndoc346lmsadmin1 from './pages/Viewndoc346lmsadmin1';

import Viewndoc346lmsadminlinks from './pages/Viewndoc346lmsadminlinks';

import Bendoc346lms from './pages/Bendoc346lms';

import Bdeldoc346lms from './pages/Bdeldoc346lms';

import Badddoc346lms from './pages/Badddoc346lms';

import Editndoc351corporate from './pages/Editndoc351corporate';
import Viewndoc351corporate from './pages/Viewndoc351corporate';
import Addndoc351corporate from './pages/Addndoc351corporate';
import Viewndoc351corporateadmin1 from './pages/Viewndoc351corporateadmin1';

import Viewndoc351corporateadminlinks from './pages/Viewndoc351corporateadminlinks';

import Bendoc351corporate from './pages/Bendoc351corporate';

import Bdeldoc351corporate from './pages/Bdeldoc351corporate';

import Badddoc351corporate from './pages/Badddoc351corporate';

import Tracklist from './pages/Tracklist';

import Editnotrack from './pages/Editnotrack';
import Viewnotrack from './pages/Viewnotrack';
import Addnotrack from './pages/Addnotrack';
import Viewnotrackadmin1 from './pages/Viewnotrackadmin1';

import Baddotrack from './pages/Baddotrack';

import Editnotrackd from './pages/Editnotrackd';
import Viewnotrackd from './pages/Viewnotrackd';
import Addnotrackd from './pages/Addnotrackd';
import Viewnotrackdadmin1 from './pages/Viewnotrackdadmin1';

import Baddotrackd from './pages/Baddotrackd';


import Editnfu251 from './pages/Editnfu251';
import Viewnfu251 from './pages/Viewnfu251';
import Addnfu251 from './pages/Addnfu251';
import Viewnfu251admin1 from './pages/Viewnfu251admin1';

import Baddfu251 from './pages/Baddfu251';

import Editnfu262 from './pages/Editnfu262';
import Viewnfu262 from './pages/Viewnfu262';
import Addnfu262 from './pages/Addnfu262';
import Viewnfu262admin1 from './pages/Viewnfu262admin1';

import Baddfu262 from './pages/Baddfu262';

import Editnfu252 from './pages/Editnfu252';
import Viewnfu252 from './pages/Viewnfu252';
import Addnfu252 from './pages/Addnfu252';
import Viewnfu252admin1 from './pages/Viewnfu252admin1';

import Baddfu252 from './pages/Baddfu252';

import Editnfu132 from './pages/Editnfu132';
import Viewnfu132 from './pages/Viewnfu132';
import Addnfu132 from './pages/Addnfu132';
import Viewnfu132admin1 from './pages/Viewnfu132admin1';

import Baddfu132 from './pages/Baddfu132';


import Editnaimstudents from './pages/Editnaimstudents';
import Viewnaimstudents from './pages/Viewnaimstudents';
import Addnaimstudents from './pages/Addnaimstudents';
import Viewnaimstudentsadmin1 from './pages/Viewnaimstudentsadmin1';

import Baddaimstudents from './pages/Baddaimstudents';

import Editnaimstudents1 from './pages/Editnaimstudents1';
import Viewnaimstudents1 from './pages/Viewnaimstudents1';
import Addnaimstudents1 from './pages/Addnaimstudents1';
import Viewnaimstudents1admin1 from './pages/Viewnaimstudents1admin1';

import Baddaimstudents1 from './pages/Baddaimstudents1';


import Editnocert from './pages/Editnocert';
import Viewnocert from './pages/Viewnocert';
import Addnocert from './pages/Addnocert';
import Viewnocertadmin1 from './pages/Viewnocertadmin1';

import Baddocert from './pages/Baddocert';

import Editnocerttopics from './pages/Editnocerttopics';
import Viewnocerttopics from './pages/Viewnocerttopics';
import Addnocerttopics from './pages/Addnocerttopics';
import Viewnocerttopicsadmin1 from './pages/Viewnocerttopicsadmin1';

import Baddocerttopics from './pages/Baddocerttopics';

import Editnocertref from './pages/Editnocertref';
import Viewnocertref from './pages/Viewnocertref';
import Addnocertref from './pages/Addnocertref';
import Viewnocertrefadmin1 from './pages/Viewnocertrefadmin1';

import Baddocertref from './pages/Baddocertref';

import Editnosparsh from './pages/Editnosparsh';
import Viewnosparsh from './pages/Viewnosparsh';
import Addnosparsh from './pages/Addnosparsh';
import Viewnosparshadmin1 from './pages/Viewnosparshadmin1';

import Baddosparsh from './pages/Baddosparsh';

import Editnosparshapply from './pages/Editnosparshapply';
import Viewnosparshapply from './pages/Viewnosparshapply';
import Addnosparshapply from './pages/Addnosparshapply';
import Viewnosparshapplyadmin1 from './pages/Viewnosparshapplyadmin1';

import Baddosparshapply from './pages/Baddosparshapply';

import Editnoproject from './pages/Editnoproject';
import Viewnoproject from './pages/Viewnoproject';
import Addnoproject from './pages/Addnoproject';
import Viewnoprojectadmin1 from './pages/Viewnoprojectadmin1';

import Baddoproject from './pages/Baddoproject';

import Editnoprojecttopics from './pages/Editnoprojecttopics';
import Viewnoprojecttopics from './pages/Viewnoprojecttopics';
import Addnoprojecttopics from './pages/Addnoprojecttopics';
import Viewnoprojecttopicsadmin1 from './pages/Viewnoprojecttopicsadmin1';

import Baddoprojecttopics from './pages/Baddoprojecttopics';

import Editnoprojectapply from './pages/Editnoprojectapply';
import Viewnoprojectapply from './pages/Viewnoprojectapply';
import Addnoprojectapply from './pages/Addnoprojectapply';
import Viewnoprojectapplyadmin1 from './pages/Viewnoprojectapplyadmin1';

import Baddoprojectapply from './pages/Baddoprojectapply';

import Editnomcq from './pages/Editnomcq';
import Viewnomcq from './pages/Viewnomcq';
import Addnomcq from './pages/Addnomcq';
import Viewnomcqadmin1 from './pages/Viewnomcqadmin1';

import Baddomcq from './pages/Baddomcq';

import Editnomcqques from './pages/Editnomcqques';
import Viewnomcqques from './pages/Viewnomcqques';
import Addnomcqques from './pages/Addnomcqques';
import Viewnomcqquesadmin1 from './pages/Viewnomcqquesadmin1';

import Baddomcqques from './pages/Baddomcqques';

import Editnomcqop from './pages/Editnomcqop';
import Viewnomcqop from './pages/Viewnomcqop';
import Addnomcqop from './pages/Addnomcqop';
import Viewnomcqopadmin1 from './pages/Viewnomcqopadmin1';

import Baddomcqop from './pages/Baddomcqop';

import Editnomcqscore from './pages/Editnomcqscore';
import Viewnomcqscore from './pages/Viewnomcqscore';
import Addnomcqscore from './pages/Addnomcqscore';
import Viewnomcqscoreadmin1 from './pages/Viewnomcqscoreadmin1';

import Baddomcqscore from './pages/Baddomcqscore';

import Ohlinks from './pages/Ohlinks';

import Privacypolicy from './pages/Privacypolicy';


import Viewnpasspercentadmin1 from './pages/Viewnpasspercentadmin1';

import Viewnpasspercentadminlinks from './pages/Viewnpasspercentadminlinks';

import Benpasspercent from './pages/Benpasspercent';

import Bdelpasspercent from './pages/Bdelpasspercent';

import Baddpasspercent from './pages/Baddpasspercent';


import Viewnnugrievanceadmin1 from './pages/Viewnnugrievanceadmin1';

import Viewnnugrievanceadminlinks from './pages/Viewnnugrievanceadminlinks';

import Bennugrievance from './pages/Bennugrievance';

import Bdelnugrievance from './pages/Bdelnugrievance';

import Baddnugrievance from './pages/Baddnugrievance';

import Editnoiprog from './pages/Editnoiprog';
import Viewnoiprog from './pages/Viewnoiprog';
import Addnoiprog from './pages/Addnoiprog';
import Viewnoiprogadmin1 from './pages/Viewnoiprogadmin1';

import Baddoiprog from './pages/Baddoiprog';

import Editnoiproj from './pages/Editnoiproj';
import Viewnoiproj from './pages/Viewnoiproj';
import Addnoiproj from './pages/Addnoiproj';
import Viewnoiprojadmin1 from './pages/Viewnoiprojadmin1';

import Baddoiproj from './pages/Baddoiproj';

import Viewaiactivity from './pages/Viewaiactivity';

import Viewnamastuddiffall from './pages/Viewnamastuddiffall';

import Circularevents from './pages/Circularevents';

import Viewneventreport from './pages/Viewneventreport';

import Createfeedbackreport from './pages/Createfeedbackreport';
import Viewaigenmenu from './pages/Viewaigenmenu';



import Editnpstatusnew from './pages/Editnpstatusnew';
import Viewnpstatusnew from './pages/Viewnpstatusnew';
import Addnpstatusnew from './pages/Addnpstatusnew';
import Viewnpstatusnewadmin1 from './pages/Viewnpstatusnewadmin1';

import Viewnpstatusnewadminlinks from './pages/Viewnpstatusnewadminlinks';

import Benpstatusnew from './pages/Benpstatusnew';

import Bdelpstatusnew from './pages/Bdelpstatusnew';

import Baddpstatusnew from './pages/Baddpstatusnew';

import Editnseedadd from './pages/Editnseedadd';
import Viewnseedadd from './pages/Viewnseedadd';
import Addnseedadd from './pages/Addnseedadd';
import Viewnseedaddadmin1 from './pages/Viewnseedaddadmin1';

import Viewnseedaddadminlinks from './pages/Viewnseedaddadminlinks';

import Benseedadd from './pages/Benseedadd';

import Bdelseedadd from './pages/Bdelseedadd';

import Baddseedadd from './pages/Baddseedadd';

import Editnphddeclare from './pages/Editnphddeclare';
import Viewnphddeclare from './pages/Viewnphddeclare';
import Addnphddeclare from './pages/Addnphddeclare';
import Viewnphddeclareadmin1 from './pages/Viewnphddeclareadmin1';

import Viewnphddeclareadminlinks from './pages/Viewnphddeclareadminlinks';

import Benphddeclare from './pages/Benphddeclare';

import Bdelphddeclare from './pages/Bdelphddeclare';

import Baddphddeclare from './pages/Baddphddeclare';

import Editnpstudmaster from './pages/Editnpstudmaster';
import Viewnpstudmaster from './pages/Viewnpstudmaster';
import Addnpstudmaster from './pages/Addnpstudmaster';
import Viewnpstudmasteradmin1 from './pages/Viewnpstudmasteradmin1';

import Viewnpstudmasteradminlinks from './pages/Viewnpstudmasteradminlinks';

import Benpstudmaster from './pages/Benpstudmaster';

import Bdelpstudmaster from './pages/Bdelpstudmaster';

import Baddpstudmaster from './pages/Baddpstudmaster';

import Editnpfacmaster from './pages/Editnpfacmaster';
import Viewnpfacmaster from './pages/Viewnpfacmaster';
import Addnpfacmaster from './pages/Addnpfacmaster';
import Viewnpfacmasteradmin1 from './pages/Viewnpfacmasteradmin1';

import Viewnpfacmasteradminlinks from './pages/Viewnpfacmasteradminlinks';

import Benpfacmaster from './pages/Benpfacmaster';

import Bdelpfacmaster from './pages/Bdelpfacmaster';

import Baddpfacmaster from './pages/Baddpfacmaster';

import Viewaigenmenuf from './pages/Viewaigenmenuf';

import Selecttypeai from './pages/Selecttypeai';

import Createfeedbackreport1 from './pages/Createfeedbackreport1';

import Createfeedbackforms from './pages/Createfeedbackforms';

import Viewnseedaddsanction from './pages/Viewnseedaddsanction';

import Createphd from './pages/Createphd';

import Editnvacnew1 from './pages/Editnvacnew1';
import Viewnvacnew1 from './pages/Viewnvacnew1';
import Addnvacnew1 from './pages/Addnvacnew1';
import Viewnvacnew1admin1 from './pages/Viewnvacnew1admin1';

import Viewnvacnew1adminlinks from './pages/Viewnvacnew1adminlinks';

import Benvacnew1 from './pages/Benvacnew1';

import Bdelvacnew1 from './pages/Bdelvacnew1';

import Baddvacnew1 from './pages/Baddvacnew1';

import Editnvacstudents1 from './pages/Editnvacstudents1';
import Viewnvacstudents1 from './pages/Viewnvacstudents1';
import Addnvacstudents1 from './pages/Addnvacstudents1';
import Viewnvacstudents1admin1 from './pages/Viewnvacstudents1admin1';

import Viewnvacstudents1adminlinks from './pages/Viewnvacstudents1adminlinks';

import Benvacstudents1 from './pages/Benvacstudents1';

import Bdelvacstudents1 from './pages/Bdelvacstudents1';

import Baddvacstudents1 from './pages/Baddvacstudents1';


import Editnvacnew2 from './pages/Editnvacnew2';
import Viewnvacnew2 from './pages/Viewnvacnew2';
import Addnvacnew2 from './pages/Addnvacnew2';
import Viewnvacnew2admin1 from './pages/Viewnvacnew2admin1';

import Viewnvacnew2adminlinks from './pages/Viewnvacnew2adminlinks';

import Benvacnew2 from './pages/Benvacnew2';

import Bdelvacnew2 from './pages/Bdelvacnew2';

import Baddvacnew2 from './pages/Baddvacnew2';

import Createvacall from './pages/Createvacall';


import Editnassignsubmit from './pages/Editnassignsubmit';
import Viewnassignsubmit from './pages/Viewnassignsubmit';
import Addnassignsubmit from './pages/Addnassignsubmit';
import Viewnassignsubmitadmin1 from './pages/Viewnassignsubmitadmin1';

import Viewnassignsubmitadminlinks from './pages/Viewnassignsubmitadminlinks';

import Benassignsubmit from './pages/Benassignsubmit';

import Bdelassignsubmit from './pages/Bdelassignsubmit';

import Baddassignsubmit from './pages/Baddassignsubmit';



import Dasherplms from './pages/Dasherplms';

import Editnacalendernew from './pages/Editnacalendernew';
import Viewnacalendernew from './pages/Viewnacalendernew';
import Addnacalendernew from './pages/Addnacalendernew';
import Viewnacalendernewadmin1 from './pages/Viewnacalendernewadmin1';

import Viewnacalendernewadminlinks from './pages/Viewnacalendernewadminlinks';

import Benacalendernew from './pages/Benacalendernew';

import Bdelacalendernew from './pages/Bdelacalendernew';

import Baddacalendernew from './pages/Baddacalendernew';

import Editndeptasks from './pages/Editndeptasks';
import Viewndeptasks from './pages/Viewndeptasks';
import Addndeptasks from './pages/Addndeptasks';
import Viewndeptasksadmin1 from './pages/Viewndeptasksadmin1';

import Viewndeptasksadminlinks from './pages/Viewndeptasksadminlinks';

import Bendeptasks from './pages/Bendeptasks';

import Bdeldeptasks from './pages/Bdeldeptasks';

import Badddeptasks from './pages/Badddeptasks';



import Editncas11 from './pages/Editncas11';
import Viewncas11 from './pages/Viewncas11';
import Addncas11 from './pages/Addncas11';
import Viewncas11admin1 from './pages/Viewncas11admin1';

import Viewncas11adminlinks from './pages/Viewncas11adminlinks';

import Bencas11 from './pages/Bencas11';

import Bdelcas11 from './pages/Bdelcas11';

import Baddcas11 from './pages/Baddcas11';

import Editncas12 from './pages/Editncas12';
import Viewncas12 from './pages/Viewncas12';
import Addncas12 from './pages/Addncas12';
import Viewncas12admin1 from './pages/Viewncas12admin1';

import Viewncas12adminlinks from './pages/Viewncas12adminlinks';

import Bencas12 from './pages/Bencas12';

import Bdelcas12 from './pages/Bdelcas12';

import Baddcas12 from './pages/Baddcas12';

import Editncas22 from './pages/Editncas22';
import Viewncas22 from './pages/Viewncas22';
import Addncas22 from './pages/Addncas22';
import Viewncas22admin1 from './pages/Viewncas22admin1';

import Viewncas22adminlinks from './pages/Viewncas22adminlinks';

import Bencas22 from './pages/Bencas22';

import Bdelcas22 from './pages/Bdelcas22';

import Baddcas22 from './pages/Baddcas22';

import Editncas23 from './pages/Editncas23';
import Viewncas23 from './pages/Viewncas23';
import Addncas23 from './pages/Addncas23';
import Viewncas23admin1 from './pages/Viewncas23admin1';

import Viewncas23adminlinks from './pages/Viewncas23adminlinks';

import Bencas23 from './pages/Bencas23';

import Bdelcas23 from './pages/Bdelcas23';

import Baddcas23 from './pages/Baddcas23';

import Editncas241 from './pages/Editncas241';
import Viewncas241 from './pages/Viewncas241';
import Addncas241 from './pages/Addncas241';
import Viewncas241admin1 from './pages/Viewncas241admin1';

import Viewncas241adminlinks from './pages/Viewncas241adminlinks';

import Bencas241 from './pages/Bencas241';

import Bdelcas241 from './pages/Bdelcas241';

import Baddcas241 from './pages/Baddcas241';

import Editncas242 from './pages/Editncas242';
import Viewncas242 from './pages/Viewncas242';
import Addncas242 from './pages/Addncas242';
import Viewncas242admin1 from './pages/Viewncas242admin1';

import Viewncas242adminlinks from './pages/Viewncas242adminlinks';

import Bencas242 from './pages/Bencas242';

import Bdelcas242 from './pages/Bdelcas242';

import Baddcas242 from './pages/Baddcas242';

import Editncas243 from './pages/Editncas243';
import Viewncas243 from './pages/Viewncas243';
import Addncas243 from './pages/Addncas243';
import Viewncas243admin1 from './pages/Viewncas243admin1';

import Viewncas243adminlinks from './pages/Viewncas243adminlinks';

import Bencas243 from './pages/Bencas243';

import Bdelcas243 from './pages/Bdelcas243';

import Baddcas243 from './pages/Baddcas243';

import Editncas251 from './pages/Editncas251';
import Viewncas251 from './pages/Viewncas251';
import Addncas251 from './pages/Addncas251';
import Viewncas251admin1 from './pages/Viewncas251admin1';

import Viewncas251adminlinks from './pages/Viewncas251adminlinks';

import Bencas251 from './pages/Bencas251';

import Bdelcas251 from './pages/Bdelcas251';

import Baddcas251 from './pages/Baddcas251';

import Editncas252 from './pages/Editncas252';
import Viewncas252 from './pages/Viewncas252';
import Addncas252 from './pages/Addncas252';
import Viewncas252admin1 from './pages/Viewncas252admin1';

import Viewncas252adminlinks from './pages/Viewncas252adminlinks';

import Bencas252 from './pages/Bencas252';

import Bdelcas252 from './pages/Bdelcas252';

import Baddcas252 from './pages/Baddcas252';

import Editncas253 from './pages/Editncas253';
import Viewncas253 from './pages/Viewncas253';
import Addncas253 from './pages/Addncas253';
import Viewncas253admin1 from './pages/Viewncas253admin1';

import Viewncas253adminlinks from './pages/Viewncas253adminlinks';

import Bencas253 from './pages/Bencas253';

import Bdelcas253 from './pages/Bdelcas253';

import Baddcas253 from './pages/Baddcas253';

import Editncas26 from './pages/Editncas26';
import Viewncas26 from './pages/Viewncas26';
import Addncas26 from './pages/Addncas26';
import Viewncas26admin1 from './pages/Viewncas26admin1';

import Viewncas26adminlinks from './pages/Viewncas26adminlinks';

import Bencas26 from './pages/Bencas26';

import Bdelcas26 from './pages/Bdelcas26';

import Baddcas26 from './pages/Baddcas26';



import Editnoptrackd from './pages/Editnoptrackd';
import Viewnoptrackd from './pages/Viewnoptrackd';
import Addnoptrackd from './pages/Addnoptrackd';
import Viewnoptrackdadmin1 from './pages/Viewnoptrackdadmin1';

import Baddoptrackd from './pages/Baddoptrackd';

import Editnadmapplnew from './pages/Editnadmapplnew';
import Viewnadmapplnew from './pages/Viewnadmapplnew';
import Addnadmapplnew from './pages/Addnadmapplnew';
import Viewnadmapplnewadmin1 from './pages/Viewnadmapplnewadmin1';

import Viewnadmapplnewadminlinks from './pages/Viewnadmapplnewadminlinks';

import Benadmapplnew from './pages/Benadmapplnew';

import Bdeladmapplnew from './pages/Bdeladmapplnew';

import Baddadmapplnew from './pages/Baddadmapplnew';
























function App() {
  return (
  <div>
    
    <Switch>
    <Route path='/exam' exact>
      <Allclasses />
    </Route>
    <Route path='/' exact>
      <Newclassesf43 />
    </Route>
    <Route path='/new' exact>
      <Newclassesf43 />
    </Route>


    <Route path='/benadmapplnew'>
<Benadmapplnew/>
</Route>
<Route path='/bdeladmapplnew'>
<Bdeladmapplnew/>
</Route>
<Route path='/baddadmapplnew'>
<Baddadmapplnew/>
</Route>
<Route path='/editnadmapplnew'>
<Editnadmapplnew/>
</Route>
<Route path='/viewnadmapplnew'>
<Viewnadmapplnew/>
</Route>
<Route path='/viewnadmapplnewadmin1'>
<Viewnadmapplnewadmin1/>
</Route>
<Route path='/viewnadmapplnewadminlinks'>
<Viewnadmapplnewadminlinks/>
</Route>
<Route path='/addnadmapplnew'>
<Addnadmapplnew/>
</Route>



    <Route path='/baddoptrackd'>
<Baddoptrackd/>
</Route>
<Route path='/editnoptrackd'>
<Editnoptrackd/>
</Route>
<Route path='/viewnoptrackd'>
<Viewnoptrackd/>
</Route>
<Route path='/viewnoptrackdadmin1'>
<Viewnoptrackdadmin1/>
</Route>
<Route path='/addnoptrackd'>
<Addnoptrackd/>
</Route>


    <Route path='/bencas11'>
<Bencas11/>
</Route>
<Route path='/bdelcas11'>
<Bdelcas11/>
</Route>
<Route path='/baddcas11'>
<Baddcas11/>
</Route>
<Route path='/editncas11'>
<Editncas11/>
</Route>
<Route path='/viewncas11'>
<Viewncas11/>
</Route>
<Route path='/viewncas11admin1'>
<Viewncas11admin1/>
</Route>
<Route path='/viewncas11adminlinks'>
<Viewncas11adminlinks/>
</Route>
<Route path='/addncas11'>
<Addncas11/>
</Route>
<Route path='/bencas12'>
<Bencas12/>
</Route>
<Route path='/bdelcas12'>
<Bdelcas12/>
</Route>
<Route path='/baddcas12'>
<Baddcas12/>
</Route>
<Route path='/editncas12'>
<Editncas12/>
</Route>
<Route path='/viewncas12'>
<Viewncas12/>
</Route>
<Route path='/viewncas12admin1'>
<Viewncas12admin1/>
</Route>
<Route path='/viewncas12adminlinks'>
<Viewncas12adminlinks/>
</Route>
<Route path='/addncas12'>
<Addncas12/>
</Route>
<Route path='/bencas22'>
<Bencas22/>
</Route>
<Route path='/bdelcas22'>
<Bdelcas22/>
</Route>
<Route path='/baddcas22'>
<Baddcas22/>
</Route>
<Route path='/editncas22'>
<Editncas22/>
</Route>
<Route path='/viewncas22'>
<Viewncas22/>
</Route>
<Route path='/viewncas22admin1'>
<Viewncas22admin1/>
</Route>
<Route path='/viewncas22adminlinks'>
<Viewncas22adminlinks/>
</Route>
<Route path='/addncas22'>
<Addncas22/>
</Route>
<Route path='/bencas23'>
<Bencas23/>
</Route>
<Route path='/bdelcas23'>
<Bdelcas23/>
</Route>
<Route path='/baddcas23'>
<Baddcas23/>
</Route>
<Route path='/editncas23'>
<Editncas23/>
</Route>
<Route path='/viewncas23'>
<Viewncas23/>
</Route>
<Route path='/viewncas23admin1'>
<Viewncas23admin1/>
</Route>
<Route path='/viewncas23adminlinks'>
<Viewncas23adminlinks/>
</Route>
<Route path='/addncas23'>
<Addncas23/>
</Route>
<Route path='/bencas241'>
<Bencas241/>
</Route>
<Route path='/bdelcas241'>
<Bdelcas241/>
</Route>
<Route path='/baddcas241'>
<Baddcas241/>
</Route>
<Route path='/editncas241'>
<Editncas241/>
</Route>
<Route path='/viewncas241'>
<Viewncas241/>
</Route>
<Route path='/viewncas241admin1'>
<Viewncas241admin1/>
</Route>
<Route path='/viewncas241adminlinks'>
<Viewncas241adminlinks/>
</Route>
<Route path='/addncas241'>
<Addncas241/>
</Route>
<Route path='/bencas242'>
<Bencas242/>
</Route>
<Route path='/bdelcas242'>
<Bdelcas242/>
</Route>
<Route path='/baddcas242'>
<Baddcas242/>
</Route>
<Route path='/editncas242'>
<Editncas242/>
</Route>
<Route path='/viewncas242'>
<Viewncas242/>
</Route>
<Route path='/viewncas242admin1'>
<Viewncas242admin1/>
</Route>
<Route path='/viewncas242adminlinks'>
<Viewncas242adminlinks/>
</Route>
<Route path='/addncas242'>
<Addncas242/>
</Route>
<Route path='/bencas243'>
<Bencas243/>
</Route>
<Route path='/bdelcas243'>
<Bdelcas243/>
</Route>
<Route path='/baddcas243'>
<Baddcas243/>
</Route>
<Route path='/editncas243'>
<Editncas243/>
</Route>
<Route path='/viewncas243'>
<Viewncas243/>
</Route>
<Route path='/viewncas243admin1'>
<Viewncas243admin1/>
</Route>
<Route path='/viewncas243adminlinks'>
<Viewncas243adminlinks/>
</Route>
<Route path='/addncas243'>
<Addncas243/>
</Route>
<Route path='/bencas251'>
<Bencas251/>
</Route>
<Route path='/bdelcas251'>
<Bdelcas251/>
</Route>
<Route path='/baddcas251'>
<Baddcas251/>
</Route>
<Route path='/editncas251'>
<Editncas251/>
</Route>
<Route path='/viewncas251'>
<Viewncas251/>
</Route>
<Route path='/viewncas251admin1'>
<Viewncas251admin1/>
</Route>
<Route path='/viewncas251adminlinks'>
<Viewncas251adminlinks/>
</Route>
<Route path='/addncas251'>
<Addncas251/>
</Route>
<Route path='/bencas252'>
<Bencas252/>
</Route>
<Route path='/bdelcas252'>
<Bdelcas252/>
</Route>
<Route path='/baddcas252'>
<Baddcas252/>
</Route>
<Route path='/editncas252'>
<Editncas252/>
</Route>
<Route path='/viewncas252'>
<Viewncas252/>
</Route>
<Route path='/viewncas252admin1'>
<Viewncas252admin1/>
</Route>
<Route path='/viewncas252adminlinks'>
<Viewncas252adminlinks/>
</Route>
<Route path='/addncas252'>
<Addncas252/>
</Route>
<Route path='/bencas253'>
<Bencas253/>
</Route>
<Route path='/bdelcas253'>
<Bdelcas253/>
</Route>
<Route path='/baddcas253'>
<Baddcas253/>
</Route>
<Route path='/editncas253'>
<Editncas253/>
</Route>
<Route path='/viewncas253'>
<Viewncas253/>
</Route>
<Route path='/viewncas253admin1'>
<Viewncas253admin1/>
</Route>
<Route path='/viewncas253adminlinks'>
<Viewncas253adminlinks/>
</Route>
<Route path='/addncas253'>
<Addncas253/>
</Route>
<Route path='/bencas26'>
<Bencas26/>
</Route>
<Route path='/bdelcas26'>
<Bdelcas26/>
</Route>
<Route path='/baddcas26'>
<Baddcas26/>
</Route>
<Route path='/editncas26'>
<Editncas26/>
</Route>
<Route path='/viewncas26'>
<Viewncas26/>
</Route>
<Route path='/viewncas26admin1'>
<Viewncas26admin1/>
</Route>
<Route path='/viewncas26adminlinks'>
<Viewncas26adminlinks/>
</Route>
<Route path='/addncas26'>
<Addncas26/>
</Route>


    <Route path='/benacalendernew'>
<Benacalendernew/>
</Route>
<Route path='/bdelacalendernew'>
<Bdelacalendernew/>
</Route>
<Route path='/baddacalendernew'>
<Baddacalendernew/>
</Route>
<Route path='/editnacalendernew'>
<Editnacalendernew/>
</Route>
<Route path='/viewnacalendernew'>
<Viewnacalendernew/>
</Route>
<Route path='/viewnacalendernewadmin1'>
<Viewnacalendernewadmin1/>
</Route>
<Route path='/viewnacalendernewadminlinks'>
<Viewnacalendernewadminlinks/>
</Route>
<Route path='/addnacalendernew'>
<Addnacalendernew/>
</Route>
<Route path='/bendeptasks'>
<Bendeptasks/>
</Route>
<Route path='/bdeldeptasks'>
<Bdeldeptasks/>
</Route>
<Route path='/badddeptasks'>
<Badddeptasks/>
</Route>
<Route path='/editndeptasks'>
<Editndeptasks/>
</Route>
<Route path='/viewndeptasks'>
<Viewndeptasks/>
</Route>
<Route path='/viewndeptasksadmin1'>
<Viewndeptasksadmin1/>
</Route>
<Route path='/viewndeptasksadminlinks'>
<Viewndeptasksadminlinks/>
</Route>
<Route path='/addndeptasks'>
<Addndeptasks/>
</Route>


    <Route path='/dasherplms'>
<Dasherplms/>
</Route>
    <Route path='/benassignsubmit'>
<Benassignsubmit/>
</Route>
<Route path='/bdelassignsubmit'>
<Bdelassignsubmit/>
</Route>
<Route path='/baddassignsubmit'>
<Baddassignsubmit/>
</Route>
<Route path='/editnassignsubmit'>
<Editnassignsubmit/>
</Route>
<Route path='/viewnassignsubmit'>
<Viewnassignsubmit/>
</Route>
<Route path='/viewnassignsubmitadmin1'>
<Viewnassignsubmitadmin1/>
</Route>
<Route path='/viewnassignsubmitadminlinks'>
<Viewnassignsubmitadminlinks/>
</Route>
<Route path='/addnassignsubmit'>
<Addnassignsubmit/>
</Route>


    <Route path='/createvacall'>
<Createvacall/>
</Route>

    <Route path='/benvacnew2'>
<Benvacnew2/>
</Route>
<Route path='/bdelvacnew2'>
<Bdelvacnew2/>
</Route>
<Route path='/baddvacnew2'>
<Baddvacnew2/>
</Route>
<Route path='/editnvacnew2'>
<Editnvacnew2/>
</Route>
<Route path='/viewnvacnew2'>
<Viewnvacnew2/>
</Route>
<Route path='/viewnvacnew2admin1'>
<Viewnvacnew2admin1/>
</Route>
<Route path='/viewnvacnew2adminlinks'>
<Viewnvacnew2adminlinks/>
</Route>
<Route path='/addnvacnew2'>
<Addnvacnew2/>
</Route>


    <Route path='/benvacnew1'>
<Benvacnew1/>
</Route>
<Route path='/bdelvacnew1'>
<Bdelvacnew1/>
</Route>
<Route path='/baddvacnew1'>
<Baddvacnew1/>
</Route>
<Route path='/editnvacnew1'>
<Editnvacnew1/>
</Route>
<Route path='/viewnvacnew1'>
<Viewnvacnew1/>
</Route>
<Route path='/viewnvacnew1admin1'>
<Viewnvacnew1admin1/>
</Route>
<Route path='/viewnvacnew1adminlinks'>
<Viewnvacnew1adminlinks/>
</Route>
<Route path='/addnvacnew1'>
<Addnvacnew1/>
</Route>
<Route path='/benvacstudents1'>
<Benvacstudents1/>
</Route>
<Route path='/bdelvacstudents1'>
<Bdelvacstudents1/>
</Route>
<Route path='/baddvacstudents1'>
<Baddvacstudents1/>
</Route>
<Route path='/editnvacstudents1'>
<Editnvacstudents1/>
</Route>
<Route path='/viewnvacstudents1'>
<Viewnvacstudents1/>
</Route>
<Route path='/viewnvacstudents1admin1'>
<Viewnvacstudents1admin1/>
</Route>
<Route path='/viewnvacstudents1adminlinks'>
<Viewnvacstudents1adminlinks/>
</Route>
<Route path='/addnvacstudents1'>
<Addnvacstudents1/>
</Route>


    <Route path='/createphd'>
<Createphd/>
</Route>
    <Route path='/viewnseedaddsanction'>
<Viewnseedaddsanction/>
</Route>
    <Route path='/createfeedbackforms'>
<Createfeedbackforms/>
</Route>
    <Route path='/createfeedbackreport1'>
<Createfeedbackreport1/>
</Route>

    <Route path='/selecttypeai'>
<Selecttypeai/>
</Route>

    <Route path='/viewaigenmenuf'>
<Viewaigenmenuf/>
</Route>

    <Route path='/benpstatusnew'>
<Benpstatusnew/>
</Route>
<Route path='/bdelpstatusnew'>
<Bdelpstatusnew/>
</Route>
<Route path='/baddpstatusnew'>
<Baddpstatusnew/>
</Route>
<Route path='/editnpstatusnew'>
<Editnpstatusnew/>
</Route>
<Route path='/viewnpstatusnew'>
<Viewnpstatusnew/>
</Route>
<Route path='/viewnpstatusnewadmin1'>
<Viewnpstatusnewadmin1/>
</Route>
<Route path='/viewnpstatusnewadminlinks'>
<Viewnpstatusnewadminlinks/>
</Route>
<Route path='/addnpstatusnew'>
<Addnpstatusnew/>
</Route>
<Route path='/benseedadd'>
<Benseedadd/>
</Route>
<Route path='/bdelseedadd'>
<Bdelseedadd/>
</Route>
<Route path='/baddseedadd'>
<Baddseedadd/>
</Route>
<Route path='/editnseedadd'>
<Editnseedadd/>
</Route>
<Route path='/viewnseedadd'>
<Viewnseedadd/>
</Route>
<Route path='/viewnseedaddadmin1'>
<Viewnseedaddadmin1/>
</Route>
<Route path='/viewnseedaddadminlinks'>
<Viewnseedaddadminlinks/>
</Route>
<Route path='/addnseedadd'>
<Addnseedadd/>
</Route>
<Route path='/benphddeclare'>
<Benphddeclare/>
</Route>
<Route path='/bdelphddeclare'>
<Bdelphddeclare/>
</Route>
<Route path='/baddphddeclare'>
<Baddphddeclare/>
</Route>
<Route path='/editnphddeclare'>
<Editnphddeclare/>
</Route>
<Route path='/viewnphddeclare'>
<Viewnphddeclare/>
</Route>
<Route path='/viewnphddeclareadmin1'>
<Viewnphddeclareadmin1/>
</Route>
<Route path='/viewnphddeclareadminlinks'>
<Viewnphddeclareadminlinks/>
</Route>
<Route path='/addnphddeclare'>
<Addnphddeclare/>
</Route>
<Route path='/benpstudmaster'>
<Benpstudmaster/>
</Route>
<Route path='/bdelpstudmaster'>
<Bdelpstudmaster/>
</Route>
<Route path='/baddpstudmaster'>
<Baddpstudmaster/>
</Route>
<Route path='/editnpstudmaster'>
<Editnpstudmaster/>
</Route>
<Route path='/viewnpstudmaster'>
<Viewnpstudmaster/>
</Route>
<Route path='/viewnpstudmasteradmin1'>
<Viewnpstudmasteradmin1/>
</Route>
<Route path='/viewnpstudmasteradminlinks'>
<Viewnpstudmasteradminlinks/>
</Route>
<Route path='/addnpstudmaster'>
<Addnpstudmaster/>
</Route>
<Route path='/benpfacmaster'>
<Benpfacmaster/>
</Route>
<Route path='/bdelpfacmaster'>
<Bdelpfacmaster/>
</Route>
<Route path='/baddpfacmaster'>
<Baddpfacmaster/>
</Route>
<Route path='/editnpfacmaster'>
<Editnpfacmaster/>
</Route>
<Route path='/viewnpfacmaster'>
<Viewnpfacmaster/>
</Route>
<Route path='/viewnpfacmasteradmin1'>
<Viewnpfacmasteradmin1/>
</Route>
<Route path='/viewnpfacmasteradminlinks'>
<Viewnpfacmasteradminlinks/>
</Route>
<Route path='/addnpfacmaster'>
<Addnpfacmaster/>
</Route>



    <Route path='/createfeedbackreport'>
<Createfeedbackreport/>
</Route>
<Route path='/viewaigenmenu'>
<Viewaigenmenu/>
</Route>

    <Route path='/viewneventreport'>
<Viewneventreport/>
</Route>

    <Route path='/circularevents'>
<Circularevents/>
</Route>

    <Route path='/viewnamastuddiffall'>
<Viewnamastuddiffall/>
</Route>


    <Route path='/viewaiactivity'>
<Viewaiactivity/>
</Route>

    <Route path='/baddoiprog'>
<Baddoiprog/>
</Route>
<Route path='/editnoiprog'>
<Editnoiprog/>
</Route>
<Route path='/viewnoiprog'>
<Viewnoiprog/>
</Route>
<Route path='/viewnoiprogadmin1'>
<Viewnoiprogadmin1/>
</Route>
<Route path='/addnoiprog'>
<Addnoiprog/>
</Route>
<Route path='/baddoiproj'>
<Baddoiproj/>
</Route>
<Route path='/editnoiproj'>
<Editnoiproj/>
</Route>
<Route path='/viewnoiproj'>
<Viewnoiproj/>
</Route>
<Route path='/viewnoiprojadmin1'>
<Viewnoiprojadmin1/>
</Route>
<Route path='/addnoiproj'>
<Addnoiproj/>
</Route>



    <Route path='/benpasspercent'>
<Benpasspercent/>
</Route>
<Route path='/bdelpasspercent'>
<Bdelpasspercent/>
</Route>
<Route path='/baddpasspercent'>
<Baddpasspercent/>
</Route>


<Route path='/viewnpasspercentadmin1'>
<Viewnpasspercentadmin1/>
</Route>
<Route path='/viewnpasspercentadminlinks'>
<Viewnpasspercentadminlinks/>
</Route>

<Route path='/bennugrievance'>
<Bennugrievance/>
</Route>
<Route path='/bdelnugrievance'>
<Bdelnugrievance/>
</Route>
<Route path='/baddnugrievance'>
<Baddnugrievance/>
</Route>

<Route path='/viewnnugrievanceadmin1'>
<Viewnnugrievanceadmin1/>
</Route>
<Route path='/viewnnugrievanceadminlinks'>
<Viewnnugrievanceadminlinks/>
</Route>



    <Route path='/privacypolicy'>
<Privacypolicy/>
</Route>
    <Route path='/ohlinks'>
<Ohlinks/>
</Route>

    <Route path='/baddocert'>
<Baddocert/>
</Route>
<Route path='/editnocert'>
<Editnocert/>
</Route>
<Route path='/viewnocert'>
<Viewnocert/>
</Route>
<Route path='/viewnocertadmin1'>
<Viewnocertadmin1/>
</Route>
<Route path='/addnocert'>
<Addnocert/>
</Route>
<Route path='/baddocerttopics'>
<Baddocerttopics/>
</Route>
<Route path='/editnocerttopics'>
<Editnocerttopics/>
</Route>
<Route path='/viewnocerttopics'>
<Viewnocerttopics/>
</Route>
<Route path='/viewnocerttopicsadmin1'>
<Viewnocerttopicsadmin1/>
</Route>
<Route path='/addnocerttopics'>
<Addnocerttopics/>
</Route>
<Route path='/baddocertref'>
<Baddocertref/>
</Route>
<Route path='/editnocertref'>
<Editnocertref/>
</Route>
<Route path='/viewnocertref'>
<Viewnocertref/>
</Route>
<Route path='/viewnocertrefadmin1'>
<Viewnocertrefadmin1/>
</Route>
<Route path='/addnocertref'>
<Addnocertref/>
</Route>
<Route path='/baddosparsh'>
<Baddosparsh/>
</Route>
<Route path='/editnosparsh'>
<Editnosparsh/>
</Route>
<Route path='/viewnosparsh'>
<Viewnosparsh/>
</Route>
<Route path='/viewnosparshadmin1'>
<Viewnosparshadmin1/>
</Route>
<Route path='/addnosparsh'>
<Addnosparsh/>
</Route>
<Route path='/baddosparshapply'>
<Baddosparshapply/>
</Route>
<Route path='/editnosparshapply'>
<Editnosparshapply/>
</Route>
<Route path='/viewnosparshapply'>
<Viewnosparshapply/>
</Route>
<Route path='/viewnosparshapplyadmin1'>
<Viewnosparshapplyadmin1/>
</Route>
<Route path='/addnosparshapply'>
<Addnosparshapply/>
</Route>
<Route path='/baddoproject'>
<Baddoproject/>
</Route>
<Route path='/editnoproject'>
<Editnoproject/>
</Route>
<Route path='/viewnoproject'>
<Viewnoproject/>
</Route>
<Route path='/viewnoprojectadmin1'>
<Viewnoprojectadmin1/>
</Route>
<Route path='/addnoproject'>
<Addnoproject/>
</Route>
<Route path='/baddoprojecttopics'>
<Baddoprojecttopics/>
</Route>
<Route path='/editnoprojecttopics'>
<Editnoprojecttopics/>
</Route>
<Route path='/viewnoprojecttopics'>
<Viewnoprojecttopics/>
</Route>
<Route path='/viewnoprojecttopicsadmin1'>
<Viewnoprojecttopicsadmin1/>
</Route>
<Route path='/addnoprojecttopics'>
<Addnoprojecttopics/>
</Route>
<Route path='/baddoprojectapply'>
<Baddoprojectapply/>
</Route>
<Route path='/editnoprojectapply'>
<Editnoprojectapply/>
</Route>
<Route path='/viewnoprojectapply'>
<Viewnoprojectapply/>
</Route>
<Route path='/viewnoprojectapplyadmin1'>
<Viewnoprojectapplyadmin1/>
</Route>
<Route path='/addnoprojectapply'>
<Addnoprojectapply/>
</Route>
<Route path='/baddomcq'>
<Baddomcq/>
</Route>
<Route path='/editnomcq'>
<Editnomcq/>
</Route>
<Route path='/viewnomcq'>
<Viewnomcq/>
</Route>
<Route path='/viewnomcqadmin1'>
<Viewnomcqadmin1/>
</Route>
<Route path='/addnomcq'>
<Addnomcq/>
</Route>
<Route path='/baddomcqques'>
<Baddomcqques/>
</Route>
<Route path='/editnomcqques'>
<Editnomcqques/>
</Route>
<Route path='/viewnomcqques'>
<Viewnomcqques/>
</Route>
<Route path='/viewnomcqquesadmin1'>
<Viewnomcqquesadmin1/>
</Route>
<Route path='/addnomcqques'>
<Addnomcqques/>
</Route>
<Route path='/baddomcqop'>
<Baddomcqop/>
</Route>
<Route path='/editnomcqop'>
<Editnomcqop/>
</Route>
<Route path='/viewnomcqop'>
<Viewnomcqop/>
</Route>
<Route path='/viewnomcqopadmin1'>
<Viewnomcqopadmin1/>
</Route>
<Route path='/addnomcqop'>
<Addnomcqop/>
</Route>
<Route path='/baddomcqscore'>
<Baddomcqscore/>
</Route>
<Route path='/editnomcqscore'>
<Editnomcqscore/>
</Route>
<Route path='/viewnomcqscore'>
<Viewnomcqscore/>
</Route>
<Route path='/viewnomcqscoreadmin1'>
<Viewnomcqscoreadmin1/>
</Route>
<Route path='/addnomcqscore'>
<Addnomcqscore/>
</Route>





    <Route path='/baddaimstudents1'>
<Baddaimstudents1/>
</Route>
<Route path='/editnaimstudents1'>
<Editnaimstudents1/>
</Route>
<Route path='/viewnaimstudents1'>
<Viewnaimstudents1/>
</Route>
<Route path='/viewnaimstudents1admin1'>
<Viewnaimstudents1admin1/>
</Route>
<Route path='/addnaimstudents1'>
<Addnaimstudents1/>
</Route>



    <Route path='/baddaimstudents'>
<Baddaimstudents/>
</Route>
<Route path='/editnaimstudents'>
<Editnaimstudents/>
</Route>
<Route path='/viewnaimstudents'>
<Viewnaimstudents/>
</Route>
<Route path='/viewnaimstudentsadmin1'>
<Viewnaimstudentsadmin1/>
</Route>
<Route path='/addnaimstudents'>
<Addnaimstudents/>
</Route>



    <Route path='/baddfu251'>
<Baddfu251/>
</Route>
<Route path='/editnfu251'>
<Editnfu251/>
</Route>
<Route path='/viewnfu251'>
<Viewnfu251/>
</Route>
<Route path='/viewnfu251admin1'>
<Viewnfu251admin1/>
</Route>
<Route path='/addnfu251'>
<Addnfu251/>
</Route>
<Route path='/baddfu262'>
<Baddfu262/>
</Route>
<Route path='/editnfu262'>
<Editnfu262/>
</Route>
<Route path='/viewnfu262'>
<Viewnfu262/>
</Route>
<Route path='/viewnfu262admin1'>
<Viewnfu262admin1/>
</Route>
<Route path='/addnfu262'>
<Addnfu262/>
</Route>
<Route path='/baddfu252'>
<Baddfu252/>
</Route>
<Route path='/editnfu252'>
<Editnfu252/>
</Route>
<Route path='/viewnfu252'>
<Viewnfu252/>
</Route>
<Route path='/viewnfu252admin1'>
<Viewnfu252admin1/>
</Route>
<Route path='/addnfu252'>
<Addnfu252/>
</Route>
<Route path='/baddfu132'>
<Baddfu132/>
</Route>
<Route path='/editnfu132'>
<Editnfu132/>
</Route>
<Route path='/viewnfu132'>
<Viewnfu132/>
</Route>
<Route path='/viewnfu132admin1'>
<Viewnfu132admin1/>
</Route>
<Route path='/addnfu132'>
<Addnfu132/>
</Route>


    <Route path='/baddotrackd'>
<Baddotrackd/>
</Route>
<Route path='/editnotrackd'>
<Editnotrackd/>
</Route>
<Route path='/viewnotrackd'>
<Viewnotrackd/>
</Route>
<Route path='/viewnotrackdadmin1'>
<Viewnotrackdadmin1/>
</Route>
<Route path='/addnotrackd'>
<Addnotrackd/>
</Route>


    <Route path='/baddotrack'>
<Baddotrack/>
</Route>
<Route path='/editnotrack'>
<Editnotrack/>
</Route>
<Route path='/viewnotrack'>
<Viewnotrack/>
</Route>
<Route path='/viewnotrackadmin1'>
<Viewnotrackadmin1/>
</Route>
<Route path='/addnotrack'>
<Addnotrack/>
</Route>

    <Route path='/tracklist'>
<Tracklist/>
</Route>
    <Route path='/bendoc346ec'>
<Bendoc346ec/>
</Route>
<Route path='/bdeldoc346ec'>
<Bdeldoc346ec/>
</Route>
<Route path='/badddoc346ec'>
<Badddoc346ec/>
</Route>
<Route path='/editndoc346ec'>
<Editndoc346ec/>
</Route>
<Route path='/viewndoc346ec'>
<Viewndoc346ec/>
</Route>
<Route path='/viewndoc346ecadmin1'>
<Viewndoc346ecadmin1/>
</Route>
<Route path='/viewndoc346ecadminlinks'>
<Viewndoc346ecadminlinks/>
</Route>
<Route path='/addndoc346ec'>
<Addndoc346ec/>
</Route>
<Route path='/bendoc346lms'>
<Bendoc346lms/>
</Route>
<Route path='/bdeldoc346lms'>
<Bdeldoc346lms/>
</Route>
<Route path='/badddoc346lms'>
<Badddoc346lms/>
</Route>
<Route path='/editndoc346lms'>
<Editndoc346lms/>
</Route>
<Route path='/viewndoc346lms'>
<Viewndoc346lms/>
</Route>
<Route path='/viewndoc346lmsadmin1'>
<Viewndoc346lmsadmin1/>
</Route>
<Route path='/viewndoc346lmsadminlinks'>
<Viewndoc346lmsadminlinks/>
</Route>
<Route path='/addndoc346lms'>
<Addndoc346lms/>
</Route>
<Route path='/bendoc351corporate'>
<Bendoc351corporate/>
</Route>
<Route path='/bdeldoc351corporate'>
<Bdeldoc351corporate/>
</Route>
<Route path='/badddoc351corporate'>
<Badddoc351corporate/>
</Route>
<Route path='/editndoc351corporate'>
<Editndoc351corporate/>
</Route>
<Route path='/viewndoc351corporate'>
<Viewndoc351corporate/>
</Route>
<Route path='/viewndoc351corporateadmin1'>
<Viewndoc351corporateadmin1/>
</Route>
<Route path='/viewndoc351corporateadminlinks'>
<Viewndoc351corporateadminlinks/>
</Route>
<Route path='/addndoc351corporate'>
<Addndoc351corporate/>
</Route>
<Route path='/bendoc346agency'>
<Bendoc346agency/>
</Route>
<Route path='/bdeldoc346agency'>
<Bdeldoc346agency/>
</Route>
<Route path='/badddoc346agency'>
<Badddoc346agency/>
</Route>
<Route path='/editndoc346agency'>
<Editndoc346agency/>
</Route>
<Route path='/viewndoc346agency'>
<Viewndoc346agency/>
</Route>
<Route path='/viewndoc346agencyadmin1'>
<Viewndoc346agencyadmin1/>
</Route>
<Route path='/viewndoc346agencyadminlinks'>
<Viewndoc346agencyadminlinks/>
</Route>
<Route path='/addndoc346agency'>
<Addndoc346agency/>
</Route>



    <Route path='/viewai1'>
<Viewai1/>
</Route>
    <Route path='/dashscore1'>
<Dashscore1/>
</Route>
    <Route path='/viewdoclistunivadmin'>
<Viewdoclistunivadmin/>
</Route>
<Route path='/viewdoclistuniv'>
<Viewdoclistuniv/>
</Route>

    <Route path='/bendoc121ac'>
<Bendoc121ac/>
</Route>
<Route path='/bdeldoc121ac'>
<Bdeldoc121ac/>
</Route>
<Route path='/badddoc121ac'>
<Badddoc121ac/>
</Route>
<Route path='/editndoc121ac'>
<Editndoc121ac/>
</Route>
<Route path='/viewndoc121ac'>
<Viewndoc121ac/>
</Route>
<Route path='/viewndoc121acadmin1'>
<Viewndoc121acadmin1/>
</Route>
<Route path='/viewndoc121acadminlinks'>
<Viewndoc121acadminlinks/>
</Route>
<Route path='/addndoc121ac'>
<Addndoc121ac/>
</Route>
<Route path='/bendoc121bos'>
<Bendoc121bos/>
</Route>
<Route path='/bdeldoc121bos'>
<Bdeldoc121bos/>
</Route>
<Route path='/badddoc121bos'>
<Badddoc121bos/>
</Route>
<Route path='/editndoc121bos'>
<Editndoc121bos/>
</Route>
<Route path='/viewndoc121bos'>
<Viewndoc121bos/>
</Route>
<Route path='/viewndoc121bosadmin1'>
<Viewndoc121bosadmin1/>
</Route>
<Route path='/viewndoc121bosadminlinks'>
<Viewndoc121bosadminlinks/>
</Route>
<Route path='/addndoc121bos'>
<Addndoc121bos/>
</Route>
<Route path='/bendoc132att'>
<Bendoc132att/>
</Route>
<Route path='/bdeldoc132att'>
<Bdeldoc132att/>
</Route>
<Route path='/badddoc132att'>
<Badddoc132att/>
</Route>
<Route path='/editndoc132att'>
<Editndoc132att/>
</Route>
<Route path='/viewndoc132att'>
<Viewndoc132att/>
</Route>
<Route path='/viewndoc132attadmin1'>
<Viewndoc132attadmin1/>
</Route>
<Route path='/viewndoc132attadminlinks'>
<Viewndoc132attadminlinks/>
</Route>
<Route path='/addndoc132att'>
<Addndoc132att/>
</Route>
<Route path='/bendoc132brochure'>
<Bendoc132brochure/>
</Route>
<Route path='/bdeldoc132brochure'>
<Bdeldoc132brochure/>
</Route>
<Route path='/badddoc132brochure'>
<Badddoc132brochure/>
</Route>
<Route path='/editndoc132brochure'>
<Editndoc132brochure/>
</Route>
<Route path='/viewndoc132brochure'>
<Viewndoc132brochure/>
</Route>
<Route path='/viewndoc132brochureadmin1'>
<Viewndoc132brochureadmin1/>
</Route>
<Route path='/viewndoc132brochureadminlinks'>
<Viewndoc132brochureadminlinks/>
</Route>
<Route path='/addndoc132brochure'>
<Addndoc132brochure/>
</Route>
<Route path='/bendoc132cert'>
<Bendoc132cert/>
</Route>
<Route path='/bdeldoc132cert'>
<Bdeldoc132cert/>
</Route>
<Route path='/badddoc132cert'>
<Badddoc132cert/>
</Route>
<Route path='/editndoc132cert'>
<Editndoc132cert/>
</Route>
<Route path='/viewndoc132cert'>
<Viewndoc132cert/>
</Route>
<Route path='/viewndoc132certadmin1'>
<Viewndoc132certadmin1/>
</Route>
<Route path='/viewndoc132certadminlinks'>
<Viewndoc132certadminlinks/>
</Route>
<Route path='/addndoc132cert'>
<Addndoc132cert/>
</Route>
<Route path='/bendoc133intern'>
<Bendoc133intern/>
</Route>
<Route path='/bdeldoc133intern'>
<Bdeldoc133intern/>
</Route>
<Route path='/badddoc133intern'>
<Badddoc133intern/>
</Route>
<Route path='/editndoc133intern'>
<Editndoc133intern/>
</Route>
<Route path='/viewndoc133intern'>
<Viewndoc133intern/>
</Route>
<Route path='/viewndoc133internadmin1'>
<Viewndoc133internadmin1/>
</Route>
<Route path='/viewndoc133internadminlinks'>
<Viewndoc133internadminlinks/>
</Route>
<Route path='/addndoc133intern'>
<Addndoc133intern/>
</Route>
<Route path='/bendoc133bos'>
<Bendoc133bos/>
</Route>
<Route path='/bdeldoc133bos'>
<Bdeldoc133bos/>
</Route>
<Route path='/badddoc133bos'>
<Badddoc133bos/>
</Route>
<Route path='/editndoc133bos'>
<Editndoc133bos/>
</Route>
<Route path='/viewndoc133bos'>
<Viewndoc133bos/>
</Route>
<Route path='/viewndoc133bosadmin1'>
<Viewndoc133bosadmin1/>
</Route>
<Route path='/viewndoc133bosadminlinks'>
<Viewndoc133bosadminlinks/>
</Route>
<Route path='/addndoc133bos'>
<Addndoc133bos/>
</Route>
<Route path='/bendoc141fsubmit'>
<Bendoc141fsubmit/>
</Route>
<Route path='/bdeldoc141fsubmit'>
<Bdeldoc141fsubmit/>
</Route>
<Route path='/badddoc141fsubmit'>
<Badddoc141fsubmit/>
</Route>
<Route path='/editndoc141fsubmit'>
<Editndoc141fsubmit/>
</Route>
<Route path='/viewndoc141fsubmit'>
<Viewndoc141fsubmit/>
</Route>
<Route path='/viewndoc141fsubmitadmin1'>
<Viewndoc141fsubmitadmin1/>
</Route>
<Route path='/viewndoc141fsubmitadminlinks'>
<Viewndoc141fsubmitadminlinks/>
</Route>
<Route path='/addndoc141fsubmit'>
<Addndoc141fsubmit/>
</Route>
<Route path='/bendoc141sample'>
<Bendoc141sample/>
</Route>
<Route path='/bdeldoc141sample'>
<Bdeldoc141sample/>
</Route>
<Route path='/badddoc141sample'>
<Badddoc141sample/>
</Route>
<Route path='/editndoc141sample'>
<Editndoc141sample/>
</Route>
<Route path='/viewndoc141sample'>
<Viewndoc141sample/>
</Route>
<Route path='/viewndoc141sampleadmin1'>
<Viewndoc141sampleadmin1/>
</Route>
<Route path='/viewndoc141sampleadminlinks'>
<Viewndoc141sampleadminlinks/>
</Route>
<Route path='/addndoc141sample'>
<Addndoc141sample/>
</Route>
<Route path='/bendod211intake'>
<Bendod211intake/>
</Route>
<Route path='/bdeldod211intake'>
<Bdeldod211intake/>
</Route>
<Route path='/badddod211intake'>
<Badddod211intake/>
</Route>
<Route path='/editndod211intake'>
<Editndod211intake/>
</Route>
<Route path='/viewndod211intake'>
<Viewndod211intake/>
</Route>
<Route path='/viewndod211intakeadmin1'>
<Viewndod211intakeadmin1/>
</Route>
<Route path='/viewndod211intakeadminlinks'>
<Viewndod211intakeadminlinks/>
</Route>
<Route path='/addndod211intake'>
<Addndod211intake/>
</Route>
<Route path='/bendoc211extract'>
<Bendoc211extract/>
</Route>
<Route path='/bdeldoc211extract'>
<Bdeldoc211extract/>
</Route>
<Route path='/badddoc211extract'>
<Badddoc211extract/>
</Route>
<Route path='/editndoc211extract'>
<Editndoc211extract/>
</Route>
<Route path='/viewndoc211extract'>
<Viewndoc211extract/>
</Route>
<Route path='/viewndoc211extractadmin1'>
<Viewndoc211extractadmin1/>
</Route>
<Route path='/viewndoc211extractadminlinks'>
<Viewndoc211extractadminlinks/>
</Route>
<Route path='/addndoc211extract'>
<Addndoc211extract/>
</Route>
<Route path='/bendoc212final'>
<Bendoc212final/>
</Route>
<Route path='/bdeldoc212final'>
<Bdeldoc212final/>
</Route>
<Route path='/badddoc212final'>
<Badddoc212final/>
</Route>
<Route path='/editndoc212final'>
<Editndoc212final/>
</Route>
<Route path='/viewndoc212final'>
<Viewndoc212final/>
</Route>
<Route path='/viewndoc212finaladmin1'>
<Viewndoc212finaladmin1/>
</Route>
<Route path='/viewndoc212finaladminlinks'>
<Viewndoc212finaladminlinks/>
</Route>
<Route path='/addndoc212final'>
<Addndoc212final/>
</Route>
<Route path='/bendoc212letter'>
<Bendoc212letter/>
</Route>
<Route path='/bdeldoc212letter'>
<Bdeldoc212letter/>
</Route>
<Route path='/badddoc212letter'>
<Badddoc212letter/>
</Route>
<Route path='/editndoc212letter'>
<Editndoc212letter/>
</Route>
<Route path='/viewndoc212letter'>
<Viewndoc212letter/>
</Route>
<Route path='/viewndoc212letteradmin1'>
<Viewndoc212letteradmin1/>
</Route>
<Route path='/viewndoc212letteradminlinks'>
<Viewndoc212letteradminlinks/>
</Route>
<Route path='/addndoc212letter'>
<Addndoc212letter/>
</Route>
<Route path='/bendod222stud'>
<Bendod222stud/>
</Route>
<Route path='/bdeldod222stud'>
<Bdeldod222stud/>
</Route>
<Route path='/badddod222stud'>
<Badddod222stud/>
</Route>
<Route path='/editndod222stud'>
<Editndod222stud/>
</Route>
<Route path='/viewndod222stud'>
<Viewndod222stud/>
</Route>
<Route path='/viewndod222studadmin1'>
<Viewndod222studadmin1/>
</Route>
<Route path='/viewndod222studadminlinks'>
<Viewndod222studadminlinks/>
</Route>
<Route path='/addndod222stud'>
<Addndod222stud/>
</Route>
<Route path='/bendoc141action'>
<Bendoc141action/>
</Route>
<Route path='/bdeldoc141action'>
<Bdeldoc141action/>
</Route>
<Route path='/badddoc141action'>
<Badddoc141action/>
</Route>
<Route path='/editndoc141action'>
<Editndoc141action/>
</Route>
<Route path='/viewndoc141action'>
<Viewndoc141action/>
</Route>
<Route path='/viewndoc141actionadmin1'>
<Viewndoc141actionadmin1/>
</Route>
<Route path='/viewndoc141actionadminlinks'>
<Viewndoc141actionadminlinks/>
</Route>
<Route path='/addndoc141action'>
<Addndoc141action/>
</Route>
<Route path='/bendoc222teachers'>
<Bendoc222teachers/>
</Route>
<Route path='/bdeldoc222teachers'>
<Bdeldoc222teachers/>
</Route>
<Route path='/badddoc222teachers'>
<Badddoc222teachers/>
</Route>
<Route path='/editndoc222teachers'>
<Editndoc222teachers/>
</Route>
<Route path='/viewndoc222teachers'>
<Viewndoc222teachers/>
</Route>
<Route path='/viewndoc222teachersadmin1'>
<Viewndoc222teachersadmin1/>
</Route>
<Route path='/viewndoc222teachersadminlinks'>
<Viewndoc222teachersadminlinks/>
</Route>
<Route path='/addndoc222teachers'>
<Addndoc222teachers/>
</Route>
<Route path='/bendoc241sanction'>
<Bendoc241sanction/>
</Route>
<Route path='/bdeldoc241sanction'>
<Bdeldoc241sanction/>
</Route>
<Route path='/badddoc241sanction'>
<Badddoc241sanction/>
</Route>
<Route path='/editndoc241sanction'>
<Editndoc241sanction/>
</Route>
<Route path='/viewndoc241sanction'>
<Viewndoc241sanction/>
</Route>
<Route path='/viewndoc241sanctionadmin1'>
<Viewndoc241sanctionadmin1/>
</Route>
<Route path='/viewndoc241sanctionadminlinks'>
<Viewndoc241sanctionadminlinks/>
</Route>
<Route path='/addndoc241sanction'>
<Addndoc241sanction/>
</Route>
<Route path='/bendoc242faclist'>
<Bendoc242faclist/>
</Route>
<Route path='/bdeldoc242faclist'>
<Bdeldoc242faclist/>
</Route>
<Route path='/badddoc242faclist'>
<Badddoc242faclist/>
</Route>
<Route path='/editndoc242faclist'>
<Editndoc242faclist/>
</Route>
<Route path='/viewndoc242faclist'>
<Viewndoc242faclist/>
</Route>
<Route path='/viewndoc242faclistadmin1'>
<Viewndoc242faclistadmin1/>
</Route>
<Route path='/viewndoc242faclistadminlinks'>
<Viewndoc242faclistadminlinks/>
</Route>
<Route path='/addndoc242faclist'>
<Addndoc242faclist/>
</Route>
<Route path='/bendoc252list'>
<Bendoc252list/>
</Route>
<Route path='/bdeldoc252list'>
<Bdeldoc252list/>
</Route>
<Route path='/badddoc252list'>
<Badddoc252list/>
</Route>
<Route path='/editndoc252list'>
<Editndoc252list/>
</Route>
<Route path='/viewndoc252list'>
<Viewndoc252list/>
</Route>
<Route path='/viewndoc252listadmin1'>
<Viewndoc252listadmin1/>
</Route>
<Route path='/viewndoc252listadminlinks'>
<Viewndoc252listadminlinks/>
</Route>
<Route path='/addndoc252list'>
<Addndoc252list/>
</Route>
<Route path='/bendoc253screenshot'>
<Bendoc253screenshot/>
</Route>
<Route path='/bdeldoc253screenshot'>
<Bdeldoc253screenshot/>
</Route>
<Route path='/badddoc253screenshot'>
<Badddoc253screenshot/>
</Route>
<Route path='/editndoc253screenshot'>
<Editndoc253screenshot/>
</Route>
<Route path='/viewndoc253screenshot'>
<Viewndoc253screenshot/>
</Route>
<Route path='/viewndoc253screenshotadmin1'>
<Viewndoc253screenshotadmin1/>
</Route>
<Route path='/viewndoc253screenshotadminlinks'>
<Viewndoc253screenshotadminlinks/>
</Route>
<Route path='/addndoc253screenshot'>
<Addndoc253screenshot/>
</Route>
<Route path='/bendoc253report'>
<Bendoc253report/>
</Route>
<Route path='/bdeldoc253report'>
<Bdeldoc253report/>
</Route>
<Route path='/badddoc253report'>
<Badddoc253report/>
</Route>
<Route path='/editndoc253report'>
<Editndoc253report/>
</Route>
<Route path='/viewndoc253report'>
<Viewndoc253report/>
</Route>
<Route path='/viewndoc253reportadmin1'>
<Viewndoc253reportadmin1/>
</Route>
<Route path='/viewndoc253reportadminlinks'>
<Viewndoc253reportadminlinks/>
</Route>
<Route path='/addndoc253report'>
<Addndoc253report/>
</Route>
{/* <Route path='/bendoc261polist'>
<Bendoc261polist/>
</Route>
<Route path='/bdeldoc261polist'>
<Bdeldoc261polist/>
</Route>
<Route path='/badddoc261polist'>
<Badddoc261polist/>
</Route>
<Route path='/editndoc261polist'>
<Editndoc261polist/>
</Route>
<Route path='/viewndoc261polist'>
<Viewndoc261polist/>
</Route>
<Route path='/viewndoc261polistadmin1'>
<Viewndoc261polistadmin1/>
</Route>
<Route path='/viewndoc261polistadminlinks'>
<Viewndoc261polistadminlinks/>
</Route>
<Route path='/addndoc261polist'>
<Addndoc261polist/>
</Route> */}
<Route path='/bendoc261colist'>
<Bendoc261colist/>
</Route>
<Route path='/bdeldoc261colist'>
<Bdeldoc261colist/>
</Route>
<Route path='/badddoc261colist'>
<Badddoc261colist/>
</Route>
<Route path='/editndoc261colist'>
<Editndoc261colist/>
</Route>
<Route path='/viewndoc261colist'>
<Viewndoc261colist/>
</Route>
<Route path='/viewndoc261colistadmin1'>
<Viewndoc261colistadmin1/>
</Route>
<Route path='/viewndoc261colistadminlinks'>
<Viewndoc261colistadminlinks/>
</Route>
<Route path='/addndoc261colist'>
<Addndoc261colist/>
</Route>
<Route path='/bendoc262cert'>
<Bendoc262cert/>
</Route>
<Route path='/bdeldoc262cert'>
<Bdeldoc262cert/>
</Route>
<Route path='/badddoc262cert'>
<Badddoc262cert/>
</Route>
<Route path='/editndoc262cert'>
<Editndoc262cert/>
</Route>
<Route path='/viewndoc262cert'>
<Viewndoc262cert/>
</Route>
<Route path='/viewndoc262certadmin1'>
<Viewndoc262certadmin1/>
</Route>
<Route path='/viewndoc262certadminlinks'>
<Viewndoc262certadminlinks/>
</Route>
<Route path='/addndoc262cert'>
<Addndoc262cert/>
</Route>
<Route path='/bendoc262annual'>
<Bendoc262annual/>
</Route>
<Route path='/bdeldoc262annual'>
<Bdeldoc262annual/>
</Route>
<Route path='/badddoc262annual'>
<Badddoc262annual/>
</Route>
<Route path='/editndoc262annual'>
<Editndoc262annual/>
</Route>
<Route path='/viewndoc262annual'>
<Viewndoc262annual/>
</Route>
<Route path='/viewndoc262annualadmin1'>
<Viewndoc262annualadmin1/>
</Route>
<Route path='/viewndoc262annualadminlinks'>
<Viewndoc262annualadminlinks/>
</Route>
<Route path='/addndoc262annual'>
<Addndoc262annual/>
</Route>
<Route path='/bendoc311co'>
<Bendoc311co/>
</Route>
<Route path='/bdeldoc311co'>
<Bdeldoc311co/>
</Route>
<Route path='/badddoc311co'>
<Badddoc311co/>
</Route>
<Route path='/editndoc311co'>
<Editndoc311co/>
</Route>
<Route path='/viewndoc311co'>
<Viewndoc311co/>
</Route>
<Route path='/viewndoc311coadmin1'>
<Viewndoc311coadmin1/>
</Route>
<Route path='/viewndoc311coadminlinks'>
<Viewndoc311coadminlinks/>
</Route>
<Route path='/addndoc311co'>
<Addndoc311co/>
</Route>
<Route path='/bendoc312sanction'>
<Bendoc312sanction/>
</Route>
<Route path='/bdeldoc312sanction'>
<Bdeldoc312sanction/>
</Route>
<Route path='/badddoc312sanction'>
<Badddoc312sanction/>
</Route>
<Route path='/editndoc312sanction'>
<Editndoc312sanction/>
</Route>
<Route path='/viewndoc312sanction'>
<Viewndoc312sanction/>
</Route>
<Route path='/viewndoc312sanctionadmin1'>
<Viewndoc312sanctionadmin1/>
</Route>
<Route path='/viewndoc312sanctionadminlinks'>
<Viewndoc312sanctionadminlinks/>
</Route>
<Route path='/addndoc312sanction'>
<Addndoc312sanction/>
</Route>
<Route path='/bendoc312finance'>
<Bendoc312finance/>
</Route>
<Route path='/bdeldoc312finance'>
<Bdeldoc312finance/>
</Route>
<Route path='/badddoc312finance'>
<Badddoc312finance/>
</Route>
<Route path='/editndoc312finance'>
<Editndoc312finance/>
</Route>
<Route path='/viewndoc312finance'>
<Viewndoc312finance/>
</Route>
<Route path='/viewndoc312financeadmin1'>
<Viewndoc312financeadmin1/>
</Route>
<Route path='/viewndoc312financeadminlinks'>
<Viewndoc312financeadminlinks/>
</Route>
<Route path='/addndoc312finance'>
<Addndoc312finance/>
</Route>
<Route path='/bendoc341syllabus'>
<Bendoc341syllabus/>
</Route>
<Route path='/bdeldoc341syllabus'>
<Bdeldoc341syllabus/>
</Route>
<Route path='/badddoc341syllabus'>
<Badddoc341syllabus/>
</Route>
<Route path='/editndoc341syllabus'>
<Editndoc341syllabus/>
</Route>
<Route path='/viewndoc341syllabus'>
<Viewndoc341syllabus/>
</Route>
<Route path='/viewndoc341syllabusadmin1'>
<Viewndoc341syllabusadmin1/>
</Route>
<Route path='/viewndoc341syllabusadminlinks'>
<Viewndoc341syllabusadminlinks/>
</Route>
<Route path='/addndoc341syllabus'>
<Addndoc341syllabus/>
</Route>
<Route path='/bendoc341ethics'>
<Bendoc341ethics/>
</Route>
<Route path='/bdeldoc341ethics'>
<Bdeldoc341ethics/>
</Route>
<Route path='/badddoc341ethics'>
<Badddoc341ethics/>
</Route>
<Route path='/editndoc341ethics'>
<Editndoc341ethics/>
</Route>
<Route path='/viewndoc341ethics'>
<Viewndoc341ethics/>
</Route>
<Route path='/viewndoc341ethicsadmin1'>
<Viewndoc341ethicsadmin1/>
</Route>
<Route path='/viewndoc341ethicsadminlinks'>
<Viewndoc341ethicsadminlinks/>
</Route>
<Route path='/addndoc341ethics'>
<Addndoc341ethics/>
</Route>
<Route path='/bendoc341ethicsmin'>
<Bendoc341ethicsmin/>
</Route>
<Route path='/bdeldoc341ethicsmin'>
<Bdeldoc341ethicsmin/>
</Route>
<Route path='/badddoc341ethicsmin'>
<Badddoc341ethicsmin/>
</Route>
<Route path='/editndoc341ethicsmin'>
<Editndoc341ethicsmin/>
</Route>
<Route path='/viewndoc341ethicsmin'>
<Viewndoc341ethicsmin/>
</Route>
<Route path='/viewndoc341ethicsminadmin1'>
<Viewndoc341ethicsminadmin1/>
</Route>
<Route path='/viewndoc341ethicsminadminlinks'>
<Viewndoc341ethicsminadminlinks/>
</Route>
<Route path='/addndoc341ethicsmin'>
<Addndoc341ethicsmin/>
</Route>
<Route path='/bendoc341research'>
<Bendoc341research/>
</Route>
<Route path='/bdeldoc341research'>
<Bdeldoc341research/>
</Route>
<Route path='/badddoc341research'>
<Badddoc341research/>
</Route>
<Route path='/editndoc341research'>
<Editndoc341research/>
</Route>
<Route path='/viewndoc341research'>
<Viewndoc341research/>
</Route>
<Route path='/viewndoc341researchadmin1'>
<Viewndoc341researchadmin1/>
</Route>
<Route path='/viewndoc341researchadminlinks'>
<Viewndoc341researchadminlinks/>
</Route>
<Route path='/addndoc341research'>
<Addndoc341research/>
</Route>
<Route path='/bendoc341researchmin'>
<Bendoc341researchmin/>
</Route>
<Route path='/bdeldoc341researchmin'>
<Bdeldoc341researchmin/>
</Route>
<Route path='/badddoc341researchmin'>
<Badddoc341researchmin/>
</Route>
<Route path='/editndoc341researchmin'>
<Editndoc341researchmin/>
</Route>
<Route path='/viewndoc341researchmin'>
<Viewndoc341researchmin/>
</Route>
<Route path='/viewndoc341researchminadmin1'>
<Viewndoc341researchminadmin1/>
</Route>
<Route path='/viewndoc341researchminadminlinks'>
<Viewndoc341researchminadminlinks/>
</Route>
<Route path='/addndoc341researchmin'>
<Addndoc341researchmin/>
</Route>
<Route path='/bendoc341bills'>
<Bendoc341bills/>
</Route>
<Route path='/bdeldoc341bills'>
<Bdeldoc341bills/>
</Route>
<Route path='/badddoc341bills'>
<Badddoc341bills/>
</Route>
<Route path='/editndoc341bills'>
<Editndoc341bills/>
</Route>
<Route path='/viewndoc341bills'>
<Viewndoc341bills/>
</Route>
<Route path='/viewndoc341billsadmin1'>
<Viewndoc341billsadmin1/>
</Route>
<Route path='/viewndoc341billsadminlinks'>
<Viewndoc341billsadminlinks/>
</Route>
<Route path='/addndoc341bills'>
<Addndoc341bills/>
</Route>
<Route path='/bendoc342phdaward'>
<Bendoc342phdaward/>
</Route>
<Route path='/bdeldoc342phdaward'>
<Bdeldoc342phdaward/>
</Route>
<Route path='/badddoc342phdaward'>
<Badddoc342phdaward/>
</Route>
<Route path='/editndoc342phdaward'>
<Editndoc342phdaward/>
</Route>
<Route path='/viewndoc342phdaward'>
<Viewndoc342phdaward/>
</Route>
<Route path='/viewndoc342phdawardadmin1'>
<Viewndoc342phdawardadmin1/>
</Route>
<Route path='/viewndoc342phdawardadminlinks'>
<Viewndoc342phdawardadminlinks/>
</Route>
<Route path='/addndoc342phdaward'>
<Addndoc342phdaward/>
</Route>
<Route path='/bendoc342letter'>
<Bendoc342letter/>
</Route>
<Route path='/bdeldoc342letter'>
<Bdeldoc342letter/>
</Route>
<Route path='/badddoc342letter'>
<Badddoc342letter/>
</Route>
<Route path='/editndoc342letter'>
<Editndoc342letter/>
</Route>
<Route path='/viewndoc342letter'>
<Viewndoc342letter/>
</Route>
<Route path='/viewndoc342letteradmin1'>
<Viewndoc342letteradmin1/>
</Route>
<Route path='/viewndoc342letteradminlinks'>
<Viewndoc342letteradminlinks/>
</Route>
<Route path='/addndoc342letter'>
<Addndoc342letter/>
</Route>


    <Route path='/editnaddonc'>
<Editnaddonc/>
</Route>
<Route path='/viewnaddonc'>
<Viewnaddonc/>
</Route>
<Route path='/addnaddonc'>
<Addnaddonc/>
</Route>
    <Route path='/viewai'>
<Viewai/>
</Route>
    <Route path='/addnnallcourses'>
<Addnnallcourses/>
</Route>
    <Route path='/editnnallcourses'>
<Editnnallcourses/>
</Route>
<Route path='/viewnnallcourses'>
<Viewnnallcourses/>
</Route>
    <Route path='/viewnewunivmenufac'>
<Viewnewunivmenufac/>
</Route>
    <Route path='/viewnewunivmenu'>
<Viewnewunivmenu/>
</Route>
    <Route path='/viewnnuexplearningadmin1'>
<Viewnnuexplearningadmin1/>
</Route>
<Route path='/viewnnuexplearningadminlinks'>
<Viewnnuexplearningadminlinks/>
</Route>
    <Route path='/bennuexplearning'>
<Bennuexplearning/>
</Route>
<Route path='/bdelnuexplearning'>
<Bdelnuexplearning/>
</Route>
<Route path='/baddnuexplearning'>
<Baddnuexplearning/>
</Route>
    <Route path='/bennallcourses'>
<Bennallcourses/>
</Route>
<Route path='/bdelnallcourses'>
<Bdelnallcourses/>
</Route>
<Route path='/baddnallcourses'>
<Baddnallcourses/>
</Route>
<Route path='/viewnnallcoursesadmin1'>
<Viewnnallcoursesadmin1/>
</Route>
<Route path='/viewnnallcoursesadminlinks'>
<Viewnnallcoursesadminlinks/>
</Route>
<Route path='/benaddonc'>
<Benaddonc/>
</Route>
<Route path='/bdeladdonc'>
<Bdeladdonc/>
</Route>
<Route path='/baddaddonc'>
<Baddaddonc/>
</Route>
<Route path='/viewnaddoncadmin1'>
<Viewnaddoncadmin1/>
</Route>
<Route path='/viewnaddoncadminlinks'>
<Viewnaddoncadminlinks/>
</Route>
<Route path='/benaddoncstud'>
<Benaddoncstud/>
</Route>
<Route path='/bdeladdoncstud'>
<Bdeladdoncstud/>
</Route>
<Route path='/baddaddoncstud'>
<Baddaddoncstud/>
</Route>
<Route path='/editnaddoncstud'>
<Editnaddoncstud/>
</Route>
<Route path='/viewnaddoncstud'>
<Viewnaddoncstud/>
</Route>
<Route path='/viewnaddoncstudadmin1'>
<Viewnaddoncstudadmin1/>
</Route>
<Route path='/viewnaddoncstudadminlinks'>
<Viewnaddoncstudadminlinks/>
</Route>
<Route path='/addnaddoncstud'>
<Addnaddoncstud/>
</Route>


    <Route path='/viewnemploymentalladmin1'>
<Viewnemploymentalladmin1/>
</Route>
<Route path='/viewnemploymentalladminlinks'>
<Viewnemploymentalladminlinks/>
</Route>
    <Route path='/viewncertificatesadmin1'>
<Viewncertificatesadmin1/>
</Route>
<Route path='/viewncertificatesadminlinks'>
<Viewncertificatesadminlinks/>
</Route>
    <Route path='/viewnconsultancyadmin1'>
<Viewnconsultancyadmin1/>
</Route>
<Route path='/viewnconsultancyadminlinks'>
<Viewnconsultancyadminlinks/>
</Route>
    <Route path='/viewnpatentsadmin1'>
<Viewnpatentsadmin1/>
</Route>
<Route path='/viewnpatentsadminlinks'>
<Viewnpatentsadminlinks/>
</Route>
    <Route path='/viewnbookadmin1'>
<Viewnbookadmin1/>
</Route>
<Route path='/viewnbookadminlinks'>
<Viewnbookadminlinks/>
</Route>
    <Route path='/viewnseminaradmin1'>
<Viewnseminaradmin1/>
</Route>
<Route path='/viewnseminaradminlinks'>
<Viewnseminaradminlinks/>
</Route>
    <Route path='/viewnpublicationsadmin1'>
<Viewnpublicationsadmin1/>
</Route>
<Route path='/viewnpublicationsadminlinks'>
<Viewnpublicationsadminlinks/>
</Route>
    <Route path='/viewnprojectsadminlinks'>
<Viewnprojectsadminlinks/>
</Route>
    <Route path='/viewnprojectsadmin1'>
<Viewnprojectsadmin1/>
</Route>
    <Route path='/viewnbookadmin'>
<Viewnbookadmin/>
</Route>
    <Route path='/bencertificates'>
<Bencertificates/>
</Route>
<Route path='/bdelcertificates'>
<Bdelcertificates/>
</Route>
<Route path='/baddcertificates'>
<Baddcertificates/>
</Route>
<Route path='/editncertificates'>
<Editncertificates/>
</Route>
<Route path='/viewncertificates'>
<Viewncertificates/>
</Route>
<Route path='/viewncertificatesadmin'>
<Viewncertificatesadmin/>
</Route>
<Route path='/viewcertificatesprog'>
<Viewcertificatesprog/>
</Route>
<Route path='/addncertificates'>
<Addncertificates/>
</Route>
<Route path='/addcertificatesbulkn'>
<Addcertificatesbulkn />
</Route>
<Route path='/benemploymentall'>
<Benemploymentall/>
</Route>
<Route path='/bdelemploymentall'>
<Bdelemploymentall/>
</Route>
<Route path='/baddemploymentall'>
<Baddemploymentall/>
</Route>
<Route path='/editnemploymentall'>
<Editnemploymentall/>
</Route>
<Route path='/viewnemploymentall'>
<Viewnemploymentall/>
</Route>
<Route path='/viewnemploymentalladmin'>
<Viewnemploymentalladmin/>
</Route>
<Route path='/viewemploymentallprog'>
<Viewemploymentallprog/>
</Route>
<Route path='/addnemploymentall'>
<Addnemploymentall/>
</Route>
<Route path='/addemploymentallbulkn'>
<Addemploymentallbulkn />
</Route>


    <Route path='/benconsultancy'>
<Benconsultancy/>
</Route>
<Route path='/bdelconsultancy'>
<Bdelconsultancy/>
</Route>
<Route path='/baddconsultancy'>
<Baddconsultancy/>
</Route>

    <Route path='/benpatents'>
<Benpatents/>
</Route>
<Route path='/bdelpatents'>
<Bdelpatents/>
</Route>
<Route path='/baddpatents'>
<Baddpatents/>
</Route>

    <Route path='/benbook'>
<Benbook/>
</Route>
<Route path='/bdelbook'>
<Bdelbook/>
</Route>
<Route path='/baddbook'>
<Baddbook/>
</Route>

    <Route path='/benpublications'>
<Benpublications/>
</Route>
<Route path='/bdelpublications'>
<Bdelpublications/>
</Route>
<Route path='/baddpublications'>
<Baddpublications/>
</Route>
<Route path='/editnpublications'>
<Editnpublications/>
</Route>
<Route path='/viewnpublications'>
<Viewnpublications/>
</Route>
<Route path='/viewnpublicationsadmin'>
<Viewnpublicationsadmin/>
</Route>
<Route path='/viewpublicationsprog'>
<Viewpublicationsprog/>
</Route>
<Route path='/addnpublications'>
<Addnpublications/>
</Route>
<Route path='/addpublicationsbulkn'>
<Addpublicationsbulkn />
</Route>
<Route path='/benseminar'>
<Benseminar/>
</Route>
<Route path='/bdelseminar'>
<Bdelseminar/>
</Route>
<Route path='/baddseminar'>
<Baddseminar/>
</Route>
<Route path='/editnseminar'>
<Editnseminar/>
</Route>
<Route path='/viewnseminar'>
<Viewnseminar/>
</Route>
<Route path='/viewnseminaradmin'>
<Viewnseminaradmin/>
</Route>
<Route path='/viewseminarprog'>
<Viewseminarprog/>
</Route>
<Route path='/addnseminar'>
<Addnseminar/>
</Route>
<Route path='/addseminarbulkn'>
<Addseminarbulkn />
</Route>

    <Route path='/benprojects'>
<Benprojects/>
</Route>
<Route path='/bdelprojects'>
<Bdelprojects/>
</Route>
<Route path='/baddprojects'>
<Baddprojects/>
</Route>
<Route path='/editnprojects'>
<Editnprojects/>
</Route>
<Route path='/viewnprojects'>
<Viewnprojects/>
</Route>
<Route path='/viewnprojectsadmin'>
<Viewnprojectsadmin/>
</Route>
<Route path='/viewprojectsprog'>
<Viewprojectsprog/>
</Route>
<Route path='/addnprojects'>
<Addnprojects/>
</Route>
<Route path='/addprojectsbulkn'>
<Addprojectsbulkn />
</Route>

    <Route path='/baddsalarypaid'>
<Baddsalarypaid/>
</Route>
<Route path='/baddemptravel'>
<Baddemptravel/>
</Route>
    <Route path='/viewnphdfacelinks'>
<Viewnphdfacelinks/>
</Route>
    <Route path='/bensalarypaid'>
<Bensalarypaid/>
</Route>
<Route path='/bdelsalarypaid'>
<Bdelsalarypaid/>
</Route>
<Route path='/editnsalarypaid'>
<Editnsalarypaid/>
</Route>
<Route path='/viewnsalarypaid'>
<Viewnsalarypaid/>
</Route>
<Route path='/viewnsalarypaidadmin'>
<Viewnsalarypaidadmin/>
</Route>
<Route path='/viewsalarypaidprog'>
<Viewsalarypaidprog/>
</Route>
<Route path='/addnsalarypaid'>
<Addnsalarypaid/>
</Route>
<Route path='/addsalarypaidbulkn'>
<Addsalarypaidbulkn />
</Route>
<Route path='/benemptravel'>
<Benemptravel/>
</Route>
<Route path='/bdelemptravel'>
<Bdelemptravel/>
</Route>
<Route path='/editnemptravel'>
<Editnemptravel/>
</Route>
<Route path='/viewnemptravel'>
<Viewnemptravel/>
</Route>
<Route path='/viewnemptraveladmin'>
<Viewnemptraveladmin/>
</Route>
<Route path='/viewemptravelprog'>
<Viewemptravelprog/>
</Route>
<Route path='/addnemptravel'>
<Addnemptravel/>
</Route>
<Route path='/addemptravelbulkn'>
<Addemptravelbulkn />
</Route>


    <Route path='/beadm'>
<Beadm/>
</Route>
    <Route path='/bdadm'>
<Bdadm/>
</Route>
    <Route path='/addsupportingdoc21'>
<Addsupportingdoc21/>
</Route>
    <Route path='/createfile'>
<Createfile/>
</Route>
    <Route path='/addsupportingdoc2f1'>
<Addsupportingdoc2f1/>
</Route>
    <Route path='/assetedit'>
<Assetedit/>
</Route>
    <Route path='/addassetbulk'>
<Addassetbulk/>
</Route>
    <Route path='/selectmetricdoctypea'>
<Selectmetricdoctypea/>
</Route>
<Route path='/alllinksfbymetric'>
<Alllinksfbymetric/>
</Route>
    <Route path='/alllinksf'>
<Alllinksf/>
</Route>
    <Route path='/addsupportingdoc2f'>
<Addsupportingdoc2f/>
</Route>
    <Route path='/selectmetricdoctype'>
<Selectmetricdoctype/>
</Route>
<Route path='/selectawsconfigf'>
<Selectawsconfigf/>
</Route>
  
    <Route path='/assetlistadmin'>
<Assetlistadmin/>
</Route>
    <Route path='/assamannualreport1'>
<Assamannualreport1/>
</Route>
    <Route path='/editngenderaudit'>
<Editngenderaudit/>
</Route>
<Route path='/viewngenderaudit'>
<Viewngenderaudit/>
</Route>
<Route path='/viewngenderauditadmin'>
<Viewngenderauditadmin/>
</Route>
<Route path='/viewgenderauditprog'>
<Viewgenderauditprog/>
</Route>
<Route path='/addngenderaudit'>
<Addngenderaudit/>
</Route>
<Route path='/addgenderauditbulkn'>
<Addgenderauditbulkn />
</Route>


    <Route path='/assamannualreport'>
<Assamannualreport/>
</Route>
    <Route path='/viewnamastuddiff'>
<Viewnamastuddiff/>
</Route>

    <Route path='/viewnamaduplicate'>
<Viewnamaduplicate/>
</Route>

    <Route path='/viewnamastudnewbyuniv'>
<Viewnamastudnewbyuniv/>
</Route>
    <Route path='/editnevguests'>
<Editnevguests/>
</Route>
<Route path='/viewnevguests'>
<Viewnevguests/>
</Route>
<Route path='/viewnevguestsadmin'>
<Viewnevguestsadmin/>
</Route>
<Route path='/viewevguestsprog'>
<Viewevguestsprog/>
</Route>
<Route path='/addnevguests'>
<Addnevguests/>
</Route>
<Route path='/addevguestsbulkn'>
<Addevguestsbulkn />
</Route>

    <Route path='/editnpayimages'>
<Editnpayimages/>
</Route>
<Route path='/viewnpayimages'>
<Viewnpayimages/>
</Route>
<Route path='/viewnpayimagesadmin'>
<Viewnpayimagesadmin/>
</Route>
<Route path='/viewpayimagesprog'>
<Viewpayimagesprog/>
</Route>
<Route path='/addnpayimages'>
<Addnpayimages/>
</Route>
<Route path='/addpayimagesbulkn'>
<Addpayimagesbulkn />
</Route>
<Route path='/editnalumnipay'>
<Editnalumnipay/>
</Route>
<Route path='/viewnalumnipay'>
<Viewnalumnipay/>
</Route>
<Route path='/viewnalumnipayadmin'>
<Viewnalumnipayadmin/>
</Route>
<Route path='/viewalumnipayprog'>
<Viewalumnipayprog/>
</Route>
<Route path='/addnalumnipay'>
<Addnalumnipay/>
</Route>
<Route path='/addalumnipaybulkn'>
<Addalumnipaybulkn />
</Route>


    <Route path='/thankyouaftermob'>
<Thankyouaftermob/>
</Route>

    <Route path='/editnprojectsmob'>
<Editnprojectsmob/>
</Route>
<Route path='/viewnprojectsmob'>
<Viewnprojectsmob/>
</Route>
<Route path='/viewnprojectsadminmob'>
<Viewnprojectsadminmob/>
</Route>
<Route path='/addnprojectsmob'>
<Addnprojectsmob/>
</Route>
<Route path='/editnpublicationsmob'>
<Editnpublicationsmob/>
</Route>
<Route path='/viewnpublicationsmob'>
<Viewnpublicationsmob/>
</Route>
<Route path='/viewnpublicationsadminmob'>
<Viewnpublicationsadminmob/>
</Route>
<Route path='/addnpublicationsmob'>
<Addnpublicationsmob/>
</Route>
<Route path='/editnpatentsmob'>
<Editnpatentsmob/>
</Route>
<Route path='/viewnpatentsmob'>
<Viewnpatentsmob/>
</Route>
<Route path='/viewnpatentsadminmob'>
<Viewnpatentsadminmob/>
</Route>
<Route path='/addnpatentsmob'>
<Addnpatentsmob/>
</Route>
<Route path='/editnconsultancymob'>
<Editnconsultancymob/>
</Route>
<Route path='/viewnconsultancymob'>
<Viewnconsultancymob/>
</Route>
<Route path='/viewnconsultancyadminmob'>
<Viewnconsultancyadminmob/>
</Route>
<Route path='/addnconsultancymob'>
<Addnconsultancymob/>
</Route>
<Route path='/editnphdguidemob'>
<Editnphdguidemob/>
</Route>
<Route path='/viewnphdguidemob'>
<Viewnphdguidemob/>
</Route>
<Route path='/viewnphdguideadminmob'>
<Viewnphdguideadminmob/>
</Route>
<Route path='/addnphdguidemob'>
<Addnphdguidemob/>
</Route>
<Route path='/editnteacherfellowmob'>
<Editnteacherfellowmob/>
</Route>
<Route path='/viewnteacherfellowmob'>
<Viewnteacherfellowmob/>
</Route>
<Route path='/viewnteacherfellowadminmob'>
<Viewnteacherfellowadminmob/>
</Route>
<Route path='/addnteacherfellowmob'>
<Addnteacherfellowmob/>
</Route>


    <Route path='/editncocal'>
<Editncocal/>
</Route>
<Route path='/viewncocal'>
<Viewncocal/>
</Route>
<Route path='/viewncocaladmin'>
<Viewncocaladmin/>
</Route>
<Route path='/viewcocalprog'>
<Viewcocalprog/>
</Route>
<Route path='/addncocal'>
<Addncocal/>
</Route>
<Route path='/addcocalbulkn'>
<Addcocalbulkn />
</Route>


    <Route path='/ocrtest35mob'>
<Ocrtest35mob/>
</Route>
<Route path='/selectawsconfignewmob'>
<Selectawsconfignewmob/>
</Route>

    <Route path='/viewsupportingdocquerymob'>
<Viewsupportingdocquerymob/>
</Route>
    <Route path='/selectbrowseradmin'>
<Selectbrowseradmin/>
</Route>
<Route path='/dashadminmob'>
<Dashadminmob/>
</Route>

    <Route path='/editnseminarmob'>
<Editnseminarmob/>
</Route>
<Route path='/viewnseminarmob'>
<Viewnseminarmob/>
</Route>
<Route path='/viewnseminaradminmob'>
<Viewnseminaradminmob/>
</Route>
<Route path='/addnseminarmob'>
<Addnseminarmob/>
</Route>

    <Route path='/viewnhigherexamlinks'>
<Viewnhigherexamlinks/>
</Route>
    <Route path='/editnfeedback'>
<Editnfeedback/>
</Route>
<Route path='/viewnfeedback'>
<Viewnfeedback/>
</Route>
<Route path='/viewnfeedbackadmin'>
<Viewnfeedbackadmin/>
</Route>
<Route path='/viewfeedbackprog'>
<Viewfeedbackprog/>
</Route>
<Route path='/addnfeedback'>
<Addnfeedback/>
</Route>
<Route path='/addfeedbackbulkn'>
<Addfeedbackbulkn />
</Route>

    <Route path='/viewnhigheredulinks'>
<Viewnhigheredulinks/>
</Route>
    <Route path='/viewnustudschsplinks'>
<Viewnustudschsplinks/>
</Route>
    <Route path='/viewnnuefacilitieslinks'>
<Viewnnuefacilitieslinks/>
</Route>
    <Route path='/editnbookmob'>
<Editnbookmob/>
</Route>
<Route path='/viewnbookmob'>
<Viewnbookmob/>
</Route>
<Route path='/viewnbookadminmob'>
<Viewnbookadminmob/>
</Route>
<Route path='/addnbookmob'>
<Addnbookmob/>
</Route>


    <Route path='/dashfacmob'>
<Dashfacmob/>
</Route>
<Route path='/selectbrowser'>
<Selectbrowser/>
</Route>

    <Route path='/selectawsconfigmob'>
<Selectawsconfigmob/>
</Route>
<Route path='/addsupportingdoc2mob'>
<Addsupportingdoc2mob/>
</Route>

    <Route path='/viewnbooksadminlinks'>
<Viewnbooksadminlinks/>
</Route>
    <Route path='/editnbook'>
<Editnbook/>
</Route>
<Route path='/viewnbook'>
<Viewnbook/>
</Route>
<Route path='/viewnbookalladmin'>
<Viewnbookadmin/>
</Route>
<Route path='/viewbookprog'>
<Viewbookprog/>
</Route>
<Route path='/addnbook'>
<Addnbook/>
</Route>
<Route path='/addbookbulkn'>
<Addbookbulkn />
</Route>



    <Route path='/editnadmusers'>
<Editnadmusers/>
</Route>
<Route path='/viewnadmusers'>
<Viewnadmusers/>
</Route>
<Route path='/viewnadmusersadmin'>
<Viewnadmusersadmin/>
</Route>
<Route path='/viewadmusersprog'>
<Viewadmusersprog/>
</Route>
<Route path='/addnadmusers'>
<Addnadmusers/>
</Route>
<Route path='/addadmusersbulkn'>
<Addadmusersbulkn />
</Route>
<Route path='/editnadmedu'>
<Editnadmedu/>
</Route>
<Route path='/viewnadmedu'>
<Viewnadmedu/>
</Route>
<Route path='/viewnadmeduadmin'>
<Viewnadmeduadmin/>
</Route>
<Route path='/viewadmeduprog'>
<Viewadmeduprog/>
</Route>
<Route path='/addnadmedu'>
<Addnadmedu/>
</Route>
<Route path='/addadmedubulkn'>
<Addadmedubulkn />
</Route>
<Route path='/editnadmwork'>
<Editnadmwork/>
</Route>
<Route path='/viewnadmwork'>
<Viewnadmwork/>
</Route>
<Route path='/viewnadmworkadmin'>
<Viewnadmworkadmin/>
</Route>
<Route path='/viewadmworkprog'>
<Viewadmworkprog/>
</Route>
<Route path='/addnadmwork'>
<Addnadmwork/>
</Route>
<Route path='/addadmworkbulkn'>
<Addadmworkbulkn />
</Route>
<Route path='/editnadmprograms'>
<Editnadmprograms/>
</Route>
<Route path='/viewnadmprograms'>
<Viewnadmprograms/>
</Route>
<Route path='/viewnadmprogramsadmin'>
<Viewnadmprogramsadmin/>
</Route>
<Route path='/viewadmprogramsprog'>
<Viewadmprogramsprog/>
</Route>
<Route path='/addnadmprograms'>
<Addnadmprograms/>
</Route>
<Route path='/addadmprogramsbulkn'>
<Addadmprogramsbulkn />
</Route>
<Route path='/editnadmintern'>
<Editnadmintern/>
</Route>
<Route path='/viewnadmintern'>
<Viewnadmintern/>
</Route>
<Route path='/viewnadminternadmin'>
<Viewnadminternadmin/>
</Route>
<Route path='/viewadminternprog'>
<Viewadminternprog/>
</Route>
<Route path='/addnadmintern'>
<Addnadmintern/>
</Route>
<Route path='/addadminternbulkn'>
<Addadminternbulkn />
</Route>
<Route path='/editnadmextra'>
<Editnadmextra/>
</Route>
<Route path='/viewnadmextra'>
<Viewnadmextra/>
</Route>
<Route path='/viewnadmextraadmin'>
<Viewnadmextraadmin/>
</Route>
<Route path='/viewadmextraprog'>
<Viewadmextraprog/>
</Route>
<Route path='/addnadmextra'>
<Addnadmextra/>
</Route>
<Route path='/addadmextrabulkn'>
<Addadmextrabulkn />
</Route>
<Route path='/editnadmlanguages'>
<Editnadmlanguages/>
</Route>
<Route path='/viewnadmlanguages'>
<Viewnadmlanguages/>
</Route>
<Route path='/viewnadmlanguagesadmin'>
<Viewnadmlanguagesadmin/>
</Route>
<Route path='/viewadmlanguagesprog'>
<Viewadmlanguagesprog/>
</Route>
<Route path='/addnadmlanguages'>
<Addnadmlanguages/>
</Route>
<Route path='/addadmlanguagesbulkn'>
<Addadmlanguagesbulkn />
</Route>
<Route path='/editnadmmarks'>
<Editnadmmarks/>
</Route>
<Route path='/viewnadmmarks'>
<Viewnadmmarks/>
</Route>
<Route path='/viewnadmmarksadmin'>
<Viewnadmmarksadmin/>
</Route>
<Route path='/viewadmmarksprog'>
<Viewadmmarksprog/>
</Route>
<Route path='/addnadmmarks'>
<Addnadmmarks/>
</Route>
<Route path='/addadmmarksbulkn'>
<Addadmmarksbulkn />
</Route>
<Route path='/editnadmfacilities'>
<Editnadmfacilities/>
</Route>
<Route path='/viewnadmfacilities'>
<Viewnadmfacilities/>
</Route>
<Route path='/viewnadmfacilitiesadmin'>
<Viewnadmfacilitiesadmin/>
</Route>
<Route path='/viewadmfacilitiesprog'>
<Viewadmfacilitiesprog/>
</Route>
<Route path='/addnadmfacilities'>
<Addnadmfacilities/>
</Route>
<Route path='/addadmfacilitiesbulkn'>
<Addadmfacilitiesbulkn />
</Route>


    <Route path='/dashadm1'>
<Dashadm1/>
</Route>
<Route path='/selectawsconfigadm1'>
<Selectawsconfigadm1/>
</Route>
<Route path='/addphotoadm'>
<Addphotoadm/>
</Route>

    <Route path='/editnawsconfig'>
<Editnawsconfig/>
</Route>
<Route path='/viewnawsconfig'>
<Viewnawsconfig/>
</Route>
<Route path='/viewnawsconfigadmin'>
<Viewnawsconfigadmin/>
</Route>
<Route path='/viewawsconfigprog'>
<Viewawsconfigprog/>
</Route>
<Route path='/addnawsconfig'>
<Addnawsconfig/>
</Route>
<Route path='/addawsconfigbulkn'>
<Addawsconfigbulkn />
</Route>

    <Route path='/editnmsusancseatn'>
<Editnmsusancseatn/>
</Route>
<Route path='/viewnmsusancseatn'>
<Viewnmsusancseatn/>
</Route>
<Route path='/viewnmsusancseatnadmin'>
<Viewnmsusancseatnadmin/>
</Route>
<Route path='/viewmsusancseatnprog'>
<Viewmsusancseatnprog/>
</Route>
<Route path='/addnmsusancseatn'>
<Addnmsusancseatn/>
</Route>
<Route path='/addmsusancseatnbulkn'>
<Addmsusancseatnbulkn />
</Route>

    <Route path='/updatephone'>
<Updatephone/>
</Route>
    <Route path='/addinstitutionself'>
<Addinstitutionself/>
</Route>
    <Route path='/addinstitutionnew'>
<Addinstitutionnew/>
</Route>
    <Route path='/viewastudcont'>
<Viewastudcont/>
</Route>
    <Route path='/selectaccreditation'>
<Selectaccreditation/>
</Route>
    <Route path='/viewnamastudshort'>
<Viewnamastudshort/>
</Route>
    <Route path='/viewnamastudc'>
<Viewnamastudc/>
</Route>
    <Route path='/editnuser'>
<Editnuser/>
</Route>
<Route path='/viewnuser'>
<Viewnuser/>
</Route>
<Route path='/viewnuseradmin'>
<Viewnuseradmin/>
</Route>
<Route path='/viewuserprog'>
<Viewuserprog/>
</Route>
<Route path='/addnuser'>
<Addnuser/>
</Route>
<Route path='/adduserbulkn'>
<Adduserbulkn />
</Route>


    <Route path='/editnrmsutrustdata'>
<Editnrmsutrustdata/>
</Route>
<Route path='/viewnrmsutrustdata'>
<Viewnrmsutrustdata/>
</Route>
<Route path='/viewnrmsutrustdataadmin'>
<Viewnrmsutrustdataadmin/>
</Route>
<Route path='/viewrmsutrustdataprog'>
<Viewrmsutrustdataprog/>
</Route>
<Route path='/addnrmsutrustdata'>
<Addnrmsutrustdata/>
</Route>
<Route path='/addrmsutrustdatabulkn'>
<Addrmsutrustdatabulkn />
</Route>



    <Route path='/editnrmsucoldeta'>
<Editnrmsucoldeta/>
</Route>
<Route path='/viewnrmsucoldeta'>
<Viewnrmsucoldeta/>
</Route>
<Route path='/viewnrmsucoldetaadmin'>
<Viewnrmsucoldetaadmin/>
</Route>
<Route path='/viewrmsucoldetaprog'>
<Viewrmsucoldetaprog/>
</Route>
<Route path='/addnrmsucoldeta'>
<Addnrmsucoldeta/>
</Route>
<Route path='/addrmsucoldetabulkn'>
<Addrmsucoldetabulkn />
</Route>
<Route path='/editnrmsunirf'>
<Editnrmsunirf/>
</Route>
<Route path='/viewnrmsunirf'>
<Viewnrmsunirf/>
</Route>
<Route path='/viewnrmsunirfadmin'>
<Viewnrmsunirfadmin/>
</Route>
<Route path='/viewrmsunirfprog'>
<Viewrmsunirfprog/>
</Route>
<Route path='/addnrmsunirf'>
<Addnrmsunirf/>
</Route>
<Route path='/addrmsunirfbulkn'>
<Addrmsunirfbulkn />
</Route>




    <Route path='/editnaffcolprog'>
<Editnaffcolprog/>
</Route>
<Route path='/viewnaffcolprog'>
<Viewnaffcolprog/>
</Route>
<Route path='/viewnaffcolprogadmin'>
<Viewnaffcolprogadmin/>
</Route>
<Route path='/viewaffcolprogprog'>
<Viewaffcolprogprog/>
</Route>
<Route path='/addnaffcolprog'>
<Addnaffcolprog/>
</Route>
<Route path='/addaffcolprogbulkn'>
<Addaffcolprogbulkn />
</Route>
<Route path='/editnaffproglist'>
<Editnaffproglist/>
</Route>
<Route path='/viewnaffproglist'>
<Viewnaffproglist/>
</Route>
<Route path='/viewnaffproglistadmin'>
<Viewnaffproglistadmin/>
</Route>
<Route path='/viewaffproglistprog'>
<Viewaffproglistprog/>
</Route>
<Route path='/addnaffproglist'>
<Addnaffproglist/>
</Route>
<Route path='/addaffproglistbulkn'>
<Addaffproglistbulkn />
</Route>
<Route path='/editnInstitutions'>
<EditnInstitutions/>
</Route>
<Route path='/viewnInstitutions'>
<ViewnInstitutions/>
</Route>
<Route path='/viewnInstitutionsadmin'>
<ViewnInstitutionsadmin/>
</Route>
<Route path='/viewInstitutionsprog'>
<ViewInstitutionsprog/>
</Route>
<Route path='/addnInstitutions'>
<AddnInstitutions/>
</Route>
<Route path='/addInstitutionsbulkn'>
<AddInstitutionsbulkn />
</Route>




    <Route path='/editnamastudnew'>
<Editnamastudnew/>
</Route>
<Route path='/viewnamastudnew'>
<Viewnamastudnew/>
</Route>
<Route path='/viewnamastudnewadmin'>
<Viewnamastudnewadmin/>
</Route>
<Route path='/viewamastudnewprog'>
<Viewamastudnewprog/>
</Route>
<Route path='/addnamastudnew'>
<Addnamastudnew/>
</Route>
<Route path='/addamastudnewbulkn'>
<Addamastudnewbulkn />
</Route>


{/* 
    <Route path='/editnanmstudnew'>
<Editnanmstudnew/>
</Route>
<Route path='/viewnanmstudnew'>
<Viewnanmstudnew/>
</Route>
<Route path='/viewnanmstudnewadmin'>
<Viewnanmstudnewadmin/>
</Route>
<Route path='/viewanmstudnewprog'>
<Viewanmstudnewprog/>
</Route>
<Route path='/addnanmstudnew'>
<Addnanmstudnew/>
</Route>
<Route path='/addanmstudnewbulkn'>
<Addanmstudnewbulkn />
</Route> */}


    <Route path='/viewnmkumarksheet'>
<Viewnmkumarksheet/>
</Route>



    <Route path='/editnmkustud'>
<Editnmkustud/>
</Route>
<Route path='/viewnmkustud'>
<Viewnmkustud/>
</Route>
<Route path='/viewnmkustudadmin'>
<Viewnmkustudadmin/>
</Route>
<Route path='/viewmkustudprog'>
<Viewmkustudprog/>
</Route>
<Route path='/addnmkustud'>
<Addnmkustud/>
</Route>
<Route path='/addmkustudbulkn'>
<Addmkustudbulkn />
</Route>
<Route path='/editnmkumarks'>
<Editnmkumarks/>
</Route>
<Route path='/viewnmkumarks'>
<Viewnmkumarks/>
</Route>
<Route path='/viewnmkumarksadmin'>
<Viewnmkumarksadmin/>
</Route>
<Route path='/viewmkumarksprog'>
<Viewmkumarksprog/>
</Route>
<Route path='/addnmkumarks'>
<Addnmkumarks/>
</Route>
<Route path='/addmkumarksbulkn'>
<Addmkumarksbulkn />
</Route>


    <Route path='/editnaffassign'>
<Editnaffassign/>
</Route>
<Route path='/viewnaffassign'>
<Viewnaffassign/>
</Route>
<Route path='/viewnaffassignadmin'>
<Viewnaffassignadmin/>
</Route>
<Route path='/viewaffassignprog'>
<Viewaffassignprog/>
</Route>
<Route path='/addnaffassign'>
<Addnaffassign/>
</Route>
<Route path='/addaffassignbulkn'>
<Addaffassignbulkn />
</Route>


    <Route path='/editnassignment'>
<Editnassignment/>
</Route>
<Route path='/viewnassignment'>
<Viewnassignment/>
</Route>
<Route path='/viewnassignmentadmin'>
<Viewnassignmentadmin/>
</Route>
<Route path='/viewassignmentprog'>
<Viewassignmentprog/>
</Route>
<Route path='/addnassignment'>
<Addnassignment/>
</Route>
<Route path='/addassignmentbulkn'>
<Addassignmentbulkn />
</Route>
    <Route path='/viewnninvoicebill'>
<Viewnninvoicebill/>
</Route>
<Route path='/viewnninvoicereceipt'>
<Viewnninvoicereceipt/>
</Route>
    <Route path='/viewnmodulesstud'>
<Viewnmodulesstud/>
</Route>
    <Route path='/editnsssstud'>
<Editnsssstud/>
</Route>
<Route path='/viewnsssstud'>
<Viewnsssstud/>
</Route>
<Route path='/viewnsssstudadmin'>
<Viewnsssstudadmin/>
</Route>
<Route path='/viewsssstudprog'>
<Viewsssstudprog/>
</Route>
<Route path='/addnsssstud'>
<Addnsssstud/>
</Route>
<Route path='/addsssstudbulkn'>
<Addsssstudbulkn />
</Route>

    <Route path='/viewnticketsallstatus'>
<Viewnticketsallstatus/>
</Route>
    <Route path='/viewnticketsallclosed'>
<Viewnticketsallclosed/>
</Route>
    <Route path='/ocrtest35remote'>
<Ocrtest35remote/>
</Route>
    <Route path='/ocrtest35'>
<Ocrtest35/>
</Route>
    <Route path='/viewcnewfeedbackusers'>
<Viewcnewfeedackusers/>
</Route>
    <Route path='/editncnewfeedback'>
<Editncnewfeedback/>
</Route>
<Route path='/viewncnewfeedback'>
<Viewncnewfeedback/>
</Route>
<Route path='/viewncnewfeedbackadmin'>
<Viewncnewfeedbackadmin/>
</Route>
<Route path='/viewcnewfeedbackprog'>
<Viewcnewfeedbackprog/>
</Route>
<Route path='/addncnewfeedback'>
<Addncnewfeedback/>
</Route>
<Route path='/addcnewfeedbackbulkn'>
<Addcnewfeedbackbulkn />
</Route>

    <Route path='/editnpfiles'>
<Editnpfiles/>
</Route>
<Route path='/viewnpfiles'>
<Viewnpfiles/>
</Route>
<Route path='/viewnpfilesadmin'>
<Viewnpfilesadmin/>
</Route>
<Route path='/viewpfilesprog'>
<Viewpfilesprog/>
</Route>
<Route path='/addnpfiles'>
<Addnpfiles/>
</Route>
<Route path='/addpfilesbulkn'>
<Addpfilesbulkn />
</Route>
<Route path='/editnpdeliverables'>
<Editnpdeliverables/>
</Route>
<Route path='/viewnpdeliverables'>
<Viewnpdeliverables/>
</Route>
<Route path='/viewnpdeliverablesadmin'>
<Viewnpdeliverablesadmin/>
</Route>
<Route path='/viewpdeliverablesprog'>
<Viewpdeliverablesprog/>
</Route>
<Route path='/addnpdeliverables'>
<Addnpdeliverables/>
</Route>
<Route path='/addpdeliverablesbulkn'>
<Addpdeliverablesbulkn />
</Route>


    <Route path='/alumniregister'>
<Alumniregister/>
</Route>
    <Route path='/selectawsconfigalumni'>
<Selectawsconfigalumni/>
</Route>
<Route path='/addphotoalumni'>
<Addphotoalumni/>
</Route>
    <Route path='/dashalumni1'>
<Dashalumni1/>
</Route>
    <Route path='/editnalumniusers'>
<Editnalumniusers/>
</Route>
<Route path='/viewnalumniusers'>
<Viewnalumniusers/>
</Route>
<Route path='/viewnalumniusersadmin'>
<Viewnalumniusersadmin/>
</Route>
<Route path='/viewalumniusersprog'>
<Viewalumniusersprog/>
</Route>
<Route path='/addnalumniusers'>
<Addnalumniusers/>
</Route>
<Route path='/addalumniusersbulkn'>
<Addalumniusersbulkn />
</Route>
<Route path='/editnalumniwork'>
<Editnalumniwork/>
</Route>
<Route path='/viewnalumniwork'>
<Viewnalumniwork/>
</Route>
<Route path='/viewnalumniworkadmin'>
<Viewnalumniworkadmin/>
</Route>
<Route path='/viewalumniworkprog'>
<Viewalumniworkprog/>
</Route>
<Route path='/addnalumniwork'>
<Addnalumniwork/>
</Route>
<Route path='/addalumniworkbulkn'>
<Addalumniworkbulkn />
</Route>
<Route path='/editnalumnihighered'>
<Editnalumnihighered/>
</Route>
<Route path='/viewnalumnihighered'>
<Viewnalumnihighered/>
</Route>
<Route path='/viewnalumnihigheredadmin'>
<Viewnalumnihigheredadmin/>
</Route>
<Route path='/viewalumnihigheredprog'>
<Viewalumnihigheredprog/>
</Route>
<Route path='/addnalumnihighered'>
<Addnalumnihighered/>
</Route>
<Route path='/addalumnihigheredbulkn'>
<Addalumnihigheredbulkn />
</Route>
<Route path='/editnalumnicontribute'>
<Editnalumnicontribute/>
</Route>
<Route path='/viewnalumnicontribute'>
<Viewnalumnicontribute/>
</Route>
<Route path='/viewnalumnicontributeadmin'>
<Viewnalumnicontributeadmin/>
</Route>
<Route path='/viewalumnicontributeprog'>
<Viewalumnicontributeprog/>
</Route>
<Route path='/addnalumnicontribute'>
<Addnalumnicontribute/>
</Route>
<Route path='/addalumnicontributebulkn'>
<Addalumnicontributebulkn />
</Route>
{/* <Route path='/editnalumnijobs'>
<Editnalumnijobs/>
</Route>
<Route path='/viewnalumnijobs'>
<Viewnalumnijobs/>
</Route>
<Route path='/viewnalumnijobsadmin'>
<Viewnalumnijobsadmin/>
</Route>
<Route path='/viewalumnijobsprog'>
<Viewalumnijobsprog/>
</Route>
<Route path='/addnalumnijobs'>
<Addnalumnijobs/>
</Route>
<Route path='/addalumnijobsbulkn'>
<Addalumnijobsbulkn />
</Route> */}
<Route path='/editnalumnievents'>
<Editnalumnievents/>
</Route>
<Route path='/viewnalumnievents'>
<Viewnalumnievents/>
</Route>
<Route path='/viewnalumnieventsadmin'>
<Viewnalumnieventsadmin/>
</Route>
<Route path='/viewalumnieventsprog'>
<Viewalumnieventsprog/>
</Route>
<Route path='/addnalumnievents'>
<Addnalumnievents/>
</Route>
<Route path='/addalumnieventsbulkn'>
<Addalumnieventsbulkn />
</Route>

    <Route path='/editnstudmentor'>
<Editnstudmentor/>
</Route>
<Route path='/viewnstudmentor'>
<Viewnstudmentor/>
</Route>
<Route path='/viewnstudmentoradmin'>
<Viewnstudmentoradmin/>
</Route>
<Route path='/viewstudmentorprog'>
<Viewstudmentorprog/>
</Route>
<Route path='/addnstudmentor'>
<Addnstudmentor/>
</Route>
<Route path='/addstudmentorbulkn'>
<Addstudmentorbulkn />
</Route>
<Route path='/editnlphdfac'>
<Editnlphdfac/>
</Route>
<Route path='/viewnlphdfac'>
<Viewnlphdfac/>
</Route>
<Route path='/viewnlphdfacadmin'>
<Viewnlphdfacadmin/>
</Route>
<Route path='/viewlphdfacprog'>
<Viewlphdfacprog/>
</Route>
<Route path='/addnlphdfac'>
<Addnlphdfac/>
</Route>
<Route path='/addlphdfacbulkn'>
<Addlphdfacbulkn />
</Route>


    <Route path='/viewlegalunimenuadmin'>
<Viewlegalunimenuadmin/>
</Route>
<Route path='/viewnewuniversitymenuadmin'>
<Viewnewuniversitymenuadmin/>
</Route>
<Route path='/viewaffiliatedmenuadmin'>
<Viewaffiliatedmenuadmin/>
</Route>
<Route path='/viewautonomousmenuadmin'>
<Viewautonomousmenuadmin/>
</Route>

    <Route path='/anamolystudmaster'>
<Anamolystudmaster/>
</Route>
    <Route path='/editnstudmasterall'>
<Editnstudmasterall/>
</Route>
<Route path='/viewnstudmasterall'>
<Viewnstudmasterall/>
</Route>
<Route path='/viewnstudmasteralladmin'>
<Viewnstudmasteralladmin/>
</Route>
<Route path='/viewstudmasterallprog'>
<Viewstudmasterallprog/>
</Route>
<Route path='/addnstudmasterall'>
<Addnstudmasterall/>
</Route>
<Route path='/addstudmasterallbulkn'>
<Addstudmasterallbulkn />
</Route>
<Route path='/editninnovationnew'>
<Editninnovationnew/>
</Route>
<Route path='/viewninnovationnew'>
<Viewninnovationnew/>
</Route>
<Route path='/viewninnovationnewadmin'>
<Viewninnovationnewadmin/>
</Route>
<Route path='/viewinnovationnewprog'>
<Viewinnovationnewprog/>
</Route>
<Route path='/addninnovationnew'>
<Addninnovationnew/>
</Route>
<Route path='/addinnovationnewbulkn'>
<Addinnovationnewbulkn />
</Route>
<Route path='/editnexpenditurenew'>
<Editnexpenditurenew/>
</Route>
<Route path='/viewnexpenditurenew'>
<Viewnexpenditurenew/>
</Route>
<Route path='/viewnexpenditurenewadmin'>
<Viewnexpenditurenewadmin/>
</Route>
<Route path='/viewexpenditurenewprog'>
<Viewexpenditurenewprog/>
</Route>
<Route path='/addnexpenditurenew'>
<Addnexpenditurenew/>
</Route>
<Route path='/addexpenditurenewbulkn'>
<Addexpenditurenewbulkn />
</Route>
<Route path='/editnqualitynew'>
<Editnqualitynew/>
</Route>
<Route path='/viewnqualitynew'>
<Viewnqualitynew/>
</Route>
<Route path='/viewnqualitynewadmin'>
<Viewnqualitynewadmin/>
</Route>
<Route path='/viewqualitynewprog'>
<Viewqualitynewprog/>
</Route>
<Route path='/addnqualitynew'>
<Addnqualitynew/>
</Route>
<Route path='/addqualitynewbulkn'>
<Addqualitynewbulkn />
</Route>
<Route path='/editnlquality'>
<Editnlquality/>
</Route>
<Route path='/viewnlquality'>
<Viewnlquality/>
</Route>
<Route path='/viewnlqualityadmin'>
<Viewnlqualityadmin/>
</Route>
<Route path='/viewlqualityprog'>
<Viewlqualityprog/>
</Route>
<Route path='/addnlquality'>
<Addnlquality/>
</Route>
<Route path='/addlqualitybulkn'>
<Addlqualitybulkn />
</Route>
<Route path='/editnuquality'>
<Editnuquality/>
</Route>
<Route path='/viewnuquality'>
<Viewnuquality/>
</Route>
<Route path='/viewnuqualityadmin'>
<Viewnuqualityadmin/>
</Route>
<Route path='/viewuqualityprog'>
<Viewuqualityprog/>
</Route>
<Route path='/addnuquality'>
<Addnuquality/>
</Route>
<Route path='/adduqualitybulkn'>
<Adduqualitybulkn />
</Route>
<Route path='/editnstudschspnew'>
<Editnstudschspnew/>
</Route>
<Route path='/viewnstudschspnew'>
<Viewnstudschspnew/>
</Route>
<Route path='/viewnstudschspnewadmin'>
<Viewnstudschspnewadmin/>
</Route>
<Route path='/viewstudschspnewprog'>
<Viewstudschspnewprog/>
</Route>
<Route path='/addnstudschspnew'>
<Addnstudschspnew/>
</Route>
<Route path='/addstudschspnewbulkn'>
<Addstudschspnewbulkn />
</Route>
<Route path='/editnustudschsp'>
<Editnustudschsp/>
</Route>
<Route path='/viewnustudschsp'>
<Viewnustudschsp/>
</Route>
<Route path='/viewnustudschspadmin'>
<Viewnustudschspadmin/>
</Route>
<Route path='/viewustudschspprog'>
<Viewustudschspprog/>
</Route>
<Route path='/addnustudschsp'>
<Addnustudschsp/>
</Route>
<Route path='/addustudschspbulkn'>
<Addustudschspbulkn />
</Route>

    <Route path='/editnnprojectsnew'>
<Editnnprojectsnew/>
</Route>
<Route path='/viewnnprojectsnew'>
<Viewnnprojectsnew/>
</Route>
<Route path='/viewnnprojectsnewadmin'>
<Viewnnprojectsnewadmin/>
</Route>
<Route path='/viewnprojectsnewprog'>
<Viewnprojectsnewprog/>
</Route>
<Route path='/addnnprojectsnew'>
<Addnnprojectsnew/>
</Route>
<Route path='/addnprojectsnewbulkn'>
<Addnprojectsnewbulkn />
</Route>

    <Route path='/editnnstatus'>
<Editnnstatus/>
</Route>
<Route path='/viewnnstatus'>
<Viewnnstatus/>
</Route>
<Route path='/viewnnstatusadmin'>
<Viewnnstatusadmin/>
</Route>
<Route path='/viewnstatusprog'>
<Viewnstatusprog/>
</Route>
<Route path='/addnnstatus'>
<Addnnstatus/>
</Route>
<Route path='/addnstatusbulkn'>
<Addnstatusbulkn />
</Route>
<Route path='/editnnprojects'>
<Editnnprojects/>
</Route>
<Route path='/viewnnprojects'>
<Viewnnprojects/>
</Route>
<Route path='/viewnnprojectsadmin'>
<Viewnnprojectsadmin/>
</Route>
<Route path='/viewnprojectsprog'>
<Viewnprojectsprog/>
</Route>
<Route path='/addnnprojects'>
<Addnnprojects/>
</Route>
<Route path='/addnprojectsbulkn'>
<Addnprojectsbulkn />
</Route>

    <Route path='/editnninvoicefnew'>
<Editnninvoicefnew/>
</Route>
<Route path='/viewnninvoicefnew'>
<Viewnninvoicefnew/>
</Route>
<Route path='/viewnninvoicefnewadmin'>
<Viewnninvoicefnewadmin/>
</Route>
<Route path='/viewninvoicefnewprog'>
<Viewninvoicefnewprog/>
</Route>
<Route path='/addnninvoicefnew'>
<Addnninvoicefnew/>
</Route>
<Route path='/addninvoicefnewbulkn'>
<Addninvoicefnewbulkn />
</Route>
<Route path='/editnnorgdetails'>
<Editnnorgdetails/>
</Route>
<Route path='/viewnnorgdetails'>
<Viewnnorgdetails/>
</Route>
<Route path='/viewnnorgdetailsadmin'>
<Viewnnorgdetailsadmin/>
</Route>
<Route path='/viewnorgdetailsprog'>
<Viewnorgdetailsprog/>
</Route>
<Route path='/addnnorgdetails'>
<Addnnorgdetails/>
</Route>
<Route path='/addnorgdetailsbulkn'>
<Addnorgdetailsbulkn />
</Route>


    <Route path='/adminautonomousnew'>
<Adminautonomousnew/>
</Route>
    <Route path='/adminlegalnew'>
<Adminlegalnew/>
</Route>
    <Route path='/adminunivapr23'>
<Adminunivapr23/>
</Route>
    <Route path='/adminaffiliatedfeb23'>
<Adminaffiliatedfeb23/>
</Route>
    <Route path='/viewnninvoiceprint'>
<Viewnninvoiceprint/>
</Route>
    <Route path='/editnninvoicefinal'>
<Editnninvoicefinal/>
</Route>
<Route path='/viewnninvoicefinal'>
<Viewnninvoicefinal/>
</Route>
<Route path='/viewnninvoicefinaladmin'>
<Viewnninvoicefinaladmin/>
</Route>
<Route path='/viewninvoicefinalprog'>
<Viewninvoicefinalprog/>
</Route>
<Route path='/addnninvoicefinal'>
<Addnninvoicefinal/>
</Route>
<Route path='/addninvoicefinalbulkn'>
<Addninvoicefinalbulkn />
</Route>
<Route path='/editnninvoiceitems'>
<Editnninvoiceitems/>
</Route>
<Route path='/viewnninvoiceitems'>
<Viewnninvoiceitems/>
</Route>
<Route path='/viewnninvoiceitemsadmin'>
<Viewnninvoiceitemsadmin/>
</Route>
<Route path='/viewninvoiceitemsprog'>
<Viewninvoiceitemsprog/>
</Route>
<Route path='/addnninvoiceitems'>
<Addnninvoiceitems/>
</Route>
<Route path='/addninvoiceitemsbulkn'>
<Addninvoiceitemsbulkn />
</Route>


    <Route path='/viewsupportingdocquery1'>
<Viewsupportingdocquery1/>
</Route>
    <Route path='/viewnticketsforme'>
<Viewnticketsforme/>
</Route>
    <Route path='/viewnticketsall'>
<Viewnticketsall/>
</Route>
<Route path='/editnticketsassign'>
<Editnticketsassign/>
</Route>

<Route path='/editnticketsforme'>
<Editnticketsforme/>
</Route>
    <Route path='/editnvacstudents'>
<Editnvacstudents/>
</Route>
<Route path='/viewnvacstudents'>
<Viewnvacstudents/>
</Route>
<Route path='/viewnvacstudentsadmin'>
<Viewnvacstudentsadmin/>
</Route>
<Route path='/viewvacstudentsprog'>
<Viewvacstudentsprog/>
</Route>
<Route path='/addnvacstudents'>
<Addnvacstudents/>
</Route>
<Route path='/addvacstudentsbulkn'>
<Addvacstudentsbulkn />
</Route>


    <Route path='/editnawardsnew'>
<Editnawardsnew/>
</Route>
<Route path='/viewnawardsnew'>
<Viewnawardsnew/>
</Route>
<Route path='/viewnawardsnewadmin'>
<Viewnawardsnewadmin/>
</Route>
<Route path='/viewawardsnewprog'>
<Viewawardsnewprog/>
</Route>
<Route path='/addnawardsnew'>
<Addnawardsnew/>
</Route>
<Route path='/addawardsnewbulkn'>
<Addawardsnewbulkn />
</Route>

    <Route path='/viewnreservecatadminlinks'>
<Viewnreservecatadminlinks/>
</Route>

    <Route path='/editnhostel'>
<Editnhostel/>
</Route>
<Route path='/viewnhostel'>
<Viewnhostel/>
</Route>
<Route path='/viewnhosteladmin'>
<Viewnhosteladmin/>
</Route>
<Route path='/viewhostelprog'>
<Viewhostelprog/>
</Route>
<Route path='/addnhostel'>
<Addnhostel/>
</Route>
<Route path='/addhostelbulkn'>
<Addhostelbulkn />
</Route>

    <Route path='/editnppuclgdetails'>
<Editnppuclgdetails/>
</Route>
<Route path='/viewnppuclgdetails'>
<Viewnppuclgdetails/>
</Route>
<Route path='/viewnppuclgdetailsadmin'>
<Viewnppuclgdetailsadmin/>
</Route>
<Route path='/viewppuclgdetailsprog'>
<Viewppuclgdetailsprog/>
</Route>
<Route path='/addnppuclgdetails'>
<Addnppuclgdetails/>
</Route>
<Route path='/addppuclgdetailsbulkn'>
<Addppuclgdetailsbulkn />
</Route>
<Route path='/editnppuheaddetails'>
<Editnppuheaddetails/>
</Route>
<Route path='/viewnppuheaddetails'>
<Viewnppuheaddetails/>
</Route>
<Route path='/viewnppuheaddetailsadmin'>
<Viewnppuheaddetailsadmin/>
</Route>
<Route path='/viewppuheaddetailsprog'>
<Viewppuheaddetailsprog/>
</Route>
<Route path='/addnppuheaddetails'>
<Addnppuheaddetails/>
</Route>
<Route path='/addppuheaddetailsbulkn'>
<Addppuheaddetailsbulkn />
</Route>
<Route path='/editnppulegalrep'>
<Editnppulegalrep/>
</Route>
<Route path='/viewnppulegalrep'>
<Viewnppulegalrep/>
</Route>
<Route path='/viewnppulegalrepadmin'>
<Viewnppulegalrepadmin/>
</Route>
<Route path='/viewppulegalrepprog'>
<Viewppulegalrepprog/>
</Route>
<Route path='/addnppulegalrep'>
<Addnppulegalrep/>
</Route>
<Route path='/addppulegalrepbulkn'>
<Addppulegalrepbulkn />
</Route>
<Route path='/editnpputrustdetails'>
<Editnpputrustdetails/>
</Route>
<Route path='/viewnpputrustdetails'>
<Viewnpputrustdetails/>
</Route>
<Route path='/viewnpputrustdetailsadmin'>
<Viewnpputrustdetailsadmin/>
</Route>
<Route path='/viewpputrustdetailsprog'>
<Viewpputrustdetailsprog/>
</Route>
<Route path='/addnpputrustdetails'>
<Addnpputrustdetails/>
</Route>
<Route path='/addpputrustdetailsbulkn'>
<Addpputrustdetailsbulkn />
</Route>
<Route path='/editnppupermission'>
<Editnppupermission/>
</Route>
<Route path='/viewnppupermission'>
<Viewnppupermission/>
</Route>
<Route path='/viewnppupermissionadmin'>
<Viewnppupermissionadmin/>
</Route>
<Route path='/viewppupermissionprog'>
<Viewppupermissionprog/>
</Route>
<Route path='/addnppupermission'>
<Addnppupermission/>
</Route>
<Route path='/addppupermissionbulkn'>
<Addppupermissionbulkn />
</Route>
<Route path='/editnppumanagingbody'>
<Editnppumanagingbody/>
</Route>
<Route path='/viewnppumanagingbody'>
<Viewnppumanagingbody/>
</Route>
<Route path='/viewnppumanagingbodyadmin'>
<Viewnppumanagingbodyadmin/>
</Route>
<Route path='/viewppumanagingbodyprog'>
<Viewppumanagingbodyprog/>
</Route>
<Route path='/addnppumanagingbody'>
<Addnppumanagingbody/>
</Route>
<Route path='/addppumanagingbodybulkn'>
<Addppumanagingbodybulkn />
</Route>
<Route path='/editnppuwlfcomt'>
<Editnppuwlfcomt/>
</Route>
<Route path='/viewnppuwlfcomt'>
<Viewnppuwlfcomt/>
</Route>
<Route path='/viewnppuwlfcomtadmin'>
<Viewnppuwlfcomtadmin/>
</Route>
<Route path='/viewppuwlfcomtprog'>
<Viewppuwlfcomtprog/>
</Route>
<Route path='/addnppuwlfcomt'>
<Addnppuwlfcomt/>
</Route>
<Route path='/addppuwlfcomtbulkn'>
<Addppuwlfcomtbulkn />
</Route>
<Route path='/editnpputrustfin'>
<Editnpputrustfin/>
</Route>
<Route path='/viewnpputrustfin'>
<Viewnpputrustfin/>
</Route>
<Route path='/viewnpputrustfinadmin'>
<Viewnpputrustfinadmin/>
</Route>
<Route path='/viewpputrustfinprog'>
<Viewpputrustfinprog/>
</Route>
<Route path='/addnpputrustfin'>
<Addnpputrustfin/>
</Route>
<Route path='/addpputrustfinbulkn'>
<Addpputrustfinbulkn />
</Route>
<Route path='/editnppufdrdetails'>
<Editnppufdrdetails/>
</Route>
<Route path='/viewnppufdrdetails'>
<Viewnppufdrdetails/>
</Route>
<Route path='/viewnppufdrdetailsadmin'>
<Viewnppufdrdetailsadmin/>
</Route>
<Route path='/viewppufdrdetailsprog'>
<Viewppufdrdetailsprog/>
</Route>
<Route path='/addnppufdrdetails'>
<Addnppufdrdetails/>
</Route>
<Route path='/addppufdrdetailsbulkn'>
<Addppufdrdetailsbulkn />
</Route>
<Route path='/editnppuimmvprop'>
<Editnppuimmvprop/>
</Route>
<Route path='/viewnppuimmvprop'>
<Viewnppuimmvprop/>
</Route>
<Route path='/viewnppuimmvpropadmin'>
<Viewnppuimmvpropadmin/>
</Route>
<Route path='/viewppuimmvpropprog'>
<Viewppuimmvpropprog/>
</Route>
<Route path='/addnppuimmvprop'>
<Addnppuimmvprop/>
</Route>
<Route path='/addppuimmvpropbulkn'>
<Addppuimmvpropbulkn />
</Route>
<Route path='/editnppufinance'>
<Editnppufinance/>
</Route>
<Route path='/viewnppufinance'>
<Viewnppufinance/>
</Route>
<Route path='/viewnppufinanceadmin'>
<Viewnppufinanceadmin/>
</Route>
<Route path='/viewppufinanceprog'>
<Viewppufinanceprog/>
</Route>
<Route path='/addnppufinance'>
<Addnppufinance/>
</Route>
<Route path='/addppufinancebulkn'>
<Addppufinancebulkn />
</Route>
<Route path='/editnppuland'>
<Editnppuland/>
</Route>
<Route path='/viewnppuland'>
<Viewnppuland/>
</Route>
<Route path='/viewnppulandadmin'>
<Viewnppulandadmin/>
</Route>
<Route path='/viewppulandprog'>
<Viewppulandprog/>
</Route>
<Route path='/addnppuland'>
<Addnppuland/>
</Route>
<Route path='/addppulandbulkn'>
<Addppulandbulkn />
</Route>
<Route path='/editnppubuilding'>
<Editnppubuilding/>
</Route>
<Route path='/viewnppubuilding'>
<Viewnppubuilding/>
</Route>
<Route path='/viewnppubuildingadmin'>
<Viewnppubuildingadmin/>
</Route>
<Route path='/viewppubuildingprog'>
<Viewppubuildingprog/>
</Route>
<Route path='/addnppubuilding'>
<Addnppubuilding/>
</Route>
<Route path='/addppubuildingbulkn'>
<Addppubuildingbulkn />
</Route>
<Route path='/editnppuhostel'>
<Editnppuhostel/>
</Route>
<Route path='/viewnppuhostel'>
<Viewnppuhostel/>
</Route>
<Route path='/viewnppuhosteladmin'>
<Viewnppuhosteladmin/>
</Route>
<Route path='/viewppuhostelprog'>
<Viewppuhostelprog/>
</Route>
<Route path='/addnppuhostel'>
<Addnppuhostel/>
</Route>
<Route path='/addppuhostelbulkn'>
<Addppuhostelbulkn />
</Route>
<Route path='/editnppurooms'>
<Editnppurooms/>
</Route>
<Route path='/viewnppurooms'>
<Viewnppurooms/>
</Route>
<Route path='/viewnppuroomsadmin'>
<Viewnppuroomsadmin/>
</Route>
<Route path='/viewppuroomsprog'>
<Viewppuroomsprog/>
</Route>
<Route path='/addnppurooms'>
<Addnppurooms/>
</Route>
<Route path='/addppuroomsbulkn'>
<Addppuroomsbulkn />
</Route>
<Route path='/editnppuphyedu'>
<Editnppuphyedu/>
</Route>
<Route path='/viewnppuphyedu'>
<Viewnppuphyedu/>
</Route>
<Route path='/viewnppuphyeduadmin'>
<Viewnppuphyeduadmin/>
</Route>
<Route path='/viewppuphyeduprog'>
<Viewppuphyeduprog/>
</Route>
<Route path='/addnppuphyedu'>
<Addnppuphyedu/>
</Route>
<Route path='/addppuphyedubulkn'>
<Addppuphyedubulkn />
</Route>
<Route path='/editnppustaff'>
<Editnppustaff/>
</Route>
<Route path='/viewnppustaff'>
<Viewnppustaff/>
</Route>
<Route path='/viewnppustaffadmin'>
<Viewnppustaffadmin/>
</Route>
<Route path='/viewppustaffprog'>
<Viewppustaffprog/>
</Route>
<Route path='/addnppustaff'>
<Addnppustaff/>
</Route>
<Route path='/addppustaffbulkn'>
<Addppustaffbulkn />
</Route>
<Route path='/editnppucollege'>
<Editnppucollege/>
</Route>
<Route path='/viewnppucollege'>
<Viewnppucollege/>
</Route>
<Route path='/viewnppucollegeadmin'>
<Viewnppucollegeadmin/>
</Route>
<Route path='/viewppucollegeprog'>
<Viewppucollegeprog/>
</Route>
<Route path='/addnppucollege'>
<Addnppucollege/>
</Route>
<Route path='/addppucollegebulkn'>
<Addppucollegebulkn />
</Route>
<Route path='/editnpputrust'>
<Editnpputrust/>
</Route>
<Route path='/viewnpputrust'>
<Viewnpputrust/>
</Route>
<Route path='/viewnpputrustadmin'>
<Viewnpputrustadmin/>
</Route>
<Route path='/viewpputrustprog'>
<Viewpputrustprog/>
</Route>
<Route path='/addnpputrust'>
<Addnpputrust/>
</Route>
<Route path='/addpputrustbulkn'>
<Addpputrustbulkn />
</Route>
<Route path='/editnppuaddprog'>
<Editnppuaddprog/>
</Route>
<Route path='/viewnppuaddprog'>
<Viewnppuaddprog/>
</Route>
<Route path='/viewnppuaddprogadmin'>
<Viewnppuaddprogadmin/>
</Route>
<Route path='/viewppuaddprogprog'>
<Viewppuaddprogprog/>
</Route>
<Route path='/addnppuaddprog'>
<Addnppuaddprog/>
</Route>
<Route path='/addppuaddprogbulkn'>
<Addppuaddprogbulkn />
</Route>
<Route path='/editnppuvarintake'>
<Editnppuvarintake/>
</Route>
<Route path='/viewnppuvarintake'>
<Viewnppuvarintake/>
</Route>
<Route path='/viewnppuvarintakeadmin'>
<Viewnppuvarintakeadmin/>
</Route>
<Route path='/viewppuvarintakeprog'>
<Viewppuvarintakeprog/>
</Route>
<Route path='/addnppuvarintake'>
<Addnppuvarintake/>
</Route>
<Route path='/addppuvarintakebulkn'>
<Addppuvarintakebulkn />
</Route>
<Route path='/editnppuexistprog'>
<Editnppuexistprog/>
</Route>
<Route path='/viewnppuexistprog'>
<Viewnppuexistprog/>
</Route>
<Route path='/viewnppuexistprogadmin'>
<Viewnppuexistprogadmin/>
</Route>
<Route path='/viewppuexistprogprog'>
<Viewppuexistprogprog/>
</Route>
<Route path='/addnppuexistprog'>
<Addnppuexistprog/>
</Route>
<Route path='/addppuexistprogbulkn'>
<Addppuexistprogbulkn />
</Route>
<Route path='/editnppucourses'>
<Editnppucourses/>
</Route>
<Route path='/viewnppucourses'>
<Viewnppucourses/>
</Route>
<Route path='/viewnppucoursesadmin'>
<Viewnppucoursesadmin/>
</Route>
<Route path='/viewppucoursesprog'>
<Viewppucoursesprog/>
</Route>
<Route path='/addnppucourses'>
<Addnppucourses/>
</Route>
<Route path='/addppucoursesbulkn'>
<Addppucoursesbulkn />
</Route>
<Route path='/editnppucoursrep'>
<Editnppucoursrep/>
</Route>
<Route path='/viewnppucoursrep'>
<Viewnppucoursrep/>
</Route>
<Route path='/viewnppucoursrepadmin'>
<Viewnppucoursrepadmin/>
</Route>
<Route path='/viewppucoursrepprog'>
<Viewppucoursrepprog/>
</Route>
<Route path='/addnppucoursrep'>
<Addnppucoursrep/>
</Route>
<Route path='/addppucoursrepbulkn'>
<Addppucoursrepbulkn />
</Route>
<Route path='/editnppuprincipal'>
<Editnppuprincipal/>
</Route>
<Route path='/viewnppuprincipal'>
<Viewnppuprincipal/>
</Route>
<Route path='/viewnppuprincipaladmin'>
<Viewnppuprincipaladmin/>
</Route>
<Route path='/viewppuprincipalprog'>
<Viewppuprincipalprog/>
</Route>
<Route path='/addnppuprincipal'>
<Addnppuprincipal/>
</Route>
<Route path='/addppuprincipalbulkn'>
<Addppuprincipalbulkn />
</Route>
<Route path='/editnppufaculty'>
<Editnppufaculty/>
</Route>
<Route path='/viewnppufaculty'>
<Viewnppufaculty/>
</Route>
<Route path='/viewnppufacultyadmin'>
<Viewnppufacultyadmin/>
</Route>
<Route path='/viewppufacultyprog'>
<Viewppufacultyprog/>
</Route>
<Route path='/addnppufaculty'>
<Addnppufaculty/>
</Route>
<Route path='/addppufacultybulkn'>
<Addppufacultybulkn />
</Route>
<Route path='/editnppustafstudratio'>
<Editnppustafstudratio/>
</Route>
<Route path='/viewnppustafstudratio'>
<Viewnppustafstudratio/>
</Route>
<Route path='/viewnppustafstudratioadmin'>
<Viewnppustafstudratioadmin/>
</Route>
<Route path='/viewppustafstudratioprog'>
<Viewppustafstudratioprog/>
</Route>
<Route path='/addnppustafstudratio'>
<Addnppustafstudratio/>
</Route>
<Route path='/addppustafstudratiobulkn'>
<Addppustafstudratiobulkn />
</Route>
<Route path='/editnppulabequip'>
<Editnppulabequip/>
</Route>
<Route path='/viewnppulabequip'>
<Viewnppulabequip/>
</Route>
<Route path='/viewnppulabequipadmin'>
<Viewnppulabequipadmin/>
</Route>
<Route path='/viewppulabequipprog'>
<Viewppulabequipprog/>
</Route>
<Route path='/addnppulabequip'>
<Addnppulabequip/>
</Route>
<Route path='/addppulabequipbulkn'>
<Addppulabequipbulkn />
</Route>
<Route path='/editnppulabequipprocure'>
<Editnppulabequipprocure/>
</Route>
<Route path='/viewnppulabequipprocure'>
<Viewnppulabequipprocure/>
</Route>
<Route path='/viewnppulabequipprocureadmin'>
<Viewnppulabequipprocureadmin/>
</Route>
<Route path='/viewppulabequipprocureprog'>
<Viewppulabequipprocureprog/>
</Route>
<Route path='/addnppulabequipprocure'>
<Addnppulabequipprocure/>
</Route>
<Route path='/addppulabequipprocurebulkn'>
<Addppulabequipprocurebulkn />
</Route>
<Route path='/editnppulecturerooms'>
<Editnppulecturerooms/>
</Route>
<Route path='/viewnppulecturerooms'>
<Viewnppulecturerooms/>
</Route>
<Route path='/viewnppulectureroomsadmin'>
<Viewnppulectureroomsadmin/>
</Route>
<Route path='/viewppulectureroomsprog'>
<Viewppulectureroomsprog/>
</Route>
<Route path='/addnppulecturerooms'>
<Addnppulecturerooms/>
</Route>
<Route path='/addppulectureroomsbulkn'>
<Addppulectureroomsbulkn />
</Route>
<Route path='/editnppuavailclsroom'>
<Editnppuavailclsroom/>
</Route>
<Route path='/viewnppuavailclsroom'>
<Viewnppuavailclsroom/>
</Route>
<Route path='/viewnppuavailclsroomadmin'>
<Viewnppuavailclsroomadmin/>
</Route>
<Route path='/viewppuavailclsroomprog'>
<Viewppuavailclsroomprog/>
</Route>
<Route path='/addnppuavailclsroom'>
<Addnppuavailclsroom/>
</Route>
<Route path='/addppuavailclsroombulkn'>
<Addppuavailclsroombulkn />
</Route>
<Route path='/editnppunetwork'>
<Editnppunetwork/>
</Route>
<Route path='/viewnppunetwork'>
<Viewnppunetwork/>
</Route>
<Route path='/viewnppunetworkadmin'>
<Viewnppunetworkadmin/>
</Route>
<Route path='/viewppunetworkprog'>
<Viewppunetworkprog/>
</Route>
<Route path='/addnppunetwork'>
<Addnppunetwork/>
</Route>
<Route path='/addppunetworkbulkn'>
<Addppunetworkbulkn />
</Route>
<Route path='/editnppucompfaci'>
<Editnppucompfaci/>
</Route>
<Route path='/viewnppucompfaci'>
<Viewnppucompfaci/>
</Route>
<Route path='/viewnppucompfaciadmin'>
<Viewnppucompfaciadmin/>
</Route>
<Route path='/viewppucompfaciprog'>
<Viewppucompfaciprog/>
</Route>
<Route path='/addnppucompfaci'>
<Addnppucompfaci/>
</Route>
<Route path='/addppucompfacibulkn'>
<Addppucompfacibulkn />
</Route>
<Route path='/editnppucompsoftware'>
<Editnppucompsoftware/>
</Route>
<Route path='/viewnppucompsoftware'>
<Viewnppucompsoftware/>
</Route>
<Route path='/viewnppucompsoftwareadmin'>
<Viewnppucompsoftwareadmin/>
</Route>
<Route path='/viewppucompsoftwareprog'>
<Viewppucompsoftwareprog/>
</Route>
<Route path='/addnppucompsoftware'>
<Addnppucompsoftware/>
</Route>
<Route path='/addppucompsoftwarebulkn'>
<Addppucompsoftwarebulkn />
</Route>
<Route path='/editnppucompaddinfo'>
<Editnppucompaddinfo/>
</Route>
<Route path='/viewnppucompaddinfo'>
<Viewnppucompaddinfo/>
</Route>
<Route path='/viewnppucompaddinfoadmin'>
<Viewnppucompaddinfoadmin/>
</Route>
<Route path='/viewppucompaddinfoprog'>
<Viewppucompaddinfoprog/>
</Route>
<Route path='/addnppucompaddinfo'>
<Addnppucompaddinfo/>
</Route>
<Route path='/addppucompaddinfobulkn'>
<Addppucompaddinfobulkn />
</Route>
<Route path='/editnppucompaddsoft'>
<Editnppucompaddsoft/>
</Route>
<Route path='/viewnppucompaddsoft'>
<Viewnppucompaddsoft/>
</Route>
<Route path='/viewnppucompaddsoftadmin'>
<Viewnppucompaddsoftadmin/>
</Route>
<Route path='/viewppucompaddsoftprog'>
<Viewppucompaddsoftprog/>
</Route>
<Route path='/addnppucompaddsoft'>
<Addnppucompaddsoft/>
</Route>
<Route path='/addppucompaddsoftbulkn'>
<Addppucompaddsoftbulkn />
</Route>
<Route path='/editnppulibrary'>
<Editnppulibrary/>
</Route>
<Route path='/viewnppulibrary'>
<Viewnppulibrary/>
</Route>
<Route path='/viewnppulibraryadmin'>
<Viewnppulibraryadmin/>
</Route>
<Route path='/viewppulibraryprog'>
<Viewppulibraryprog/>
</Route>
<Route path='/addnppulibrary'>
<Addnppulibrary/>
</Route>
<Route path='/addppulibrarybulkn'>
<Addppulibrarybulkn />
</Route>
<Route path='/editnppulibstaff'>
<Editnppulibstaff/>
</Route>
<Route path='/viewnppulibstaff'>
<Viewnppulibstaff/>
</Route>
<Route path='/viewnppulibstaffadmin'>
<Viewnppulibstaffadmin/>
</Route>
<Route path='/viewppulibstaffprog'>
<Viewppulibstaffprog/>
</Route>
<Route path='/addnppulibstaff'>
<Addnppulibstaff/>
</Route>
<Route path='/addppulibstaffbulkn'>
<Addppulibstaffbulkn />
</Route>
<Route path='/editnppulibbooks'>
<Editnppulibbooks/>
</Route>
<Route path='/viewnppulibbooks'>
<Viewnppulibbooks/>
</Route>
<Route path='/viewnppulibbooksadmin'>
<Viewnppulibbooksadmin/>
</Route>
<Route path='/viewppulibbooksprog'>
<Viewppulibbooksprog/>
</Route>
<Route path='/addnppulibbooks'>
<Addnppulibbooks/>
</Route>
<Route path='/addppulibbooksbulkn'>
<Addppulibbooksbulkn />
</Route>
<Route path='/editnppulibindjrnl'>
<Editnppulibindjrnl/>
</Route>
<Route path='/viewnppulibindjrnl'>
<Viewnppulibindjrnl/>
</Route>
<Route path='/viewnppulibindjrnladmin'>
<Viewnppulibindjrnladmin/>
</Route>
<Route path='/viewppulibindjrnlprog'>
<Viewppulibindjrnlprog/>
</Route>
<Route path='/addnppulibindjrnl'>
<Addnppulibindjrnl/>
</Route>
<Route path='/addppulibindjrnlbulkn'>
<Addppulibindjrnlbulkn />
</Route>
<Route path='/editnppulibforgjrnl'>
<Editnppulibforgjrnl/>
</Route>
<Route path='/viewnppulibforgjrnl'>
<Viewnppulibforgjrnl/>
</Route>
<Route path='/viewnppulibforgjrnladmin'>
<Viewnppulibforgjrnladmin/>
</Route>
<Route path='/viewppulibforgjrnlprog'>
<Viewppulibforgjrnlprog/>
</Route>
<Route path='/addnppulibforgjrnl'>
<Addnppulibforgjrnl/>
</Route>
<Route path='/addppulibforgjrnlbulkn'>
<Addppulibforgjrnlbulkn />
</Route>
<Route path='/editnppulibautomation'>
<Editnppulibautomation/>
</Route>
<Route path='/viewnppulibautomation'>
<Viewnppulibautomation/>
</Route>
<Route path='/viewnppulibautomationadmin'>
<Viewnppulibautomationadmin/>
</Route>
<Route path='/viewppulibautomationprog'>
<Viewppulibautomationprog/>
</Route>
<Route path='/addnppulibautomation'>
<Addnppulibautomation/>
</Route>
<Route path='/addppulibautomationbulkn'>
<Addppulibautomationbulkn />
</Route>
<Route path='/editnppulibprocure'>
<Editnppulibprocure/>
</Route>
<Route path='/viewnppulibprocure'>
<Viewnppulibprocure/>
</Route>
<Route path='/viewnppulibprocureadmin'>
<Viewnppulibprocureadmin/>
</Route>
<Route path='/viewppulibprocureprog'>
<Viewppulibprocureprog/>
</Route>
<Route path='/addnppulibprocure'>
<Addnppulibprocure/>
</Route>
<Route path='/addppulibprocurebulkn'>
<Addppulibprocurebulkn />
</Route>
<Route path='/editnppulibexp'>
<Editnppulibexp/>
</Route>
<Route path='/viewnppulibexp'>
<Viewnppulibexp/>
</Route>
<Route path='/viewnppulibexpadmin'>
<Viewnppulibexpadmin/>
</Route>
<Route path='/viewppulibexpprog'>
<Viewppulibexpprog/>
</Route>
<Route path='/addnppulibexp'>
<Addnppulibexp/>
</Route>
<Route path='/addppulibexpbulkn'>
<Addppulibexpbulkn />
</Route>
<Route path='/editnppustudfaci'>
<Editnppustudfaci/>
</Route>
<Route path='/viewnppustudfaci'>
<Viewnppustudfaci/>
</Route>
<Route path='/viewnppustudfaciadmin'>
<Viewnppustudfaciadmin/>
</Route>
<Route path='/viewppustudfaciprog'>
<Viewppustudfaciprog/>
</Route>
<Route path='/addnppustudfaci'>
<Addnppustudfaci/>
</Route>
<Route path='/addppustudfacibulkn'>
<Addppustudfacibulkn />
</Route>
<Route path='/editnppuotherfaci'>
<Editnppuotherfaci/>
</Route>
<Route path='/viewnppuotherfaci'>
<Viewnppuotherfaci/>
</Route>
<Route path='/viewnppuotherfaciadmin'>
<Viewnppuotherfaciadmin/>
</Route>
<Route path='/viewppuotherfaciprog'>
<Viewppuotherfaciprog/>
</Route>
<Route path='/addnppuotherfaci'>
<Addnppuotherfaci/>
</Route>
<Route path='/addppuotherfacibulkn'>
<Addppuotherfacibulkn />
</Route>
<Route path='/editnppumedservice'>
<Editnppumedservice/>
</Route>
<Route path='/viewnppumedservice'>
<Viewnppumedservice/>
</Route>
<Route path='/viewnppumedserviceadmin'>
<Viewnppumedserviceadmin/>
</Route>
<Route path='/viewppumedserviceprog'>
<Viewppumedserviceprog/>
</Route>
<Route path='/addnppumedservice'>
<Addnppumedservice/>
</Route>
<Route path='/addppumedservicebulkn'>
<Addppumedservicebulkn />
</Route>
<Route path='/editnppuaudvidequip'>
<Editnppuaudvidequip/>
</Route>
<Route path='/viewnppuaudvidequip'>
<Viewnppuaudvidequip/>
</Route>
<Route path='/viewnppuaudvidequipadmin'>
<Viewnppuaudvidequipadmin/>
</Route>
<Route path='/viewppuaudvidequipprog'>
<Viewppuaudvidequipprog/>
</Route>
<Route path='/addnppuaudvidequip'>
<Addnppuaudvidequip/>
</Route>
<Route path='/addppuaudvidequipbulkn'>
<Addppuaudvidequipbulkn />
</Route>
<Route path='/editnppuclubs'>
<Editnppuclubs/>
</Route>
<Route path='/viewnppuclubs'>
<Viewnppuclubs/>
</Route>
<Route path='/viewnppuclubsadmin'>
<Viewnppuclubsadmin/>
</Route>
<Route path='/viewppuclubsprog'>
<Viewppuclubsprog/>
</Route>
<Route path='/addnppuclubs'>
<Addnppuclubs/>
</Route>
<Route path='/addppuclubsbulkn'>
<Addppuclubsbulkn />
</Route>
<Route path='/editnpputechstaff'>
<Editnpputechstaff/>
</Route>
<Route path='/viewnpputechstaff'>
<Viewnpputechstaff/>
</Route>
<Route path='/viewnpputechstaffadmin'>
<Viewnpputechstaffadmin/>
</Route>
<Route path='/viewpputechstaffprog'>
<Viewpputechstaffprog/>
</Route>
<Route path='/addnpputechstaff'>
<Addnpputechstaff/>
</Route>
<Route path='/addpputechstaffbulkn'>
<Addpputechstaffbulkn />
</Route>
<Route path='/editnppuminststaff'>
<Editnppuminststaff/>
</Route>
<Route path='/viewnppuminststaff'>
<Viewnppuminststaff/>
</Route>
<Route path='/viewnppuminststaffadmin'>
<Viewnppuminststaffadmin/>
</Route>
<Route path='/viewppuminststaffprog'>
<Viewppuminststaffprog/>
</Route>
<Route path='/addnppuminststaff'>
<Addnppuminststaff/>
</Route>
<Route path='/addppuminststaffbulkn'>
<Addppuminststaffbulkn />
</Route>
<Route path='/editnppuaddinfo'>
<Editnppuaddinfo/>
</Route>
<Route path='/viewnppuaddinfo'>
<Viewnppuaddinfo/>
</Route>
<Route path='/viewnppuaddinfoadmin'>
<Viewnppuaddinfoadmin/>
</Route>
<Route path='/viewppuaddinfoprog'>
<Viewppuaddinfoprog/>
</Route>
<Route path='/addnppuaddinfo'>
<Addnppuaddinfo/>
</Route>
<Route path='/addppuaddinfobulkn'>
<Addppuaddinfobulkn />
</Route>
<Route path='/editnppudeclaration'>
<Editnppudeclaration/>
</Route>
<Route path='/viewnppudeclaration'>
<Viewnppudeclaration/>
</Route>
<Route path='/viewnppudeclarationadmin'>
<Viewnppudeclarationadmin/>
</Route>
<Route path='/viewppudeclarationprog'>
<Viewppudeclarationprog/>
</Route>
<Route path='/addnppudeclaration'>
<Addnppudeclaration/>
</Route>
<Route path='/addppudeclarationbulkn'>
<Addppudeclarationbulkn />
</Route>
<Route path='/editnppucertificates'>
<Editnppucertificates/>
</Route>
<Route path='/viewnppucertificates'>
<Viewnppucertificates/>
</Route>
<Route path='/viewnppucertificatesadmin'>
<Viewnppucertificatesadmin/>
</Route>
<Route path='/viewppucertificatesprog'>
<Viewppucertificatesprog/>
</Route>
<Route path='/addnppucertificates'>
<Addnppucertificates/>
</Route>
<Route path='/addppucertificatesbulkn'>
<Addppucertificatesbulkn />
</Route>
<Route path='/editnppuaddinfoprog'>
<Editnppuaddinfoprog/>
</Route>
<Route path='/viewnppuaddinfoprog'>
<Viewnppuaddinfoprog/>
</Route>
<Route path='/viewnppuaddinfoprogadmin'>
<Viewnppuaddinfoprogadmin/>
</Route>
<Route path='/viewppuaddinfoprogprog'>
<Viewppuaddinfoprogprog/>
</Route>
<Route path='/addnppuaddinfoprog'>
<Addnppuaddinfoprog/>
</Route>
<Route path='/addppuaddinfoprogbulkn'>
<Addppuaddinfoprogbulkn />
</Route>
<Route path='/editnppuaddinfonoc'>
<Editnppuaddinfonoc/>
</Route>
<Route path='/viewnppuaddinfonoc'>
<Viewnppuaddinfonoc/>
</Route>
<Route path='/viewnppuaddinfonocadmin'>
<Viewnppuaddinfonocadmin/>
</Route>
<Route path='/viewppuaddinfonocprog'>
<Viewppuaddinfonocprog/>
</Route>
<Route path='/addnppuaddinfonoc'>
<Addnppuaddinfonoc/>
</Route>
<Route path='/addppuaddinfonocbulkn'>
<Addppuaddinfonocbulkn />
</Route>
<Route path='/editnppuaddinfopermission'>
<Editnppuaddinfopermission/>
</Route>
<Route path='/viewnppuaddinfopermission'>
<Viewnppuaddinfopermission/>
</Route>
<Route path='/viewnppuaddinfopermissionadmin'>
<Viewnppuaddinfopermissionadmin/>
</Route>
<Route path='/viewppuaddinfopermissionprog'>
<Viewppuaddinfopermissionprog/>
</Route>
<Route path='/addnppuaddinfopermission'>
<Addnppuaddinfopermission/>
</Route>
<Route path='/addppuaddinfopermissionbulkn'>
<Addppuaddinfopermissionbulkn />
</Route>
<Route path='/editnppuaddinfobos'>
<Editnppuaddinfobos/>
</Route>
<Route path='/viewnppuaddinfobos'>
<Viewnppuaddinfobos/>
</Route>
<Route path='/viewnppuaddinfobosadmin'>
<Viewnppuaddinfobosadmin/>
</Route>
<Route path='/viewppuaddinfobosprog'>
<Viewppuaddinfobosprog/>
</Route>
<Route path='/addnppuaddinfobos'>
<Addnppuaddinfobos/>
</Route>
<Route path='/addppuaddinfobosbulkn'>
<Addppuaddinfobosbulkn />
</Route>
<Route path='/editnppuaddinfosyllabi'>
<Editnppuaddinfosyllabi/>
</Route>
<Route path='/viewnppuaddinfosyllabi'>
<Viewnppuaddinfosyllabi/>
</Route>
<Route path='/viewnppuaddinfosyllabiadmin'>
<Viewnppuaddinfosyllabiadmin/>
</Route>
<Route path='/viewppuaddinfosyllabiprog'>
<Viewppuaddinfosyllabiprog/>
</Route>
<Route path='/addnppuaddinfosyllabi'>
<Addnppuaddinfosyllabi/>
</Route>
<Route path='/addppuaddinfosyllabibulkn'>
<Addppuaddinfosyllabibulkn />
</Route>
<Route path='/editnppuaddinfofac'>
<Editnppuaddinfofac/>
</Route>
<Route path='/viewnppuaddinfofac'>
<Viewnppuaddinfofac/>
</Route>
<Route path='/viewnppuaddinfofacadmin'>
<Viewnppuaddinfofacadmin/>
</Route>
<Route path='/viewppuaddinfofacprog'>
<Viewppuaddinfofacprog/>
</Route>
<Route path='/addnppuaddinfofac'>
<Addnppuaddinfofac/>
</Route>
<Route path='/addppuaddinfofacbulkn'>
<Addppuaddinfofacbulkn />
</Route>
<Route path='/editnppuaddinfostaffdetails'>
<Editnppuaddinfostaffdetails/>
</Route>
<Route path='/viewnppuaddinfostaffdetails'>
<Viewnppuaddinfostaffdetails/>
</Route>
<Route path='/viewnppuaddinfostaffdetailsadmin'>
<Viewnppuaddinfostaffdetailsadmin/>
</Route>
<Route path='/viewppuaddinfostaffdetailsprog'>
<Viewppuaddinfostaffdetailsprog/>
</Route>
<Route path='/addnppuaddinfostaffdetails'>
<Addnppuaddinfostaffdetails/>
</Route>
<Route path='/addppuaddinfostaffdetailsbulkn'>
<Addppuaddinfostaffdetailsbulkn />
</Route>
<Route path='/editnppuaddfacdetails'>
<Editnppuaddfacdetails/>
</Route>
<Route path='/viewnppuaddfacdetails'>
<Viewnppuaddfacdetails/>
</Route>
<Route path='/viewnppuaddfacdetailsadmin'>
<Viewnppuaddfacdetailsadmin/>
</Route>
<Route path='/viewppuaddfacdetailsprog'>
<Viewppuaddfacdetailsprog/>
</Route>
<Route path='/addnppuaddfacdetails'>
<Addnppuaddfacdetails/>
</Route>
<Route path='/addppuaddfacdetailsbulkn'>
<Addppuaddfacdetailsbulkn />
</Route>
<Route path='/editnppufacworkload'>
<Editnppufacworkload/>
</Route>
<Route path='/viewnppufacworkload'>
<Viewnppufacworkload/>
</Route>
<Route path='/viewnppufacworkloadadmin'>
<Viewnppufacworkloadadmin/>
</Route>
<Route path='/viewppufacworkloadprog'>
<Viewppufacworkloadprog/>
</Route>
<Route path='/addnppufacworkload'>
<Addnppufacworkload/>
</Route>
<Route path='/addppufacworkloadbulkn'>
<Addppufacworkloadbulkn />
</Route>

    <Route path='/editnnuexplearning'>
<Editnnuexplearning/>
</Route>
<Route path='/viewnnuexplearning'>
<Viewnnuexplearning/>
</Route>
<Route path='/viewnnuexplearningadmin'>
<Viewnnuexplearningadmin/>
</Route>
<Route path='/viewnuexplearningprog'>
<Viewnuexplearningprog/>
</Route>
<Route path='/addnnuexplearning'>
<Addnnuexplearning/>
</Route>
<Route path='/addnuexplearningbulkn'>
<Addnuexplearningbulkn />
</Route>
<Route path='/editnnucodeofethics'>
<Editnnucodeofethics/>
</Route>
<Route path='/viewnnucodeofethics'>
<Viewnnucodeofethics/>
</Route>
<Route path='/viewnnucodeofethicsadmin'>
<Viewnnucodeofethicsadmin/>
</Route>
<Route path='/viewnucodeofethicsprog'>
<Viewnucodeofethicsprog/>
</Route>
<Route path='/addnnucodeofethics'>
<Addnnucodeofethics/>
</Route>
<Route path='/addnucodeofethicsbulkn'>
<Addnucodeofethicsbulkn />
</Route>
<Route path='/editnnlufocus'>
<Editnnlufocus/>
</Route>
<Route path='/viewnnlufocus'>
<Viewnnlufocus/>
</Route>
<Route path='/viewnnlufocusadmin'>
<Viewnnlufocusadmin/>
</Route>
<Route path='/viewnlufocusprog'>
<Viewnlufocusprog/>
</Route>
<Route path='/addnnlufocus'>
<Addnnlufocus/>
</Route>
<Route path='/addnlufocusbulkn'>
<Addnlufocusbulkn />
</Route>
<Route path='/editnnluadmission'>
<Editnnluadmission/>
</Route>
<Route path='/viewnnluadmission'>
<Viewnnluadmission/>
</Route>
<Route path='/viewnnluadmissionadmin'>
<Viewnnluadmissionadmin/>
</Route>
<Route path='/viewnluadmissionprog'>
<Viewnluadmissionprog/>
</Route>
<Route path='/addnnluadmission'>
<Addnnluadmission/>
</Route>
<Route path='/addnluadmissionbulkn'>
<Addnluadmissionbulkn />
</Route>
<Route path='/editnnauscholarship'>
<Editnnauscholarship/>
</Route>
<Route path='/viewnnauscholarship'>
<Viewnnauscholarship/>
</Route>
<Route path='/viewnnauscholarshipadmin'>
<Viewnnauscholarshipadmin/>
</Route>
<Route path='/viewnauscholarshipprog'>
<Viewnauscholarshipprog/>
</Route>
<Route path='/addnnauscholarship'>
<Addnnauscholarship/>
</Route>
<Route path='/addnauscholarshipbulkn'>
<Addnauscholarshipbulkn />
</Route>


    <Route path='/editnpaunewfcoeexp'>
<Editnpaunewfcoeexp/>
</Route>
<Route path='/viewnpaunewfcoeexp'>
<Viewnpaunewfcoeexp/>
</Route>
<Route path='/viewnpaunewfcoeexpadmin'>
<Viewnpaunewfcoeexpadmin/>
</Route>
<Route path='/viewpaunewfcoeexpprog'>
<Viewpaunewfcoeexpprog/>
</Route>
<Route path='/addnpaunewfcoeexp'>
<Addnpaunewfcoeexp/>
</Route>
<Route path='/addpaunewfcoeexpbulkn'>
<Addpaunewfcoeexpbulkn />
</Route>


    <Route path='/editnpnirf'>
  <Editnpnirf/>
  </Route>
  <Route path='/viewnpnirf'>
  <Viewnpnirf/>
  </Route>
  <Route path='/viewnpnirfadmin'>
  <Viewnpnirfadmin/>
  </Route>
  <Route path='/viewpnirfprog'>
  <Viewpnirfprog/>
  </Route>
  <Route path='/addnpnirf'>
  <Addnpnirf/>
  </Route>
  <Route path='/addpnirfbulkn'>
  <Addpnirfbulkn />
  </Route>
  <Route path='/editnpadmnew'>
  <Editnpadmnew/>
  </Route>
  <Route path='/viewnpadmnew'>
  <Viewnpadmnew/>
  </Route>
  <Route path='/viewnpadmnewadmin'>
  <Viewnpadmnewadmin/>
  </Route>
  <Route path='/viewpadmnewprog'>
  <Viewpadmnewprog/>
  </Route>
  <Route path='/addnpadmnew'>
  <Addnpadmnew/>
  </Route>
  <Route path='/addpadmnewbulkn'>
  <Addpadmnewbulkn />
  </Route>
  <Route path='/editnpnbanew'>
  <Editnpnbanew/>
  </Route>
  <Route path='/viewnpnbanew'>
  <Viewnpnbanew/>
  </Route>
  <Route path='/viewnpnbanewadmin'>
  <Viewnpnbanewadmin/>
  </Route>
  {/* <Route path='/viewpnbanewprog'>
  <Viewpnbanewprog/>
  </Route> */}
  <Route path='/addnpnbanew'>
  <Addnpnbanew/>
  </Route>
  <Route path='/addpnbanewbulkn'>
  <Addpnbanewbulkn />
  </Route>


    <Route path='/dashanna1'>
<Dashanna1/>
</Route>
<Route path='/dashannaadmin1'>
<Dashannaadmin1/>
</Route>

    <Route path='/editnpaunewlanddetails'>
<Editnpaunewlanddetails/>
</Route>
<Route path='/viewnpaunewlanddetails'>
<Viewnpaunewlanddetails/>
</Route>
<Route path='/viewnpaunewlanddetailsadmin'>
<Viewnpaunewlanddetailsadmin/>
</Route>
<Route path='/viewpaunewlanddetailsprog'>
<Viewpaunewlanddetailsprog/>
</Route>
<Route path='/addnpaunewlanddetails'>
<Addnpaunewlanddetails/>
</Route>
<Route path='/addpaunewlanddetailsbulkn'>
<Addpaunewlanddetailsbulkn />
</Route>
<Route path='/editnpaunewfaddqualif'>
<Editnpaunewfaddqualif/>
</Route>
<Route path='/viewnpaunewfaddqualif'>
<Viewnpaunewfaddqualif/>
</Route>
<Route path='/viewnpaunewfaddqualifadmin'>
<Viewnpaunewfaddqualifadmin/>
</Route>
<Route path='/viewpaunewfaddqualifprog'>
<Viewpaunewfaddqualifprog/>
</Route>
<Route path='/addnpaunewfaddqualif'>
<Addnpaunewfaddqualif/>
</Route>
<Route path='/addpaunewfaddqualifbulkn'>
<Addpaunewfaddqualifbulkn />
</Route>
<Route path='/editnpaunewfacdexp'>
<Editnpaunewfacdexp/>
</Route>
<Route path='/viewnpaunewfacdexp'>
<Viewnpaunewfacdexp/>
</Route>
<Route path='/viewnpaunewfacdexpadmin'>
<Viewnpaunewfacdexpadmin/>
</Route>
<Route path='/viewpaunewfacdexpprog'>
<Viewpaunewfacdexpprog/>
</Route>
<Route path='/addnpaunewfacdexp'>
<Addnpaunewfacdexp/>
</Route>
<Route path='/addpaunewfacdexpbulkn'>
<Addpaunewfacdexpbulkn />
</Route>
<Route path='/editnpaunewfindexp'>
<Editnpaunewfindexp/>
</Route>
<Route path='/viewnpaunewfindexp'>
<Viewnpaunewfindexp/>
</Route>
<Route path='/viewnpaunewfindexpadmin'>
<Viewnpaunewfindexpadmin/>
</Route>
<Route path='/viewpaunewfindexpprog'>
<Viewpaunewfindexpprog/>
</Route>
<Route path='/addnpaunewfindexp'>
<Addnpaunewfindexp/>
</Route>
<Route path='/addpaunewfindexpbulkn'>
<Addpaunewfindexpbulkn />
</Route>

    <Route path='/editnpaunewfacultydetails'>
<Editnpaunewfacultydetails/>
</Route>
<Route path='/viewnpaunewfacultydetails'>
<Viewnpaunewfacultydetails/>
</Route>
<Route path='/viewnpaunewfacultydetailsadmin'>
<Viewnpaunewfacultydetailsadmin/>
</Route>
<Route path='/viewpaunewfacultydetailsprog'>
<Viewpaunewfacultydetailsprog/>
</Route>
<Route path='/addnpaunewfacultydetails'>
<Addnpaunewfacultydetails/>
</Route>
<Route path='/addpaunewfacultydetailsbulkn'>
<Addpaunewfacultydetailsbulkn />
</Route>
<Route path='/editnpaunewfeduqualif'>
<Editnpaunewfeduqualif/>
</Route>
<Route path='/viewnpaunewfeduqualif'>
<Viewnpaunewfeduqualif/>
</Route>
<Route path='/viewnpaunewfeduqualifadmin'>
<Viewnpaunewfeduqualifadmin/>
</Route>
<Route path='/viewpaunewfeduqualifprog'>
<Viewpaunewfeduqualifprog/>
</Route>
<Route path='/addnpaunewfeduqualif'>
<Addnpaunewfeduqualif/>
</Route>
<Route path='/addpaunewfeduqualifbulkn'>
<Addpaunewfeduqualifbulkn />
</Route>



    <Route path='/editnpaunewidetails'>
<Editnpaunewidetails/>
</Route>
<Route path='/viewnpaunewidetails'>
<Viewnpaunewidetails/>
</Route>
<Route path='/viewnpaunewidetailsadmin'>
<Viewnpaunewidetailsadmin/>
</Route>
<Route path='/viewpaunewidetailsprog'>
<Viewpaunewidetailsprog/>
</Route>
<Route path='/addnpaunewidetails'>
<Addnpaunewidetails/>
</Route>
<Route path='/addpaunewidetailsbulkn'>
<Addpaunewidetailsbulkn />
</Route>
<Route path='/editnpauidetailsc'>
<Editnpauidetailsc/>
</Route>
<Route path='/viewnpauidetailsc'>
<Viewnpauidetailsc/>
</Route>
<Route path='/viewnpauidetailscadmin'>
<Viewnpauidetailscadmin/>
</Route>
<Route path='/viewpauidetailscprog'>
<Viewpauidetailscprog/>
</Route>
<Route path='/addnpauidetailsc'>
<Addnpauidetailsc/>
</Route>
<Route path='/addpauidetailscbulkn'>
<Addpauidetailscbulkn />
</Route>
<Route path='/editnpautrust'>
<Editnpautrust/>
</Route>
<Route path='/viewnpautrust'>
<Viewnpautrust/>
</Route>
<Route path='/viewnpautrustadmin'>
<Viewnpautrustadmin/>
</Route>
<Route path='/viewpautrustprog'>
<Viewpautrustprog/>
</Route>
<Route path='/addnpautrust'>
<Addnpautrust/>
</Route>
<Route path='/addpautrustbulkn'>
<Addpautrustbulkn />
</Route>
<Route path='/editnpaunewprincipal'>
<Editnpaunewprincipal/>
</Route>
<Route path='/viewnpaunewprincipal'>
<Viewnpaunewprincipal/>
</Route>
<Route path='/viewnpaunewprincipaladmin'>
<Viewnpaunewprincipaladmin/>
</Route>
<Route path='/viewpaunewprincipalprog'>
<Viewpaunewprincipalprog/>
</Route>
<Route path='/addnpaunewprincipal'>
<Addnpaunewprincipal/>
</Route>
<Route path='/addpaunewprincipalbulkn'>
<Addpaunewprincipalbulkn />
</Route>
<Route path='/editnpaungovcouncil'>
<Editnpaungovcouncil/>
</Route>
<Route path='/viewnpaungovcouncil'>
<Viewnpaungovcouncil/>
</Route>
<Route path='/viewnpaungovcounciladmin'>
<Viewnpaungovcounciladmin/>
</Route>
<Route path='/viewpaungovcouncilprog'>
<Viewpaungovcouncilprog/>
</Route>
<Route path='/addnpaungovcouncil'>
<Addnpaungovcouncil/>
</Route>
<Route path='/addpaungovcouncilbulkn'>
<Addpaungovcouncilbulkn />
</Route>
<Route path='/editnpaunboard'>
<Editnpaunboard/>
</Route>
<Route path='/viewnpaunboard'>
<Viewnpaunboard/>
</Route>
<Route path='/viewnpaunboardadmin'>
<Viewnpaunboardadmin/>
</Route>
<Route path='/viewpaunboardprog'>
<Viewpaunboardprog/>
</Route>
<Route path='/addnpaunboard'>
<Addnpaunboard/>
</Route>
<Route path='/addpaunboardbulkn'>
<Addpaunboardbulkn />
</Route>
<Route path='/editnpauragcomt'>
<Editnpauragcomt/>
</Route>
<Route path='/viewnpauragcomt'>
<Viewnpauragcomt/>
</Route>
<Route path='/viewnpauragcomtadmin'>
<Viewnpauragcomtadmin/>
</Route>
<Route path='/viewpauragcomtprog'>
<Viewpauragcomtprog/>
</Route>
<Route path='/addnpauragcomt'>
<Addnpauragcomt/>
</Route>
<Route path='/addpauragcomtbulkn'>
<Addpauragcomtbulkn />
</Route>
<Route path='/editnpauragsquad'>
<Editnpauragsquad/>
</Route>
<Route path='/viewnpauragsquad'>
<Viewnpauragsquad/>
</Route>
<Route path='/viewnpauragsquadadmin'>
<Viewnpauragsquadadmin/>
</Route>
<Route path='/viewpauragsquadprog'>
<Viewpauragsquadprog/>
</Route>
<Route path='/addnpauragsquad'>
<Addnpauragsquad/>
</Route>
<Route path='/addpauragsquadbulkn'>
<Addpauragsquadbulkn />
</Route>
<Route path='/editnpauwelfarecomt'>
<Editnpauwelfarecomt/>
</Route>
<Route path='/viewnpauwelfarecomt'>
<Viewnpauwelfarecomt/>
</Route>
<Route path='/viewnpauwelfarecomtadmin'>
<Viewnpauwelfarecomtadmin/>
</Route>
<Route path='/viewpauwelfarecomtprog'>
<Viewpauwelfarecomtprog/>
</Route>
<Route path='/addnpauwelfarecomt'>
<Addnpauwelfarecomt/>
</Route>
<Route path='/addpauwelfarecomtbulkn'>
<Addpauwelfarecomtbulkn />
</Route>
<Route path='/editnpauredressal'>
<Editnpauredressal/>
</Route>
<Route path='/viewnpauredressal'>
<Viewnpauredressal/>
</Route>
<Route path='/viewnpauredressaladmin'>
<Viewnpauredressaladmin/>
</Route>
<Route path='/viewpauredressalprog'>
<Viewpauredressalprog/>
</Route>
<Route path='/addnpauredressal'>
<Addnpauredressal/>
</Route>
<Route path='/addpauredressalbulkn'>
<Addpauredressalbulkn />
</Route>
<Route path='/editnpaubankacnts'>
<Editnpaubankacnts/>
</Route>
<Route path='/viewnpaubankacnts'>
<Viewnpaubankacnts/>
</Route>
<Route path='/viewnpaubankacntsadmin'>
<Viewnpaubankacntsadmin/>
</Route>
<Route path='/viewpaubankacntsprog'>
<Viewpaubankacntsprog/>
</Route>
<Route path='/addnpaubankacnts'>
<Addnpaubankacnts/>
</Route>
<Route path='/addpaubankacntsbulkn'>
<Addpaubankacntsbulkn />
</Route>
<Route path='/editnpaudeposit'>
<Editnpaudeposit/>
</Route>
<Route path='/viewnpaudeposit'>
<Viewnpaudeposit/>
</Route>
<Route path='/viewnpaudepositadmin'>
<Viewnpaudepositadmin/>
</Route>
<Route path='/viewpaudepositprog'>
<Viewpaudepositprog/>
</Route>
<Route path='/addnpaudeposit'>
<Addnpaudeposit/>
</Route>
<Route path='/addpaudepositbulkn'>
<Addpaudepositbulkn />
</Route>
<Route path='/editnpauendowment'>
<Editnpauendowment/>
</Route>
<Route path='/viewnpauendowment'>
<Viewnpauendowment/>
</Route>
<Route path='/viewnpauendowmentadmin'>
<Viewnpauendowmentadmin/>
</Route>
<Route path='/viewpauendowmentprog'>
<Viewpauendowmentprog/>
</Route>
<Route path='/addnpauendowment'>
<Addnpauendowment/>
</Route>
<Route path='/addpauendowmentbulkn'>
<Addpauendowmentbulkn />
</Route>
<Route path='/editnpaulanddetailsa'>
<Editnpaulanddetailsa/>
</Route>
<Route path='/viewnpaulanddetailsa'>
<Viewnpaulanddetailsa/>
</Route>
<Route path='/viewnpaulanddetailsaadmin'>
<Viewnpaulanddetailsaadmin/>
</Route>
<Route path='/viewpaulanddetailsaprog'>
<Viewpaulanddetailsaprog/>
</Route>
<Route path='/addnpaulanddetailsa'>
<Addnpaulanddetailsa/>
</Route>
<Route path='/addpaulanddetailsabulkn'>
<Addpaulanddetailsabulkn />
</Route>
<Route path='/editnpaulanddetailsb'>
<Editnpaulanddetailsb/>
</Route>
<Route path='/viewnpaulanddetailsb'>
<Viewnpaulanddetailsb/>
</Route>
<Route path='/viewnpaulanddetailsbadmin'>
<Viewnpaulanddetailsbadmin/>
</Route>
<Route path='/viewpaulanddetailsbprog'>
<Viewpaulanddetailsbprog/>
</Route>
<Route path='/addnpaulanddetailsb'>
<Addnpaulanddetailsb/>
</Route>
<Route path='/addpaulanddetailsbbulkn'>
<Addpaulanddetailsbbulkn />
</Route>
<Route path='/editnpaucourse'>
<Editnpaucourse/>
</Route>
<Route path='/viewnpaucourse'>
<Viewnpaucourse/>
</Route>
<Route path='/viewnpaucourseadmin'>
<Viewnpaucourseadmin/>
</Route>
<Route path='/viewpaucourseprog'>
<Viewpaucourseprog/>
</Route>
<Route path='/addnpaucourse'>
<Addnpaucourse/>
</Route>
<Route path='/addpaucoursebulkn'>
<Addpaucoursebulkn />
</Route>
<Route path='/editnpaucrsedetails'>
<Editnpaucrsedetails/>
</Route>
<Route path='/viewnpaucrsedetails'>
<Viewnpaucrsedetails/>
</Route>
<Route path='/viewnpaucrsedetailsadmin'>
<Viewnpaucrsedetailsadmin/>
</Route>
<Route path='/viewpaucrsedetailsprog'>
<Viewpaucrsedetailsprog/>
</Route>
<Route path='/addnpaucrsedetails'>
<Addnpaucrsedetails/>
</Route>
<Route path='/addpaucrsedetailsbulkn'>
<Addpaucrsedetailsbulkn />
</Route>
<Route path='/editnpauaddcrse'>
<Editnpauaddcrse/>
</Route>
<Route path='/viewnpauaddcrse'>
<Viewnpauaddcrse/>
</Route>
<Route path='/viewnpauaddcrseadmin'>
<Viewnpauaddcrseadmin/>
</Route>
<Route path='/viewpauaddcrseprog'>
<Viewpauaddcrseprog/>
</Route>
<Route path='/addnpauaddcrse'>
<Addnpauaddcrse/>
</Route>
<Route path='/addpauaddcrsebulkn'>
<Addpauaddcrsebulkn />
</Route>
<Route path='/editnpaunstuddetails'>
<Editnpaunstuddetails/>
</Route>
<Route path='/viewnpaunstuddetails'>
<Viewnpaunstuddetails/>
</Route>
<Route path='/viewnpaunstuddetailsadmin'>
<Viewnpaunstuddetailsadmin/>
</Route>
<Route path='/viewpaunstuddetailsprog'>
<Viewpaunstuddetailsprog/>
</Route>
<Route path='/addnpaunstuddetails'>
<Addnpaunstuddetails/>
</Route>
<Route path='/addpaunstuddetailsbulkn'>
<Addpaunstuddetailsbulkn />
</Route>
<Route path='/editnpaufaculty'>
<Editnpaufaculty/>
</Route>
<Route path='/viewnpaufaculty'>
<Viewnpaufaculty/>
</Route>
<Route path='/viewnpaufacultyadmin'>
<Viewnpaufacultyadmin/>
</Route>
<Route path='/viewpaufacultyprog'>
<Viewpaufacultyprog/>
</Route>
<Route path='/addnpaufaculty'>
<Addnpaufaculty/>
</Route>
<Route path='/addpaufacultybulkn'>
<Addpaufacultybulkn />
</Route>
<Route path='/editnpaunfacultydetails'>
<Editnpaunfacultydetails/>
</Route>
<Route path='/viewnpaunfacultydetails'>
<Viewnpaunfacultydetails/>
</Route>
<Route path='/viewnpaunfacultydetailsadmin'>
<Viewnpaunfacultydetailsadmin/>
</Route>
<Route path='/viewpaunfacultydetailsprog'>
<Viewpaunfacultydetailsprog/>
</Route>
<Route path='/addnpaunfacultydetails'>
<Addnpaunfacultydetails/>
</Route>
<Route path='/addpaunfacultydetailsbulkn'>
<Addpaunfacultydetailsbulkn />
</Route>
<Route path='/editnpaunfaddqualif'>
<Editnpaunfaddqualif/>
</Route>
<Route path='/viewnpaunfaddqualif'>
<Viewnpaunfaddqualif/>
</Route>
<Route path='/viewnpaunfaddqualifadmin'>
<Viewnpaunfaddqualifadmin/>
</Route>
<Route path='/viewpaunfaddqualifprog'>
<Viewpaunfaddqualifprog/>
</Route>
<Route path='/addnpaunfaddqualif'>
<Addnpaunfaddqualif/>
</Route>
<Route path='/addpaunfaddqualifbulkn'>
<Addpaunfaddqualifbulkn />
</Route>
<Route path='/editnpaunfacdexp'>
<Editnpaunfacdexp/>
</Route>
<Route path='/viewnpaunfacdexp'>
<Viewnpaunfacdexp/>
</Route>
<Route path='/viewnpaunfacdexpadmin'>
<Viewnpaunfacdexpadmin/>
</Route>
<Route path='/viewpaunfacdexpprog'>
<Viewpaunfacdexpprog/>
</Route>
<Route path='/addnpaunfacdexp'>
<Addnpaunfacdexp/>
</Route>
<Route path='/addpaunfacdexpbulkn'>
<Addpaunfacdexpbulkn />
</Route>
<Route path='/editnpaunfindexp'>
<Editnpaunfindexp/>
</Route>
<Route path='/viewnpaunfindexp'>
<Viewnpaunfindexp/>
</Route>
<Route path='/viewnpaunfindexpadmin'>
<Viewnpaunfindexpadmin/>
</Route>
<Route path='/viewpaunfindexpprog'>
<Viewpaunfindexpprog/>
</Route>
<Route path='/addnpaunfindexp'>
<Addnpaunfindexp/>
</Route>
<Route path='/addpaunfindexpbulkn'>
<Addpaunfindexpbulkn />
</Route>
<Route path='/editnpaunnfeduqualif'>
<Editnpaunnfeduqualif/>
</Route>
<Route path='/viewnpaunnfeduqualif'>
<Viewnpaunnfeduqualif/>
</Route>
<Route path='/viewnpaunnfeduqualifadmin'>
<Viewnpaunnfeduqualifadmin/>
</Route>
<Route path='/viewpaunnfeduqualifprog'>
<Viewpaunnfeduqualifprog/>
</Route>
<Route path='/addnpaunnfeduqualif'>
<Addnpaunnfeduqualif/>
</Route>
<Route path='/addpaunnfeduqualifbulkn'>
<Addpaunnfeduqualifbulkn />
</Route>
<Route path='/editnpaunnfcoeexp'>
<Editnpaunnfcoeexp/>
</Route>
<Route path='/viewnpaunnfcoeexp'>
<Viewnpaunnfcoeexp/>
</Route>
<Route path='/viewnpaunnfcoeexpadmin'>
<Viewnpaunnfcoeexpadmin/>
</Route>
<Route path='/viewpaunnfcoeexpprog'>
<Viewpaunnfcoeexpprog/>
</Route>
<Route path='/addnpaunnfcoeexp'>
<Addnpaunnfcoeexp/>
</Route>
<Route path='/addpaunnfcoeexpbulkn'>
<Addpaunnfcoeexpbulkn />
</Route>
<Route path='/editnpauconfacshe'>
<Editnpauconfacshe/>
</Route>
<Route path='/viewnpauconfacshe'>
<Viewnpauconfacshe/>
</Route>
<Route path='/viewnpauconfacsheadmin'>
<Viewnpauconfacsheadmin/>
</Route>
<Route path='/viewpauconfacsheprog'>
<Viewpauconfacsheprog/>
</Route>
<Route path='/addnpauconfacshe'>
<Addnpauconfacshe/>
</Route>
<Route path='/addpauconfacshebulkn'>
<Addpauconfacshebulkn />
</Route>
<Route path='/editnpauconfaccourse'>
<Editnpauconfaccourse/>
</Route>
<Route path='/viewnpauconfaccourse'>
<Viewnpauconfaccourse/>
</Route>
<Route path='/viewnpauconfaccourseadmin'>
<Viewnpauconfaccourseadmin/>
</Route>
<Route path='/viewpauconfaccourseprog'>
<Viewpauconfaccourseprog/>
</Route>
<Route path='/addnpauconfaccourse'>
<Addnpauconfaccourse/>
</Route>
<Route path='/addpauconfaccoursebulkn'>
<Addpauconfaccoursebulkn />
</Route>
<Route path='/editnpauconfacmemtech'>
<Editnpauconfacmemtech/>
</Route>
<Route path='/viewnpauconfacmemtech'>
<Viewnpauconfacmemtech/>
</Route>
<Route path='/viewnpauconfacmemtechadmin'>
<Viewnpauconfacmemtechadmin/>
</Route>
<Route path='/viewpauconfacmemtechprog'>
<Viewpauconfacmemtechprog/>
</Route>
<Route path='/addnpauconfacmemtech'>
<Addnpauconfacmemtech/>
</Route>
<Route path='/addpauconfacmemtechbulkn'>
<Addpauconfacmemtechbulkn />
</Route>
<Route path='/editnpaunontstaff'>
<Editnpaunontstaff/>
</Route>
<Route path='/viewnpaunontstaff'>
<Viewnpaunontstaff/>
</Route>
<Route path='/viewnpaunontstaffadmin'>
<Viewnpaunontstaffadmin/>
</Route>
<Route path='/viewpaunontstaffprog'>
<Viewpaunontstaffprog/>
</Route>
<Route path='/addnpaunontstaff'>
<Addnpaunontstaff/>
</Route>
<Route path='/addpaunontstaffbulkn'>
<Addpaunontstaffbulkn />
</Route>
<Route path='/editnpauspacereq'>
<Editnpauspacereq/>
</Route>
<Route path='/viewnpauspacereq'>
<Viewnpauspacereq/>
</Route>
<Route path='/viewnpauspacereqadmin'>
<Viewnpauspacereqadmin/>
</Route>
<Route path='/viewpauspacereqprog'>
<Viewpauspacereqprog/>
</Route>
<Route path='/addnpauspacereq'>
<Addnpauspacereq/>
</Route>
<Route path='/addpauspacereqbulkn'>
<Addpauspacereqbulkn />
</Route>
<Route path='/editnpaunequip'>
<Editnpaunequip/>
</Route>
<Route path='/viewnpaunequip'>
<Viewnpaunequip/>
</Route>
<Route path='/viewnpaunequipadmin'>
<Viewnpaunequipadmin/>
</Route>
<Route path='/viewpaunequipprog'>
<Viewpaunequipprog/>
</Route>
<Route path='/addnpaunequip'>
<Addnpaunequip/>
</Route>
<Route path='/addpaunequipbulkn'>
<Addpaunequipbulkn />
</Route>
<Route path='/editnpauccomfarea'>
<Editnpauccomfarea/>
</Route>
<Route path='/viewnpauccomfarea'>
<Viewnpauccomfarea/>
</Route>
<Route path='/viewnpauccomfareaadmin'>
<Viewnpauccomfareaadmin/>
</Route>
<Route path='/viewpauccomfareaprog'>
<Viewpauccomfareaprog/>
</Route>
<Route path='/addnpauccomfarea'>
<Addnpauccomfarea/>
</Route>
<Route path='/addpauccomfareabulkn'>
<Addpauccomfareabulkn />
</Route>
<Route path='/editnpauntermlanwan'>
<Editnpauntermlanwan/>
</Route>
<Route path='/viewnpauntermlanwan'>
<Viewnpauntermlanwan/>
</Route>
<Route path='/viewnpauntermlanwanadmin'>
<Viewnpauntermlanwanadmin/>
</Route>
<Route path='/viewpauntermlanwanprog'>
<Viewpauntermlanwanprog/>
</Route>
<Route path='/addnpauntermlanwan'>
<Addnpauntermlanwan/>
</Route>
<Route path='/addpauntermlanwanbulkn'>
<Addpauntermlanwanbulkn />
</Route>
<Route path='/editnpausoftware'>
<Editnpausoftware/>
</Route>
<Route path='/viewnpausoftware'>
<Viewnpausoftware/>
</Route>
<Route path='/viewnpausoftwareadmin'>
<Viewnpausoftwareadmin/>
</Route>
<Route path='/viewpausoftwareprog'>
<Viewpausoftwareprog/>
</Route>
<Route path='/addnpausoftware'>
<Addnpausoftware/>
</Route>
<Route path='/addpausoftwarebulkn'>
<Addpausoftwarebulkn />
</Route>
<Route path='/editnpaunetwork'>
<Editnpaunetwork/>
</Route>
<Route path='/viewnpaunetwork'>
<Viewnpaunetwork/>
</Route>
<Route path='/viewnpaunetworkadmin'>
<Viewnpaunetworkadmin/>
</Route>
<Route path='/viewpaunetworkprog'>
<Viewpaunetworkprog/>
</Route>
<Route path='/addnpaunetwork'>
<Addnpaunetwork/>
</Route>
<Route path='/addpaunetworkbulkn'>
<Addpaunetworkbulkn />
</Route>
<Route path='/editnpaulibarea'>
<Editnpaulibarea/>
</Route>
<Route path='/viewnpaulibarea'>
<Viewnpaulibarea/>
</Route>
<Route path='/viewnpaulibareaadmin'>
<Viewnpaulibareaadmin/>
</Route>
<Route path='/viewpaulibareaprog'>
<Viewpaulibareaprog/>
</Route>
<Route path='/addnpaulibarea'>
<Addnpaulibarea/>
</Route>
<Route path='/addpaulibareabulkn'>
<Addpaulibareabulkn />
</Route>
<Route path='/editnpaunlibbooks'>
<Editnpaunlibbooks/>
</Route>
<Route path='/viewnpaunlibbooks'>
<Viewnpaunlibbooks/>
</Route>
<Route path='/viewnpaunlibbooksadmin'>
<Viewnpaunlibbooksadmin/>
</Route>
<Route path='/viewpaunlibbooksprog'>
<Viewpaunlibbooksprog/>
</Route>
<Route path='/addnpaunlibbooks'>
<Addnpaunlibbooks/>
</Route>
<Route path='/addpaunlibbooksbulkn'>
<Addpaunlibbooksbulkn />
</Route>
<Route path='/editnpaunlibjournal'>
<Editnpaunlibjournal/>
</Route>
<Route path='/viewnpaunlibjournal'>
<Viewnpaunlibjournal/>
</Route>
<Route path='/viewnpaunlibjournaladmin'>
<Viewnpaunlibjournaladmin/>
</Route>
<Route path='/viewpaunlibjournalprog'>
<Viewpaunlibjournalprog/>
</Route>
<Route path='/addnpaunlibjournal'>
<Addnpaunlibjournal/>
</Route>
<Route path='/addpaunlibjournalbulkn'>
<Addpaunlibjournalbulkn />
</Route>
<Route path='/editnpauclassblock'>
<Editnpauclassblock/>
</Route>
<Route path='/viewnpauclassblock'>
<Viewnpauclassblock/>
</Route>
<Route path='/viewnpauclassblockadmin'>
<Viewnpauclassblockadmin/>
</Route>
<Route path='/viewpauclassblockprog'>
<Viewpauclassblockprog/>
</Route>
<Route path='/addnpauclassblock'>
<Addnpauclassblock/>
</Route>
<Route path='/addpauclassblockbulkn'>
<Addpauclassblockbulkn />
</Route>
<Route path='/editnpauclassnew'>
<Editnpauclassnew/>
</Route>
<Route path='/viewnpauclassnew'>
<Viewnpauclassnew/>
</Route>
<Route path='/viewnpauclassnewadmin'>
<Viewnpauclassnewadmin/>
</Route>
<Route path='/viewpauclassnewprog'>
<Viewpauclassnewprog/>
</Route>
<Route path='/addnpauclassnew'>
<Addnpauclassnew/>
</Route>
<Route path='/addpauclassnewbulkn'>
<Addpauclassnewbulkn />
</Route>
<Route path='/editnpaunnoofdrawhall'>
<Editnpaunnoofdrawhall/>
</Route>
<Route path='/viewnpaunnoofdrawhall'>
<Viewnpaunnoofdrawhall/>
</Route>
<Route path='/viewnpaunnoofdrawhalladmin'>
<Viewnpaunnoofdrawhalladmin/>
</Route>
<Route path='/viewpaunnoofdrawhallprog'>
<Viewpaunnoofdrawhallprog/>
</Route>
<Route path='/addnpaunnoofdrawhall'>
<Addnpaunnoofdrawhall/>
</Route>
<Route path='/addpaunnoofdrawhallbulkn'>
<Addpaunnoofdrawhallbulkn />
</Route>
<Route path='/editnpauadminarea'>
<Editnpauadminarea/>
</Route>
<Route path='/viewnpauadminarea'>
<Viewnpauadminarea/>
</Route>
<Route path='/viewnpauadminareaadmin'>
<Viewnpauadminareaadmin/>
</Route>
<Route path='/viewpauadminareaprog'>
<Viewpauadminareaprog/>
</Route>
<Route path='/addnpauadminarea'>
<Addnpauadminarea/>
</Route>
<Route path='/addpauadminareabulkn'>
<Addpauadminareabulkn />
</Route>
<Route path='/editnpaunamen'>
<Editnpaunamen/>
</Route>
<Route path='/viewnpaunamen'>
<Viewnpaunamen/>
</Route>
<Route path='/viewnpaunamenadmin'>
<Viewnpaunamenadmin/>
</Route>
<Route path='/viewpaunamenprog'>
<Viewpaunamenprog/>
</Route>
<Route path='/addnpaunamen'>
<Addnpaunamen/>
</Route>
<Route path='/addpaunamenbulkn'>
<Addpaunamenbulkn />
</Route>
<Route path='/editnpaubhostel'>
<Editnpaubhostel/>
</Route>
<Route path='/viewnpaubhostel'>
<Viewnpaubhostel/>
</Route>
<Route path='/viewnpaubhosteladmin'>
<Viewnpaubhosteladmin/>
</Route>
<Route path='/viewpaubhostelprog'>
<Viewpaubhostelprog/>
</Route>
<Route path='/addnpaubhostel'>
<Addnpaubhostel/>
</Route>
<Route path='/addpaubhostelbulkn'>
<Addpaubhostelbulkn />
</Route>
<Route path='/editnpaughostel'>
<Editnpaughostel/>
</Route>
<Route path='/viewnpaughostel'>
<Viewnpaughostel/>
</Route>
<Route path='/viewnpaughosteladmin'>
<Viewnpaughosteladmin/>
</Route>
<Route path='/viewpaughostelprog'>
<Viewpaughostelprog/>
</Route>
<Route path='/addnpaughostel'>
<Addnpaughostel/>
</Route>
<Route path='/addpaughostelbulkn'>
<Addpaughostelbulkn />
</Route>
<Route path='/editnpaunotherbuildarea'>
<Editnpaunotherbuildarea/>
</Route>
<Route path='/viewnpaunotherbuildarea'>
<Viewnpaunotherbuildarea/>
</Route>
<Route path='/viewnpaunotherbuildareaadmin'>
<Viewnpaunotherbuildareaadmin/>
</Route>
<Route path='/viewpaunotherbuildareaprog'>
<Viewpaunotherbuildareaprog/>
</Route>
<Route path='/addnpaunotherbuildarea'>
<Addnpaunotherbuildarea/>
</Route>
<Route path='/addpaunotherbuildareabulkn'>
<Addpaunotherbuildareabulkn />
</Route>
<Route path='/editnpauphyedu'>
<Editnpauphyedu/>
</Route>
<Route path='/viewnpauphyedu'>
<Viewnpauphyedu/>
</Route>
<Route path='/viewnpauphyeduadmin'>
<Viewnpauphyeduadmin/>
</Route>
<Route path='/viewpauphyeduprog'>
<Viewpauphyeduprog/>
</Route>
<Route path='/addnpauphyedu'>
<Addnpauphyedu/>
</Route>
<Route path='/addpauphyedubulkn'>
<Addpauphyedubulkn />
</Route>
<Route path='/editnpaufacilityavail'>
<Editnpaufacilityavail/>
</Route>
<Route path='/viewnpaufacilityavail'>
<Viewnpaufacilityavail/>
</Route>
<Route path='/viewnpaufacilityavailadmin'>
<Viewnpaufacilityavailadmin/>
</Route>
<Route path='/viewpaufacilityavailprog'>
<Viewpaufacilityavailprog/>
</Route>
<Route path='/addnpaufacilityavail'>
<Addnpaufacilityavail/>
</Route>
<Route path='/addpaufacilityavailbulkn'>
<Addpaufacilityavailbulkn />
</Route>
<Route path='/editnpaualumni'>
<Editnpaualumni/>
</Route>
<Route path='/viewnpaualumni'>
<Viewnpaualumni/>
</Route>
<Route path='/viewnpaualumniadmin'>
<Viewnpaualumniadmin/>
</Route>
<Route path='/viewpaualumniprog'>
<Viewpaualumniprog/>
</Route>
<Route path='/addnpaualumni'>
<Addnpaualumni/>
</Route>
<Route path='/addpaualumnibulkn'>
<Addpaualumnibulkn />
</Route>
<Route path='/editnpautpc'>
<Editnpautpc/>
</Route>
<Route path='/viewnpautpc'>
<Viewnpautpc/>
</Route>
<Route path='/viewnpautpcadmin'>
<Viewnpautpcadmin/>
</Route>
<Route path='/viewpautpcprog'>
<Viewpautpcprog/>
</Route>
<Route path='/addnpautpc'>
<Addnpautpc/>
</Route>
<Route path='/addpautpcbulkn'>
<Addpautpcbulkn />
</Route>
<Route path='/editnpaunhealth'>
<Editnpaunhealth/>
</Route>
<Route path='/viewnpaunhealth'>
<Viewnpaunhealth/>
</Route>
<Route path='/viewnpaunhealthadmin'>
<Viewnpaunhealthadmin/>
</Route>
<Route path='/viewpaunhealthprog'>
<Viewpaunhealthprog/>
</Route>
<Route path='/addnpaunhealth'>
<Addnpaunhealth/>
</Route>
<Route path='/addpaunhealthbulkn'>
<Addpaunhealthbulkn />
</Route>
<Route path='/editnpauothamen'>
<Editnpauothamen/>
</Route>
<Route path='/viewnpauothamen'>
<Viewnpauothamen/>
</Route>
<Route path='/viewnpauothamenadmin'>
<Viewnpauothamenadmin/>
</Route>
<Route path='/viewpauothamenprog'>
<Viewpauothamenprog/>
</Route>
<Route path='/addnpauothamen'>
<Addnpauothamen/>
</Route>
<Route path='/addpauothamenbulkn'>
<Addpauothamenbulkn />
</Route>
<Route path='/editnpauregrec'>
<Editnpauregrec/>
</Route>
<Route path='/viewnpauregrec'>
<Viewnpauregrec/>
</Route>
<Route path='/viewnpauregrecadmin'>
<Viewnpauregrecadmin/>
</Route>
<Route path='/viewpauregrecprog'>
<Viewpauregrecprog/>
</Route>
<Route path='/addnpauregrec'>
<Addnpauregrec/>
</Route>
<Route path='/addpauregrecbulkn'>
<Addpauregrecbulkn />
</Route>
<Route path='/editnpaucertificates'>
<Editnpaucertificates/>
</Route>
<Route path='/viewnpaucertificates'>
<Viewnpaucertificates/>
</Route>
<Route path='/viewnpaucertificatesadmin'>
<Viewnpaucertificatesadmin/>
</Route>
<Route path='/viewpaucertificatesprog'>
<Viewpaucertificatesprog/>
</Route>
<Route path='/addnpaucertificates'>
<Addnpaucertificates/>
</Route>
<Route path='/addpaucertificatesbulkn'>
<Addpaucertificatesbulkn />
</Route>
<Route path='/editnpaunprocfee'>
<Editnpaunprocfee/>
</Route>
<Route path='/viewnpaunprocfee'>
<Viewnpaunprocfee/>
</Route>
<Route path='/viewnpaunprocfeeadmin'>
<Viewnpaunprocfeeadmin/>
</Route>
<Route path='/viewpaunprocfeeprog'>
<Viewpaunprocfeeprog/>
</Route>
<Route path='/addnpaunprocfee'>
<Addnpaunprocfee/>
</Route>
<Route path='/addpaunprocfeebulkn'>
<Addpaunprocfeebulkn />
</Route>
<Route path='/editnpauprcplendrsmnt'>
<Editnpauprcplendrsmnt/>
</Route>
<Route path='/viewnpauprcplendrsmnt'>
<Viewnpauprcplendrsmnt/>
</Route>
<Route path='/viewnpauprcplendrsmntadmin'>
<Viewnpauprcplendrsmntadmin/>
</Route>
<Route path='/viewpauprcplendrsmntprog'>
<Viewpauprcplendrsmntprog/>
</Route>
<Route path='/addnpauprcplendrsmnt'>
<Addnpauprcplendrsmnt/>
</Route>
<Route path='/addpauprcplendrsmntbulkn'>
<Addpauprcplendrsmntbulkn />
</Route>
<Route path='/editnpaumngdeclaration'>
<Editnpaumngdeclaration/>
</Route>
<Route path='/viewnpaumngdeclaration'>
<Viewnpaumngdeclaration/>
</Route>
<Route path='/viewnpaumngdeclarationadmin'>
<Viewnpaumngdeclarationadmin/>
</Route>
<Route path='/viewpaumngdeclarationprog'>
<Viewpaumngdeclarationprog/>
</Route>
<Route path='/addnpaumngdeclaration'>
<Addnpaumngdeclaration/>
</Route>
<Route path='/addpaumngdeclarationbulkn'>
<Addpaumngdeclarationbulkn />
</Route>


    <Route path='/editnainst'>
<Editnainst/>
</Route>
<Route path='/viewnainst'>
<Viewnainst/>
</Route>
<Route path='/viewnainstadmin'>
<Viewnainstadmin/>
</Route>
<Route path='/viewainstprog'>
<Viewainstprog/>
</Route>
<Route path='/addnainst'>
<Addnainst/>
</Route>
<Route path='/addainstbulkn'>
<Addainstbulkn />
</Route>


    <Route path='/viewautonomousmenu'>
<Viewautonomousmenu/>
</Route>
    <Route path='/viewaffiliatedmenu'>
<Viewaffiliatedmenu/>
</Route>
    <Route path='/viewlegaluniversitymenu'>
<Viewlegaluniversitymenu/>
</Route>

    <Route path='/editnnluteacherdata'>
<Editnnluteacherdata/>
</Route>
<Route path='/viewnnluteacherdata'>
<Viewnnluteacherdata/>
</Route>
<Route path='/viewnnluteacherdataadmin'>
<Viewnnluteacherdataadmin/>
</Route>
<Route path='/viewnluteacherdataprog'>
<Viewnluteacherdataprog/>
</Route>
<Route path='/addnnluteacherdata'>
<Addnnluteacherdata/>
</Route>
<Route path='/addnluteacherdatabulkn'>
<Addnluteacherdatabulkn />
</Route>
<Route path='/editnnlufacilities'>
<Editnnlufacilities/>
</Route>
<Route path='/viewnnlufacilities'>
<Viewnnlufacilities/>
</Route>
<Route path='/viewnnlufacilitiesadmin'>
<Viewnnlufacilitiesadmin/>
</Route>
<Route path='/viewnlufacilitiesprog'>
<Viewnlufacilitiesprog/>
</Route>
<Route path='/addnnlufacilities'>
<Addnnlufacilities/>
</Route>
<Route path='/addnlufacilitiesbulkn'>
<Addnlufacilitiesbulkn />
</Route>
<Route path='/editnnlurecognitions'>
<Editnnlurecognitions/>
</Route>
<Route path='/viewnnlurecognitions'>
<Viewnnlurecognitions/>
</Route>
<Route path='/viewnnlurecognitionsadmin'>
<Viewnnlurecognitionsadmin/>
</Route>
<Route path='/viewnlurecognitionsprog'>
<Viewnlurecognitionsprog/>
</Route>
<Route path='/addnnlurecognitions'>
<Addnnlurecognitions/>
</Route>
<Route path='/addnlurecognitionsbulkn'>
<Addnlurecognitionsbulkn />
</Route>
<Route path='/editnnlubills'>
<Editnnlubills/>
</Route>
<Route path='/viewnnlubills'>
<Viewnnlubills/>
</Route>
<Route path='/viewnnlubillsadmin'>
<Viewnnlubillsadmin/>
</Route>
<Route path='/viewnlubillsprog'>
<Viewnlubillsprog/>
</Route>
<Route path='/addnnlubills'>
<Addnnlubills/>
</Route>
<Route path='/addnlubillsbulkn'>
<Addnlubillsbulkn />
</Route>
<Route path='/editnnluincentives'>
<Editnnluincentives/>
</Route>
<Route path='/viewnnluincentives'>
<Viewnnluincentives/>
</Route>
<Route path='/viewnnluincentivesadmin'>
<Viewnnluincentivesadmin/>
</Route>
<Route path='/viewnluincentivesprog'>
<Viewnluincentivesprog/>
</Route>
<Route path='/addnnluincentives'>
<Addnnluincentives/>
</Route>
<Route path='/addnluincentivesbulkn'>
<Addnluincentivesbulkn />
</Route>
<Route path='/editnnluphdtheisis'>
<Editnnluphdtheisis/>
</Route>
<Route path='/viewnnluphdtheisis'>
<Viewnnluphdtheisis/>
</Route>
<Route path='/viewnnluphdtheisisadmin'>
<Viewnnluphdtheisisadmin/>
</Route>
<Route path='/viewnluphdtheisisprog'>
<Viewnluphdtheisisprog/>
</Route>
<Route path='/addnnluphdtheisis'>
<Addnnluphdtheisis/>
</Route>
<Route path='/addnluphdtheisisbulkn'>
<Addnluphdtheisisbulkn />
</Route>
<Route path='/editnnlucitation'>
<Editnnlucitation/>
</Route>
<Route path='/viewnnlucitation'>
<Viewnnlucitation/>
</Route>
<Route path='/viewnnlucitationadmin'>
<Viewnnlucitationadmin/>
</Route>
<Route path='/viewnlucitationprog'>
<Viewnlucitationprog/>
</Route>
<Route path='/addnnlucitation'>
<Addnnlucitation/>
</Route>
<Route path='/addnlucitationbulkn'>
<Addnlucitationbulkn />
</Route>
<Route path='/editnnlulegaldatabases'>
<Editnnlulegaldatabases/>
</Route>
<Route path='/viewnnlulegaldatabases'>
<Viewnnlulegaldatabases/>
</Route>
<Route path='/viewnnlulegaldatabasesadmin'>
<Viewnnlulegaldatabasesadmin/>
</Route>
<Route path='/viewnlulegaldatabasesprog'>
<Viewnlulegaldatabasesprog/>
</Route>
<Route path='/addnnlulegaldatabases'>
<Addnnlulegaldatabases/>
</Route>
<Route path='/addnlulegaldatabasesbulkn'>
<Addnlulegaldatabasesbulkn />
</Route>
<Route path='/editnnlbar'>
<Editnnlbar/>
</Route>
<Route path='/viewnnlbar'>
<Viewnnlbar/>
</Route>
<Route path='/viewnnlbaradmin'>
<Viewnnlbaradmin/>
</Route>
<Route path='/viewnlbarprog'>
<Viewnlbarprog/>
</Route>
<Route path='/addnnlbar'>
<Addnnlbar/>
</Route>
<Route path='/addnlbarbulkn'>
<Addnlbarbulkn />
</Route>


    <Route path='/viewuniversitymetricnew'>
<Viewuniversitymetricnew/>
</Route>
    <Route path='/editnexplearning'>
<Editnexplearning/>
</Route>
<Route path='/viewnexplearning'>
<Viewnexplearning/>
</Route>
<Route path='/viewnexplearningadmin'>
<Viewnexplearningadmin/>
</Route>
<Route path='/viewexplearningprog'>
<Viewexplearningprog/>
</Route>
<Route path='/addnexplearning'>
<Addnexplearning/>
</Route>
<Route path='/addexplearningbulkn'>
<Addexplearningbulkn />
</Route>
<Route path='/editnnufacultydata'>
<Editnnufacultydata/>
</Route>
<Route path='/viewnnufacultydata'>
<Viewnnufacultydata/>
</Route>
<Route path='/viewnnufacultydataadmin'>
<Viewnnufacultydataadmin/>
</Route>
<Route path='/viewnufacultydataprog'>
<Viewnufacultydataprog/>
</Route>
<Route path='/addnnufacultydata'>
<Addnnufacultydata/>
</Route>
<Route path='/addnufacultydatabulkn'>
<Addnufacultydatabulkn />
</Route>
<Route path='/editnphdface'>
<Editnphdface/>
</Route>
<Route path='/viewnphdface'>
<Viewnphdface/>
</Route>
<Route path='/viewnphdfaceadmin'>
<Viewnphdfaceadmin/>
</Route>
<Route path='/viewphdfaceprog'>
<Viewphdfaceprog/>
</Route>
<Route path='/addnphdface'>
<Addnphdface/>
</Route>
<Route path='/addphdfacebulkn'>
<Addphdfacebulkn />
</Route>
<Route path='/editnteacherdata'>
<Editnteacherdata/>
</Route>
<Route path='/viewnteacherdata'>
<Viewnteacherdata/>
</Route>
<Route path='/viewnteacherdataadmin'>
<Viewnteacherdataadmin/>
</Route>
<Route path='/viewteacherdataprog'>
<Viewteacherdataprog/>
</Route>
<Route path='/addnteacherdata'>
<Addnteacherdata/>
</Route>
<Route path='/addteacherdatabulkn'>
<Addteacherdatabulkn />
</Route>
<Route path='/editnresult'>
<Editnresult/>
</Route>
<Route path='/viewnresult'>
<Viewnresult/>
</Route>
<Route path='/viewnresultadmin'>
<Viewnresultadmin/>
</Route>
<Route path='/viewresultprog'>
<Viewresultprog/>
</Route>
<Route path='/addnresult'>
<Addnresult/>
</Route>
<Route path='/addresultbulkn'>
<Addresultbulkn />
</Route>
<Route path='/editnnugrievance'>
<Editnnugrievance/>
</Route>
<Route path='/viewnnugrievance'>
<Viewnnugrievance/>
</Route>
<Route path='/viewnnugrievanceadmin'>
<Viewnnugrievanceadmin/>
</Route>
<Route path='/viewnugrievanceprog'>
<Viewnugrievanceprog/>
</Route>
<Route path='/addnnugrievance'>
<Addnnugrievance/>
</Route>
<Route path='/addnugrievancebulkn'>
<Addnugrievancebulkn />
</Route>
<Route path='/editnpasspercent'>
<Editnpasspercent/>
</Route>
<Route path='/viewnpasspercent'>
<Viewnpasspercent/>
</Route>
<Route path='/viewnpasspercentadmin'>
<Viewnpasspercentadmin/>
</Route>
<Route path='/viewpasspercentprog'>
<Viewpasspercentprog/>
</Route>
<Route path='/addnpasspercent'>
<Addnpasspercent/>
</Route>
<Route path='/addpasspercentbulkn'>
<Addpasspercentbulkn />
</Route>
<Route path='/editnteacherfellow'>
<Editnteacherfellow/>
</Route>
<Route path='/viewnteacherfellow'>
<Viewnteacherfellow/>
</Route>
<Route path='/viewnteacherfellowadmin'>
<Viewnteacherfellowadmin/>
</Route>
<Route path='/viewteacherfellowprog'>
<Viewteacherfellowprog/>
</Route>
<Route path='/addnteacherfellow'>
<Addnteacherfellow/>
</Route>
<Route path='/addteacherfellowbulkn'>
<Addteacherfellowbulkn />
</Route>
<Route path='/editnpatents'>
<Editnpatents/>
</Route>
<Route path='/viewnpatents'>
<Viewnpatents/>
</Route>
<Route path='/viewnpatentsadmin'>
<Viewnpatentsadmin/>
</Route>
<Route path='/viewpatentsprog'>
<Viewpatentsprog/>
</Route>
<Route path='/addnpatents'>
<Addnpatents/>
</Route>
<Route path='/addpatentsbulkn'>
<Addpatentsbulkn />
</Route>
<Route path='/editnconsultancy'>
<Editnconsultancy/>
</Route>
<Route path='/viewnconsultancy'>
<Viewnconsultancy/>
</Route>
<Route path='/viewnconsultancyadmin'>
<Viewnconsultancyadmin/>
</Route>
<Route path='/viewconsultancyprog'>
<Viewconsultancyprog/>
</Route>
<Route path='/addnconsultancy'>
<Addnconsultancy/>
</Route>
<Route path='/addconsultancybulkn'>
<Addconsultancybulkn />
</Route>
<Route path='/editnmou'>
<Editnmou/>
</Route>
<Route path='/viewnmou'>
<Viewnmou/>
</Route>
<Route path='/viewnmouadmin'>
<Viewnmouadmin/>
</Route>
<Route path='/viewmouprog'>
<Viewmouprog/>
</Route>
<Route path='/addnmou'>
<Addnmou/>
</Route>
<Route path='/addmoubulkn'>
<Addmoubulkn />
</Route>
<Route path='/editnnuefacilities'>
<Editnnuefacilities/>
</Route>
<Route path='/viewnnuefacilities'>
<Viewnnuefacilities/>
</Route>
<Route path='/viewnnuefacilitiesadmin'>
<Viewnnuefacilitiesadmin/>
</Route>
<Route path='/viewnuefacilitiesprog'>
<Viewnuefacilitiesprog/>
</Route>
<Route path='/addnnuefacilities'>
<Addnnuefacilities/>
</Route>
<Route path='/addnuefacilitiesbulkn'>
<Addnuefacilitiesbulkn />
</Route>
<Route path='/editnnustudcompratio'>
<Editnnustudcompratio/>
</Route>
<Route path='/viewnnustudcompratio'>
<Viewnnustudcompratio/>
</Route>
<Route path='/viewnnustudcompratioadmin'>
<Viewnnustudcompratioadmin/>
</Route>
<Route path='/viewnustudcompratioprog'>
<Viewnustudcompratioprog/>
</Route>
<Route path='/addnnustudcompratio'>
<Addnnustudcompratio/>
</Route>
<Route path='/addnustudcompratiobulkn'>
<Addnustudcompratiobulkn />
</Route>
<Route path='/editnawards'>
<Editnawards/>
</Route>
<Route path='/viewnawards'>
<Viewnawards/>
</Route>
<Route path='/viewnawardsadmin'>
<Viewnawardsadmin/>
</Route>
<Route path='/viewawardsprog'>
<Viewawardsprog/>
</Route>
<Route path='/addnawards'>
<Addnawards/>
</Route>
<Route path='/addawardsbulkn'>
<Addawardsbulkn />
</Route>
<Route path='/editnnlibusage'>
<Editnnlibusage/>
</Route>
<Route path='/viewnnlibusage'>
<Viewnnlibusage/>
</Route>
<Route path='/viewnnlibusageadmin'>
<Viewnnlibusageadmin/>
</Route>
<Route path='/viewnlibusageprog'>
<Viewnlibusageprog/>
</Route>
<Route path='/addnnlibusage'>
<Addnnlibusage/>
</Route>
<Route path='/addnlibusagebulkn'>
<Addnlibusagebulkn />
</Route>
<Route path='/editnhigherexam'>
<Editnhigherexam/>
</Route>
<Route path='/viewnhigherexam'>
<Viewnhigherexam/>
</Route>
<Route path='/viewnhigherexamadmin'>
<Viewnhigherexamadmin/>
</Route>
<Route path='/viewhigherexamprog'>
<Viewhigherexamprog/>
</Route>
<Route path='/addnhigherexam'>
<Addnhigherexam/>
</Route>
<Route path='/addhigherexambulkn'>
<Addhigherexambulkn />
</Route>



    {/* <Route path='/editnnlibusage'>
<Editnnlibusage/>
</Route>
<Route path='/viewnnlibusage'>
<Viewnnlibusage/>
</Route>
<Route path='/viewnnlibusageadmin'>
<Viewnnlibusageadmin/>
</Route>
<Route path='/viewnlibusageprog'>
<Viewnlibusageprog/>
</Route>
<Route path='/addnnlibusage'>
<Addnnlibusage/>
</Route>
<Route path='/addnlibusagebulkn'>
<Addnlibusagebulkn />
</Route> */}

    <Route path='/viewnnmytasksuser'>
<Viewnnmytasksuser/>
</Route>
    <Route path='/editnnmytasksuser'>
<Editnnmytasksuser/>
</Route>
    <Route path='/editnnqualification'>
<Editnnqualification/>
</Route>
<Route path='/viewnnqualification'>
<Viewnnqualification/>
</Route>
<Route path='/viewnnqualificationadmin'>
<Viewnnqualificationadmin/>
</Route>
<Route path='/viewnqualificationprog'>
<Viewnqualificationprog/>
</Route>
<Route path='/addnnqualification'>
<Addnnqualification/>
</Route>
<Route path='/addnqualificationbulkn'>
<Addnqualificationbulkn />
</Route>
<Route path='/editnnexperience'>
<Editnnexperience/>
</Route>
<Route path='/viewnnexperience'>
<Viewnnexperience/>
</Route>
<Route path='/viewnnexperienceadmin'>
<Viewnnexperienceadmin/>
</Route>
<Route path='/viewnexperienceprog'>
<Viewnexperienceprog/>
</Route>
<Route path='/addnnexperience'>
<Addnnexperience/>
</Route>
<Route path='/addnexperiencebulkn'>
<Addnexperiencebulkn />
</Route>
<Route path='/editnnmytasks'>
<Editnnmytasks/>
</Route>
<Route path='/viewnnmytasks'>
<Viewnnmytasks/>
</Route>
<Route path='/viewnnmytasksadmin'>
<Viewnnmytasksadmin/>
</Route>
<Route path='/viewnmytasksprog'>
<Viewnmytasksprog/>
</Route>
<Route path='/addnnmytasks'>
<Addnnmytasks/>
</Route>
<Route path='/addnmytasksbulkn'>
<Addnmytasksbulkn />
</Route>
<Route path='/editnsalarydetails'>
<Editnsalarydetails/>
</Route>
<Route path='/viewnsalarydetails'>
<Viewnsalarydetails/>
</Route>
<Route path='/viewnsalarydetailsadmin'>
<Viewnsalarydetailsadmin/>
</Route>
<Route path='/viewsalarydetailsprog'>
<Viewsalarydetailsprog/>
</Route>
<Route path='/addnsalarydetails'>
<Addnsalarydetails/>
</Route>
<Route path='/addsalarydetailsbulkn'>
<Addsalarydetailsbulkn />
</Route>
<Route path='/editnempapprec'>
<Editnempapprec/>
</Route>
<Route path='/viewnempapprec'>
<Viewnempapprec/>
</Route>
<Route path='/viewnempapprecadmin'>
<Viewnempapprecadmin/>
</Route>
<Route path='/viewempapprecprog'>
<Viewempapprecprog/>
</Route>
<Route path='/addnempapprec'>
<Addnempapprec/>
</Route>
<Route path='/addempapprecbulkn'>
<Addempapprecbulkn />
</Route>


    <Route path='/editnrnphospitals'>
<Editnrnphospitals/>
</Route>
<Route path='/viewnrnphospitals'>
<Viewnrnphospitals/>
</Route>
<Route path='/viewnrnphospitalsadmin'>
<Viewnrnphospitalsadmin/>
</Route>
<Route path='/viewrnphospitalsprog'>
<Viewrnphospitalsprog/>
</Route>
<Route path='/addnrnphospitals'>
<Addnrnphospitals/>
</Route>
<Route path='/addrnphospitalsbulkn'>
<Addrnphospitalsbulkn />
</Route>
<Route path='/editnrnbuilding'>
<Editnrnbuilding/>
</Route>
<Route path='/viewnrnbuilding'>
<Viewnrnbuilding/>
</Route>
<Route path='/viewnrnbuildingadmin'>
<Viewnrnbuildingadmin/>
</Route>
<Route path='/viewrnbuildingprog'>
<Viewrnbuildingprog/>
</Route>
<Route path='/addnrnbuilding'>
<Addnrnbuilding/>
</Route>
<Route path='/addrnbuildingbulkn'>
<Addrnbuildingbulkn />
</Route>



    <Route path='/editnrnotherc'>
<Editnrnotherc/>
</Route>
<Route path='/viewnrnotherc'>
<Viewnrnotherc/>
</Route>
<Route path='/viewnrnothercadmin'>
<Viewnrnothercadmin/>
</Route>
<Route path='/viewrnothercprog'>
<Viewrnothercprog/>
</Route>
<Route path='/addnrnotherc'>
<Addnrnotherc/>
</Route>
<Route path='/addrnothercbulkn'>
<Addrnothercbulkn />
</Route>
<Route path='/editnrnclassroom'>
<Editnrnclassroom/>
</Route>
<Route path='/viewnrnclassroom'>
<Viewnrnclassroom/>
</Route>
<Route path='/viewnrnclassroomadmin'>
<Viewnrnclassroomadmin/>
</Route>
<Route path='/viewrnclassroomprog'>
<Viewrnclassroomprog/>
</Route>
<Route path='/addnrnclassroom'>
<Addnrnclassroom/>
</Route>
<Route path='/addrnclassroombulkn'>
<Addrnclassroombulkn />
</Route>
<Route path='/editnrnlabs'>
<Editnrnlabs/>
</Route>
<Route path='/viewnrnlabs'>
<Viewnrnlabs/>
</Route>
<Route path='/viewnrnlabsadmin'>
<Viewnrnlabsadmin/>
</Route>
<Route path='/viewrnlabsprog'>
<Viewrnlabsprog/>
</Route>
<Route path='/addnrnlabs'>
<Addnrnlabs/>
</Route>
<Route path='/addrnlabsbulkn'>
<Addrnlabsbulkn />
</Route>
<Route path='/editnrnvehicle'>
<Editnrnvehicle/>
</Route>
<Route path='/viewnrnvehicle'>
<Viewnrnvehicle/>
</Route>
<Route path='/viewnrnvehicleadmin'>
<Viewnrnvehicleadmin/>
</Route>
<Route path='/viewrnvehicleprog'>
<Viewrnvehicleprog/>
</Route>
<Route path='/addnrnvehicle'>
<Addnrnvehicle/>
</Route>
<Route path='/addrnvehiclebulkn'>
<Addrnvehiclebulkn />
</Route>
<Route path='/editnrnlibrarian'>
<Editnrnlibrarian/>
</Route>
<Route path='/viewnrnlibrarian'>
<Viewnrnlibrarian/>
</Route>
<Route path='/viewnrnlibrarianadmin'>
<Viewnrnlibrarianadmin/>
</Route>
<Route path='/viewrnlibrarianprog'>
<Viewrnlibrarianprog/>
</Route>
<Route path='/addnrnlibrarian'>
<Addnrnlibrarian/>
</Route>
<Route path='/addrnlibrarianbulkn'>
<Addrnlibrarianbulkn />
</Route>
<Route path='/editnrnadministrative'>
<Editnrnadministrative/>
</Route>
<Route path='/viewnrnadministrative'>
<Viewnrnadministrative/>
</Route>
<Route path='/viewnrnadministrativeadmin'>
<Viewnrnadministrativeadmin/>
</Route>
<Route path='/viewrnadministrativeprog'>
<Viewrnadministrativeprog/>
</Route>
<Route path='/addnrnadministrative'>
<Addnrnadministrative/>
</Route>
<Route path='/addrnadministrativebulkn'>
<Addrnadministrativebulkn />
</Route>
<Route path='/editnrnaffhospital'>
<Editnrnaffhospital/>
</Route>
<Route path='/viewnrnaffhospital'>
<Viewnrnaffhospital/>
</Route>
<Route path='/viewnrnaffhospitaladmin'>
<Viewnrnaffhospitaladmin/>
</Route>
<Route path='/viewrnaffhospitalprog'>
<Viewrnaffhospitalprog/>
</Route>
<Route path='/addnrnaffhospital'>
<Addnrnaffhospital/>
</Route>
<Route path='/addrnaffhospitalbulkn'>
<Addrnaffhospitalbulkn />
</Route>
<Route path='/editnrndocuments'>
<Editnrndocuments/>
</Route>
<Route path='/viewnrndocuments'>
<Viewnrndocuments/>
</Route>
<Route path='/viewnrndocumentsadmin'>
<Viewnrndocumentsadmin/>
</Route>
<Route path='/viewrndocumentsprog'>
<Viewrndocumentsprog/>
</Route>
<Route path='/addnrndocuments'>
<Addnrndocuments/>
</Route>
<Route path='/addrndocumentsbulkn'>
<Addrndocumentsbulkn />
</Route>
<Route path='/editnrnonteaching'>
<Editnrnonteaching/>
</Route>
<Route path='/viewnrnonteaching'>
<Viewnrnonteaching/>
</Route>
<Route path='/viewnrnonteachingadmin'>
<Viewnrnonteachingadmin/>
</Route>
<Route path='/viewrnonteachingprog'>
<Viewrnonteachingprog/>
</Route>
<Route path='/addnrnonteaching'>
<Addnrnonteaching/>
</Route>
<Route path='/addrnonteachingbulkn'>
<Addrnonteachingbulkn />
</Route>



    <Route path='/editnrinstitute'>
<Editnrinstitute/>
</Route>
<Route path='/viewnrinstitute'>
<Viewnrinstitute/>
</Route>
<Route path='/viewnrinstituteadmin'>
<Viewnrinstituteadmin/>
</Route>
<Route path='/viewrinstituteprog'>
<Viewrinstituteprog/>
</Route>
<Route path='/addnrinstitute'>
<Addnrinstitute/>
</Route>
<Route path='/addrinstitutebulkn'>
<Addrinstitutebulkn />
</Route>



    <Route path='/editnrapplication'>
<Editnrapplication/>
</Route>
<Route path='/viewnrapplication'>
<Viewnrapplication/>
</Route>
<Route path='/viewnrapplicationadmin'>
<Viewnrapplicationadmin/>
</Route>
<Route path='/viewrapplicationprog'>
<Viewrapplicationprog/>
</Route>
<Route path='/addnrapplication'>
<Addnrapplication/>
</Route>
<Route path='/addrapplicationbulkn'>
<Addrapplicationbulkn />
</Route>
<Route path='/editnrtrust'>
<Editnrtrust/>
</Route>
<Route path='/viewnrtrust'>
<Viewnrtrust/>
</Route>
<Route path='/viewnrtrustadmin'>
<Viewnrtrustadmin/>
</Route>
<Route path='/viewrtrustprog'>
<Viewrtrustprog/>
</Route>
<Route path='/addnrtrust'>
<Addnrtrust/>
</Route>
<Route path='/addrtrustbulkn'>
<Addrtrustbulkn />
</Route>


    <Route path='/editnopencourse'>
<Editnopencourse/>
</Route>
<Route path='/viewnopencourse'>
<Viewnopencourse/>
</Route>
<Route path='/viewnopencourseadmin'>
<Viewnopencourseadmin/>
</Route>
<Route path='/viewopencourseprog'>
<Viewopencourseprog/>
</Route>
<Route path='/addnopencourse'>
<Addnopencourse/>
</Route>
<Route path='/addopencoursebulkn'>
<Addopencoursebulkn />
</Route>
<Route path='/editnopenmodules'>
<Editnopenmodules/>
</Route>
<Route path='/viewnopenmodules'>
<Viewnopenmodules/>
</Route>
<Route path='/viewnopenmodulesadmin'>
<Viewnopenmodulesadmin/>
</Route>
<Route path='/viewopenmodulesprog'>
<Viewopenmodulesprog/>
</Route>
<Route path='/addnopenmodules'>
<Addnopenmodules/>
</Route>
<Route path='/addopenmodulesbulkn'>
<Addopenmodulesbulkn />
</Route>


    <Route path='/viewpayslipdetailsbyid'>
<Viewpayslipdetailsbyid/>
</Route>
    <Route path='/viewnpayslipdetailsadmiid'>
<Viewnpayslipdetailsadmiid/>
</Route>
    <Route path='/editnpayslips'>
<Editnpayslips/>
</Route>
<Route path='/viewnpayslips'>
<Viewnpayslips/>
</Route>
<Route path='/viewnpayslipsadmin'>
<Viewnpayslipsadmin/>
</Route>
<Route path='/viewpayslipsprog'>
<Viewpayslipsprog/>
</Route>
<Route path='/addnpayslips'>
<Addnpayslips/>
</Route>
<Route path='/addpayslipsbulkn'>
<Addpayslipsbulkn />
</Route>
<Route path='/editnpayslipdetails'>
<Editnpayslipdetails/>
</Route>
<Route path='/viewnpayslipdetails'>
<Viewnpayslipdetails/>
</Route>
<Route path='/viewnpayslipdetailsadmin'>
<Viewnpayslipdetailsadmin/>
</Route>
<Route path='/viewpayslipdetailsprog'>
<Viewpayslipdetailsprog/>
</Route>
<Route path='/addnpayslipdetails'>
<Addnpayslipdetails/>
</Route>
<Route path='/addpayslipdetailsbulkn'>
<Addpayslipdetailsbulkn />
</Route>


    <Route path='/viewexplearningprojlinks'>
<Viewexplearningprojlinks/>
</Route>
    <Route path='/viewplacementdoclinks'>
<Viewplacementdoclinks/>
</Route>
    <Route path='/viewphdguidedoclinks'>
<Viewphdguidedoclinks/>
</Route>
    <Route path='/viewnphdfacadminlinks'>
<Viewnphdfacadminlinks/>
</Route>
    <Route path='/viewconsultancydlinks'>
<Viewconsultancydlinks/>
</Route>
    <Route path='/viewcollabdoccommentsdlinks'>
<Viewcollabdoccommentsdlinks/>
</Route>
    <Route path='/viewnseedmadminlinks'>
<Viewnseedmadminlinks/>
</Route>
    <Route path='/viewqualityinitdlinks'>
<Viewqualityinitdlinks/>
</Route>
    <Route path='/viewqualitydlinks'>
<Viewqualitydlinks/>
</Route>
    <Route path='/viewprojectyrdocsdlinks'>
<Viewprojectyrdocsdlinks/>
</Route>
    <Route path='/viewneventadmintype'>
<Viewneventadmintype/>
</Route>
<Route path='/viewneventadmintypelinks'>
<Viewneventadmintypelinks/>
</Route>
    <Route path='/selecttype'>
<Selecttype/>
</Route>
    <Route path='/viewnmenteesadminlinks'>
<Viewnmenteesadminlinks/>
</Route>
    <Route path='/viewnotherstudadminlinks'>
<Viewnotherstudadminlinks/>
</Route>
    <Route path='/viewninteradminlinks'>
<Viewninteradminlinks/>
</Route>
    <Route path='/viewnnpatientsadminlinks'>
<Viewnnpatientsadminlinks/>
</Route>
    <Route path='/viewextawardsdlinks'>
<Viewextawardsdlinks/>
</Route>
    <Route path='/viewnrevaladminlinks'>
<Viewnrevaladminlinks/>
</Route>
    <Route path='/viewfacultycadre'>
<Viewfacultycadre/>
</Route>
    <Route path='/viewsfrapi'>
<Viewsfrapi/>
</Route>
    <Route path='/viewadmissionperfapi'>
<Viewadmissionperfapi/>
</Route>
    <Route path='/viewacadapi'>
<Viewacadapi/>
</Route>
    <Route path='/editnacadperf'>
<Editnacadperf/>
</Route>
<Route path='/viewnacadperf'>
<Viewnacadperf/>
</Route>
<Route path='/viewnacadperfadmin'>
<Viewnacadperfadmin/>
</Route>
<Route path='/viewacadperfprog'>
<Viewacadperfprog/>
</Route>
<Route path='/addnacadperf'>
<Addnacadperf/>
</Route>
<Route path='/addacadperfbulkn'>
<Addacadperfbulkn />
</Route>

<Route path='/viewplacementindex1'>
<Viewplacementindex1/>
</Route>
    <Route path='/viewplacementindex'>
<Viewplacementindex/>
</Route>
    <Route path='/editnstartup'>
<Editnstartup/>
</Route>
<Route path='/viewnstartup'>
<Viewnstartup/>
</Route>
<Route path='/viewnstartupadmin'>
<Viewnstartupadmin/>
</Route>
<Route path='/viewstartupprog'>
<Viewstartupprog/>
</Route>
<Route path='/addnstartup'>
<Addnstartup/>
</Route>
<Route path='/addstartupbulkn'>
<Addstartupbulkn />
</Route>


    <Route path='/viewnfacilitiesadminlinksnew'>
<Viewnfacilitiesadminlinksnew/>
</Route>
    <Route path='/viewsyllabusrevdocsdt'>
<Viewsyllabusrevdocsdt/>
</Route>
    <Route path='/viewnnursing424adminlinks'>
<Viewnnursing424adminlinks/>
</Route>
    <Route path='/editnstudmasternew'>
<Editnstudmasternew/>
</Route>
<Route path='/viewnstudmasternew'>
<Viewnstudmasternew/>
</Route>
<Route path='/viewnstudmasternewadmin'>
<Viewnstudmasternewadmin/>
</Route>
<Route path='/viewstudmasternewprog'>
<Viewstudmasternewprog/>
</Route>
<Route path='/addnstudmasternew'>
<Addnstudmasternew/>
</Route>
<Route path='/addstudmasternewbulkn'>
<Addstudmasternewbulkn />
</Route>

    <Route path='/viewnalumniconlinks'>
<Viewnalumniconlinks/>
</Route>
    <Route path='/viewnteacherfsadminlinks'>
<Viewnteacherfsadminlinks/>
</Route>
    <Route path='/editnlabsafety'>
<Editnlabsafety/>
</Route>
<Route path='/viewnlabsafety'>
<Viewnlabsafety/>
</Route>
<Route path='/viewnlabsafetyadmin'>
<Viewnlabsafetyadmin/>
</Route>
<Route path='/viewlabsafetyprog'>
<Viewlabsafetyprog/>
</Route>
<Route path='/addnlabsafety'>
<Addnlabsafety/>
</Route>
<Route path='/addlabsafetybulkn'>
<Addlabsafetybulkn />
</Route>
<Route path='/editnlabequipments'>
<Editnlabequipments/>
</Route>
<Route path='/viewnlabequipments'>
<Viewnlabequipments/>
</Route>
<Route path='/viewnlabequipmentsadmin'>
<Viewnlabequipmentsadmin/>
</Route>
<Route path='/viewlabequipmentsprog'>
<Viewlabequipmentsprog/>
</Route>
<Route path='/addnlabequipments'>
<Addnlabequipments/>
</Route>
<Route path='/addlabequipmentsbulkn'>
<Addlabequipmentsbulkn />
</Route>
<Route path='/editnprogrambudget'>
<Editnprogrambudget/>
</Route>
<Route path='/viewnprogrambudget'>
<Viewnprogrambudget/>
</Route>
<Route path='/viewnprogrambudgetadmin'>
<Viewnprogrambudgetadmin/>
</Route>
<Route path='/viewprogrambudgetprog'>
<Viewprogrambudgetprog/>
</Route>
<Route path='/addnprogrambudget'>
<Addnprogrambudget/>
</Route>
<Route path='/addprogrambudgetbulkn'>
<Addprogrambudgetbulkn />
</Route>
<Route path='/editninternet'>
<Editninternet/>
</Route>
<Route path='/viewninternet'>
<Viewninternet/>
</Route>
<Route path='/viewninternetadmin'>
<Viewninternetadmin/>
</Route>
<Route path='/viewinternetprog'>
<Viewinternetprog/>
</Route>
<Route path='/addninternet'>
<Addninternet/>
</Route>
<Route path='/addinternetbulkn'>
<Addinternetbulkn />
</Route>
<Route path='/editnemfaculty'>
<Editnemfaculty/>
</Route>
<Route path='/viewnemfaculty'>
<Viewnemfaculty/>
</Route>
<Route path='/viewnemfacultyadmin'>
<Viewnemfacultyadmin/>
</Route>
<Route path='/viewemfacultyprog'>
<Viewemfacultyprog/>
</Route>
<Route path='/addnemfaculty'>
<Addnemfaculty/>
</Route>
<Route path='/addemfacultybulkn'>
<Addemfacultybulkn />
</Route>
<Route path='/editnstudentpubs'>
<Editnstudentpubs/>
</Route>
<Route path='/viewnstudentpubs'>
<Viewnstudentpubs/>
</Route>
<Route path='/viewnstudentpubsadmin'>
<Viewnstudentpubsadmin/>
</Route>
<Route path='/viewstudentpubsprog'>
<Viewstudentpubsprog/>
</Route>
<Route path='/addnstudentpubs'>
<Addnstudentpubs/>
</Route>
<Route path='/addstudentpubsbulkn'>
<Addstudentpubsbulkn />
</Route>
<Route path='/editndeppublications'>
<Editndeppublications/>
</Route>
<Route path='/viewndeppublications'>
<Viewndeppublications/>
</Route>
<Route path='/viewndeppublicationsadmin'>
<Viewndeppublicationsadmin/>
</Route>
<Route path='/viewdeppublicationsprog'>
<Viewdeppublicationsprog/>
</Route>
<Route path='/addndeppublications'>
<Addndeppublications/>
</Route>
<Route path='/adddeppublicationsbulkn'>
<Adddeppublicationsbulkn />
</Route>
<Route path='/editnfacyear'>
<Editnfacyear/>
</Route>
<Route path='/viewnfacyear'>
<Viewnfacyear/>
</Route>
<Route path='/viewnfacyearadmin'>
<Viewnfacyearadmin/>
</Route>
<Route path='/viewfacyearprog'>
<Viewfacyearprog/>
</Route>
<Route path='/addnfacyear'>
<Addnfacyear/>
</Route>
<Route path='/addfacyearbulkn'>
<Addfacyearbulkn />
</Route>
<Route path='/editnstudmaster'>
<Editnstudmaster/>
</Route>
<Route path='/viewnstudmaster'>
<Viewnstudmaster/>
</Route>
<Route path='/viewnstudmasteradmin'>
<Viewnstudmasteradmin/>
</Route>
<Route path='/viewstudmasterprog'>
<Viewstudmasterprog/>
</Route>
<Route path='/addnstudmaster'>
<Addnstudmaster/>
</Route>
<Route path='/addstudmasterbulkn'>
<Addstudmasterbulkn />
</Route>
<Route path='/editnseedm'>
<Editnseedm/>
</Route>
<Route path='/viewnseedm'>
<Viewnseedm/>
</Route>
<Route path='/viewnseedmadmin'>
<Viewnseedmadmin/>
</Route>
<Route path='/viewseedmprog'>
<Viewseedmprog/>
</Route>
<Route path='/addnseedm'>
<Addnseedm/>
</Route>
<Route path='/addseedmbulkn'>
<Addseedmbulkn />
</Route>
{/* <Route path='/editnplacement'>
<Editnplacement/>
</Route>
<Route path='/viewnplacement'>
<Viewnplacement/>
</Route>
<Route path='/viewnplacementadmin'>
<Viewnplacementadmin/>
</Route>
<Route path='/viewplacementprog'>
<Viewplacementprog/>
</Route>
<Route path='/addnplacement'>
<Addnplacement/>
</Route>
<Route path='/addplacementbulkn'>
<Addplacementbulkn />
</Route> */}



    <Route path='/viewneventlinks'>
<Viewneventlinks/>
</Route>
    <Route path='/editnteacherfs'>
<Editnteacherfs/>
</Route>
<Route path='/viewnteacherfs'>
<Viewnteacherfs/>
</Route>
<Route path='/viewnteacherfsadmin'>
<Viewnteacherfsadmin/>
</Route>
<Route path='/viewteacherfsprog'>
<Viewteacherfsprog/>
</Route>
<Route path='/addnteacherfs'>
<Addnteacherfs/>
</Route>
<Route path='/addteacherfsbulkn'>
<Addteacherfsbulkn />
</Route>




    <Route path='/editnalumnicon'>
<Editnalumnicon/>
</Route>
<Route path='/viewnalumnicon'>
<Viewnalumnicon/>
</Route>
<Route path='/viewnalumniconadmin'>
<Viewnalumniconadmin/>
</Route>
<Route path='/viewalumniconprog'>
<Viewalumniconprog/>
</Route>
<Route path='/addnalumnicon'>
<Addnalumnicon/>
</Route>
<Route path='/addalumniconbulkn'>
<Addalumniconbulkn />
</Route>
<Route path='/editnevent'>
<Editnevent/>
</Route>
<Route path='/viewnevent'>
<Viewnevent/>
</Route>
<Route path='/viewneventadmin'>
<Viewneventadmin/>
</Route>
<Route path='/vieweventprog'>
<Vieweventprog/>
</Route>
<Route path='/addnevent'>
<Addnevent/>
</Route>
<Route path='/addeventbulkn'>
<Addeventbulkn />
</Route>



    <Route path='/editnmentees'>
<Editnmentees/>
</Route>
<Route path='/viewnmentees'>
<Viewnmentees/>
</Route>
<Route path='/viewnmenteesadmin'>
<Viewnmenteesadmin/>
</Route>
<Route path='/viewmenteesprog'>
<Viewmenteesprog/>
</Route>
<Route path='/addnmentees'>
<Addnmentees/>
</Route>
<Route path='/addmenteesbulkn'>
<Addmenteesbulkn />
</Route>


    <Route path='/editnempexpenses'>
<Editnempexpenses/>
</Route>
<Route path='/viewnempexpenses'>
<Viewnempexpenses/>
</Route>
<Route path='/viewnempexpensesadmin'>
<Viewnempexpensesadmin/>
</Route>
<Route path='/viewempexpensesprog'>
<Viewempexpensesprog/>
</Route>
<Route path='/addnempexpenses'>
<Addnempexpenses/>
</Route>
<Route path='/addempexpensesbulkn'>
<Addempexpensesbulkn />
</Route>
<Route path='/editneventexpenses'>
<Editneventexpenses/>
</Route>
<Route path='/viewneventexpenses'>
<Viewneventexpenses/>
</Route>
<Route path='/viewneventexpensesadmin'>
<Viewneventexpensesadmin/>
</Route>
<Route path='/vieweventexpensesprog'>
<Vieweventexpensesprog/>
</Route>
<Route path='/addneventexpenses'>
<Addneventexpenses/>
</Route>
<Route path='/addeventexpensesbulkn'>
<Addeventexpensesbulkn />
</Route>


    <Route path='/editnnmetricissues'>
<Editnnmetricissues/>
</Route>
<Route path='/viewnnmetricissues'>
<Viewnnmetricissues/>
</Route>
<Route path='/viewnnmetricissuesadmin'>
<Viewnnmetricissuesadmin/>
</Route>
<Route path='/viewnmetricissuesprog'>
<Viewnmetricissuesprog/>
</Route>
<Route path='/addnnmetricissues'>
<Addnnmetricissues/>
</Route>
<Route path='/addnmetricissuesbulkn'>
<Addnmetricissuesbulkn />
</Route>


    <Route path='/editnteacherdata'>
<Editnteacherdata/>
</Route>
<Route path='/viewnteacherdata'>
<Viewnteacherdata/>
</Route>
<Route path='/viewnteacherdataadmin'>
<Viewnteacherdataadmin/>
</Route>
<Route path='/viewteacherdataprog'>
<Viewteacherdataprog/>
</Route>
<Route path='/addnteacherdata'>
<Addnteacherdata/>
</Route>
<Route path='/addteacherdatabulkn'>
<Addteacherdatabulkn />
</Route>
<Route path='/editnresult'>
<Editnresult/>
</Route>
<Route path='/viewnresult'>
<Viewnresult/>
</Route>
<Route path='/viewnresultadmin'>
<Viewnresultadmin/>
</Route>
<Route path='/viewresultprog'>
<Viewresultprog/>
</Route>
<Route path='/addnresult'>
<Addnresult/>
</Route>
<Route path='/addresultbulkn'>
<Addresultbulkn />
</Route>
<Route path='/editnphdface'>
<Editnphdface/>
</Route>
<Route path='/viewnphdface'>
<Viewnphdface/>
</Route>
<Route path='/viewnphdfaceadmin'>
<Viewnphdfaceadmin/>
</Route>
<Route path='/viewphdfaceprog'>
<Viewphdfaceprog/>
</Route>
<Route path='/addnphdface'>
<Addnphdface/>
</Route>
<Route path='/addphdfacebulkn'>
<Addphdfacebulkn />
</Route>



    <Route path='/viewemployabilitys'>
<Viewemployabilitys/>
</Route>
<Route path='/editemployabilitys'>
<Editemployabilitys/>
</Route>

    <Route path='/editnphdfac'>
<Editnphdfac/>
</Route>
<Route path='/viewnphdfac'>
<Viewnphdfac/>
</Route>
<Route path='/viewnphdfacadmin'>
<Viewnphdfacadmin/>
</Route>
<Route path='/viewphdfacprog'>
<Viewphdfacprog/>
</Route>
<Route path='/addnphdfac'>
<Addnphdfac/>
</Route>
<Route path='/addphdfacbulkn'>
<Addphdfacbulkn />
</Route>



    <Route path='/editnreservecat'>
<Editnreservecat/>
</Route>
<Route path='/viewnreservecat'>
<Viewnreservecat/>
</Route>
<Route path='/viewnreservecatadmin'>
<Viewnreservecatadmin/>
</Route>
<Route path='/viewreservecatprog'>
<Viewreservecatprog/>
</Route>
<Route path='/addnreservecat'>
<Addnreservecat/>
</Route>
<Route path='/addreservecatbulkn'>
<Addreservecatbulkn />
</Route>

    <Route path='/editnhigheredu'>
<Editnhigheredu/>
</Route>
<Route path='/viewnhigheredu'>
<Viewnhigheredu/>
</Route>
<Route path='/viewnhighereduadmin'>
<Viewnhighereduadmin/>
</Route>
<Route path='/viewhighereduprog'>
<Viewhighereduprog/>
</Route>
<Route path='/addnhigheredu'>
<Addnhigheredu/>
</Route>
<Route path='/addhigheredubulkn'>
<Addhigheredubulkn />
</Route>
<Route path='/editnhigherexam'>
<Editnhigherexam/>
</Route>
<Route path='/viewnhigherexam'>
<Viewnhigherexam/>
</Route>
<Route path='/viewnhigherexamadmin'>
<Viewnhigherexamadmin/>
</Route>
<Route path='/viewhigherexamprog'>
<Viewhigherexamprog/>
</Route>
<Route path='/addnhigherexam'>
<Addnhigherexam/>
</Route>
<Route path='/addhigherexambulkn'>
<Addhigherexambulkn />
</Route>


    <Route path='/bulkupdatecomments'>
<Bulkupdatecomments/>
</Route>
    <Route path='/editnmeetingtaskssuper'>
<Editnmeetingtaskssuper/>
</Route>
    <Route path='/editnadmission'>
<Editnadmission/>
</Route>
<Route path='/viewnadmission'>
<Viewnadmission/>
</Route>
<Route path='/viewnadmissionadmin'>
<Viewnadmissionadmin/>
</Route>
<Route path='/addnadmission'>
<Addnadmission/>
</Route>
<Route path='/addadmissionbulkn'>
<Addadmissionbulkn />
</Route>
<Route path='/editnpassexam'>
<Editnpassexam/>
</Route>
<Route path='/viewnpassexam'>
<Viewnpassexam/>
</Route>
<Route path='/viewnpassexamadmin'>
<Viewnpassexamadmin/>
</Route>
<Route path='/addnpassexam'>
<Addnpassexam/>
</Route>
<Route path='/addpassexambulkn'>
<Addpassexambulkn />
</Route>
<Route path='/editnplacement'>
<Editnplacement/>
</Route>
<Route path='/viewnplacement'>
<Viewnplacement/>
</Route>
<Route path='/viewnplacementadmin'>
<Viewnplacementadmin/>
</Route>
<Route path='/addnplacement'>
<Addnplacement/>
</Route>
<Route path='/addplacementbulkn'>
<Addplacementbulkn />
</Route>





    <Route path='/generatereport'>
<Generatereport/>
</Route>
    <Route path='/selectreporttype'>
<Selectreporttype/>
</Route>
    <Route path='/viewadmissionprog'>
<Viewadmissionprog/>
</Route>
<Route path='/viewpassexamprog'>
<Viewpassexamprog/>
</Route>
<Route path='/viewplacementprog'>
<Viewplacementprog/>
</Route>

    <Route path='/addpassexambulk'>
<Addpassexambulk />
</Route>
<Route path='/editnbaug33result'>
<Editnbaug33result/>
</Route>
<Route path='/viewnbaug33result'>
<Viewnbaug33result/>
</Route>
<Route path='/viewnbaug33resultadmin'>
<Viewnbaug33resultadmin/>
</Route>
<Route path='/viewnbaug33resultprog'>
<Viewnbaug33resultprog/>
</Route>
<Route path='/addnbaug33result'>
<Addnbaug33result/>
</Route>
<Route path='/addnbaug33resultbulk'>
<Addnbaug33resultbulk />
</Route>

<Route path='/editwriterdb'>
<Editwriterdb/>
</Route>
<Route path='/viewwriterdb'>
<Viewwriterdb/>
</Route>
<Route path='/viewwriterdbadmin'>
<Viewwriterdbadmin/>
</Route>
<Route path='/viewwriterdbprog'>
<Viewwriterdbprog/>
</Route>
<Route path='/addwriterdb'>
<Addwriterdb/>
</Route>
<Route path='/addwriterdbbulk'>
<Addwriterdbbulk />
</Route>

    <Route path='/viewfundslinkssr'>
<Viewfundslinkssr/>
</Route>
    <Route path='/viewlibraryexpenlinksssr'>
<Viewlibraryexpenlinksssr/>
</Route>
    <Route path='/viewexpenditurelinksssr'>
<Viewexpenditurelinksssr/>
</Route>
    <Route path='/viewextensionactlinksssr'>
<Viewextensionactlinksssr/>
</Route>
    <Route path='/viewallpatentlinksssr'>
<Viewallpatentlinksssr/>
</Route>
    <Route path='/viewallbooklinksssr'>
<Viewallbooklinksssr/>
</Route>
    <Route path='/viewsportsactlinks'>
<Viewsportsactlinks/>
</Route>
    <Route path='/viewresultlinks'>
<Viewresultlinks/>
</Route>
    <Route path='/viewecontentlinks'>
<Viewecontentlinks/>
</Route>
    <Route path='/viewexamautolinks'>
<Viewexamautolinks/>
</Route>
    <Route path='/viewteacherdatalinks'>
<Viewteacherdatalinks/>
</Route>
    <Route path='/viewpassexamlinks'>
<Viewpassexamlinks/>
</Route>
    <Route path='/viewseedmlinks'>
<Viewseedmlinks/>
</Route>
    <Route path='/viewteacherfellowlinks'>
<Viewteacherfellowlinks/>
</Route>
    <Route path='/viewresearchfellowlinks'>
<Viewresearchfellowlinks/>
</Route>
    <Route path='/viewallseminarlinksssr'>
<Viewallseminarlinksssr/>
</Route>
    <Route path='/viewawardsstudlinks'>
<Viewawardsstudlinks/>
</Route>
    <Route path='/viewinnovationlinks'>
<Viewinnovationlinks/>
</Route>
    <Route path='/viewexplearninglinksssr'>
<Viewexplearninglinksssr/>
</Route>
    <Route path='/viewemployabilitylinksssr'>
<Viewemployabilitylinksssr/>
</Route>
    <Route path='/viewsyllabusrevlinksssr'>
<Viewsyllabusrevlinksssr/>
</Route>
    <Route path='/adminhumetric'>
<Adminhumetric/>
</Route>
    <Route path='/editverifystatus'>
<Editverifystatus/>
</Route>
<Route path='/viewverifystatus'>
<Viewverifystatus/>
</Route>
<Route path='/viewverifystatusadmin'>
<Viewverifystatusadmin/>
</Route>
<Route path='/viewverifystatusprog'>
<Viewverifystatusprog/>
</Route>
<Route path='/addverifystatus'>
<Addverifystatus/>
</Route>
<Route path='/addverifystatusbulk'>
<Addverifystatusbulk />
</Route>
<Route path='/editnissuesall'>
<Editnissuesall/>
</Route>
<Route path='/viewnissuesall'>
<Viewnissuesall/>
</Route>
<Route path='/viewnissuesalladmin'>
<Viewnissuesalladmin/>
</Route>
<Route path='/viewnissuesallprog'>
<Viewnissuesallprog/>
</Route>
<Route path='/addnissuesall'>
<Addnissuesall/>
</Route>
<Route path='/addnissuesallbulk'>
<Addnissuesallbulk />
</Route>
<Route path='/editntickets'>
<Editntickets/>
</Route>
<Route path='/viewntickets'>
<Viewntickets/>
</Route>
<Route path='/viewnticketsadmin'>
<Viewnticketsadmin/>
</Route>
<Route path='/viewnticketsprog'>
<Viewnticketsprog/>
</Route>
<Route path='/addntickets'>
<Addntickets/>
</Route>
<Route path='/addnticketsbulk'>
<Addnticketsbulk />
</Route>




    <Route path='/viewnmeetingtasksallclients'>
<Viewnmeetingtasksallclients/>
</Route>
    <Route path='/viewnmeetingtaskspending'>
<Viewnmeetingtaskspending/>
</Route>
    <Route path='/viewhumetric'>
<Viewhumetric/>
</Route>
    <Route path='/vieweventlinksbyuser'>
<Vieweventlinksbyuser/>
</Route>
    <Route path='/viewexplearnuaqarlinks'>
<Viewexplearnuaqarlinks/>
</Route>
<Route path='/viewexplearnuaqar'>
<Viewexplearnuaqar/>
</Route>
    <Route path='/viewnclinicskillsadminlinks'>
<Viewnclinicskillsadminlinks/>
</Route>
    <Route path='/viewnhlearneradminlinks'>
<Viewnhlearneradminlinks/>
</Route>
    <Route path='/viewnnratiolinks'>
<Viewnnratiolinks/>
</Route>
    <Route path='/viewnnursing818adminlinks'>
<Viewnnursing818adminlinks/>
</Route>
    <Route path='/viewnnursing814links'>
<Viewnnursing814links/>
</Route>
    <Route path='/viewnnursing812links'>
<Viewnnursing812links/>
</Route>
    <Route path='/viewegovlinks'>
<Viewegovlinks/>
</Route>
    <Route path='/viewaddonlinks1'>
<Viewaddonlinks1/>
</Route>
    <Route path='/viewnbaaug11adminnewlinks'>
<Viewnbaaug11adminnewlinks/>
</Route>
    <Route path='/viewnbaaug11adminnew'>
<Viewnbaaug11adminnew/>
</Route>
    <Route path='/editnallcourses'>
<Editnallcourses/>
</Route>
<Route path='/viewnallcourses'>
<Viewnallcourses/>
</Route>
<Route path='/viewnallcoursesadmin'>
<Viewnallcoursesadmin/>
</Route>
<Route path='/viewnallcoursesprog'>
<Viewnallcoursesprog/>
</Route>
<Route path='/addnallcourses'>
<Addnallcourses/>
</Route>
<Route path='/addnallcoursesbulk'>
<Addnallcoursesbulk />
</Route>

    <Route path='/editndepmeetingsall'>
<Editndepmeetingsall/>
</Route>
<Route path='/viewndepmeetingsall'>
<Viewndepmeetingsall/>
</Route>
<Route path='/viewndepmeetingsalladmin'>
<Viewndepmeetingsalladmin/>
</Route>
<Route path='/addndepmeetingsall'>
<Addndepmeetingsall/>
</Route>
<Route path='/addndepmeetingsallbulk'>
<Addndepmeetingsallbulk />
</Route>
<Route path='/editnmeetingtasksall'>
<Editnmeetingtasksall/>
</Route>
<Route path='/viewnmeetingtasksall'>
<Viewnmeetingtasksall/>
</Route>
<Route path='/viewnmeetingtasksalladmin'>
<Viewnmeetingtasksalladmin/>
</Route>
<Route path='/addnmeetingtasksall'>
<Addnmeetingtasksall/>
</Route>
<Route path='/addnmeetingtasksallbulk'>
<Addnmeetingtasksallbulk />
</Route>


    <Route path='/viewnirfexpenditure'>
<Viewnirfexpenditure/>
</Route>
    <Route path='/viewnirfreimbursement'>
<Viewnirfreimbursement/>
</Route>
    <Route path='/viewnirflibraryexp'>
<Viewnirflibraryexp/>
</Route>
    <Route path='/viewnirfplacement'>
<Viewnirfplacement/>
</Route>
    <Route path='/viewnirfpass'>
<Viewnirfpass/>
</Route>
    <Route path='/viewnirfadmission'>
<Viewnirfadmission/>
</Route>
    <Route path='/viewkpiuserprint'>
<Viewkpiuserprint/>
</Route>
    <Route path='/modifysyllabusrev'>
<Modifysyllabusrev/>
</Route>
    <Route path='/addsyllabusrevupdate'>
<Addsyllabusrevupdate/>
</Route>
    <Route path='/viewsyllabusrevlinks'>
<Viewsyllabusrevlinks/>
</Route>
    <Route path='/viewhigheredulinksssr'>
<Viewhigheredulinksssr/>
</Route>
    <Route path='/viewmoulinksssr'>
<Viewmoulinksssr/>
</Route>
    <Route path='/editndepmeetings'>
<Editndepmeetings/>
</Route>
<Route path='/viewndepmeetings'>
<Viewndepmeetings/>
</Route>
<Route path='/viewndepmeetingsadmin'>
<Viewndepmeetingsadmin/>
</Route>
<Route path='/addndepmeetings'>
<Addndepmeetings/>
</Route>
<Route path='/addndepmeetingsbulk'>
<Addndepmeetingsbulk />
</Route>
<Route path='/editnmeetingtasks'>
<Editnmeetingtasks/>
</Route>
<Route path='/viewnmeetingtasks'>
<Viewnmeetingtasks/>
</Route>
<Route path='/viewnmeetingtasksadmin'>
<Viewnmeetingtasksadmin/>
</Route>
<Route path='/addnmeetingtasks'>
<Addnmeetingtasks/>
</Route>
<Route path='/addnmeetingtasksbulk'>
<Addnmeetingtasksbulk />
</Route>




    <Route path='/viewteacherfslinks'>
<Viewteacherfslinks/>
</Route>
    <Route path='/viewallsemreimlins'>
<Viewallsemreimlinks/>
</Route>
    <Route path='/viewphdguidelinksssr'>
<Viewphdguidelinksssr/>
</Route>
    <Route path='/viewteacherawardlinks'>
<Viewteacherawardlinks/>
</Route>
    <Route path='/viewhigherexamlinksssr'>
<Viewhigherexamlinksssr/>
</Route>
    <Route path='/viewplacementlinksssr'>
<Viewplacementlinksssr/>
</Route>
    <Route path='/viewnnursing515links'>
<Viewnnursing515links/>
</Route>
    <Route path='/viewcareerlinks'>
<Viewcareerlinks/>
</Route>
    <Route path='/viewscholarshiplinks'>
<Viewscholarshiplinks/>
</Route>
    <Route path='/viewskilldevlinksssr'>
<Viewskilldevlinksssr/>
</Route>
    <Route path='/vieweventlinksssr'>
<Vieweventlinksssr/>
</Route>
    <Route path='/viewictlinks'>
<Viewictlinks/>
</Route>
    <Route path='/viewallpublinksssr'>
<Viewallpublinksssr/>
</Route>
    <Route path='/viewcollablinks'>
<Viewcollablinks/>
</Route>
    <Route path='/viewadmissionlinksssr'>
<Viewadmissionlinksssr/>
</Route>
    <Route path='/viewboslinks'>
<Viewboslinks/>
</Route>
    <Route path='/viewaddonlinks'>
<Viewaddonlinks/>
</Route>
    <Route path='/viewnfacilitiesadminlinks'>
<Viewnfacilitiesadminlinks/>
</Route>
    <Route path='/viewnnursinginteradminlinks'>
<Viewnnursinginteradminlinks/>
</Route>
    <Route path='/viewnprogcourseadminlinks'>
<Viewnprogcourseadminlinks/>
</Route>
    <Route path='/editnnursing812'>
<Editnnursing812/>
</Route>
<Route path='/viewnnursing812'>
<Viewnnursing812/>
</Route>
<Route path='/viewnnursing812admin'>
<Viewnnursing812admin/>
</Route>
<Route path='/addnnursing812'>
<Addnnursing812/>
</Route>
<Route path='/addnnursing812bulk'>
<Addnnursing812bulk />
</Route>
<Route path='/editnnursing814'>
<Editnnursing814/>
</Route>
<Route path='/viewnnursing814'>
<Viewnnursing814/>
</Route>
<Route path='/viewnnursing814admin'>
<Viewnnursing814admin/>
</Route>
<Route path='/addnnursing814'>
<Addnnursing814/>
</Route>
<Route path='/addnnursing814bulk'>
<Addnnursing814bulk />
</Route>
<Route path='/editnnursing815'>
<Editnnursing815/>
</Route>
<Route path='/viewnnursing815'>
<Viewnnursing815/>
</Route>
<Route path='/viewnnursing815admin'>
<Viewnnursing815admin/>
</Route>
<Route path='/addnnursing815'>
<Addnnursing815/>
</Route>
<Route path='/addnnursing815bulk'>
<Addnnursing815bulk />
</Route>
<Route path='/editnnursing818'>
<Editnnursing818/>
</Route>
<Route path='/viewnnursing818'>
<Viewnnursing818/>
</Route>
<Route path='/viewnnursing818admin'>
<Viewnnursing818admin/>
</Route>
<Route path='/addnnursing818'>
<Addnnursing818/>
</Route>
<Route path='/addnnursing818bulk'>
<Addnnursing818bulk />
</Route>
<Route path='/editnnursing424'>
<Editnnursing424/>
</Route>
<Route path='/viewnnursing424'>
<Viewnnursing424/>
</Route>
<Route path='/viewnnursing424admin'>
<Viewnnursing424admin/>
</Route>
<Route path='/addnnursing424'>
<Addnnursing424/>
</Route>
<Route path='/addnnursing424bulk'>
<Addnnursing424bulk />
</Route>
<Route path='/editncompetitive'>
<Editncompetitive/>
</Route>
<Route path='/viewncompetitive'>
<Viewncompetitive/>
</Route>
<Route path='/viewncompetitiveadmin'>
<Viewncompetitiveadmin/>
</Route>
<Route path='/addncompetitive'>
<Addncompetitive/>
</Route>
<Route path='/addncompetitivebulk'>
<Addncompetitivebulk />
</Route>
<Route path='/editnnursing515'>
<Editnnursing515/>
</Route>
<Route path='/viewnnursing515'>
<Viewnnursing515/>
</Route>
<Route path='/viewnnursing515admin'>
<Viewnnursing515admin/>
</Route>
<Route path='/addnnursing515'>
<Addnnursing515/>
</Route>
<Route path='/addnnursing515bulk'>
<Addnnursing515bulk />
</Route>

    <Route path='/reportcriteria1'>
<Reportcriteria1/>
</Route>
    <Route path='/viewsuperfaculty'>
<Viewsuperfaculty/>
</Route>
    <Route path='/datewisesummaryledger'>
<Datewisesummaryledger/>
</Route>
<Route path='/selectfin'>
<Selectfin/>
</Route>
    <Route path='/editnaccountheads'>
<Editnaccountheads/>
</Route>
<Route path='/viewnaccountheads'>
<Viewnaccountheads/>
</Route>
<Route path='/viewnaccountheadsadmin'>
<Viewnaccountheadsadmin/>
</Route>
<Route path='/addnaccountheads'>
<Addnaccountheads/>
</Route>
<Route path='/addnaccountheadsbulk'>
<Addnaccountheadsbulk />
</Route>
<Route path='/editntransactions'>
<Editntransactions/>
</Route>
<Route path='/viewntransactions'>
<Viewntransactions/>
</Route>
<Route path='/viewntransactionsadmin'>
<Viewntransactionsadmin/>
</Route>
<Route path='/addntransactions'>
<Addntransactions/>
</Route>
<Route path='/addntransactionsbulk'>
<Addntransactionsbulk />
</Route>
<Route path='/editntledger'>
<Editntledger/>
</Route>
<Route path='/viewntledger'>
<Viewntledger/>
</Route>
<Route path='/viewntledgeradmin'>
<Viewntledgeradmin/>
</Route>
<Route path='/addntledger'>
<Addntledger/>
</Route>
<Route path='/addntledgerbulk'>
<Addntledgerbulk />
</Route>




    <Route path='/editaddoncourseadmin'>
<Editaddoncourseadmin/>
</Route>
    <Route path='/editsyllabusrevadmin'>
<Editsyllabusrevadmin/>
</Route>
    <Route path='/viewdepdata'>
<Viewdepdata/>
</Route>
    <Route path='/viewvactodaystud'>
<Viewvactodaystud/>
</Route>
    <Route path='/viewfeedbackdata'>
<Viewfeedbackdata/>
</Route>
    <Route path='/editprojectledger'>
<Editprojectledger/>
</Route>
<Route path='/viewprojectledger'>
<Viewprojectledger/>
</Route>
<Route path='/viewprojectledgeradmin'>
<Viewprojectledgeradmin/>
</Route>
<Route path='/addprojectledger'>
<Addprojectledger/>
</Route>
<Route path='/addprojectledgerbulk'>
<Addprojectledgerbulk />
</Route>
<Route path='/editprojectcentral'>
<Editprojectcentral/>
</Route>
<Route path='/viewprojectcentral'>
<Viewprojectcentral/>
</Route>
<Route path='/viewprojectcentraladmin'>
<Viewprojectcentraladmin/>
</Route>
<Route path='/addprojectcentral'>
<Addprojectcentral/>
</Route>
<Route path='/addprojectcentralbulk'>
<Addprojectcentralbulk />
</Route>
<Route path='/editmaintenance'>
<Editmaintenance/>
</Route>
<Route path='/viewmaintenance'>
<Viewmaintenance/>
</Route>
<Route path='/viewmaintenanceadmin'>
<Viewmaintenanceadmin/>
</Route>
<Route path='/addmaintenance'>
<Addmaintenance/>
</Route>
<Route path='/addmaintenancebulk'>
<Addmaintenancebulk />
</Route>
<Route path='/editotherincome'>
<Editotherincome/>
</Route>
<Route path='/viewotherincome'>
<Viewotherincome/>
</Route>
<Route path='/viewotherincomeadmin'>
<Viewotherincomeadmin/>
</Route>
<Route path='/addotherincome'>
<Addotherincome/>
</Route>
<Route path='/addotherincomebulk'>
<Addotherincomebulk />
</Route>


    <Route path='/classattendancefaccl'>
<Classattendancefaccl/>
</Route>
<Route path='/classattendancestud'>
<Classattendancestud/>
</Route>
    <Route path='/classattendancefac'>
<Classattendancefac/>
</Route>
    <Route path='/addlessonplanbulknew'>
<Addlessonplanbulknew/>
</Route>
    <Route path='/editstudparents'>
<Editstudparents/>
</Route>
<Route path='/viewstudparents'>
<Viewstudparents/>
</Route>
<Route path='/viewstudparentsadmin'>
<Viewstudparentsadmin/>
</Route>
<Route path='/addstudparents'>
<Addstudparents/>
</Route>
<Route path='/addstudparentsbulk'>
<Addstudparentsbulk />
</Route>

    <Route path='/viewcircularstud'>
<Viewcircularstud/>
</Route>
    <Route path='/selectawsconfigstudassignment'>
<Selectawsconfigstudassignment/>
</Route>
<Route path='/addphotostudassignment'>
<Addphotostudassignment/>
</Route>
    <Route path='/viewassignmentstud'>
<Viewassignmentstud/>
</Route>
    <Route path='/editcurrenyear'>
<Editcurrenyear/>
</Route>
<Route path='/viewcurrenyear'>
<Viewcurrenyear/>
</Route>
<Route path='/viewcurrenyearadmin'>
<Viewcurrenyearadmin/>
</Route>
<Route path='/addcurrenyear'>
<Addcurrenyear/>
</Route>
<Route path='/addcurrenyearbulk'>
<Addcurrenyearbulk />
</Route>

    <Route path='/viewcursefilesstud'>
<Viewcoursefilesstud/>
</Route>
    <Route path='/viewenrolcourseyr'>
<Viewenrolcourseyr/>
</Route>
    <Route path='/viewclassprogramstud'>
<Viewclassprogramstud/>
</Route>
    <Route path='/addphotostud'>
<Addphotostud/>
</Route>
    <Route path='/selectawsconfigphotostud'>
<Selectawsconfigphotostud/>
</Route>
    <Route path='/dashstud1'>
<Dashstud2/>
</Route>
    <Route path='/deleteworkload'>
<Deleteworkload/>
</Route>
    <Route path='/viewallfacultieslmsdep'>
<Viewallfacultieslmsdep/>
</Route>
    <Route path='/selectperiodlms'>
<Selectperiodlms/>
</Route>
    <Route path='/selectawsconfigphoto'>
<Selectawsconfigphoto/>
</Route>
    <Route path='/addphoto'>
<Addphoto/>
</Route>
    <Route path='/viewlsyllabusemp'>
<Viewlsyllabusemp/>
</Route>
    <Route path='/edituser'>
<Edituser/>
</Route>
<Route path='/viewuserstudent'>
<Viewuserstudent/>
</Route>
<Route path='/viewuserfaculty'>
<Viewuserfaculty/>
</Route>
<Route path='/viewuserhod'>
<Viewuserhod/>
</Route>
<Route path='/viewuseradmin'>
<Viewuseradmin/>
</Route>
<Route path='/adduser'>
<Adduser/>
</Route>
<Route path='/adduserhod'>
<Adduserhod/>
</Route>
<Route path='/adduserfaculty'>
<Adduserfaculty/>
</Route>
<Route path='/adduserstudent'>
<Adduserstudent/>
</Route>
<Route path='/adduserbulk'>
<Adduserbulk />
</Route>
<Route path='/adduserbulkhod'>
<Adduserbulkhod />
</Route>
<Route path='/adduserbulkstudent'>
<Adduserbulkstudent />
</Route>

    <Route path='/editco'>
<Editco/>
</Route>
<Route path='/viewco'>
<Viewco/>
</Route>
<Route path='/viewcoadmin'>
<Viewcoadmin/>
</Route>
<Route path='/addco'>
<Addco/>
</Route>
<Route path='/addcobulk'>
<Addcobulk />
</Route>
<Route path='/editlannouncement'>
<Editlannouncement/>
</Route>
<Route path='/viewlannouncement'>
<Viewlannouncement/>
</Route>
<Route path='/viewlannouncementadmin'>
<Viewlannouncementadmin/>
</Route>
<Route path='/addlannouncement'>
<Addlannouncement/>
</Route>
<Route path='/addlannouncementbulk'>
<Addlannouncementbulk />
</Route>
<Route path='/editlsyllabus'>
<Editlsyllabus/>
</Route>
<Route path='/viewlsyllabus'>
<Viewlsyllabus/>
</Route>
<Route path='/viewlsyllabusadmin'>
<Viewlsyllabusadmin/>
</Route>
<Route path='/addlsyllabus'>
<Addlsyllabus/>
</Route>
<Route path='/addlsyllabusbulk'>
<Addlsyllabusbulk />
</Route>
<Route path='/editlcalendar'>
<Editlcalendar/>
</Route>
<Route path='/viewlcalendar'>
<Viewlcalendar/>
</Route>
<Route path='/viewlcalendaradmin'>
<Viewlcalendaradmin/>
</Route>
<Route path='/addlcalendar'>
<Addlcalendar/>
</Route>
<Route path='/addlcalendarbulk'>
<Addlcalendarbulk />
</Route>




    <Route path='/viewworkloadadminfac'>
<Viewworkloadadminfac/>
</Route>
<Route path='/viewallfacultieslms'>
<Viewallfacultieslms/>
</Route>
    <Route path='/viewclassseven'>
<Viewclassseven/>
</Route>
    <Route path='/viewclassprogram'>
<Viewclassprogram/>
</Route>
<Route path='/selectprogramstt'>
<Selectprogramstt/>
</Route>
<Route path='/selectsemsecyr'>
<Selectsemsecyr/>
</Route>
    <Route path='/addclassnewweeks'>
<Addclassnewweeks/>
</Route>
    <Route path='/editcoursefiles'>
<Editcoursefiles/>
</Route>
<Route path='/viewcoursefiles'>
<Viewcoursefiles/>
</Route>
<Route path='/viewcoursefilesadmin'>
<Viewcoursefilesadmin/>
</Route>
<Route path='/addcoursefilesnew'>
<Addcoursefilesnew/>
</Route>
<Route path='/addcoursefilesbulk'>
<Addcoursefilesbulk />
</Route>
<Route path='/editclass'>
<Editclass/>
</Route>
<Route path='/viewclass'>
<Viewclass/>
</Route>
<Route path='/viewclassadmin'>
<Viewclassadmin/>
</Route>
<Route path='/addclassnew'>
<Addclassnew/>
</Route>
<Route path='/addclassbulk'>
<Addclassbulk />
</Route>



    <Route path='/viewlessonplanadmin'>
<Viewlessonplanadmin/>
</Route>
<Route path='/viewworkloadadmin'>
<Viewworkloadadmin/>
</Route>

    <Route path='/addworkloadbulk'>
<Addworkloadbulk />
</Route>
<Route path='/addlessonplanbulk'>
<Addlessonplanbulk />
</Route>

    <Route path='/editncommittees'>
<Editncommittees/>
</Route>
<Route path='/viewncommittees'>
<Viewncommittees/>
</Route>
<Route path='/viewncommitteesadmin'>
<Viewncommitteesadmin/>
</Route>
<Route path='/addncommittees'>
<Addncommittees/>
</Route>
<Route path='/addncommitteesbulk'>
<Addncommitteesbulk />
</Route>
<Route path='/editncommembers'>
<Editncommembers/>
</Route>
<Route path='/viewncommembers'>
<Viewncommembers/>
</Route>
<Route path='/viewncommembersadmin'>
<Viewncommembersadmin/>
</Route>
<Route path='/addncommembers'>
<Addncommembers/>
</Route>
<Route path='/addncommembersbulk'>
<Addncommembersbulk />
</Route>
<Route path='/editncomtasks'>
<Editncomtasks/>
</Route>
<Route path='/viewncomtasks'>
<Viewncomtasks/>
</Route>
<Route path='/viewncomtasksadmin'>
<Viewncomtasksadmin/>
</Route>
<Route path='/addncomtasks'>
<Addncomtasks/>
</Route>
<Route path='/addncomtasksbulk'>
<Addncomtasksbulk />
</Route>
<Route path='/editncomminutes'>
<Editncomminutes/>
</Route>
<Route path='/viewncomminutes'>
<Viewncomminutes/>
</Route>
<Route path='/viewncomminutesadmin'>
<Viewncomminutesadmin/>
</Route>
<Route path='/addncomminutes'>
<Addncomminutes/>
</Route>
<Route path='/addncomminutesbulk'>
<Addncomminutesbulk />
</Route>



    <Route path='/viewdepfacdocs'>
<Viewdepfacdocs/>
</Route>
    <Route path='/viewnoffcampusadmin'>
<Viewnoffcampusadmin/>
</Route>
<Route path='/viewnjobsapplyadmin'>
<Viewnjobsapplyadmin/>
</Route>
<Route path='/viewnhigheredurepadmin'>
<Viewnhigheredurepadmin/>
</Route>
<Route path='/viewnoffawardsadmin'>
<Viewnoffawardsadmin/>
</Route>
<Route path='/viewnoffscholarshipadmin'>
<Viewnoffscholarshipadmin/>
</Route>
<Route path='/viewnoffextensionadmin'>
<Viewnoffextensionadmin/>
</Route>
<Route path='/viewnoffcoursesadmin'>
<Viewnoffcoursesadmin/>
</Route>


    <Route path='/editnjobs'>
<Editnjobs/>
</Route>
<Route path='/viewnjobs'>
<Viewnjobs/>
</Route>
<Route path='/viewnjobsadmin'>
<Viewnjobsadmin/>
</Route>
<Route path='/addnjobs'>
<Addnjobs/>
</Route>
<Route path='/addnjobsbulk'>
<Addnjobsbulk />
</Route>
<Route path='/editnjobstudents'>
<Editnjobstudents/>
</Route>
<Route path='/viewnjobstudents'>
<Viewnjobstudents/>
</Route>
<Route path='/viewnjobstudentsadmin'>
<Viewnjobstudentsadmin/>
</Route>
<Route path='/addnjobstudents'>
<Addnjobstudents/>
</Route>
<Route path='/addnjobstudentsbulk'>
<Addnjobstudentsbulk />
</Route>
<Route path='/editnhighereducation'>
<Editnhighereducation/>
</Route>
<Route path='/viewnhighereducation'>
<Viewnhighereducation/>
</Route>
<Route path='/viewnhighereducationadmin'>
<Viewnhighereducationadmin/>
</Route>
<Route path='/addnhighereducation'>
<Addnhighereducation/>
</Route>
<Route path='/addnhighereducationbulk'>
<Addnhighereducationbulk />
</Route>
<Route path='/editncompanies'>
<Editncompanies/>
</Route>
<Route path='/viewncompanies'>
<Viewncompanies/>
</Route>
<Route path='/viewncompaniesadmin'>
<Viewncompaniesadmin/>
</Route>
<Route path='/addncompanies'>
<Addncompanies/>
</Route>
<Route path='/addncompaniesbulk'>
<Addncompaniesbulk />
</Route>


    <Route path='/editnseedmoney'>
<Editnseedmoney/>
</Route>
<Route path='/viewnseedmoney'>
<Viewnseedmoney/>
</Route>
<Route path='/viewnseedmoneyadmin'>
<Viewnseedmoneyadmin/>
</Route>
<Route path='/addnseedmoney'>
<Addnseedmoney/>
</Route>
<Route path='/addnseedmoneybulk'>
<Addnseedmoneybulk />
</Route>
<Route path='/editnbudgetreq'>
<Editnbudgetreq/>
</Route>
<Route path='/viewnbudgetreq'>
<Viewnbudgetreq/>
</Route>
<Route path='/viewnbudgetreqadmin'>
<Viewnbudgetreqadmin/>
</Route>
<Route path='/addnbudgetreq'>
<Addnbudgetreq/>
</Route>
<Route path='/addnbudgetreqbulk'>
<Addnbudgetreqbulk />
</Route>
<Route path='/editnoodreq'>
<Editnoodreq/>
</Route>
<Route path='/viewnoodreq'>
<Viewnoodreq/>
</Route>
<Route path='/viewnoodreqadmin'>
<Viewnoodreqadmin/>
</Route>
<Route path='/addnoodreq'>
<Addnoodreq/>
</Route>
<Route path='/addnoodreqbulk'>
<Addnoodreqbulk />
</Route>
<Route path='/editneventreq'>
<Editneventreq/>
</Route>
<Route path='/viewneventreq'>
<Viewneventreq/>
</Route>
<Route path='/viewneventreqadmin'>
<Viewneventreqadmin/>
</Route>
<Route path='/addneventreq'>
<Addneventreq/>
</Route>
<Route path='/addneventreqbulk'>
<Addneventreqbulk />
</Route>
<Route path='/editnassistantreq'>
<Editnassistantreq/>
</Route>
<Route path='/viewnassistantreq'>
<Viewnassistantreq/>
</Route>
<Route path='/viewnassistantreqadmin'>
<Viewnassistantreqadmin/>
</Route>
<Route path='/addnassistantreq'>
<Addnassistantreq/>
</Route>
<Route path='/addnassistantreqbulk'>
<Addnassistantreqbulk />
</Route>
<Route path='/editnhousingreq'>
<Editnhousingreq/>
</Route>
<Route path='/viewnhousingreq'>
<Viewnhousingreq/>
</Route>
<Route path='/viewnhousingreqadmin'>
<Viewnhousingreqadmin/>
</Route>
<Route path='/addnhousingreq'>
<Addnhousingreq/>
</Route>
<Route path='/addnhousingreqbulk'>
<Addnhousingreqbulk />
</Route>
<Route path='/editntravelsupport'>
<Editntravelsupport/>
</Route>
<Route path='/viewntravelsupport'>
<Viewntravelsupport/>
</Route>
<Route path='/viewntravelsupportadmin'>
<Viewntravelsupportadmin/>
</Route>
<Route path='/addntravelsupport'>
<Addntravelsupport/>
</Route>
<Route path='/addntravelsupportbulk'>
<Addntravelsupportbulk />
</Route>




    <Route path='/editnbudget'>
<Editnbudget/>
</Route>
<Route path='/viewnbudget'>
<Viewnbudget/>
</Route>
<Route path='/viewnbudgetadmin'>
<Viewnbudgetadmin/>
</Route>
<Route path='/addnbudget'>
<Addnbudget/>
</Route>
<Route path='/addnbudgetbulk'>
<Addnbudgetbulk />
</Route>
<Route path='/editnledger'>
<Editnledger/>
</Route>
<Route path='/viewnledger'>
<Viewnledger/>
</Route>
<Route path='/viewnledgeradmin'>
<Viewnledgeradmin/>
</Route>
<Route path='/addnledger'>
<Addnledger/>
</Route>
<Route path='/addnledgerbulk'>
<Addnledgerbulk />
</Route>
<Route path='/editnaccounts'>
<Editnaccounts/>
</Route>
<Route path='/viewnaccounts'>
<Viewnaccounts/>
</Route>
<Route path='/viewnaccountsadmin'>
<Viewnaccountsadmin/>
</Route>
<Route path='/addnaccounts'>
<Addnaccounts/>
</Route>
<Route path='/addnaccountsbulk'>
<Addnaccountsbulk />
</Route>




    <Route path='/dasherpadmin1'>
<Dasherpadmin1/>
</Route>
<Route path='/dashfeedbackreports'>
<Dashfeedbackreports/>
</Route>

    <Route path='/viewplacementdep'>
<Viewplacementdep/>
</Route>
    <Route path='/viewhigherexamdep'>
<Viewhigherexamdep/>
</Route>
    <Route path='/viewhigheredudep'>
<Viewhigheredudep/>
</Route>
    <Route path='/viewecontentdep'>
<Viewecontentdep/>
</Route>
    <Route path='/viewexplearningdocsdep'>
<Viewexplearningdocsdep/>
</Route>
    <Route path='/viewsyllabusrevdocsdep'>
<Viewsyllabusrevdocsdep/>
</Route>
    <Route path='/viewemployabilitydocsdep'>
<Viewemployabilitydocsdep/>
</Route>

    <Route path='/editnmilestone'>
<Editnmilestone/>
</Route>
<Route path='/viewnmilestone'>
<Viewnmilestone/>
</Route>
<Route path='/viewnmilestoneadmin'>
<Viewnmilestoneadmin/>
</Route>
<Route path='/addnmilestone'>
<Addnmilestone/>
</Route>
<Route path='/addnmilestonebulk'>
<Addnmilestonebulk />
</Route>
<Route path='/editninvoice'>
<Editninvoice/>
</Route>
<Route path='/viewninvoice'>
<Viewninvoice/>
</Route>
<Route path='/viewninvoiceadmin'>
<Viewninvoiceadmin/>
</Route>
<Route path='/addninvoice'>
<Addninvoice/>
</Route>
<Route path='/addninvoicebulk'>
<Addninvoicebulk />
</Route>
<Route path='/editngstgain'>
<Editngstgain/>
</Route>
<Route path='/viewngstgain'>
<Viewngstgain/>
</Route>
<Route path='/viewngstgainadmin'>
<Viewngstgainadmin/>
</Route>
<Route path='/addngstgain'>
<Addngstgain/>
</Route>
<Route path='/addngstgainbulk'>
<Addngstgainbulk />
</Route>
<Route path='/editnvendor'>
<Editnvendor/>
</Route>
<Route path='/viewnvendor'>
<Viewnvendor/>
</Route>
<Route path='/viewnvendoradmin'>
<Viewnvendoradmin/>
</Route>
<Route path='/addnvendor'>
<Addnvendor/>
</Route>
<Route path='/addnvendorbulk'>
<Addnvendorbulk />
</Route>
<Route path='/editnvworkorder'>
<Editnvworkorder/>
</Route>
<Route path='/viewnvworkorder'>
<Viewnvworkorder/>
</Route>
<Route path='/viewnvworkorderadmin'>
<Viewnvworkorderadmin/>
</Route>
<Route path='/addnvworkorder'>
<Addnvworkorder/>
</Route>
<Route path='/addnvworkorderbulk'>
<Addnvworkorderbulk />
</Route>
<Route path='/editnvinvoice'>
<Editnvinvoice/>
</Route>
<Route path='/viewnvinvoice'>
<Viewnvinvoice/>
</Route>
<Route path='/viewnvinvoiceadmin'>
<Viewnvinvoiceadmin/>
</Route>
<Route path='/addnvinvoice'>
<Addnvinvoice/>
</Route>
<Route path='/addnvinvoicebulk'>
<Addnvinvoicebulk />
</Route>
<Route path='/editntrialbalance'>
<Editntrialbalance/>
</Route>
<Route path='/viewntrialbalance'>
<Viewntrialbalance/>
</Route>
<Route path='/viewntrialbalanceadmin'>
<Viewntrialbalanceadmin/>
</Route>
<Route path='/addntrialbalance'>
<Addntrialbalance/>
</Route>
<Route path='/addntrialbalancebulk'>
<Addntrialbalancebulk />
</Route>
<Route path='/editnclient'>
<Editnclient/>
</Route>
<Route path='/viewnclient'>
<Viewnclient/>
</Route>
<Route path='/viewnclientadmin'>
<Viewnclientadmin/>
</Route>
<Route path='/addnclient'>
<Addnclient/>
</Route>
<Route path='/addnclientbulk'>
<Addnclientbulk />
</Route>





    <Route path='/viewkpipending'>
<Viewkpipending/>
</Route>
    <Route path='/dasherp1'>
<Dasherp1/>
</Route>

    <Route path='/editnecourse'>
<Editnecourse/>
</Route>
<Route path='/viewnecourse'>
<Viewnecourse/>
</Route>
<Route path='/viewnecourseadmin'>
<Viewnecourseadmin/>
</Route>
<Route path='/addnecourse'>
<Addnecourse/>
</Route>
<Route path='/addnecoursebulk'>
<Addnecoursebulk />
</Route>
<Route path='/editnnextension'>
<Editnnextension/>
</Route>
<Route path='/viewnnextension'>
<Viewnnextension/>
</Route>
<Route path='/viewnnextensionadmin'>
<Viewnnextensionadmin/>
</Route>
<Route path='/addnnextension'>
<Addnnextension/>
</Route>
<Route path='/addnnextensionbulk'>
<Addnnextensionbulk />
</Route>
<Route path='/editnncollab'>
<Editnncollab/>
</Route>
<Route path='/viewnncollab'>
<Viewnncollab/>
</Route>
<Route path='/viewnncollabadmin'>
<Viewnncollabadmin/>
</Route>
<Route path='/addnncollab'>
<Addnncollab/>
</Route>
<Route path='/addnncollabbulk'>
<Addnncollabbulk />
</Route>
<Route path='/editnnmou'>
<Editnnmou/>
</Route>
<Route path='/viewnnmou'>
<Viewnnmou/>
</Route>
<Route path='/viewnnmouadmin'>
<Viewnnmouadmin/>
</Route>
<Route path='/addnnmou'>
<Addnnmou/>
</Route>
<Route path='/addnnmoubulk'>
<Addnnmoubulk />
</Route>
<Route path='/editnnpatients'>
<Editnnpatients/>
</Route>
<Route path='/viewnnpatients'>
<Viewnnpatients/>
</Route>
<Route path='/viewnnpatientsadmin'>
<Viewnnpatientsadmin/>
</Route>
<Route path='/addnnpatients'>
<Addnnpatients/>
</Route>
<Route path='/addnnpatientsbulk'>
<Addnnpatientsbulk />
</Route>
<Route path='/editnnexposure'>
<Editnnexposure/>
</Route>
<Route path='/viewnnexposure'>
<Viewnnexposure/>
</Route>
<Route path='/viewnnexposureadmin'>
<Viewnnexposureadmin/>
</Route>
<Route path='/addnnexposure'>
<Addnnexposure/>
</Route>
<Route path='/addnnexposurebulk'>
<Addnnexposurebulk />
</Route>





    <Route path='/editnnexp'>
<Editnnexp/>
</Route>
<Route path='/viewnnexp'>
<Viewnnexp/>
</Route>
<Route path='/viewnnexpadmin'>
<Viewnnexpadmin/>
</Route>
<Route path='/addnnexp'>
<Addnnexp/>
</Route>
<Route path='/addnnexpbulk'>
<Addnnexpbulk />
</Route>
<Route path='/editnnratio'>
<Editnnratio/>
</Route>
<Route path='/viewnnratio'>
<Viewnnratio/>
</Route>
<Route path='/viewnnratioadmin'>
<Viewnnratioadmin/>
</Route>
<Route path='/addnnratio'>
<Addnnratio/>
</Route>
<Route path='/addnnratiobulk'>
<Addnnratiobulk />
</Route>
<Route path='/editnnmentor'>
<Editnnmentor/>
</Route>
<Route path='/viewnnmentor'>
<Viewnnmentor/>
</Route>
<Route path='/viewnnmentoradmin'>
<Viewnnmentoradmin/>
</Route>
<Route path='/addnnmentor'>
<Addnnmentor/>
</Route>
<Route path='/addnnmentorbulk'>
<Addnnmentorbulk />
</Route>
<Route path='/editnnexam'>
<Editnnexam/>
</Route>
<Route path='/viewnnexam'>
<Viewnnexam/>
</Route>
<Route path='/viewnnexamadmin'>
<Viewnnexamadmin/>
</Route>
<Route path='/addnnexam'>
<Addnnexam/>
</Route>
<Route path='/addnnexambulk'>
<Addnnexambulk />
</Route>


    <Route path='/editnnursinginter'>
<Editnnursinginter/>
</Route>
<Route path='/viewnnursinginter'>
<Viewnnursinginter/>
</Route>
<Route path='/viewnnursinginteradmin'>
<Viewnnursinginteradmin/>
</Route>
<Route path='/addnnursinginter'>
<Addnnursinginter/>
</Route>
<Route path='/addnnursinginterbulk'>
<Addnnursinginterbulk />
</Route>

    <Route path='/editnnvacstud'>
<Editnnvacstud/>
</Route>
<Route path='/viewnnvacstud'>
<Viewnnvacstud/>
</Route>
<Route path='/viewnnvacstudadmin'>
<Viewnnvacstudadmin/>
</Route>
<Route path='/addnnvacstud'>
<Addnnvacstud/>
</Route>
<Route path='/addnnvacstudbulk'>
<Addnnvacstudbulk />
</Route>

    <Route path='/editnprogcourse'>
<Editnprogcourse/>
</Route>
<Route path='/viewnprogcourse'>
<Viewnprogcourse/>
</Route>
<Route path='/viewnprogcourseadmin'>
<Viewnprogcourseadmin/>
</Route>
<Route path='/addnprogcourse'>
<Addnprogcourse/>
</Route>
<Route path='/addnprogcoursebulk'>
<Addnprogcoursebulk />
</Route>

    <Route path='/editndepfunding'>
<Editndepfunding/>
</Route>
<Route path='/viewndepfunding'>
<Viewndepfunding/>
</Route>
<Route path='/viewndepfundingadmin'>
<Viewndepfundingadmin/>
</Route>
<Route path='/addndepfunding'>
<Addndepfunding/>
</Route>
<Route path='/addndepfundingbulk'>
<Addndepfundingbulk />
</Route>


    <Route path='/editnfacilities'>
<Editnfacilities/>
</Route>
<Route path='/viewnfacilities'>
<Viewnfacilities/>
</Route>
<Route path='/viewnfacilitiesadmin'>
<Viewnfacilitiesadmin/>
</Route>
<Route path='/addnfacilities'>
<Addnfacilities/>
</Route>
<Route path='/addnfacilitiesbulk'>
<Addnfacilitiesbulk />
</Route>

    <Route path='/editnreval'>
<Editnreval/>
</Route>
<Route path='/viewnreval'>
<Viewnreval/>
</Route>
<Route path='/viewnrevaladmin'>
<Viewnrevaladmin/>
</Route>
<Route path='/addnreval'>
<Addnreval/>
</Route>
<Route path='/addnrevalbulk'>
<Addnrevalbulk />
</Route>


    <Route path='/editnotherstud'>
<Editnotherstud/>
</Route>
<Route path='/viewnotherstud'>
<Viewnotherstud/>
</Route>
<Route path='/viewnotherstudadmin'>
<Viewnotherstudadmin/>
</Route>
<Route path='/addnotherstud'>
<Addnotherstud/>
</Route>
<Route path='/addnotherstudbulk'>
<Addnotherstudbulk />
</Route>

    <Route path='/editnhlearner'>
<Editnhlearner/>
</Route>
<Route path='/viewnhlearner'>
<Viewnhlearner/>
</Route>
<Route path='/viewnhlearneradmin'>
<Viewnhlearneradmin/>
</Route>
<Route path='/addnhlearner'>
<Addnhlearner/>
</Route>
<Route path='/addnhlearnerbulk'>
<Addnhlearnerbulk />
</Route>

    <Route path='/editnclinicskills'>
<Editnclinicskills/>
</Route>
<Route path='/viewnclinicskills'>
<Viewnclinicskills/>
</Route>
<Route path='/viewnclinicskillsadmin'>
<Viewnclinicskillsadmin/>
</Route>
<Route path='/addnclinicskills'>
<Addnclinicskills/>
</Route>
<Route path='/addnclinicskillsbulk'>
<Addnclinicskillsbulk />
</Route>



    <Route path='/editninter'>
<Editninter/>
</Route>
<Route path='/viewninter'>
<Viewninter/>
</Route>
<Route path='/viewninteradmin'>
<Viewninteradmin/>
</Route>
<Route path='/addninter'>
<Addninter/>
</Route>
<Route path='/addninterbulk'>
<Addninterbulk />
</Route>


    <Route path='/editncourseplan'>
<Editncourseplan/>
</Route>
<Route path='/viewncourseplan'>
<Viewncourseplan/>
</Route>
<Route path='/viewncourseplanadmin'>
<Viewncourseplanadmin/>
</Route>
<Route path='/addncourseplan'>
<Addncourseplan/>
</Route>
<Route path='/addncourseplanbulk'>
<Addncourseplanbulk />
</Route>


    <Route path='/editnenrollment'>
<Editnenrollment/>
</Route>
<Route path='/viewnenrollment'>
<Viewnenrollment/>
</Route>
<Route path='/viewnenrollmentadmin'>
<Viewnenrollmentadmin/>
</Route>
<Route path='/addnenrollment'>
<Addnenrollment/>
</Route>
<Route path='/addnenrollmentbulk'>
<Addnenrollmentbulk />
</Route>


    <Route path='/editnworkbook'>
<Editnworkbook/>
</Route>
<Route path='/viewnworkbook'>
<Viewnworkbook/>
</Route>
<Route path='/viewnworkbookadmin'>
<Viewnworkbookadmin/>
</Route>
<Route path='/addnworkbook'>
<Addnworkbook/>
</Route>
<Route path='/addnworkbookbulk'>
<Addnworkbookbulk />
</Route>

    <Route path='/editnacademic'>
<Editnacademic/>
</Route>
<Route path='/viewnacademic'>
<Viewnacademic/>
</Route>
<Route path='/viewnacademicadmin'>
<Viewnacademicadmin/>
</Route>
<Route path='/addnacademic'>
<Addnacademic/>
</Route>
<Route path='/addnacademicbulk'>
<Addnacademicbulk />
</Route>


    <Route path='/editnsop'>
<Editnsop/></Route>
<Route path='/viewnsop'>
<Viewnsop/></Route>
<Route path='/viewnsopadmin'>
<Viewnsopadmin/></Route>
<Route path='/addnsop'>
<Addnsop/></Route>

<Route path='/viewsportsactdocs'>
      <Viewsportsactdocs/>
    </Route>

    <Route path='/addkpibulkin'>
      <Addkpibulkin/>
    </Route>
    <Route path='/editntimesheet'>
      <Editntimesheet/>
    </Route>
    <Route path='/addntimesheet'>
      <Addntimesheet/>
    </Route>
    <Route path='/viewntimesheet'>
      <Viewntimesheet/>
    </Route>
    <Route path='/viewntimesheetadmin'>
      <Viewntimesheetadmin/>
    </Route>
    <Route path='/viewhnewprogadmin'>
      <Viewhnewprogadmin/>
    </Route>
    <Route path='/edithnewprog'>
      <Edithnewprog/>
    </Route>
    <Route path='/addhnewprog'>
      <Addhnewprog/>
    </Route>
    <Route path='/viewhnewprog'>
      <Viewhnewprog/>
    </Route>
    <Route path='/viewfdplinks'>
      <Viewfdplinks/>
    </Route>
    <Route path='/viewfdpexcel'>
      <Viewfdpexcel/>
    </Route>
    <Route path='/vieweventexcel'>
      <Vieweventexcel/>
    </Route>
    <Route path='/vieweventlinks'>
      <Vieweventlinks/>
    </Route>
    <Route path='/viewskilldevexcel'>
      <Viewskilldevexcel/>
    </Route>
    <Route path='/viewskilldevlinks'>
      <Viewskilldevlinks/>
    </Route>
    <Route path='/viewscholarshipexcel'>
      <Viewscholarshipexcel/>
    </Route>
    <Route path='/viewawardsexcel'>
      <Viewawardsexcel/>
    </Route>
    <Route path='/viewawardslink'>
      <Viewawardslink/>
    </Route>
    <Route path='/viewawardsdocsd'>
      <Viewawardsdocsd/>
    </Route>
    <Route path='/viewextensionexcel'>
      <Viewextensionexcel/>
    </Route>
    <Route path='/viewextensionlinks'>
      <Viewextensionlinks/>
    </Route>
    <Route path='/viewcollabexcel'>
      <Viewcollabexcel/>
    </Route>
    <Route path='/viewbooksexcel'>
      <Viewbooksexcel/>
    </Route>
    <Route path='/viewbooklinks'>
      <Viewbooklinks/>
    </Route>
    <Route path='/viewmoulinks'>
      <Viewmoulinks/>
    </Route>
    <Route path='/viewmouexcel'>
      <Viewmouexcel/>
    </Route>
    <Route path='/viewpubexcel'>
      <Viewpubexcel/>
    </Route>
    <Route path='/viewpublinks'>
      <Viewpublinks/>
    </Route>
    <Route path='/viewprojectlinks'>
      <Viewprojectlinks/>
    </Route>
    <Route path='/viewprojectexcel'>
      <Viewprojectexcel/>
    </Route>
    <Route path='/viewhigherexamlinks'>
      <Viewhigherexamlinks/>
    </Route>
    <Route path='/viewhigherexamexcel'>
      <Viewhigherexamexcel/>
    </Route>
    <Route path='/viewhigheredulinks'>
      <Viewhigheredulinks/>
    </Route>
    <Route path='/viewhighereduexcel'>
      <Viewhighereduexcel/>
    </Route>
    <Route path='/viewplacementlinks'>
      <Viewplacementlinks/>
    </Route>
    <Route path='/viewplacementexcel'>
      <Viewplacementexcel/>
    </Route>
    <Route path='/viewpasslinks'>
      <Viewpasslinks/>
    </Route>
    <Route path='/viewpassexcel'>
      <Viewpassexcel/>
    </Route>
    <Route path='/viewphdguidelink'>
      <Viewphdguidelinks/>
    </Route>
    <Route path='/viewphdguideexcel'>
      <Viewphdguideexcel/>
    </Route>
    <Route path='/viewreservecatlinks'>
      <Viewreservecatlinks/>
    </Route>
    <Route path='/viewadmissionlinks'>
      <Viewadmissionlinks/>
    </Route>
    <Route path='/viewadmissionexcel'>
      <Viewadmissionexcel/>
    </Route>
    <Route path='/viewaddondeclaration'>
      <Viewaddondeclaration/>
    </Route>
    <Route path='/viewexplearnaffexcel'>
      <Viewexplearnaffexcel/>
    </Route>
    <Route path='/viewexplearnprojexcel'>
      <Viewexplearnprojexcel/>
    </Route>
    <Route path='/viewaddonlinksyear'>
      <Viewaddonlinksyear/>
    </Route>
    <Route path='/viewaddonexcelyear'>
      <Viewaddonexcelyear/>
    </Route>
    <Route path='/viewcbcsexcelyear'>
      <Viewcbcsexcelyear/>
    </Route>
    <Route path='/viewboslinksyear'>
      <Viewboslinksyear/>
    </Route>
    <Route path='/viewbosexcelyear'>
      <Viewbosexcelyear/>
    </Route>
    <Route path='/viewfdpcold'>
      <Viewfdpcold/>
    </Route>
    <Route path='/viewfieldprojdd'>
      <Viewfieldprojdd/>
    </Route>
    <Route path='/viewdeletebyuser'>
      <Viewdeletebyuser/>
    </Route>



    
  <Route path='/viewexamautomationdd'>
  <Viewexamautomationd />
  </Route>
  <Route path='/viewincubationdd'>
  <Viewincubationd />
  </Route>
  <Route path='/viewalumnicondd'>
  <Viewalumnicond />
  </Route>
  <Route path='/viewegoverndd'>
  <Viewegovernd />
  </Route>
  <Route path='/viewhigheredudd'>
  <Viewhigheredud />
  </Route>
  <Route path='/viewhigherexamdd'>
  <Viewhigherexamd />
  </Route>
  <Route path='/viewlibraryexpdd'>
  <Viewlibraryexpd />
  </Route>
  <Route path='/viewexpendituredd'>
  <Viewexpenditured />
  </Route>
  <Route path='/viewextensionactdd'>
  <Viewextensionactd />
  </Route>
  <Route path='/viewconsultancydd'>
  <Viewconsultancyd />
  </Route>
  <Route path='/viewecontentdd'>
  <Viewecontentd />
  </Route>
  <Route path='/viewresultdd'>
  <Viewresultd />
  </Route>
  <Route path='/viewqualitydd'>
  <Viewqualityd />
  </Route>
  <Route path='/Viewqualityinitdd'>
  <Viewqualityinitd />
  </Route>
  <Route path='/viewfundsdd'>
  <Viewfundsd />
  </Route>
  <Route path='/viewextawardsd'>
  <Viewextawardsd />
  </Route>
  <Route path='/viewteacherfsdd'>
  <Viewteacherfsd />
  </Route>



    
    <Route path='/viewscholarshipdocs'>
      <Viewscholarshipdocs/>
    </Route>
    <Route path='/thankyouafter'>
      <Thankyouafter/>
    </Route>
    <Route path='/selectawspdf'>
      <Selectawspdf/>
    </Route>
    <Route path='/pdftest2'>
      <Pdftest3/>
    </Route>
    <Route path='/addpdfbulk'>
      <Addpdfbulk/>
    </Route>
    <Route path='/selectawsphoto'>
      <Selectawsphoto/>
    </Route>
    <Route path='/geotagtest1'>
      <Geotagtest1/>
    </Route>
    <Route path='/offlinewelcome'>
      <Offlinewelcome/>
    </Route>
    <Route path='/viewleaveapplybyadmin'>
      <Viewleaveapplyadmin/>
    </Route>
    <Route path='/selectawsconfignew'>
      <Selectawsconfignew/>
    </Route>
    <Route path='/ocrtest2'>
      <Ocrtest34/>
    </Route>
    <Route path='/ocrtest1'>
      <Ocrtest1/>
    </Route>
    <Route path='/checkocr'>
      <Checkocr/>
    </Route>
    <Route path='/viewsyllabuserror'>
      <Viewsyllabuserror/>
    </Route>
    <Route path='/addallkpi1'>
      <Addallkpi1/>
    </Route>
    <Route path='/viewkpiuser1'>
      <Viewkpiuser2/>
    </Route>
    <Route path='/addprojectbalance'>
      <Addprojectbalance/>
    </Route>
    <Route path='/editprojectbalance'>
      <Editprojectbalance/>
    </Route>
    <Route path='/addinvoice'>
      <Addinvoice/>
    </Route>
    <Route path='/editinvoice'>
      <Editinvoice/>
    </Route>
    <Route path='/viewinvoice'>
      <Viewinvoice/>
    </Route>
    <Route path='/viewprojectbalance'>
      <Viewprojectbalance />
    </Route>
    <Route path='/selectmyprojectadm'>
      <Selectmyprojectadm />
    </Route>
    <Route path='/viewresearchfellowadmfac'>
      <Viewresearchfellowadmfac />
    </Route>
    <Route path='/selectmyproject'>
      <Selectmyproject />
    </Route>
    <Route path='/selectproject'>
      <Selectproject />
    </Route>
    <Route path='/viewresearchfellowdocs'>
      <Viewresearchfellodocs />
    </Route>
    <Route path='/viewresearchfellowdocsd'>
      <Viewresearchfellodocsd />
    </Route>
    <Route path='/viewpassexamdocs'>
      <Viewpassexamdocs />
    </Route>
    <Route path='/viewphdguidedoccomments'>
      <Viewphdguidedoccomments />
    </Route>
    <Route path='/viewteacherdatadoc'>
      <Viewteacherdatadoc />
    </Route>
    <Route path='/viewexplearningprojdoc'>
      <Viewexplearningprojdoc />
    </Route>
    <Route path='/viewcbcsdoc'>
      <Viewcbcsdoc />
    </Route>
    <Route path='/viewreservecatdoccomments'>
      <Viewreservecatdoccomments />
    </Route>
    <Route path='/viewnbamca'>
      <Viewnbamca />
    </Route>
    <Route path='/viewnbadiploma'>
      <Viewnbadiploma />
    </Route>
    <Route path='/viewleavehistory'>
      <Viewleavehistory />
    </Route>
    <Route path='/viewleavependingprincipal'>
      <Viewleavependingprincipal />
    </Route>
    <Route path='/viewleavependinghod'>
      <Viewleavependinghod />
    </Route>
    <Route path='/viewleavepending'>
      <Viewleavepending />
    </Route>
    <Route path='/addleaveapply'>
      <Addleaveapply />
    </Route>
    <Route path='/viewleaveapply'>
      <Viewleaveapply />
    </Route>
    <Route path='/viewssstext'>
      <Viewssstext />
    </Route>
    <Route path='/viewssscount'>
      <Viewssscount />
    </Route>
    <Route path='/viewsssqscore'>
      <Viewsssqscore />
    </Route>
    <Route path='/viewdeptfeedbackcount'>
      <Viewdeptfeedbackcount />
    </Route>
    <Route path='/addleavebalance'>
      <Addleavebalance />
    </Route>
    <Route path='/editleavebalance'>
      <Editleavebalance />
    </Route>
    <Route path='/viewleavebalance'>
      <Viewleavebalance />
    </Route>
    <Route path='/addclass'>
      <Addclass />
    </Route>
    <Route path='/editworkloadnew'>
      <Editworkloadnew />
    </Route>
    <Route path='/editlessonplannew'>
      <Editlessonplannew />
    </Route>
    <Route path='/addlessonplannew'>
      <Addlessonplannew />
    </Route>
    <Route path='/viewlessonnew'>
      <Viewlessonnew />
    </Route>
    <Route path='/viewgoals'>
      <Viewgoals />
    </Route>
    <Route path='/addgoals'>
      <Addgoals />
    </Route>
    <Route path='/viewnbaug11admin'>
      <Viewnbaug11admin />
    </Route>
    <Route path='/addnbaug11'>
      <Addnbaug11 />
    </Route>
    <Route path='/viewnbaug11'>
      <Viewnbaug11 />
    </Route>
    <Route path='/viewnbaug'>
      <Viewnbaug />
    </Route>
    <Route path='/viewcurstructureadmin'>
      <Viewcurstructureadmin />
    </Route>
    <Route path='/editcurstructure'>
      <Editcurstructure />
    </Route>
    <Route path='/addcurstructure'>
      <Addcurstructure />
    </Route>
    <Route path='/viewcurstructure'>
      <Viewcurstructure />
    </Route>
    <Route path='/viewremedialadmin'>
      <Viewremedialadmin />
    </Route>
    <Route path='/editremedial'>
      <Editremedial />
    </Route>
    <Route path='/addremedial'>
      <Addremedial />
    </Route>
    <Route path='/viewremedial'>
      <Viewremedial />
    </Route>
    <Route path='/adminuniversitymetric'>
      <Adminuniversitymetric />
    </Route>
    <Route path='/adminaffiliatedmetricnew'>
      <Adminaffiliatedmetricnew />
    </Route>
    <Route path='/viewaffiliatedmetricnew'>
      <Viewaffiliatedmetricnew />
    </Route>
    <Route path='/adminaffiliatedmetric'>
      <Adminaffiliatedmetric />
    </Route>
    <Route path='/viewaffiliatedmetric'>
      <Viewaffiliatedmetric />
    </Route>
    <Route path='/viewuniversitymetric'>
      <Viewuniversitymetric1 />
    </Route>
    <Route path='/viewstudpubadmin'>
      <Viewstudpubadmin />
    </Route>
    <Route path='/viewstudpub'>
      <Viewstudpub />
    </Route>
    <Route path='/addstudpub'>
      <Addstudpub />
    </Route>
    <Route path='/editstudpub'>
      <Editstudpub />
    </Route>
    <Route path='/editdeppub'>
      <Editdeppub />
    </Route>
    <Route path='/adddeppub'>
      <Adddeppub />
    </Route>
    <Route path='/viewdeppublicationsadmin'>
      <Viewdeppublicationsadmin />
    </Route>
    <Route path='/viewdeppublications'>
      <Viewdeppublications />
    </Route>
    <Route path='/addprogramsbulk'>
      <Addprogrambulk />
    </Route>
    <Route path='/editprograms'>
      <Editprograms />
    </Route>
    <Route path='/addprograms'>
      <Addprograms />
    </Route>
    <Route path='/viewprograms'>
      <Viewprograms />
    </Route>
    <Route path='/viewcurgapadmin'>
      <Viewcurgapadmin />
    </Route>
    <Route path='/viewcurgap'>
      <Viewcurgap />
    </Route>
    <Route path='/editcurgap'>
      <Editcurgap />
    </Route>
    <Route path='/addcurgap'>
      <Addcurgap />
    </Route>
    <Route path='/viewincubationadmin'>
      <Viewincubationadmin />
    </Route>
    <Route path='/viewsportsactadmin'>
      <Viewsportsactadmin />
    </Route>
    <Route path='/viewfdpadmin'>
      <Viewfdpadmin />
    </Route>
    <Route path='/viewfundsadmin'>
      <Viewfundsadmin />
    </Route>
    <Route path='/viewqualityinitadmin'>
      <Viewqualityinitadmin />
    </Route>
    <Route path='/viewfieldprojadmin'>
      <Viewfieldprojadmin />
    </Route>
<Route path='/viewexplearnprojadmin'>
      <Viewexplearnprojadmin />
    </Route>

    <Route path='/viewmoudoccomments'>
      <Viewmoudoccomments/>
    </Route>
    <Route path='/viewcollabdoccomments'>
      <Viewcollabdoccomments/>
    </Route>
    <Route path='/viewbosdoccomments'>
      <Viewbosdoccomments/>
    </Route>
    <Route path='/viewictdoccomments'>
      <Viewictdoccomments/>
    </Route>
    <Route path='/viewaddonccomments'>
      <Viewaddonccomments/>
    </Route>
    <Route path='/viewseedmdoccommentsd'>
      <Viewseedmdoccommentsd/>
    </Route>
    <Route path='/viewseedmdoccomments'>
      <Viewseedmdoscomments/>
    </Route>
    <Route path='/vieweventdoccomments'>
      <Vieweventdoccomments/>
    </Route>
    <Route path='/viewcareerdoccomments'>
      <Viewcareerdoccomments/>
    </Route>
    <Route path='/viewplacementdoccomments'>
      <Viewplacementdoccomments/>
    </Route>
    <Route path='/viewskilldevdoccomments'>
      <Viewskilldevdoccomments/>
    </Route>
    <Route path='/viewscholarshipdoccomments'>
      <Viewscholarshipdoccomments/>
    </Route>
    <Route path='/viewadmissiondoccomments'>
      <Viewadmissiondoccomments/>
    </Route>
    <Route path='/viewteacherawarddoccomments'>
      <Viewteacherawarddoccomments/>
    </Route>
    <Route path='/viewteacherfellowdoccomments'>
      <Viewteacherfellowdoccomments/>
    </Route>
    <Route path='/viewinnovationdoccoments'>
      <Viewinnovationdoccomments/>
    </Route>
    <Route path='/viewinnovationdoccomentsd'>
      <Viewinnovationdoccommentsd/>
    </Route>
    <Route path='/viewallbookdoccomments'>
      <Viewallbookdoccomments/>
    </Route>
    <Route path='/viewallpatentdocs'>
      <Viewallpatentdocs/>
    </Route>
    <Route path='/viewsyllabusrevdocs'>
      <Viewsyllabusrevdocs/>
    </Route>
    <Route path='/viewemployabilitydocs'>
      <Viewemployabilitydocs/>
    </Route>
    <Route path='/viewexplearningdocs'>
      <Viewexplearningdocs/>
    </Route>
    <Route path='/selectperiod'>
      <Selectperiod/>
    </Route>
    <Route path='/viewallpubdoccomcy'>
      <Viewallpubdoccomcy/>
    </Route>
    <Route path='/viewallpubdoccomments'>
      <Viewallpubdoccomments/>
    </Route>
    <Route path='/viewallpubdocs'>
      <Viewallpubdocs/>
    </Route>
    <Route path='/viewallsemreimbdocs'>
      <Viewallsemreimbdocs/>
    </Route>
    <Route path='/viewallseminardocs'>
      <Viewallseminardocs/>
    </Route>
    <Route path='/viewprojectyrdocs'>
      <Viewprojectyrdocs/>
    </Route>
    <Route path='/viewprojectsadmindoc'>
      <Viewprojectsadmindoc/>
    </Route>
    <Route path='/noaccess'>
      <Noaccess/>
    </Route>
    <Route path='/viewallhod'>
      <Viewallhod/>
    </Route>
    <Route path='/viewsupportingdocqueryuser'>
      <Viewsupportingdocqueryuser/>
    </Route>
    <Route path='/viewsupportingdocquery'>
      <Viewsupportingdocquery/>
    </Route>
    <Route path='/addkpibulk'>
      <Addkpibulk/>
    </Route>
    <Route path='/viewplacementall'>
      <Viewplacementall/>
    </Route>
    <Route path='/viewweeklyattendance'>
      <Viewweeklyattendance/>
    </Route>
    <Route path='/classattendanceweek'>
      <ClassAttendanceweek/>
    </Route>
    <Route path='/selectattendanceweek'>
      <Selectattendanceweek/>
    </Route>
    <Route path='/viewqsemployers'>
      <Viewqsemployers/>
    </Route>
    <Route path='/viewqspeer'>
      <Viewqspeer/>
    </Route>
    <Route path='/qspeer1/:id'>
      <Qspeer1/>
    </Route>
    <Route exact path='/qspeeradd/:id'>
      <Qspeeradd/>
    </Route>
    <Route path='/viewextawardsadmin'>
      <Viewextawardsadmin/>
    </Route>
    <Route path='/classstudentsmsec'>
      <Classstudentsmsec/>
    </Route>
    <Route path='/viewteacherfellowadmin'>
      <Viewteacherfellowadmin/>
    </Route>
    <Route path='/viewresearchfellowadmin'>
      <Viewresearchfellowadmin/>
    </Route>
    <Route path='/viewphdguideadmin'>
      <Viewphdguideadmin/>
    </Route>
    <Route path='/viewexplearningadmin'>
      <Viewexplearningadmin/>
    </Route>
    <Route path='/viewsyllabusrevadmin'>
      <Viewsyllabusrevadmin/>
    </Route>
    <Route path='/viewemployabilityadmin'>
      <Viewemployabilityadmin/>
    </Route>
    <Route path='/viewsyllabusrevaqao'>
      <Viewsyllabusrevaqao/>
    </Route>
    <Route path='/addsyllabusrevaqao'>
      <Addsyllabusrevaqao/>
    </Route>
    <Route path='/editsyllabusrevaqao'>
      <Editsyllabusrevaqao/>
    </Route>
    <Route path='/viewcbcsuao'>
      <Viewcbcsuao/>
    </Route>
    <Route path='/addcbcsuao'>
      <Addcbcsuao/>
    </Route>
    <Route path='/editcbcsuao'>
      <Editcbcsuao/>
    </Route>
    <Route path='/viewcombined'>
      <Viewcombined/>
    </Route>
    <Route path='/viewstartup'>
      <Viewstartup/>
    </Route>
    <Route path='/addstartup'>
      <Addstartup/>
    </Route>
    <Route path='/editstartup'>
      <Editstartup/>
    </Route>
    <Route path='/viewincubation'>
      <Viewincubation/>
    </Route>
    <Route path='/addincubation'>
      <Addincubation/>
    </Route>
    <Route path='/editincubation'>
      <Editincubation/>
    </Route>
    <Route path='/viewallassignments'>
      <Viewallassignments/>
    </Route>
    <Route path='/viewallcoursefiles'>
      <Viewallcoursefiles/>
    </Route>
    <Route path='/viewallclasses'>
      <Viewallclasses/>
    </Route>
    <Route path='/enrollstudentsext'>
      <Enrollstudentsext1/>
    </Route>
    <Route path='/viewlibbookstudents'>
      <Viewlibbookstudents/>
    </Route>
    <Route path='/viewallstudents'>
      <Viewallstudents/>
    </Route>
    <Route path='/selectmetricrulefiles'>
      <Selectmetricrulefiles/>
    </Route>
    <Route path='/selectmetricrule'>
      <Selectmetricrule/>
    </Route>
    <Route path='/viewmetricrules'>
      <Viewmetricrules/>
    </Route>
    <Route path='/editmetricrules'>
      <Editmetricrules/>
    </Route>
    <Route path='/addmetricrules'>
      <Addmetricrules/>
    </Route>

    <Route path='/updatequestions'>
      <Updatequestions/>
    </Route>
    <Route path='/getstudentbycatgender'>
      <Getstudentbycatgender/>
    </Route>
    <Route path='/getstudentbycategory'>
      <Getstudentbycategory/>
    </Route>
    <Route path='/selectattendance'>
      <Selectattendance/>
    </Route>
    <Route path='/getfacultycount'>
      <Getfacultycount/>
    </Route>
    <Route path='/getstudentcount'>
      <Getstudentcount/>
    </Route>
    <Route path='/viewdepbooksmy'>
      <Viewdepbooksmy/>
    </Route>
    <Route path='/viewdepprojectsmy'>
      <Viewdepprojectsmy/>
    </Route>
    <Route path='/viewdepseminarmy'>
      <Viewdepseminarmy/>
    </Route>
    <Route path='/viewdeppubmy'>
      <Viewdeppubmy/>
    </Route>
    <Route path='/viewsupportingdocadmin'>
      <Viewsupportingdocadmin/>
    </Route>
    <Route path='/editadminbooks'>
      <Editadminbooks/>
    </Route>
    <Route path='/addbulkaddonc'>
      <Addbulkaddonc/>
    </Route>
    <Route path='/addinstitution'>
      <Addinstitution/>
    </Route>
    <Route path='/viewinstitutions'>
      <Viewinstitutions/>
    </Route>
    <Route path='/editinstitutions'>
      <Editinstitutions/>
    </Route>
    <Route path='/selectinstitution'>
      <Selectinstitution/>
    </Route>

    <Route path='/selectdepartment'>
      <Selectdepartment/>
    </Route>
    <Route path='/viewdeppub'>
      <Viewdeppub/>
    </Route>
    <Route path='/viewdepprojects'>
      <Viewdepprojects/>
    </Route>
    <Route path='/viewdepseminar'>
      <Viewdepseminar/>
    </Route>
    <Route path='/viewdepbooks'>
      <Viewdepbooks/>
    </Route>


    <Route path='/addcircularfac'>
      <Addcircularfac/>
    </Route>
    <Route path='/viewcircularfac'>
      <Viewcircularfac/>
    </Route>
    <Route path='/editcircularfac'>
      <Editcircularfac/>
    </Route>

    <Route path='/addsupportingdoc'>
      <Addsupportingdoc/>
    </Route>
    <Route path='/addsupportingdoc2'>
      <Addsupportingdoc2/>
    </Route>
    <Route path='/selectawsconfig'>
      <Selectawsconfig/>
    </Route>
    <Route path='/viewsupportingdoc'>
      <Viewsupportingdoc/>
    </Route>
    <Route path='/viewsupportingdocall'>
      <Viewsupportingdocall/>
    </Route>


    <Route path='/viewawsconfig'>
      <Viewawsconfig/>
    </Route>
    <Route path='/editawsconfig'>
      <Editawsconfig/>
    </Route>
    <Route path='/addawsconfig'>
      <Addawsconfig/>
    </Route>

    <Route path='/viewtaskassignuser'>
      <Viewtaskassignuser/>
    </Route>
    <Route path='/edittaskassignuser'>
      <Edittaskassignuser/>
    </Route>
    <Route path='/viewtaskbyidticket'>
      <Viewtaskbyidticket/>
    </Route>

    <Route path='/addtaskticket'>
      <Addtaskticket/>
    </Route>
    <Route path='/viewtaskticket'>
      <Viewtaskticket/>
    </Route>
    <Route path='/viewtaskticketall'>
      <Viewtaskticketall/>
    </Route>
    <Route path='/edittaskticket'>
      <Edittaskticket/>
    </Route>

    <Route path='/thankyou'>
      <Thankyou/>
    </Route>
   

    <Route path='/addbosfac'>
      <Addbosfac/>
    </Route>
    <Route path='/viewbosfac'>
      <Viewbosfac/>
    </Route>
    <Route path='/editbosfac'>
      <Editbosfac/>
    </Route>

    <Route path='/adddepartment'>
      <Adddepartment/>
    </Route>
    <Route path='/viewdepartment'>
      <Viewdepartment/>
    </Route>
    <Route path='/editdepartment'>
      <Editdepartment/>
    </Route>

    <Route path='/addpubaqar'>
      <Addpubaqar/>
    </Route>
    <Route path='/viewpubaqar'>
      <Viewpubaqar/>
    </Route>
    <Route path='/editpubaqar'>
      <Editpubaqar/>
    </Route>

    <Route path='/addtaskassign'>
      <Addtaskassign/>
    </Route>
    <Route path='/edittaskassign'>
      <Edittaskassign />
    </Route>
    <Route path='/viewtaskassign'>
      <Viewtaskassign />
    </Route>
    
    <Route path='/viewkpigroup'>
      <Viewkpigroup />
    </Route>
    <Route path='/viewkpicriteria'>
      <Viewkpicriteria />
    </Route>
    <Route path='/viewweeklystatus'>
      <Viewweeklystatus />
    </Route>
    <Route path='/viewsuperweekly'>
      <Viewsuperweekly />
    </Route>
    <Route path='/addallkpi'>
      <Addallkpi/>
    </Route>
    <Route path='/alumnifeedbakanalysis'>
      <Alumnifeedbackanalysis />
    </Route>
    <Route path='/viewallfaculties'>
      <Viewallfaculties />
    </Route>
    <Route path='/viewfacultyreport'>
      <Viewfacultyreport />
    </Route>
    <Route path='/viewinnovation'>
      <Viewinnovation />
    </Route>
    <Route path='/viewinnovationadmin'>
      <Viewinnovationadmin />
    </Route>
    <Route path='/addinnovation'>
      <Addinnovation />
    </Route>
    <Route path='/editinnovation'>
      <Editinnovation />
    </Route>
    <Route path='/vieweventfdp'>
      <Vieweventfdp />
    </Route>
    <Route path='/open'>
      <Openclasses />
    </Route>
    <Route path='/workload'>
      <Workload13 />
    </Route>
    <Route path='/takeattendance'>
      <Takeattendance />
    </Route>
    <Route path='/viewclassattendance'>
      <Viewclassattendance />
    </Route>
    <Route path='/viewscopus'>
      <Viewscopus />
    </Route>
    <Route path='/assetlist'>
      <Assetlist />
    </Route>
    <Route path='/addasset'>
      <Addassets />
    </Route>
    <Route path='/assetassignment'>
      <Assetassignment />
    </Route>
    <Route path='/addassetassignment'>
      <Addassetassignment />
    </Route>
    <Route path='/addworkload'>
      <Addworkload />
    </Route>
    <Route path='/addworkloadf'>
      <Addworkloadf />
    </Route>
    <Route path='/submittedassignments'>
      <Submittedassignments />
    </Route>
    <Route path='/submissioncomments'>
      <Submissioncomments />
    </Route>
    <Route path='/addperiod'>
      <Addperiod />
    </Route>
    <Route path='/dashcourse'>
      <Dashcourse2 />
    </Route>
    <Route path='/dashcoursef'>
      <Dashcoursef />
    </Route>
    <Route path='/courseactions'>
      <CourseActions />
    </Route>
    <Route path='/enrollstudents'>
      <Enrollstudents1 />
    </Route>
    <Route path='/classstudents'>
      <Classstudents />
    </Route>
    <Route path='/classstudentsm'>
      <Classstudentsm />
    </Route>
    <Route path='/classattendance'>
      <Classattendance4 />
    </Route>
    <Route path='/getmyfeedback'>
      <Getmyfeedback />
    </Route>
    <Route path='/addassignments'>
      <Addassignments1 />
    </Route>
    <Route path='/classassignments'>
      <Classassignments />
    </Route>
    <Route path='/addcoursefiles'>
      <Addcoursefiles />
    </Route>
    <Route path='/classfiles'>
      <Classfiles />
    </Route>
    <Route path='/proctorlist'>
      <Proctorlist />
    </Route>
    <Route path='/examenrlist'>
      <Examenrlist1 />
    </Route>
    <Route path='/lessonplan'>
      <Lessonplan />
    </Route>
    <Route path='/addlessonplan'>
      <Addlessonplan />
    </Route>
    <Route path='/menteelist'>
      <Menteelist />
    </Route>
    <Route path='/mentorlogbook'>
      <Mentorlogbook />
    </Route>
    <Route path='/addmentorlogbook'>
      <Addmentorlogbook />
    </Route>
    <Route path='/addmentee'>
      <Addmentee />
    </Route>
    <Route path='/fees'>
      <Fees />
    </Route>
    <Route path='/workdiary'>
      <Workdiary />
    </Route>
    <Route path='/addlibbooks'>
      <Addlibbooks />
    </Route>
    <Route path='/libraryactions'>
      <LibraryActions />
    </Route>
    <Route path='/viewlibbooks'>
      <Viewlibbooks1 />
    </Route>
    <Route path='/viewlibbooksfaculty'>
      <Viewlibbooksfaculty />
    </Route>
    <Route path='/searchlibbooks'>
      <Searchlibbooks />
    </Route>
    <Route path='/searchlibbooks1'>
      <Searchlibbooks1 />
    </Route>
    <Route path='/libissuebooks'>
      <Libissuebooks />
    </Route>
    <Route path='/libreturnbooks'>
      <Libreturnbooks />
    </Route>
    <Route path='/libissuelist'>
      <Libissuelist />
    </Route>
    <Route path='/libissuelistall'>
      <Libissuelistall />
    </Route>
    <Route path='/viewprojects'>
      <Viewprojects />
    </Route>
    <Route path='/addprojects'>
      <Addprojects />
    </Route>
    <Route path='/editprojects'>
      <Editprojects />
    </Route>
    <Route path='/viewseminar'>
      <Viewseminar />
    </Route>
    <Route path='/addseminar'>
      <Addseminar />
    </Route>
    <Route path='/editseminar'>
      <Editseminar />
    </Route>
    <Route path='/viewpatent'>
      <Viewpatent />
    </Route>
    <Route path='/addpatent'>
      <Addpatent />
    </Route>
    <Route path='/editpatent'>
      <Editpatent />
    </Route>
    <Route path='/viewpub'>
      <Viewpub />
    </Route>
    <Route path='/addpub'>
      <Addpub />
    </Route>
    <Route path='/editpub'>
      <Editpub />
    </Route>
    <Route path='/viewbooks'>
      <Viewbooks />
    </Route>
    <Route path='/addbooks'>
      <Addbooks />
    </Route>
    <Route path='/editbooks'>
      <Editbooks />
    </Route>
    <Route path='/dashmydetails'>
      <Dashmydetails2 />
    </Route>
    <Route path='/updatepassword'>
      <Updatepassword1 />
    </Route>
    <Route path='/dashcriteria1'>
      <Dashcriteria1 />
    </Route>
    <Route path='/dashcriteria2'>
      <Dashcriteria2 />
    </Route>
    <Route path='/dashcriteria3'>
      <Dashcriteria3 />
    </Route>
    <Route path='/dashcriteria4'>
      <Dashcriteria4 />
    </Route>
    <Route path='/dashcriteria5'>
      <Dashcriteria5 />
    </Route>
    <Route path='/dashcriteria6'>
      <Dashcriteria6 />
    </Route>
    <Route path='/webinarregister/:id'>
      <Webinarregister />
    </Route>
    <Route path='/parentfeedbackanalysis'>
      <Parentfeedbackanalysis1 />
    </Route>
    <Route path='/curriculumfeedbackoption1'>
      <Curriculumfeedbackopt1 />
    </Route>
    <Route path='/employerfeedbackanalysis'>
      <Employerfeedbackanalysis />
    </Route>
    <Route path='/viewalerts'>
      <Viewalerts />
    </Route>
    <Route path='/viewkpi'>
      <Viewkpi />
    </Route>
    <Route path='/addphdguidebulk'>
      <Addphdguidebulk/>
    </Route>
    <Route path='/viewdynamicmodules'>
      <Viewdynamicmodules />
    </Route>
    <Route path='/selectcourse'>
      <Selectcourse />
    </Route>
    <Route path='/viewdynamicworkdiary'>
      <Viewdynamicworkdiary />
    </Route>
    <Route path='/viewvacstudlist'>
      <Viewvacstudlist />
    </Route>
    <Route path='/dashfacultydetails'>
      <Dashfacultydetails />
    </Route>
    <Route path='/viewallbooks'>
      <Viewallbooks />
    </Route>
    <Route path='/viewallprojects'>
      <Viewallprojects />
    </Route>
    <Route path='/viewallpublications'>
      <Viewallpubs />
    </Route>
    <Route path='/viewallpatents'>
      <Viewallpatents />
    </Route>
    <Route path='/viewallseminars'>
      <Viewallseminars />
    </Route>
    <Route path='/viewaddoncourseadmin'>
      <Viewaddoncourseadmin />
    </Route>
    <Route path='/viewadmissionadmin'>
      <Viewadmissionadmin />
    </Route>
    <Route path='/viewbosadmin'>
      <Viewbosadmin />
    </Route>
    <Route path='/viewcollaborationadmin'>
      <Viewcollaborationadmin />
    </Route>
    <Route path='/vieweventadmin'>
      <Vieweventadmin />
    </Route>
    <Route path='/viewexpenditureadmin'>
      <Viewexpenditureadmin />
    </Route>
    <Route path='/viewextensionactadmin'>
      <Viewextensionactadmin />
    </Route>
    <Route path='/viewictadmin'>
      <Viewictadmin />
    </Route>
    <Route path='/viewinstawardsadmin'>
      <Viewinstawardsadmin />
    </Route>
    <Route path='/viewlibraryexpadmin'>
      <Viewlibraryexpadmin />
    </Route>
    <Route path='/viewmouadmin'>
      <Viewmouadmin />
    </Route>
    <Route path='/viewpassexamadmin'>
      <Viewpassexamadmin />
    </Route>
    <Route path='/viewreservecatadmin'>
      <Viewreservecatadmin />
    </Route>
    <Route path='/deleteroleuser'>
      <Deleteroleuser />
    </Route>
    <Route path='/vieweventextension'>
      <Vieweventextension />
    </Route>
    <Route path='/vieweventipr'>
      <Vieweventipr />
    </Route>
    <Route path='/viewmlink'>
      <Viewmlink />
    </Route>
    <Route path='/editmlink'>
      <Editmlink />
    </Route>
    <Route path='/addmlink'>
      <Addmlink />
    </Route>


    <Route path='/addsdg3'>
      <Addsdg3/>
    </Route>
    <Route path='/editsdg3'>
      <Editsdg3 />
    </Route>
    <Route path='/viewsdg3'>
      <Viewsdg3 />
    </Route>
    <Route path='/addsdg4'>
      <Addsdg4/>
    </Route>
    <Route path='/editsdg4'>
      <Editsdg4 />
    </Route>
    <Route path='/viewsdg4'>
      <Viewsdg4 />
    </Route>
    <Route path='/addsdg5'>
      <Addsdg5/>
    </Route>
    <Route path='/editsdg5'>
      <Editsdg5 />
    </Route>
    <Route path='/viewsdg5'>
      <Viewsdg5 />
    </Route>
    <Route path='/addsdg8'>
      <Addsdg8/>
    </Route>
    <Route path='/editsdg8'>
      <Editsdg8 />
    </Route>
    <Route path='/viewsdg8'>
      <Viewsdg8 />
    </Route>
    <Route path='/addsdg9'>
      <Addsdg9/>
    </Route>
    <Route path='/editsdg9'>
      <Editsdg9 />
    </Route>
    <Route path='/viewsdg9'>
      <Viewsdg9 />
    </Route>
    <Route path='/addsdg10'>
      <Addsdg10/>
    </Route>
    <Route path='/editsdg10'>
      <Editsdg10 />
    </Route>
    <Route path='/viewsdg10'>
      <Viewsdg10 />
    </Route>
    <Route path='/addsdg11'>
      <Addsdg11/>
    </Route>
    <Route path='/editsdg11'>
      <Editsdg11 />
    </Route>
    <Route path='/viewsdg11'>
      <Viewsdg11 />
    </Route>
    <Route path='/addsdg12'>
      <Addsdg12/>
    </Route>
    <Route path='/editsdg12'>
      <Editsdg12 />
    </Route>
    <Route path='/viewsdg12'>
      <Viewsdg12 />
    </Route>
    <Route path='/addsdg13'>
      <Addsdg13/>
    </Route>
    <Route path='/editsdg13'>
      <Editsdg13 />
    </Route>
    <Route path='/viewsdg13'>
      <Viewsdg13 />
    </Route>
    <Route path='/addsdg16'>
      <Addsdg16/>
    </Route>
    <Route path='/editsdg16'>
      <Editsdg16 />
    </Route>
    <Route path='/viewsdg16'>
      <Viewsdg16 />
    </Route>
    <Route path='/addsdg17'>
      <Addsdg17/>
    </Route>
    <Route path='/editsdg17'>
      <Editsdg17 />
    </Route>
    <Route path='/viewsdg17'>
      <Viewsdg17 />
    </Route>
    <Route path='/viewsdg'>
      <Viewsdg />
    </Route>

    <Route path='/addsdg1'>
      <Addsdg1/>
    </Route>
    <Route path='/editsdg1'>
      <Editsdg1 />
    </Route>
    <Route path='/viewsdg1'>
      <Viewsdg1 />
    </Route>
    <Route path='/addsdg2'>
      <Addsdg2/>
    </Route>
    <Route path='/editsdg2'>
      <Editsdg2 />
    </Route>
    <Route path='/viewsdg2'>
      <Viewsdg2 />
    </Route>
    <Route path='/addsdg6'>
      <Addsdg6/>
    </Route>
    <Route path='/editsdg6'>
      <Editsdg6 />
    </Route>
    <Route path='/viewsdg6'>
      <Viewsdg6 />
    </Route>
    <Route path='/addsdg7'>
      <Addsdg7/>
    </Route>
    <Route path='/editsdg7'>
      <Editsdg7 />
    </Route>
    <Route path='/viewsdg7'>
      <Viewsdg7 />
    </Route>
    <Route path='/addsdg14'>
      <Addsdg14/>
    </Route>
    <Route path='/editsdg14'>
      <Editsdg14 />
    </Route>
    <Route path='/viewsdg14'>
      <Viewsdg14 />
    </Route>
    <Route path='/addsdg15'>
      <Addsdg15/>
    </Route>
    <Route path='/editsdg15'>
      <Editsdg15 />
    </Route>
    <Route path='/viewsdg15'>
      <Viewsdg15 />
    </Route>




    <Route path='/viewawardsadmin'>
      <Viewawardsadmin />
    </Route>
    <Route path='/viewcareercounseladmin'>
      <Viewcareercounseladmin />
    </Route>
    <Route path='/viewcbcsadmin'>
      <Viewcbcsadmin />
    </Route>
    <Route path='/viewegovernadmin'>
      <Viewegovernadmin />
    </Route> 
    <Route path='/viewhighereduadmin'>
      <Viewhighereduadmin />
    </Route>
    <Route path='/viewhigherexamadmin'>
      <Viewhigherexamadmin />
    </Route>
    <Route path='/viewplacementadmin'>
      <Viewplacementadmin />
    </Route>
    <Route path='/viewqualityadmin'>
      <Viewqualityadmin />
    </Route>
    <Route path='/viewskilldevadmin'>
      <Viewskilldevadmin />
    </Route>
    <Route path='/viewteacherdataadmin'>
      <Viewteacherdataadmin />
    </Route>
    <Route path='/viewteacherfsadmin'>
      <Viewteacherfsadmin />
    </Route>




    <Route path='/addaddoncourse'>
      <Addaddoncourse />
    </Route>
    <Route path='/addadmission'>
      <Addadmission />
    </Route>
    <Route path='/addbos'>
      <Addbos />
    </Route>
    <Route path='/addcbcs'>
      <Addcbcs />
    </Route>
    <Route path='/addcollaboration'>
      <Addcollaboration />
    </Route>
    <Route path='/addevent'>
      <Addevent />
    </Route>
    <Route path='/addexpenditure'>
      <Addexpenditure />
    </Route>
    <Route path='/addextensionact'>
      <Addextensionact />
    </Route>
    <Route path='/addict'>
      <Addict />
    </Route>
    <Route path='/addinstawards'>
      <Addinstawards />
    </Route>
    <Route path='/addlibraryexp'>
      <Addlibraryexp />
    </Route>
    <Route path='/addmou'>
      <Addmou />
    </Route>
    <Route path='/addpassexam'>
      <Addpassexam />
    </Route>
    <Route path='/addreservecategory'>
      <Addreservecat />
    </Route>
    <Route path='/addteacherdata'>
      <Addteacherdata />
    </Route>
  
    <Route path='/addegovern'>
      <Addegovern />
    </Route>
    <Route path='/addcareercounsel'>
      <Addcareercounsel />
    </Route>
    <Route path='/addawards'>
      <Addawards />
    </Route>
    <Route path='/addhigheredu'>
      <Addhigheredu />
    </Route>
    <Route path='/addhigherexam'>
      <Addhigherexam />
    </Route>
    <Route path='/addplacement'>
      <Addplacement />
    </Route>
    <Route path='/addquality'>
      <Addquality />
    </Route>
    <Route path='/addskilldev'>
      <Addskilldev />
    </Route>
    <Route path='/addteacherfs'>
      <Addteacherfs />
    </Route>


    <Route path='/viewaddoncourse'>
      <Viewaddoncourse />
    </Route>
    <Route path='/viewadmission'>
      <Viewadmission />
    </Route>
    <Route path='/viewbos'>
      <Viewbos />
    </Route>
    <Route path='/viewcbcs'>
      <Viewcbcs />
    </Route>
    <Route path='/viewcollaboration'>
      <Viewcollaboration />
    </Route>
    <Route path='/viewevent'>
      <Viewevent />
    </Route>
    <Route path='/viewexpenditure'>
      <Viewexpenditure />
    </Route>
    <Route path='/viewextact'>
      <Viewextensionact />
    </Route>
    <Route path='/viewict'>
      <Viewict />
    </Route>
    <Route path='/viewextawards'>
      <Viewinstawards />
    </Route>
    <Route path='/viewlibrary'>
      <Viewlibraryexp />
    </Route>
    <Route path='/viewmou'>
      <Viewmou />
    </Route>
    <Route path='/viewpassexam'>
      <Viewpassexam />
    </Route>
    <Route path='/viewreservecat'>
      <Viewreservecat />
    </Route>
    <Route path='/viewteacherdata'>
      <Viewteacherdata />
    </Route>

    <Route path='/viewegovern'>
      <Viewegovern />
    </Route>
    <Route path='/viewcareercounsel'>
      <Viewcareercounsel />
    </Route>
    <Route path='/viewawards'>
      <Viewawards />
    </Route>
    <Route path='/viewhigheredu'>
      <Viewhigheredu />
    </Route>
    <Route path='/viewhigherexam'>
      <Viewhigherexam />
    </Route>
    <Route path='/viewplacement'>
      <Viewplacement />
    </Route>
    <Route path='/viewquality'>
      <Viewquality />
    </Route>
    <Route path='/viewskilldev'>
      <Viewskilldev />
    </Route>
    <Route path='/viewteacherfs'>
      <Viewteacherfs />
    </Route>

    <Route path='/editaddoncourse'>
      <Editaddoncourse />
    </Route>
    <Route path='/editadmission'>
      <Editadmission />
    </Route>
    <Route path='/editbos'>
      <Editbos />
    </Route>
    <Route path='/editcbcs'>
      <Editcbcs />
    </Route>
    <Route path='/editcollaboration'>
      <Editcollaboration />
    </Route>
    <Route path='/editevent'>
      <Editevent />
    </Route>
    <Route path='/editexpenditure'>
      <Editexpenditure />
    </Route>
    <Route path='/editextensionact'>
      <Editextensionact />
    </Route>
    <Route path='/editict'>
      <Editict />
    </Route>
    <Route path='/editinstawards'>
      <Editinstawards />
    </Route>
    <Route path='/editlibraryexp'>
      <Editlibraryexp />
    </Route>
    <Route path='/editmou'>
      <Editmou />
    </Route>
    <Route path='/editpassexam'>
      <Editpassexam />
    </Route>
    <Route path='/editreservecategory'>
      <Editreservecat />
    </Route>
    <Route path='/editteacherdata'>
      <Editteacherdata />
    </Route>

    <Route path='/editegovern'>
      <Editegovern />
    </Route>
    <Route path='/editcareercounsel'>
      <Editcareercounsel />
    </Route>
    <Route path='/editawards'>
      <Editawards />
    </Route>
    <Route path='/edithigheredu'>
      <Edithigheredu />
    </Route>
    <Route path='/edithigherexam'>
      <Edithigherexam />
    </Route>
    <Route path='/editplacement'>
      <Editplacement />
    </Route>
    <Route path='/editquality'>
      <Editquality />
    </Route>
    <Route path='/editskilldev'>
      <Editskilldev />
    </Route>
    <Route path='/editteacherfs'>
      <Editteacherfs />
    </Route>

    <Route path='/addprojectstatus'>
      <Addprojectstatus1 />
    </Route>
    <Route path='/editprojectstatus'>
      <Editprojectstatus />
    </Route>
    <Route path='/viewprojectstatus'>
      <Viewprojectstatus />
    </Route>
    <Route path='/viewprojectstatusv'>
      <Viewprojectstatusv />
    </Route>
    <Route path='/viewprojectstatusbytaskdep'>
      <Viewprojectstatusbytaskdep />
    </Route>
    <Route path='/viewprojectstatusbytask'>
      <Viewprojectstatusbytask />
    </Route>

    <Route path='/addkpi'>
      <Addkpi/>
    </Route>
    <Route path='/editkpi'>
      <Editkpi />
    </Route>
    <Route path='/viewkpiuser'>
      <Viewkpiuser />
    </Route>

<Route path='/addtasks'>
      <Addtasks/>
    </Route>
    <Route path='/edittasks'>
      <Edittasks />
    </Route>
    <Route path='/viewtasks'>
      <Viewtasks />
    </Route>



    <Route path='/addresult'>
      <Addresult/>
    </Route>
    <Route path='/editresult'>
      <Editresult />
    </Route>
    <Route path='/viewresult'>
      <Viewresult />
    </Route>

    <Route path='/addecontent'>
      <Addecontent/>
    </Route>
    <Route path='/editecontent'>
      <Editecontent />
    </Route>
    <Route path='/viewecontent'>
      <Viewecontent />
    </Route>

    <Route path='/addscholarship'>
      <Addscholarship/>
    </Route>
    <Route path='/editscholarship'>
      <Editscholarship />
    </Route>
    <Route path='/viewscholarship'>
      <Viewscholarship />
    </Route>

    <Route path='/addfunds'>
      <Addfunds/>
    </Route>
    <Route path='/editfunds'>
      <Editfunds />
    </Route>
    <Route path='/viewfunds'>
      <Viewfunds />
    </Route>

    <Route path='/addseedm'>
      <Addseedm/>
    </Route>
    <Route path='/editseedm'>
      <Editseedm />
    </Route>
    <Route path='/viewseedm'>
      <Viewseedm />
    </Route>

    <Route path='/addexplearnprojbulk'>
      <Addexplearnprojbulk/>
    </Route>
    <Route path='/addfieldprojbulk'>
      <Addfieldprojbulk/>
    </Route>
    <Route path='/addteacherguidebulk'>
      <Addteacherguidebulk/>
    </Route>
    <Route path='/addsportsactbulk'>
      <Addsportsactbulk/>
    </Route>
    <Route path='/addegovbulk'>
      <Addegovbulk/>
    </Route>
    <Route path='/addeventbulk'>
      <Addeventbulk/>
    </Route>
    <Route path='/addfdpbulk'>
      <Addfdpbulk/>
    </Route>
    <Route path='/addqualityinitbulk'>
      <Addqualityinitbulk/>
    </Route>
    <Route path='/addmouactbulk'>
      <Addmouactbulk/>
    </Route>

    <Route path='/addconsultancy'>
      <Addconsultancy/>
    </Route>
    <Route path='/editconsultancy'>
      <Editconsultancy />
    </Route>
    <Route path='/viewconsultancy'>
      <Viewconsultancy />
    </Route>

    <Route path='/addsyllabusrev'>
      <Addsyllabusrev/>
    </Route>
    <Route path='/editsyllabusrev'>
      <Editsyllabusrev />
    </Route>
    <Route path='/viewsyllabusrev'>
      <Viewsyllabusrev />
    </Route>


    <Route path='/addexplearning'>
      <Addexplearning/>
    </Route>
    <Route path='/editexplearning'>
      <Editexplearning />
    </Route>
    <Route path='/viewexplearning'>
      <Viewexplearning />
    </Route>




    <Route path='/addexplearnproj'>
      <Addexplearnproj/>
    </Route>
    <Route path='/editexplearnproj'>
      <Editexplearnproj />
    </Route>
    <Route path='/viewexplearnproj'>
      <Viewexplearnproj />
    </Route>
    <Route path='/addfieldproj'>
      <Addfieldproj/>
    </Route>
    <Route path='/editfieldproj'>
      <Editfieldproj />
    </Route>
    <Route path='/viewfieldproj'>
      <Viewfieldproj />
    </Route>
    <Route path='/addteacherguide'>
      <Addteacherguide/>
    </Route>
    <Route path='/editteacherguide'>
      <Editteacherguide />
    </Route>
    <Route path='/viewteacherguide'>
      <Viewteacherguide />
    </Route>
    <Route path='/addextawards'>
      <Addextawards/>
    </Route>
    <Route path='/editextawards'>
      <Editextawards />
    </Route>
    <Route path='/viewextawards'>
      <Viewextawards />
    </Route>
    <Route path='/addmouact'>
      <Addmouact/>
    </Route>
    <Route path='/editmouact'>
      <Editmouact />
    </Route>
    <Route path='/viewmouact'>
      <Viewmouact />
    </Route>
    <Route path='/addsportsact'>
      <Addsportsact/>
    </Route>
    <Route path='/editsportsact'>
      <Editsportsact />
    </Route>
    <Route path='/viewsportsact'>
      <Viewsportsact />
    </Route>
    <Route path='/addegov'>
      <Addegov/>
    </Route>
    <Route path='/editegov'>
      <Editegov />
    </Route>
    <Route path='/viewegov'>
      <Viewegov />
    </Route>
    <Route path='/addfdp'>
      <Addfdp/>
    </Route>
    <Route path='/editfdp'>
      <Editfdp />
    </Route>
    <Route path='/viewfdp'>
      <Viewfdp />
    </Route>
    <Route path='/addqualityinit'>
      <Addqualityinit/>
    </Route>
    <Route path='/editqualityinit'>
      <Editqualityinit />
    </Route>
    <Route path='/viewqualityinit'>
      <Viewqualityinit />
    </Route>


    <Route path='/addbulkpublications'>
      <Addbulkpublications/>
    </Route>
    <Route path='/addbulkpatents'>
      <Addbulkpatents/>
    </Route>
    <Route path='/addbulkprojects'>
      <Addbulkprojects/>
    </Route>
    <Route path='/addbulkbooks'>
      <Addbulkbooks/>
    </Route>
    <Route path='/addbulkseminar'>
      <Addbulkseminar/>
    </Route>



    <Route path='/addemployability'>
      <Addemployability/>
    </Route>
    <Route path='/editemployability'>
      <Editemployability />
    </Route>
    <Route path='/viewemployability'>
      <Viewemployability />
    </Route>

    <Route path='/addphdguide'>
      <Addphdguide/>
    </Route>
    <Route path='/editphdguide'>
      <Editphdguide />
    </Route>
    <Route path='/viewphdguide'>
      <Viewphdguide />
    </Route>

    <Route path='/addteacheraward'>
      <Addteacheraward />
    </Route>
    <Route path='/editteacheraward'>
      <Editteacheraward />
    </Route>
    <Route path='/viewteacheraward'>
      <Viewteacheraward />
    </Route>

    <Route path='/addexamautomation'>
      <Addexamautomation/>
    </Route>
    <Route path='/editexamautomation'>
      <Editexamautomation />
    </Route>
    <Route path='/viewexamautomation'>
      <Viewexamautomation />
    </Route>

    <Route path='/addteacherfellow'>
      <Addteacherfellow/>
    </Route>
    <Route path='/editteacherfellow'>
      <Editteacherfellow />
    </Route>
    <Route path='/viewteacherfellow'>
      <Viewteacherfellow />
    </Route>

    <Route path='/addresearchfellow'>
      <Addresearchfellow/>
    </Route>
    <Route path='/editresearchfellow'>
      <Editresearchfellow />
    </Route>
    <Route path='/viewresearchfellow'>
      <Viewresearchfellow />
    </Route>

    <Route path='/addmentees'>
      <Addmentees/>
    </Route>
    <Route path='/editmentees'>
      <Editmentees />
    </Route>
    <Route path='/viewmentees'>
      <Viewmentees />
    </Route>

    <Route path='/addalumnicon'>
      <Addalumnicon/>
    </Route>
    <Route path='/editalumnicon'>
      <Editalumnicon />
    </Route>
    <Route path='/viewalumnicon'>
      <Viewalumnicon />
    </Route>




    <Route path='/addbosbulk'>
      <Addbosbulk/>
    </Route>
    <Route path='/addcbcsbulk'>
      <Addcbcsbulk/>
    </Route>
    <Route path='/addsyllabusrevbulk'>
      <Addsyllabusrevbulk/>
    </Route>
    <Route path='/addemployabilitybulk'>
      <Addemployabilitybulk/>
    </Route>
    <Route path='/addexplearningbulk'>
      <Addexplearningbulk/>
    </Route>

    <Route path='/addadmissionbulk'>
      <Addadmissionbulk/>
    </Route>
    <Route path='/addreservecatbulk'>
      <Addreservecatbulk/>
    </Route>
    <Route path='/addteacherdatabulk'>
      <Addteacherdatabulk/>
    </Route>
    <Route path='/addpassexambulk'>
      <Addpassexambulk/>
    </Route>
    <Route path='/addresultbulk'>
      <Addresultbulk/>
    </Route>
    <Route path='/addteacherawardbulk'>
      <Addteacherawardbulk/>
    </Route>
    <Route path='/addexamautomationbulk'>
      <Addexamautomationbulk/>
    </Route>
    <Route path='/addmenteesbulk'>
      <Addmenteesbulk/>
    </Route>

    <Route path='/addinstawardsbulk'>
      <Addinstawardsbulk/>
    </Route>
    <Route path='/addextactbulk'>
      <Addextactbulk/>
    </Route>
    <Route path='/addcollaborationbulk'>
      <Addcollaborationbulk/>
    </Route>
    <Route path='/addmoubulk'>
      <Addmoubulk/>
    </Route>
    <Route path='/addecontentbulk'>
      <Addecontentbulk/>
    </Route>
    <Route path='/addseedmbulk'>
      <Addseedmbulk/>
    </Route>
    <Route path='/addteacherfellowbulk'>
      <Addteacherfellowbulk/>
    </Route>
    <Route path='/addresearchfellowbulk'>
      <Addresearchfellowbulk/>

    </Route>
    <Route path='/addictbulk'>
      <Addictbulk/>
    </Route>
    <Route path='/addexpenditurebulk'>
      <Addexpenditurebulk/>
    </Route>
    <Route path='/addlibrarybulk'>
      <Addlibrarybulk/>
    </Route>

    <Route path='/addscholarshipbulk'>
      <Addscholarshipbulk/>
    </Route>
    <Route path='/addskilldevbulk'>
      <Addskilldevbulk/>
    </Route>
    <Route path='/addcareercounselbulk'>
      <Addcareercounselbulk/>
    </Route>
    <Route path='/addplacementbulk'>
      <Addplacementbulk/>
    </Route>
    <Route path='/addhigheredubulk'>
      <Addhigheredubulk/>
    </Route>
    <Route path='/addhigherexambulk'>
      <Addhigherexambulk/>
    </Route>
    <Route path='/addawardsbulk'>
      <Addawardsbulk/>
    </Route>
    <Route path='/addalumniconbulk'>
      <Addalumniconbulk/>
    </Route>

    <Route path='/addegovernbulk'>
      <Addegovernbulk/>
    </Route>
    <Route path='/addteacherfsbulk'>
      <Addteacherfsbulk/>
    </Route>
    <Route path='/addfundsbulk'>
      <Addfundsbulk/>
    </Route>
    <Route path='/addqualitybulk'>
      <Addqualitybulk/>
    </Route>

    <Route path='/viewecontentadmin'>
      <Viewecontentadmin />
    </Route>
    <Route path='/viewalumniconadmin'>
      <Viewalumniconadmin />
    </Route>
    <Route path='/viewresultadmin'>
      <Viewresultadmin />
    </Route>
    <Route path='/viewexamautomationadmin'>
      <Viewexamautomationadmin />
    </Route> 
    <Route path='/viewseedmadmin'>
      <Viewseedmadmin />
    </Route>
    <Route path='/viewmenteesadmin'>
      <Viewmenteesadmin />
    </Route>
    <Route path='/viewteacherawardadmin'>
      <Viewteacherawardadmin />
    </Route>
    <Route path='/viewconsultancyadmin'>
      <Viewconsultancyadmin />
    </Route>
    <Route path='/viewscholarshipadmin'>
      <Viewscholarshipadmin />
    </Route>
    <Route path='/viewdeptreport'>
      <Viewdeptreport/>
    </Route>





    
<Route path='/viewadmissiondoccommentsd'>
      <Viewadmissiondoccommentsd />
    </Route>
    <Route path='/viewallbookdoccommentsd'>
      <Viewallbookdoccommentsd />
    </Route>
    <Route path='/viewallpatentdocsd'>
      <Viewallpatentdocsd />
    </Route>
    <Route path='/viewallpubdoccomcyd'>
      <Viewallpubdoccomcyd />
    </Route> 
    <Route path='/viewallpubdoccommentsd'>
      <Viewallpubdoccommentsd />
    </Route>
    <Route path='/viewallpubdocsd'>
      <Viewallpubdocsd />
    </Route>
    <Route path='/viewallseminardocsd'>
      <Viewallseminardocsd />
    </Route>
    <Route path='/viewallsemreimbdocsd'>
      <Viewallsemreimbdocsd />
    </Route>
    <Route path='/viewbosdoccommentsd'>
      <Viewbosdoccommentsd />
    </Route>
    <Route path='/viewcareerdoccommentsd'>
      <Viewcareerdoccommentsd />
    </Route>
    <Route path='/viewcbcsdocd'>
      <Viewcbcsdocd />
    </Route>

    <Route path='/viewcollabdoccommentsd'>
      <Viewcollabdoccommentsd />
    </Route>
    <Route path='/viewemployabilitydocsd'>
      <Viewemployabilitydocsd />
    </Route>
    <Route path='/vieweventdoccommentsd'>
      <Vieweventdoccommentsd />
    </Route>
    <Route path='/viewexplearningdocsd'>
      <Viewexplearningdocsd />
    </Route>
    <Route path='/viewexplearningprojdocd'>
      <Viewexplearningprojdocd />
    </Route>
    <Route path='/viewictdoccommentsd'>
      <Viewictdoccommentsd />
    </Route>
    <Route path='/viewmoudoccommentsd'>
      <Viewmoudoccommentsd />
    </Route>

    <Route path='/viewpassexamdocsd'>
      <Viewpassexamdocsd />
    </Route>
    <Route path='/viewphdguidedoccommentsd'>
      <Viewphdguidedoccommentsd />
    </Route>
    <Route path='/viewplacementdoccommentsd'>
      <Viewplacementdoccommentsd />
    </Route>
    <Route path='/viewprojectyrdocsd'>
      <Viewprojectyrdocsd />
    </Route>
    <Route path='/viewreservecatdoccommentsd'>
      <Viewreservecatdoccommentsd />
    </Route>
    <Route path='/viewscholarshipdoccommentsd'>
      <Viewscholarshipdoccommentsd />
    </Route>
    <Route path='/viewsyllabusrevdocsd'>
      <Viewsyllabusrevdocsd />
    </Route>

    <Route path='/viewteacherawarddoccommentsd'>
      <Viewteacherawarddoccommentsd />
    </Route>
    <Route path='/viewteacherdatadocd'>
      <Viewteacherdatadocd />
    </Route>
    <Route path='/viewteacherfellowdoccommentsd'>
      <Viewteacherfellowdoccommentsd />
    </Route>
    <Route path='/viewsyllabusrevdocsd'>
      <Viewsyllabusrevdocsd />
    </Route>
    <Route path='/viewaddoncdoccommentsd'>
      <Viewaddoncdoccommentsd />
    </Route>







    </Switch>

    

  </div>
  );
}

export default App;
