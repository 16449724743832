import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const unitref=useRef();
    const topicref=useRef();
    const moduleref=useRef();
    const objectiveref=useRef();
    const pedagogyref=useRef();
    const yearref=useRef();
    const noofhoursref=useRef();
    const classdateref=useRef();
    

    const section=global1.section;
    const semester=global1.semester;
    
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const unit=unitref.current.value;
        //const module=moduleref.current.value;
        const classdate=classdateref.current.value;
        const year=yearref.current.value;
        const topic=topicref.current.value;
        const objective=objectiveref.current.value;
        const pedagogy=pedagogyref.current.value;
        const noofhours=noofhoursref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createlessonplan', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                department:department,
                unit:unit,
                module: '',
                topic:topic,
                objective:objective,
                pedagogy:pedagogy,
                noofhours:noofhours,
                course:coursename,
                coursecode:coursecode,
                academicyear:year,
                classdate:classdate,
                section:section,
                semester: semester,
                status1:'Submitted'

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewlessonnew');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add Lesson Plan for {coursename} {coursecode} {section} {semester}</p>
                        <hr />
                       
                            No of hours <br /><br />
                            <input type="number" required style={{width: '100%'}} placeholder="Enter no of hours" id="noofhours" ref={noofhoursref} />
                            
                        <br /><br />

                        <label for="topic">Topic</label>

<textarea id="topic" name="topic" required style={{width: '100%'}}  rows="4" cols="50"  ref={topicref}>
Enter topic here
</textarea>
        <br /><br />

        <label for="objective">Objective</label>

<textarea id="objective" name="objective" required style={{width: '100%'}}  rows="4" cols="50"  ref={objectiveref}>
Enter objective here
</textarea>
        <br /><br />

        <label for="pedagogy">Pedagogy</label>

<textarea id="pedagogy" name="pedagogy" required style={{width: '100%'}}  rows="4" cols="50"  ref={pedagogyref}>
Enter pedagogy here
</textarea>
        <br /><br />

                        Class date <br /><br />

                        <input type="date" required style={{width: '100%'}} placeholder="Enter class date" id="class date" ref={classdateref} />
                            
                            <br /><br />

                            {/* <label htmlFor='topic'>Topic</label>
                    <select id="topic" className="form-control"  ref={topicref}>
                    <option value="Teaching Objective">Teaching Objective</option>
                    <option value="Content">Content</option>
                    <option value="Teaching Pedogogy">Teaching Pedogogy</option>
                    

                    </select>
                    <br /><br /> */}

                    
                            <label htmlFor='unit'>Module / Unit</label>
                    <select id="unit" className="form-control"  ref={unitref}>
                    <option value="Unit 1">Unit 1</option>
                    <option value="Unit 2">Unit 2</option>
                    <option value="Unit 3">Unit 3</option>
                    <option value="Unit 4">Unit 4</option>
                        <option value="Unit 5">Unit 5</option>
                        <option value="Unit 6">Unit 6</option>
                        <option value="Unit 7">Unit 7</option>
                        <option value="Unit 8">Unit 8</option>
                        <option value="Unit 9">Unit 9</option>
                        <option value="Unit 10">Unit 10</option>

                    </select>
                    <br /><br />

                            <label htmlFor='academicyear'>Academic year</label>
                    <select id="academicyear" className="form-control"  ref={yearref}>
                    <option value="2023-24">2023-24</option>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                        <option value="2020-21">2020-21</option>
                        <option value="2021-22">2021-22</option>
                        <option value="2022-23">2022-23</option>
                       
                    </select>
                    <br /><br />

               
                  


                      
                        <button  onClick={searchapi}>Add lesson plan</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;