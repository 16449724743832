import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Spondi';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const degreeref=useRef();
const yopref=useRef();
const marksobtref=useRef();
const univnameref=useRef();
const spclzref=useRef();
const dobref=useRef();
const ageref=useRef();
const dojref=useRef();
const yearsofexpprofref=useRef();
const yearsofexpindref=useRef();
const totalexpref=useRef();
const totalpayref=useRef();
const ifqualpernormsref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const degree=degreeref.current.value;
const yop=yopref.current.value;
const marksobt=marksobtref.current.value;
const univname=univnameref.current.value;
const spclz=spclzref.current.value;
const dob=dobref.current.value;
const age=ageref.current.value;
const doj=dojref.current.value;
const yearsofexpprof=yearsofexpprofref.current.value;
const yearsofexpind=yearsofexpindref.current.value;
const totalexp=totalexpref.current.value;
const totalpay=totalpayref.current.value;
const ifqualpernorms=ifqualpernormsref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createppuprincipalbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                degree:degree,
yop:yop,
marksobt:marksobt,
univname:univname,
spclz:spclz,
dob:dob,
age:age,
doj:doj,
yearsofexpprof:yearsofexpprof,
yearsofexpind:yearsofexpind,
totalexp:totalexp,
totalpay:totalpay,
ifqualpernorms:ifqualpernorms,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnppuprincipal');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  <label htmlFor="degree">Degree</label>

<select id="degree" className="form-control"  ref={degreeref}>
<option value="UG">UG</option>
<option value="PG">PG</option>
<option value="PHD">PHD</option>
<option value="Additional">Additional</option>
</select>
<br /><br />

Enter Years of Passing<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Years of Passing" id="yop" ref={yopref} />
<br /><br />

Enter Percentage of marks obtained<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Percentage of marks obtained" id="marksobt" ref={marksobtref} />
<br /><br />

Enter University<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter University" id="univname" ref={univnameref} />
<br /><br />

Enter Specialisation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Specialisation" id="spclz" ref={spclzref} />
<br /><br />

Enter Date of Birth<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Birth" id="dob" ref={dobref} />
<br /><br />

Enter Age<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Age" id="age" ref={ageref} />
<br /><br />

Enter Date of Joining<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Joining" id="doj" ref={dojref} />
<br /><br />

Enter Years of experience as Professor<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Years of experience as Professor" id="yearsofexpprof" ref={yearsofexpprofref} />
<br /><br />

Enter Years of experience in Industry<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Years of experience in Industry" id="yearsofexpind" ref={yearsofexpindref} />
<br /><br />

Enter Total years of Experience<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Total years of Experience" id="totalexp" ref={totalexpref} />
<br /><br />

Enter Total pay<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Total pay" id="totalpay" ref={totalpayref} />
<br /><br />

<label htmlFor="ifqualpernorms">Whether the Director/ Dean/ Principal is qualifed as per UGCI/MCU/AICTE/DCU/VCU/NCTE etc norms</label>

<select id="ifqualpernorms" className="form-control"  ref={ifqualpernormsref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;