import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const projectref=useRef();
    const durationref=useRef();
    const typeref=useRef();
    const agencyref=useRef();
    const yopref=useRef();
    const fundsref=useRef();
    const facultyref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const projectid=global1.projectid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       projectref.current.value=global1.project;
       agencyref.current.value=global1.agency;
       typeref.current.value=global1.type;
       durationref.current.value=global1.duration;
       fundsref.current.value=global1.funds;
       yopref.current.value=global1.yop;
       facultyref.current.value=global1.faculty;

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const project=projectref.current.value;
        const agency=agencyref.current.value;
        const type=typeref.current.value;
        const funds=fundsref.current.value;
        const yop=yopref.current.value;
        const duration=durationref.current.value;
        const faculty=facultyref.current.value;
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateprojectbyfaculty', {
            params: {
                id: projectid,
                user: user,
                token: token,
                colid: colid,
                name: faculty,
                project: project,
                duration:duration,
                type:type,
                agency: agency,
                funds:funds,
                yop:yop,
                status1:'Submitted',
                comments:'',
                department:department

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewprojects');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit project</p>
                        <hr />
                       
                        <input type="text" required style={{width: '100%'}} placeholder="Enter faculty" id="faculty" ref={facultyref} />
                            
                        <br /><br />
                            
                            <input type="text" required style={{width: '100%'}} placeholder="Enter project" id="project" ref={projectref} />
                            
                        <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter duration" id="duration" ref={durationref} />
                            
                            <br /><br />

                            <input type="number" required style={{width: '100%'}} placeholder="Enter funds sanctioned" id="funds" ref={fundsref} />
                            
                            <br /><br />
    

                        <input type="text" required style={{width: '100%'}} placeholder="Enter agency" id="agency" ref={agencyref} />
                            
                            <br /><br />

                            <label htmlFor='academicyear'>Year</label>
                    <select id="academicyear" className="form-control"  ref={yopref}>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                        <option value="2020-21">2020-21</option>
                        <option value="2021-22">2021-22</option>
                        <option value="2022-23">2022-23</option>
                        <option value="2023-24">2023-24</option>
                       
                    </select>
                    <br /><br />

                    <label htmlFor='type'>Type</label>
                    <select id="type" className="form-control"  ref={typeref}>
                    <option value="Government">Government</option>
                    <option value="Non Government">Non Government</option>
                    
                       
                    </select>
                    <br /><br />
                  


                      
                        <button  onClick={searchapi}>Update project</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;