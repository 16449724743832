import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />

            <Link to='/viewnpnirfadmin'> NIRF ranking</Link>
<Link to='/viewnpnbanewadmin'> NBA and other accreditation</Link>
<Link to='/viewnpadmnewadmin'> Admission details</Link>

<Link to='/viewnrmsucoldetaadmin'> College Deatils admin</Link>
<Link to='/viewnrmsunirfadmin'> NIRF/NAAC admin</Link>
<Link to='/viewnrmsutrustdataadmin'> Trust Details</Link>

            
        
            {/* <Link to='/viewnrapplicationadmin'> rapplication</Link> */}
            {/* <Link to='/viewnainstadmin'> Institution details</Link> */}
            {/* <Link to='/viewnpaunewprincipaladmin'> paunewprincipal</Link>
<Link to='/viewnpaucourseadmin'> paucourse</Link>
<Link to='/viewnpaucrsedetailsadmin'> paucrsedetails</Link>
<Link to='/viewnpauaddcrseadmin'> pauaddcrse</Link>
<Link to='/viewnpaunstuddetailsadmin'> paunstuddetails</Link>
<Link to='/viewnpaunewfacultydetailsadmin'> paunewfacultydetails</Link>
<Link to='/viewnpaunewfeduqualifadmin'> paunewfeduqualif</Link>
<Link to='/viewnpauconfaccourseadmin'> pauconfaccourse</Link>
<Link to='/viewnpauconfacmemtechadmin'> pauconfacmemtech</Link>
<Link to='/viewnpauspacereqadmin'> pauspacereq</Link>
<Link to='/viewnpaunequipadmin'> paunequip</Link>
<Link to='/viewnpaunlibjournaladmin'> paunlibjournal</Link>
<Link to='/viewnpaunnoofdrawhalladmin'> paunnoofdrawhall</Link>
<Link to='/viewnpaunewlanddetailsadmin'> paunewlanddetails</Link>
<Link to='/viewnpaunewfaddqualifadmin'> paunewfaddqualif</Link>
<Link to='/viewnpaunewfacdexpadmin'> paunewfacdexp</Link>
<Link to='/viewnpaunewfindexpadmin'> paunewfindexp</Link> */}

<Link to='/viewnpaunewidetailsadmin'> 1. College Details</Link>
<Link to='/viewnpauidetailscadmin'> 1. College Details(Extended)</Link>
<Link to='/viewnpautrustadmin'> 2. Trust and Society Details</Link>
<Link to='/viewnpaunewprincipaladmin'> 3. Details of Principal</Link>
<Link to='/viewnpaungovcounciladmin'> 4. Details of Governing Council</Link>
<Link to='/viewnpaunboardadmin'> 5. Planning and Monitoring Board Member</Link>
<Link to='/viewnpauragcomtadmin'> 6(i). Anti Ragging Committee</Link>
<Link to='/viewnpauragsquadadmin'> 6(ii). Anti Ragging Squad</Link>
<Link to='/viewnpauwelfarecomtadmin'> 7(i). Discipline and Welfare Committee</Link>
<Link to='/viewnpauredressaladmin'> 7(ii).Complaints and Redressal Committee</Link>
<Link to='/viewnpaubankacntsadmin'> 8(i).Savings Bank/Current Accounts</Link>
<Link to='/viewnpaudepositadmin'> 8(ii).Long term Deposits</Link>
<Link to='/viewnpauendowmentadmin'> 8(iii).Endowment</Link>
<Link to='/viewnpaunewlanddetailsadmin'> 9. Details of Land Availability</Link>
<Link to='/viewnpaucourseadmin'> 10(i). Details of Existing Affiliated Courses</Link>
<Link to='/viewnpaucrsedetailsadmin'> 10(ii). Details of the Courses approval is sought for current year</Link>
<Link to='/viewnpauaddcrseadmin'> 11. Additional Courses</Link>
<Link to='/viewnpaunstuddetailsadmin'> 12(i). Details of Students presently stydying in all years</Link>
<Link to='/viewnpaunewfacultydetailsadmin'> 13(i). Faculty Details</Link>
<Link to='/viewnpaunewfaddqualifadmin'> 13(i). Faculty Additional Qualification</Link>
<Link to='/viewnpaunewfacdexpadmin'> 13(i). Faculty Experience</Link>
<Link to='/viewnpaunewfindexpadmin'> 13(i). Faculty Industrial Experience</Link>
<Link to='/viewnpaunewfeduqualifadmin'> 13(i). Faculty Educational Qualification</Link>
<Link to='/viewnpaunewfcoeexpadmin'> 13(i). COE Experience</Link>
<Link to='/viewnpauconfacsheadmin'> 14(i). Faculty Details for Science,Humanities & General Engineering</Link>
<Link to='/viewnpauconfaccourseadmin'> 14(ii). Course Details except M.E/M.Tech</Link>
<Link to='/viewnpauconfacmemtechadmin'> 14(iii).Faculty Details for all courses except M.E/M.Tech</Link>
<Link to='/viewnpaunontstaffadmin'> 15. Non-Teaching Staff</Link>
<Link to='/viewnpauspacereqadmin'> 16(i). Space Requirements</Link>
<Link to='/viewnpaunequipadmin'> 16(ii).Equipments</Link>
<Link to='/viewnpauccomfareaadmin'> 17(i). Central Computing Facility Area</Link>
<Link to='/viewnpauntermlanwanadmin'> 17(ii). Terminals & LAN WAN connections</Link>
<Link to='/viewnpausoftwareadmin'> 17(iii). Softwares</Link>
<Link to='/viewnpaunetworkadmin'> 17(iv). Network Connectivity</Link>
<Link to='/viewnpaulibareaadmin'> 18(i). Library Area</Link>
<Link to='/viewnpaunlibbooksadmin'> 18(ii). Library Books</Link>
<Link to='/viewnpaunlibjournaladmin'> 18(iii). Library Journals</Link>
<Link to='/viewnpauclassblockadmin'> 19(i). Classrooms Availability</Link>
<Link to='/viewnpauclassnewadmin'> 19(i). Classrooms Availability(Extended)</Link>
<Link to='/viewnpaunnoofdrawhalladmin'> 20. Drawing Halls/Conference Halls</Link>
<Link to='/viewnpauadminareaadmin'> 21(i). Administrative Area</Link>
<Link to='/viewnpaunamenadmin'> 21(ii). Amenities</Link>
<Link to='/viewnpaubhosteladmin'> 22(i). Details of Boys Hostel</Link>
<Link to='/viewnpaughosteladmin'> 22(iii). Details of Girls Hostel</Link>
<Link to='/viewnpaunotherbuildareaadmin'> 22(v). Other Building Areas</Link>
<Link to='/viewnpauphyeduadmin'> 23. Physical Education</Link>
<Link to='/viewnpautpcadmin'> 24(i). Training and Placement Cell-Details of Staff</Link>
<Link to='/viewnpaufacilityavailadmin'> 24(ii). Training and Placement Cell-Available Facilities </Link>
<Link to='/viewnpaualumniadmin'> 25. Alumni Association</Link>
<Link to='/viewnpaunhealthadmin'> 26(i). Health Centres</Link>
<Link to='/viewnpauothamenadmin'> 26(ii). Other Amenities</Link>
<Link to='/viewnpauregrecadmin'> 27. Registers and Records</Link>
<Link to='/viewnpaucertificatesadmin'> 28. Certificates</Link>
<Link to='/viewnpaunprocfeeadmin'> 29. Inspection Processing Fee</Link>
<Link to='/viewnpauprcplendrsmntadmin'> 30. Endorsement of the Principal</Link>
<Link to='/viewnpaumngdeclarationadmin'> 31. Declaration by the Management</Link>



<br /><br /><br /><br /><br /><br />
      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;