import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />

            <Link to='/viewnpnirfadmin'> NIRF ranking</Link>
<Link to='/viewnpnbanewadmin'> NBA and other accreditation</Link>
<Link to='/viewnpadmnewadmin'> Admission details</Link>
            
        
            {/* <Link to='/viewnrapplicationadmin'> rapplication</Link> */}
            {/* <Link to='/viewnainstadmin'> Institution details</Link> */}
            {/* <Link to='/viewnpaunewprincipaladmin'> paunewprincipal</Link>
<Link to='/viewnpaucourseadmin'> paucourse</Link>
<Link to='/viewnpaucrsedetailsadmin'> paucrsedetails</Link>
<Link to='/viewnpauaddcrseadmin'> pauaddcrse</Link>
<Link to='/viewnpaunstuddetailsadmin'> paunstuddetails</Link>
<Link to='/viewnpaunewfacultydetailsadmin'> paunewfacultydetails</Link>
<Link to='/viewnpaunewfeduqualifadmin'> paunewfeduqualif</Link>
<Link to='/viewnpauconfaccourseadmin'> pauconfaccourse</Link>
<Link to='/viewnpauconfacmemtechadmin'> pauconfacmemtech</Link>
<Link to='/viewnpauspacereqadmin'> pauspacereq</Link>
<Link to='/viewnpaunequipadmin'> paunequip</Link>
<Link to='/viewnpaunlibjournaladmin'> paunlibjournal</Link>
<Link to='/viewnpaunnoofdrawhalladmin'> paunnoofdrawhall</Link>
<Link to='/viewnpaunewlanddetailsadmin'> paunewlanddetails</Link>
<Link to='/viewnpaunewfaddqualifadmin'> paunewfaddqualif</Link>
<Link to='/viewnpaunewfacdexpadmin'> paunewfacdexp</Link>
<Link to='/viewnpaunewfindexpadmin'> paunewfindexp</Link> */}

<Link to='/viewnInstitutionsadmin'> Institutions</Link>
<Link to='/viewnaffproglistadmin'> Programs offered</Link>
<Link to='/viewnaffcolprogadmin'> Affiliated college program mapping</Link>
<Link to='/viewastudcont'> Student details entered</Link>
<Link to='/viewnmsusancseatnadmin'> Affiliated college programwise sanctioned seat</Link>







<br /><br /><br /><br /><br /><br />
      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;