import React, { useState, useContext, useEffect, useRef } from "react";
import Accordion from 'react-bootstrap/Accordion';
import {Link} from 'react-router-dom';

import global1 from '../../pages/global1';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function Sidecr1() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    const changeHandler = () => {
        const instype=global1.instype;
        //alert(instype);
        if(instype=='Affiliated') {
            return (
                <>
             
             
        <Link to='/viewaffiliatedmenu'> Affiliated Jan 2023 for SSR new</Link>
        
        <Link to='/viewaffiliatedmetric'> NAAC Affiliated (AQAR 2020-21)</Link>
        <Link to='/viewaffiliatedmetricnew'> NAAC Affiliated New (AQAR)</Link>

        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
        
        <Link to='/viewnbaug'> NBA Tier 1 UG Second Cycle</Link>
        <Link to='/viewnbadiploma'> NBA Diploma</Link>
        <Link to='/viewnbamca'> NBA MCA</Link>
       
                </>
            )

        } else if(instype=='Legal') {
            return (
                <>
         <Link to='/viewlegaluniversitymenu'> Legal University metrics</Link>
        
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
        
       
          
                </>
            )

        } else if(instype=='Autonomous') {
            return (
                <>
         <Link to='/viewnewunivmenufac'> NAAC Revised University / Autonomous</Link>
        <Link to='/viewautonomousmenu'> NAAC Autonomous New framework 2023</Link>
        
        <Link to='/viewuniversitymetric'> NAAC University / Autonomous (SSR / AQAR 2020-21)</Link>
        
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
        
        <Link to='/viewnbaug'> NBA Tier 1 UG Second Cycle</Link>
        <Link to='/viewnbadiploma'> NBA Diploma</Link>
        <Link to='/viewnbamca'> NBA MCA</Link>
          
                </>
            )
  
        } else if(instype=='University') {
          return (
              <>
              <Link to='/viewnewunivmenufac'> NAAC Revised University / Autonomous</Link>
      <Link to='/viewuniversitymetricnew'> NAAC University New framework 2023 SSR</Link>
        
        <Link to='/viewuniversitymetric'> NAAC University / Autonomous (AQAR 2020-21)</Link>
        
        <Link to='/viewnallcourses'>Additional Integrated metrics </Link>
        <Link to='/viewnexplearning'>NAAC Feb 2023 modified metrics</Link>
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>

        <Link to='/viewnbaug'> NBA Tier 1 UG Second Cycle</Link>
        <Link to='/viewnbadiploma'> NBA Diploma</Link>
        <Link to='/viewnbamca'> NBA MCA</Link>
        
              </>
          )
            
      } else if(instype=='HealthUniversity') {
        return (
            <>
            <Link to='/viewnewunivmenufac'> NAAC Revised University / Autonomous</Link>
       <Link to='/viewuniversitymetricnew'> NAAC University New framework 2023</Link>
        
        <Link to='/viewuniversitymetric'> NAAC University / Autonomous (SSR / AQAR 2020-21)</Link>
       
        <Link to='/viewnallcourses'>Additional Integrated metrics </Link>
        
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
        <Link to='/viewnnursing812'> Nursing additional documents</Link>
        <Link to='/viewhumetric'> Health University additional metrics</Link>
        
            </>
        )
          
    } else if(instype=='Dual') {
        return (
            <>
        <Link to='/viewuniversitymetricnew'> NAAC University New framework 2023</Link>
        
        <Link to='/viewuniversitymetric'> NAAC University / Autonomous (SSR / AQAR 2020-21)</Link>
        
        <Link to='/viewnallcourses'>Additional Integrated metrics </Link>
        <Link to='/viewnexplearning'>NAAC Feb 2023 modified metrics</Link>
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
    
        <Link to='/viewnbaug'> NBA Tier 1 UG Second Cycle</Link>
        <Link to='/viewnbadiploma'> NBA Diploma</Link>
        <Link to='/viewnbamca'> NBA MCA</Link>
            </>
        )
          
    } else {
          return (
              <>
            <Link to='/viewnewunivmenufac'> NAAC Revised University / Autonomous</Link>
            <Link to='/viewuniversitymetricnew'> NAAC University New framework 2023</Link>
        <Link to='/viewlegaluniversitymenu'> Legal University metrics</Link>
        <Link to='/viewautonomousmenu'> NAAC Autonomous New framework 2023</Link>
        <Link to='/viewaffiliatedmenu'> Affiliated Jan 2023</Link>
        <Link to='/viewuniversitymetric'> NAAC University / Autonomous (SSR / AQAR 2020-21)</Link>
        <Link to='/viewaffiliatedmetric'> NAAC Affiliated (SSR / AQAR 2020-21)</Link>
        <Link to='/viewaffiliatedmetricnew'> NAAC Affiliated New (SSR) June 2022 Onwards</Link>
        <Link to='/viewnallcourses'>Additional Integrated metrics </Link>
        <Link to='/viewnexplearning'>NAAC Feb 2023 modified metrics</Link>
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
        <Link to='/viewnnursing812'> Nursing additional documents</Link>
        <Link to='/viewhumetric'> Health University additional metrics</Link>
        <Link to='/viewnbaug'> NBA Tier 1 UG Second Cycle</Link>
        <Link to='/viewnbadiploma'> NBA Diploma</Link>
        <Link to='/viewnbamca'> NBA MCA</Link>
              </>
          )
  
      }
      };

    



    return (
        // <div></div>
        <div className={classes1.sidebar}>
        <div className={classes1.sidebarinside}>   

        <div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />

            <Accordion flush>
            <Accordion.Item eventKey="5">
        <Accordion.Header>Dashboard</Accordion.Header>
        <Accordion.Body>
        <Link to='/dasherp1'> KPI Dashboard</Link>
<Link to='/dasherplms'> LMS Dashboard</Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Personal details</Accordion.Header>
        <Accordion.Body>
        <Link to='/viewnprojects'> Funded projects</Link>
<Link to='/viewnpublications'> Publications</Link>
<Link to='/viewnseminar'> Seminars participated</Link>
<Link to='/viewnbook'> Books and chapters published</Link>
<Link to='/viewnconsultancy'> Consultancy activities</Link>
<Link to='/viewncertificates'> Certificates, ID and address proof</Link>
<Link to='/viewnpatents'> Patents</Link>
<Link to='/viewnemploymentall'> Previous employment details</Link>
<Link to='/viewncas11'> Number of classes taught CAS</Link>
<Link to='/viewncas12'> Type of resposibility CAS</Link>
<Link to='/viewncas22'> Type of publication CAS</Link>
<Link to='/viewncas23'> Type of ICT CAS</Link>
<Link to='/viewncas241'> Type of Research guidance CAS</Link>
<Link to='/viewncas242'> Type of Research Projects Completed CAS</Link>
<Link to='/viewncas243'> Type of Research Projects Ongoing CAS</Link>
<Link to='/viewncas251'> Patents CAS</Link>
<Link to='/viewncas253'> Awards or Fellowship CAS</Link>
<Link to='/viewncas26'> Participation CAS</Link>
<Link to='/viewncas252'> Type of Policy Document CAS</Link>

<Link to='/updatepassword'>Update profile</Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Accreditation and Ranking</Accordion.Header>
        <Accordion.Body>
        <Link to='/viewdoclistuniv'>Document list SSR university</Link>
        {changeHandler()}
        <Link to='/viewnevent'> All events </Link>
        <Link to='/viewaigenmenuf'> Additional generative AI </Link>
        <Link to='/viewnpstatusnew'> Project status </Link>
        <Link to='/viewnsopadmin'> Sample documents </Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Productivity</Accordion.Header>
        <Accordion.Body>
        <Link to='/viewai1'>AI Content generator </Link>
        <Link to='/viewndepmeetingsall'>Project Tasks </Link>
        <Link to='/viewnseedmoney'>Apply for approval</Link>
        <Link to='/viewncommittees'>Committees and meeting minutes </Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>LMS and ICT </Accordion.Header>
        <Accordion.Body>
        <Link to='/selectperiodlms'>Learning Management System (LMS)</Link>
        <Link to='/viewnopencourse'>Open courses and modules</Link>
        <Link to='/viewntrialbalance'>Finance</Link>
        <Link to='/dashmydetails'> Manage leave and balance</Link>
        <Link to='/assetlist'> Manage assets</Link>
        <Link to='/viewncocal'> CO Attainment Upload</Link>
        <Link to='/viewncompanies'>Placement and higher education </Link>
        <Link to='/viewnnqualification'>Task and HR metrics</Link>
        <Link to='/viewnmkustud'>Examination module</Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Affiliation and institution</Accordion.Header>
        <Accordion.Body>
        <Link to='/viewnInstitutions'>Affiliation management</Link>
        <Link to='/viewnrmsucoldeta'> Institution Details (format 1)</Link>
        <Link to='/viewnrapplication'>Information related to Nursing </Link>
        <Link to='/viewnainst'>Information related to Engg </Link>
        <Link to='/viewnppuclgdetails'>Institutional information (format 2)</Link>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

          

           
            
    
         
        
        
        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
        {/* <Link to='/viewuniversitymetricnew'> NAAC University New framework 2023</Link>
        <Link to='/viewlegaluniversitymenu'> Legal University metrics</Link>
        <Link to='/viewautonomousmenu'> NAAC Autonomous New framework 2023</Link>
        <Link to='/viewaffiliatedmenu'> Affiliated Jan 2023</Link>
        <Link to='/viewuniversitymetric'> NAAC University / Autonomous (SSR / AQAR 2020-21)</Link>
        <Link to='/viewaffiliatedmetric'> NAAC Affiliated (SSR / AQAR 2020-21)</Link>
        <Link to='/viewaffiliatedmetricnew'> NAAC Affiliated New (SSR) June 2022 Onwards</Link>
        <Link to='/viewnallcourses'>Additional Integrated metrics </Link>
        <Link to='/viewnexplearning'>NAAC Feb 2023 modified metrics</Link>
        
        <Link to='/viewhnewprog'> Additional documents </Link>
        <Link to='/viewnacademic'> Other documents </Link>
        <Link to='/viewnnursing812'> Nursing additional documents</Link>
        <Link to='/viewhumetric'> Health University additional metrics</Link>
        <Link to='/viewnbaug'> NBA Tier 1 UG Second Cycle</Link>
        <Link to='/viewnbadiploma'> NBA Diploma</Link>
        <Link to='/viewnbamca'> NBA MCA</Link> */}


        {/* <Link to='/workload'> Create Class</Link>    
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link> */}
     

        
        </div>      
        <br /><br /> 

</div>
    );

}

export default Sidecr1;