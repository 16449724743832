import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sproject';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const studentref=useRef();
const genderref=useRef();
const categoryref=useRef();
const domicileref=useRef();
const nationalityref=useRef();
const emailref=useRef();
const levelref=useRef();
const disciplineref=useRef();
const departmentref=useRef();
const courseref=useRef();
const regnoref=useRef();
const mobileref=useRef();
const joinyearref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const student=studentref.current.value;
const gender=genderref.current.value;
const category=categoryref.current.value;
const domicile=domicileref.current.value;
const nationality=nationalityref.current.value;
const email=emailref.current.value;
const level=levelref.current.value;
const discipline=disciplineref.current.value;
const department=departmentref.current.value;
const course=courseref.current.value;
const regno=regnoref.current.value;
const mobile=mobileref.current.value;
const joinyear=joinyearref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createsssstudbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                student:student,
gender:gender,
category:category,
domicile:domicile,
nationality:nationality,
email:email,
level:level,
discipline:discipline,
department:department,
course:course,
regno:regno,
mobile:mobile,
joinyear:joinyear,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnsssstud');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name" id="student" ref={studentref} />
<br /><br />

Enter Gender<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter Gender" id="gender" ref={genderref} />
<br /><br />

Enter Category<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter Category" id="category" ref={categoryref} />
<br /><br />

Enter Original state of domicile<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Original state of domicile" id="domicile" ref={domicileref} />
<br /><br />

Enter Nationality<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Nationality" id="nationality" ref={nationalityref} />
<br /><br />

Enter Email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email" id="email" ref={emailref} />
<br /><br />

Enter Level of course<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter Level of course" id="level" ref={levelref} />
<br /><br />

Enter Discipline<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter Discipline" id="discipline" ref={disciplineref} />
<br /><br />

Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

Enter Course name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Course name" id="course" ref={courseref} />
<br /><br />

Enter Registration no or roll no<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Registration no or roll no" id="regno" ref={regnoref} />
<br /><br />

Enter Mobile<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mobile" id="mobile" ref={mobileref} />
<br /><br />

Enter Joining year<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Joining year" id="joinyear" ref={joinyearref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;