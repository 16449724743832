import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Mexam';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const collegecoderef=useRef();
const collegeref=useRef();
const coldistrictref=useRef();
const coltyperef=useRef();
const admissionyearref=useRef();
const photoref=useRef();
const emailref=useRef();
const regnoref=useRef();
const rollnoref=useRef();
const studentref=useRef();
const dateofbirthref=useRef();
const genderref=useRef();
const religionref=useRef();
const categoryref=useRef();
const casteref=useRef();
const stateref=useRef();
const nationalityref=useRef();
const mobileref=useRef();
const aadharref=useRef();
const bloodref=useRef();
const departmentref=useRef();
const programcoderef=useRef();
const programref=useRef();
const durationref=useRef();
const streamref=useRef();
const mediumref=useRef();
const shiftref=useRef();
const sectionref=useRef();
const admissiondateref=useRef();
const joiningdateref=useRef();
const counsellingref=useRef();
const studstatusref=useRef();
const admmoderef=useRef();
const lateralref=useRef();
const fatherref=useRef();
const fincomeref=useRef();
const foccuref=useRef();
const motherref=useRef();
const mincomeref=useRef();
const moccuref=useRef();
const guardianref=useRef();
const pmobileref=useRef();
const orphanref=useRef();
const annualincref=useRef();
const cardtyperef=useRef();
const cardnoref=useRef();
const diffableref=useRef();
const diffabtyperef=useRef();
const diffpercentref=useRef();
const udidnoref=useRef();
const firstgradref=useRef();
const spcatref=useRef();
const emisnoref=useRef();
const hscboardref=useRef();
const hscregnoref=useRef();
const languageref=useRef();
const hscmarksref=useRef();
const hscscholarshipref=useRef();
const lastscholref=useRef();
const schoolref=useRef();
const govtschoolref=useRef();
const tamilref=useRef();
const addressref=useRef();
const villageref=useRef();
const studdistrictref=useRef();
const pinref=useRef();
const prevprogref=useRef();
const lastyearref=useRef();
const prevregnref=useRef();
const prevyearref=useRef();
const prevpercentref=useRef();
const previnscoderef=useRef();
const previnsnameref=useRef();
const qualmoderef=useRef();
const hostelref=useRef();
const hjoindateref=useRef();
const hleavedateref=useRef();
const hosteltyperef=useRef();
const bankref=useRef();
const accountref=useRef();
const accnameref=useRef();
const acctyperef=useRef();
const ifscref=useRef();
const branchref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       collegecoderef.current.value=global1.collegecode;
collegeref.current.value=global1.college;
coldistrictref.current.value=global1.coldistrict;
coltyperef.current.value=global1.coltype;
admissionyearref.current.value=global1.admissionyear;
photoref.current.value=global1.photo;
emailref.current.value=global1.email;
regnoref.current.value=global1.regno;
rollnoref.current.value=global1.rollno;
studentref.current.value=global1.student;
dateofbirthref.current.value=global1.dateofbirth;
genderref.current.value=global1.gender;
religionref.current.value=global1.religion;
categoryref.current.value=global1.category;
casteref.current.value=global1.caste;
stateref.current.value=global1.state;
nationalityref.current.value=global1.nationality;
mobileref.current.value=global1.mobile;
aadharref.current.value=global1.aadhar;
bloodref.current.value=global1.blood;
departmentref.current.value=global1.department;
programcoderef.current.value=global1.programcode;
programref.current.value=global1.program;
durationref.current.value=global1.duration;
streamref.current.value=global1.stream;
mediumref.current.value=global1.medium;
shiftref.current.value=global1.shift;
sectionref.current.value=global1.section;
admissiondateref.current.value=global1.admissiondate;
joiningdateref.current.value=global1.joiningdate;
counsellingref.current.value=global1.counselling;
studstatusref.current.value=global1.studstatus;
admmoderef.current.value=global1.admmode;
lateralref.current.value=global1.lateral;
fatherref.current.value=global1.father;
fincomeref.current.value=global1.fincome;
foccuref.current.value=global1.foccu;
motherref.current.value=global1.mother;
mincomeref.current.value=global1.mincome;
moccuref.current.value=global1.moccu;
guardianref.current.value=global1.guardian;
pmobileref.current.value=global1.pmobile;
orphanref.current.value=global1.orphan;
annualincref.current.value=global1.annualinc;
cardtyperef.current.value=global1.cardtype;
cardnoref.current.value=global1.cardno;
diffableref.current.value=global1.diffable;
diffabtyperef.current.value=global1.diffabtype;
diffpercentref.current.value=global1.diffpercent;
udidnoref.current.value=global1.udidno;
firstgradref.current.value=global1.firstgrad;
spcatref.current.value=global1.spcat;
emisnoref.current.value=global1.emisno;
hscboardref.current.value=global1.hscboard;
hscregnoref.current.value=global1.hscregno;
languageref.current.value=global1.language;
hscmarksref.current.value=global1.hscmarks;
hscscholarshipref.current.value=global1.hscscholarship;
lastscholref.current.value=global1.lastschol;
schoolref.current.value=global1.school;
govtschoolref.current.value=global1.govtschool;
tamilref.current.value=global1.tamil;
addressref.current.value=global1.address;
villageref.current.value=global1.village;
studdistrictref.current.value=global1.studdistrict;
pinref.current.value=global1.pin;
prevprogref.current.value=global1.prevprog;
lastyearref.current.value=global1.lastyear;
prevregnref.current.value=global1.prevregn;
prevyearref.current.value=global1.prevyear;
prevpercentref.current.value=global1.prevpercent;
previnscoderef.current.value=global1.previnscode;
previnsnameref.current.value=global1.previnsname;
qualmoderef.current.value=global1.qualmode;
hostelref.current.value=global1.hostel;
hjoindateref.current.value=global1.hjoindate;
hleavedateref.current.value=global1.hleavedate;
hosteltyperef.current.value=global1.hosteltype;
bankref.current.value=global1.bank;
accountref.current.value=global1.account;
accnameref.current.value=global1.accname;
acctyperef.current.value=global1.acctype;
ifscref.current.value=global1.ifsc;
branchref.current.value=global1.branch;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const collegecode=collegecoderef.current.value;
const college=collegeref.current.value;
const coldistrict=coldistrictref.current.value;
const coltype=coltyperef.current.value;
const admissionyear=admissionyearref.current.value;
const photo=photoref.current.value;
const email=emailref.current.value;
const regno=regnoref.current.value;
const rollno=rollnoref.current.value;
const student=studentref.current.value;
const dateofbirth=dateofbirthref.current.value;
const gender=genderref.current.value;
const religion=religionref.current.value;
const category=categoryref.current.value;
const caste=casteref.current.value;
const state=stateref.current.value;
const nationality=nationalityref.current.value;
const mobile=mobileref.current.value;
const aadhar=aadharref.current.value;
const blood=bloodref.current.value;
const department=departmentref.current.value;
const programcode=programcoderef.current.value;
const program=programref.current.value;
const duration=durationref.current.value;
const stream=streamref.current.value;
const medium=mediumref.current.value;
const shift=shiftref.current.value;
const section=sectionref.current.value;
const admissiondate=admissiondateref.current.value;
const joiningdate=joiningdateref.current.value;
const counselling=counsellingref.current.value;
const studstatus=studstatusref.current.value;
const admmode=admmoderef.current.value;
const lateral=lateralref.current.value;
const father=fatherref.current.value;
const fincome=fincomeref.current.value;
const foccu=foccuref.current.value;
const mother=motherref.current.value;
const mincome=mincomeref.current.value;
const moccu=moccuref.current.value;
const guardian=guardianref.current.value;
const pmobile=pmobileref.current.value;
const orphan=orphanref.current.value;
const annualinc=annualincref.current.value;
const cardtype=cardtyperef.current.value;
const cardno=cardnoref.current.value;
const diffable=diffableref.current.value;
const diffabtype=diffabtyperef.current.value;
const diffpercent=diffpercentref.current.value;
const udidno=udidnoref.current.value;
const firstgrad=firstgradref.current.value;
const spcat=spcatref.current.value;
const emisno=emisnoref.current.value;
const hscboard=hscboardref.current.value;
const hscregno=hscregnoref.current.value;
const language=languageref.current.value;
const hscmarks=hscmarksref.current.value;
const hscscholarship=hscscholarshipref.current.value;
const lastschol=lastscholref.current.value;
const school=schoolref.current.value;
const govtschool=govtschoolref.current.value;
const tamil=tamilref.current.value;
const address=addressref.current.value;
const village=villageref.current.value;
const studdistrict=studdistrictref.current.value;
const pin=pinref.current.value;
const prevprog=prevprogref.current.value;
const lastyear=lastyearref.current.value;
const prevregn=prevregnref.current.value;
const prevyear=prevyearref.current.value;
const prevpercent=prevpercentref.current.value;
const previnscode=previnscoderef.current.value;
const previnsname=previnsnameref.current.value;
const qualmode=qualmoderef.current.value;
const hostel=hostelref.current.value;
const hjoindate=hjoindateref.current.value;
const hleavedate=hleavedateref.current.value;
const hosteltype=hosteltyperef.current.value;
const bank=bankref.current.value;
const account=accountref.current.value;
const accname=accnameref.current.value;
const acctype=acctyperef.current.value;
const ifsc=ifscref.current.value;
const branch=branchref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateamastudnewbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                collegecode:collegecode,
college:college,
coldistrict:coldistrict,
coltype:coltype,
admissionyear:admissionyear,
photo:photo,
email:email,
regno:regno,
rollno:rollno,
student:student,
dateofbirth:dateofbirth,
gender:gender,
religion:religion,
category:category,
caste:caste,
state:state,
nationality:nationality,
mobile:mobile,
aadhar:aadhar,
blood:blood,
department:department,
programcode:programcode,
program:program,
duration:duration,
stream:stream,
medium:medium,
shift:shift,
section:section,
admissiondate:admissiondate,
joiningdate:joiningdate,
counselling:counselling,
studstatus:studstatus,
admmode:admmode,
lateral:lateral,
father:father,
fincome:fincome,
foccu:foccu,
mother:mother,
mincome:mincome,
moccu:moccu,
guardian:guardian,
pmobile:pmobile,
orphan:orphan,
annualinc:annualinc,
cardtype:cardtype,
cardno:cardno,
diffable:diffable,
diffabtype:diffabtype,
diffpercent:diffpercent,
udidno:udidno,
firstgrad:firstgrad,
spcat:spcat,
emisno:emisno,
hscboard:hscboard,
hscregno:hscregno,
language:language,
hscmarks:hscmarks,
hscscholarship:hscscholarship,
lastschol:lastschol,
school:school,
govtschool:govtschool,
tamil:tamil,
address:address,
village:village,
studdistrict:studdistrict,
pin:pin,
prevprog:prevprog,
lastyear:lastyear,
prevregn:prevregn,
prevyear:prevyear,
prevpercent:prevpercent,
previnscode:previnscode,
previnsname:previnsname,
qualmode:qualmode,
hostel:hostel,
hjoindate:hjoindate,
hleavedate:hleavedate,
hosteltype:hosteltype,
bank:bank,
account:account,
accname:accname,
acctype:acctype,
ifsc:ifsc,
branch:branch,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnamastudnew');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter College Code <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College Code " id="collegecode" ref={collegecoderef} />
<br /><br />

Enter College Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College Name" id="college" ref={collegeref} />
<br /><br />

Enter College  Distict <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter College  Distict " id="coldistrict" ref={coldistrictref} />
<br /><br />

<label htmlFor="coltype">College Type</label>

<select id="coltype" className="form-control"  ref={coltyperef}>
<option value="Govt aided">Govt aided</option>
<option value="Self financed">Self financed</option>
<option value="Govt">Govt</option>
<option value="Autonomous">Autonomous</option>
<option value="Self finance autonomous">Self finance autonomous</option>
</select>
<br /><br />

<label htmlFor="admissionyear">Year of Admission</label>

<select id="admissionyear" className="form-control"  ref={admissionyearref}>
<option value="2022-23">2022-23</option>
<option value="2023-24">2023-24</option>
</select>
<br /><br />

Enter Student Photo<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student Photo" id="photo" ref={photoref} />
<br /><br />

Enter Email addrees<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email addrees" id="email" ref={emailref} />
<br /><br />

Enter Student  Registration Number (given by University)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student  Registration Number (given by University)" id="regno" ref={regnoref} />
<br /><br />

Enter Student Roll No. (given By Collge) <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student Roll No. (given By Collge) " id="rollno" ref={rollnoref} />
<br /><br />

Enter Name of the Student (Capital Letters with initial at the end. For eg. KANNADHASAN D)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of the Student (Capital Letters with initial at the end. For eg. KANNADHASAN D)" id="student" ref={studentref} />
<br /><br />

Enter Date of Birth <br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Birth " id="dateofbirth" ref={dateofbirthref} />
<br /><br />

<label htmlFor="gender">Gender </label>

<select id="gender" className="form-control"  ref={genderref}>
<option value="Male">Male</option>
<option value="Female">Female</option>
<option value="Transgender">Transgender</option>
</select>
<br /><br />

Enter Religion <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Religion " id="religion" ref={religionref} />
<br /><br />

<label htmlFor="category">Category</label>

<select id="category" className="form-control"  ref={categoryref}>
<option value="OC">OC</option>
<option value="BC">BC</option>
<option value="BCM">BCM</option>
<option value="MBC">MBC</option>
<option value="DNT">DNT</option>
<option value="SC">SC</option>
<option value="SCA">SCA</option>
<option value="ST">ST</option>
<option value="EN">EN</option>
</select>
<br /><br />

Enter Caste<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Caste" id="caste" ref={casteref} />
<br /><br />

Enter State <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter State " id="state" ref={stateref} />
<br /><br />

Enter Nationality <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Nationality " id="nationality" ref={nationalityref} />
<br /><br />

Enter Mobile Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mobile Number" id="mobile" ref={mobileref} />
<br /><br />

Enter Aadhar Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Aadhar Number" id="aadhar" ref={aadharref} />
<br /><br />

Enter Blood Group<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Blood Group" id="blood" ref={bloodref} />
<br /><br />

Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

Enter Programme Code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Code" id="programcode" ref={programcoderef} />
<br /><br />

Enter Programme Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Name" id="program" ref={programref} />
<br /><br />

Enter Programme Duration (in years)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Duration (in years)" id="duration" ref={durationref} />
<br /><br />

Enter Programme Stream<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Programme Stream" id="stream" ref={streamref} />
<br /><br />

Enter Medium of Instruction<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Medium of Instruction" id="medium" ref={mediumref} />
<br /><br />

<label htmlFor="shift">Shift</label>

<select id="shift" className="form-control"  ref={shiftref}>
<option value="Shift 1 Day">Shift 1 Day</option>
<option value="Shift 2 Evening">Shift 2 Evening</option>
<option value="No Shift">No Shift</option>
</select>
<br /><br />

<label htmlFor="section">Section</label>

<select id="section" className="form-control"  ref={sectionref}>
<option value="1">1</option>
<option value="2">2</option>
<option value="3">3</option>
<option value="4">4</option>
</select>
<br /><br />

Enter Date of Admission to the programme <br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Admission to the programme " id="admissiondate" ref={admissiondateref} />
<br /><br />

Enter Date of Joining in the programme <br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Joining in the programme " id="joiningdate" ref={joiningdateref} />
<br /><br />

Enter Counselling Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Counselling Number" id="counselling" ref={counsellingref} />
<br /><br />

<label htmlFor="studstatus">Student status</label>

<select id="studstatus" className="form-control"  ref={studstatusref}>
<option value="Active">Active</option>
<option value="Development">Development</option>
<option value="Discontinued">Discontinued</option>
</select>
<br /><br />

<label htmlFor="admmode">Admission mode</label>

<select id="admmode" className="form-control"  ref={admmoderef}>
<option value="Government">Government</option>
<option value="Management">Management</option>
</select>
<br /><br />

<label htmlFor="lateral">Lateral Entry</label>

<select id="lateral" className="form-control"  ref={lateralref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Father Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Father Name" id="father" ref={fatherref} />
<br /><br />

Enter Father Income<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Father Income" id="fincome" ref={fincomeref} />
<br /><br />

Enter Father's Occupation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Father's Occupation" id="foccu" ref={foccuref} />
<br /><br />

Enter Mother Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mother Name" id="mother" ref={motherref} />
<br /><br />

Enter Mother Income<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mother Income" id="mincome" ref={mincomeref} />
<br /><br />

Enter Mother's Occupation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mother's Occupation" id="moccu" ref={moccuref} />
<br /><br />

Enter Guardian Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Guardian Name" id="guardian" ref={guardianref} />
<br /><br />

Enter Parent/Guardian Mobile Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Parent/Guardian Mobile Number" id="pmobile" ref={pmobileref} />
<br /><br />

<label htmlFor="orphan">Orphan</label>

<select id="orphan" className="form-control"  ref={orphanref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Annual Family Income<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Annual Family Income" id="annualinc" ref={annualincref} />
<br /><br />

Enter Family Card Type<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Family Card Type" id="cardtype" ref={cardtyperef} />
<br /><br />

Enter Family Card Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Family Card Number" id="cardno" ref={cardnoref} />
<br /><br />

<label htmlFor="diffable">Differently abled</label>

<select id="diffable" className="form-control"  ref={diffableref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Type of Differently abled (List Encolsed)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Type of Differently abled (List Encolsed)" id="diffabtype" ref={diffabtyperef} />
<br /><br />

Enter Differently Percentage<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Differently Percentage" id="diffpercent" ref={diffpercentref} />
<br /><br />

Enter UDID No (Unique Disability ID)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter UDID No (Unique Disability ID)" id="udidno" ref={udidnoref} />
<br /><br />

<label htmlFor="firstgrad">First Graduate (Y/N)</label>

<select id="firstgrad" className="form-control"  ref={firstgradref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="spcat">Special Categories</label>

<select id="spcat" className="form-control"  ref={spcatref}>
<option value="Sports">Sports</option>
<option value="Ex serviceman">Ex serviceman</option>
</select>
<br /><br />

Enter EMIS No.(Education Management Information System)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter EMIS No.(Education Management Information System)" id="emisno" ref={emisnoref} />
<br /><br />

Enter Board of Hsc Passed<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Board of Hsc Passed" id="hscboard" ref={hscboardref} />
<br /><br />

Enter HSc Registration No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter HSc Registration No." id="hscregno" ref={hscregnoref} />
<br /><br />

Enter Language Studied in Hsc other than English <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Language Studied in Hsc other than English " id="language" ref={languageref} />
<br /><br />

Enter HSc % of Markes <br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter HSc % of Markes " id="hscmarks" ref={hscmarksref} />
<br /><br />

Enter HSc Scholarship Recceived if any<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter HSc Scholarship Recceived if any" id="hscscholarship" ref={hscscholarshipref} />
<br /><br />

Enter Last Scholarship Recceived if any<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Last Scholarship Recceived if any" id="lastschol" ref={lastscholref} />
<br /><br />

Enter Name of the School Studied (HSc)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of the School Studied (HSc)" id="school" ref={schoolref} />
<br /><br />

<label htmlFor="govtschool">6th Std to 12th Std studied at Govt School only (Yes/No)</label>

<select id="govtschool" className="form-control"  ref={govtschoolref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="tamil">6th Std to 12th Std studied in Tamil Medium only (Yes/No)</label>

<select id="tamil" className="form-control"  ref={tamilref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Student Address   Door No.  Street Name <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student Address   Door No.  Street Name " id="address" ref={addressref} />
<br /><br />

Enter Name of the Village/Town/City<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of the Village/Town/City" id="village" ref={villageref} />
<br /><br />

Enter District<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter District" id="studdistrict" ref={studdistrictref} />
<br /><br />

Enter Pincode<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Pincode" id="pin" ref={pinref} />
<br /><br />

Enter Previous Programme name and discipline (For. E g.B.A.)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Previous Programme name and discipline (For. E g.B.A.)" id="prevprog" ref={prevprogref} />
<br /><br />

Enter Last year / semester psaasut <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Last year / semester psaasut " id="lastyear" ref={lastyearref} />
<br /><br />

Enter Previous programme's exam Registratoin Number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Previous programme's exam Registratoin Number" id="prevregn" ref={prevregnref} />
<br /><br />

<label htmlFor="prevyear">Previous programme's passing year</label>

<select id="prevyear" className="form-control"  ref={prevyearref}>
<option value="2022-23">2022-23</option>
<option value="2021-22">2021-22</option>
<option value="2020-21">2020-21</option>
</select>
<br /><br />

Enter Previous programme's percentage of marks <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Previous programme's percentage of marks " id="prevpercent" ref={prevpercentref} />
<br /><br />

Enter Previous instiution code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Previous instiution code" id="previnscode" ref={previnscoderef} />
<br /><br />

Enter Previous instiution name <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Previous instiution name " id="previnsname" ref={previnsnameref} />
<br /><br />

<label htmlFor="qualmode">Last qualification mode of study</label>

<select id="qualmode" className="form-control"  ref={qualmoderef}>
<option value="Regular">Regular</option>
<option value="Part time">Part time</option>
<option value="Distance">Distance</option>
</select>
<br /><br />

<label htmlFor="hostel">Hosteller</label>

<select id="hostel" className="form-control"  ref={hostelref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Date of Joining (Hostel)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Date of Joining (Hostel)" id="hjoindate" ref={hjoindateref} />
<br /><br />

Enter Date of Leaving (Hostel)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Date of Leaving (Hostel)" id="hleavedate" ref={hleavedateref} />
<br /><br />

<label htmlFor="hosteltype">Hostel type</label>

<select id="hosteltype" className="form-control"  ref={hosteltyperef}>
<option value="Free">Free</option>
<option value="Paid">Paid</option>
</select>
<br /><br />

Enter Bank Name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank Name" id="bank" ref={bankref} />
<br /><br />

Enter Bank Account No.<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank Account No." id="account" ref={accountref} />
<br /><br />

Enter Bank Account Name <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank Account Name " id="accname" ref={accnameref} />
<br /><br />

Enter Bank Account Type<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank Account Type" id="acctype" ref={acctyperef} />
<br /><br />

Enter IFSC Code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter IFSC Code" id="ifsc" ref={ifscref} />
<br /><br />

Enter Bank Branch<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank Branch" id="branch" ref={branchref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;