import ep1 from '../api/ep1';
import epsms from '../api/epsms';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';

// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 
import Table from 'react-bootstrap/Table';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';

import Spinner from 'react-bootstrap/Spinner';
import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/Sfebadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

import Button from 'react-bootstrap/Button';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    const threshold=global1.threshold;
    const totalclasses=global1.totalclasses;
    //const name=global1.name;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/studmasteranamoly', {
            params: {
                token: token,
                colid: colid

            }

        });
        
        console.log(response.data.data);
        //localStorage.setItem("attendance" + coursecode1, JSON.stringify(response.data.data.classes));
        setResults(response.data.data.classes);
        $('#example').DataTable(
            {
                pagingType: 'full_numbers',
                  pageLength: 5,
                  processing: true,
                  dom: 'Bfrtip',
                      buttons: ['copy', 'csv', 'print'
                      ]
            }
        );
        setLoading(false);
        //alert(results.length);
       
    };

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteclassenr', {
            params: {
                id: enrolmentid,
                token: token
            }

        });
        setLoading(false);
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("attendance" + coursecode1);
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    const checkattendance = (attendance) => {
        var att=parseFloat(attendance);
        var th1=parseFloat(threshold);
        var tc1=parseFloat(totalclasses);
        var pc1=att/tc1 * 100;
        if(pc1<th1) {
            return <span style={{color: 'red'}}>Not ok</span>;
            
              
        } else {
            return <span style={{color: 'green'}}>Ok</span>;
            
        }

    }

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        getlocal();
        searchApi('');
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    
    const sendsms = async (name, regno, attendance) => {
      
        var res = Math.round(parseFloat(attendance) / parseFloat(totalclasses)* 100);

        //setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/getparentphone', {
            params: {
                regno: regno,
                token: token,
                colid: colid
            }

        });
        //alert(response.data.data.classes[0].phone);

        var phone=response.data.data.classes[0].phone;
        var date1=new Date();
        var month=date1.getMonth() + 1;
        var dt1=date1.getDate() + '/' + month + '/' + date1.getFullYear();
        var smstext='Dear Parent, your ward is having ' + res + '% attendance as on ' + 'today' + '. Please ensure that your ward maintains 75% of attendance to write the final exam - iTOCBM&priority=ndnd&stype=normal';
        var smsurl='http://trans.smsfresh.co/api/sendmsg.php?user=KAHAN&pass=123456&sender=TOCBMM&phone=' + phone + '&text=' + smstext;
        //setLoading(false);
        //history.replace('/viewlibbooks');
        const response1 = await epsms.get('/api/sendmsg.php', {
            params: {
                user: 'KAHAN',
                pass: '123456',
                phone: phone,
                text: smstext
            }

        });
        alert('SMS sent to ' + phone);

        //return smsurl;
        //var win = window.open(smsurl, '_blank');
        //win.focus();
        
       
       
    };


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }

    const getattendancepercentage = (attendance) => {
        var res = Math.round(parseFloat(attendance) / parseFloat(totalclasses)* 100);
        return res;
    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidecolorNavigation />
                    </td>
                    
                    <td> 

                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>Student master data anamoly check</p>
            <hr />
            {loading ? (
                <div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div></div>
            )} 
            
    
        <br />

        <Table striped bordered hover id="example">
{/* <table id="example"> */}
<thead>
<tr>
      <th>Student</th>
      <th>Reg no</th>
      <th>Admission year</th>
      <th>Total entries</th>
  
    </tr>
    </thead>
    <tbody>
    {results.map((meetup3) => {
                return (
                    <tr>
                    <td>
                        {meetup3._id.student}
                    </td>
                    <td>
                        {meetup3._id.regno}
                    </td>
                    <td>
                        {meetup3._id.admissionyear}
                    </td>
                    <td>
                    {meetup3.total_attendance}
                    </td>
                   
                          </tr>

                );
            })}

    </tbody>
    </Table>
        

        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;