import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Aff';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const unividref=useRef();
const programref=useRef();
const programcoderef=useRef();
const yearref=useRef();
const sancref=useRef();
const admittedref=useRef();
const levelref=useRef();

const allprogramsref=useRef();
const allprograms1ref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [faculties, setFaculties] = useState([]);
    const [faculties1, setFaculties1] = useState([]);

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       getproglist();
       getproglist1();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const aff=allprogramsref.current.value;
        const userarray=aff.split('-');
        const program=userarray[0];
        const programcode=userarray[1];

        const aff1=allprograms1ref.current.value;
        const userarray1=aff1.split('-');
        //const program=userarray[0];
        const univid=userarray1[1];

        //const univid=unividref.current.value;
//const program=programref.current.value;
//const programcode=programcoderef.current.value;
const year=yearref.current.value;
const sanc=sancref.current.value;
const admitted=admittedref.current.value;
const level=levelref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createmsusancseatnbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                univid:univid,
program:program,
programcode:programcode,
year:year,
sanc:sanc,
admitted:admitted,
level:level,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnmsusancseatn');
       
    };

    const getproglist = async () => {
        //setLoading(true);
        
        const response = await ep1.get('/api/v1/affproglistdocs', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const getval1=(event)=> {
      
        //alert(event.target.value);
        const aff=allprogramsref.current.value;
          const userarray=aff.split('-');
          const user1=userarray[0];
          const uame1=userarray[1];
          alert('Select program ' + user1 + ' ' + uame1);
      
    }

    const combine=(val1,val2)=> {
      
        return val1 + '-' + val2;
      
    }

    const getproglist1 = async () => {
        //setLoading(true);
        
        const response = await ep1.get('/api/v1/Institutionsdocs', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties1(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const getval12=(event)=> {
      
        //alert(event.target.value);
        const aff1=allprograms1ref.current.value;
          const userarray=aff1.split('-');
          const user1=userarray[0];
          const uame1=userarray[1];
          alert('Select institution ' + user1 + ' instition id ' + uame1);
      
    }

    const combine1=(val1,val2)=> {
      
        return val1 + '-' + val2;
      
    }

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       

            <label htmlFor='allprograms1'>Select institution</label>
                    <select id="allprograms1" className="form-control" onChange={getval12}  ref={allprograms1ref}>
                    
                    <option value="1">Select institution</option>

    {faculties1.map((meetup3) => {
                return (
                    <option value={combine1(meetup3.institutionname,meetup3.colid)}>{meetup3.institutionname} - {meetup3.colid}</option>
 
                );
            })}
                  
                    </select>
                    <br /><br />

                            
                  {/* Enter University id<br /><br />

<input type="Number" required style={{width: "100%"}} placeholder="Enter University id" id="univid" ref={unividref} />
<br /><br /> */}

{/* Enter Program<br /><br /> */}



<label htmlFor='allprograms'>Select program</label>
                    <select id="allprograms" className="form-control" onChange={getval1}  ref={allprogramsref}>
                    
                    <option value="1">Select program</option>

    {faculties.map((meetup3) => {
                return (
                    <option value={combine(meetup3.program,meetup3.programcode)}>{meetup3.program} - {meetup3.programcode}</option>
 
                );
            })}
                  
                    </select>
                    <br /><br />

{/* <input type="String" required style={{width: "100%"}} placeholder="Enter Program" id="program" ref={programref} />
<br /><br />

Enter Program code<br /><br />

<input type="String" required style={{width: "100%"}} placeholder="Enter Program code" id="programcode" ref={programcoderef} />
<br /><br /> */}

{/* Enter Academic year<br /><br /> */}

<label htmlFor="admissionyear">Academic Year</label>

<select id="admissionyear" className="form-control"  ref={yearref}>
<option value="2022-23">2022-23</option>
<option value="2023-24">2023-24</option>
</select>
<br /><br />

{/* <input type="String" required style={{width: "100%"}} placeholder="Enter Academic year" id="year" ref={yearref} />
<br /><br /> */}

Enter Sanctioned strength<br /><br />

<input type="Number" required style={{width: "100%"}} placeholder="Enter Sanctioned strength" id="sanc" ref={sancref} />
<br /><br />

Enter Admitted students (Add 0 here)<br /><br />

<input type="Number" required style={{width: "100%"}} placeholder="Enter Admitted students" id="admitted" ref={admittedref} />
<br /><br />

{/* Enter Level<br /><br />

<input type="String" required style={{width: "100%"}} placeholder="Enter Level" id="level" ref={levelref} />
<br /><br /> */}

<label htmlFor="level">Level</label>

<select id="level" className="form-control"  ref={levelref}>
<option value="First">First year</option>
<option value="Second">Second year</option>
<option value="Third">Third year</option>
<option value="Fourth">Fourth year</option>
<option value="Fifth">Fifth year</option>


</select>
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;