import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sfeb';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
const schemeref=useRef();
const noofgovstudref=useRef();
const amountgovref=useRef();
const noofinststudref=useRef();
const amountinstref=useRef();
const noofngostudref=useRef();
const amountngoref=useRef();
const ngoagencynameref=useRef();
const noofinsdustrystudref=useRef();
const amountindustryref=useRef();
const industryref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       yearref.current.value=global1.year;
schemeref.current.value=global1.scheme;
noofgovstudref.current.value=global1.noofgovstud;
amountgovref.current.value=global1.amountgov;
noofinststudref.current.value=global1.noofinststud;
amountinstref.current.value=global1.amountinst;
noofngostudref.current.value=global1.noofngostud;
amountngoref.current.value=global1.amountngo;
ngoagencynameref.current.value=global1.ngoagencyname;
noofinsdustrystudref.current.value=global1.noofinsdustrystud;
amountindustryref.current.value=global1.amountindustry;
industryref.current.value=global1.industry;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const year=yearref.current.value;
const scheme=schemeref.current.value;
const noofgovstud=noofgovstudref.current.value;
const amountgov=amountgovref.current.value;
const noofinststud=noofinststudref.current.value;
const amountinst=amountinstref.current.value;
const noofngostud=noofngostudref.current.value;
const amountngo=amountngoref.current.value;
const ngoagencyname=ngoagencynameref.current.value;
const noofinsdustrystud=noofinsdustrystudref.current.value;
const amountindustry=amountindustryref.current.value;
const industry=industryref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatestudschspnewbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                year:year,
scheme:scheme,
noofgovstud:noofgovstud,
amountgov:amountgov,
noofinststud:noofinststud,
amountinst:amountinst,
noofngostud:noofngostud,
amountngo:amountngo,
ngoagencyname:ngoagencyname,
noofinsdustrystud:noofinsdustrystud,
amountindustry:amountindustry,
industry:industry,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnstudschspnew');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Academic year<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Academic year" id="year" ref={yearref} />
<br /><br />

Enter Scheme<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Scheme" id="scheme" ref={schemeref} />
<br /><br />

Enter No of students receiving govt scholarship<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter No of students receiving govt scholarship" id="noofgovstud" ref={noofgovstudref} />
<br /><br />

Enter Amount of govt scholarship<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Amount of govt scholarship" id="amountgov" ref={amountgovref} />
<br /><br />

Enter No of students receiving institutional scholarship<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter No of students receiving institutional scholarship" id="noofinststud" ref={noofinststudref} />
<br /><br />

Enter Amount of institutional scholarship<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Amount of institutional scholarship" id="amountinst" ref={amountinstref} />
<br /><br />

Enter No of students receiving NGO scholarships<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter No of students receiving NGO scholarships" id="noofngostud" ref={noofngostudref} />
<br /><br />

Enter Amount of NGO scholarship<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Amount of NGO scholarship" id="amountngo" ref={amountngoref} />
<br /><br />

Enter NGO agency name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter NGO agency name" id="ngoagencyname" ref={ngoagencynameref} />
<br /><br />

Enter No of students receiving industry scholarship<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter No of students receiving industry scholarship" id="noofinsdustrystud" ref={noofinsdustrystudref} />
<br /><br />

Enter Amount of industry scholarship<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Amount of industry scholarship" id="amountindustry" ref={amountindustryref} />
<br /><br />

Enter Industry<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Industry" id="industry" ref={industryref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;