import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const trustheadref=useRef();
const trustnameref=useRef();
const addressref=useRef();
const address2ref=useRef();
const regnoref=useRef();
const dateofregref=useRef();
const chairmannameref=useRef();
const fathernameref=useRef();
const emailref=useRef();
const mobnoref=useRef();
const restelnoref=useRef();
const trustemailref=useRef();
const trustnoref=useRef();
const offtelnoref=useRef();
const faxnoref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const trusthead=trustheadref.current.value;
const trustname=trustnameref.current.value;
const address=addressref.current.value;
const address2=address2ref.current.value;
const regno=regnoref.current.value;
const dateofreg=dateofregref.current.value;
const chairmanname=chairmannameref.current.value;
const fathername=fathernameref.current.value;
const email=emailref.current.value;
const mobno=mobnoref.current.value;
const restelno=restelnoref.current.value;
const trustemail=trustemailref.current.value;
const trustno=trustnoref.current.value;
const offtelno=offtelnoref.current.value;
const faxno=faxnoref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/creatermsutrustdatabyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                trusthead:trusthead,
trustname:trustname,
address:address,
address2:address2,
regno:regno,
dateofreg:dateofreg,
chairmanname:chairmanname,
fathername:fathername,
email:email,
mobno:mobno,
restelno:restelno,
trustemail:trustemail,
trustno:trustno,
offtelno:offtelno,
faxno:faxno,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnrmsutrustdata');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Head of the Trust<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Head of the Trust" id="trusthead" ref={trustheadref} />
<br /><br />

Enter Name of Trust or Society<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of Trust or Society" id="trustname" ref={trustnameref} />
<br /><br />

Enter Address(City Pincode)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address(City Pincode)" id="address" ref={addressref} />
<br /><br />

Enter District<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter District" id="address2" ref={address2ref} />
<br /><br />

Enter Registration No <br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Registration No " id="regno" ref={regnoref} />
<br /><br />

Enter Date of Registration<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Registration" id="dateofreg" ref={dateofregref} />
<br /><br />

Enter Name of Chairman<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of Chairman" id="chairmanname" ref={chairmannameref} />
<br /><br />

Enter Father's Name of Chairman<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Father's Name of Chairman" id="fathername" ref={fathernameref} />
<br /><br />

Enter Chairman email<br /><br />

<input type="email" required style={{width: "100%"}} placeholder="Enter Chairman email" id="email" ref={emailref} />
<br /><br />

Enter Chairman Mobile No<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Chairman Mobile No" id="mobno" ref={mobnoref} />
<br /><br />

Enter Chairman Resedential no<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Chairman Resedential no" id="restelno" ref={restelnoref} />
<br /><br />

Enter Trust email<br /><br />

<input type="email" required style={{width: "100%"}} placeholder="Enter Trust email" id="trustemail" ref={trustemailref} />
<br /><br />

Enter Trust office No<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Trust office No" id="trustno" ref={trustnoref} />
<br /><br />

Enter Office Telephone No<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Office Telephone No" id="offtelno" ref={offtelnoref} />
<br /><br />

Enter Trust Fax no<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Trust Fax no" id="faxno" ref={faxnoref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;