import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import { Bar } from 'react-chartjs-2';
import Card1 from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ListGroup from 'react-bootstrap/ListGroup';

import Button from 'react-bootstrap/Button';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faCube, faVideo, faCubes, faFile, faUsers, faDatabase, faTachometerAlt, faComment, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidemetricNavigationadmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';



function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();
    const programref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);


    const [circular, setCircular] = useState([]);
    
    const [projectcount, setProjectcount] = useState([]);
    const [projectamount, setProjectamount] = useState([]);
    const [bookcount, setBookcount] = useState([]);
    const [patentscount, setPatentscount] = useState([]);
    const [pubtarget, setPubtarget] = useState();
    const [pubvariant, setPubvariant] = useState();
    const [booktarget, setBooktarget] = useState();
    const [bookvariant, setBookvariant] = useState();
    const [semtarget, setSemtarget] = useState();
    const [semvariant, setSemvariant] = useState('');
    const [projtarget, setProjtarget] = useState();
    const [projvariant, setProjvariant] = useState();
    const [constarget, setConstarget] = useState();
    const [consvariant, setConsvariant] = useState();
    const [ectarget, setEctarget] = useState();
    const [ecvariant, setEcvariant] = useState();

    const [newcourse, setNewcourse] = useState();
    const [ncvariant, setNcvariant] = useState();

    const [vac, setVac] = useState();
    const [vacvariant, setVacvariant] = useState();

    const [exp, setExp] = useState();
    const [expvariant, setExpvariant] = useState();

    const [adm, setAdm] = useState();
    const [admvariant, setAdmvariant] = useState();

    const [publicationcount, setPublicationcount] = useState([]);
    const [link, setLink] = useState();
    const [photo, setPhoto] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [dep, setDep] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    //const name=global1.name;
    const coursename=global1.coursename;
    const coursecode1=global1.coursecode;
    const courseid=global1.courseid; 
    const section=global1.section;
    const semester=global1.semester;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const viewstudents = () => {
        history.replace('/classstudents');
    }
    const viewstudentsm = () => {
        history.replace('/classstudentsm');
    }
    const viewstudentsmsec = () => {
        history.replace('/classstudentsmsec');
    }

    const addassignments = () => {
        history.replace('/addassignments');
    }

    const viewassignments = () => {
        history.replace('/classassignments');
    }
    const viewstudymaterial = () => {
        history.replace('/classfiles');
    }

    const addstudymaterial = () => {
        history.replace('/addcoursefiles');
    }

    const gotoattendance = () => {
        // history.replace('/classattendance');
        history.replace('/selectattendance');
    }

    const addmodules = () => {
        history.replace('/addlessonplan');
    }

    const viewmodules = () => {
        history.replace('/lessonplan');
    }
    const gotodynamicmodules = () => {
        history.replace('/viewdynamicmodules');
    }

    const addlessonplan = () => {
        history.replace('/viewlessonnew');
    }

    const addstudents = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudents');
    }

    const addstudentsext = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudentsext');
    }

    

   
var uphoto='';
var uname='';


const progcount = async () => {
    if(!programref.current.value) {
        alert('Please enter program count');
        return;
    }
    const prog=parseInt(programref.current.value);
    

    const response = await ep1.get('/api/v1/expcount', {
        params: {
            colid: colid,
            user: user

        }

    });
    //setExp(response.data.data.totalcourse);
    //alert(prog + ' ' + response.data.data.totalcourse);
    var l=Math.floor(parseInt(response.data.data.totalcourse)/prog*100);
    //alert(l);
    if(l<60) {
        setExpvariant('danger');
    } else if (l > 59 && l < 80) {
        setExpvariant('warning');
    } else {
        setExpvariant('success');
    }
    setExp(l);


    // const response1 = await ep1.get('/api/v1/newcoursecount', {
    //     params: {
    //         colid: colid,
    //         user: user

    //     }

    // });

}


const admcount = async () => {
    setLoading(true);
    //setTerm(studid);
    const response = await ep1.get('/api/v1/admcount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    //setResults1(response.data.data.classes);
    setAdm(response.data.data.percentage);
    var l=parseInt(response.data.data.percentage);
    if(l<80) {
        setAdmvariant('danger');
    } else if (l > 79 && l < 90) {
        setAdmvariant('warning');
    } else {
        setAdmvariant('success');
    }
 
   
    //alert(results.length);
   
};

const searchApi1 = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/newcoursecount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    //setResults1(response.data.data.classes);
    setNewcourse(response.data.data.percentage);
    var l=parseInt(response.data.data.percentage);
    if(l<16) {
        setNcvariant('danger');
    } else if (l > 15 && l < 21) {
        setNcvariant('warning');
    } else {
        setNcvariant('success');
    }
 
   
    //alert(results.length);
   
};

const vaccount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/vaccount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    //setResults1(response.data.data.classes);
    setVac(response.data.data.totalcourse);
    var l=parseInt(response.data.data.totalcourse);
    if(l<40) {
        setVacvariant('danger');
    } else if (l > 39 && l < 51) {
        setVacvariant('warning');
    } else {
        setVacvariant('success');
    }
 
   
    //alert(results.length);
   
};

const searchPubcount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getpublicationscount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setPublicationcount(response.data.data.classes);
    var c=0;
    // var c=response.data.data.classes.length;
    response.data.data.classes.map((k)=> {
        c=c+k.total_attendance;
    })
    var l=0;
    if(c<10) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setPubtarget(l);
    if(l<50) {
        setPubvariant('danger');
    } else if (l > 50 && l < 99) {
        setPubvariant('warning');
    } else {
        setPubvariant('success');
    }

   
    //alert(results.length);
   
};

const searchBookcount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getbookcount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setBookcount(response.data.data.classes);

    var c=0;
    // var c=response.data.data.classes.length;
    response.data.data.classes.map((k)=> {
        c=c+k.total_attendance;
    })
    var l=0;
    if(c<10) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setBooktarget(l);
    if(l<50) {
        setBookvariant('danger');
    } else if (l > 50 && l < 99) {
        setBookvariant('warning');
    } else {
        setBookvariant('success');
    }
   
    //alert(results.length);
   
};

const getprojectcount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getprojectscount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setProjectcount(response.data.data.classes); 
    var c=0;
    // var c=response.data.data.classes.length;
    response.data.data.classes.map((k)=> {
        c=c+k.total_attendance;
    })
    var l=0;
    if(c<5) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setProjtarget(l); 
    if(l<50) {
        setProjvariant('danger');
    } else if (l > 50 && l < 99) {
        setProjvariant('warning');
    } else {
        setProjvariant('success');
    }
};

const getconscount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getconsultancybyfac', {
        params: {
            colid: colid,
            user: user,
            token:token

        }

    });
    setLoading(false);
    console.log(response.data.data);
    //setProjectcount(response.data.data.classes); 
    var c=0;
    var c=response.data.data.classes.length;
    // response.data.data.classes.map((k)=> {
    //     c=c+k.total_attendance;
    // })
    var l=0;
    if(c<5) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setConstarget(l); 
    if(l<50) {
        setConsvariant('danger');
    } else if (l > 50 && l < 99) {
        setConsvariant('warning');
    } else {
        setConsvariant('success');
    }
};

const geteccount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getecontentbyfac', {
        params: {
            colid: colid,
            token:token,
            user: user
            

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setProjectcount(response.data.data.classes); 
    var c=0;
    var c=response.data.data.classes.length;
    // response.data.data.classes.map((k)=> {
    //     c=c+k.total_attendance;
    // })
    var l=0;
    if(c<5) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setEctarget(l); 
    if(l<50) {
        setEcvariant('danger');
    } else if (l > 50 && l < 99) {
        setEcvariant('warning');
    } else {
        setEcvariant('success');
    }
};

    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getuserdetails', {
            params: {
                user: user,
                token: token,
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        //setResults(response.data.data.classes);
    uname=response.data.data.classes[0].name;
    uphoto=response.data.data.classes[0].photo;
    setEmail(response.data.data.classes[0].email);
    setPhone(response.data.data.classes[0].phone);
    setDep(response.data.data.classes[0].department);
    if(!uphoto) {
        uphoto='https://jadavpuruniversity.s3-ap-south-1.amazonaws.com/820222034-user.png';
    }
    setPhoto(uphoto);
        //alert(uname + ' ' + photo);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };




    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };

    useEffect(() => {
        checklogin();
        admcount();
        //search1();
        //getexamlist();
        //searchApi('');
        //getperiod();
        //getclass();
        searchApi1('');
        vaccount();
        // searchApi1('');
        // getconscount();
        // searchPubcount();
        // searchBookcount();
        
        // getprojectcount();
        // geteccount();
        

    }, []);

    function checkdata(link, ccode) {
        //alert (examcode + '-' + examname);
        if (link=='NA') {
            link="https://meet.jit.si/" + ccode;
        }
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDragStart = (ev, ccode,  course, section, semester, program) => {
        //ev.preventDefault();
        ev.dataTransfer.setData("ccode", ccode);
        ev.dataTransfer.setData("course", course);
        ev.dataTransfer.setData("section", section);
        ev.dataTransfer.setData("semester", semester);
        ev.dataTransfer.setData("program", program);
        //setCoursecode(ccode);
    }
    const onDrop = async (ev, starttime, endtime) => {
        let ccode = ev.dataTransfer.getData("ccode");
        let course = ev.dataTransfer.getData("course");
        let section = ev.dataTransfer.getData("section");
        let semester = ev.dataTransfer.getData("semester");
        let program = ev.dataTransfer.getData("program");
        const module=moduleref.current.value;
        const link=linkref.current.value;
        const topic=topicref.current.value;
        if(!module) {
            alert("Please enter module");
            return;
        }
        if(!topic) {
            alert("Please enter topic");
            return;
        }
        if(!link) {
            alert("Please enter link");
            return;
        }
        
        setCoursecode(ccode);
        const response = await ep1.get('/api/v1/createclass', {
            params: {
                user: user,
                name: name,
                colid: colid,
                classdate: datechange,
                weeks: 0,
                coursecode: ccode,
                course: course,
                link: link,
                program: program,
                semester: semester,
                section: section,
                module: module,
                topic: topic,
                classtime: starttime



            }

        });
        if (response.data.status == "Success") {
            
            //history.replace('/workload');
            //setTerm2('Thank you');  
            getclass();
            alert('Data updated successfully');
        }
        //setResults(response.data.data.classes);

        //alert(ccode + '-' + starttime + '-' + endtime + '-' + datechange + '-' + module + '-' + topic + '-' + link);

    }

    async function refreshclass(newdate) {
        
        var dt1=new Date(newdate);
        setDate2(dt1);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
    }

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        
                        <SidecolorNavigation />
                    </td>
                   
                    <td> 

                    <TopNavigation />
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >

           <h4>Score dashboard</h4>

            
            <br /><br />
            <table>
                <tr>
                    <td>
                    Enter no of programs
                    </td>
                    <td width="20px"></td>
                    <td>
                    <input type="text"  placeholder="Enter no of programs" id="hours" ref={programref} />
                    </td>
                    <td width="20px"></td>
                    <tyd>
                    <Button className="btn btn-dark" onClick={() => progcount()}> 
                        Populate program related metrics
                            </Button>
                    </tyd>
                </tr>
            </table>
            <br />
            


<br /><br />

            {/* <p style={{ fontSize: 18 }}>Select an option to proceed</p> */}
          

        <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Personal details">
        
      <div className="container">
                <div className="row">
               
                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>New course <Badge bg="info">30</Badge></Card1.Title>
        <Card1.Text>
        <ProgressBar now={newcourse} striped variant={`${ncvariant}`} label={`${newcourse}%`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>
                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>Value added courses <Badge bg="info">30</Badge></Card1.Title>
        <Card1.Text>
        <ProgressBar now={vac} striped variant={`${vacvariant}`} label={`${vac}`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>

                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>Experiential learning <Badge bg="info">5</Badge></Card1.Title>
        <Card1.Text>
        <ProgressBar now={exp} striped variant={`${expvariant}`} label={`${exp}%`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>

             

            

                </div>

        </div>
        <br />



        <div className="container">
                <div className="row">
               
                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>Enrollment <Badge bg="info">5</Badge></Card1.Title>
        <Card1.Text>
        <ProgressBar now={adm} striped variant={`${admvariant}`} label={`${adm}%`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>
                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>Value added courses <Badge bg="info">30</Badge></Card1.Title>
        <Card1.Text>
        <ProgressBar now={vac} striped variant={`${vacvariant}`} label={`${vac}`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>

                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>Experiential learning <Badge bg="info">5</Badge></Card1.Title>
        <Card1.Text>
        <ProgressBar now={exp} striped variant={`${expvariant}`} label={`${exp}%`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>

             

            

                </div>

        </div>
        <br />

        <div className="container">
                <div className="row">
               


                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>Books</Card1.Title>
        <Card1.Text>
        <ProgressBar now={booktarget} striped variant={`${bookvariant}`} label={`${booktarget}%`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>

                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>Consultancy</Card1.Title>
        <Card1.Text>
        <ProgressBar now={constarget} striped variant={`${consvariant}`} label={`${constarget}%`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>

                <div className="col-4">
                <Card1 style={{ width: '18rem' }}>
      
      <Card1.Body>
        <Card1.Title>E content</Card1.Title>
        <Card1.Text>
        <ProgressBar now={ectarget} striped variant={`${ecvariant}`} label={`${ectarget}%`} />
        </Card1.Text>
        
      </Card1.Body>
    </Card1>
                </div>

                </div>

        </div>
        <br /><br />

       




      </Tab>
      <Tab eventKey="research" title="Research funding">
        Looking for reasearch funding? Here is a quick guide for you. We can help you to create proposal. Get in touch with us at queries@epaathsala.com.
        <br /><br />
        <ListGroup>
      <ListGroup.Item>Visit funding agency site</ListGroup.Item>
      <ListGroup.Item>Check scheme and eligibility</ListGroup.Item>
      <ListGroup.Item>Check deadline</ListGroup.Item>
      <ListGroup.Item>Submit proposal</ListGroup.Item>
      <ListGroup.Item>Check status</ListGroup.Item>
    </ListGroup>
    <br /><br />
        Here is a list of some funding agencies for research funding.<br /><br />
        <a href='https://main.icmr.nic.in' target="_blank"> ICMR</a> &nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://www.isro.gov.in/ResearchGrants.html' target="_blank">ISRO</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://dst.gov.in' target="_blank"> DST</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://dbtindia.gov.in' target="_blank"> DBT</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://dae.gov.in' target="_blank">DAE</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://icfre.gov.in' target="_blank"> ICFRE</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://www.nano.gov/DOEd' target="_blank">DoED</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://www.mofpi.gov.in' target="_blank"> DoFPI</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <a href='https://www.indiascienceandtechnology.gov.in/organisations/ministry-and-departments/ministry-new-and-renewable-energy-mnre' target="_blank"> MNRE</a><br />
      </Tab>
      <Tab eventKey="contact" title="Collaborative research and publications">
      Looking for collaborative research or publications with another faculty? We can help you to create proposal. Get in touch with us at queries@epaathsala.com. 
        <br /><br />
        <ListGroup>
      <ListGroup.Item>Send email with proposed research or publication</ListGroup.Item>
      <ListGroup.Item>We try to find another faculty with same expertise who is willing to collaborate</ListGroup.Item>
      <ListGroup.Item>Once we receive the confirmation, we connect you</ListGroup.Item>
     
    </ListGroup>
    <br /><br />
    We work as facilitators only. We do not get involved in the actual work. there is no cost for this service.
      </Tab>
    </Tabs>

        

        

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;