import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sfeb';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const degreeref=useRef();
const courseref=useRef();
const natreqref=useRef();
const natavailref=useRef();
const natdefref=useRef();
const intnreqref=useRef();
const intnavailref=useRef();
const intndefref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       degreeref.current.value=global1.degree;
courseref.current.value=global1.course;
natreqref.current.value=global1.natreq;
natavailref.current.value=global1.natavail;
//natdefref.current.value=global1.natdef;
intnreqref.current.value=global1.intnreq;
intnavailref.current.value=global1.intnavail;
//intndefref.current.value=global1.intndef;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };
    const getval1=(event)=> {
      
        //alert(event.target.value);
        const aff=degreeref.current.value;
        if (aff=='B.Arch.')
        {
            natreqref.current.value=6;
            intnreqref.current.value=6
        }
       else if (aff=='B.E.')
       {
            natreqref.current.value=8;
            intnreqref.current.value=2
    }
    else if (aff=='B.Sc.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='B.Tech.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='D.M.I.T.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='M.Arch.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='M.B.A.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='M.C.A.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='M.E.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='M.plan')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='M.Sc.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else if (aff=='M.Tech.')
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
    else  
       {
            natreqref.current.value=6;
            intnreqref.current.value=6
    }
          
         
      
    }
    const searchapi = async () => {
        //const title=titleref.current.value;
        const degree=degreeref.current.value;
const course=courseref.current.value;
const natreq=natreqref.current.value;
const natavail=natavailref.current.value;
//const natdef=natdefref.current.value;
const intnreq=intnreqref.current.value;
const intnavail=intnavailref.current.value;
//const intndef=intndefref.current.value;
const natdef=(natreq-natavail)*1.0/natreq*100;
const intndef=(intnreq-intnavail)*1.0/intnreq*100;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatepaunlibjournalbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                degree:degree,
course:course,
natreq:natreq,
natavail:natavail,
natdef:natdef,
intnreq:intnreq,
intnavail:intnavail,
intndef:intndef,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpaunlibjournal');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   <label htmlFor="degree">Degree</label>

<select id="degree" className="form-control" onChange={getval1} ref={degreeref}>
<option value="Select">Select</option>
<option value="B.Arch.">B.Arch.</option>
<option value="B.E.">B.E.</option>
<option value="B.Sc.">B.Sc.</option>
<option value="B.Tech.">B.Tech.</option>
<option value="D.M.I.T.">D.M.I.T.</option>
<option value="M.Arch.">M.Arch.</option>
<option value="M.B.A.">M.B.A.</option>
<option value="M.C.A.">M.C.A.</option>
<option value="M.E.">M.E.</option>
<option value="M.plan">M.plan</option>
<option value="M.Sc.">M.Sc.</option>
<option value="M.Tech.">M.Tech.</option>
<option value="Others">Others</option>
</select>
<br /><br />

Enter Course<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Course" id="course" ref={courseref} />
<br /><br />

Enter Required no. of national journals<br /><br />

<input type="number" readOnly required style={{width: "100%"}} placeholder="Enter Required no. of national journals" id="natreq" ref={natreqref} />
<br /><br />

Enter Available no.of national journals<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Available no.of national journals" id="natavail" ref={natavailref} />
<br /><br />

{/* Enter Deficiency%<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Deficiency%" id="natdef" ref={natdefref} />
<br /><br /> */}

Enter Required no. of international journals<br /><br />

<input type="number" readOnly required style={{width: "100%"}} placeholder="Enter Required no. of international journals" id="intnreq" ref={intnreqref} />
<br /><br />

Enter Available no.of international journals<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Available no.of international journals" id="intnavail" ref={intnavailref} />
<br /><br />

{/* Enter Deficiency%<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Deficiency%" id="intndef" ref={intndefref} />
<br /><br /> */}


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;