import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';
import OfficeNavigation from '../components/layout/SidelibNavigation';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const examref=useRef();
const questionref=useRef();
const questionidref=useRef();
const optionref=useRef();
const scoreref=useRef();


   
    const assetid=global1.assetid;
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       examref.current.value=global1.exam;
questionref.current.value=global1.question;
questionidref.current.value=global1.questionid;
optionref.current.value=global1.option;
scoreref.current.value=global1.score;

       //onDatechange(global1.datecompleted);
       if (!firebase.apps.length) { //avoid re-initializing
        firebase.initializeApp({
            apiKey: "AIzaSyD4AZlzFUmachQCJYr-vlb9XKJix3NMg-E",
            authDomain: "posto-campus.firebaseapp.com",
            databaseURL: "https://posto-campus.firebaseio.com",
            projectId: "posto-campus",
            storageBucket: "posto-campus.appspot.com",
            messagingSenderId: "1002415317254",
            appId: "1:1002415317254:web:f198e146e4c2a9ab86fe87",
            measurementId: "G-W6SP1NZPMJ"
        });

    }

    }, []);

    async function submitHandler() {
        //event.preventDefault();
        const exam=examref.current.value;
const question=questionref.current.value;
const questionid=questionidref.current.value;
const option=optionref.current.value;
const score=scoreref.current.value;

        //const module=topicref.current.value;

        //console.log(course + '-' + coursecode + '-' + program + '-' + semester + '-' + section + '-' + hours + '-' + module + '-' + name + '-' + user + '-' + colid)
        
        //alert(username + '-' + password);

        const db = firebase.firestore();
        db.collection("omcqop").doc(assetid).set({
            exam:exam,
question:question,
questionid:questionid,
option:option,
score:score,
status1: 'Submitted'
        }, { merge: true })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });  



        // db.collection("assets").add({
        //     asset: asset,
        //     type: type,
        //     status: status,
        //     user: user,
        //     colid: colid,
        //     datecompleted: datechange
        // })
        //     .then(function (docRef) {
        //         console.log("Document written with ID: ", docRef.id);
        //         //alert("Workload created with ID: " + docRef.id);
                
        //     })
        //     .catch(function (error) {
        //         console.error("Error adding document: ", error);
        //         alert("Error adding document: ", error);
        //     });
            //history.replace('/assetlist');

            const pagenav=global1.pagenav;
            //history.goBack();
            history.replace('/' + pagenav);



    }


    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    

           const width=window.innerWidth;
           const mdivwidth=width - 130;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <OfficeNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       
                        Enter Examination<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Examination" id="exam" ref={examref} />
<br /><br />

Enter Question<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Question" id="question" ref={questionref} />
<br /><br />

Enter Question id<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Question id" id="questionid" ref={questionidref} />
<br /><br />

Enter Option<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Option" id="option" ref={optionref} />
<br /><br />

Enter Score<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Score" id="score" ref={scoreref} />
<br /><br />


                       
                
                        <br /><br />

                        <button  onClick={submitHandler}>Update data</button>

                   
                      
                

                       
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
               
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;