import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const emailref=useRef();
    const studentsref=useRef();
    const facultiesref=useRef();
    const alumniref=useRef();
    const parentsref=useRef();
    const contentrref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [faculties, setFaculties] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    const getfacultylist = async () => {
        //setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getalldepartments', {
            params: {
                colid: colid,
                user: user,
                token: token

            }

        });
        //setLoading(false);
        console.log(response.data.data);
        setFaculties(response.data.data.classes);
       
        //alert(results.length);
       
    };


    useEffect(() => {
        //departmentref.current.value=department;
        //getfacultylist();

       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const email=emailref.current.value;
        const students=studentsref.current.value;
        const faculties=facultiesref.current.value;
        const alumni=alumniref.current.value;
        const parents=parentsref.current.value;
        const contentr=contentrref.current.value;
        //alert(department1);
        if(!email || !students || !parents || !faculties || !alumni || !contentr ) {
            alert('All fields are required');
            return;
        }
       
      
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createfeedbackreports1', {
            params: {
                user: user,
                token: token,
                to: email,
                students: students,
                faculties:faculties,
                parents: parents,
                alumni: alumni,
                contentr:contentr,
                colid: colid

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        alert(response.data.status);
        //history.replace('/viewpatent');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 130;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Create sample feedback analysis report for five years</p>
                        <hr />

                        <p>Enter email (report will be sent here)</p>
                          
                          <input type="text" required style={{width: '100%'}} placeholder="Enter email" id="email" ref={emailref} />
                          
                      <br /><br />

                      <p>Enter mail topic</p>
                          
                          <input type="text" required style={{width: '100%'}} placeholder="Enter topic" id="topic" ref={contentrref} />
                          
                      <br /><br />
                       
                            <p>Enter no of students</p>
                          
                            <input type="number" required style={{width: '100%'}} placeholder="Enter no of students" id="students" ref={studentsref} />
                            
                        <br /><br />

                        <p>Enter no of faculties</p>
                          
                          <input type="number" required style={{width: '100%'}} placeholder="Enter no of faculties" id="faculties" ref={facultiesref} />
                          
                      <br /><br />

                      <p>Enter no of alumni</p>
                          
                          <input type="number" required style={{width: '100%'}} placeholder="Enter no of alumni" id="alumni" ref={alumniref} />
                          
                      <br /><br />

                      <p>Enter no of parents</p>
                          
                          <input type="number" required style={{width: '100%'}} placeholder="Enter no of parents" id="parents" ref={parentsref} />
                          
                      <br /><br />
                  






               
                  


                      
                        <button  onClick={searchapi}>Generate report and email</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;