import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidemetricNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const coursecoderef=useRef();
const courseref=useRef();
const programcoderef=useRef();
const programref=useRef();
const examcoderef=useRef();
const examnameref=useRef();
const semesterref=useRef();
const regnoref=useRef();
const studentref=useRef();
const iamarksref=useRef();
const extmarksref=useRef();
const totalmarksref=useRef();
const co1ref=useRef();
const co2ref=useRef();
const co3ref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const coursecode=coursecoderef.current.value;
const course=courseref.current.value;
const programcode=programcoderef.current.value;
const program=programref.current.value;
const examcode=examcoderef.current.value;
const examname=examnameref.current.value;
const semester=semesterref.current.value;
const regno=regnoref.current.value;
const student=studentref.current.value;
const iamarks=iamarksref.current.value;
const extmarks=extmarksref.current.value;
const totalmarks=totalmarksref.current.value;
const co1=co1ref.current.value;
const co2=co2ref.current.value;
const co3=co3ref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createcocalbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                coursecode:coursecode,
course:course,
programcode:programcode,
program:program,
examcode:examcode,
examname:examname,
semester:semester,
regno:regno,
student:student,
iamarks:iamarks,
extmarks:extmarks,
totalmarks:totalmarks,
co1:co1,
co2:co2,
co3:co3,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewncocal');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Course code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Course code" id="coursecode" ref={coursecoderef} />
<br /><br />

Enter Course<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Course" id="course" ref={courseref} />
<br /><br />

Enter Program code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program code" id="programcode" ref={programcoderef} />
<br /><br />

Enter Program<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program" id="program" ref={programref} />
<br /><br />

Enter Exam code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Exam code" id="examcode" ref={examcoderef} />
<br /><br />

Enter Exam name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Exam name" id="examname" ref={examnameref} />
<br /><br />

Enter Semester<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Semester" id="semester" ref={semesterref} />
<br /><br />

Enter Reg no<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Reg no" id="regno" ref={regnoref} />
<br /><br />

Enter Student<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student" id="student" ref={studentref} />
<br /><br />

Enter IA marks<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter IA marks" id="iamarks" ref={iamarksref} />
<br /><br />

Enter External marks<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter External marks" id="extmarks" ref={extmarksref} />
<br /><br />

Enter Total marks<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total marks" id="totalmarks" ref={totalmarksref} />
<br /><br />

Enter CO1 percentage<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter CO1 percentage" id="co1" ref={co1ref} />
<br /><br />

Enter CO2 percentage<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter CO2 percentage" id="co2" ref={co2ref} />
<br /><br />

Enter CO3 percentage<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter CO3 percentage" id="co3" ref={co3ref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;