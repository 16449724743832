import ep1 from '../api/ep2';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';
import { FileDrop } from 'react-file-drop';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faBinoculars, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import classes2 from './fileupload.module.css';
//import classes1 from './newform1.module.css';
import './Demo.css';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/Sadditionaladmin1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import readXlsxFile from 'read-excel-file';
import Sideicon from '../components/layout/Sideicon';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();
    

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [commentsnew, setCommentsnew] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [prepare, setPrepare] = useState(true);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [backend, setBackend] = useState();
    const [frontend, setFrontend] = useState();

    

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    //const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const usernew=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    const assignmentid=global1.assignmentid;
    const assignmentname=global1.assignmentname;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

      var b1='';
      var f1='';

    const changeHandler1 = async (files,event) => {
        // const topic=topicref.current.value;
        // if(topic=="kumropatash") {
        // } else {
        //     alert('Please enter valid password');
        //     return;
        // }
        
      
        setSelectedFile(files[0]);
        setIsFilePicked(true);
        var status1='';
        var rownumber=1;
        const res=await readXlsxFile(files[0]).then((rows) => {
            rows.shift();
            rows.forEach((row) => {
                rownumber=rownumber + 1;
                if(!row[0]){
f1=f1 + 'row ' + rownumber + '-' + 'Type of Awards or Fellowship' + ',';
return;
}
if(!row[1]){
f1=f1 + 'row ' + rownumber + '-' + 'Awards or Fellowship name' + ',';
return;
}
if(!row[2] || isNaN(row[2])){
f1=f1 + 'row ' + rownumber + '-' + 'Score obtained' + ',';
return;
}


                searchapi(rownumber,row[0],row[1],row[2],status1);
               
            });

        })
        alert('Task will be completed in thirty minutes');
        setLink(f1);

    };

    const searchapi = async (rownumber,type,awards,score,status1) => {
        
        if(!type){
return;
}
if(!awards){
return;
}
if(!score || isNaN(score)){
return;
}

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createcas253byfac', {
            params: {
              
                user: usernew,
                token: token,
                colid: colid,
                name: name,
                type:type,
awards:awards,
score:score,
type:type,
awards:awards,
score:score,
status1:'Submitted',
                comments:'NA'

            }
        });
        //setLoading(false);
        setIsuploading(false);

       
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewadmission');
        //history.replace('/viewncas253');
       
    };

    const deleteenrolment = async (enrolmentid) => {
       
            
            setLoading(true);
            //setTerm(studid);
            const response = await ep1.get('/api/v1/deleteadmissionbyfac', {
                params: {
                    id: enrolmentid,
                    token: token
                }
    
            });
           // alert(response.data.status);
            setLoading(false);
           
       
       
    };

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addassets = () => {
        history.replace('/addmentee');
    }

    const assignmentdetails = (id, asset, regno) => {
        global1.menteeid=id;
        global1.mentee=asset;
        global1.menteeregno=regno;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/mentorlogbook');
    }

    

   

    

    function converttodt() {
        try {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setPrepare(false);
        } catch(err) {

        }
    }

    const commentnew = [];

    function getdata1() {
        const db = firebase.firestore();

        db.collection("workdiary").where("colid", "==", parseInt(colid)).where("user", "==", studid)
            .onSnapshot(function (querySnapshot) {
            const Comment = [];
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);
                const { workitem, hours, typeofwork, academicyear, department, datecompleted } = doc.data();
                Comment.push({
                    _id: doc.id,
                    workitem,
                    hours,
                    typeofwork,
                    academicyear,
                    department,
                    datecompleted
                });
            });
            //const { name, coursename, link } = querySnapshot.val();
            //console.log(doc.id + ' ' + doc.data);
            setResults(Comment);
           
        });
        
    }

    function deletedata1(id) {
        const db = firebase.firestore();

        db.collection("workdiary").doc(id).delete().then(() => {
            console.log("Document successfully deleted!");
            //alert("Success");
        }).catch((error) => {
            console.error("Error removing document: ", error);
            alert(error);
        });     
    }

    function setcompleted(id, status) {
        const db = firebase.firestore();

        
        db.collection("modules").doc(id).set({
            status: status,
            datecompleted: new Date()
        }, { merge: true })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });  
        //history.replace('/courseactions'); 
    }

    const _handlePressButtonAsync = async (link) => {
        var win = window.open(link, '_blank');
        win.focus();
      };

   

    const addcomments = async (submissionid, submissionname, submissionregno) => {
        
        global1.submissionid=submissionid;
        global1.submissionregno=submissionregno;
        global1.submissionname=submissionname;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/submissioncomments');
       
    };

    

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();

        if (!firebase.apps.length) { //avoid re-initializing
            firebase.initializeApp({
                apiKey: "AIzaSyD4AZlzFUmachQCJYr-vlb9XKJix3NMg-E",
                authDomain: "posto-campus.firebaseapp.com",
                databaseURL: "https://posto-campus.firebaseio.com",
                projectId: "posto-campus",
                storageBucket: "posto-campus.appspot.com",
                messagingSenderId: "1002415317254",
                appId: "1:1002415317254:web:f198e146e4c2a9ab86fe87",
                measurementId: "G-W6SP1NZPMJ"
            });
        }

        //getdata1();


        //searchApi('');
        

    }, []);


    function timely(dt1) {
        //var date1=new Date(dt1);
        const date1 = new Date(dt1.toDate().toDateString());
        return date1.getDate() + '/' + (date1.getMonth() + 1) + '/' + date1.getFullYear();
        //return dt1.toString();
    }

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 130;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60}}>
                        <Sideicon />
                        </td>

                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidecolorNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10}}
       
        >
            <p style={{ fontSize: 18 }}>Add bulk data</p>
            <hr />

            Please upload excel file with data in proper format with following columns in order.<br /><br />
                        type,awards,score,status <br /><br />
                        Do not add any extra column or rows. Date must be in mm/dd/yyyy format. Value of status must always be Submitted.
            <br />
           
    
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
            


				{/* <div style={{ marginTop: 10, marginBottom: 10}}>
                  <table>
                <tr>
            
                    <td>
                    {prepare ? (
                    <div className={classes1.actions}>
                    <button  onClick={converttodt}>Advanced view</button>
                    </div>
                    ) : (
                        <div></div>
                    )} 
                    </td>
                </tr>
            </table>
           
				</div>
                <a href="https://drive.google.com/file/d/1c-EBydLOaM1ju7Z8uPvHSqR4tt_tim3U/view?usp=sharing" target="_blank">Sample file</a> */}


         

                {/* Enter password<br /><br />

<input type="password" required style={{width: "100%"}} placeholder="Enter table name" id="topic" ref={topicref} />
<br /><br /> */}



                <div style={styles}>
        <FileDrop className={classes2.FileDrop}
          onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
          onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
          onFrameDrop={(event) => console.log('onFrameDrop', event)}
          onDragOver={(event) => console.log('onDragOver', event)}
          onDragLeave={(event) => console.log('onDragLeave', event)}
          onDrop={(files, event) => changeHandler1(files, event)}
        >
          Drop some files here!
        </FileDrop>
      </div>
      <br /><br />
      {/* Frontend
      <br /><br />
      {frontend}
      <br /><br />
      Back end
      <br /><br />
      {backend}
      <br /><br /> */}


               
{/* 			
            
     
<Table striped bordered hover id="example">

<thead>
<tr>
     
      <th>Work item</th>
      <th>Hours</th>
      <th>Type of work</th>
      <th>Academic year</th>
      <th>Department</th>
      <th>Date completed</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        <td>
                        {meetup3.workitem}
                        </td>
                        <td>
                            {meetup3.hours}
                        </td>
                        <td>
                            {meetup3.typeofwork}
                        </td>
                        <td>
                            {meetup3.academicyear}
                        </td>
                        <td>
                            {meetup3.department}
                        </td>
                        <td>
                            {timely(meetup3.datecompleted)}
                        </td>
                        
                        
                        <td>
                        <Button variant="outline-danger" onClick={() => deletedata1(meetup3._id)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} />
                            Delete</Button>
                           
                         
                        </td>
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table> */}

            {/* <br /><br />    */}

<p>Error list</p>
{link}
{/* <Table striped bordered hover id="example">
    <thead>
        <tr>
            <td>Row number</td>
            <td>Field</td>
            <td>Value</td>
        </tr>
    </thead>
    <tbody>
            {commentsnew.map((meetup3) => {
                return (
                    <tr>
                        <td>
                        {meetup3.rownumber}
                        </td>
                        <td>
                            {meetup3.display}
                        </td>
                        <td>
                            {meetup3.field}
                        </td>
                  
                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table> */}
            <br /><br />
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;