import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const fromemailref=useRef();
    const passwordref=useRef();
    const yopref=useRef();
    const websiteref=useRef();

    const academicyearref=useRef();
    const calendaryearref=useRef();
    const lmsyearref=useRef();

    const eventtypedropref=useRef();
    const eventtypetref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [results, setResults] = useState([]);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    //const coursecode=global1.coursecode;
    //const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    const gotoeventtypet = () => {
  
        const fromemail=fromemailref.current.value;
        const password=passwordref.current.value;
        if(!fromemail || !password) {
            alert('Please enter all details');
            return;
        }
        global1.fromemail=fromemail;
        global1.emailpassword=password;
        alert('Configuration set. Please go to AI Gen menu to send reports.');
        
    }

    const gotoeventtyped = () => {
  
        const eventtyped=eventtypedropref.current.value;
        if(!eventtyped) {
            alert('Please enter event type');
            return;
        }
        global1.eventtype=eventtyped;
        alert('Event type set to ' + eventtyped);
        
    }

    const gotocalendaryear = () => {
  
        const calendaryear=calendaryearref.current.value;
        if(!calendaryear) {
            alert('Please enter calendar year');
            return;
        }
        global1.calendaryear=calendaryear;
        alert('Calendar year set to ' + calendaryear);
    }

    const gotowebsite = () => {
  
        const website=websiteref.current.value;
        if(!website) {
            alert('Please enter website');
            return;
        }
        global1.website=website;
        alert('Website set to ' + website);
    }

    const gotolmsyear = () => {
  
        const lmsyear=lmsyearref.current.value;
        if(!lmsyear) {
            alert('Please enter LMS Year');
            return;
        }
        global1.lmsyear=lmsyear;
        alert('LMS Year set to ' + lmsyear);
        
        
        //alert(global1.role);
    }

    const gotodashcourse = () => {
        history.replace('/dashcourse');
    }

    const gotoadminreports = () => {
        if(global1.role=='Admin') {

            history.replace('/viewworkloadadmin');

        } else {
            alert('This option is available only for Admin');

        }
        
    }

    

    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        //setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getclasscount', {
            params: {
                user: user,
                token: token,
                colid: colid,
                coursecode: coursecode1
            }
        });
        //setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
       

        
        setResults(response.data.data.classes);
   
        //alert('format ' + format);
        
        
       
        //alert(results.length);
       
    };


    useEffect(() => {
        //searchApi();
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

   

   
           const width=window.innerWidth;
           const mdivwidth=width - 130;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        {/* <p style={{ fontSize: 18 }}> Select Academic Year (for example, 2020-21)</p>
                        <hr />


                        <input type="text"  style={{width: '100%'}} placeholder="Enter academic year" id="academicyear" ref={academicyearref} />
                            
                            <br /><br />

                        <button  onClick={gotoacademicyear}>Set Academic Year</button>

                        <br /><br />

                          
                        <p style={{ fontSize: 18 }}> Select Calendar Year (for example, 2020)</p>
                        <hr />


                        <input type="text"  style={{width: '100%'}} placeholder="Enter calendar year" id="calendaryear" ref={calendaryearref} />
                            
                            <br /><br />

                        <button  onClick={gotocalendaryear}>Set Calendar Year</button>

                        <br /><br />

                        <p style={{ fontSize: 18 }}> Select full website url (for example, https://campus.technology)</p>
                        <hr />


                        <input type="text"  style={{width: '100%'}} placeholder="Enter website" id="website" ref={websiteref} />
                            
                            <br /><br />

                        <button  onClick={gotowebsite}>Set Website</button>

                        <br /><br /> */}

<p> Enter from email (from where email is sent, currently gmail is supported)</p>
                        <hr />


                        <input type="text"  style={{width: '100%'}} placeholder="Enter from email" id="frommail" ref={fromemailref} />
                            
                            <br />

                            <p> Enter email password (from where email is sent, currently gmail is supported)</p>
                        <hr />


                        <input type="password"  style={{width: '100%'}} placeholder="Enter email password" id="password" ref={passwordref} />
                            
                            <br /><br />

                        <button  onClick={gotoeventtypet}>Set configuration</button>

                        <br /><br />

                     

                        <br /><br />
                      


                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;