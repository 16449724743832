import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import Sidecr1 from '../components/layout/Sidecr1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
    const eventnameref=useRef();
    const descriptionref=useRef();
    const departmentref=useRef();
    const brochurelinkref=useRef();
    const dateref=useRef();
    const timeref=useRef();
    const coordinatorref=useRef();
    const typeref=useRef();
    const eventlinkref=useRef();
    const noref=useRef();
    const fromtoref=useRef();
    
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const eventid=global1.eventid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       yearref.current.value=global1.year;
       eventnameref.current.value=global1.eventname;
       descriptionref.current.value=global1.description;
       departmentref.current.value=global1.department;
       brochurelinkref.current.value=global1.brochurelink;
       dateref.current.value=global1.date;
       timeref.current.value=global1.time;
       coordinatorref.current.value=global1.coordinator;
       typeref.current.value=global1.type;
       eventlinkref.current.value=global1.eventlink;
       noref.current.value=global1.noof;
       fromtoref.current.value=global1.fromto;
       
       

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const year=yearref.current.value;
        const eventname=eventnameref.current.value;
        const description=descriptionref.current.value;
        const department=departmentref.current.value;
        const brochurelink=brochurelinkref.current.value;
        const date=dateref.current.value;
        const time=timeref.current.value;
        const coordinator=coordinatorref.current.value;
        const type=typeref.current.value;
        const eventlink=eventlinkref.current.value;
        const fromto=fromtoref.current.value;
        const noof=noref.current.value;
        
        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateeventbyfac', {
            params: {
                id: eventid,
                user: user,
                token: token,
                colid: colid,
                name: name,
                academicyear:year,
                eventname: eventname,
                description:description,
                department:department,
                brochurelink:brochurelink,
                date:date,
                time:time,
                coordinator:coordinator,
                type:type,
                eventlink:eventlink,
                fromto:fromto,
                noofparticipants:noof,
                status1:'Submitted',
                comments:'NA'

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewevent');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr1 />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit Activity</p>
                        <hr />
                       
                            
                        <label htmlFor='year'>Academic Year</label>
                        <select id="year" className="form-control"  ref={yearref}>
                            <option value="2016-17">2016-17</option>
                            <option value="2017-18">2017-18</option>
                            <option value="2018-19">2018-19</option>
                            <option value="2019-20">2019-20</option>
                            <option value="2020-21">2020-21</option>
                            <option value="2021-22">2021-22</option>
                            <option value="2022-23">2022-23</option>
                            <option value="2023-24">2023-24</option>
                       
                        </select>

                            <br /><br />
                            
                        <input type="text" required style={{width: '100%'}} placeholder="Enter event name" id="eventname" ref={eventnameref} />
                            
                        <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter event description" id="description" ref={descriptionref} />
                            
                        <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter department" id="department" ref={departmentref} />
                            
                            <br /><br />  

                        <input type="text" required style={{width: '100%'}} placeholder="Enter brochure link" id="brochurelink" ref={brochurelinkref} />
                            
                            <br /><br />  

                        <p>Enter event date</p>

                        <input type="date" required style={{width: '100%'}}  id="date" ref={dateref} />
                            
                            <br /><br />  

                        <input type="text" required style={{width: '100%'}} placeholder="Enter event time" id="time" ref={timeref} />
                            
                            <br /><br />  

                        <input type="text" required style={{width: '100%'}} placeholder="Enter event coordinator" id="coordinator" ref={coordinatorref} />
                            
                            <br /><br />  
                        
                            <input type="text" required style={{width: '100%'}} placeholder="Enter from-to" id="fromto" ref={fromtoref} />
                            
                            <br /><br />  

                            <input type="text" required style={{width: '100%'}} placeholder="Enter total no of participants" id="noof" ref={noref} />
                            
                            <br /><br />  

                        <label htmlFor='type'>Type of activity</label>
                        <select id="type" className="form-control"  ref={typeref}>
                            <option value="Field Trip">Field Trip</option>
                            <option value="Research/IPR">Research/IPR</option>
                            <option value="Sports and Cultural">Cultural</option>
                            <option value="Extension Activity">Extension Activity</option>
                            <option value="Extension Lecture">Extension Lecture</option>
                            <option value="FDP">FDP</option>
                            <option value="Skill Development Workshop">Skill Development Workshop</option>
                            <option value="Career Counselling Workshop">Career Counselling Workshop</option>
                            <option value="Green Initiative">Green Initiative</option>
                            <option value="Commemorate Day">Commemorate Day</option>
                            <option value="Conference/Seminar">Conference/Seminar</option>
                        </select>
                            
                            <br /><br />  

                        <input type="text" required style={{width: '100%'}} placeholder="Enter event link" id="eventlink" ref={eventlinkref} />
                            
                            <br /><br /> 
                      
                        <button  onClick={searchapi}>Update Activity</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;