import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faCube,  faVideo, faCubes, faFile, faUsers, faDatabase, faTachometerAlt, faComment, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faFileMedical } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [photo, setPhoto] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [dep, setDep] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    //const name=global1.name;
    const coursename=global1.coursename;
    const coursecode1=global1.coursecode;
    const courseid=global1.courseid; 
    const section=global1.section;
    const semester=global1.semester;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const viewstudents = () => {
        history.replace('/classstudents');
    }
    const viewstudentsm = () => {
        history.replace('/classstudentsm');
    }
    const viewstudentsmsec = () => {
        history.replace('/classstudentsmsec');
    }

    const addassignments = () => {
        history.replace('/addassignments');
    }

    const viewassignments = () => {
        history.replace('/classassignments');
    }
    const viewstudymaterial = () => {
        history.replace('/classfiles');
    }

    const addstudymaterial = () => {
        history.replace('/addcoursefiles');
    }

    const gotoattendance = () => {
        // history.replace('/classattendance');
        history.replace('/selectattendance');
    }

    const addmodules = () => {
        history.replace('/addlessonplan');
    }

    const viewmodules = () => {
        history.replace('/lessonplan');
    }
    const gotodynamicmodules = () => {
        history.replace('/viewdynamicmodules');
    }

    const addlessonplan = () => {
        history.replace('/viewlessonnew');
    }

    const addstudents = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudents');
    }

    const addstudentsext = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudentsext');
    }

    
    var uphoto='';
    var uname='';
   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getuserdetails', {
            params: {
                user: user,
                token: token,
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        //setResults(response.data.data.classes);
    uname=response.data.data.classes[0].name;
    uphoto=response.data.data.classes[0].photo;
    setEmail(response.data.data.classes[0].email);
    setPhone(response.data.data.classes[0].phone);
    setDep(response.data.data.classes[0].department);
    if(!uphoto) {
        uphoto='https://jadavpuruniversity.s3-ap-south-1.amazonaws.com/820222034-user.png';
    }
    setPhoto(uphoto);
        //alert(uname + ' ' + photo);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        searchApi('');
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    function checkdata(link, ccode) {
        //alert (examcode + '-' + examname);
        if (link=='NA') {
            link="https://meet.jit.si/" + ccode;
        }
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDragStart = (ev, ccode,  course, section, semester, program) => {
        //ev.preventDefault();
        ev.dataTransfer.setData("ccode", ccode);
        ev.dataTransfer.setData("course", course);
        ev.dataTransfer.setData("section", section);
        ev.dataTransfer.setData("semester", semester);
        ev.dataTransfer.setData("program", program);
        //setCoursecode(ccode);
    }
    const onDrop = async (ev, starttime, endtime) => {
        let ccode = ev.dataTransfer.getData("ccode");
        let course = ev.dataTransfer.getData("course");
        let section = ev.dataTransfer.getData("section");
        let semester = ev.dataTransfer.getData("semester");
        let program = ev.dataTransfer.getData("program");
        const module=moduleref.current.value;
        const link=linkref.current.value;
        const topic=topicref.current.value;
        if(!module) {
            alert("Please enter module");
            return;
        }
        if(!topic) {
            alert("Please enter topic");
            return;
        }
        if(!link) {
            alert("Please enter link");
            return;
        }
        
        setCoursecode(ccode);
        const response = await ep1.get('/api/v1/createclass', {
            params: {
                user: user,
                name: name,
                colid: colid,
                classdate: datechange,
                weeks: 0,
                coursecode: ccode,
                course: course,
                link: link,
                program: program,
                semester: semester,
                section: section,
                module: module,
                topic: topic,
                classtime: starttime



            }

        });
        if (response.data.status == "Success") {
            
            //history.replace('/workload');
            //setTerm2('Thank you');  
            getclass();
            alert('Data updated successfully');
        }
        //setResults(response.data.data.classes);

        //alert(ccode + '-' + starttime + '-' + endtime + '-' + datechange + '-' + module + '-' + topic + '-' + link);

    }

    async function refreshclass(newdate) {
        
        var dt1=new Date(newdate);
        setDate2(dt1);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
    }

    const width=window.innerWidth;
    const mdivwidth=width - 100;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        
                        <SidecolorNavigation />
                    </td> */}
                   
                    <td> 

                    <TopNavigation />
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >

<br /><br />

<div className="container">
    <div className="row">


    <div className="col-2">
    <img src={photo} style={{width: 130, height: 130, borderRadius: 65}} />
    <br /><br />
    <Link to='/selectawsconfigphoto' className="btn btn-dark"> Update Photo</Link>

    </div>

    <div className="col-2">
        <div style={{width:600}}>
    <h4>{name}</h4>
    <br />
    Department {dep} <br />
    Email {email} <br />
    Phone {phone} <br />
    
    </div>

    </div>

    </div>
    </div>
            
            <hr />
           
    
        <br />
        

        

        <div className="container">
                <div className="row">


                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faBook} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewuserfaculty' className="btn">Manage Users (Faculties, Students and HoD)</Link>

                        </div>
                </div>

                {/* <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewteacherfellowdoccommentsd' className="btn">Accreditation Data and Documents</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUsers} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnallcoursesadmin' className="btn">Additional Integrated metrics</Link>

                        </div>
                </div>

                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faCheckSquare} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewdepfacdocs' className="btn">Accreditation Data Submission Status</Link>

                        </div>
                </div>

                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/getstudentcount' className="btn">NIRF Data and documents</Link>

                        </div>
                </div>

                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/dashcriteria1' className="btn">NAAC all criteria wise graphical analysis</Link>

                        </div>
                </div>

                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewkpiuser1' className="btn">View and Manage Institution KPI</Link>

                        </div>
                </div>
              
                 <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/selectperiodlms' className="btn">LMS and Blended Learning</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/selectperiodlms' className="btn">Timetable and All LMS Admin reports</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUsers} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/dashmydetails' className="btn"> My Professional and Personal details</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faCube} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewndepmeetingsalladmin' className="btn"> Departmental meetings and tasks</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/dashmydetails' className="btn"> Apply and Manage leave and balance</Link>

                        </div>
                </div>
               
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUsers} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnallcoursesprog' className="btn">Additional metrics for NBA Engg Programwise</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUsers} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewlibbooks' className="btn">Library Management and OPAC</Link>

                        </div>
                </div>
               
               
                
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faCube} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewprojectstatus' className="btn">Project Status and Activities</Link>

                        </div>
                </div>
                
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewntrialbalanceadmin' className="btn">Finance Clients (AP) and Vendors (AR)</Link>

                        </div>
                </div>

                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faFile} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnopencourseadmin' className="btn">All Open courses and modules </Link>

                        </div>
                </div>

                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faFileMedical} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/dashfeedbackreports' className="btn">Feedback and value added courses</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faFileMedical} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnseedmoneyadmin' className="btn">Application forms for approval by employees</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewncompaniesadmin' className="btn">Placement and higher education </Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faFile} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnoffcampusadmin' className="btn">Data reported by the students </Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnpayslipsadmin' className="btn">HR module and Payslips for all employees</Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faDatabase} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewncommitteesadmin' className="btn">All committees and meeting minutes </Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faBook} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnrapplicationadmin' className="btn">Information for affilication all (Nursing) </Link>

                        </div>
                </div>
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faBook} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnexplearningadmin' className="btn">NAAC Feb 2023 modified metrics </Link>

                        </div>
                </div> */}
                <div className="col-3">
                         <div className={classes1.card} style={{ textAlign: 'center'}}
                          
                         >
                             <br />
                             <FontAwesomeIcon icon={faBook} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                    <Link to='/viewnpaunewidetailsadmin' className="btn">Information for institution (AU Engg) </Link>

                        </div>
                </div>
            </div></div>

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;