import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sfin1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const invoiceidref=useRef();
const itemref=useRef();
const hsnsacref=useRef();
const saleref=useRef();
const cgstref=useRef();
const sgstref=useRef();
const igstref=useRef();
const samestateref=useRef();
const totalref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }

    const invoiceid=global1.invoiceid;

    useEffect(() => {
       //logout();
       

       invoiceidref.current.value=global1.invoiceid;
itemref.current.value=global1.item;
hsnsacref.current.value=global1.hsnsac;
saleref.current.value=global1.sale;
cgstref.current.value=global1.cgst;
sgstref.current.value=global1.sgst;
igstref.current.value=global1.igst;
samestateref.current.value=global1.samestate;
totalref.current.value=global1.total;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const invoiceid=invoiceidref.current.value;
const item=itemref.current.value;
const hsnsac=hsnsacref.current.value;
const sale=saleref.current.value;
const cgst=cgstref.current.value;
const sgst=sgstref.current.value;
const igst=igstref.current.value;
const samestate=samestateref.current.value;
const total=totalref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateninvoiceitemsbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                invoiceid:invoiceid,
item:item,
hsnsac:hsnsac,
sale:sale,
cgst:cgst,
sgst:sgst,
igst:igst,
samestate:samestate,
total:total,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnninvoiceitems');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Invoice ID<br /><br />

<input type="text" readOnly required style={{width: "100%"}} placeholder="Enter Invoice ID" id="invoiceid" ref={invoiceidref} />
<br /><br />

Enter Item<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Item" id="item" ref={itemref} />
<br /><br />

Enter HSN or SAC code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter HSN or SAC code" id="hsnsac" ref={hsnsacref} />
<br /><br />

Enter Sale price<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Sale price" id="sale" ref={saleref} />
<br /><br />

Enter CGST amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter CGST amount" id="cgst" ref={cgstref} />
<br /><br />

Enter SGST amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter SGST amount" id="sgst" ref={sgstref} />
<br /><br />

Enter IGST amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter IGST amount" id="igst" ref={igstref} />
<br /><br />

<label htmlFor="samestate">If same state</label>

<select id="samestate" className="form-control"  ref={samestateref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Total amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total amount" id="total" ref={totalref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;