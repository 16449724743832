import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/An';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const col_nameref=useRef();
const addressref=useRef();
const pincoderef=useRef();
const estab_yearref=useRef();
const instu_typeref=useRef();
const instu_type_coderef=useRef();
const isInsti_autoref=useRef();
const isInsti_approvedref=useRef();
const refref=useRef();
const minorityref=useRef();
const tele_noref=useRef();
const mob_noref=useRef();
const fax_noref=useRef();
const emailref=useRef();
const web_addref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const col_name=col_nameref.current.value;
const address=addressref.current.value;
const pincode=pincoderef.current.value;
const estab_year=estab_yearref.current.value;
const instu_type=instu_typeref.current.value;
const instu_type_code=instu_type_coderef.current.value;
const isInsti_auto=isInsti_autoref.current.value;
const isInsti_approved=isInsti_approvedref.current.value;
const ref=refref.current.value;
const minority=minorityref.current.value;
const tele_no=tele_noref.current.value;
const mob_no=mob_noref.current.value;
const fax_no=fax_noref.current.value;
const email=emailref.current.value;
const web_add=web_addref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/creatermsucoldetabyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                col_name:col_name,
address:address,
pincode:pincode,
estab_year:estab_year,
instu_type:instu_type,
instu_type_code:instu_type_code,
isInsti_auto:isInsti_auto,
isInsti_approved:isInsti_approved,
ref:ref,
minority:minority,
tele_no:tele_no,
mob_no:mob_no,
fax_no:fax_no,
email:email,
web_add:web_add,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnrmsucoldeta');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Name of the college<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of the college" id="col_name" ref={col_nameref} />
<br /><br />

Enter Address<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address" id="address" ref={addressref} />
<br /><br />

Enter Pincode<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Pincode" id="pincode" ref={pincoderef} />
<br /><br />

Enter Year of Estalishment (Academic Year)<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Year of Estalishment (Academic Year)" id="estab_year" ref={estab_yearref} />
<br /><br />

Enter Type of Institution<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Type of Institution" id="instu_type" ref={instu_typeref} />
<br /><br />

<label htmlFor="instu_type_code">Type of Institution</label>

<select id="instu_type_code" className="form-control"  ref={instu_type_coderef}>
<option value="Government">Government</option>
<option value="Government Aided">Government Aided</option>
<option value="Self Financed">Self Financed</option>
<option value="Government Aided and Self Financed ">Government Aided and Self Financed </option>
</select>
<br /><br />

<label htmlFor="isInsti_auto">Is the instituion autonomous</label>

<select id="isInsti_auto" className="form-control"  ref={isInsti_autoref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

<label htmlFor="isInsti_approved">Is the instituion functioning at the approved site</label>

<select id="isInsti_approved" className="form-control"  ref={isInsti_approvedref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter G.O references<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter G.O references" id="ref" ref={refref} />
<br /><br />

<label htmlFor="minority">Minority</label>

<select id="minority" className="form-control"  ref={minorityref}>
<option value="G.O">G.O</option>
<option value="Renewal">Renewal</option>
</select>
<br /><br />

Enter Telephone Number with STD Code<br /><br />

<input type="Number" required style={{width: "100%"}} placeholder="Enter Telephone Number with STD Code" id="tele_no" ref={tele_noref} />
<br /><br />

Enter Mobile Number of Principal<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Mobile Number of Principal" id="mob_no" ref={mob_noref} />
<br /><br />

Enter Enter Fax Number<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Enter Fax Number" id="fax_no" ref={fax_noref} />
<br /><br />

Enter Email<br /><br />

<input type="email" required style={{width: "100%"}} placeholder="Enter Email" id="email" ref={emailref} />
<br /><br />

Enter Website_address<br /><br />

<input type="url" required style={{width: "100%"}} placeholder="Enter Website_address" id="web_add" ref={web_addref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;