import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Mexam';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const departmentref=useRef();
const deptcoderef=useRef();
const studentref=useRef();
const regnoref=useRef();
const examref=useRef();
const pubdateref=useRef();
const coursecoderef=useRef();
const courseref=useRef();
const creditref=useRef();
const maxiaref=useRef();
const maxesref=useRef();
const maxtotref=useRef();
const marksiaref=useRef();
const marksesref=useRef();
const markstotref=useRef();
const gpref=useRef();
const lgref=useRef();
const nstatusref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       departmentref.current.value=global1.department;
deptcoderef.current.value=global1.deptcode;
studentref.current.value=global1.student;
regnoref.current.value=global1.regno;
examref.current.value=global1.exam;
pubdateref.current.value=global1.pubdate;
coursecoderef.current.value=global1.coursecode;
courseref.current.value=global1.course;
creditref.current.value=global1.credit;
maxiaref.current.value=global1.maxia;
maxesref.current.value=global1.maxes;
maxtotref.current.value=global1.maxtot;
marksiaref.current.value=global1.marksia;
marksesref.current.value=global1.markses;
markstotref.current.value=global1.markstot;
gpref.current.value=global1.gp;
lgref.current.value=global1.lg;
nstatusref.current.value=global1.nstatus;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const department=departmentref.current.value;
const deptcode=deptcoderef.current.value;
const student=studentref.current.value;
const regno=regnoref.current.value;
const exam=examref.current.value;
const pubdate=pubdateref.current.value;
const coursecode=coursecoderef.current.value;
const course=courseref.current.value;
const credit=creditref.current.value;
const maxia=maxiaref.current.value;
const maxes=maxesref.current.value;
const maxtot=maxtotref.current.value;
const marksia=marksiaref.current.value;
const markses=marksesref.current.value;
const markstot=markstotref.current.value;
const gp=gpref.current.value;
const lg=lgref.current.value;
const nstatus=nstatusref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatemkumarksbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                department:department,
deptcode:deptcode,
student:student,
regno:regno,
exam:exam,
pubdate:pubdate,
coursecode:coursecode,
course:course,
credit:credit,
maxia:maxia,
maxes:maxes,
maxtot:maxtot,
marksia:marksia,
markses:markses,
markstot:markstot,
gp:gp,
lg:lg,
nstatus:nstatus,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnmkumarks');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

Enter Department code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department code" id="deptcode" ref={deptcoderef} />
<br /><br />

Enter Student<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student" id="student" ref={studentref} />
<br /><br />

Enter Reg no<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Reg no" id="regno" ref={regnoref} />
<br /><br />

Enter Examination<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Examination" id="exam" ref={examref} />
<br /><br />

Enter Publishing date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Publishing date" id="pubdate" ref={pubdateref} />
<br /><br />

Enter Course code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Course code" id="coursecode" ref={coursecoderef} />
<br /><br />

Enter Course<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Course" id="course" ref={courseref} />
<br /><br />

Enter Credit<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Credit" id="credit" ref={creditref} />
<br /><br />

Enter Max CA marks<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Max CA marks" id="maxia" ref={maxiaref} />
<br /><br />

Enter Max ES marks<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Max ES marks" id="maxes" ref={maxesref} />
<br /><br />

Enter Max total<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Max total" id="maxtot" ref={maxtotref} />
<br /><br />

Enter Marks obtained CA<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Marks obtained CA" id="marksia" ref={marksiaref} />
<br /><br />

Enter Marks obtained ES<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Marks obtained ES" id="markses" ref={marksesref} />
<br /><br />

Enter Marks obtained total<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Marks obtained total" id="markstot" ref={markstotref} />
<br /><br />

Enter Grade point<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Grade point" id="gp" ref={gpref} />
<br /><br />

Enter LG<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter LG" id="lg" ref={lgref} />
<br /><br />

Enter N<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter N" id="nstatus" ref={nstatusref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;