import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sadditional1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const yearref=useRef();
const programref=useRef();
const programcoderef=useRef();
const studentref=useRef();
const genderref=useRef();
const categoryref=useRef();
const ispwdref=useRef();
const pwdcategoryref=useRef();
const addressref=useRef();
const cityref=useRef();
const districtref=useRef();
const pincoderef=useRef();
const emailref=useRef();
const phoneref=useRef();
const tenthref=useRef();
const tenthboardref=useRef();
const tenthstreamref=useRef();
const twelvethref=useRef();
const twelveboardref=useRef();
const twelvestreamref=useRef();
const extracurref=useRef();
const sportsref=useRef();
const hostelref=useRef();
const fatherref=useRef();
const fphoneref=useRef();
const femailref=useRef();
const motherref=useRef();
const mcontactref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       yearref.current.value=global1.year;
programref.current.value=global1.program;
programcoderef.current.value=global1.programcode;
studentref.current.value=global1.student;
genderref.current.value=global1.gender;
categoryref.current.value=global1.category;
ispwdref.current.value=global1.ispwd;
pwdcategoryref.current.value=global1.pwdcategory;
addressref.current.value=global1.address;
cityref.current.value=global1.city;
districtref.current.value=global1.district;
pincoderef.current.value=global1.pincode;
emailref.current.value=global1.email;
phoneref.current.value=global1.phone;
tenthref.current.value=global1.tenth;
tenthboardref.current.value=global1.tenthboard;
tenthstreamref.current.value=global1.tenthstream;
twelvethref.current.value=global1.twelveth;
twelveboardref.current.value=global1.twelveboard;
twelvestreamref.current.value=global1.twelvestream;
extracurref.current.value=global1.extracur;
sportsref.current.value=global1.sports;
hostelref.current.value=global1.hostel;
fatherref.current.value=global1.father;
fphoneref.current.value=global1.fphone;
femailref.current.value=global1.femail;
motherref.current.value=global1.mother;
mcontactref.current.value=global1.mcontact;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const year=yearref.current.value;
const program=programref.current.value;
const programcode=programcoderef.current.value;
const student=studentref.current.value;
const gender=genderref.current.value;
const category=categoryref.current.value;
const ispwd=ispwdref.current.value;
const pwdcategory=pwdcategoryref.current.value;
const address=addressref.current.value;
const city=cityref.current.value;
const district=districtref.current.value;
const pincode=pincoderef.current.value;
const email=emailref.current.value;
const phone=phoneref.current.value;
const tenth=tenthref.current.value;
const tenthboard=tenthboardref.current.value;
const tenthstream=tenthstreamref.current.value;
const twelveth=twelvethref.current.value;
const twelveboard=twelveboardref.current.value;
const twelvestream=twelvestreamref.current.value;
const extracur=extracurref.current.value;
const sports=sportsref.current.value;
const hostel=hostelref.current.value;
const father=fatherref.current.value;
const fphone=fphoneref.current.value;
const femail=femailref.current.value;
const mother=motherref.current.value;
const mcontact=mcontactref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateadmapplnewbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                year:year,
program:program,
programcode:programcode,
student:student,
gender:gender,
category:category,
ispwd:ispwd,
pwdcategory:pwdcategory,
address:address,
city:city,
district:district,
pincode:pincode,
email:email,
phone:phone,
tenth:tenth,
tenthboard:tenthboard,
tenthstream:tenthstream,
twelveth:twelveth,
twelveboard:twelveboard,
twelvestream:twelvestream,
extracur:extracur,
sports:sports,
hostel:hostel,
father:father,
fphone:fphone,
femail:femail,
mother:mother,
mcontact:mcontact,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnadmapplnew');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Academic year<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Academic year" id="year" ref={yearref} />
<br /><br />

Enter Program<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program" id="program" ref={programref} />
<br /><br />

Enter Program code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program code" id="programcode" ref={programcoderef} />
<br /><br />

Enter Student<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Student" id="student" ref={studentref} />
<br /><br />

<label htmlFor="gender">Gender</label>

<select id="gender" className="form-control"  ref={genderref}>
<option value="Male">Male</option>
<option value="Female">Female</option>
<option value="Others">Others</option>
</select>
<br /><br />

<label htmlFor="category">Category</label>

<select id="category" className="form-control"  ref={categoryref}>
<option value="General">General</option>
<option value="SC">SC</option>
<option value="ST">ST</option>
<option value="OBC">OBC</option>
<option value="OBA A">OBA A</option>
<option value="OBC B">OBC B</option>
</select>
<br /><br />

<label htmlFor="ispwd">Is differently abled</label>

<select id="ispwd" className="form-control"  ref={ispwdref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Differently abled category<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Differently abled category" id="pwdcategory" ref={pwdcategoryref} />
<br /><br />

Enter Address<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address" id="address" ref={addressref} />
<br /><br />

Enter City<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter City" id="city" ref={cityref} />
<br /><br />

Enter State<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter State" id="district" ref={districtref} />
<br /><br />

Enter Pincode<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Pincode" id="pincode" ref={pincoderef} />
<br /><br />

Enter Email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email" id="email" ref={emailref} />
<br /><br />

Enter Phone<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Phone" id="phone" ref={phoneref} />
<br /><br />

Enter Tenth aggregate marks<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Tenth aggregate marks" id="tenth" ref={tenthref} />
<br /><br />

Enter Tenth board<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Tenth board" id="tenthboard" ref={tenthboardref} />
<br /><br />

Enter Tenth Stream<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Tenth Stream" id="tenthstream" ref={tenthstreamref} />
<br /><br />

Enter Twelveth aggregate marks<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Twelveth aggregate marks" id="twelveth" ref={twelvethref} />
<br /><br />

<label htmlFor="twelveboard">Twelveth board</label>

<select id="twelveboard" className="form-control"  ref={twelveboardref}>
<option value="CBSE">CBSE</option>
<option value="AISSCE">AISSCE</option>
<option value="ISC">ISC</option>
<option value="State boards">State boards</option>
</select>
<br /><br />

Enter Twelveth stream<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Twelveth stream" id="twelvestream" ref={twelvestreamref} />
<br /><br />

Enter Extra curricular activities<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Extra curricular activities" id="extracur" ref={extracurref} />
<br /><br />

Enter Sports activities<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Sports activities" id="sports" ref={sportsref} />
<br /><br />

<label htmlFor="hostel">Hostel required</label>

<select id="hostel" className="form-control"  ref={hostelref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Father name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Father name" id="father" ref={fatherref} />
<br /><br />

Enter Father phone<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Father phone" id="fphone" ref={fphoneref} />
<br /><br />

Enter Father email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Father email" id="femail" ref={femailref} />
<br /><br />

Enter Mother name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mother name" id="mother" ref={motherref} />
<br /><br />

Enter Mother contact<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mother contact" id="mcontact" ref={mcontactref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;