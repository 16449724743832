import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Mexam';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const departmentref=useRef();
const deptcoderef=useRef();
const programref=useRef();
const programcoderef=useRef();
const studentref=useRef();
const studenttamilref=useRef();
const regnoref=useRef();
const dobref=useRef();
const sexref=useRef();
const pwdref=useRef();
const communityref=useRef();
const comcertref=useRef();
const aadharref=useRef();
const aspdistrictref=useRef();
const rpref=useRef();
const nriref=useRef();
const mobileref=useRef();
const emailref=useRef();
const scholarshipref=useRef();
const fellowshipref=useRef();
const internshipref=useRef();
const selfcourseref=useRef();
const guardianref=useRef();
const guardoccuref=useRef();
const motherref=useRef();
const addressref=useRef();
const guardmobref=useRef();
const guardemailref=useRef();
const anyotherref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       departmentref.current.value=global1.department;
deptcoderef.current.value=global1.deptcode;
programref.current.value=global1.program;
programcoderef.current.value=global1.programcode;
studentref.current.value=global1.student;
studenttamilref.current.value=global1.studenttamil;
regnoref.current.value=global1.regno;
dobref.current.value=global1.dob;
sexref.current.value=global1.sex;
pwdref.current.value=global1.pwd;
communityref.current.value=global1.community;
comcertref.current.value=global1.comcert;
aadharref.current.value=global1.aadhar;
aspdistrictref.current.value=global1.aspdistrict;
rpref.current.value=global1.rp;
nriref.current.value=global1.nri;
mobileref.current.value=global1.mobile;
emailref.current.value=global1.email;
scholarshipref.current.value=global1.scholarship;
fellowshipref.current.value=global1.fellowship;
internshipref.current.value=global1.internship;
selfcourseref.current.value=global1.selfcourse;
guardianref.current.value=global1.guardian;
guardoccuref.current.value=global1.guardoccu;
motherref.current.value=global1.mother;
addressref.current.value=global1.address;
guardmobref.current.value=global1.guardmob;
guardemailref.current.value=global1.guardemail;
anyotherref.current.value=global1.anyother;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const department=departmentref.current.value;
const deptcode=deptcoderef.current.value;
const program=programref.current.value;
const programcode=programcoderef.current.value;
const student=studentref.current.value;
const studenttamil=studenttamilref.current.value;
const regno=regnoref.current.value;
const dob=dobref.current.value;
const sex=sexref.current.value;
const pwd=pwdref.current.value;
const community=communityref.current.value;
const comcert=comcertref.current.value;
const aadhar=aadharref.current.value;
const aspdistrict=aspdistrictref.current.value;
const rp=rpref.current.value;
const nri=nriref.current.value;
const mobile=mobileref.current.value;
const email=emailref.current.value;
const scholarship=scholarshipref.current.value;
const fellowship=fellowshipref.current.value;
const internship=internshipref.current.value;
const selfcourse=selfcourseref.current.value;
const guardian=guardianref.current.value;
const guardoccu=guardoccuref.current.value;
const mother=motherref.current.value;
const address=addressref.current.value;
const guardmob=guardmobref.current.value;
const guardemail=guardemailref.current.value;
const anyother=anyotherref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatemkustudbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                department:department,
deptcode:deptcode,
program:program,
programcode:programcode,
student:student,
studenttamil:studenttamil,
regno:regno,
dob:dob,
sex:sex,
pwd:pwd,
community:community,
comcert:comcert,
aadhar:aadhar,
aspdistrict:aspdistrict,
rp:rp,
nri:nri,
mobile:mobile,
email:email,
scholarship:scholarship,
fellowship:fellowship,
internship:internship,
selfcourse:selfcourse,
guardian:guardian,
guardoccu:guardoccu,
mother:mother,
address:address,
guardmob:guardmob,
guardemail:guardemail,
anyother:anyother,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnmkustud');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

Enter Department code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department code" id="deptcode" ref={deptcoderef} />
<br /><br />

Enter Program<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program" id="program" ref={programref} />
<br /><br />

Enter Program code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Program code" id="programcode" ref={programcoderef} />
<br /><br />

Enter Name of student<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of student" id="student" ref={studentref} />
<br /><br />

Enter Name in Tamil<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name in Tamil" id="studenttamil" ref={studenttamilref} />
<br /><br />

Enter Registration no<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Registration no" id="regno" ref={regnoref} />
<br /><br />

Enter Date of birth<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of birth" id="dob" ref={dobref} />
<br /><br />

<label htmlFor="sex">Sex</label>

<select id="sex" className="form-control"  ref={sexref}>
<option value="Male">Male</option>
<option value="Female">Female</option>
<option value="Transgender">Transgender</option>
</select>
<br /><br />

<label htmlFor="pwd">If PWD</label>

<select id="pwd" className="form-control"  ref={pwdref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Community<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Community" id="community" ref={communityref} />
<br /><br />

Enter Community certificate number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Community certificate number" id="comcert" ref={comcertref} />
<br /><br />

Enter Aadhar<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Aadhar" id="aadhar" ref={aadharref} />
<br /><br />

<label htmlFor="aspdistrict">If from any aspirational district</label>

<select id="aspdistrict" className="form-control"  ref={aspdistrictref}>
<option value="Yes">Yes</option>
<option value="No">No</option>
</select>
<br /><br />

Enter Rp<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Rp" id="rp" ref={rpref} />
<br /><br />

Enter NRI<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter NRI" id="nri" ref={nriref} />
<br /><br />

Enter Mobile<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mobile" id="mobile" ref={mobileref} />
<br /><br />

Enter Email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Email" id="email" ref={emailref} />
<br /><br />

Enter Scholarship details<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Scholarship details" id="scholarship" ref={scholarshipref} />
<br /><br />

Enter Fellowship details<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Fellowship details" id="fellowship" ref={fellowshipref} />
<br /><br />

Enter Internship details<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Internship details" id="internship" ref={internshipref} />
<br /><br />

Enter Self study course<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Self study course" id="selfcourse" ref={selfcourseref} />
<br /><br />

Enter Guardian name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Guardian name" id="guardian" ref={guardianref} />
<br /><br />

Enter Guardian occupation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Guardian occupation" id="guardoccu" ref={guardoccuref} />
<br /><br />

Enter Mother name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mother name" id="mother" ref={motherref} />
<br /><br />

Enter Address<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Address" id="address" ref={addressref} />
<br /><br />

Enter Guardian mobile<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Guardian mobile" id="guardmob" ref={guardmobref} />
<br /><br />

Enter Guardian email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Guardian email" id="guardemail" ref={guardemailref} />
<br /><br />

Enter Any other information<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Any other information" id="anyother" ref={anyotherref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;