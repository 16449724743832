import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sproject';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const projectref=useRef();
const taskref=useRef();
const subtaskref=useRef();
const startdateref=useRef();
const duedateref=useRef();
const assignedtoref=useRef();
const statusref=useRef();
const percentref=useRef();
const issuesref=useRef();
const dependencyidref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       projectref.current.value=global1.project;
taskref.current.value=global1.task;
subtaskref.current.value=global1.subtask;
startdateref.current.value=global1.startdate;
duedateref.current.value=global1.duedate;
assignedtoref.current.value=global1.assignedto;
statusref.current.value=global1.status;
percentref.current.value=global1.percent;
issuesref.current.value=global1.issues;
dependencyidref.current.value=global1.dependencyid;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const project=projectref.current.value;
const task=taskref.current.value;
const subtask=subtaskref.current.value;
const startdate=startdateref.current.value;
const duedate=duedateref.current.value;
const assignedto=assignedtoref.current.value;
const status=statusref.current.value;
const percent=percentref.current.value;
const issues=issuesref.current.value;
const dependencyid=dependencyidref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updatenprojectsnewbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                project:project,
task:task,
subtask:subtask,
startdate:startdate,
duedate:duedate,
assignedto:assignedto,
status:status,
percent:percent,
issues:issues,
dependencyid:dependencyid,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnnprojectsnew');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Project<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Project" id="project" ref={projectref} />
<br /><br />

Enter Task<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Task" id="task" ref={taskref} />
<br /><br />

Enter Sub task<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Sub task" id="subtask" ref={subtaskref} />
<br /><br />

Enter Start date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Start date" id="startdate" ref={startdateref} />
<br /><br />

Enter Due date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Due date" id="duedate" ref={duedateref} />
<br /><br />

Enter Assigned to<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Assigned to" id="assignedto" ref={assignedtoref} />
<br /><br />

Enter Status<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Status" id="status" ref={statusref} />
<br /><br />

Enter Percentage completed<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Percentage completed" id="percent" ref={percentref} />
<br /><br />

Enter Issues<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Issues" id="issues" ref={issuesref} />
<br /><br />

Enter Dependent on task<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Dependent on task" id="dependencyid" ref={dependencyidref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;