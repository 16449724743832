import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        {/* <Link to='/viewnacademic'> Academic calendar</Link>
        <Link to='/viewnworkbook'> Personal workbook</Link>
        <Link to='/viewnenrollment'> Enrollment</Link>
        <Link to='/viewncourseplan'> Course plan</Link> */}



<br />
        <p><b>Part A-Basic Information</b></p>
           <hr />
<Link to='/viewnppuclgdetailsadmin'> 1. College/Institution</Link>
<Link to='/viewnppuheaddetailsadmin'> 2. Head of Institution(Director/Dean/Principal)</Link>
<Link to='/viewnppulegalrepadmin'> 3. Legally authorised representatíve for communicatíon </Link>
<Link to='/viewnpputrustdetailsadmin'> 4. Trust</Link>
<Link to='/viewnppupermissionadmin'> 5. Permission letter obtained from the State Government to start the college</Link>
<Link to='/viewnppumanagingbodyadmin'> 6. Members of the Managing Body</Link>
<Link to='/viewnppuwlfcomtadmin'> 7. Welfare Committee</Link>
<Link to='/viewnpputrustfinadmin'> 8.a. Financial Stability-Financial status of Trust</Link>
<Link to='/viewnppufdrdetailsadmin'> 8.b. Financial Stability-FDR Details</Link>
<Link to='/viewnppuimmvpropadmin'> 8.c. Financial Stability-Value of immovable properties (GLV and MV )</Link>
<Link to='/viewnppufinanceadmin'> 8.d.& e. Financial Stability-Endowment & PAN</Link>
<Link to='/viewnppulandadmin'> 9. Details of the Land earmarked for the College</Link>
<Link to='/viewnppubuildingadmin'> 10. Building</Link>
<Link to='/viewnppuhosteladmin'> 11. Hostel</Link>
<Link to='/viewnppuroomsadmin'> 11. Rooms</Link>
<Link to='/viewnppuphyeduadmin'> 12. Physical Education</Link>
<Link to='/viewnppustaffadmin'> 13. Details of Staff Quarters</Link>
<br />
        <p><b>Part B-Programme Details</b></p>
           <hr />
<Link to='/viewnppucollegeadmin'> 14. College Details </Link>
<Link to='/viewnpputrustadmin'> 15. Trust Details</Link>
<Link to='/viewnppuaddprogadmin'> 16.a. Additional Programme(s) for which provisional affiliation is sougnt</Link>
<Link to='/viewnppuvarintakeadmin'> 16.b. Variation in intake in the existing Programme(s) for which provisional affiliation is sought</Link>
<Link to='/viewnppuexistprogadmin'> 16.c. Details of existing provisionally affiliated programme for which continuation of provisional affiliation is sought</Link>
<Link to='/viewnppucoursesadmin'> 16.d. Courses being run by the College as of now</Link>
<Link to='/viewnppucoursrepadmin'> 16.d. Additional Information</Link>
<Link to='/viewnppuaddinfoprogadmin'> 17. Additional Information-Rationales</Link>
<Link to='/viewnppuaddinfonocadmin'> 17. Additional Information-NOC</Link>
<Link to='/viewnppuaddinfopermissionadmin'> 17. Additional Information-Permissions/Approvals/Recognitions</Link>
<Link to='/viewnppuaddinfobosadmin'> 17. Additional Information-BOS</Link>
<Link to='/viewnppuaddinfosyllabiadmin'> 17. Additional Information-Syllabi</Link>
<Link to='/viewnppuprincipaladmin'> 18.a. Details of Directorl/Dean/Principal</Link>
<Link to='/viewnppufacultyadmin'> 18.b. Details of Teaching staff</Link>
<Link to='/viewnppustafstudratioadmin'> 18.c. Staff-Student ratio</Link>
<Link to='/viewnppuaddinfofacadmin'> 18.d. Additional Information on Teachers</Link>
<Link to='/viewnppuaddinfostaffdetailsadmin'> 18.d. Additional Information on Teachers-Additional Staff Details</Link>
<Link to='/viewnppuaddfacdetailsadmin'> 18.d. Additional Information on Teachers-Available Staff Details</Link>
<Link to='/viewnppufacworkloadadmin'> 18.d. Additional Information on Teachers-Workload of Teaching Staff</Link>
<Link to='/viewnppulabequipadmin'> 19.a. List of Equipment</Link>
<Link to='/viewnppulabequipprocureadmin'> 19.b. List of equipment to be procured for new program</Link>
<Link to='/viewnppulectureroomsadmin'> 20. Lecture rooms for new programme</Link>
<Link to='/viewnppuavailclsroomadmin'> 20. Additional Information</Link>
<Link to='/viewnppunetworkadmin'> 21. Network Connectivity</Link>
<Link to='/viewnppucompfaciadmin'> 22. Computing Facility</Link>
<Link to='/viewnppucompsoftwareadmin'> 22. Software Details</Link>
<Link to='/viewnppucompaddinfoadmin'> 22. Additional Information</Link>
<Link to='/viewnppucompaddsoftadmin'> 22. Additional softwares</Link>
<Link to='/viewnppulibraryadmin'> 23.i-v. Library Facility</Link>
<Link to='/viewnppulibstaffadmin'> 23.iv. Details of Other staff members</Link>
<Link to='/viewnppulibbooksadmin'> 23.vi. Library Books</Link>
<Link to='/viewnppulibindjrnladmin'> 23.vi. Library Indian Journals</Link>
<Link to='/viewnppulibforgjrnladmin'> 23.vi. Library Foreign Journals</Link>
<Link to='/viewnppulibautomationadmin'> 23.vii. Library Automation</Link>
<Link to='/viewnppulibprocureadmin'> 23.viii. Library Procurement</Link>
<Link to='/viewnppulibexpadmin'> 23.ix. Library Expenditure</Link>
<Link to='/viewnppustudfaciadmin'> 24.a-m. Facilities for students</Link>
<Link to='/viewnppumedserviceadmin'> 24.n. Medical Services</Link>
<Link to='/viewnppuotherfaciadmin'> 24.o-u. Other facilities</Link>
<Link to='/viewnppuaudvidequipadmin'> 24.p. Audio-Visual Equipments</Link>
<Link to='/viewnppuclubsadmin'> 24.q. Association/Clubs</Link>
<Link to='/viewnpputechstaffadmin'> 25.a. Details of Technical Staff</Link>
<Link to='/viewnppuminststaffadmin'> 25.b. Details of Ministerial Staff</Link>
<Link to='/viewnppuaddinfoadmin'> 26. Additional Information</Link>
<Link to='/viewnppudeclarationadmin'> 26. Declaration</Link>
<br />
        <p><b>Part C</b></p>
           <hr />
<Link to='/viewnppucertificatesadmin'> 27. Certificates</Link>
        <br />




<br /><br /><br /><br />


      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;