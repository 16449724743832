import ep1 from '../api/ep3';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faUpload, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Sidecr1Navigation from '../components/layout/Slms';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const apikeyref=useRef();
    const passwordref=useRef();
    const promptref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [aitext, setAitext] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    


    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const edit = (id, coursecode,coursename,co,cocode,status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.addonid=id;
        global1.coursecode=coursecode;
global1.coursename=coursename;
global1.co=co;
global1.cocode=cocode;

        history.replace('/editco');
    }

  


  

    const upload = (id) => {
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.2';
        global1.collection1='addonc';
        global1.type='BOT';
        global1.pagenav='viewco';
        
        history.replace('/selectawsconfig');
    }

    

    

   

    let tutorials = [];
    const searchapi = async () => {

        const apikey=apikeyref.current.value;
        const password=passwordref.current.value;
        const prompt=promptref.current.value;

        if(!apikey || !password || !prompt) {
            alert('All fields are required for this');
            return;

        }
        if(password=='kumropatash') {

        } else {
            alert('Invalid password');
            return;
        }
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getaicontent', {
            params: {
                user: user,
                token: token,
                colid: colid,
                apikey:apikey,
                prompt:prompt
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_addoncourse", JSON.stringify(response.data.data.classes));

       
        setAitext(response.data.data.aitext);

        const aiarray=response.data.data.aitext.split('\n');
        setResults2(aiarray);
        
        // setResults(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
       
        
       
        //alert(results.length);
       
    };

   

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid, status1) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletecobyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        //searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
        global1.pagenav='viewco';
        history.replace('/thankyouafter');
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_addoncourse");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    

    useEffect(() => {
        checklogin();

        const p1="Write a detailed report on " + global1.eventname + " conducted at " + global1.insname + " on " + global1.eventdate;

        promptref.current.value=p1 +  ", " + global1.description;
        
        //+ ". " + global1.description;
        //search1();
        //getexamlist();
        //getlocal();
        //searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


 
    
      const list1 = async (id1) => {
        //var link1='http://localhost:3001/viewsupportingdocquery';
        var link1=window.location.origin + '/viewsupportingdocquery';
        link1=link1 + '?field1=' + id1 + '&colid=' + colid + '&user=' + user + '&name=' + name + '&token=' + token; 
        var win = window.open(link1, '_blank');
        win.focus();
      };



      //start

      
      const uploadreport = (id,title, fromto) => {
        // if(status1=='Accepted') {
        //     alert('This document is accepted. Please contact IQAC coordinator to allow editing');
        //     return;
        // }
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.4';
        global1.collection1='explearning';
        global1.type='Experiential Learning';
        global1.pagenav='viewprojects';
        
        //var funds1=funds + '-' + funds.toLocaleString('en-IN');

        global1.datatocheck=title + '`' + fromto;

        global1.type='Report';
        global1.pagenav='viewevent';
        
        history.replace('/selectawspdf');
    }

    const uploadcircular = (id, title) => {
        
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.4';
        global1.collection1='explearning';
        global1.type='BOT';
        global1.pagenav='viewprojects';

        global1.datatocheck=title + '`circular';

        global1.type='Circular';
        global1.pagenav='viewevent';
        
        history.replace('/selectawsconfignew');
    }

    const uploadcertificates = (id, title) => {
        
        global1.field1=id;
        global1.criteria='1';
        global1.metric='1.3.4';
        global1.collection1='explearning';
        global1.type='BOT';
        global1.pagenav='viewprojects';

        global1.datatocheck=title;

        global1.type='Certificate';
        global1.pagenav='viewevent';
        
        history.replace('/selectawsconfignew');
    }


    const formatdates = (date1) => {
          var dt1=new Date(date1);
          var month=dt1.getMonth() + 1;
          return dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
      }




    const width=window.innerWidth;
    const mdivwidth=width - 130;




    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    {/* <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td> */}
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr1Navigation />
                    </td> */}
                   
                    <td> 
                        {/* <TopNavigation /> */}

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            {/* <p style={{ fontSize: 18 }}>View data</p> */}
            <p style={{ fontSize: 18 }}>Generate AI Content</p>
            <hr />

                 
            Enter API Key<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter api key" id="apikey" ref={apikeyref} />
<br /><br />

Enter password<br /><br />

<input type="password" required style={{width: "100%"}} placeholder="Enter password" id="password" ref={passwordref} />
<br /><br />

Please provide as much information as possible in one line description<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter prompt" id="prompt" ref={promptref} />
<br /><br />
           
                
<button  onClick={searchapi}>Get AI Content</button>
            
    
        <br />
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<h4>AI content</h4>


{results2.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        {meetup31}
                        <br />
                      
                        </div>

                    );
                })}




            <br /><br />

            {aitext}

      <br /><br />

            

       
        
        </div>
        {/* <FooterNav1 /> */}
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;