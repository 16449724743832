import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    //const name=global1.name;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewworkload = () => {
        history.replace('/addworkload');
    }

    const addnewworkloadbulk = () => {
        history.replace('/addworkloadbulk');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = (courseid, coursecode, coursename, section, semester, program, programcode, year) => {
        global1.courseid=courseid;
        global1.coursecode=coursecode;
        global1.coursename=coursename;
        global1.section=section;
        global1.semester=semester;
        global1.programname=program;
        global1.programcode=programcode;
        global1.lmsyear=year;

        //history.replace('/enrollstudents');
        history.replace('/courseactions');
    }

    
const aqaryear=global1.aqaryear;
const lmsyear=global1.lmsyear;
   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        //alert(user + ' ' + aqaryear);
        const response = await ep1.get('/api/v1/getworkloadbyfaca', {
            params: {
                user: user,
                token: token,
                year: lmsyear

            }

        });
        setLoading(false);
        console.log(response.data.data);
        localStorage.setItem("dashcourse", JSON.stringify(response.data.data.classes));
        setResults(response.data.data.classes);
        favcontxt.addFavorite({
            studid: user,
            name: name,
        },role,colid,name,response.data.data.classes.length);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteworkload = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteworkloadapi', {
            params: {
                id: periodid,
                token: token
            }

        });
        setLoading(false);
        //alert('deleted');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("dashcourse");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //getlocal();
        searchApi();
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    function checkdata(link, ccode) {
        //alert (examcode + '-' + examname);
        if (link=='NA') {
            link="https://meet.jit.si/" + ccode;
        }
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDragStart = (ev, ccode,  course, section, semester, program) => {
        //ev.preventDefault();
        ev.dataTransfer.setData("ccode", ccode);
        ev.dataTransfer.setData("course", course);
        ev.dataTransfer.setData("section", section);
        ev.dataTransfer.setData("semester", semester);
        ev.dataTransfer.setData("program", program);
        //setCoursecode(ccode);
    }
    const onDrop = async (ev, starttime, endtime) => {
        let ccode = ev.dataTransfer.getData("ccode");
        let course = ev.dataTransfer.getData("course");
        let section = ev.dataTransfer.getData("section");
        let semester = ev.dataTransfer.getData("semester");
        let program = ev.dataTransfer.getData("program");
        const module=moduleref.current.value;
        const link=linkref.current.value;
        const topic=topicref.current.value;
        if(!module) {
            alert("Please enter module");
            return;
        }
        if(!topic) {
            alert("Please enter topic");
            return;
        }
        if(!link) {
            alert("Please enter link");
            return;
        }
        
        setCoursecode(ccode);
        const response = await ep1.get('/api/v1/createclass', {
            params: {
                user: user,
                name: name,
                colid: colid,
                classdate: datechange,
                weeks: 0,
                coursecode: ccode,
                course: course,
                link: link,
                program: program,
                semester: semester,
                section: section,
                module: module,
                topic: topic,
                classtime: starttime



            }

        });
        if (response.data.status == "Success") {
            
            //history.replace('/workload');
            //setTerm2('Thank you');  
            getclass();
            alert('Data updated successfully');
        }
        //setResults(response.data.data.classes);

        //alert(ccode + '-' + starttime + '-' + endtime + '-' + datechange + '-' + module + '-' + topic + '-' + link);

    }

    async function refreshclass(newdate) {
        
        var dt1=new Date(newdate);
        setDate2(dt1);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
    }

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            {/* <div style={{float: 'left', width: 230 }}>
            

            </div> */}
            <table style={{ width: '100%'}}>
                <tr>
                <td valign="top" style={{ backgroundColor: ':rgba(222, 222, 224, 0.377)', width: 60, borderRightColor: '#fff', borderRightWidth: 1 }}>
                    <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: ':rgba(130, 130, 133, 0.377)', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
    {/* <SidefacNavigation /> */}
    <SidecolorNavigation />
    
                    </td>
                    
                    <td> 

                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{fontSize: 18 }}>Home - My Courses</p>
            <hr />
           
    
        <br />
        

        

        <div className="container">
                <div className="row">
                    <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                        //   onClick={() => addnewworkload()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 30, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Please contact HoD to Add Course</p>

                        </div>
                </div>
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addnewworkload()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 30, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Course</p>

                        </div>
                </div> */}
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addnewworkloadbulk()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faPlusSquare} style={{fontSize: 30, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Course (Bulk)</p>

                        </div>
                </div> */}
                {results.map((meetup3) => {
                return (
                    <div className="col-4">
                         <div className={classes1.card}>
                         Program {meetup3.program} (Programcode {meetup3.programcode}) {meetup3.year} Course {meetup3.course} (Coursecode {meetup3.coursecode})  <i> Section {meetup3.section} Semester {meetup3.semester}</i>
                   
                    <br /><br />
                    <div className={classes1.actions}>
                    
                    
                                    <button type="button" onClick={() => addstudents(meetup3._id, meetup3.coursecode, meetup3.course, meetup3.section, meetup3.semester, meetup3.program,meetup3.programcode,meetup3.year)}>
                                    <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                                        Course Dashboard</button>

                                        {/* <button type="button" onClick={() => deleteworkload(meetup3._id)}>
                                    <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                                        Delete</button> */}
                                </div>
                    </div>
                    </div>

                );
            })}
            </div></div>

            

       
        
        </div>

        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;