import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sfeb';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const volreqref=useRef();
const volavailref=useRef();
const voldefref=useRef();
const titlereqref=useRef();
const titleavailref=useRef();
const titledefref=useRef();
const voladdreqref=useRef();
const voladdavailref=useRef();
const voladddefref=useRef();
const totalreqref=useRef();
const totalavailref=useRef();
const totaldefref=useRef();
const alldefref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        //const volreq=volreqref.current.value;
        const noofugcourses=parseFloat(global1.noofugcourses);
        const noofpgcourses=parseFloat(global1.noofpgcourses);
        const noofmbacourses=parseFloat(global1.noofmbacourses);
        const noofmcacourses=parseFloat(global1.noofmcacourses);
        const noofyears=parseFloat(global1.noofyears);
        const volreq=Math.ceil((noofugcourses*250*4)+(noofpgcourses*150*2)+(noofmbacourses*500*2)+noofyears*(noofmcacourses*500*2)+(noofpgcourses*10));                 
        const volavail=volavailref.current.value;
        //const voldef=voldefref.current.value;
        const voldef=(volreq-volavail)*1.0/volreq*100;
        const titlereq=Math.ceil((noofugcourses*50)+(noofpgcourses*50));
        const titleavail=titleavailref.current.value;
        //const titledef=titledefref.current.value;
        const titledef=(titlereq-titleavail)*1.0/titlereq*100;
        const voladdreq=Math.ceil((noofugcourses*20)+(noofpgcourses*10));
        const voladdavail=voladdavailref.current.value;
        //const voladddef=voladddefref.current.value;
        const voladddef=(titlereq-titleavail)*1.0/titlereq*100;
        const totalreq=Math.ceil(volreq+titlereq+voladdreq);
        const totalavail=totalavailref.current.value;
        //const totaldef=totaldefref.current.value;
        const totaldef=(totalreq-totalavail)*1.0/totalreq*100;
        const alldef=Math.ceil(voldef+titledef+voladddef+totaldef);

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createpaunlibbooksbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                volreq:volreq,
volavail:volavail,
voldef:voldef,
titlereq:titlereq,
titleavail:titleavail,
titledef:titledef,
voladdreq:voladdreq,
voladdavail:voladdavail,
voladddef:voladddef,
totalreq:totalreq,
totalavail:totalavail,
totaldef:totaldef,
alldef:alldef,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnpaunlibbooks');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter No.of volumes required<br /><br />

<input type="number" readOnly required style={{width: "100%"}} placeholder="Enter No.of volumes required" id="volreq" ref={volreqref} />
<br /><br />

Enter No.of volumes available<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter No.of volumes available" id="volavail" ref={volavailref} />
<br /><br />

{/* Enter Volume deficiency<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Volume deficiency" id="voldef" ref={voldefref} />
<br /><br /> */}

Enter No.of titles required<br /><br />

<input type="number" readOnly required style={{width: "100%"}} placeholder="Enter No.of titles required" id="titlereq" ref={titlereqref} />
<br /><br />

Enter No.of titles available<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter No.of titles available" id="titleavail" ref={titleavailref} />
<br /><br />

{/* Enter Titles deficiency<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Titles deficiency" id="titledef" ref={titledefref} />
<br /><br /> */}

Enter No.of volumes added for current year required<br /><br />

<input type="number" readOnly required style={{width: "100%"}} placeholder="Enter No.of volumes added for current year required" id="voladdreq" ref={voladdreqref} />
<br /><br />

Enter No.of volumes added for current year available<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter No.of volumes added for current year available" id="voladdavail" ref={voladdavailref} />
<br /><br />

{/* Enter Volumes added deficiency<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Volumes added deficiency" id="voladddef" ref={voladddefref} />
<br /><br /> */}

Enter Total no.of volumes required<br /><br />

<input type="number"  readOnly required style={{width: "100%"}} placeholder="Enter Total no.of volumes required" id="totalreq" ref={totalreqref} />
<br /><br />

Enter Total no.of volumes available<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total no.of volumes available" id="totalavail" ref={totalavailref} />
<br /><br />

{/* Enter Total volumes defifciency<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total volumes defifciency" id="totaldef" ref={totaldefref} />
<br /><br /> */}

{/* Enter Overall deficiency<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Overall deficiency" id="alldef" ref={alldefref} />
<br /><br /> */}




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;