import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sproject';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const presenterref=useRef();
const pemailref=useRef();
const meetingidref=useRef();
const respondentref=useRef();
const remailref=useRef();
const rphoneref=useRef();
const submitteddateref=useRef();
const knowledgeref=useRef();
const communicationref=useRef();
const confidenceref=useRef();
const understoodref=useRef();
const satisfiedref=useRef();
const responseref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const presenter=presenterref.current.value;
const pemail=pemailref.current.value;
const meetingid=meetingidref.current.value;
const respondent=respondentref.current.value;
const remail=remailref.current.value;
const rphone=rphoneref.current.value;
const submitteddate=submitteddateref.current.value;
const knowledge=knowledgeref.current.value;
const communication=communicationref.current.value;
const confidence=confidenceref.current.value;
const understood=understoodref.current.value;
const satisfied=satisfiedref.current.value;
const response=responseref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response1 = await ep1.get('/api/v1/createcnewfeedbackbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                presenter:presenter,
pemail:pemail,
meetingid:meetingid,
respondent:respondent,
remail:remail,
rphone:rphone,
submitteddate:submitteddate,
knowledge:knowledge,
communication:communication,
confidence:confidence,
understood:understood,
satisfied:satisfied,
response:response,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewncnewfeedback');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Presenter<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Presenter" id="presenter" ref={presenterref} />
<br /><br />

Enter Presenter email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Presenter email" id="pemail" ref={pemailref} />
<br /><br />

Enter Meeting id<br /><br />

<input type="String" required style={{width: "100%"}} placeholder="Enter Meeting id" id="meetingid" ref={meetingidref} />
<br /><br />

Enter Your name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Your name" id="respondent" ref={respondentref} />
<br /><br />

Enter Your email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Your email" id="remail" ref={remailref} />
<br /><br />

Enter Your phone<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Your phone" id="rphone" ref={rphoneref} />
<br /><br />

Enter Submitted on<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Submitted on" id="submitteddate" ref={submitteddateref} />
<br /><br />

Enter Rate the knowledge of the speaker<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter Rate the knowledge of the speaker" id="knowledge" ref={knowledgeref} />
<br /><br />

Enter How effective was the communication of the speaker<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter How effective was the communication of the speaker" id="communication" ref={communicationref} />
<br /><br />

Enter How do you rate the confidence of the speaker in answering questions<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter How do you rate the confidence of the speaker in answering questions" id="confidence" ref={confidenceref} />
<br /><br />

Enter Have you understood the topic<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter Have you understood the topic" id="understood" ref={understoodref} />
<br /><br />

Enter How satisfied are you for overall presentation<br /><br />

<input type="radio" required style={{width: "100%"}} placeholder="Enter How satisfied are you for overall presentation" id="satisfied" ref={satisfiedref} />
<br /><br />

Enter Any other note or comments<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Any other note or comments" id="response" ref={responseref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;