import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faFile } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;

function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const topicref=useRef();
    const departmentref=useRef();
  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [results, setResults] = useState([]);

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;

    const username=global1.username;
    const password=global1.password;
    const region=global1.region;
    const bucket=global1.bucket;

    const criteria=global1.criteria;
    const metric=global1.metric;
    const type=global1.type;
    const description=global1.description;
    const collection1=global1.collection1;
    const field1=global1.field1;

    const gobacknav = () => {
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
        
    };


    // const config = {
    //     bucketName: 'jadavpuruniversity',
    //     // dirName: 'media', /* optional */
    //     // region: 'us-east-2',
    //     region: 'ap-south-1',
    //     accessKeyId: 'AKIAUAC655EBDFT6YKIL',
    //     secretAccessKey: 'a7jpUecFZi5f8GCLhU8HJD9lsG9fSCF5DjIWKYXo',
    //     // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    // }

    const config = {
        bucketName: bucket,
        // dirName: 'media', /* optional */
        // region: 'us-east-2',
        region: region,
        accessKeyId: username,
        secretAccessKey: password,
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }
    
    const ReactS3Client = new S3(config);
    /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */
    
    /* This is optional */
    //var dt1=new Date();
    

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       if (!firebase.apps.length) { //avoid re-initializing
        firebase.initializeApp({
            apiKey: "AIzaSyD4AZlzFUmachQCJYr-vlb9XKJix3NMg-E",
            authDomain: "posto-campus.firebaseapp.com",
            databaseURL: "https://posto-campus.firebaseio.com",
            projectId: "posto-campus",
            storageBucket: "posto-campus.appspot.com",
            messagingSenderId: "1002415317254",
            appId: "1:1002415317254:web:f198e146e4c2a9ab86fe87",
            measurementId: "G-W6SP1NZPMJ"
        });
    }

    getdata1();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    // const submit1 = () => {
    //     ReactS3Client
    // .uploadFile(selectedFile, newFileName)
    // .then(data => console.log(data))
    // .catch(err => console.error(err))
    // alert('ok');
    // }

    // const submit1 = () => {
    //     const newFileName = dt1.toString() + '-' + selectedFile.name;
    //     ReactS3Client
    // .uploadFile(selectedFile, newFileName)
    // .then(data => {
    //     alert(data.location);
    // })
    // .catch(err => console.error(err))
    // alert('ok');
    // }

    const submit1 =  () => {
        const type=departmentref.current.value;
        if(type=="1") {
            alert('Please select type of file');
            return;
        }


        setIsuploading(true);
        var dt1=new Date();
        var month=dt1.getMonth() + 1;
        //var dt2=dt1.getMonth().toString() + dt1.getFullYear().toString() + dt1.getDay().toString() + dt1.getMinutes().toString() + dt1.getSeconds();
        var dt2=month + '-' + dt1.getFullYear().toString() + '-' + dt1.getDate().toString() + '-' + dt1.getMinutes().toString() + dt1.getSeconds();
        const newFileName = dt2.toString() + '-' + selectedFile.name;
        ReactS3Client
    .uploadFile(selectedFile, newFileName)
    .then(data => {

        const db = firebase.firestore();
        db.collection("alllinks").add({
            metric: metric,
            type: type,
            field1:field1,
            description: description,
            link: data.location,
            user: user,
            colid: colid,
            status:'Submitted',
            datecompleted: datechange
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef.id);
                //alert("Workload created with ID: " + docRef.id);
                
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
                alert("Error adding document: ", error);
            });
            //history.replace('/assetlist');
        
        // const response =  ep1.get('/api/v1/createsupportingdoc', {
        //     params: {
        //         user: user,
        //         token: token,
        //         colid: colid,
        //         name: name,
        //         criteria: criteria,
        //         filename: newFileName,
        //         link: data.location,
        //         metric: metric,
        //         type: type,
        //         collection1: collection1,
        //         field1: field1


        //     }
        // });
        alert('File uploaded to ' + data.location);
        setIsuploading(false);

    })
    .catch(err => console.error(err))
    //alert('ok');
    }

    function getdata1() {
        const field1=global1.field1;
        const db = firebase.firestore();

        db.collection("alllinks").where("colid", "==", colid).where("metric", "==", metric).where("field1", "==", field1)
            .onSnapshot(function (querySnapshot) {
            const Comment = [];
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);
                const { user, metric, type, field1,description, status, datecompleted, link } = doc.data();
                Comment.push({
                    _id: doc.id,
                    user,
                    metric,
                    type,
                    field1,
                    link,
                    description,
                    status,
                    datecompleted
                });
            });
            //const { name, coursename, link } = querySnapshot.val();
            //console.log(doc.id + ' ' + doc.data);
            setResults(Comment);
           
        });
        
    }

    const handleSubmission = () => {
        //alert('checking');
                
                //const topic=topicref.current.value;
                const formData = new FormData();
        		formData.append('upl', selectedFile);
                formData.append('token', token);
                formData.append('user', user);
                formData.append('name', name);
                formData.append('colid', colid);
                formData.append('criteria', '1');
                formData.append('metric', '1.1');
                formData.append('field1', 'field123');
                formData.append('type', 'Qlm');
                formData.append('collwction1', 'Event');

                setIsuploading(true);
                
        
        		fetch(
        			//'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
                    //'/api/v1/createcoursefiles',
                    'https://canvasapi.azurewebsites.net/api/v1/uploadsupportingdoc',
                    //'https://ctnodeapps2.azurewebsites.net/api/v1/uploadclassenr',
        			{
        				method: 'POST',
                        // headers: {
                        //     'Content-Length': selectedFile.length,
                        //     'Content-Type': 'multipart/form-data'
                        //   },
        				body: formData,
        			}
        		)
        			.then((response) => response.json())
        			.then((result) => {
        				console.log('Success:', result);
                        alert(result.status + '-' + result.error);
                        setIsuploading(false);
        			})
        			.catch((error) => {
        				console.error('Error:', error);
                        setIsuploading(false);
        		});
                
       	};



    function setcompleted(id, status) {
        const db = firebase.firestore();

        
        db.collection("alllinks").doc(id).set({
            status: status
        }, { merge: true })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });  
        //history.replace('/courseactions'); 
    }
   

    
const getweblink21=(link,status1) => {
    var newlink='';
    //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
    //newlink=link.substring(link.indexOf('amazonaws.com') + 14, link.lastIndexOf('.'));
    newlink=link.substring(link.indexOf('amazonaws.com') + 14);
    var website=global1.website;
    if(!website) {
        website='https://<yourdomain.com>';
    }
    //newlink=website + '/Criteria1/valueaddedcourse' + newlink;
    newlink=website + '?link=' + newlink;
    var str1='';
      if(status1=='Flagged') {     
          return '';
      } else {
        
        // return content + '<br />' + newlink + '<br />' ;
        return (
            <div>
                <a href={newlink} target="_blank">{newlink}</a>
                <br /><br />
            </div>

        );
      }
    //return newlink;
}

function timely(dt1) {
    //var date1=new Date(dt1);
    const date1 = new Date(dt1.toDate().toDateString());
    return date1.getDate() + '/' + (date1.getMonth() + 1) + '/' + date1.getFullYear();
    //return dt1.toString();
}
	

           const width=window.innerWidth;
           const mdivwidth=width - 130;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidecolorNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       ><table>
        <tr>
            <td>
           Upload supporting document
            </td>
            <td width="20px"></td>
            <td>
            <button  onClick={gobacknav}>Go back</button>
            </td>
        </tr>
       </table>
                        
                     
                        <hr />
                        Note: Documents will <b>only</b> be uploaded if it is less than 3 MB and in pdf format. Please try to keep the document size less than 1 MB per document.
                       
                            
                
                        <br /><br />

                      

      

<label htmlFor='affiliated'>Select document type</label>
                    <select id="affiliated" className="form-control"   ref={departmentref}>
                    <option value="1" selected>Select type</option>
                    <option value="Course">Course content</option>
                    <option value="Attendance">Attendance report</option>
                    <option value="Minutes">Meeting minutes</option>
                    <option value="Approval">Approval</option>
                    <option value="Communication">Communication</option>
                    <option value="Egov">E Governance</option>
                    <option value="Circular">Circular</option>
                    <option value="Brochure">Brochure</option>
                    <option value="Report">Report</option>
                    <option value="BoS">BoS</option>
                    <option value="Academic council">Academic council</option>
                    <option value="Sanction">Sanction letter</option>
                    <option value="Financial mapping">Financial mapping</option>
                    <option value="Policy">Policy</option>
                    <option value="Attendance">Attendance record and participation list</option>

                  
                    </select>
                

                      

                        <br />

                        <input type="file" name="file" onChange={changeHandler} />

                        <br /><br />




                        <div style={styles}>
        <FileDrop className={classes2.FileDrop}
          onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
          onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
          onFrameDrop={(event) => console.log('onFrameDrop', event)}
          onDragOver={(event) => console.log('onDragOver', event)}
          onDragLeave={(event) => console.log('onDragLeave', event)}
          onDrop={(files, event) => changeHandler1(files, event)}
        >
          Drop some files here!
        </FileDrop>
      </div>
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
                {isFilePicked ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>File Information</p>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
                    <div className={classes1.actions}>
                    <button  onClick={submit1}>Add Supporting Document</button>
                    </div>
					{/* <p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p> */}
				</div>
			) : (
                <div>
				<p>Select a supporting document to show details</p>
                
                </div>
			)}
	
              
                <br />
<p>Uploaded documents</p><br />

<div style={{fontSize:10}}>
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
{/* <th>Added by</th> */}
      <th>Document code</th>
      <th>Metric</th>
      <th>Type</th>
      <th>Data ID</th>
      <th>Description</th>
      <th>Link</th>
      <th>Web link</th>
      <th>Status</th>
      <th>Date added</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        {/* <td>
                            {meetup3.user}
                        </td> */}
                        <td>
                        {meetup3._id}
                        </td>
                        <td>
                            {meetup3.metric}
                        </td>
                        <td>
                            {meetup3.type}
                        </td>
                        <td>
                            {meetup3.field1}
                        </td>
                        <td>
                            {meetup3.description}
                        </td>
                        <td>
                        <a href={meetup3.link} target="_blank">{meetup3.link}</a>
                        </td>
                        <td>
                        <a href={getweblink21(meetup3.link,meetup3.status1)} target="_blank">{getweblink21(meetup3.link,meetup3.status1)}</a> 
                        </td>
                        <td>
                            {meetup3.status}
                        </td>
                        <td>
                            {timely(meetup3.datecompleted)}
                        </td>
                        <td>
                      
                            <Button variant="outline-danger" onClick={() => setcompleted(meetup3._id, "Submitted")}>
                        <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} />
                            Unflag</Button>
                            <br /><br />
                            <Button variant="outline-danger" onClick={() => setcompleted(meetup3._id, "Flagged")}>
                        <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} />
                            Flag</Button>
                            <br /><br />
                            {/* <Button variant="outline-danger" onClick={() => assignmentdetails(meetup3._id, meetup3.asset)}>
                        <FontAwesomeIcon icon={faBinoculars} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} />
                            Asset Assignment</Button> */}
                            {/* <br />
                            <Button variant="outline-danger" onClick={() => setcompleted(meetup3._id, "Pending")}>
                        <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} />
                            Set pending</Button> */}
                        </td>
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>
            </div>
            <br /><br />


                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;