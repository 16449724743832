import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import Table from 'react-bootstrap/Table';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faFlag,  faTrash, faHome, faCheckCircle, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';

import Button from 'react-bootstrap/Button';

// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';

import Spinner from 'react-bootstrap/Spinner';



function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [circular, setCircular] = useState([]);
    const [results1, setResults1] = useState([]);
    const [projectcount, setProjectcount] = useState([]);
    const [projectamount, setProjectamount] = useState([]);
    const [bookcount, setBookcount] = useState([]);
    const [patentscount, setPatentscount] = useState([]);
    const [publicationcount, setPublicationcount] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const aqaryear='2022-23';
    const aqarpubyear='2023';

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }


    const getcircular = async () => {
        //let tutorials = [];
        //setResults([]);
       // setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getcircularfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                role: role
            }
        });
        //setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("circularfacbyrole", JSON.stringify(response.data.data.classes));
        setCircular(response.data.data.classes);
       
    };

    

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getseminaramount', {
            params: {
                colid: colid,
                user: user

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchApi1 = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getseminarcounta', {
            params: {
                colid: colid,
                user: user

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchPubcount = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getpublicationscounta', {
            params: {
                colid: colid,
                user: user

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setPublicationcount(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const searchBookcount = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getbookcounta', {
            params: {
                colid: colid,
                user: user

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setBookcount(response.data.data.classes);
       
        //alert(results.length);
       
    };

    const getprojectcount = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getprojectscounta', {
            params: {
                colid: colid,
                user: user

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setProjectcount(response.data.data.classes);  
    };

    const getprojectamount = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getprojectscount', {
            params: {
                colid: colid,
                user: user

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setProjectamount(response.data.data.classes);  
    };

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteclassenr', {
            params: {
                id: enrolmentid,
                token: token
            }

        });
        setLoading(false);
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getadm = async () => {
        //let tutorials = [];
        //setResults([]);
        //setLoading(true);
        //setIsuploading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/admissiondoccommentsa', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year: aqaryear
            }
        });
        //setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_admissiona", JSON.stringify(response.data.data.classes));

       

        
        setAdm(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
    
        
       
        //alert(results.length);
       
    };

    const getphdguide = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/phdguidedoccommentsd', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_moua", JSON.stringify(response.data.data.classes));

       

        
        setPhdguide(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
      
        
       
        //alert(results.length);
       
    };

    const getpass = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/passdoccommentsa', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year:aqaryear
            }
        });
        setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_passexama", JSON.stringify(response.data.data.classes));

       

        
        setPass(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
      
       
    };

    const getplacement = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/placementdoccommentsdep', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_placementadmin", JSON.stringify(response.data.data.classes));

       

        
        setPlacement(response.data.data.classes);
      
       
    };

    const gethigheredu = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/higheredudocsdep', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_highereduadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
       
       
    };

    const getevents = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        //setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/eventdoccommentsa', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year:aqaryear
            }
        });
        setLoading(false);
        //setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_addoncoursea", JSON.stringify(response.data.data.classes));

       

        
        setEvents(response.data.data.classes);
       
       
    };

    const getteacherdata = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
       
        setTerm(studid);
        const response = await ep1.get('/api/v1/teacherdatadocs', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        
        console.log(response.data.data);
        //localStorage.setItem("p_addoncoursea", JSON.stringify(response.data.data.classes));

       

        
        setTeacherdata(response.data.data.classes);
   
       
    };

    const getprojects = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        
        setTerm(studid);
        const response = await ep1.get('/api/v1/projectdoccommentsa', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year:aqaryear
            }
        });
        setLoading(false);
        
        console.log(response.data.data);
        //localStorage.setItem("allprojects", JSON.stringify(response.data.data.classes));

       

        
        setProjects(response.data.data.classes);
    
       
    };

    const getpublications = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
       
        setTerm(studid);
        const response = await ep1.get('/api/v1/publicationdocsd', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
       
        console.log(response.data.data);
        //localStorage.setItem("allpubs", JSON.stringify(response.data.data.classes));

       

        
        setPublications(response.data.data.classes);
  
       
    };

    const getseminars = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        
        setTerm(studid);
        const response = await ep1.get('/api/v1/seminardoccommentsa', {
            params: {
                user: user,
                token: token,
                colid: colid,
                year:aqaryear
            }
        });
        setLoading(false);
        
        console.log(response.data.data);
        //localStorage.setItem("allseminars", JSON.stringify(response.data.data.classes));

       

        
        setSeminars(response.data.data.classes);
      
       
    };

    const [adm, setAdm] = useState([]);
    const [phdguide, setPhdguide] = useState([]);
    const [pass, setPass] = useState([]);
    const [placement, setPlacement] = useState([]);
    const [higheredu, setHigheredu] = useState([]);
    const [events, setEvents] = useState([]);
    const [teacherdata, setTeacherdata] = useState([]);
    const [projects, setProjects] = useState([]);
    const [publications, setPublications] = useState([]);
    const [seminars, setSeminars] = useState([]);

    useEffect(() => {
        checklogin();
        //getcircular();
        //search1();
        //getexamlist();
        //searchApi('');
        searchApi1('');
        searchPubcount();
        searchBookcount();
        //getprojectamount();
        getprojectcount();
        getadm();
        getphdguide();
        getpass();
        getplacement();
        gethigheredu();
        getevents();
        getteacherdata();
        getprojects();
        getpublications();
        getseminars();
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    
    const getweblink=(link) => {
        var newlink='';
        //newlink=link.substring(link.indexOf('amazonaws.com') + 13);
        //newlink=link.substring(link.indexOf('amazonaws.com') + 14, link.lastIndexOf('.'));
        newlink=link.substring(link.indexOf('amazonaws.com') + 14);
        var website=global1.website;
        if(!website) {
            website='https://<yourdomain.com>';
        }
        //newlink=website + '/Criteria1/valueaddedcourse' + newlink;
        newlink=website + '?link=' + newlink;
        return newlink;
    }

    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }

    const formatdates = (date1) => {
        var dt1=new Date(date1);
        var month=dt1.getMonth() + 1;
        return dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    {/* <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 

                        {/* <TopNavigation /> */}

                  


    {/* {circular.map((meetup6) => {
                return (
                    <div style={{paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
                    <h5>Circular / Notice - {meetup6.title}</h5>
                   
                   
                            {meetup6.description}
                            <br />
                       
                            Link: {meetup6.link}
                            <br />
                        
                                </div>
                );
            })} */}



                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, fontSize:10, width: mdivwidth }}
       
        >

<h4>Overall Report</h4>

<br /><br />
          
            {loading ? (
                <div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div></div>
            )} 


        <div className="container">
                <div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of projects per year</h5>
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: projectcount.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Projects',
                        data: 
                            projectcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                {/* <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Total amount of seminar support per year</h5>
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: results.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Total Amount',
                        data: 
                            results.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div> */}
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of seminars attended per year</h5>
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: results1.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Number of seminars',
                        data: 
                            results1.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>


{/* start div */}
                <div className="row">
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of publications per year</h5>
            <hr />
            <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: publicationcount.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Publications',
                        data: 
                            publicationcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>
                           

                        </div>
                </div>
                <div className="col-6">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                         >
                             <h5>Number of books published per year</h5>
        <hr />
        <div style={{width: 450, height: 250}}>

            <Bar
	            data={{
                    labels: bookcount.map((labels) => {
                        return (
                            labels._id        
                            );
                        }),
                    datasets: [{
                        label: 'Books published',
                        data: 
                            bookcount.map((labels1) => {
                                return (
                                    parseInt(labels1.total_attendance)        
                                    );
                                })
                            
                        ,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }}
	            width={400}
	            height={250}
	            options={{ 
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                }

                            }
                            
                        ]
                    }
                }}
            />
           </div>


                         </div>
                </div>
               
               
                </div>
                {/* end div */}

                <br /><br />                
<h4>Details of student enrollment</h4>
<br /><br />

<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    
        <th>Academic Year</th>
        <th>Program Name</th>
        <th>Programme Code</th>
        <th>Number of seats available/sanctioned</th>
        <th>Number of eligible applications received</th>
        <th>Number of students admitted</th>
        <th>Department</th>
        <th>Documents</th>
        <th>Virtual link</th>
    
        
    </tr>
    </thead>
    <tbody>
    

    {adm.map((meetup3) => {
                return (
                    <tr>
                       
                        <td>
                            {meetup3.year}
                        </td>
                        <td>
                            {meetup3.programname}
                        </td>
                        <td>
                            {meetup3.programcode}
                        </td>
                        <td>
                            {meetup3.sancseat}
                        </td>
                        <td>
                            {meetup3.studapply}
                        </td>
                        <td>
                            {meetup3.studadmt}
                        </td>
                        <td>
                        {meetup3.userdetails.map((meetup31) => {
                return (
                    <div>
                        
                        {meetup31.department}
                      
                      
                        </div>

                    );
                })}
                        </td>

                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a>
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                    
                     
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

            <h4>PhD Scholars</h4>

            <br /><br />

            <Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    
        
        <th>Name of the PhD scholar</th>
        <th>Name of the Department</th>
        <th>Name of the guide/s</th>
        <th>Title of the thesis</th>
        <th>Year of registration of the scholar</th>
        <th>Year of PhD awarded</th>
        {/* <th>Whether recognised as a Research Guide for Ph.D./D.M/M.Ch./D.N.B Super speciality/D.Sc./D’Lit (YES/NO) </th> */}
        <th>Year of Recognition as a Research Guide</th>
        <th>Document link</th>
        <th>Virtual link</th>
      
    </tr>
    </thead>
    <tbody>
    

    {phdguide.map((meetup3) => {
                return (
                    <tr>
                      
                        
                        <td>
                            {meetup3.scholar}
                        </td>
                        <td>
                            {meetup3.department}
                        </td>
                        <td>
                            {meetup3.researchguide}
                        </td>
                        <td>
                            {meetup3.title}
                        </td>
                        <td>
                            {meetup3.yor}
                        </td>
                        <td>
                            {meetup3.yop}
                        </td>
                        {/* <td>
                            {meetup3.ifrecognized}
                        </td> */}
                        <td>
                            {meetup3.yog}
                        </td>

                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a>
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                      
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

            <h4>Pass percentage</h4>

            <br /><br />

             
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
      
        <th>Academic Year</th>
        <th>Program Name</th>
        <th>Program Code</th>
        <th>No.of students appeared in final exam</th>
        <th>No.of students passed in final exam</th>
        <th>Department</th>
        
        <th>Document link</th>
        <th>Virtual link</th>
       
    </tr>
    </thead>
    <tbody>
    

    {pass.map((meetup3) => {
                return (
                    <tr>
                  
                        <td>
                            {meetup3.year}
                        </td>
                        <td>
                            {meetup3.programname}
                        </td>
                        <td>
                            {meetup3.programcode}
                        </td>
                        <td>
                            {meetup3.studappear}
                        </td>
                        <td>
                            {meetup3.studpass}
                        </td>

                        <td>
                        {meetup3.userdetails.map((meetup31) => {
                return (
                    <div>
                        
                        {meetup31.department}
                      
                      
                        </div>

                    );
                })}
                        </td>
                    

                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                     
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

<h4>Placement</h4>

<br /><br />

    
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
   
      <th>Department</th>
      <th>Academic Year</th>
      <th>Name of student placed  and contact details</th>
      <th>Program graduated from</th>
      <th>Name of the  employer with contact details</th>


      <th>Pay package at appointmnet(in INR/annum)</th>
      <th>Document</th>
   
    </tr>
    </thead>
    <tbody>
    

    {placement.map((meetup3) => {
                return (
                    <tr>
                       
                        <td>
                            
                            {meetup3.userdetails.map((meetup31) => {
                    return (
                        <div>
                            
                            {meetup31.department}
                            
                            </div>
    
                        );
                    })}
                            </td>
                        <td>
                        {meetup3.year}
                        </td>
                        <td>
                            {meetup3.studentname} {meetup3.regno} {meetup3.studcontactdetails}
                        </td>
                      
                        <td>
                            {meetup3.programname}
                        </td>
                        <td>
                            {meetup3.employername}  {meetup3.empcontactdetails}
                        </td>
                       
                        
                        <td>
                            {meetup3.salary}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                      
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

<br /><br />

<h4>Higher education progression</h4>
<br /><br />

<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
      
      <th>Department</th>
      <th>Added by</th>
      <th>Academic Year</th>
      <th>Student Name</th>
      <th>Prgram graduating from</th>
      <th>Institution Name</th>
      <th>Prgram admitted to</th>
      <th>Status</th>
      {/* <th>Comments</th> */}
      <th>Documents</th>
        <th>Virtual link</th>
      
    </tr>
    </thead>
    <tbody>
    

    {higheredu.map((meetup3) => {
                return (
                    <tr>
                   
                        <td>
                            
                            {meetup3.userdetails.map((meetup31) => {
                    return (
                        <div>
                            
                            {meetup31.department}
                            
                            </div>
    
                        );
                    })}
                            </td>
                        <td>
                            {meetup3.name}
                        </td>
                        <td>
                            {meetup3.year}
                        </td>
                        <td>
                            {meetup3.studentname}
                        </td>
                   
                        <td>
                            {meetup3.programgrad}
                        </td>
                        <td>
                            {meetup3.institution}
                        </td>
                        <td>
                            {meetup3.programadm}
                        </td>
                   
                        <td>
                            {meetup3.status1}
                        </td>
                      
                        {/* <td>
                            {meetup3.comments}
                        </td> */}
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                     
                
                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>




<br /><br />

<h4>Events conducted</h4>


<br /><br />


<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    
     <th>Department</th>

     <th>eventname</th>
<th>description</th>
<th>department</th>

<th>date</th>

<th>type</th>
<th>academicyear</th>
<th>noofparticipants</th>
<th>noofteachers</th>
<th>eventlink</th>
<th>fromto</th>

        
     
       
       
        <th>Documents</th>
        <th>Virtual link</th>
      
    </tr>
    </thead>
    <tbody>
    

    {events.map((meetup3) => {
                return (
                    <tr>
                          <td>
                        {meetup3.userdetails.map((meetup31) => {
                return (
                    <div>
                        
                        {meetup31.department}
                      
                      
                        </div>

                    );
                })}
                        </td>

                       
                    <td>{meetup3.eventname}</td>
<td>{meetup3.description}</td>
<td>{meetup3.department}</td>

<td>{formatdates(meetup3.date)}</td>

<td>{meetup3.type}</td>
<td>{meetup3.academicyear}</td>
<td>{meetup3.noofparticipants}</td>
<td>{meetup3.noofteachers}</td>
<td>{meetup3.eventlink}</td>
<td>{meetup3.fromto}</td>

                        
                       
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                      
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

            <h4>List of faculties</h4>

            <br /><br />

              
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
     
     

     <th>Academic Year</th>
<th>Name of Faculty</th>
<th>Department</th>
<th>PAN</th>
<th>Faculty designation</th>
<th>Year of Appointment</th>
<th>Nature of Appointment</th>
<th>Total Years of Teaching Experince including previous institution</th>
<th>Total Years of Teaching Experince in same institution</th>
<th>Is faculty still serving</th>
<th>Last year of service</th>

        
     
       
       
        <th>Documents</th>
        <th>Virtual link</th>
    
    </tr>
    </thead>
    <tbody>
    

    {teacherdata.map((meetup3) => {
                return (
                    <tr>
                      
                       
                    <td>{meetup3.year}</td>
<td>{meetup3.fname}</td>
<td>{meetup3.department}</td>
<td>{meetup3.pan}</td>
<td>{meetup3.designation}</td>
<td>{meetup3.yoa}</td>
<td>{meetup3.type}</td>
<td>{meetup3.yoe}</td>
<td>{meetup3.yoeinstitution}</td>
<td>{meetup3.status}</td>
<td>{meetup3.lastyear}</td>

                        
                       
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                       
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

            <h4>Projects sanctioned</h4>

            <br /><br />

            <Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
<th>Faculty department</th>
      <th>Name of the Principal Investigator/ Co Investigator (if applicable)</th>
      <th>Name of the Scheme/Project/ Endowments/ Chairs</th>
      <th>Name of the Funding agency </th>
      <th>Type (Government/Non-Government)</th>
      
      <th>Department</th>
      <th>Year of Award</th>
      <th>Funds provided (INR in lakhs)</th>
      <th>Duration of the project</th>
      
      
      <th>Documents</th>
      <th>Virtual link</th>
     
    </tr>
    </thead>
    <tbody>
    

    {projects.map((meetup3) => {
                return (
                    <tr>
                       
                       <td>
                            
                            {meetup3.userdetails.map((meetup31) => {
                    return (
                        <div>
                            
                            {meetup31.department}
                            
                            </div>
    
                        );
                    })}
                            </td>
                        <td>
                        {meetup3.name}
                        </td>
                        <td>
                        {meetup3.project}
                        </td>
                        <td>
                            {meetup3.agency}
                        </td>
                        <td>
                            {meetup3.type}
                        </td>
                        
                        <td>
                            {meetup3.department}
                        </td>
                        <td>
                            {meetup3.yop}
                        </td>
                        <td>
                            {meetup3.funds}
                        </td>
                        <td>
                            {meetup3.duration}
                        </td>
                    
                     
                  
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={getweblink(meetup31.link)} target="_blank">{getweblink(meetup31.link)}</a> 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>

                     
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

            <h4>Publications</h4>

            <br /><br />

                
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>

<th>Title of paper</th>
      <th>Name of the author/s</th>
      <th>Department of the teacher</th>
      <th>Name of journal</th>
      <th>Year of publication</th>
      
      <th>ISSN number</th>
      
      <th>Link to website of the Journal</th>
      <th>Link to article/paper /abstract of the article</th>
      
      <th>Is it listed in UGC Care list/Scopus/Web of Science /other, mention</th>
      <th>Documents</th>
    
    </tr>
    </thead>
    <tbody>
    

    {publications.map((meetup3) => {
                return (
                    <tr>
                    
                        <td>
                        {meetup3.title}
                        </td>
                        <td>
                        {meetup3.name}
                        </td>
                        <td>
                            {meetup3.department}
                        </td>
                        <td>
                            {meetup3.journal}
                        </td>
                        <td>
                            {meetup3.yop}
                        </td>
                        
                        <td>
                            {meetup3.issn}
                        </td>
                      
                        <td>
                            {meetup3.journallink}
                        </td>
                        <td>
                            {meetup3.articlelink}
                        </td>
                        <td>
                            {meetup3.ugclisted}
                        </td>
                        
                       
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {meetup31.link} {meetup31.status1} 
                        <br />
                      
                        </div>

                    );
                })}
                        </td>
                     
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />

            <h4>Seminars attended</h4>
            <br /><br />

            <Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
{/* <th>Document ID</th> */}
<th>User</th>
<th>Year</th>
<th>Department</th>
      <th>Name of teacher who attended</th>
      <th>Title of the program</th>
      <th>Duration (from – to) (DD-MM-YYYY)</th>
      
      {/* <th>Year</th>
      <th>Amount</th>
      <th>Status</th>
      <th>Role</th>
      <th>Level</th> */}
      <th>Documents</th>

    </tr>
    </thead>
    <tbody>
    

    {seminars.map((meetup3) => {
                return (
                    <tr>
                        {/* <td>
                        {meetup3._id}
                        </td> */}
                        
                        <td>
                        {meetup3.user}
                        </td>
                        <td>
                            {meetup3.yop}
                        </td>
                        <td>
                            
                            {meetup3.userdetails.map((meetup31) => {
                    return (
                        <div>
                            
                            {meetup31.department}
                            
                            </div>
    
                        );
                    })}
                            </td>

                        <td>
                        {meetup3.name}
                        </td>
                        <td>
                        {meetup3.title}
                        </td>
                        <td>
                            {meetup3.duration}
                        </td>
                    
                        {/* <td>
                            {meetup3.membership}
                        </td> */}
                        {/* <td>
                            {meetup3.yop}
                        </td>
                        <td>
                            {meetup3.amount}
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                        <td>
                            {meetup3.role}
                        </td>
                        <td>
                            {meetup3.level}
                        </td> */}
                        <td>
                        {meetup3.seminars.map((meetup31) => {
                return (
                    <div>
                        
                        {/* {meetup31.link} {meetup31.status1}  */}
                        <a href={meetup31.link} target="_blank">{meetup31.link}</a> - {meetup31.status1} 
                        <br /><br />
                      
                        </div>

                    );
                })}
                        </td>
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>














            </div>

            
    
        

        

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;