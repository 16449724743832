import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import SidemetricNavigation from '../components/layout/SidemetricNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    
    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    
    const addperiod = () => {
        history.replace('/addperiod');
    }  

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/gethigheredubyadmin', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        localStorage.setItem("p_highereduadmin", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            formattable();
        } catch(err) {

        }
        
       
        alert(results.length);
       
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

    const deleteenrolment = async (enrolmentid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deletehigheredubyfac', {
            params: {
                id: enrolmentid,
                token: token,
                user: user
            }

        });
        alert(response.data.status);
        setLoading(false);
        //history.replace('/viewlibbooks');
        searchApi();
        //console.log(response.data.data);
        //getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getlocal = () => {
        let collection=localStorage.getItem("p_highereduadmin");
        if(!collection) {

        } else {
            setResults(JSON.parse(collection));

        }
        
    }


    useEffect(() => {
        checklogin();
        formattable();
        //search1();
        //getexamlist();
        //getlocal();
        //searchApi('');
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    const width=window.innerWidth;
    const mdivwidth=width - 330;

        //alert('loaded');
  

    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidemetricNavigation />
                    </td>
                   
                    <td> 
                        <TopNavigation />

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>Legal University Metrics</p>
            <hr />
             
       <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
    <th>Criteria No.</th>
    <th>Criteria Name</th>
    <th>Metric No.</th>
    <th>Metric Name</th>
    <th>Links</th>
</tr>
</thead>
<tbody>
                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.1.1</td>
                        <td>Programme outcomes (POs), Programme Specific Outcomes(PSOs) and Course Outcomes(COs) of the Programmes offered by the Institution</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.1.1&question=PO, PSO and CO of the Programmes offered by the Institution&accreditation=NAACLEGALNUNI&documents=PO,PSO and CO' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.1.2 - 1.2.2</td>
                        <td>Syllabus Revision - CBCS Programs</td>
                        <td>
                        <Link to='/viewsyllabusrev' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.1.3 - 1.2.1</td>
                        <td>Courses having focus on Litigation/Judicial and legal service/entrepreneurship/ skill development - Percentage of new courses introduced</td>
                        <td>
                        <Link to='/viewnnlufocus?name=1.1.3 - 1.2.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.3.1</td>
                        <td>Institution integrates cross-cutting issues</td>
                        <td>
                        <Link to='/viewnbaug11?name=1.3.1&question=Cross-cutting issues&accreditation=NAACLEGALNUNI&documents=List and description of courses' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.3.2 - 1.3.3</td>
                        <td>Value Added Courses</td>
                        <td>
                        <Link to='/viewaddoncourse?name=1.3.2 - 1.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>1</td>
                        <td>Curicular Aspects</td>
                        <td>1.3.4</td>
                        <td>Percentage of students undertaking Moot Courts,Court visits,Arbitration/Mediation/Client Counseling Exercises, and internship in law firms/NGOs/Judicial Clerkships/field projects</td>
                        <td>
                        <Link to='/viewnexplearning?name=1.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.1.1-2.1.2</td>
                        <td>Enrolment percentage - Student Seats against Reserved Category</td>
                        <td>
                        <Link to='/viewnnluadmission' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.2.1</td>
                        <td>The institution assesses the learning levels of the students-Advanced learners and Slow learners</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.2.1&question=Advanced and Slow learners&accreditation=NAACLEGALNUNI&documents=Syllabus copy,Time table' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.2.2</td>
                        <td>Student-Full time teacher ratio</td>
                        <td>
                        <Link to='/viewnnratio?name=2.2.2&question=Student teacher ratio&accreditation=NAACLEGALNUNI&documents=Teacher and Student list' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.1</td>
                        <td>Student centric methods</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.3.1&question=Student centric methods&accreditation=NAACLEGALNUNI&documents=Student centric methods' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.2</td>
                        <td>Teachers use ICT- enabled tools</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.3.2&question=Teachers use ICT- enabled tools&accreditation=NAACLEGALUNI&documents=ICT-enabled tools' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.3</td>
                        <td>Mentor Mentee</td>
                        <td>
                        <Link to='/viewmentees?name=2.3.3 - 2.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.3.4</td>
                        <td>Student mentor</td>
                        <td>
                        <Link to='/viewnstudmentor' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.1</td>
                        <td>Full time Teachers</td>
                        <td>
                        <Link to='/viewnnufacultydata?name=2.4.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.2</td>
                        <td>Research Guide.

                        Each faculty may add their data. Bulk upload facility is also available.
                        </td>
                        <td>
                        <Link to='/viewnphdface?name=2.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.2</td>
                        <td>Research Guide (template wise)
                        </td>
                        <td>
                        <Link to='/viewnlphdfac' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.3</td>
                        <td>Teaching experience of Full time Teachers</td>
                        <td>
                        <Link to='/viewnteacherdata?name=2.4.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.4</td>
                        <td>Measures/Policies/Incentive by the institution for faculty retention</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.4.4&question=Measures for faculty retention&accreditation=NAACLEGALUNI&documents=Policy documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.4.5</td>
                        <td>Percentage of full time teachers involved in drafting of bills</td>
                        <td>
                        <Link to='/viewnnluteacherdata?name=2.4.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.1</td>
                        <td>Result Publication</td>
                        <td>
                        <Link to='/viewnresult?name=2.5.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.2</td>
                        <td>Average percentage of student complaints/grievances about evaluation against total number appeared in the examinations</td>
                        <td>
                        <Link to='/viewnnugrievance?name=2.5.2&question=Exam grievance&accreditation=NAACLEGALNUNI&documents=Student tabulation sheet,grievance mechanism' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.3</td>
                        <td>IT integration and reforms in the examination procedures</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.5.3&question=IT integration and reforms in the examination&accreditation=NAACLEGALUNI&documents=IT integration and reforms' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.5.4</td>
                        <td>Automation for Examination</td>
                        <td>
                        <Link to='/viewexamautomation?name=2.5.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.6.1</td>
                        <td>The institution has stated learning outcomes</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.6.1&question=The institution has stated learning outcomes&accreditation=NAACLEGALNUNI&documents=PO,PSO,CO' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.6.2</td>
                        <td>Attainment of PO,PSO,CO</td>
                        <td>
                        <Link to='/viewnbaug11?name=2.6.2&question=Attainment of outcomes&accreditation=NAACLEGALNUNI&documents=PO,PSO,CO' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Teaching-Learning-Evaluation</td>
                        <td>2.6.3</td>
                        <td>Student Pass Percentage</td>
                        <td>
                        <Link to='/viewnpassexam?name=2.6.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.1</td>
                        <td>The institution’s Research facilities are frequently updated</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.1.1&question=Minutes of the Governing Council related to research promotion policy&accreditation=NAACLEGALNUNI&documents=Minutes, Promotion policy' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.2</td>
                        <td>Seed Money provided to Faculty</td>
                        <td>
                        <Link to='/viewnseedm?name=3.1.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.3</td>
                        <td>Percentage of Teachers receiving national/ international Fellowship</td>
                        <td>
                        <Link to='/viewnteacherfellow?name=3.1.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.4</td>
                        <td>Research Fellowships</td>
                        <td>
                        <Link to='/viewresearchfellow?name=3.1.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.5</td>
                        <td>Institution has the facilities to support research</td>
                        <td>
                        <Link to='/viewnnlufacilities?name=3.1.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.1.6</td>
                        <td>Institution has the facilities to support research</td>
                        <td>
                        <Link to='/viewnnlurecognitions?name=3.1.6' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.2.1 - 3.2.2 - 3.2.3</td>
                        <td>Gov./Non-gov Grants for Research Projects.

                        Each faculty may add data for their projects or bulk upload data for all faculties.
                        </td>
                        <td>
                        <Link to='/viewprojects?name=3.2.1 - 3.2.2 - 3.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.2.4</td>
                        <td>Bill, Ordinances, Rules and regulation drafted by the faculty of the University</td>
                        <td>
                        <Link to='/viewnnlubills?name=3.2.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.1</td>
                        <td>Institution has an ecosystem for Knowledge Creation</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.3.1&question=Innovations and Incubation centres&accreditation=NAACLEGALNUNI&documents=Availability, Usage of centres' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.2</td>
                        <td>Workshops/Seminars/Conferences.

                        Add every activity/event in All events and select proper category. During data validation, category will be validated.
                        </td>
                        <td>
                        <Link to='/viewnevent?name=3.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.3</td>
                        <td>Number of awards received for research/innovations by the institution/teachers/research scholars/students (entered by faculty)</td>
                        <td>
                        <Link to='/viewinnovation?name=3.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.3.3</td>
                        <td>Number of awards received for research/innovations by the institution/teachers/research scholars/students (entered by criteria coordinator)</td>
                        <td>
                        <Link to='/viewninnovationnew?name=3.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.1</td>
                        <td>The institution ensures implementation of its stated Code of Ethics for research</td>
                        <td>
                        <Link to='/viewnnucodeofethics?name=3.4.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.2</td>
                        <td>The institution provides incentives to teachers who receive state,national and international recognitions/awards</td>
                        <td>
                        <Link to='/viewnnluincentives?name=3.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.3</td>
                        <td>Number of PhD thesis and LLM Dissertations on IPR
                        </td>
                        <td>
                        <Link to='/viewnnluphdtheisis?name=3.4.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.4</td>
                        <td>Research Guide.

                        Each faculty may add their data. Bulk upload facility is also available.
                        </td>
                        <td>
                        <Link to='/viewphdguide?name=3.4.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.5 - 3.4.8</td>
                        <td>Research Papers.

                        Each faculty may add their data. Bulk upload facility is also available. System will combine individual data into metrics.
                        </td>
                        <td>
                        <Link to='/viewpub?name=3.4.5 - 3.4.8' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.6</td>
                        <td>Books & Chapters.

                        Each faculty may add their data. Bulk upload facility is also available. System will combine individual data into metrics.
                        </td>
                        <td>
                        <Link to='/viewbooks?name=3.4.6' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.7</td>
                        <td>E-content developed by Teachers</td>
                        <td>
                        <Link to='/viewecontent?name=3.4.6' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.4.9</td>
                        <td>Citations in the Judgments of Supreme Court,High Courts,Courts of Foreign Jurisdiction and constitutional tribunals</td>
                        <td>
                        <Link to='/viewnnlucitation?name=3.4.9' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.5.1</td>
                        <td>Institution has a policy for consultancy including revenue sharing</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.5.1&question=Institution has a policy for consultancy including revenue sharing&accreditation=NAACLEGALNUNI&documents=Policy documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.5.2</td>
                        <td>Revenue generated from Consultancy</td>
                        <td>
                        <Link to='/viewnconsultancy?name=3.5.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.6.1</td>
                        <td>Extension activities in the neighborhood community</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.6.1&question=Impact of extension activities in sensitizing the students&accreditation=NAACLEGALNUNI&documents=Social issues, development activities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.6.2</td>
                        <td>Impact of Legal aid/ Community services/Extension activities</td>
                        <td>
                        <Link to='/viewnbaug11?name=3.6.2&question=Impact of extension activities in sensitizing the students&accreditation=NAACLEGALNUNI&documents=Social issues, development activities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.6.3 - 3.6.4</td>
                        <td>Extension and outreach programs conducted by the institution through NSS/NCC.

                        You may add activities into specific metric or into All Events.
                        </td>
                        <td>
                        <Link to='/viewextact?name=3.6.3 - 3.6.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.7.1</td>
                        <td>Collaborative Activities</td>
                        <td>
                        <Link to='/viewcollaboration?name=3.7.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Research-Consultancy-Extension</td>
                        <td>3.7.2</td>
                        <td>Functional MoUs/linkages with institutions/ industries</td>
                        <td>
                        <Link to='/viewnmou?name=3.7.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.1</td>
                        <td>The institution has adequate facilities for teaching - learning</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.1.1&question=Adequacy of facilities for teaching-learning&accreditation=NAACLEGALNUNI&documents=Classrooms, Labs used' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.2</td>
                        <td>The institution has adequate facilities for cultural facilities</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.1.2&question=Adequacy of facilities for cultural activities&accreditation=NAACLEGALNUNI&documents=Classrooms, Labs used' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.3</td>
                        <td>Availability of general campus facilities and overall ambience</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.1.3&question=General campus facilities and overall ambience&accreditation=NAACUNI&documents=General campus facilities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.1.4 - 4.4.1</td>
                        <td>Infrastructure Augmentation</td>
                        <td>
                        <Link to='/viewexpenditure?name=4.1.4 - 4.4.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.1</td>
                        <td>Library is automated using Integrated Library Management System</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.2.1&question=Library is automated using ILMS&accreditation=NAACLEGALNUNI&documents=Library automation implementation' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.2 - 4.2.3</td>
                        <td>Library Expenditures</td>
                        <td>
                        <Link to='/viewlibrary?name=4.2.2 - 4.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.4</td>
                        <td>Legal Databases made available to the students</td>
                        <td>
                        <Link to='/viewnnlulegaldatabases?name=4.2.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.2.5</td>
                        <td>Percentage per day usage of library by teachers and students</td>
                        <td>
                        <Link to='/viewnnlibusage?name=4.2.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.1</td>
                        <td>ICT Enabled Classrooms & Seminar Halls</td>
                        <td>
                        <Link to='/viewict?name=4.1.4 - 4.4.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.2</td>
                        <td>Institution frequently updates its IT facilities</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.3.2&question=Institution frequently updates its IT facilities&accreditation=NAACLEGALNUNI&documents=Policy details of systems and procedures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.3</td>
                        <td>Student - Computer ratio</td>
                        <td>
                        <Link to='/viewnnustudcompratio?name=4.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.4</td>
                        <td>Available bandwidth of internet connection in the Institution(Leased Line)</td>
                        <td>
                        <Link to='/viewninternet?name=4.3.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.3.5</td>
                        <td>Institution has Facilities for e-content development</td>
                        <td>
                        <Link to='/viewnnuefacilities?name=4.3.5' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Infrastructure-Learning Resources</td>
                        <td>4.4.2</td>
                        <td>Institution has established systems and procedures for maintaining and utilising physical, academic and support facilities</td>
                        <td>
                        <Link to='/viewnbaug11?name=4.4.2&question=Institution has established systems and maintainance procedures&accreditation=NAACLEGALNUNI&documents=Policy details of systems and procedures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.1</td>
                        <td>Student Scholarships</td>
                        <td>
                        <Link to='/viewnnauscholarship?name=5.1.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.2</td>
                        <td>Career Counselling</td>
                        <td>
                        <Link to='/viewcareercounsel?name=5.1.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.3</td>
                        <td>Skill Development Programs</td>
                        <td>
                        <Link to='/viewskilldev?name=5.1.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.1.4</td>
                        <td>Institution adopt measures for redressal of student grievances including sexual harassment and ragging cases</td>
                        <td>
                        <Link to='/viewnnursing515?name=5.1.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.1</td>
                        <td>Student Qualifying in Higher Examination</td>
                        <td>
                        <Link to='/viewnhigherexam?name=5.2.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.2</td>
                        <td>Outgoing Student Placement </td>
                        <td>
                        <Link to='/viewnplacement?name=5.2.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.3</td>
                        <td>Percentage of Students enrolled with State Bar council</td>
                        <td>
                        <Link to='/viewnnlbar' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.2.4</td>
                        <td>Student Progression to Higher Education</td>
                        <td>
                        <Link to='/viewnhigheredu?name=5.2.4' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.3.1</td>
                        <td>Student Awards/Medals</td>
                        <td>
                        <Link to='/viewnawards?name=5.3.1' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.3.2</td>
                        <td>Presence of Student Council</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.3.2&question=Student Council for institutional development&accreditation=NAACLEGALNUNI&documents=Student Council and its activities' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.3.3</td>
                        <td>Sports & Cultural Activities.

                        Add data to all events with category as Sports and Cultural for both Sports and Cultural activities
                        </td>
                        <td>
                        <Link to='/viewnevent?name=5.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.4.1</td>
                        <td>Alumni contributions and engagements for institutional development</td>
                        <td>
                        <Link to='/viewnbaug11?name=5.4.1&question=Alumni contributes for institutional development&accreditation=NAACLEGALNUNI&documents=Contribution of alumni' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>Student Support-Progression</td>
                        <td>5.4.2</td>
                        <td>Alumni Contribution</td>
                        <td>
                        <Link to='/viewnalumnicon?name=5.4.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.1.1</td>
                        <td>The institution has a clearly stated vision and mission</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.1.1&question=Institutional Vision and Mission&accreditation=NAACLEGALNUNI&documents=Vision-Mission documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.1.2</td>
                        <td>Effective leadership is reflected in various institutional practices</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.1.2&question=Institutional practises in effective leadership&accreditation=NAACLEGALUNI&documents=Decentralization, Participative management' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.2.1</td>
                        <td>The institutional perspective plan is effectively deployed</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.2.1&question=One successfully-implemented activity based on the strategic plan&accreditation=NAACLEGALNUNI&documents=Strategic Plan and deployment documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.2.2</td>
                        <td>Functioning of the institutional bodies is effective and efficient</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.2.2&question=Effective and efficient institutional bodies&accreditation=NAACUNI&documents=Organogram, Policies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.2.3</td>
                        <td>Implementation of E Governance</td>
                        <td>
                        <Link to='/viewnegovern?name=6.2.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.1</td>
                        <td>The institution has a performance appraisal system</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.3.1&question=Performance appraisal system, promotional avenues&accreditation=NAACLEGALNUNI&documents=Appraisal system' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.2</td>
                        <td>Financial Support provided for Faculty.

                        Each faculty may add data from their profile.
                        </td>
                        <td>
                        <Link to='/viewseminar?name=6.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.2</td>
                        <td>Financial Support provided for Faculty.

                        Add data to a centralized level.
                        </td>
                        <td>
                        <Link to='/viewnteacherfs?name=6.3.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.3</td>
                        <td>Professional development/Administrative Training Programs.

                        Add data to all events with category as FDP.
                        </td>
                        <td>
                        <Link to='/viewnevent?name=6.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.4</td>
                        <td>Faculty Development Programs.

                        Each faculty may add data from their profile.
                        </td>
                        <td>
                        <Link to='/viewseminar?name=6.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.3.4</td>
                        <td>Faculty Development Programs.

                        Add data to all events with category as FDP.
                        </td>
                        <td>
                        <Link to='/viewnevent?name=6.3.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.4.1</td>
                        <td>Institutional strategies for mobilisation of funds and the optimal utilisation of resources</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.4.1&question=Resource mobilisation policy and procedures&accreditation=NAACLEGALNUNI&documents=Policy, Procedures' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.4.2 - 6.4.3</td>
                        <td>Gov./Non-Governmnet Funds</td>
                        <td>
                        <Link to='/viewfunds?name=6.4.2 - 6.4.3' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.4.4</td>
                        <td>Institution regularly conducts internal and external financial audits </td>
                        <td>
                        <Link to='/viewnbaug11?name=6.4.4&question=Internal and external financial audits conducted&accreditation=NAACLEGALNUNI&documents=Audit documents' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.5.1</td>
                        <td>Significant contributions by IQAC</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.5.1&question=IQAC Contributions&accreditation=NAACLEGALNUNI&documents=Two practices institutionalized' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.5.2</td>
                        <td>Institutional Quality Initiatives</td>
                        <td>
                        <Link to='/viewnlquality?name=6.5.2' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>Governance-Leadership-Management</td>
                        <td>6.5.3</td>
                        <td>Incremental improvements made for the preceding five years with regard to quality</td>
                        <td>
                        <Link to='/viewnbaug11?name=6.5.3&question=IQAC operations&accreditation=NAACLEGALNUNI&documents=Two institutional reviews and implementation of teaching learning reforms facilitated' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>


                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.1</td>
                        <td>Institution has initiated the Gender Audit & Promotion of gender equity</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.1&question=Gender equity & sensitization in curricular and co-curricular activities&accreditation=NAACLEGALNUNI&documents=Annual gender sensitization action plan' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.2</td>
                        <td>Institution has facilities for alternate sources of energy and energy conservation measures</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.2&question=Facilities for alternate sources of energy in the Institution&accreditation=NAACLEGALNUNI&documents=geotagged photos, Invoice copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.3</td>
                        <td>Waste Management</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.3&question=Management of Waste&accreditation=NAACLEGALNUNI&documents=Agreements, Geotagged photos' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.4</td>
                        <td>Water conservation facilities available in the Institution</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.4&question=Water conservation facilities available in the Institution&accreditation=NAACLEGALNUNI&documents=Geotagged photos, Invoice copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.5</td>
                        <td>Green campus inititatives</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.5&question=Green campus inititatives&accreditation=NAACLEGALNUNI&documents=Geotagged photos' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.6</td>
                        <td>Quality audits on environment and energy are regularly undertaken by the institution</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.6&question=Quality audits on environment and energy&accreditation=NAACLEGALNUNI&documents=Audit reports,Audit certificates,Award copies' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.7</td>
                        <td>The Institution has friendly, barrier free environmen</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.7&question=Disabled-friendly,barrier free environment in Institution&accreditation=NAACLEGALNUNI&documents=Reports, Geotagged photos' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.8</td>
                        <td>Institutional efforts/initiatives in providing an inclusive environment</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.8&question=Inclusive Environment&accreditation=NAACLEGALNUNI&documents=Administrative, Academic activity reports ' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.9</td>
                        <td>Sensitization of students and employees</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.9&question=Sensitization of students and employees&accreditation=NAACLEGALNUNI&documents=Activity reports' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.1.10</td>
                        <td>The Institution has a prescribed code of conduct for students,teachers,administrators and other staf</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.1.10&question=Sensitization of students and employees&accreditation=NAACLEGALNUNI&documents=Activity reports' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.2.1</td>
                        <td>Institutional Best Practices</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.2.1&question=Two Best practices successfully implemented &accreditation=NAACLEGALNUNI&documents=Best practices as hosted on the Institutional website' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Institutional Values and Best Practices</td>
                        <td>7.3.1</td>
                        <td>Institutional Distinctiveness</td>
                        <td>
                        <Link to='/viewnbaug11?name=7.3.1&question=One Distinctive performance of the Institution&accreditation=NAACLEGALNUNI&documents=Performance of the Institution' className="btn btn-dark"><FontAwesomeIcon icon={faBook} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} /></Link>
                        </td>
                    </tr>







              
            </tbody>
            </Table>

            <br /><br />
            

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;