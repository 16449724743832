import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        {/* <Link to='/viewnacademic'> Academic calendar</Link>
        <Link to='/viewnworkbook'> Personal workbook</Link>
        <Link to='/viewnenrollment'> Enrollment</Link>
        <Link to='/viewncourseplan'> Course plan</Link> */}

{/* <Link to='/viewnainst'> Institution details</Link> */}
<Link to='/viewnInstitutions'> Institutions</Link>
<Link to='/viewnaffproglist'> Programs offered</Link>
<Link to='/viewnaffcolprog'> Affiliated college program mapping</Link>
<Link to='/viewnmsusancseatn'> Affiliated college programwise sanctioned seat</Link>


{/* 
<Link to='/viewnpaunewidetails'> 1. College Details</Link>
<Link to='/viewnpauidetailsc'> 1. College Details(Extended)</Link>
<Link to='/viewnpautrust'> 2. Trust and Society Details</Link>
<Link to='/viewnpaunewprincipal'> 3. Details of Principal</Link>
<Link to='/viewnpaungovcouncil'> 4. Details of Governing </Link>
<Link to='/viewnpaunboard'> 5. Planning and Monitoring Board Member</Link>
<Link to='/viewnpauragcomt'>  6(i). Anti Ragging Committee</Link>
<Link to='/viewnpauragsquad'> 6(ii). Anti Ragging Squad</Link>
<Link to='/viewnpauwelfarecomt'> 7(i). Discipline and Welfare Committee</Link>
<Link to='/viewnpauredressal'> 7(ii).Complaints and Redressal Committe</Link>
<Link to='/viewnpaubankacnts'> 8(i).Savings Bank/Current Accounts</Link>
<Link to='/viewnpaudeposit'> 8(ii).Long term Deposits</Link>
<Link to='/viewnpauendowment'> 8(iii).Endowment</Link>
<Link to='/viewnpaulanddetailsa'>  9. Details of Land Availability (Part A)</Link>
<Link to='/viewnpaulanddetailsb'>  9. Details of Land Availability (Part B)</Link>
<Link to='/viewnpaucourse'> 10(i). Details of Existing Affiliated Courses</Link>
<Link to='/viewnpaucrsedetails'> 10(ii). Details of the Courses approval is sought for current year</Link>
<Link to='/viewnpauaddcrse'> 11. Additional Courses</Link>
<Link to='/viewnpaunstuddetails'> 12(i). Details of Students presently stydying in all years</Link>
<Link to='/viewnpaufaculty'> 13(i). Faculty Detils</Link>
<Link to='/viewnpaunfacultydetails'> 13(i). Faculty Qualification & Experience</Link>
<Link to='/viewnpaunfaddqualif'> 13(i). Faculty Additional Qualification</Link>
<Link to='/viewnpaunfacdexp'>  13(i). Faculty Experience</Link>
<Link to='/viewnpaunfindexp'> 13(i). Faculty Industrial Experience</Link>
<Link to='/viewnpaunnfeduqualif'> 13(i). Faculty Educational Qualification</Link>
<Link to='/viewnpaunnfcoeexp'> 13(i). COE Experience</Link>
<Link to='/viewnpauconfacshe'>  14(i). Faculty Details for Science,Humanities & General Engineering</Link>
<Link to='/viewnpauconfaccourse'> 14(ii). Course Details except M.E/M.Tech</Link>
<Link to='/viewnpauconfacmemtech'> 14(iii).Faculty Details for all courses except M.E/M.Tech</Link>
<Link to='/viewnpaunontstaff'> 15. Non-Teaching Staff</Link>
<Link to='/viewnpauspacereq'>  16(i). Space Requirements</Link>
<Link to='/viewnpaunequip'> 16(ii).Equipments</Link>
{/* <Link to='/viewnpauequiplist'> 16(ii). Equipments List</Link> 
<Link to='/viewnpauccomfarea'> 17(i). Central Computing Facility Area</Link>
<Link to='/viewnpauntermlanwan'> 17(ii). Terminals & LAN WAN connections</Link>
<Link to='/viewnpausoftware'>  17(iii). Softwares</Link>
<Link to='/viewnpaunetwork'> 17(iv). Network Connectivity</Link>
<Link to='/viewnpaulibarea'> 18(i). Library Area</Link>
<Link to='/viewnpaunlibbooks'> 18(ii). Library Books</Link>
<Link to='/viewnpaunlibjournal'>  18(iii). Library Journals</Link>
<Link to='/viewnpauclassblock'> 19(i). Classrooms Availability</Link>
<Link to='/viewnpauclassnew'> 19(i). Classrooms Availability(Extended)</Link>
<Link to='/viewnpaunnoofdrawhall'> 20. Drawing Halls/Conference Halls</Link>
<Link to='/viewnpauadminarea'> 21(i). Administrative Area</Link>
<Link to='/viewnpaunamen'> 21(ii). Amenities</Link>
<Link to='/viewnpaubhostel'> 22(i). Details of Boys Hostel</Link>
<Link to='/viewnpaughostel'> 22(iii). Details of Girls Hostel</Link>
<Link to='/viewnpaunotherbuildarea'> 22(v). Other Building Areas</Link>
<Link to='/viewnpauphyedu'> 23. Physical Education</Link>
<Link to='/viewnpautpc'> 24(i). Training and Placement Cell-Details of Staff</Link>
<Link to='/viewnpaufacilityavail'> 24(ii). Training and Placement Cell-Available Facilities</Link>
<Link to='/viewnpaualumni'> 25. Alumni Association</Link>
<Link to='/viewnpaunhealth'> 26(i). Health Centres</Link>
<Link to='/viewnpauothamen'> 26(ii). Other Amenities</Link>
<Link to='/viewnpauregrec'> 27. Registers and Records</Link>
<Link to='/viewnpaucertificates'> 28. Certificates</Link>
<Link to='/viewnpaunprocfee'>  29. Inspection Processing Fee</Link>
<Link to='/viewnpauprcplendrsmnt'>  30. Endorsement of the Principal</Link>
<Link to='/viewnpaumngdeclaration'> 31. Declaration by the Management</Link> */}
{/* <Link to='/viewnpaunewprincipal'> paunewprincipal</Link>
<Link to='/viewnpaucourse'> paucourse</Link>
<Link to='/viewnpaucrsedetails'> paucrsedetails</Link>
<Link to='/viewnpauaddcrse'> pauaddcrse</Link>
<Link to='/viewnpaunstuddetails'> paunstuddetails</Link>
<Link to='/viewnpaunewfacultydetails'> paunewfacultydetails</Link>
<Link to='/viewnpaunewfeduqualif'> paunewfeduqualif</Link>
<Link to='/viewnpauconfaccourse'> pauconfaccourse</Link>
<Link to='/viewnpauconfacmemtech'> pauconfacmemtech</Link>
<Link to='/viewnpauspacereq'> pauspacereq</Link>
<Link to='/viewnpaunequip'> paunequip</Link>
<Link to='/viewnpaunlibjournal'> paunlibjournal</Link>
<Link to='/viewnpaunnoofdrawhall'> paunnoofdrawhall</Link>
<Link to='/viewnpaunewlanddetails'> paunewlanddetails</Link>
<Link to='/viewnpaunewfaddqualif'> paunewfaddqualif</Link>
<Link to='/viewnpaunewfacdexp'> paunewfacdexp</Link>
<Link to='/viewnpaunewfindexp'> paunewfindexp</Link> */}







<br /><br /><br /><br />


      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;