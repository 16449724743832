import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import { Bar } from 'react-chartjs-2';
import Card1 from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ListGroup from 'react-bootstrap/ListGroup';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faCube, faVideo, faCubes, faFile, faUsers, faDatabase, faTachometerAlt, faComment, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';
import classes2 from "./newstyles.css";

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidemetricNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

import $ from 'jquery'; 


function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const affiliatedref=useRef();
    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);

    const [isUploading, setIsuploading] = useState(false);


    const [circular, setCircular] = useState([]);
    
    const [projectcount, setProjectcount] = useState([]);
    const [projectamount, setProjectamount] = useState([]);
    const [bookcount, setBookcount] = useState([]);
    const [patentscount, setPatentscount] = useState([]);
    const [pubtarget, setPubtarget] = useState();
    const [pubvariant, setPubvariant] = useState();
    const [booktarget, setBooktarget] = useState();
    const [bookvariant, setBookvariant] = useState();
    const [semtarget, setSemtarget] = useState();
    const [semvariant, setSemvariant] = useState('');
    const [projtarget, setProjtarget] = useState();
    const [projvariant, setProjvariant] = useState();
    const [constarget, setConstarget] = useState();
    const [consvariant, setConsvariant] = useState();
    const [ectarget, setEctarget] = useState();
    const [ecvariant, setEcvariant] = useState();
    const [publicationcount, setPublicationcount] = useState([]);
    const [link, setLink] = useState();
    const [photo, setPhoto] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [dep, setDep] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    //const name=global1.name;
    const coursename=global1.coursename;
    const coursecode1=global1.coursecode;
    const courseid=global1.courseid; 
    const section=global1.section;
    const semester=global1.semester;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const viewstudents = () => {
        history.replace('/classstudents');
    }
    const viewstudentsm = () => {
        history.replace('/classstudentsm');
    }
    const viewstudentsmsec = () => {
        history.replace('/classstudentsmsec');
    }

    const addassignments = () => {
        history.replace('/addassignments');
    }

    const viewassignments = () => {
        history.replace('/classassignments');
    }
    const viewstudymaterial = () => {
        history.replace('/classfiles');
    }

    const addstudymaterial = () => {
        history.replace('/addcoursefiles');
    }

    const gotoattendance = () => {
        // history.replace('/classattendance');
        history.replace('/selectattendance');
    }

    const addmodules = () => {
        history.replace('/addlessonplan');
    }

    const viewmodules = () => {
        history.replace('/lessonplan');
    }
    const gotodynamicmodules = () => {
        history.replace('/viewdynamicmodules');
    }

    const addlessonplan = () => {
        history.replace('/viewlessonnew');
    }

    // const addstudents = () => {
     
    //     history.replace('/enrollstudents');
    // }

    const formatdates = (date1) => {
        var dt1=new Date(date1);
        var month=dt1.getMonth() + 1;
        return dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
    }

    const addstudentsext = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudentsext');
    }

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                       dom: 'Bfrtip',
                          buttons: []
                }
            );
            setFormat(true);

        }
    }

    const viewclass = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getclassbyfacd', {
            params: {
                user: user,
                token: token,
                colid: colid
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_addoncourse", JSON.stringify(response.data.data.classes));
        
        setResults(response.data.data.classes);
        //setLink(response.data.data.link);
        //alert('format ' + format);
        try{

            //formattable();
        } catch(err) {

        }
        
       
        //alert(results.length);
       
    };

    

   
var uphoto='';
var uname='';


const searchApi1 = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getseminarcount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setResults1(response.data.data.classes);
    var c=0;
    // var c=response.data.data.classes.length;
    response.data.data.classes.map((k)=> {
        c=c+k.total_attendance;
    })
    var l=0;
    if(c<10) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setSemtarget(l);
    if(l<50) {
        setSemvariant('danger');
    } else if (l > 50 && l < 99) {
        setSemvariant('warning');
    } else {
        setSemvariant('success');
    }
   
    //alert(results.length);
   
};

const searchPubcount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getpublicationscount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setPublicationcount(response.data.data.classes);
    var c=0;
    // var c=response.data.data.classes.length;
    response.data.data.classes.map((k)=> {
        c=c+k.total_attendance;
    })
    var l=0;
    if(c<10) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setPubtarget(l);
    if(l<50) {
        setPubvariant('danger');
    } else if (l > 50 && l < 99) {
        setPubvariant('warning');
    } else {
        setPubvariant('success');
    }

   
    //alert(results.length);
   
};

const searchBookcount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getbookcount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setBookcount(response.data.data.classes);

    var c=0;
    // var c=response.data.data.classes.length;
    response.data.data.classes.map((k)=> {
        c=c+k.total_attendance;
    })
    var l=0;
    if(c<10) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setBooktarget(l);
    if(l<50) {
        setBookvariant('danger');
    } else if (l > 50 && l < 99) {
        setBookvariant('warning');
    } else {
        setBookvariant('success');
    }
   
    //alert(results.length);
   
};

const getprojectcount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getprojectscount', {
        params: {
            colid: colid,
            user: user

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setProjectcount(response.data.data.classes); 
    var c=0;
    // var c=response.data.data.classes.length;
    response.data.data.classes.map((k)=> {
        c=c+k.total_attendance;
    })
    var l=0;
    if(c<5) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setProjtarget(l); 
    if(l<50) {
        setProjvariant('danger');
    } else if (l > 50 && l < 99) {
        setProjvariant('warning');
    } else {
        setProjvariant('success');
    }
};

const getconscount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getconsultancybyfac', {
        params: {
            colid: colid,
            user: user,
            token:token

        }

    });
    setLoading(false);
    console.log(response.data.data);
    //setProjectcount(response.data.data.classes); 
    var c=0;
    var c=response.data.data.classes.length;
    // response.data.data.classes.map((k)=> {
    //     c=c+k.total_attendance;
    // })
    var l=0;
    if(c<5) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setConstarget(l); 
    if(l<50) {
        setConsvariant('danger');
    } else if (l > 50 && l < 99) {
        setConsvariant('warning');
    } else {
        setConsvariant('success');
    }
};

const geteccount = async () => {
    setLoading(true);
    setTerm(studid);
    const response = await ep1.get('/api/v1/getecontentbyfac', {
        params: {
            colid: colid,
            token:token,
            user: user
            

        }

    });
    setLoading(false);
    console.log(response.data.data);
    setProjectcount(response.data.data.classes); 
    var c=0;
    var c=response.data.data.classes.length;
    // response.data.data.classes.map((k)=> {
    //     c=c+k.total_attendance;
    // })
    var l=0;
    if(c<5) {
        l=Math.floor(c/10*100);
    } else {
        l=100;
    }
    //alert(c);
    setEctarget(l); 
    if(l<50) {
        setEcvariant('danger');
    } else if (l > 50 && l < 99) {
        setEcvariant('warning');
    } else {
        setEcvariant('success');
    }
};

    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getuserdetails', {
            params: {
                user: user,
                token: token,
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        //setResults(response.data.data.classes);
    uname=response.data.data.classes[0].name;
    uphoto=response.data.data.classes[0].photo;
    setEmail(response.data.data.classes[0].email);
    setPhone(response.data.data.classes[0].phone);
    setDep(response.data.data.classes[0].department);
    if(!uphoto) {
        uphoto='https://jadavpuruniversity.s3-ap-south-1.amazonaws.com/820222034-user.png';
    }
    setPhoto(uphoto);
        //alert(uname + ' ' + photo);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };




    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const addstudents = (courseid, coursecode, coursename, section, semester, program, programcode, year) => {
        global1.courseid=courseid;
        global1.coursecode=coursecode;
        global1.coursename=coursename;
        global1.section=section;
        global1.semester=semester;
        global1.programname=program;
        global1.programcode=programcode;
        global1.lmsyear=year;

        //history.replace('/enrollstudents');
        history.replace('/courseactions');
    }

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };


    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //searchApi('');
        viewclass();
        //getperiod();
        //getclass();
        //searchApi1('');
        // searchApi1('');
        // getconscount();
        // searchPubcount();
        // searchBookcount();
        
        // getprojectcount();
        // geteccount();
        

    }, []);

    function checkdata(link, ccode) {
        //alert (examcode + '-' + examname);
        if (link=='NA') {
            link="https://meet.jit.si/" + ccode;
        }
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDragStart = (ev, ccode,  course, section, semester, program) => {
        //ev.preventDefault();
        ev.dataTransfer.setData("ccode", ccode);
        ev.dataTransfer.setData("course", course);
        ev.dataTransfer.setData("section", section);
        ev.dataTransfer.setData("semester", semester);
        ev.dataTransfer.setData("program", program);
        //setCoursecode(ccode);
    }
    const onDrop = async (ev, starttime, endtime) => {
        let ccode = ev.dataTransfer.getData("ccode");
        let course = ev.dataTransfer.getData("course");
        let section = ev.dataTransfer.getData("section");
        let semester = ev.dataTransfer.getData("semester");
        let program = ev.dataTransfer.getData("program");
        const module=moduleref.current.value;
        const link=linkref.current.value;
        const topic=topicref.current.value;
        if(!module) {
            alert("Please enter module");
            return;
        }
        if(!topic) {
            alert("Please enter topic");
            return;
        }
        if(!link) {
            alert("Please enter link");
            return;
        }
        
        setCoursecode(ccode);
        const response = await ep1.get('/api/v1/createclass', {
            params: {
                user: user,
                name: name,
                colid: colid,
                classdate: datechange,
                weeks: 0,
                coursecode: ccode,
                course: course,
                link: link,
                program: program,
                semester: semester,
                section: section,
                module: module,
                topic: topic,
                classtime: starttime



            }

        });
        if (response.data.status == "Success") {
            
            //history.replace('/workload');
            //setTerm2('Thank you');  
            getclass();
            alert('Data updated successfully');
        }
        //setResults(response.data.data.classes);

        //alert(ccode + '-' + starttime + '-' + endtime + '-' + datechange + '-' + module + '-' + topic + '-' + link);

    }

    async function refreshclass(newdate) {
        
        var dt1=new Date(newdate);
        setDate2(dt1);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
    }

    const takeattendance = (id, classdate, coursecode, course, semester, section, program, programcode) => {
        global1.classid=id;
        global1.classdate=classdate;
        global1.coursecode=coursecode;
        global1.course=course;
        global1.semester=semester;
        global1.section=section;
        global1.program=program;
        global1.programcode=programcode;
        history.replace('/takeattendance');
    }

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    const getval1=async(event)=> {
      
        //alert(event.target.value);
        const aff=affiliatedref.current.value;
        global1.lmsyear=aff;
        //alert(aff);
        const response = await ep1.get('/api/v1/getworkloadbyfaca', {
            params: {
                user: user,
                token: token,
                year: aff

            }

        });
        setLoading(false);
       // console.log(response.data.data);
        setResults1(response.data.data.classes);
        //   const userarray=aff.split('-');
        //   const user1=userarray[0];
        //   const uame1=userarray[1];
        //   alert('Select user ' + user1 + ' ' + uame1);
      
    }

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        
                        <SidecolorNavigation />
                    </td>
                   
                    <td> 

                    <TopNavigation />
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >

            <br /><br />

<table>
    <tr>
        <td>
        <Card1 style={{ width: '28rem' }}>
                  
                  <Card1.Header>Upcoming classes</Card1.Header>
        <Card1.Body>
          <Card1.Text>
            <div style={{width: '400px', height: '160px', overflow: 'scroll', fontSize: '10px' }}>
  
          <Table striped hover id="example">
  {/* <table id="mytable"> */}
  <thead>
  <tr>
  <th>Course</th>
  <th>Date</th>
  <th>Action</th>
      </tr>
      </thead>
      <tbody>
      
  
      {results.map((meetup3) => {
                  return (
                      <tr>
  
  <td> {meetup3.program} {meetup3.course} {meetup3.coursecode}</td>
  <td>{formatdates(meetup3.classdate)} {meetup3.classtime}</td>
  <td>
  <Button variant="success" onClick={() => takeattendance(meetup3._id, meetup3.classdate, meetup3.coursecode, meetup3.course, meetup3.semester, meetup3.section, meetup3.program, meetup3.programcode)}>              
                          Attendance
                              </Button>
  </td>
                                  </tr>
                  );
              })}
              </tbody>
              </Table>
  </div>
  
  
          </Card1.Text>
          
        </Card1.Body>
      </Card1>
        </td>
        <td width="20px"></td>
        <td>
        <Card1 style={{ width: '28rem' }}>
                  
                  <Card1.Header>
                   My workload
                  </Card1.Header>
        <Card1.Body>
          <Card1.Text>
            <div style={{width: '400px', height: '160px', overflow: 'scroll', fontSize: '10px' }}>

            <table>
                        <tr>
                            <td>Academic year</td>
                            <td width="10px"></td>
                            <td>
                            <select id="affiliated" className="form-control" onChange={getval1}  ref={affiliatedref}>
                    
                    <option value="1">Select year</option>
                    <option value="2022-23">2022-23</option>
                    <option value="2023-24">2023-24</option>
                    <option value="2024-25">2024-25</option>
                    </select>
                            </td>
                        </tr>
                    </table>
                    <br />
  
          <Table striped hover id="example">
  {/* <table id="mytable"> */}
  <thead>
  <tr>
  <th>Program</th>
  <th>Course</th>
  <th>Action</th>
      </tr>
      </thead>
      <tbody>
      
  
      {results1.map((meetup3) => {
                  return (
                      <tr>
  
  <td> {meetup3.program} </td>
  <td>{meetup3.course} {meetup3.coursecode}</td>
  <td>
  <Button variant="success" onClick={() => addstudents(meetup3._id, meetup3.coursecode, meetup3.course, meetup3.section, meetup3.semester, meetup3.program,meetup3.programcode,meetup3.year)}>              
                          Dashboard
                              </Button>
  </td>
                                  </tr>
                  );
              })}
              </tbody>
              </Table>
  </div>
  
  
          </Card1.Text>
          
        </Card1.Body>
      </Card1>
        </td>
    </tr>
</table>
<br /><br /><br />


           
        <br />

 
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;