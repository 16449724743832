import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sadditional1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const studentref=useRef();
const regnoref=useRef();
const filldateref=useRef();
const securityref=useRef();
const commonref=useRef();
const sanitationref=useRef();
const cctvref=useRef();
const classroomref=useRef();
const libraryref=useRef();
const timingref=useRef();
const daycareref=useRef();
const medicalref=useRef();
const hostelref=useRef();
const iccref=useRef();
const seminarref=useRef();
const courseref=useRef();
const icccomref=useRef();
const discriminatedref=useRef();
const equalref=useRef();
const grievanceref=useRef();
const saferef=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const student=studentref.current.value;
const regno=regnoref.current.value;
const filldate=filldateref.current.value;
const security=securityref.current.value;
const common=commonref.current.value;
const sanitation=sanitationref.current.value;
const cctv=cctvref.current.value;
const classroom=classroomref.current.value;
const library=libraryref.current.value;
const timing=timingref.current.value;
const daycare=daycareref.current.value;
const medical=medicalref.current.value;
const hostel=hostelref.current.value;
const icc=iccref.current.value;
const seminar=seminarref.current.value;
const course=courseref.current.value;
const icccom=icccomref.current.value;
const discriminated=discriminatedref.current.value;
const equal=equalref.current.value;
const grievance=grievanceref.current.value;
const safe=saferef.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/creategenderauditbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                student:student,
regno:regno,
filldate:filldate,
security:security,
common:common,
sanitation:sanitation,
cctv:cctv,
classroom:classroom,
library:library,
timing:timing,
daycare:daycare,
medical:medical,
hostel:hostel,
icc:icc,
seminar:seminar,
course:course,
icccom:icccom,
discriminated:discriminated,
equal:equal,
grievance:grievance,
safe:safe,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewngenderaudit');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Student<br /><br />

<input type="String" required style={{width: "100%"}} placeholder="Enter Student" id="student" ref={studentref} />
<br /><br />

Enter Reg no<br /><br />

<input type="String" required style={{width: "100%"}} placeholder="Enter Reg no" id="regno" ref={regnoref} />
<br /><br />

Enter Fill date<br /><br />

<input type="Date" required style={{width: "100%"}} placeholder="Enter Fill date" id="filldate" ref={filldateref} />
<br /><br />

<label htmlFor="security">Security is adequate in campus</label>

<select id="security" className="form-control"  ref={securityref}>
<option value="5">5</option>
<option value="4">4</option>
<option value="3">3</option>
<option value="2">2</option>
<option value="1">1</option>
</select>
<br /><br />

<label htmlFor="common">Common room is available in campus for girls</label>

<select id="common" className="form-control"  ref={commonref}>
<option value="5">5</option>
<option value="4">4</option>
<option value="3">3</option>
<option value="2">2</option>
<option value="1">1</option>
</select>
<br /><br />

<label htmlFor="sanitation">Adequate sanitation facility is available in campus for girls</label>

<select id="sanitation" className="form-control"  ref={sanitationref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="cctv">CCTV is installed</label>

<select id="cctv" className="form-control"  ref={cctvref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="classroom">Do you feel safe in classroom</label>

<select id="classroom" className="form-control"  ref={classroomref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="library">Do you feel safe in library during and after class hours</label>

<select id="library" className="form-control"  ref={libraryref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="timing">Classes are arranged during day time</label>

<select id="timing" className="form-control"  ref={timingref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="daycare">Daycare facility is available</label>

<select id="daycare" className="form-control"  ref={daycareref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="medical">Medical facility is available separately for girls</label>

<select id="medical" className="form-control"  ref={medicalref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="hostel">Hostel is available for girls</label>

<select id="hostel" className="form-control"  ref={hostelref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="icc">You are aware about ICC</label>

<select id="icc" className="form-control"  ref={iccref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="seminar">Regular workshops are conducted for gender awareness</label>

<select id="seminar" className="form-control"  ref={seminarref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="course">There are courses for gender sensitization</label>

<select id="course" className="form-control"  ref={courseref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="icccom">I am aware on how to contact ICC</label>

<select id="icccom" className="form-control"  ref={icccomref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="discriminated">I have never felt discriminated</label>

<select id="discriminated" className="form-control"  ref={discriminatedref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="equal">Equal opportunity exists in campus for all gender</label>

<select id="equal" className="form-control"  ref={equalref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="grievance">I am aware about grievance redressal committee and how to contact them</label>

<select id="grievance" className="form-control"  ref={grievanceref}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />

<label htmlFor="safe">The campus us safe for all gender all the time</label>

<select id="safe" className="form-control"  ref={saferef}>
<option value="Strongly agree">Strongly agree</option>
<option value="Agree">Agree</option>
<option value="No opinion">No opinion</option>
<option value="Disagree">Disagree</option>
<option value="Strongly disagree">Strongly disagree</option>
</select>
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;