import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import Sidecr2Navigation from '../components/layout/Sidecr2Navigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const departmentref=useRef();
    const researchguideref=useRef();
    const yogref=useRef();
    const scholarref=useRef();
    const titleref=useRef();
    const yorref=useRef();
    const yopref=useRef();
    const qualificationref=useRef();
    const pgyearref=useRef();
    const phdyearref=useRef();
   

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };
    const [faculties, setFaculties] = useState([]);
    
    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        const department=departmentref.current.value;
        const researchguide=researchguideref.current.value;
        const yog=yogref.current.value;
        const scholar=scholarref.current.value;
        const title=titleref.current.value;
        const yor=yorref.current.value;
        const yop=yopref.current.value;
        const qualification=qualificationref.current.value;

        const pgyear=pgyearref.current.value;
        const phdyear=phdyearref.current.value;
        
        

        

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createphdguidebyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                department: department,
                researchguide: researchguide,
                yog: yog,
                scholar: scholar,
                title: title,
                yor: yor,
                yop: yop,
                pgyear: pgyear,
                phdyear: phdyear,
                status1:'Submitted',
                comments:'NA',
                qualification:qualification

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewphdguide');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };
          useEffect(() => {
            //logout();\
            getfacultylist();
      
            
    
         }, []);
          const getfacultylist = async () => {
            // setLoading(true);
            setTerm(studid);
            const response = await ep1.get('/api/v1/getdepartmentlist', {
            params: {
            colid: colid,
            user: user,
            token: token
            
            }
            
            });
            // setLoading(false);
            console.log(response.data.data);
            setFaculties(response.data.data.classes);
            //alert(results.length);
            };
       
               
    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sidecr2Navigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add PG / PhD / Research Guide</p>
                        <hr />
                       
                        <label htmlFor='department'>Select Department</label>
                        <select id="department" className="form-control" ref={departmentref}>
                            <option value="1">Select Department</option>

                            {faculties.map((meetup3) => {
                            return (
                            <option value={meetup3}>{meetup3}</option>
                        );
                        })}
                        </select>
                            <br />

                            Faculty name <br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter research guide" id="researchguide" ref={researchguideref} />
                            
                        <br /><br />

                        Qualification<br /><br />

                        <input type="text" required style={{width: '100%'}} placeholder="Enter qualification" id="qualification" ref={qualificationref} />
                            
                            <br /><br />

                            Year of obtaining PG <br /><br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter pgyear" id="pgyear" ref={pgyearref} />
                            
                            <br /><br />

                            Year of obtaining PhD <br /><br />

                            <input type="text" required style={{width: '100%'}} placeholder="Enter phdyear" id="phdyear" ref={phdyearref} />
                            
                            <br /><br />

                    <label htmlFor='yog'>Year of Recognition as Research Guide</label>
                    <select id="yog" className="form-control"  ref={yogref}>
                    <option value="2000-01">2000-01</option>
                    <option value="2001-02">2001-02</option>
                    <option value="2002-03">2002-03</option>
                    <option value="2003-04">2003-04</option>
                    <option value="2004-05">2004-05</option>
                    <option value="2005-06">2005-06</option>
                    <option value="2006-07">2006-07</option>
                    <option value="2007-08">2007-08</option>
                    <option value="2008-09">2008-09</option>
                    <option value="2009-10">2009-10</option>
                    <option value="2010-11">2010-11</option>
                    <option value="2011-12">2011-12</option>
                    <option value="2012-13">2012-13</option>
                    <option value="2013-14">2013-14</option>
                    <option value="2014-15">2014-15</option>
                    <option value="2015-16">2015-16</option>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                    <option value="2020-21">2020-21</option>
                    <option value="2021-22">2021-22</option>
                       
                    </select>
                    <br /><br />

                    Scholar name <br /><br />

                    <input type="text" required style={{width: '100%'}} placeholder="Enter name of scholar" id="scholar" ref={scholarref} />
                            
                        <br /><br />

                        Title of thesis <br /><br />

                    <input type="text" required style={{width: '100%'}} placeholder="Enter title of thesis" id="title" ref={titleref} />
                            
                        <br /><br />
    
                    <label htmlFor='yor'>Year of registration as scholar</label>
                    <select id="yor" className="form-control"  ref={yorref}>
                    <option value="2000-01">2000-01</option>
                    <option value="2001-02">2001-02</option>
                    <option value="2002-03">2002-03</option>
                    <option value="2003-04">2003-04</option>
                    <option value="2004-05">2004-05</option>
                    <option value="2005-06">2005-06</option>
                    <option value="2006-07">2006-07</option>
                    <option value="2007-08">2007-08</option>
                    <option value="2008-09">2008-09</option>
                    <option value="2009-10">2009-10</option>
                    <option value="2010-11">2010-11</option>
                    <option value="2011-12">2011-12</option>
                    <option value="2012-13">2012-13</option>
                    <option value="2013-14">2013-14</option>
                    <option value="2014-15">2014-15</option>
                    <option value="2015-16">2015-16</option>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                    <option value="2020-21">2020-21</option>
                    <option value="2021-22">2021-22</option>
                       
                    </select>
                    <br /><br />

                  
                    <label htmlFor='yop'>Year of degree awarded</label>
                    <select id="yop" className="form-control"  ref={yopref}>
                    <option value="2016-17">2016-17</option>
                    <option value="2017-18">2017-18</option>
                    <option value="2018-19">2018-19</option>
                    <option value="2019-20">2019-20</option>
                    <option value="2020-21">2020-21</option>
                    <option value="2021-22">2021-22</option>
                    <option value="2022-23">2022-23</option>
                        <option value="2023-24">2023-24</option>
                       
                    </select>
                    <br /><br />

                      
                        <button  onClick={searchapi}>Add Research Guide</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;