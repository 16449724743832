import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        <Link to='/viewuserstudent'> All students</Link>
        <Link to='/viewuserhod'> All HoD</Link>
        <Link to='/viewuserfaculty'> All faculties</Link>
        <Link to='/viewuseradmin'> All users</Link>
        <Link to='/viewnalumniusersadmin'> All alumni</Link>
        <Link to='/viewnalumniusers'> Add alumni</Link>
        <Link to='/viewnadmusers'> Add admission applicants</Link>
        <Link to='/viewnadmusersadmin'> Manage admission applicants</Link>
        <Link to='/viewnaffassign'> Add affiliated inspection</Link>
        <Link to='/viewnaffassignadmin'> Manage affiliated inspection</Link>
        {/* <Link to='/viewnaimstudents'> AI Mentor students</Link> */}
        <Link to='/viewnaimstudents1'> AI Mentor students</Link>


        
      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;