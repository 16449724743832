import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sadditional1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const companyref=useRef();
const designationref=useRef();
const fromdateref=useRef();
const todateref=useRef();
const totalexpref=useRef();
const locationref=useRef();
const managerref=useRef();
const mphoneref=useRef();
const memailref=useRef();
const hrref=useRef();
const hremailref=useRef();
const hrphoneref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       companyref.current.value=global1.company;
designationref.current.value=global1.designation;
fromdateref.current.value=global1.fromdate;
todateref.current.value=global1.todate;
totalexpref.current.value=global1.totalexp;
locationref.current.value=global1.location;
managerref.current.value=global1.manager;
mphoneref.current.value=global1.mphone;
memailref.current.value=global1.memail;
hrref.current.value=global1.hr;
hremailref.current.value=global1.hremail;
hrphoneref.current.value=global1.hrphone;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const company=companyref.current.value;
const designation=designationref.current.value;
const fromdate=fromdateref.current.value;
const todate=todateref.current.value;
const totalexp=totalexpref.current.value;
const location=locationref.current.value;
const manager=managerref.current.value;
const mphone=mphoneref.current.value;
const memail=memailref.current.value;
const hr=hrref.current.value;
const hremail=hremailref.current.value;
const hrphone=hrphoneref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateemploymentallbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                company:company,
designation:designation,
fromdate:fromdate,
todate:todate,
totalexp:totalexp,
location:location,
manager:manager,
mphone:mphone,
memail:memail,
hr:hr,
hremail:hremail,
hrphone:hrphone,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnemploymentall');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Organization<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Organization" id="company" ref={companyref} />
<br /><br />

Enter Designation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Designation" id="designation" ref={designationref} />
<br /><br />

Enter From date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter From date" id="fromdate" ref={fromdateref} />
<br /><br />

Enter To date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter To date" id="todate" ref={todateref} />
<br /><br />

Enter Total experience in years<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total experience in years" id="totalexp" ref={totalexpref} />
<br /><br />

Enter Location<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Location" id="location" ref={locationref} />
<br /><br />

Enter Manager<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Manager" id="manager" ref={managerref} />
<br /><br />

Enter Manager phone<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Manager phone" id="mphone" ref={mphoneref} />
<br /><br />

Enter Manager email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Manager email" id="memail" ref={memailref} />
<br /><br />

Enter HR name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter HR name" id="hr" ref={hrref} />
<br /><br />

Enter HR email<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter HR email" id="hremail" ref={hremailref} />
<br /><br />

Enter HR phone<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter HR phone" id="hrphone" ref={hrphoneref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;