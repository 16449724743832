import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidefacNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />
            
        
        {/* <div style={{marginBottom: 10}}><b>Insights</b></div> */}
        {/* <Link to='/viewnacademic'> Academic calendar</Link>
        <Link to='/viewnworkbook'> Personal workbook</Link>
        <Link to='/viewnenrollment'> Enrollment</Link>
        <Link to='/viewncourseplan'> Course plan</Link> */}

<br />
        <p><b>Part A-Basic Information</b></p>
           <hr />
<Link to='/viewnppuclgdetails'> 1. College/Institution</Link>
<Link to='/viewnppuheaddetails'> 2. Head of Institution(Director/Dean/Principal)</Link>
<Link to='/viewnppulegalrep'> 3. Legally authorised representatíve for communicatíon </Link>
<Link to='/viewnpputrustdetails'> 4. Trust</Link>
<Link to='/viewnppupermission'> 5. Permission letter obtained from the State Government to start the college</Link>
<Link to='/viewnppumanagingbody'> 6. Members of the Managing Body</Link>
<Link to='/viewnppuwlfcomt'> 7. Welfare Committee</Link>
<Link to='/viewnpputrustfin'> 8.a. Financial Stability-Financial status of Trust</Link>
<Link to='/viewnppufdrdetails'> 8.b. Financial Stability-FDR Details</Link>
<Link to='/viewnppuimmvprop'> 8.c. Financial Stability-Value of immovable properties (GLV and MV )</Link>
<Link to='/viewnppufinance'> 8.d.& e. Financial Stability-Endowment & PAN</Link>
<Link to='/viewnppuland'> 9. Details of the Land earmarked for the College</Link>
<Link to='/viewnppubuilding'> 10. Building</Link>
<Link to='/viewnppuhostel'> 11. Hostel</Link>
<Link to='/viewnppurooms'> 11. Rooms</Link>
<Link to='/viewnppuphyedu'> 12. Physical Education</Link>
<Link to='/viewnppustaff'> 13. Details of Staff Quarters</Link>
<br />
        <p><b>Part B-Programme Details</b></p>
           <hr />
<Link to='/viewnppucollege'> 14. College Details </Link>
<Link to='/viewnpputrust'> 15. Trust Details</Link>
<Link to='/viewnppuaddprog'> 16.a. Additional Programme(s) for which provisional affiliation is sougnt</Link>
<Link to='/viewnppuvarintake'> 16.b. Variation in intake in the existing Programme(s) for which provisional affiliation is sought</Link>
<Link to='/viewnppuexistprog'> 16.c. Details of existing provisionally affiliated programme for which continuation of provisional affiliation is sought</Link>
<Link to='/viewnppucourses'> 16.d. Courses being run by the College as of now</Link>
<Link to='/viewnppucoursrep'> 16.d. Additional Information</Link>
<Link to='/viewnppuaddinfoprog'> 17. Additional Information-Rationales</Link>
<Link to='/viewnppuaddinfonoc'> 17. Additional Information-NOC</Link>
<Link to='/viewnppuaddinfopermission'> 17. Additional Information-Permissions/Approvals/Recognitions</Link>
<Link to='/viewnppuaddinfobos'> 17. Additional Information-BOS</Link>
<Link to='/viewnppuaddinfosyllabi'> 17. Additional Information-Syllabi</Link>
<Link to='/viewnppuprincipal'> 18.a. Details of Directorl/Dean/Principal</Link>
<Link to='/viewnppufaculty'> 18.b. Details of Teaching staff</Link>
<Link to='/viewnppustafstudratio'> 18.c. Staff-Student ratio</Link>
<Link to='/viewnppuaddinfofac'> 18.d. Additional Information on Teachers</Link>
<Link to='/viewnppuaddinfostaffdetails'> 18.d. Additional Information on Teachers-Additional Staff Details</Link>
<Link to='/viewnppuaddfacdetails'> 18.d. Additional Information on Teachers-Available Staff Details</Link>
<Link to='/viewnppufacworkload'> 18.d. Additional Information on Teachers-Workload of Teaching Staff</Link>
<Link to='/viewnppulabequip'> 19.a. List of Equipment</Link>
<Link to='/viewnppulabequipprocure'> 19.b. List of equipment to be procured for new program</Link>
<Link to='/viewnppulecturerooms'> 20. Lecture rooms for new programme</Link>
<Link to='/viewnppuavailclsroom'> 20. Additional Information</Link>
<Link to='/viewnppunetwork'> 21. Network Connectivity</Link>
<Link to='/viewnppucompfaci'> 22. Computing Facility</Link>
<Link to='/viewnppucompsoftware'> 22. Software Details</Link>
<Link to='/viewnppucompaddinfo'> 22. Additional Information</Link>
<Link to='/viewnppucompaddsoft'> 22. Additional softwares</Link>
<Link to='/viewnppulibrary'> 23.i-v. Library Facility</Link>
<Link to='/viewnppulibstaff'> 23.iv. Details of Other staff members</Link>
<Link to='/viewnppulibbooks'> 23.vi. Library Books</Link>
<Link to='/viewnppulibindjrnl'> 23.vi. Library Indian Journals</Link>
<Link to='/viewnppulibforgjrnl'> 23.vi. Library Foreign Journals</Link>
<Link to='/viewnppulibautomation'> 23.vii. Library Automation</Link>
<Link to='/viewnppulibprocure'> 23.viii. Library Procurement</Link>
<Link to='/viewnppulibexp'> 23.ix. Library Expenditure</Link>
<Link to='/viewnppustudfaci'> 24.a-m. Facilities for students</Link>
<Link to='/viewnppumedservice'> 24.n. Medical Services</Link>
<Link to='/viewnppuotherfaci'> 24.o-u. Other facilities</Link>
<Link to='/viewnppuaudvidequip'> 24.p. Audio-Visual Equipments</Link>
<Link to='/viewnppuclubs'> 24.q. Association/Clubs</Link>
<Link to='/viewnpputechstaff'> 25.a. Details of Technical Staff</Link>
<Link to='/viewnppuminststaff'> 25.b. Details of Ministerial Staff</Link>
<Link to='/viewnppuaddinfo'> 26. Additional Information</Link>
<Link to='/viewnppudeclaration'> 26. Declaration</Link>
<br />
        <p><b>Part C</b></p>
           <hr />
<Link to='/viewnppucertificates'> 27. Certificates</Link>
        <br />









<br /><br /><br /><br />


      
       

        <hr />
        {/* <div style={{marginBottom: 10}}><b>Useful links</b></div> */}
     
     

        
       </div>

</div>
    );

}

export default SidefacNavigation;