import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Spondi';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const departmentref=useRef();
const facultyref=useRef();
const designationref=useRef();
const qualificationref=useRef();
const marksobtref=useRef();
const degreeref=useRef();
const spclzref=useRef();
const yearsofexpprofref=useRef();
const yearsofexpindref=useRef();
const dobref=useRef();
const dojref=useRef();
const payscaleref=useRef();
const basicpayref=useRef();
const emolumentsref=useRef();
const univaprvlref=useRef();
const ifqualpernormsref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       departmentref.current.value=global1.department;
facultyref.current.value=global1.faculty;
designationref.current.value=global1.designation;
qualificationref.current.value=global1.qualification;
marksobtref.current.value=global1.marksobt;
degreeref.current.value=global1.degree;
spclzref.current.value=global1.spclz;
yearsofexpprofref.current.value=global1.yearsofexpprof;
yearsofexpindref.current.value=global1.yearsofexpind;
dobref.current.value=global1.dob;
dojref.current.value=global1.doj;
payscaleref.current.value=global1.payscale;
basicpayref.current.value=global1.basicpay;
emolumentsref.current.value=global1.emoluments;
univaprvlref.current.value=global1.univaprvl;
ifqualpernormsref.current.value=global1.ifqualpernorms;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const department=departmentref.current.value;
const faculty=facultyref.current.value;
const designation=designationref.current.value;
const qualification=qualificationref.current.value;
const marksobt=marksobtref.current.value;
const degree=degreeref.current.value;
const spclz=spclzref.current.value;
const yearsofexpprof=yearsofexpprofref.current.value;
const yearsofexpind=yearsofexpindref.current.value;
const dob=dobref.current.value;
const doj=dojref.current.value;
const payscale=payscaleref.current.value;
const basicpay=basicpayref.current.value;
const emoluments=emolumentsref.current.value;
const univaprvl=univaprvlref.current.value;
const ifqualpernorms=ifqualpernormsref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateppufacultybyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                department:department,
faculty:faculty,
designation:designation,
qualification:qualification,
marksobt:marksobt,
degree:degree,
spclz:spclz,
yearsofexpprof:yearsofexpprof,
yearsofexpind:yearsofexpind,
dob:dob,
doj:doj,
payscale:payscale,
basicpay:basicpay,
emoluments:emoluments,
univaprvl:univaprvl,
ifqualpernorms:ifqualpernorms,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnppufaculty');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

Enter Name of Faculty<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of Faculty" id="faculty" ref={facultyref} />
<br /><br />

Enter Designation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Designation" id="designation" ref={designationref} />
<br /><br />

Enter Qualification<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Qualification" id="qualification" ref={qualificationref} />
<br /><br />

Enter Percentage of marks obtained<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Percentage of marks obtained" id="marksobt" ref={marksobtref} />
<br /><br />

<label htmlFor="degree">Degree</label>

<select id="degree" className="form-control"  ref={degreeref}>
<option value="UG">UG</option>
<option value="PG">PG</option>
<option value="PHD">PHD</option>
<option value="Additional">Additional</option>
</select>
<br /><br />

Enter Specialisation<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Specialisation" id="spclz" ref={spclzref} />
<br /><br />

Enter Years of experience in Teaching/Research<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Years of experience in Teaching/Research" id="yearsofexpprof" ref={yearsofexpprofref} />
<br /><br />

Enter Years of experience in Industry/Others<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Years of experience in Industry/Others" id="yearsofexpind" ref={yearsofexpindref} />
<br /><br />

Enter Date of Birth<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Birth" id="dob" ref={dobref} />
<br /><br />

Enter Date of Joining<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Date of Joining" id="doj" ref={dojref} />
<br /><br />

Enter Scale of Pay<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Scale of Pay" id="payscale" ref={payscaleref} />
<br /><br />

Enter Present Basic Pay<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Present Basic Pay" id="basicpay" ref={basicpayref} />
<br /><br />

Enter Total emoluments(in Rs)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Total emoluments(in Rs)" id="emoluments" ref={emolumentsref} />
<br /><br />

Enter University approval of Qualification(No and Date)<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter University approval of Qualification(No and Date)" id="univaprvl" ref={univaprvlref} />
<br /><br />

Enter Whether the teaching staff are qualified as per UGC/ MCV AICTE/ DCI VCI NCTE etc norm<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Whether the teaching staff are qualified as per UGC/ MCV AICTE/ DCI VCI NCTE etc norm" id="ifqualpernorms" ref={ifqualpernormsref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;