import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Shr';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const employeeref=useRef();
const useridref=useRef();
const itemref=useRef();
const clientidref=useRef();
const clientref=useRef();
const fromdateref=useRef();
const todateref=useRef();
const moderef=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       employeeref.current.value=global1.employee;
useridref.current.value=global1.userid;
itemref.current.value=global1.item;
clientidref.current.value=global1.clientid;
clientref.current.value=global1.client;
fromdateref.current.value=global1.fromdate;
todateref.current.value=global1.todate;
moderef.current.value=global1.mode;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const employee=employeeref.current.value;
const userid=useridref.current.value;
const item=itemref.current.value;
const clientid=clientidref.current.value;
const client=clientref.current.value;
const fromdate=fromdateref.current.value;
const todate=todateref.current.value;
const mode=moderef.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateemptravelbyfac', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                employee:employee,
userid:userid,
item:item,
clientid:clientid,
client:client,
fromdate:fromdate,
todate:todate,
mode:mode,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnemptravel');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    {/* <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td> */}
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Employee<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Employee" id="employee" ref={employeeref} />
<br /><br />

Enter User ID<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter User ID" id="userid" ref={useridref} />
<br /><br />

Enter Item<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Item" id="item" ref={itemref} />
<br /><br />

Enter Client colid<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Client colid" id="clientid" ref={clientidref} />
<br /><br />

Enter Client<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Client" id="client" ref={clientref} />
<br /><br />

Enter From date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter From date" id="fromdate" ref={fromdateref} />
<br /><br />

Enter To date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter To date" id="todate" ref={todateref} />
<br /><br />

<label htmlFor="mode">Mode</label>

<select id="mode" className="form-control"  ref={moderef}>
<option value="Flight">Flight</option>
<option value="Train">Train</option>
<option value="Bus">Bus</option>
<option value="Cab">Cab</option>
<option value="Own car">Own car</option>
</select>
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;