import React, { useState, useContext, useEffect, useRef } from "react";
import {Link} from 'react-router-dom';

import global1 from '../../pages/global1';


import classes from './MainNavigation1.module.css';
import classes1 from './SidecolorNavigation.module.css';
import FavoritesContext from '../../store/favourites-context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';



function SidelibNavigation() {

    const favcontxt=useContext(FavoritesContext);

    let content;

    const changeHandler = () => {
      const instype=global1.instype;
      //alert(instype);
      if(instype=='Affiliated') {
          return (
              <>
           <p><b>My Activities</b></p>
           <hr />
              {/* <Link to='/viewprojects'> View projects</Link>
        <Link to='/viewseminar'> View seminar</Link>
        <Link to='/viewpatent'> View patent</Link>
        <Link to='/viewpub'> View publications</Link>
        <Link to='/viewbooks'> View books</Link>
        <Link to='/viewinnovation'> Innovation awards</Link>
        <Link to='/viewphdguide'> PhD Guide</Link>
        <Link to='/viewpubaqar'> Books and Proceedings (AQAR)</Link>
        <Link to='/viewbosfac'> Membership </Link>
        <Link to='/viewresearchfellowadmfac'> Research fellow </Link> */}
        {/* <Link to='/viewdeletebyuser'> Delete my data </Link> */}
        <Link to='/viewntimesheet'> Timesheet </Link>
        <Link to='/viewnsopadmin'> SOP </Link>
        {/* <Link to='/viewnsop'> nSOP </Link> */}
        <br />

        <p><b>HR and Leave</b></p>
           <hr />
        <Link to='/viewleaveapply'> Apply for leave </Link>
        <Link to='/viewleavepending'> Applied leave </Link>
        <Link to='/viewleavehistory'> Leave history </Link>
        <Link to='/viewnpayslips'> Payslips</Link>
        {/* <Link to='/viewnpayslipdetails'> Payslip details</Link> */}
        
        <br />
        <p><b>Tasks and Other details</b></p>
           <hr />

        <Link to='/viewmlink'> My meeting links</Link>
        <Link to='/viewtaskassignuser'> Tasks assigned</Link>

        <br />
        {/* <p><b>Goals and profile</b></p>
           <hr /> */}
        {/* <Link to='/updatepassword'> Update profile</Link>
        <Link to='/viewgoals'> My goals</Link>
      <Link to='/viewnbaug11?name=1&question=Govt ID Card&accreditation=PERSONAL&documents=Aadhar card'>Id proof</Link>
      <Link to='/viewnbaug11?name=2&question=Govt Address proof&accreditation=PERSONAL&documents=Aadhar card, Driving license'>Address proof</Link>
      <Link to='/viewnbaug11?name=3&question=PAN card&accreditation=PERSONAL&documents=PAN card'>PAN card</Link>
      <Link to='/viewnbaug11?name=4&question=Passport&accreditation=PERSONAL&documents=Passport'>Passport</Link>
      <Link to='/viewnbaug11?name=4&question=Marksheets&accreditation=PERSONAL&documents=All marksheets'>Marksheets</Link>
      <Link to='/viewnbaug11?name=4&question=Degree Certificates&accreditation=PERSONAL&documents=All degree certificates'>Degree certificates</Link> */}
              </>
          )

      } else if(instype=='University') {
        return (
            <>
           <p><b>My Activities</b></p>
           <hr />
              {/* <Link to='/viewprojects'> View projects</Link>
        <Link to='/viewseminar'> View seminar</Link>
        <Link to='/viewpatent'> View patent</Link>
        <Link to='/viewpub'> View publications</Link>
        <Link to='/viewbooks'> View books</Link>
        <Link to='/viewinnovation'> Innovation awards</Link>
        <Link to='/viewphdguide'> PhD Guide</Link>
        <Link to='/viewpubaqar'> Books and Proceedings (AQAR)</Link>
        <Link to='/viewbosfac'> Membership </Link>
        <Link to='/viewresearchfellowadmfac'> Research fellow </Link> */}
        <Link to='/viewntimesheet'> Timesheet </Link>
        <Link to='/viewnsopadmin'> SOP </Link>

        <br />

        <p><b>HR and Leave</b></p>
           <hr />
        <Link to='/viewleaveapply'> Apply for leave </Link>
        <Link to='/viewleavepending'> Applied leave </Link>
        <Link to='/viewleavehistory'> Leave history </Link>
        <Link to='/viewnpayslips'> Payslips</Link>
        {/* <Link to='/viewnpayslipdetails'> Payslip details</Link> */}
        
        <br />
        <p><b>Tasks and Other details</b></p>
           <hr />

        <Link to='/viewmlink'> My meeting links</Link>
        <Link to='/viewtaskassignuser'> Tasks assigned</Link>

        <br />
        {/* <p><b>Goals and profile</b></p>
           <hr />
        <Link to='/updatepassword'> Update profile</Link>
        <Link to='/viewgoals'> My goals</Link>
      <Link to='/viewnbaug11?name=1&question=Govt ID Card&accreditation=PERSONAL&documents=Aadhar card'>Id proof</Link>
      <Link to='/viewnbaug11?name=2&question=Govt Address proof&accreditation=PERSONAL&documents=Aadhar card, Driving license'>Address proof</Link>
      <Link to='/viewnbaug11?name=3&question=PAN card&accreditation=PERSONAL&documents=PAN card'>PAN card</Link>
      <Link to='/viewnbaug11?name=4&question=Passport&accreditation=PERSONAL&documents=Passport'>Passport</Link>
      <Link to='/viewnbaug11?name=4&question=Marksheets&accreditation=PERSONAL&documents=All marksheets'>Marksheets</Link>
      <Link to='/viewnbaug11?name=4&question=Degree Certificates&accreditation=PERSONAL&documents=All degree certificates'>Degree certificates</Link> */}
            </>
        )
          
    } else {
        return (
            <>
          
          <p><b>My Activities</b></p>
           <hr />
              {/* <Link to='/viewprojects'> View projects</Link>
        <Link to='/viewseminar'> View seminar</Link>
        <Link to='/viewpatent'> View patent</Link>
        <Link to='/viewpub'> View publications</Link>
        <Link to='/viewbooks'> View books</Link>
        <Link to='/viewinnovation'> Innovation awards</Link>
        <Link to='/viewphdguide'> PhD Guide</Link>
        <Link to='/viewpubaqar'> Books and Proceedings (AQAR)</Link>
        <Link to='/viewbosfac'> Membership </Link>
        <Link to='/viewresearchfellowadmfac'> Research fellow </Link> */}
        <Link to='/viewntimesheet'> Timesheet </Link>
        <Link to='/viewnsopadmin'> SOP </Link>

        <br />

        <p><b>HR and Leave</b></p>
           <hr />
        <Link to='/viewleaveapply'> Apply for leave </Link>
        <Link to='/viewleavepending'> Applied leave </Link>
        <Link to='/viewleavehistory'> Leave history </Link>
        <Link to='/viewnpayslips'> Payslips</Link>
        {/* <Link to='/viewnpayslipdetails'> Payslip details</Link> */}
        
        <br />
        <p><b>Tasks and Other details</b></p>
           <hr />

        <Link to='/viewmlink'> My meeting links</Link>
        <Link to='/viewtaskassignuser'> Tasks assigned</Link>

        <br />
        <p><b>Goals and profile</b></p>
           <hr />
        {/* <Link to='/updatepassword'> Update profile</Link>
        <Link to='/viewgoals'> My goals</Link>
      <Link to='/viewnbaug11?name=1&question=Govt ID Card&accreditation=PERSONAL&documents=Aadhar card'>Id proof</Link>
      <Link to='/viewnbaug11?name=2&question=Govt Address proof&accreditation=PERSONAL&documents=Aadhar card, Driving license'>Address proof</Link>
      <Link to='/viewnbaug11?name=3&question=PAN card&accreditation=PERSONAL&documents=PAN card'>PAN card</Link>
      <Link to='/viewnbaug11?name=4&question=Passport&accreditation=PERSONAL&documents=Passport'>Passport</Link>
      <Link to='/viewnbaug11?name=4&question=Marksheets&accreditation=PERSONAL&documents=All marksheets'>Marksheets</Link>
      <Link to='/viewnbaug11?name=4&question=Degree Certificates&accreditation=PERSONAL&documents=All degree certificates'>Degree certificates</Link> */}
            </>
        )

    }
    };

    



    return (
        <div className={classes1.sidebar}>
            <div className={classes1.sidebarinside}>

<div style={{alignItems: 'center', width:'100%'}}>
                <img src="logo.png" style={{width:180}} />
                <br /><i><span style={{ fontSize: 12}}>a portfolio company of</span></i><br />
                <img src="times-internet-logo.png" style={{height:20, marginTop: 5, alignSelf: 'center', marginLeft: 0}} />
            </div>
            <hr />

            {changeHandler()}
            <br /><br />
            
        
        {/* <div style={{marginBottom: 10}}><b>My details</b></div> */}
        
        {/* <Link to='/updatequestions'> Update questions</Link> */}
     
{/* 
        <hr />
        <div style={{marginBottom: 10}}><b>Useful links</b></div>
        <Link to='/dashcourse'> Dashboard</Link>
        <Link to='/workload'> Create Class</Link>
        
        <Link to='/getmyfeedback'> My feedback</Link>
        <Link to='/proctorlist'> Proctor list</Link>
        <Link to='/examenrlist'> Check exam enrollment</Link> */}

      
        <br /><br /><br /><br /><br /><br />
     

        
        </div>

</div>
    );

}

export default SidelibNavigation;