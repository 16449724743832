import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Spondi';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const dirnameref=useRef();
const qualiexpref=useRef();
const noofattndrref=useRef();
const attndrnameref=useRef();
const totalarearef=useRef();
const outgamesref=useRef();
const ingamesref=useRef();
const gymref=useRef();
const fundsref=useRef();
const itemsncostref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const dirname=dirnameref.current.value;
const qualiexp=qualiexpref.current.value;
const noofattndr=noofattndrref.current.value;
const attndrname=attndrnameref.current.value;
const totalarea=totalarearef.current.value;
const outgames=outgamesref.current.value;
const ingames=ingamesref.current.value;
const gym=gymref.current.value;
const funds=fundsref.current.value;
const itemsncost=itemsncostref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createppuphyedubyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                dirname:dirname,
qualiexp:qualiexp,
noofattndr:noofattndr,
attndrname:attndrname,
totalarea:totalarea,
outgames:outgames,
ingames:ingames,
gym:gym,
funds:funds,
itemsncost:itemsncost,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnppuphyedu');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Name of Physical Director<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of Physical Director" id="dirname" ref={dirnameref} />
<br /><br />

Enter Qualification and Experience<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Qualification and Experience" id="qualiexp" ref={qualiexpref} />
<br /><br />

Enter Number of Attenders<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Number of Attenders" id="noofattndr" ref={noofattndrref} />
<br /><br />

Enter Name of Attender<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Name of Attender" id="attndrname" ref={attndrnameref} />
<br /><br />

Enter Total area of the playground<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Total area of the playground" id="totalarea" ref={totalarearef} />
<br /><br />

Enter Details of the outdoor games available<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Details of the outdoor games available" id="outgames" ref={outgamesref} />
<br /><br />

Enter Details of the Indoor games available<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Details of the Indoor games available" id="ingames" ref={ingamesref} />
<br /><br />

Enter Details of gymnasium available<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Details of gymnasium available" id="gym" ref={gymref} />
<br /><br />

Enter Fund alloted to Physical Education<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Fund alloted to Physical Education" id="funds" ref={fundsref} />
<br /><br />

Enter Details of Sports/ Games items available and their cost<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Details of Sports/ Games items available and their cost" id="itemsncost" ref={itemsncostref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;