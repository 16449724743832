import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';

import ep1 from '../api/ep1';
import FavoritesContext from '../store/favourites-context';

//import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage'; 
import { GoogleAuthProvider } from "firebase/auth";


function Newclasses() {

    //const provider = new firebase.auth.GoogleAuthProvider();

    const favcontxt=useContext(FavoritesContext);

    const usernameref=useRef();
    const passwordref=useRef();

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       logout();
       if (!firebase.apps.length) { //avoid re-initializing
        firebase.initializeApp({
            apiKey: "AIzaSyD4AZlzFUmachQCJYr-vlb9XKJix3NMg-E",
            authDomain: "posto-campus.firebaseapp.com",
            databaseURL: "https://posto-campus.firebaseio.com",
            projectId: "posto-campus",
            storageBucket: "posto-campus.appspot.com",
            messagingSenderId: "1002415317254",
            appId: "1:1002415317254:web:f198e146e4c2a9ab86fe87",
            measurementId: "G-W6SP1NZPMJ"
        });

        firebase.firestore().enablePersistence()
        .catch((err) => {
            if (err.code == 'failed-precondition') {
                alert('Multiple tabs open. Please use in one tab');
            } else if (err.code == 'unimplemented') {
                alert('Browser is not supported for offline persistence');
            }
        });
        
    }

    
    
       

    }, []);

    const searchapi = async () => {
        const username=usernameref.current.value;
        const password=passwordref.current.value;
        if(!username || !password) {
            alert('Please enter username and password');
            return;
        }
        
        const response = await ep1.get('/api/v1/loginapi', {
            params: {
                email: username.toLowerCase(),
                password: password
                

            }
        });
        if (response.data.status == "Success") {
            const user=response.data.user;
            const name=response.data.name;
            const colid=response.data.colid;
            const role=response.data.role;
            localStorage.setItem('user', user);
            localStorage.setItem('name', name);
            localStorage.setItem('colid', colid);
            localStorage.setItem('role', role);
            const department=response.data.department;
            localStorage.setItem('department', department);
            localStorage.setItem('admincolid', colid);
            global1.studid = response.data.user;
            global1.user = response.data.user;
            global1.name = response.data.name;
            global1.name1 = response.data.name;
            global1.colid = response.data.colid;
            global1.admincolid = response.data.colid;
            global1.token = response.data.token;
            global1.department = response.data.department;
            global1.programcode = response.data.programcode;
            // //global1.programid = response.data[0].programid;
            // //global1.batch = response.data[0].batch;
            global1.regno = response.data.regno;
            // //global1.photos1 = response.data[0].photos1;
            global1.semester = response.data.semester;
            global1.section = response.data.section;
            global1.role=response.data.role;
            //alert(colid + '-' + name + '-' + user);

            global1.aqaryear='2020-21';
            global1.calendaryear='2020';
            
            
            const fuser = firebase.auth().currentUser;
            if (fuser != null) {    
            } else {
                skipuser();
            }

            const response1 = await ep1.get('/api/v1/getinstitutionname', {
                params: {
                    user: user,
                    token: response.data.token,
                    colid: colid
                }
            });

            global1.instype='';
            global1.insname='';

            global1.bulkuploadurl='https://canvasapi2.azurewebsites.net/';

            //global1.bulkuploadurl='https://canvasapi1.azurewebsites.net/';

            //global1.bulkuploadurl='http://localhost:3000/';

            var status1='Submitted';

            try {
                status1=response1.data.data.classes[0].status;
            } catch(err) {

            }
            if(status1=='Blocked') {

                history.replace('/noaccess');
                return;

            }




            
            try {
                //console.log(response1.data.data.classes);
                //alert(response1.data.data.classes[0].type + ' ' + response1.data.data.classes[0].institutionname);
                global1.instype=response1.data.data.classes[0].type;
                localStorage.setItem('instype', response1.data.data.classes[0].type);
            } catch(err) {

            }
            try {
                //console.log(response1.data.data.classes);
                //alert(response1.data.data.classes[0].type + ' ' + response1.data.data.classes[0].institutionname);
                global1.insname=response1.data.data.classes[0].institutionname;
                localStorage.setItem('insname', response1.data.data.classes[0].institutionname);
            } catch(err) {

            }
            var name1=name;
            try {
                //console.log(response1.data.data.classes);
                //alert(response1.data.data.classes[0].type + ' ' + response1.data.data.classes[0].institutionname);
                name1=name1 + ' ' + response1.data.data.classes[0].institutionname;
                localStorage.setItem('name1', name1);
                
            } catch(err) {

            }

            favcontxt.addFavorite({
                studid: user,
                name: name1,
                course: 0,
            },response.data.role,colid,name1);

            if (response.data.role=='Student') {
                const response12 = await ep1.get('/api/v1/getcurrentyearbyprg', {
                    params: {
                        programcode: response.data.programcode,
                        semester: response.data.semester,
                        section: response.data.section,
                        token: response.data.token,
                        colid: colid
                    }
                });
                var lmsyear='2022-23';
                console.log(response12.data.data);

                
                try {
                    lmsyear= response12.data.data.classes[0].year;

                } catch (err) {

                }
                global1.lmsyear=lmsyear;

                history.replace('/dashstud1');
                //alert('Student login is not enabled. Please login from app');
            } else if (response.data.role=='Faculty') {
                history.replace('/dasherp1');
            } else if (response.data.role=='Admin') {
                history.replace('/dasherpadmin1');
            } else if (response.data.role=='Super') {
                history.replace('/dashmydetails');
            } else if (response.data.role=='HoD') {
                history.replace('/dasherp1');
            }
            

            setTerm2('Thank you');  
            //navigation.navigate('Nland1');  
        }
        else {
            alert('Invalid Username or Password. Please try again.');
            setTerm2('Invalid Username or Password. Please try again.');
        }
        //history.replace('/viewtasks');
       
    };

    async function submitHandler(event) {
        event.preventDefault();
        const username=usernameref.current.value;
        const password=passwordref.current.value;

        console.log(username + '-' + password)
        
        //alert(username + '-' + password);
        const response = await ep1.get('/api/v1/loginapi', {
            params: {
                email: username.toLowerCase(),
                password: password

            }

        });
        //setLoading(false);
        //setResults(response.data);
        setTerm2('');
        console.log(response.data.status);

        if (response.data.status == "Success") {
            //addUserid(response.data[0].id);
            //props.navigation.navigate('Wv');
            //alert(response.data.user);
            const user=response.data.user;
            const name=response.data.name;
            const colid=response.data.colid;
            const role=response.data.role;
            localStorage.setItem('user', user);
            localStorage.setItem('name', name);
            localStorage.setItem('colid', colid);
            localStorage.setItem('role', role);
            const department=response.data.department;
            localStorage.setItem('department', department);
            localStorage.setItem('admincolid', colid);
            global1.studid = response.data.user;
            global1.user = response.data.user;
            global1.name = response.data.name;
            global1.name1 = response.data.name;
            global1.colid = response.data.colid;
            global1.admincolid = response.data.colid;
            global1.token = response.data.token;
            global1.department = response.data.department;
            global1.programcode = response.data.programcode;
            // //global1.programid = response.data[0].programid;
            // //global1.batch = response.data[0].batch;
            global1.regno = response.data.regno;
            // //global1.photos1 = response.data[0].photos1;
            global1.semester = response.data.semester;
            global1.section = response.data.section;
            global1.role=response.data.role;
            //alert(colid + '-' + name + '-' + user);

            global1.aqaryear='2020-21';
            global1.calendaryear='2020';
            
            
            const fuser = firebase.auth().currentUser;
            if (fuser != null) {    
            } else {
                skipuser();
            }

            const response1 = await ep1.get('/api/v1/getinstitutionname', {
                params: {
                    user: user,
                    token: response.data.token,
                    colid: colid
                }
            });

            global1.instype='';
            global1.insname='';

            var status1='Submitted';

            try {
                status1=response1.data.data.classes[0].status;
            } catch(err) {

            }
            if(status1=='Blocked') {

                history.replace('/noaccess');
                return;

            }




            
            try {
                //console.log(response1.data.data.classes);
                //alert(response1.data.data.classes[0].type + ' ' + response1.data.data.classes[0].institutionname);
                global1.instype=response1.data.data.classes[0].type;
                localStorage.setItem('instype', response1.data.data.classes[0].type);
            } catch(err) {

            }
            try {
                //console.log(response1.data.data.classes);
                //alert(response1.data.data.classes[0].type + ' ' + response1.data.data.classes[0].institutionname);
                global1.insname=response1.data.data.classes[0].institutionname;
                localStorage.setItem('insname', response1.data.data.classes[0].institutionname);
            } catch(err) {

            }
            var name1=name;
            try {
                //console.log(response1.data.data.classes);
                //alert(response1.data.data.classes[0].type + ' ' + response1.data.data.classes[0].institutionname);
                name1=name1 + ' ' + response1.data.data.classes[0].institutionname;
                localStorage.setItem('name1', name1);
                
            } catch(err) {

            }

            favcontxt.addFavorite({
                studid: user,
                name: name1,
                course: 0,
            },response.data.role,colid,name1);

            if (response.data.role=='Student') {
                history.replace('/dashstud1');
                //alert('Student login is not enabled. Please login from app');
            } else if (response.data.role=='Faculty') {
                history.replace('/dasherp1');
            } else if (response.data.role=='Admin') {
                history.replace('/dasherpadmin1');
            } else if (response.data.role=='Super') {
                history.replace('/dashmydetails');
            } else if (response.data.role=='HoD') {
                history.replace('/dasherp1');
            }
            

            setTerm2('Thank you');  
            //navigation.navigate('Nland1');  
        }
        else {
            alert('Invalid Username or Password. Please try again.');
            setTerm2('Invalid Username or Password. Please try again.');
        }

    }

    const submnitoffline = async() => {
        
        let user=localStorage.getItem("user");
        global1.user=user;
        global1.studid=user;
        let name=localStorage.getItem("name");
        global1.name=name;
        let role=localStorage.getItem("role");
        global1.role=role;
        let department=localStorage.getItem("department");
        global1.department=department;
        //let name1=localStorage.getItem("name1");
        //global1.name1=name1;
        let colid=localStorage.getItem("colid");
        global1.colid=colid;
        //let user=localStorage.getItem("user");
        global1.admincolid=colid;
        favcontxt.addFavorite({
            studid: user,
            name: name,
            course: 0,
        },role,colid,name);
        //alert(name + ' ' + user + ' ' + role);
        if (role=='Student') {
            history.replace('/dashstud1');
            //alert('Student login is not enabled. Please login from app');
        } else if (role=='Faculty') {
            //history.replace('/dashmydetails');
            history.replace('/offlinewelcome');
        } else if (role=='Admin') {
            history.replace('/dashcriteria1');
        } else if (role=='Super') {
            history.replace('/dashmydetails');
        } else if (role=='HoD') {
            history.replace('/dashmydetails');
        }

        
    }

    function offlinelogin() {
        
       
        const fuser = firebase.auth().currentUser;
            if (fuser != null) {    
                global1.studid = localStorage.getItem('user');
                global1.user = localStorage.getItem('user');
                global1.name = localStorage.getItem('name');
                global1.name1 = localStorage.getItem('name');
                global1.colid = parseInt(localStorage.getItem('colid'));
                history.replace('/dashcoursef');
            } else {
                alert('You have logged out of offline access');
            }

    }

    function skipuser() {
        firebase.auth().signInAnonymously().then( function() {
            const user = firebase.auth().currentUser;
            if (user != null) {
                //const name = user.displayName;
                const email = user.email;
                const photoUrl = user.photoURL;
                const emailVerified = user.emailVerified;
                const uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
                // this value to authenticate with your backend server, if
                // you have one. Use User.getToken() instead.
                const token1 = user.getIdToken();
                //alert(name + ' ' + email + ' ' + uid + ' ' + photoUrl + ' ' + token1);

                //global1.email1 = email;
                //global1.email = email;
                //global1.uid = uid;
                //global1.photoURL = photoUrl;
                //props.navigation.navigate('Ftab2');
                //searchApi(uid);


            }
        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            alert('Error ' + errorCode + '-' + errorMessage);
            // ...
          });
    }

    function googleSignInPopup() {
        // [START auth_google_signin_popup]
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth()
          .signInWithPopup(provider)
          .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;
      
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            console.log(user);
            //alert('signed in' + user.email + '-' + user.photoURL + '-' + user.displayName + '-' + user.uid );
            global1.studid = user.email;
            global1.user = user.email;
            global1.name = user.displayName;
            global1.name1 = user.displayName;
            const name=user.displayName;
            localStorage.setItem('user', user.email);
            localStorage.setItem('name', user.name);
        

            checkcolid(user.email, name);

            
            // ...
          }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
        // [END auth_google_signin_popup]
      }

    async function checkcolid(useremail, name) {
        const response = await ep1.get('/api/v1/loginapif', {
            params: {
                email: useremail

            }

        });
        //setLoading(false);
        //setResults(response.data);
        setTerm2('');
        console.log(response.data.status);

        if (response.data.status == "Success") {
            // const user=response.data.user;
            // const name=response.data.name;
            // const colid=response.data.colid;
            // const role=response.data.name;
            // global1.studid = response.data.user;
            // global1.user = response.data.user;
            // global1.name = response.data.name;
            // global1.name1 = response.data.name;
            global1.colid = response.data.colid;
            global1.token = response.data.token;
            localStorage.setItem('colid', response.data.colid);
            
            // //global1.programid = response.data[0].programid;
            // //global1.batch = response.data[0].batch;
            global1.regno = response.data.regno;
            // //global1.photos1 = response.data[0].photos1;
            global1.semester = response.data.semester;
            global1.section = response.data.section;
            global1.role=response.data.role;
            //alert(colid + '-' + name + '-' + user);
            favcontxt.addFavorite({
                studid: useremail,
                name: name,
                course: 0,
            },response.data.role,response.data.colid,name);
            
           
            if (response.data.role=='Student') {
                //history.replace('/exam');
                alert('Student login is not enabled. Please login from app');
            } else if (response.data.role=='Faculty') {
                history.replace('/dashcourse');
            }
            

            setTerm2('Thank you');  
            //navigation.navigate('Nland1');  
        }
        else {
            //alert('Invalid Username or Password. Please try again.');
            setTerm2('Invalid Username or Password. Please try again.');
        }

      }


      const uploadlink = async (link1) => {
        //link1=link1 + '?criteria=3&Section=3.4.3&Subsection=Patent&field1=' + id1 + '&Year=' + yop1 + '&userid=' + user; 
        var win = window.open(link1, '_blank');
        win.focus();
      };

  


    return (

<div className="container">
                {/* <div className="row">
                <div className="col-1"> */}
                         {/* <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addstudentsext()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add All Students</p>

                        </div> */}
                        {/* <div style={{width:300}}> */}
                        <br /><br />
                        <img width="50%" src="https://jadavpuruniversity.s3-ap-south-1.amazonaws.com/8202262014-campus-tech-linear-logo-full-color-rgb-720px@300ppi.jpg" />
                        <br /><br />
                    Start something new....
                    <br /><br />
                    <p>Enter username</p>
                    <input type="text" required style={{width: "100%"}} placeholder="Enter username" id="username" ref={usernameref} />
                    <br /><br />
                    <p>Enter password</p>
                    <input type="password" required style={{width: "100%"}} placeholder="Enter password" id="password" ref={passwordref} />
                    <br /><br />
                    <button  onClick={searchapi}  className="btn btn-dark">Login</button>
                    {/* </div> */}

                {/* </div>

                </div> */}
                </div>


        // <div style={{width: 900, margin: 'auto', marginTop: 100}}>
        //     <div className={classes.logindiv}>
        //    Start something new...
        //     <br />
        //     <form className={classes.form} onSubmit={submitHandler}>
        //         <div className={classes.control}>
        //             <label htmlFor='username'>User name</label>
        //             <input type="text" required placeholder="Enter username" id="username" ref={usernameref} />
        //         </div>
        //         <div className={classes.control}>
        //             <label htmlFor='password'>Password</label>
        //             <input type="password" required placeholder="Enter password" id="password" ref={passwordref} />
        //         </div>
        //         <div className={classes.actions}>
        //             <button>Login</button>
        //         </div>
        //         <br />
        //         {term2}
            
        //    </form>
        //    <br />
        //    <table>
        //        <tr>
        //            <td>
        //            <div className={classes.actions}>
        //             <button
        //             onClick={() => googleSignInPopup()}
        //             >Login with Google</button>
        //             </div>

        //            </td>
        //            <td width="20px"></td>
        //            <td>
                  
        //         <div className={classes.actions}>
        //             <button
        //             onClick={() => submnitoffline()}
        //             >Offline login new</button>
        //         </div>
        //            </td>
        //            <td width="20px"></td>
        //            <td>
        //            <div className={classes.actions}>
        //             <button
        //             onClick={() => uploadlink('https://ctnodeapps2.azurewebsites.net/login')}
        //             >Campus Canvas (Regular)</button>
        //         </div>
        //            </td>
        //        </tr>
        //    </table>
        //    <br /><br />
        //    Version 3.06.09196 Copyright @ Campus.Technology 2022
        //    <br /><br />
           
        //    </div>
        // </div>
    );

}

export default Newclasses;