import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faCube, faVideo, faCubes, faFile, faUsers, faDatabase, faTachometerAlt, faComment, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import SidefacNavigation from '../components/layout/SidefacNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    //const name=global1.name;
    const coursename=global1.coursename;
    const coursecode1=global1.coursecode;
    const courseid=global1.courseid; 
    const section=global1.section;
    const semester=global1.semester;
    const aqaryear=global1.aqaryear;

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const viewstudents = () => {
        history.replace('/classstudents');
    }
    const viewstudentsm = () => {
        history.replace('/classstudentsm');
    }
    const viewstudentsmsec = () => {
        history.replace('/classstudentsmsec');
    }

    const addassignments = () => {
        history.replace('/addassignments');
    }

    const addassignmentsnew = () => {
        history.replace('/viewnassignment');
    }

    const viewassignments = () => {
        history.replace('/classassignments');
    }
    const viewstudymaterial = () => {
        history.replace('/classfiles');
    }

    const addstudymaterial = () => {
        history.replace('/addcoursefiles');
    }

    const gotoattendance = () => {
        // history.replace('/classattendance');
        history.replace('/selectattendance');
    }

    const gotoco = () => {
        // history.replace('/classattendance');
        history.replace('/viewco');
    }

    const gotoannouncement = () => {
        // history.replace('/classattendance');
        history.replace('/viewlannouncement');
    }

    const gotosyllabus = () => {
        // history.replace('/classattendance');
        history.replace('/viewlsyllabus');
    }

    const gotocalendar = () => {
        // history.replace('/classattendance');
        history.replace('/viewlcalendar');
    }

    const addmodules = () => {
        history.replace('/addlessonplan');
    }

    const viewmodules = () => {
        history.replace('/lessonplan');
    }
    const gotodynamicmodules = () => {
        history.replace('/viewdynamicmodules');
    }

    const addlessonplan = () => {
        history.replace('/viewlessonnew');
    }

    const addstudents = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudents');
    }

    const addstudentsext = () => {
        //global1.courseid=courseid;
        //global1.coursecode=coursecode;
        //global1.coursename=coursename;
        history.replace('/enrollstudentsext');
    }

    const courselinks = () => {
       
        history.replace('/viewcoursefiles');
    }

    

   


    const searchApi = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getworkload', {
            params: {
                user: user,
                token: token

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults(response.data.data.classes);
        favcontxt.addFavorite({
            studid: user,
            name: name,
        },role,colid,name,response.data.data.classes.length);
        //alert(results.length);
       
    };

    const getperiod = async () => {
        setLoading(true);
        setTerm(studid);
        const response = await ep1.get('/api/v1/getperiod', {
            params: {
                colid: colid

            }

        });
        setLoading(false);
        console.log(response.data.data);
        setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const deleteperiod = async (periodid) => {
        setLoading(true);
        //setTerm(studid);
        const response = await ep1.get('/api/v1/deleteperiod', {
            params: {
                id: periodid
            }

        });
        setLoading(false);
        //console.log(response.data.data);
        getperiod();
        //setResults1(response.data.data.classes);
        //alert(results.length);
       
    };

    const getclass = async () => {
        setLoading(true);
        setTerm(studid);
        // const response = await ep1.get('/api/v1/getclass', {
        //     params: {
        //         user: user
        //     }
        // });
        var dt1=new Date(date2);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
        //alert(results.length);
       
    };

    useEffect(() => {
        checklogin();
        //search1();
        //getexamlist();
        //searchApi('');
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);

    function checkdata(link, ccode) {
        //alert (examcode + '-' + examname);
        if (link=='NA') {
            link="https://meet.jit.si/" + ccode;
        }
    }

    const _handlePressButtonAsync = async (classid, link) => {
        if(link=="NA") {
            link="https://meet.jit.si/" + classid;
        }
        var win = window.open(link, '_blank');
        win.focus();
      };

    const onDragOver = (ev) => {
        ev.preventDefault();
    }
    const onDragStart = (ev, ccode,  course, section, semester, program) => {
        //ev.preventDefault();
        ev.dataTransfer.setData("ccode", ccode);
        ev.dataTransfer.setData("course", course);
        ev.dataTransfer.setData("section", section);
        ev.dataTransfer.setData("semester", semester);
        ev.dataTransfer.setData("program", program);
        //setCoursecode(ccode);
    }
    const onDrop = async (ev, starttime, endtime) => {
        let ccode = ev.dataTransfer.getData("ccode");
        let course = ev.dataTransfer.getData("course");
        let section = ev.dataTransfer.getData("section");
        let semester = ev.dataTransfer.getData("semester");
        let program = ev.dataTransfer.getData("program");
        const module=moduleref.current.value;
        const link=linkref.current.value;
        const topic=topicref.current.value;
        if(!module) {
            alert("Please enter module");
            return;
        }
        if(!topic) {
            alert("Please enter topic");
            return;
        }
        if(!link) {
            alert("Please enter link");
            return;
        }
        
        setCoursecode(ccode);
        const response = await ep1.get('/api/v1/createclass', {
            params: {
                user: user,
                name: name,
                colid: colid,
                classdate: datechange,
                weeks: 0,
                coursecode: ccode,
                course: course,
                link: link,
                program: program,
                semester: semester,
                section: section,
                module: module,
                topic: topic,
                classtime: starttime



            }

        });
        if (response.data.status == "Success") {
            
            //history.replace('/workload');
            //setTerm2('Thank you');  
            getclass();
            alert('Data updated successfully');
        }
        //setResults(response.data.data.classes);

        //alert(ccode + '-' + starttime + '-' + endtime + '-' + datechange + '-' + module + '-' + topic + '-' + link);

    }

    async function refreshclass(newdate) {
        
        var dt1=new Date(newdate);
        setDate2(dt1);
        const response = await ep1.get('/api/v1/getclassbydate', {
            params: {
                user: user,
                classdate: dt1
            }
        });
        setLoading(false);
        console.log(response.data.data);
        setResults2(response.data.data.classes);
    }

    const width=window.innerWidth;
    const mdivwidth=width - 330;

    
    const timely = (date1) => {
        var dt1=new Date(date1);
        dt1.setHours(dt1.getHours() - 5);
        dt1.setMinutes(dt1.getMinutes() - 30);
        var hours=dt1.getHours();
        var minutes=dt1.getMinutes();
        var month=dt1.getMonth() + 1;
        if(minutes.length<2) {
            minutes='0' + minutes;
        }
        var t1;
        if(hours>11) {
            t1='PM';
        } else {
            t1='AM';
        }
        if(hours>12) {
            hours=hours-12;
        }
        var dt2=dt1.getDate() + '/' + month + '/' + dt1.getFullYear() + ' ' + hours + ':' + minutes + t1;
        return dt2;

    }


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        {/* <SidefacNavigation /> */}
                        <SidecolorNavigation />
                    </td>
                   
                    <td> 

                    <TopNavigation />
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}><Link to='/dashcourse'>Dashboard</Link> - Course Dashboard for {coursename} {coursecode1} Section {section} Semester {semester} for {aqaryear}</p>
            <hr />
           
    
        <br />
        

        

        <div className="container">
                <div className="row">
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => viewstudents()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faUsers} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>View Students</p>

                        </div>
                </div> */}
                 {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addstudents()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Students</p>

                        </div>
                </div> */}
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => viewstudentsm()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faUsers} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>View Students</p>

                        </div>
                </div> */}
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addstudentsext()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faUserPlus} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add All Students</p>

                        </div>
                </div>
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => viewstudentsmsec()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faUsers} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>View All Students</p>

                        </div>
                </div>
               
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addassignmentsnew()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Assignments (new)</p>

                        </div>
                </div>
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addassignments()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faCubes} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Assignments (old)</p>

                        </div>
                </div> */}
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => viewassignments()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faCube} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>View Assignments (old)</p>

                        </div>
                </div> */}
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addstudymaterial()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faDatabase} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Study Material</p>

                        </div>
                </div>
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => viewstudymaterial()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faFile} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>View Study Material</p>

                        </div>
                </div> */}

                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => courselinks()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faFile} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>All Course File and Links</p>

                        </div>
                </div>
               
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addlessonplan()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faDatabase} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Lesson Plan</p>

                        </div>
                </div>
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addmodules()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add modules</p>

                        </div>
                </div>
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => viewmodules()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>View modules</p>

                        </div>
                </div> */}
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => addstudymaterial()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faVideo} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Add Class</p>

                        </div>
                </div> */}
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => gotoattendance()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Course Attendance</p>

                        </div>
                </div>
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => gotoco()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faBook} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Course Outcome</p>

                        </div>
                </div>
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => gotoannouncement()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faTachometerAlt} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Course Announcement</p>

                        </div>
                </div>
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => gotosyllabus()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faCheckSquare} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Course Syllabus</p>

                        </div>
                </div>
                <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => gotocalendar()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Course Calendar</p>

                        </div>
                </div>
                {/* <div className="col-4">
                         <div className={classes1.card} style={{ textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => gotodynamicmodules()}
                         >
                             <br />
                             <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 28, color: '#FC2406', marginRight: 10, cursor: 'pointer' }} 
                    />
                    <br /><br />
                             
                             <p style={{ fontSize: 18}}>Dynamic modules</p>

                        </div>
                </div> */}
            </div></div>

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;