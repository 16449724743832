import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faEdit, faFlag, faCheckCircle, faComment, faFileAlt, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import Sideaqar5 from '../components/layout/ReportAdmin';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);


    const name=favcontxt.name;

    const history=useHistory();

    const commentsref=useRef();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState();
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());
    const [format, setFormat] = useState(false);

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    const buser=global1.buser;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    let { id } = useParams();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }

      let query = useQuery();

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    // const edit = (id, date, title, sname) => {
    //     global1.sportsactid=id;
    //     global1.date=date;
    //     global1.title=title;
    //     global1.sname=sname;
        
    //     history.replace('/editsportsact');
    // }

    const addperiod = () => {
        history.replace('/addperiod');
    }

    const addstudents = () => {
        // global1.courseid=courseid;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/addstudents');
    }

    function getaffiliated(affvalue) {
        if(affvalue=="1") {
            return "Yes";
        } else {
            return "No";
        }
    }

    const gobacknav = () => {
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
        
    };

    

    // useEffect(() => {
    //     //logout();
    //     titleref.current.value=global1.title;
    //     journalref.current.value=global1.journal;
    //     yopref.current.value=global1.yop;
    //     issnref.current.value=global1.issn;
    //     articlelinkref.current.value=global1.articlelink;
    //     journallinkref.current.value=global1.journallink;
    //     ugclistedref.current.value=global1.ugclisted;
 
    //  }, []);

    let tutorials = [];
    const searchApi = async () => {
        //let tutorials = [];
        //setResults([]);
        setLoading(true);
        setIsuploading(true);
        setTerm(studid);
        // const field1=global1.field1;
        // const criteria=global1.criteria;
        // const metric=global1.metric;
        // const buser=global1.buser;
        const token=query.get("token");
        const colid=query.get("colid");
        const field1=query.get("field1");
        const response = await ep1.get('/api/v1/getsupportingdocbyfield23', {
            params: {
                token: token,
                colid: colid,
                field1: field1
            }
        });
        setLoading(false);
        setIsuploading(false);
        console.log(response.data.data);
        //localStorage.setItem("p_supportingdoc", JSON.stringify(response.data.data.classes));

       

        
        setResults(response.data.data.classes);
        setLink(response.data.data.link);
        //alert('format ' + format);
        // try{

        //     formattable();
        // } catch(err) {

        // }
        
       
        //alert(results.length);
       
    };

    const searchApi1 = async () => {
        //setLoading(true);
        const token=query.get("token");
        const colid=query.get("colid");
        const field1=query.get("field1");
        const response = await ep1.get('/api/v1/getaccrcomments', {
            params: {
                colid: colid,
                field1: field1,
                token: token

            }

        });
        setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        setResults1(response.data.data.classes);
    };

    const deletecomments = async (commentsid) => {
        //setLoading(true);
        const token=query.get("token");
        const colid=query.get("colid");
        const field1=query.get("field1");
        const response = await ep1.get('/api/v1/deleteaccrcomments', {
            params: {
                colid: colid,
                field1: field1,
                token: token,
                id: commentsid

            }

        });
        setLoading(false);
        //console.log('data');
        //console.log(response.data.data.classes);
        //setResults1(response.data.data.classes);
        alert(response.data.status);
        searchApi1();
    };

    const updatestatus = async (status1, id) => {
        const token=query.get("token");
        const colid=query.get("colid");
        const user1=query.get("user");
        const response = await ep1.get('/api/v1/updatesupportingcomments', {
            params: {
                user: user1,
                token: token,
                colid: colid,
                status1: status1,
                comments: "NA",
                id: id

            }

        });
        setLoading(false);
        alert(response.data.status + ' - ' + status1);
    };

    function formattable() {
        if (!format) {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setFormat(true);

        }
    }

   

   


    const addcomments = async () => {
        //setLoading(true);
        const token=query.get("token");
        const colid=query.get("colid");
        const field1=query.get("field1");
        const name=query.get("name");
        const user=query.get("user");
        //const comments=commentsref.current.value;
        const dt1=new Date();
        var months=dt1.getMonth() + 1;
        var dt2=dt1.getDate() + '/' + months + '/' + dt1.getFullYear();

        // const comments=dt2 + ' ' + commentsref.current.value;
        const comments=dt2 + ' ' + name + ' ' + commentsref.current.value;
        const response = await ep1.get('/api/v1/createaccrcomments', {
            params: {
                colid: colid,
                field1: field1,
                token: token,
                name: name,
                user: user,
                comments: comments,
                metric: '6.3.2',
                type:'Iqac'

            }

        });
        setLoading(false);
        searchApi1();
   
    };

    const addcomments1 = async (comments) => {
        //setLoading(true);
        const token=query.get("token");
        const colid=query.get("colid");
        const field1=query.get("field1");
        const name=query.get("name");
        const user=query.get("user");
        //const comments=commentsref.current.value;
        const response = await ep1.get('/api/v1/createaccrcomments', {
            params: {
                colid: colid,
                field1: field1,
                token: token,
                name: name,
                user: user,
                comments: comments,
                metric: '6.3.2',
                type:'Iqac'

            }

        });
        setLoading(false);
        searchApi1();
   
    };
    

    useEffect(() => {
        //checklogin();
        //search1();
        //getexamlist();
        // getlocal();
        searchApi('');
        searchApi1();
        //alert(window.location.origin);
        //$('#example').DataTable();
        //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable();
    //     },
    //     1000
    //     );
    // });
    
    //initialize datatable
    // $(document).ready(function () {
    //     setTimeout(function(){
    //     $('#example').DataTable(
    //         {
    //             pagingType: 'full_numbers',
    //               pageLength: 5,
    //               processing: true,
    //               dom: 'Bfrtip',
    //                   buttons: ['copy', 'csv', 'print'
    //                   ]
    //         }
    //     );
    //     } ,
    //     1000
    //     );
    // });
        //getperiod();
        //getclass();
        //searchApi1('');

    }, []);


    // const uploadlink = async (link1, id1, year) => {
    //     link1=link1 + '?criteria=5&Section=5.3.3&Subsection=SportsCulturalActivities&field1=' + id1 + '&Year=' + year; 
    //     var win = window.open(link1, '_blank');
    //     win.focus();
    //   };

    


    
   

    

    const width=window.innerWidth;
    const mdivwidth=width - 330;


    const changeHandler = (id1) => {
        const role=global1.role;
        //alert(instype);
        if(role=='Admin') {
            return (
                <>

{/* <Button variant="outline-danger" onClick={() => updatestatus('Accepted',meetup3._id)}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Accept
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Flagged',meetup3._id)}>
                        <FontAwesomeIcon icon={faFlag} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Flag
                    </Button>  */}
             
          
       
                </>
            )
  
        }  else {
          return (
              <>
            
        
              </>
          )
  
      }
      };


   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    {/* <td valign="top" style={{ width: 60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <Sideaqar5 />
                    </td> */}
                   
                    <td> 
                        {/* <TopNavigation /> */}

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
            <p style={{ fontSize: 18 }}>View Supporting Documents and comments</p>
            <hr />
            {/* <p>Supporting documents uploaded earlier is being migrated to a cloud based server and will be available here after Nov 15, 2021</p>
          
        <br /><br /> */}

        {/* <button  onClick={gobacknav}>Go back</button> */}

<br /><br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
     
     <p style={{ fontSize: 18 }}> Add comments</p>
                        <hr />
                       
                            Comment
                            <br />
                            <input type="text" required style={{width: '100%'}} placeholder="Enter comment" id="comments" ref={commentsref} />
                            
                        <br /><br />

                        <button  onClick={addcomments}>Add comment</button>

<br /><br />
{/* <table>
    <tr>
        <td>
        <button  onClick={addcomments1("Documents missing. Please add all documents as per task list")}>Missing documents</button>
        </td>
        <td>
        <button  onClick={addcomments1("Documents not clear. Only full page scanned documents are accepted.")}>Not clear</button>
        </td>
        <td>
        <button  onClick={addcomments1("You have uploaded only fe documents. Please upload all documents as per task list for every data item.")}>Not clear</button>
        </td>
    </tr>
    </table>
    <br /><br /> */}

<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
        {/* <th>User Email</th>
        <th>Name </th>
        <th>Type</th> */}
        <th>Criteria</th>
        <th>Metric</th> 
        <th>Type</th> 
        <th>File name</th>
        <th>Link</th> 
        <th>Status</th>
        <th>Action</th>            
        
    </tr>
    </thead>
    <tbody>
    

    {results.map((meetup3) => {
                return (
                    <tr>
                        {/* <td>
                            {meetup3.user}
                        </td>
                        <td>
                            {meetup3.name}
                        </td>
                        <td>
                            {meetup3.type}
                        </td> */}
                        <td>
                            {meetup3.criteria}
                        </td>
                        <td>
                            {meetup3.metric}
                        </td>
                        <td>
                            {meetup3.type}
                        </td>
                        <td>
                            {meetup3.filename}
                        </td>
                        <td>
                           <a target="_blank" href={meetup3.link}>{meetup3.link}</a>
                        </td>
                        <td>
                            {meetup3.status1}
                        </td>
                        <td>

                    {/* {changeHandler()} */}
                    <Button variant="outline-danger" onClick={() => updatestatus('Accepted',meetup3._id)}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Accept
                    </Button> 

                    <Button variant="outline-danger" onClick={() => updatestatus('Flagged',meetup3._id)}>
                        <FontAwesomeIcon icon={faFlag} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                        Flag
                    </Button> 
                        </td>
                        {/* <td>
                        {link.map((meetup31) => {
                return (
                    <div>
                          <Button variant="outline-danger" onClick={() => uploadlink(meetup31.uploadlink,meetup3._id, meetup3.year)}>
                        <FontAwesomeIcon icon={faFileAlt} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                         
                       
                        </div>

                    );
                })}
                        <Button variant="outline-danger" onClick={() => deleteenrolment(meetup3._id)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => edit(meetup3._id,meetup3.date, meetup3.title, meetup3.sname)}>
                        <FontAwesomeIcon icon={faEdit} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                        </td> */}
                
                    
                
                
                   

                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />


<h4>Comments</h4><br /><br />
<Table striped bordered hover id="example">
{/* <table id="mytable"> */}
<thead>
<tr>
        {/* <th>User Email</th>
        <th>Name </th>
        <th>Type</th> */}
        <th>Name</th>
     
        <th>Comment</th>
        <th>Action</th>
                  
        
    </tr>
    </thead>
    <tbody>
    

    {results1.map((meetup31) => {
                return (
                    <tr>
                      
                        <td>
                            {meetup31.name}
                        </td>
                      
                        <td>
                            {meetup31.comments}
                        </td>
                        <td>
                        <Button variant="outline-danger" onClick={() => deletecomments(meetup31._id)}>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 18, color: '#FC2406', marginRight: 0, cursor: 'pointer' }} />
                            </Button>
                        </td>
                      
                   
                                </tr>
              

                );
            })}
            </tbody>
            </Table>

            <br /><br />


            {/* <h4>Document List</h4>
        <hr />
1. Circular/ Notice with proper ref.no<br />
2. Brochure of the event <br />
3. Report(min. 2 pages) <br />
4. Participation list <br />
5. Certificates (if any) <br />




        
 */}

            

       
        
        </div>
        <FooterNav1 />
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;