import ep1 from '../api/ep1';
import React, { useState, useEffect, useContext, useRef } from "react";
import global1 from './global1';
import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import FavoritesContext from '../store/favourites-context';
import classes from './form.module.css';
import DatePicker from 'react-date-picker';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faUpload, faBook, faTrash, faHome, faVideo, faBinoculars, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus, faUser, faEdit } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@material-ui/data-grid';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery'; 

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidecolorNavigation from '../components/layout/SidecolorNavigation';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';
import Sideicon from '../components/layout/Sideicon';
import OfficeNavigation from '../components/layout/SidelibNavigation';


// import classes from './Allclasses.module.css';

import classes1 from './newform2.module.css';

import Spinner from 'react-bootstrap/Spinner';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

function Allclasses() {

    const favcontxt=useContext(FavoritesContext);

    const linkref=useRef();
    const moduleref=useRef();
    const topicref=useRef();

    const name=favcontxt.name;

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [results, setResults] = useState([]);
    const [results1, setResults1] = useState([]);
    const [results2, setResults2] = useState([]);
    const [link, setLink] = useState();
    const [loading, setLoading] = useState();
    const [prepare, setPrepare] = useState(true);
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const user=global1.user;
    const token=global1.token;
    const role=global1.role;
    const coursecode1=global1.coursecode;
    const coursename=global1.coursename;
    const assignmentid=global1.assignmentid;
    const assignmentname=global1.assignmentname;
    //const name=global1.name;

    const [isUploading, setIsuploading] = useState(false);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 70 },
        { field: 'student', headerName: 'Student', width: 130 },
        { field: 'regno', headerName: 'Reg no', width: 130 },
      ];

    const checklogin = () => {
        const studid=global1.studid;
        if (!studid) {
            //alert('login');
            history.replace('/new');
        }
    }
    const addnewstudent = () => {
        history.replace('/enrollstudents');
    }

    const addassets = () => {
        history.replace('/addasset');
    }

    const assignmentdetails = (id, asset) => {
        global1.assetid=id;
        global1.asset=asset;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/assetassignment');
    }

    

   

    

    function converttodt() {
        try {
            $('#example').DataTable(
                {
                    pagingType: 'full_numbers',
                      pageLength: 5,
                      processing: true,
                      dom: 'Bfrtip',
                          buttons: ['copy', 'csv', 'print'
                          ]
                }
            );
            setPrepare(false);
        } catch(err) {

        }
    }

    function getdata1() {
        const db = firebase.firestore();

        db.collection("ocert").where("user", "==", user).where("colid", "==", colid)
            .onSnapshot(function (querySnapshot) {
            const Comment = [];
            querySnapshot.forEach((doc) => {
                console.log(`${doc.id} => ${doc.data()}`);
                const { user,certificate,certcode,description,category,status1 } = doc.data();
                Comment.push({
                    _id: doc.id,
                    user,
                   certificate,certcode,description,category,status1
                });
            });
            //const { name, coursename, link } = querySnapshot.val();
            //console.log(doc.id + ' ' + doc.data);
            setResults(Comment);
           
        });
        
    }

    function deletedata1(id) {
        const db = firebase.firestore();

        db.collection("ocert").doc(id).delete().then(() => {
            console.log("Document successfully deleted!");
            //alert("Success");
        }).catch((error) => {
            console.error("Error removing document: ", error);
            alert(error);
        });     
    }

    function setcompleted(id, status) {
        const db = firebase.firestore();

        
        db.collection("modules").doc(id).set({
            status: status,
            datecompleted: new Date()
        }, { merge: true })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });  
        //history.replace('/courseactions'); 
    }

    const _handlePressButtonAsync = async (link) => {
        var win = window.open(link, '_blank');
        win.focus();
      };

   

    const addcomments = async (submissionid, submissionname, submissionregno) => {
        
        global1.submissionid=submissionid;
        global1.submissionregno=submissionregno;
        global1.submissionname=submissionname;
        // global1.coursecode=coursecode;
        // global1.coursename=coursename;
        //history.replace('/enrollstudents');
        history.replace('/submissioncomments');
       
    };

    

    useEffect(() => {
       // checklogin();
        //search1();
        //getexamlist();

     

        //getdata1();


        //searchApi('');
        

    }, []);


    function timely(dt1) {
        //var date1=new Date(dt1);
        const date1 = new Date(dt1.toDate().toDateString());
        return date1.getDate() + '/' + (date1.getMonth() + 1) + '/' + date1.getFullYear();
        //return dt1.toString();
    }

    const upload = (id,  status1, description) => {
        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
        global1.field1=id;
        global1.criteria='1';
        global1.metric='ocert';
        global1.collection1='explearnproj';
        global1.type='Experential Learning';
        global1.description=description;
        global1.pagenav='viewnocert';
        
        // history.replace('/selectawsconfig');
        //history.replace('/selectmetricdoctype');
        history.replace('/selectawsconfigf');
        // history.replace('/addsupportingdoc2f1');
    }

    const edit = (id, certificate,certcode,description,category,status1) => {

        if(status1=='Accepted') {
            alert('This document is accepted. Please contact IQAC coordinator to allow editing');
            return;
        }
      
        global1.assetid=id;
        global1.certificate=certificate;
global1.certcode=certcode;
global1.description=description;
global1.category=category;

        global1.status1=status1;

        global1.pagenav='viewnocert';
        history.replace('/editnocert');
       
        
        //history.replace('/assetedit');
    }
    
   
    const formatdates = (date1) => {
        var dt1=new Date(date1);
        var month=dt1.getMonth() + 1;
        return dt1.getDate() + '/' + month + '/' + dt1.getFullYear();
    }
    

    const width=window.innerWidth;
    const mdivwidth=width - 130;

   
       
        //alert('loaded');
  

    


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                   
                    
                    <td> 

                        {/* <TopNavigation /> */}

                    
        
        <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
        >
       
           
    
        <br />
        {isUploading ? (
				<div style={{ alignItems: 'center', marginTop: 30, marginBottom: 60}}>
                   <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
				</div>
			) : (
                <div></div>
			)} 
         

        <h4>Privacy policy</h4>
        <hr />
        


We do not collect any personal information from you.<br /><br />

We do not use any tracking mechanism or access to your device location.<br /><br />

Registration information (Name, Phone, Email, Registration no) is provided to us by your university. The information is same as that publicly available. We do not disclose this information to any third party or use this information for any kind of marketing.<br /><br />

Personal Information <br /><br />

We do not collect personal information from users in any ways, However, we may get some information provided by the institution to us during registration such as name, email address, or other contact details.<br /><br />

Usage and Device Information<br /><br />
We do not collect any usage data.<br /><br />

Cookies and Tracking Technologies<br /><br />
We do not use any cookie or any other tracking technologies.<br /><br />

How We Use Collected Information<br /><br />
We may use the collected information for various purposes, including:<br />

To provide and maintain the App's functionality.<br />
To personalize user experience and offer tailored content.<br />
To analyze usage trends and improve the App's performance and features.<br />
To communicate with users, including responding to inquiries or providing updates and information related to the App.<br />

Data Sharing and Disclosure<br /><br />

We do not share, sell, trade, or rent personal information to third parties, unless when required by law or to protect our rights or comply with legal obligations.<br /><br />

Data Security<br /><br />
We prioritize the security of users' personal information and implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of data.<br /><br />



If you have any questions, please feel free to contact us at support@campus.technology.<br /><br />




				
			
        
        </div>
        {/* <FooterNav1 /> */}
        </td>
                </tr>
            </table>
        </div>
        
    );

}

export default Allclasses;