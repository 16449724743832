import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/SidedocNavigation';
import TopNavigation from '../components/layout/TopNavigationmob';
import FooterNav1 from '../components/layout/FooterNav1';
import { duration } from "@material-ui/core";



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);

    const consultantref=useRef();
const titleref=useRef();
const agencyref=useRef();
const yearref=useRef();
const revenueref=useRef();
const traineesref=useRef();
const advisorref=useRef();
const departmentref=useRef();
const roleref=useRef();
const contactref=useRef();
const durationref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;
    const patentid=global1.addonid;


    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();
       

       consultantref.current.value=global1.consultant;
titleref.current.value=global1.title;
agencyref.current.value=global1.agency;
yearref.current.value=global1.year;
revenueref.current.value=global1.revenue;
traineesref.current.value=global1.trainees;
advisorref.current.value=global1.advisor;
departmentref.current.value=global1.department;
roleref.current.value=global1.role;
contactref.current.value=global1.contact;
durationref.current.value=global1.duration;


    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
        //const title=titleref.current.value;
        const consultant=consultantref.current.value;
const title=titleref.current.value;
const agency=agencyref.current.value;
const year=yearref.current.value;
const revenue=revenueref.current.value;
const trainees=traineesref.current.value;
const advisor=advisorref.current.value;
const department=departmentref.current.value;
const role=roleref.current.value;
const contact=contactref.current.value;
const duration=durationref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/updateconsultancybyfacm', {
            params: {
                id: patentid,
                user: user,
                token: token,
                name: name,
                colid: colid,
                consultant:consultant,
title:title,
agency:agency,
year:year,
revenue:revenue,
trainees:trainees,
advisor:advisor,
department:department,
role:role,
contact:contact,
duration:duration,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        //history.replace('/viewnconsultancymob');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

    const goback = async () => {
       
     
        //history.replace('/viewnconsultancymob');
        const pagenav=global1.pagenav;
        //history.goBack();
        history.replace('/' + pagenav);
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 100;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className="container">
         
                        {/* <TopNavigation />
                        <br /> */}
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10 }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Edit data</p>
                        <hr />
                       

                   Enter Consultant<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Consultant" id="consultant" ref={consultantref} />
<br /><br />

Enter Title of consultancy<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Title of consultancy" id="title" ref={titleref} />
<br /><br />

Enter Sponsoring agency<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Sponsoring agency" id="agency" ref={agencyref} />
<br /><br />

<label htmlFor="year">Academic year</label>

<select id="year" className="form-control"  ref={yearref}>
<option value="2023-24">2023-24</option>
<option value="2022-23">2022-23</option>
<option value="2021-22">2021-22</option>
<option value="2020-21">2020-21</option>
<option value="2019-20">2019-20</option>
<option value="2018-19">2018-19</option>
<option value="2017-18">2017-18</option>
</select>
<br /><br />

Enter Revenue generated<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Revenue generated" id="revenue" ref={revenueref} />
<br /><br />

Enter No of trainees<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter No of trainees" id="trainees" ref={traineesref} />
<br /><br />

Enter Advisor<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Advisor" id="advisor" ref={advisorref} />
<br /><br />

Enter Department<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Department" id="department" ref={departmentref} />
<br /><br />

<label htmlFor="role">Added by role</label>

<select id="role" className="form-control"  ref={roleref}>
<option value="Faculty">Faculty</option>
<option value="Finance">Finance</option>
</select>
<br /><br />

Enter Contact details<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Contact details" id="contact" ref={contactref} />
<br /><br />

Enter Duration<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Duration" id="duration" ref={durationref} />
<br /><br />


                  


                      
                        <button  onClick={searchapi}>Update data</button>
                        <button  onClick={goback}>Cancel</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
          
        </div>
    );

}

export default Newclasses;