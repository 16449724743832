import React, { useState, useContext, useEffect, useRef } from "react";
import classes from './form.module.css';
import { useHistory } from 'react-router-dom';
import global1 from './global1';
import { FileDrop } from 'react-file-drop';

import ep1 from '../api/ep1';
import {Link} from 'react-router-dom';
import FavoritesContext from '../store/favourites-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faBook, faTrash, faHome, faVideo, faComment, faTachometerAlt, faEnvelope, faHandsHelping, faQuestionCircle, faPlusSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';


import classes2 from './fileupload.module.css';
import classes1 from './newform2.module.css';
import './Demo.css';

import SidecourseNavigation from '../components/layout/SidecourseNavigation';
import SidefacNavigation from "../components/layout/SidefacNavigation";
import Sideicon from '../components/layout/Sideicon';
import SidedocNavigation from '../components/layout/Sfin1';
import TopNavigation from '../components/layout/TopNavigation';
import FooterNav1 from '../components/layout/FooterNav1';



function Newclasses() {

    const favcontxt=useContext(FavoritesContext);



    const clientidref=useRef();
const clientref=useRef();
const amountref=useRef();
const cgstref=useRef();
const sgstref=useRef();
const igstref=useRef();
const totalref=useRef();
const duedateref=useRef();
const bankref=useRef();
const accountref=useRef();
const ifscref=useRef();
const paybankref=useRef();
const paydateref=useRef();
const refnoref=useRef();
const moderef=useRef();
const receivedref=useRef();
const tdsref=useRef();
const projectref=useRef();

  

    const history=useHistory();

    const [term, setTerm] = useState('');
    const [term1, setTerm1] = useState('');
    const [term2, setTerm2] = useState('');
    const [datechange, onDatechange] = useState(new Date());
    const [date2, setDate2] = useState(new Date());

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isUploading, setIsuploading] = useState(false);
    const styles = { border: '1px solid black', width: '90%', height: 300,  color: 'black', padding: 20 };

    const studid=global1.studid;
    const regno=global1.regno;
    const colid=global1.colid;
    const name=global1.name;
    const user=global1.user;
    const courseid=global1.courseid;
    const coursecode=global1.coursecode;
    const coursename=global1.coursename;
    const token=global1.token;
    const accno=global1.accno;
    const book=global1.book;
    const department=global1.department;

    function logout() {
        favcontxt.addFavorite({
            studid: '',
            name: ''
        },'',0,'');
    }


    useEffect(() => {
       //logout();

    }, []);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const changeHandler1 = (files,event) => {
        setSelectedFile(files[0]);
        setIsFilePicked(true);
    };

    const searchapi = async () => {
       

        const clientid=clientidref.current.value;
const client=clientref.current.value;
const amount=amountref.current.value;
const cgst=cgstref.current.value;
const sgst=sgstref.current.value;
const igst=igstref.current.value;
const total=totalref.current.value;
const duedate=duedateref.current.value;
const bank=bankref.current.value;
const account=accountref.current.value;
const ifsc=ifscref.current.value;
const paybank=paybankref.current.value;
const paydate=paydateref.current.value;
const refno=refnoref.current.value;
const mode=moderef.current.value;
const received=receivedref.current.value;
const tds=tdsref.current.value;
const project=projectref.current.value;

        //alert(department);
        //setLoading(true);
        setIsuploading(true);
        const response = await ep1.get('/api/v1/createninvoicefinalbyfac', {
            params: {
                user: user,
                token: token,
                colid: colid,
                name: name,
                clientid:clientid,
client:client,
amount:amount,
cgst:cgst,
sgst:sgst,
igst:igst,
total:total,
duedate:duedate,
bank:bank,
account:account,
ifsc:ifsc,
paybank:paybank,
paydate:paydate,
refno:refno,
mode:mode,
received:received,
tds:tds,
project:project,
status1:'Submitted',
                comments:''

            }
        });
        //setLoading(false);
        setIsuploading(false);
        //console.log(response.data.data);
        //alert(response.data.status);
        history.replace('/viewnninvoicefinal');
       
    };

   
           const width=window.innerWidth;
           const mdivwidth=width - 330;

           const openlink = async (link) => {
            
            var win = window.open(link, '_blank');
            win.focus();
          };


    return (
        <div className={classes1.fullwidth}>
            <table style={{ width: '100%'}}>
                <tr>
                    <td valign="top" style={{ width:60 }}>
                        <Sideicon />
                    </td>
                    <td valign="top" style={{ backgroundColor: '#fff', width: 230, borderRightColor: '#fff', borderRightWidth: 1 }}>
                   
                        <SidedocNavigation />
                    </td>
                    
                    <td> 
                        <TopNavigation />
                    <div style={{ margin: 'auto ', marginTop: 20, paddingLeft: 10, paddingRight: 10, width: mdivwidth }}
       
       >
                        
                        <p style={{ fontSize: 18 }}> Add data</p>
                        <hr />
                       
                            
                  Enter Client id<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Client id" id="clientid" ref={clientidref} />
<br /><br />

Enter client name<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter client name" id="client" ref={clientref} />
<br /><br />

Enter Sale amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Sale amount" id="amount" ref={amountref} />
<br /><br />

Enter CGST amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter CGST amount" id="cgst" ref={cgstref} />
<br /><br />

Enter SGST amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter SGST amount" id="sgst" ref={sgstref} />
<br /><br />

Enter IGST amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter IGST amount" id="igst" ref={igstref} />
<br /><br />

Enter Total amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Total amount" id="total" ref={totalref} />
<br /><br />

Enter Due date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Due date" id="duedate" ref={duedateref} />
<br /><br />

Enter Bank details<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Bank details" id="bank" ref={bankref} />
<br /><br />

Enter Account number<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Account number" id="account" ref={accountref} />
<br /><br />

Enter IFSC code<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter IFSC code" id="ifsc" ref={ifscref} />
<br /><br />

Enter Paid in bank<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Paid in bank" id="paybank" ref={paybankref} />
<br /><br />

Enter Paid in date<br /><br />

<input type="date" required style={{width: "100%"}} placeholder="Enter Paid in date" id="paydate" ref={paydateref} />
<br /><br />

Enter Reference no<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Reference no" id="refno" ref={refnoref} />
<br /><br />

Enter Mode of payment<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Mode of payment" id="mode" ref={moderef} />
<br /><br />

Enter Received amount<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter Received amount" id="received" ref={receivedref} />
<br /><br />

Enter TDS deducted<br /><br />

<input type="number" required style={{width: "100%"}} placeholder="Enter TDS deducted" id="tds" ref={tdsref} />
<br /><br />

Enter Project<br /><br />

<input type="text" required style={{width: "100%"}} placeholder="Enter Project" id="project" ref={projectref} />
<br /><br />




                      
                        <button  onClick={searchapi}>Add data</button>

                      
      
            <div>
          
            <br />
            
                
            {isUploading ? (
				<div>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>Please wait</p>
					<img src="http://iqac.online/images/1480.gif" />
				</div>
			) : (
                <div></div>
			)}  
        
              
	
              
                <br />
                {term2}
            
          
           </div>
           </div>
           <FooterNav1 />
           </td></tr></table>
        </div>
    );

}

export default Newclasses;